import { gql } from '@apollo/client';

export const GET_OPP_STAGE_HEADERS = gql`
  query Query {
    GetOpportunityStageGridHeaders
  }
`;

export const GET_ALL_OPP_STAGE = gql`
  query GetAllOpportunityStages($criteria: StageCriteriaInput!, $searchFields: FindStageInput) {
    GetAllOpportunityStages(Criteria: $criteria, SearchFields: $searchFields) {
      total
      results {
        id
        company {
          id
          name
        }
        data {
          id
          name
          stageGroup {
            id
            name
          }
          status {
            id
            name
          }
          closeStatus {
            id
            name
          }
          potential
          boolean
          createdAt
          updatedAt
        }
      }
    }
  }
`;
