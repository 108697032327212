import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { ContactPersonalInfoModel } from 'src/models';
import addressAdapter from './address.adapter';
import { list } from './common';
import emailAdapter from './email.adapter';
import phoneAdapter from './phone.adapter';

const contactPersonalInfoAdapter: AdapterFunc<
  GQL.ContactPersonalInfoModel,
  ContactPersonalInfoModel
> = (input) => ({
  id: input.id,
  addresses: list(addressAdapter, input.addresses),
  mainAddress: input.mainAddress,
  mainEmail: input.mainEmail,
  mainPhone: input.mainPhone,
  createdAt: input.createdAt,
  emails: list(emailAdapter, input.emails),
  phones: list(phoneAdapter, input.phones),
  updatedAt: input.updatedAt,
});

export default contactPersonalInfoAdapter;
