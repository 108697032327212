import { Form, FormInstance, notification } from 'antd';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams, useNavigate } from 'react-router';
import { ViewNames } from 'src/types/viewTypes';
import useUnSaveChangesWarning from 'src/hooks/useUnSaveChangesWarning';
import { formattingData } from 'src/components/opportunityDetail/utils/formatingData';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import en from 'dayjs/locale/en';

import { cloneDeep, uniqBy, uniq, isEqual, omitBy, isNil } from 'lodash';
import { OpportunityModel } from 'src/models/opportunity.model';
import useOpportunitiesStore from 'src/hooks/stores/useOpportunitiesStore';
import {
  GET_OPP_HEADERS,
  UPDATE_OPPORTUNITY,
  GET_ALL_OPPORTUNITIES_STAGES,
  GET_COMPANY_BY_TYPE_ID,
  GET_ALL_CONTACT_BY_COMPANY_ID,
  CLOSE_OPP,
  GQL,
} from 'src/graphql';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import useDebouncedCallback from 'src/hooks/useDebouncedCallback';
import { showConfirmationMessage } from 'src/components/commons/subTableV1/helpers';
import { GET_ALL_OPP_CLOSE_REASON } from 'src/modules/settings/modules/subTables/modules/opportunities/tabs/closeReason/graphql/queries';
import { GetAllOpportunityCloseReasonsResponse } from 'src/modules/settings/modules/subTables/modules/opportunities/tabs/closeReason/types';
import { GET_ALL_OPP_FAILED_REASON } from 'src/modules/settings/modules/subTables/modules/opportunities/tabs/failedReason/graphql/queries';
import { GetAllOpportunityFailedReasonsResponse } from 'src/modules/settings/modules/subTables/modules/opportunities/tabs/failedReason/types';
import { detectDifferences } from 'src/components/opportunityDetail/utils/detectChanges';
import { preparePayload } from 'src/components/opportunityDetail/utils/preparePayload';
import { extractIds } from 'src/components/opportunityDetail/utils/extractIds';
import { extractValidIds } from 'src/components/opportunityDetail/utils/extractValidIds';
import handleError from 'src/utils/functions/handleError';

dayjs.extend(customParseFormat);
dayjs.locale(en);

type Step = {
  name: string;
  id: number;
  selected: boolean;
};
interface OppStatus {
  label: string;
  value: number;
}

interface CompanyOption {
  label: string;
  value: number;
  desc: string;
}
type CompanyType = 'manufacturer' | 'distributor' | 'customer';
interface CustomSelectContactOptions {
  companyId: number;
  companyMainAddress: string | null;
  desc: string;
  label: string;
  value: number;
}

interface OppContextProps {
  opportunity: OpportunityModel | null;
  form: FormInstance;
  processedSave: boolean;
  stepperInfo: Step[];
  setStepperInfo: (value: Step[]) => void;
  valueCompany?: string;
  mfgContacts?: CustomSelectContactOptions[];
  contactsMfg?: string[];
  mfgCompanies?: CompanyOption[];
  distributorCompanies?: CompanyOption[];
  loading?: boolean;
  customerCompaniesValues?: string[];
  valueCompanyDistributor?: string[];
  distributorContactsValue?: string[];
  distributorContacts?: CustomSelectContactOptions[];
  customerCompaniesOptions?: CompanyOption[];
  customerContactsValues?: string[];
  customerContactsOptions?: CustomSelectContactOptions[];
  opportunityOwner?: string;
  handleSave: () => void;
  submitData: boolean;
  saveStepperExternalValue: (value: number) => void;
  oppStatus: OppStatus[];
  oppStatusDefaultValue?: string;
  handleOnSearch?: (value: string, fieldName: string) => void;
  customSelectOnChange?: (value: any[], name: any) => void;
  handleDeleteModal?: () => void;
  handleCloseOppSaveAction?: (data: any) => void;
  optionSelectFunction?: (optionId: string) => void;
  handleCloseOppModalState?: () => void;
  closeOppModalState?: boolean;
  closingLoading?: boolean;
  opportunityClosedReasonOptions?: React.MutableRefObject<any[]>;
  opportunityFailedReasonOptions?: React.MutableRefObject<any[]>;
}

const initialState: OppContextProps = {} as OppContextProps;

const OppContext = createContext(initialState);

const OppContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const initialValuesRef = useRef<any>();
  const [submitData, setSubmitData] = useState(false);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const manufacturerWatcher = Form.useWatch('manufacturer', form);
  const distributorWatcher = Form.useWatch('distributor', form);
  const customerWatcher = Form.useWatch('customer', form);

  const [loading, setLoading] = useState<boolean>(true);

  const [valueCompany, setValueCompany] = useState<string>(); // This is for the manufacturer initial value
  const [mfgCompanies, setMfgCompanies] = useState<CompanyOption[]>([]); // This is for the manufacturer
  const [contactsMfg, setContactsMfg] = useState<string[]>([]);
  const [mfgContacts, setMfgContacts] = useState<any[]>([]); // This is for the manufacturer;
  const [customerCompaniesValues, setCustomerCompaniesValues] = useState<string[]>([]);
  const [customerContactsValues, setCustomerContactsValues] = useState<string[]>([]);
  const [customerContactsOptions, setCustomerContactsOptions] = useState<
    CustomSelectContactOptions[]
  >([]);
  const [customerCompaniesOptions, setCustomerCompaniesOptions] = useState<CompanyOption[]>([]);
  const [valueCompanyDistributor, setDistributorCompaniesValues] = useState<string[]>([]);
  const [distributorContacts, setDistributorContactsOptions] = useState<
    CustomSelectContactOptions[]
  >([]);
  const [distributorCompanies, setDistributorCompaniesOptions] = useState<CompanyOption[]>([]);
  const [opportunityOwner, setOpportunityOwner] = useState<string>();
  const [distributorContactsValue, setDistributorContactsValue] = useState<string[]>([]);
  const [updateCompanyDitributors, setUpdateCompanyDitributors] = useState(false);
  const { loadById, selected, deleteOne, setSelected } = useOpportunitiesStore();
  const { id } = useParams();
  const navigate = useNavigate();

  // const [activeExpandedEdition, setActiveExpandedEdition] = useState(false);
  const [stepperInfo, setStepperInfo] = useState<Step[]>([]);
  const [stepperValue, setStepperValue] = useState<number>();
  const [processedSave, setProcessedSave] = useState(false);
  const [closeOppModalState, setCloseOppModalState] = useState(false);
  const [closingLoading, setClosingLoading] = useState(false);
  const opportunity = useRef<any | null>(null);
  const opportunityClosedReasonOptions = useRef<any[]>([]);
  const opportunityFailedReasonOptions = useRef<any[]>([]);
  const { data: opportunityHeaders } = useQuery(GET_OPP_HEADERS);
  const [getStagesByCompanyId] = useLazyQuery(GET_ALL_OPPORTUNITIES_STAGES);
  const [getCompanyTypeByID] = useLazyQuery(GET_COMPANY_BY_TYPE_ID);
  const [getContactsByCompanyId] = useLazyQuery(GET_ALL_CONTACT_BY_COMPANY_ID);
  const [fetchAllOppCloseReasons, fetchAllOppCloseReasonsResponse] =
    useLazyQuery<GetAllOpportunityCloseReasonsResponse>(GET_ALL_OPP_CLOSE_REASON, {
      fetchPolicy: 'network-only',
      onCompleted(data) {
        if (data?.GetAllOpportunityCloseReasons?.results.length === 1) {
          opportunityClosedReasonOptions.current =
            data?.GetAllOpportunityCloseReasons?.results[0].data.map((element) => ({
              label: element.name,
              value: element.id,
            }));
        } else {
          const innerOppClosedReasons = data?.GetAllOpportunityCloseReasons?.results.find(
            (element) => element.id === opportunity.current.manufacturer,
          );
          opportunityClosedReasonOptions.current =
            innerOppClosedReasons?.data?.map((element) => ({
              label: element.name,
              value: element.id,
            })) ?? [];
        }
      },
    });

  const [fetchAllOppFailedReasons, fetchAllOppFailedReasonsResponse] =
    useLazyQuery<GetAllOpportunityFailedReasonsResponse>(GET_ALL_OPP_FAILED_REASON, {
      fetchPolicy: 'network-only',
      onCompleted(data) {
        if (data?.GetAllOpportunityFailedReasons?.results.length === 1) {
          opportunityFailedReasonOptions.current =
            data?.GetAllOpportunityFailedReasons?.results[0].data.map((element) => ({
              label: element.name,
              value: element.id,
            }));
        } else {
          const innerOppFailedReasons = data?.GetAllOpportunityFailedReasons?.results.find(
            (element) => element.id === opportunity.current.manufacturer,
          );
          opportunityFailedReasonOptions.current =
            innerOppFailedReasons?.data?.map((element) => ({
              label: element.name,
              value: element.id,
            })) ?? [];
        }
      },
    });

  const [closeOppMutation] = useMutation<Pick<GQL.Mutation, 'CreateOpportunities'>>(CLOSE_OPP, {
    onCompleted: (data) => {
      if (data) {
        setCloseOppModalState(false);
        setClosingLoading(false);
        notification.success({
          message: 'Success',
          description: 'Opportunity closed successfully.',
          key: 'closeOppSuccess',
          duration: 3,
        });
      } else {
        setClosingLoading(false);
        notification.error({
          message: 'Error',
          description: 'Something went wrong',
          key: 'closeOppError',
          duration: 3,
        });
      }
    },
    onError: (error) => {
      handleError(error);
      setClosingLoading(false);
    },
  });
  const [oppStatus, setOppStatus] = useState<OppStatus[]>([]);
  // const [stages, setStages] = useState<any[]>([]);
  const [oppStatusDefaultValue, setOppStatusDefaultValue] = useState<any>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateOpp] = useMutation(UPDATE_OPPORTUNITY, {
    onCompleted: () => {
      notification.success({
        message: 'Opportunity Updated',
        key: 'update-opp-success',
      });
    },
    onError: (error) => {
      notification.error({
        message: error.message,
        key: 'update-opp-error',
      });
    },
  });

  const saveStepperExternalValue = (value: number) => {
    setStepperValue(value);
  };

  const handleStatusStageByManufacturerId = (mfcId: number, oppStageName?: string) => {
    getStagesByCompanyId({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 100,
          },
        },
        searchFields: {
          names: oppStageName ? [oppStageName] : [],
          company_ids: mfcId ? [0, mfcId] : [0],
        },
      },
    }).then((res: any) => {
      const response = res.data?.GetAllOpportunityStages?.results;

      const initialOppStatus = response[0].data.map((item: any) => ({
        label: item.status.name,
        value: item.status.id,
      }));

      setOppStatusDefaultValue(initialOppStatus[0].label);
      form.setFieldValue('status', initialOppStatus[0].value);
      const needsReview = {
        label: 'Needs Review',
        value: 'Needs Review',
      };
      const statusWithNeedsReview = [...initialOppStatus, needsReview];
      setOppStatus(statusWithNeedsReview);
    });
  };
  const updateStepperInfo = (
    opportunityHeadersData: any,
    selectedData: any,
    updateStepperState: (info: any) => void,
    manufacturerId: number,
  ) => {
    if (!opportunityHeadersData || !selectedData) return;

    const headers = opportunityHeadersData.GetOpportunitiesGridHeaders;
    const oppStageHeader = headers.find((header: any) => header.dataIndex === 'oppStage');
    if (!oppStageHeader) return;

    const stages = oppStageHeader.variableOptions;

    if (selectedData.manufacturer && stages[manufacturerId]?.length > 0) {
      updateStepperState(
        stages[manufacturerId]?.map((stage: any) => ({
          ...stage,
          selected: selectedData?.oppStage?.id === stage.id,
        })),
      );
    } else {
      updateStepperState(
        stages[0].map((stage: any, index: number) => ({
          ...stage,
          selected: selectedData?.oppStage?.id
            ? selectedData?.oppStage?.id === stage?.id
            : index === 0,
        })),
      );
    }
  };

  // helpers

  const fetchContacts = async (companyIds: any[], companyType: CompanyType) => {
    try {
      const response = await getContactsByCompanyId({
        variables: {
          criteria: {
            pagination: {
              from: 0,
              size: 100,
            },
          },
          searchFields: {
            businessinfo_company_ids: companyIds,
          },
        },
      });

      const parsedContacts: CustomSelectContactOptions[] =
        response.data?.GetAllContactsForGrid?.results.map((e: any) => ({
          label: `${e?.firstName} ${e?.lastName}`,
          value: e?.id,
          desc: e?.businessInfo?.company?.name,
          companyId: e?.businessInfo?.company?.id,
          companyMainAddress: e?.businessInfo?.mainAddress?.address || 'No Address',
        }));

      if (companyType === 'manufacturer') {
        setMfgContacts(parsedContacts);
      }
      if (companyType === 'distributor') {
        setDistributorContactsOptions((prevState) =>
          uniqBy([...prevState, ...parsedContacts], 'value'),
        );
      }
      if (companyType === 'customer') {
        setCustomerContactsOptions((prevState) =>
          uniqBy([...prevState, ...parsedContacts], 'value'),
        );
      }
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const handleContactsByCompanyIds = (mfcIds: any, companyType: CompanyType) => {
    if (!mfcIds) {
      return;
    }
    const companyIds = extractValidIds(mfcIds);
    fetchContacts(companyIds, companyType);
  };

  const fetchCompanyTypeById = async (value: any, companyType: string) => {
    await getCompanyTypeByID({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 100,
          },
        },
        searchFields: {
          companyType,
          companyName: value, // value is the name of the company
        },
      },
    }).then((res: any) => {
      const parsedCompany = res.data?.GetCompaniesByType?.results.map((e: any) => ({
        label: e?.name,
        value: e?.id,
        desc: e?.mainAddress?.address || 'No address',
      }));

      if (companyType === 'manufacturer') {
        setMfgCompanies(parsedCompany);
      }
      if (companyType === 'distributor') {
        setDistributorCompaniesOptions(parsedCompany);
      }
      if (companyType === 'customer') {
        setCustomerCompaniesOptions(parsedCompany);
      }
    });
  };

  useEffect(() => {
    if (stepperInfo) {
      const currentOppStage = stepperInfo.filter((step) => step.selected);

      const currentMfgId =
        Array.isArray(selected?.manufacturer) && selected.manufacturer.length > 0
          ? selected.manufacturer[0].id
          : undefined;
      const manufacturerWatcherIds = extractValidIds(manufacturerWatcher);

      const mfgIdValues =
        currentMfgId === (manufacturerWatcherIds && manufacturerWatcherIds[0])
          ? currentMfgId
          : manufacturerWatcherIds && manufacturerWatcherIds[0];

      if (currentMfgId) {
        handleStatusStageByManufacturerId(mfgIdValues, currentOppStage[0]?.name);
      }
    }

    // quede aqui
  }, [stepperInfo]);

  const getOppInfo = () => {
    setLoading(true);
    loadById(Number(id), 'getOpportunityId').finally(() => {
      setLoading(false);
      setProcessedSave(true);
    });
  };

  // fill the stepperInfo with the stages once the opportunityHeaders are loaded
  useEffect(() => {
    updateStepperInfo(opportunityHeaders, selected, setStepperInfo, selected?.manufacturer[0]?.id);
  }, [opportunityHeaders, selected]);

  useEffect(() => {
    form.setFieldValue('stageId', stepperInfo.find((step) => step.selected)?.id);
  }, [stepperInfo]);

  useEffect(() => {
    const watchers: { watcher: any; type: CompanyType }[] = [
      { watcher: manufacturerWatcher, type: 'manufacturer' },
      { watcher: distributorWatcher, type: 'distributor' },
      { watcher: customerWatcher, type: 'customer' },
    ];

    watchers.forEach(({ watcher, type }) => {
      handleContactsByCompanyIds(watcher, type);
    });
  }, [manufacturerWatcher, distributorWatcher, customerWatcher]);

  useEffect(() => {
    if (updateCompanyDitributors) {
      // Perform the action that needs to happen after the state update
      form.setFieldValue('distributor', valueCompanyDistributor);
      setUpdateCompanyDitributors(false); // Reset the flag
    }
  }, [valueCompanyDistributor, updateCompanyDitributors]);

  const customSelectOnChange = (value: any, name: any) => {
    if (name === 'manufacturer') {
      form.resetFields(['manufacturerContact']);
      setContactsMfg([]);
      form.setFieldValue('manufacturer', [value]);
      setValueCompany(value);
    }

    const contactsId = extractIds(value);

    if (name === 'distributor') {
      if (valueCompanyDistributor.length < value.length) {
        setDistributorCompaniesValues((prevState) => uniq([...prevState, ...value]));
      } else {
        setDistributorCompaniesValues(uniq(value));
      }

      handleContactsByCompanyIds(contactsId, name);
    } else if (name === 'contactsDistributor') {
      const innerValueCompanyDistributor: string[] = [];
      const selectedContactCompany = contactsId?.map((item) => {
        const innerDistributorContact = distributorContacts.find(
          (contact) => contact.value === item,
        );
        if (innerDistributorContact) {
          innerValueCompanyDistributor.push(
            `{{%${innerDistributorContact.companyId}%}}${innerDistributorContact.desc}`,
          );
          return {
            label: innerDistributorContact.desc,
            value: innerDistributorContact.companyId,
            desc: innerDistributorContact.companyMainAddress?.split(',')[0] || 'No address',
          };
        }
        return null;
      });

      if (selectedContactCompany) {
        const innerAutoSelectedCompanies = selectedContactCompany as CompanyOption[];
        setDistributorCompaniesValues((prevState) => {
          const updatedValues = uniq([...prevState, ...innerValueCompanyDistributor]);
          setUpdateCompanyDitributors(true);

          return updatedValues;
        });

        setDistributorCompaniesOptions((prev) =>
          uniqBy([...prev, ...innerAutoSelectedCompanies], 'value'),
        );
      }
    } else if (name === 'customer') {
      if (customerCompaniesValues.length < value.length) {
        setCustomerCompaniesValues((prevState) => uniq([...prevState, ...value]));
      } else {
        setCustomerCompaniesValues(uniq(value));
      }
      handleContactsByCompanyIds(contactsId, name);
    } else if (name === 'contactsCustomer') {
      const innerValueCompanyCustomer: string[] = [];

      const selectedContactCompany = contactsId?.map((element) => {
        const innerCustomerContact = customerContactsOptions.find((item) => item.value === element);
        if (innerCustomerContact) {
          innerValueCompanyCustomer.push(
            `{{%${innerCustomerContact.companyId}%}}${innerCustomerContact.desc}`,
          );
          return {
            label: innerCustomerContact.desc,
            value: innerCustomerContact.companyId,
            desc: innerCustomerContact.companyMainAddress?.split(',')[0] || 'No address',
          };
        }
        return null;
      });
      if (selectedContactCompany) {
        // get the inner selected companies with the structure of the companyOption
        const innerAutoSelectedCompanies = selectedContactCompany as CompanyOption[];
        setCustomerCompaniesValues((prev) => {
          const updatedValues = uniq([...prev, ...innerValueCompanyCustomer]);
          // setUpdateCompanyCustomer(true); // Set the flag to trigger useEffect
          return updatedValues;
        });
        // set the customer companies options
        setCustomerCompaniesOptions((prev) =>
          uniqBy([...prev, ...innerAutoSelectedCompanies], 'value'),
        );
      }
    }
  };
  const debouncedFunctionCompanies = (...args: unknown[]) => {
    const [value, companyType] = args as [any, string];
    return fetchCompanyTypeById(value, companyType);
  };

  const debouncedSearchCompanies = useDebouncedCallback(debouncedFunctionCompanies, 300);

  const handleOnSearch = (value: string, fieldName: string) => {
    if (fieldName === 'manufacturer') {
      debouncedSearchCompanies(value, 'manufacturer');
    }
    if (fieldName === 'distributor') {
      debouncedSearchCompanies(value, 'distributor');
    }
    if (fieldName === 'customer') {
      debouncedSearchCompanies(value, 'customer');
    }
  };
  const handleSave = async () => {
    try {
      // Retrieve initial form data from local storage
      const initialDataForm = JSON.parse(localStorage.getItem(ViewNames.detailOpportunity) ?? '{}');

      // Get current form values
      const formValues = form.getFieldsValue();

      // Format the form data
      const formattedFormData = formattingData({
        ...formValues,

        id: Number(id),
      });

      // Detect differences between the initial and formatted form data
      const dataWithChanges = detectDifferences(initialDataForm, formattedFormData);

      const updateStageInput = {
        ...preparePayload(dataWithChanges, initialDataForm),
        id: Number(id),
      };
      // Log the differences

      await updateOpp({
        variables: {
          updateStageInput,
        },
      }).then((response) => {
        const { data } = response;
        if (data?.UpdateOpportunities) {
          setSelected(data?.UpdateOpportunities);
        }
      });
    } catch (error) {
      console.error('Error saving form data:', error);
    }
  };
  const { setShowExitPrompt } = useUnSaveChangesWarning(true, handleSave);

  // const activeSubmitData = async () => {
  //   form
  //     .validateFields()
  //     .then(() => {
  //       setSubmitData(true);
  //     })
  //     .catch(() => {
  //       setSubmitData(false);
  //     });
  // };
  const activeShowPrompt = () => {
    const initialDataForm = JSON.parse(localStorage.getItem(ViewNames.detailOpportunity) ?? '{}');
    const formValues = form.getFieldsValue();
    const formattedFormData = formattingData({
      ...formValues,
      oppStage: stepperValue,
      id: Number(id),
    });

    if (
      initialDataForm &&
      formValues.topic &&
      !isEqual({ ...formattedFormData }, { ...initialDataForm })
    ) {
      localStorage.setItem('detail-opportunity-id', id as string);
      setShowExitPrompt(true);
      setSubmitData(true);
      // activeSubmitData();
    } else {
      setShowExitPrompt(false);
      setSubmitData(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    getOppInfo();
  }, []);

  useEffect(() => {
    if (selected) {
      const manufacturerFieldValue = selected.manufacturer
        ? selected.manufacturer.map((item: any) => `{{%${item.id}%}}${item.name}`)
        : [];

      const manufacturerSelectOptions = selected.manufacturer
        ? selected.manufacturer.map((item: any) => ({ label: item.name, value: item.id }))
        : [];
      setMfgCompanies(manufacturerSelectOptions);
      const distributorFieldValue = selected.distributor
        ? selected.distributor.map((item: any) => `{{%${item.id}%}}${item.name}`)
        : [];

      const distributorSelectOptions = selected.distributor
        ? selected.distributor.map((item: any) => ({ label: item.name, value: item.id }))
        : [];

      const customerFieldValue = selected.customer
        ? selected.customer.map((item: any) => `{{%${item.id}%}}${item.name}`)
        : [];

      const customerSelectOptions = selected.customer
        ? selected.customer.map((item: any) => ({ label: item.name, value: item.id }))
        : [];

      if (selected.manufacturer && selected.manufacturer.length > 0) {
        setValueCompany(selected.manufacturer[0].name);
      } else {
        setValueCompany('Default Company Name');
      }
      const mfgContactsDefaultValues = selected.manufacturerContact
        ? selected.manufacturerContact.map((item: any) => `{{%${item.id}%}}${item.name}`)
        : [];

      setContactsMfg(mfgContactsDefaultValues);

      setDistributorCompaniesOptions(distributorSelectOptions);
      setDistributorCompaniesValues(distributorFieldValue);
      const distributorContactsValues = selected.distributorContact
        ? selected.distributorContact.map((item: any) => `{{%${item.id}%}}${item.name}`)
        : [];

      setDistributorContactsValue(distributorContactsValues);

      setCustomerCompaniesOptions(customerSelectOptions);
      setCustomerCompaniesValues(customerFieldValue);
      const customerContactsValue = selected.customerContact
        ? selected.customerContact.map((item: any) => `{{%${item.id}%}}${item.name}`)
        : [];

      setCustomerContactsValues(customerContactsValue);

      setOpportunityOwner(selected.oppOwner?.name);

      const salesTeamValue = {
        value: selected.salesTeam?.id,
        label: selected.salesTeam?.name,
      };
      const leadSourceValue = {
        value: selected.leadSource?.id,
        label: selected.leadSource?.name,
      };
      const ownerIdValue = {
        value: selected.oppOwner?.id,
        label: selected.oppOwner?.name,
      };

      const registrationStatusValue = {
        value: selected.registrationStatus,
        label: selected.registrationStatus,
      };

      const typeValue = { value: selected.type?.id, label: selected.type?.name };

      initialValuesRef.current = {
        /* fill with opp values */
        topic: selected.topic,
        followUp: selected.followUp ? dayjs(selected.followUp) : undefined,
        value: parseFloat(selected.value),
        estAnnualQty: parseFloat(selected.estAnnualQty),
        manufacturer: manufacturerFieldValue,
        distributor: distributorFieldValue,
        contactsDistributor: distributorContactsValues,
        manufacturerContact: mfgContactsDefaultValues,
        customer: customerFieldValue,
        customerContact: customerContactsValue,
        nextStep: selected.nextStep,
        notifyWatchers: selected.notifyWatchers,
        priority: selected.priority,
        estimatedCloseDate: selected.estimatedCloseDate
          ? dayjs(selected.estimatedCloseDate)
          : undefined,
        description: selected.description,
        reportingComments: selected.reportingComments,
        bidDate: selected.bidDate ? dayjs(selected.bidDate) : undefined,
        competitors: selected.competitors,
        salesTeam: salesTeamValue.value,
        type: typeValue.value,
        leadSource: leadSourceValue.value,
        ownerId: ownerIdValue.value,
        registrationNumber: selected.registrationNumber,
        registrationStatus: registrationStatusValue,
        approvedDate: selected.approvedDate ? dayjs(selected.approvedDate) : undefined,
        approvedBy: selected.approvedBy,
        expirationDate: selected.expirationDate ? dayjs(selected.expirationDate) : undefined,
        registrationLevel: selected.registrationLevel,
        notes: selected.notes,
        status: selected?.oppStatus?.id,
        stageId: selected?.oppStage?.id,
      };

      if (selected?.oppStage?.id) {
        setStepperValue(selected?.oppStage?.id);
      }

      const dataToStorage = formattingData({
        ...selected,
        manufacturer: manufacturerFieldValue,
        distributor: distributorFieldValue,
        contactsDistributor: distributorContactsValues,
        manufacturerContact: mfgContactsDefaultValues,
        customer: customerFieldValue,
        customerContact: customerContactsValue,
        oppStage: selected?.oppStage?.id,
        opportunityStageId: selected?.oppStage?.id,
        id: Number(id),
      });

      form.setFieldValue('manufacturer', manufacturerFieldValue);
      form.setFieldsValue(cloneDeep(initialValuesRef.current));
      localStorage.setItem('detail-opp-id', id as string);
      localStorage.setItem(ViewNames.detailOpportunity, JSON.stringify(dataToStorage));
    }
  }, [selected]);

  useEffect(() => {
    activeShowPrompt();
  }, [values]);

  useEffect(() => {
    if (
      opportunity.current &&
      !fetchAllOppCloseReasonsResponse.loading &&
      !fetchAllOppFailedReasonsResponse.loading
    ) {
      setCloseOppModalState(true);
    }
  }, [fetchAllOppCloseReasonsResponse.loading, fetchAllOppFailedReasonsResponse.loading]);

  const contentOnDelete = (
    <div
      style={{
        background: '#FFFCEB',
        border: '1px solid #FAAD14',
        padding: '10px 10px',
        borderRadius: '5px',
      }}
    >
      Deleting this record is an irreversible action and will permanently remove all associated
      data.
    </div>
  );

  const handleDelete = async () => {
    const removeOpportunitiesIds = selected ? selected.id : undefined;

    try {
      await deleteOne(removeOpportunitiesIds).then((response: any) => {
        if (response) {
          notification.success({
            message: 'Success',
            description: 'Opportunity Deleted',
            key: 'delete-opportunity-success',
            duration: 3,
          });
          setTimeout(() => {
            navigate('/opportunities');
          }, 500);
        }
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Something went wrong',
        key: 'delete-opportunity-error',
      });
    }
  };

  const handleDeleteModal = () => {
    showConfirmationMessage({
      title: 'Are you sure?',
      content: contentOnDelete as unknown as string,
      onOk: handleDelete,
    });
  };

  const handleAllActions = (action: string, data: any) => {
    const manufacturerIds = extractValidIds(data.manufacturer);
    let manufacturerId: number[] = [];

    if (Array.isArray(manufacturerIds)) {
      manufacturerId = manufacturerIds;
    } else if (typeof manufacturerIds === 'number') {
      manufacturerId = [manufacturerIds];
    }

    if (action === 'close' && data) {
      opportunity.current = data;
      const [currentMfgId] = manufacturerId;
      opportunity.current.manufacturer = currentMfgId;
      opportunity.current.id = Number(id);

      fetchAllOppCloseReasons({
        variables: {
          criteria: {
            pagination: {
              from: 0,
              size: 100,
            },
          },
          searchFields: {
            company_ids: [...manufacturerId, 0],
          },
        },
      });
      fetchAllOppFailedReasons({
        variables: {
          criteria: {
            pagination: {
              from: 0,
              size: 100,
            },
          },
          searchFields: {
            company_ids: [...manufacturerId, 0],
          },
        },
      });
    }
  };

  const optionSelectFunction = (optionId: string) => {
    handleAllActions(optionId, initialValuesRef.current);
  };

  const handleCloseOppModalState = () => {
    if (opportunity.current) {
      opportunity.current = null;
      opportunityClosedReasonOptions.current = [];
      opportunityFailedReasonOptions.current = [];
    }
    setCloseOppModalState(false);
  };
  const handleCloseOppSaveAction = async (data: any) => {
    setClosingLoading(true);
    const closeOpportunityData = omitBy({ ...data, opportunityId: opportunity.current.id }, isNil);
    try {
      await closeOppMutation({
        variables: {
          closeOpportunityData,
        },
      });
    } catch (error) {
      console.error('Error closing opportunity :', error);
    }
  };

  const value = useMemo(
    () => ({
      contact: selected,
      form,
      loading,
      submitData,
      handleSave,
      processedSave,
      opportunity: selected,
      stepperInfo,
      setStepperInfo,
      valueCompany,
      contactsMfg,
      mfgContacts,
      mfgCompanies,
      customerCompaniesValues,
      customerContactsValues,
      customerContactsOptions,
      customerCompaniesOptions,
      distributorContactsValue,
      valueCompanyDistributor,
      distributorCompanies,
      distributorContacts,
      opportunityOwner,
      saveStepperExternalValue,
      oppStatus,
      oppStatusDefaultValue,
      customSelectOnChange,
      handleOnSearch,
      handleDeleteModal,
      optionSelectFunction,
      closeOppModalState,
      closingLoading,
      opportunityClosedReasonOptions,
      opportunityFailedReasonOptions,
      handleCloseOppSaveAction,
      handleCloseOppModalState,
    }),
    [
      selected,
      form,
      submitData,
      handleSave,
      processedSave,
      selected,
      valueCompany,
      contactsMfg,
      mfgContacts,
      mfgCompanies,
      valueCompanyDistributor,
      distributorCompanies,
      distributorContacts,
      customerCompaniesValues,
      customerContactsValues,
      customerContactsOptions,
      customerCompaniesOptions,
      distributorContactsValue,
      opportunityOwner,
      saveStepperExternalValue,
      oppStatus,
      oppStatusDefaultValue,
      handleOnSearch,
      customSelectOnChange,
      handleDeleteModal,
      optionSelectFunction,
      closeOppModalState,
      closingLoading,
      opportunityClosedReasonOptions,
      opportunityFailedReasonOptions,
      handleCloseOppSaveAction,
      handleCloseOppModalState,
    ],
  );

  return <OppContext.Provider value={value}>{children}</OppContext.Provider>;
};

export const useOppContext = () => useContext(OppContext);

export default OppContextProvider;
