import { Col, Row } from 'antd';
import { NavLink } from 'react-router-dom';
import SettingTableContextProvider from 'src/context/settingsTables.context';
import { FC } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Outlet } from 'react-router';
import SettingsTablesInterface from 'src/types/settingsTablesTypes';
import StyleInfinityScroll from 'src/modules/settings/modules/styles/StyleCustomTab.style';
import SubTable from '../commons/subTable';
import SCompanySetupNav from '../companyDetails/styles/companySetupNav.style';
import StyledSettingsTables from './styles/index.styles';
import FlexCol from '../companyDetails/styles/flexCol.style';

interface SetupNavInterface {
  tabs: { label: string; to: string }[];
}

const SetupNav: FC<SetupNavInterface> = ({ tabs }) => (
  <SCompanySetupNav style={{ marginBottom: '0.2rem' }}>
    {tabs.map((link) => (
      <NavLink key={link.to} to={`/settings/${link.to}`}>
        {link.label}
      </NavLink>
    ))}
  </SCompanySetupNav>
);

const SettingsTable: FC<SettingsTablesInterface> = ({
  dataSource,
  onListRender,
  columns,
  userColumns,
  handleFilterChange,
  handleUpdateRows,
  handleDeleteRows,
  newRecord,
  createNewRecord,
  resetNewRecord,
  postData,
  tabs,
  title,
  BodyModal,
  titleModalNew,
  titleOnDelete,
  contentOnDelete,
  columnsTeamMember,
  setCurrentRecord,
  isMembersTableOnly,
  searchPlaceholder,
  clickable,
  selectedRecord,
  onSearch,
  allUsersData,
  setAllUsersData,
  setSelectedRecord,
  ...rest
}) => {
  const handleClickedRecord = (data: any) => {
    setCurrentRecord?.(data as Record<any, any>);
  };

  if (isMembersTableOnly) {
    return (
      <SettingTableContextProvider
        data={dataSource}
        columns={columns}
        userColumns={userColumns}
        selectedRecord={selectedRecord}
        columnsMembers={columnsTeamMember ?? []}
        allUsersData={allUsersData}
        setAllUsersData={setAllUsersData}
        handleUpdateRows={handleUpdateRows}
        setSelectedRecord={setSelectedRecord}
        {...rest}
      >
        <StyledSettingsTables tabs={!!tabs}>
          <div className='tabs-outlet-container'>
            <div className='outlet-container'>
              <Outlet />
            </div>
          </div>
        </StyledSettingsTables>
      </SettingTableContextProvider>
    );
  }

  return (
    <SettingTableContextProvider
      data={dataSource}
      columns={columns}
      userColumns={userColumns}
      selectedRecord={selectedRecord}
      columnsMembers={columnsTeamMember ?? []}
      allUsersData={allUsersData}
      setSelectedRecord={setSelectedRecord}
      {...rest}
    >
      <StyledSettingsTables tabs={!!tabs}>
        <Row>
          <FlexCol
            sm={{ span: 24 }}
            md={{ span: 8 }}
            className='table-container'
            style={{ height: 'calc(-10rem + 100vh)' }}
          >
            <StyleInfinityScroll style={{ height: '100%', padding: '0' }}>
              <SubTable
                data={dataSource ?? []}
                onListRender={onListRender}
                columns={columns}
                onFilterChange={handleFilterChange}
                handleUpdateRows={handleUpdateRows}
                onDeleteClick={handleDeleteRows}
                actionCell={['delete']}
                detailsIcon={<DeleteOutlined />}
                headerActions={['edit']}
                editIcon={<EditOutlined />}
                scroll={{ y: '70vh', x: '100%' }}
                id='contacts-table'
                showOptionFilterLeft={false}
                newRecord={newRecord}
                resetNewRecord={resetNewRecord}
                saveNewRecord={createNewRecord}
                postData={postData}
                clickedRecord={handleClickedRecord}
                addWithModal
                notEditWithModal
                allowGlobalEditActive
                total
                BodyModal={BodyModal}
                titleModalNew={titleModalNew}
                titleOnDelete={titleOnDelete}
                contentOnDelete={contentOnDelete}
                columnFilterShown
                onSearch={onSearch}
                searchPlaceholder={searchPlaceholder}
                clickable
                localGlobalSearch
                rowTooltip
              />
            </StyleInfinityScroll>
          </FlexCol>
          <Col sm={{ span: 24 }} md={{ span: 16 }} className='tabs-outlet-container'>
            <div className='tabs-title-container'>
              {tabs ? (
                <SetupNav tabs={tabs} />
              ) : (
                <h2 style={{ color: '#858585', fontSize: 30, fontWeight: '500' }}>
                  {title}: <span style={{ color: '#4B4B4B' }}>{selectedRecord?.name}</span>
                </h2>
              )}
            </div>
            <div className='outlet-container'>
              <Outlet />
            </div>
          </Col>
        </Row>
      </StyledSettingsTables>
    </SettingTableContextProvider>
  );
};
export default SettingsTable;
