import React from 'react';
import CustomButton from 'src/components/commons/customButton';

interface CreateContactFooterProps {
  onCancelAction?: () => void;
  onSaveAction?: () => void;
  allowCreateContact?: boolean;
}

const CreateContactFooter: React.FC<CreateContactFooterProps> = ({
  onCancelAction,
  onSaveAction,
  allowCreateContact,
}) => (
  <div className='new-company-modal-footer-container'>
    <CustomButton
      type='default'
      onClick={onCancelAction}
      dataTestId='button-cancel-contact-creation'
      noborder={false}
      title='Cancel'
    />

    <CustomButton
      type='primary'
      title='Save'
      disabled={!allowCreateContact}
      tooltipColor='#CCCCCC'
      tooltipText={!allowCreateContact && 'Please fill the required fields before saving'}
      tooltipClassName='custom-tooltip'
      dataTestId='button-save-contact-creation'
      onClick={onSaveAction}
    />
  </div>
);

export default CreateContactFooter;
