import React, { useContext, useEffect, useRef, useMemo } from 'react';
import { Space, Row, Col } from 'antd';
import {
  DoubleRightOutlined,
  CloseCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveFilled,
} from '@ant-design/icons';
import { showFilterOptions, rowsDisplayedOptions } from 'src/services/global-variables';
import imagotipo from 'src/images/logo.png';
import { TableContext } from 'src/context/table.context';
import { CompaniesContext } from 'src/context/companies.context';
import GridPreferences from 'src/components/commons/gridPreferences';
import CompaniesColumns from 'src/utils/tables/companiesTable';
import { ListTypeOptionType } from 'src/types/companiesTypes';
import ExportCompaniesModal from 'src/modules/companies/components/exportCompanies';
import CustomPagination from '../../pagination/index';
import CustomSelect from '../../customSelect';
import {
  StyledTitle,
  StyledSearch,
  StyledSpace,
  StyledRow,
  StyledImage,
} from './CompanySubHeader.styles';
import CustomButton from '../../customButton';

const CompanySubHeader = () => {
  const subHeaderStyles = {
    background: '#0078D4',
    color: 'white',
    height: 'auto',
    padding: '2px 25px',
  };

  const {
    rowsDisplayedFilter,
    handletableMovement,
    moveToRightTable,
    handleColumnSelect,
    selectedColumns2,
    saveGridPreference,
    clearAllFilters,
    sideTableFilterHandlerFunction,
    selectedSideTableFilter,
    orderedColumnsToList,
    companyUseGridPreferences,
    rowsDisplay2,
    setGridPreferenceDefault,
  } = useContext(TableContext);
  const {
    activeStatusFilter,
    deleteRowButtonAction,
    activeFilterValue,
    totalDataSize2,
    setPaginationSizeHandler,
    currentPage,
    activeEditingColumnHandler,
    sendNewPayloadData,
    activeCreateCompany,
    activeEditingColumn,
    setOpenMap,
  } = useContext(CompaniesContext);
  const { filterOptions2 } = CompaniesColumns();
  const allCompanyFilterOptions = useRef<ListTypeOptionType[]>([]);
  const [showExportModal, setShowExportModal] = React.useState(false);

  const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

  const handleFilter = (e: React.FormEvent<HTMLInputElement>) => {
    activeStatusFilter(e);
  };
  const handleRowDisplayed = (e: React.FormEvent<HTMLInputElement>) => {
    const internalData = e as unknown as number;
    setPaginationSizeHandler(internalData);
    rowsDisplayedFilter(internalData);
  };

  const gridPreferencesNames = useMemo(
    () =>
      companyUseGridPreferences?.preferences.map((p) => ({
        value: String(p.id),
        label: String(p.name),
      })),
    [companyUseGridPreferences?.preferences],
  );

  const gridPreferenceActive = useMemo(
    () =>
      gridPreferencesNames?.find(
        (p) => p.value === String(companyUseGridPreferences?.selected?.id),
      ),
    [gridPreferencesNames, companyUseGridPreferences?.selected],
  );

  useEffect(() => {
    if (filterOptions2.current.length > 0) {
      allCompanyFilterOptions.current = [
        {
          label: 'All Companies',
          value: 'all',
        },
        {
          value: 'alphabetical',
          label: 'Alphabetical Order',
        },
      ];

      filterOptions2.current.forEach((element) => {
        const innerLabel = capitalizeFirstLetter(element.label.toLocaleLowerCase());
        allCompanyFilterOptions.current.push({
          label: innerLabel,
          value: element.value,
        });
      });
    }
  }, [filterOptions2.current.length]);

  const iconRotation = moveToRightTable ? 180 : 0;

  return (
    <>
      <StyledSpace
        id='company-sub-header'
        data-testid='company-sub-header-id'
        direction='vertical'
        size='middle'
      >
        <StyledRow
          style={{ width: '100vw', padding: '0 1.5rem' }}
          data-testid='styled-row-sub-header-id'
          align='middle'
          justify='space-between'
        >
          <Col xs={3} sm={6} md={3} lg={2} xl={3}>
            <StyledTitle
              style={{ margin: 0, width: 'max-content' }}
              data-testid='styled-sub-header-title-id'
              level={3}
            >
              Companies
            </StyledTitle>
          </Col>
          <Col xs={24} sm={12} md={4} lg={4}>
            <StyledSearch
              data-testid='company-sub-header-search-id'
              placeholder='Search Companies'
              allowClear
              size='large'
              style={{ width: '100%' }}
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={6}>
            <Row>
              <Col xs={6}>
                <CustomButton
                  dataTestId='new-button-id'
                  type='primary'
                  title='New'
                  style={subHeaderStyles}
                  onClick={activeCreateCompany}
                  ghost
                  defaultMode
                />
              </Col>
              <Col xs={6}>
                <CustomButton
                  dataTestId='export-button-id'
                  type='primary'
                  title='Export'
                  style={subHeaderStyles}
                  onClick={() => setShowExportModal(true)}
                  ghost
                  defaultMode
                />
              </Col>
              <Col xs={6}>
                <CustomButton
                  dataTestId='reimport-button-id'
                  type='primary'
                  title='Re-import'
                  style={subHeaderStyles}
                  ghost
                  defaultMode
                />
              </Col>
              <Col xs={6}>
                <CustomButton
                  dataTestId='map-button-id'
                  type='primary'
                  title='Map'
                  style={subHeaderStyles}
                  onClick={() => setOpenMap((prev) => !prev)}
                  ghost
                  defaultMode
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={2} lg={4} xl={4}>
            <GridPreferences
              gridPreferenceOnChangeHandler={(value) => setGridPreferenceDefault(Number(value))}
              gridPreferenceActive={gridPreferenceActive}
              gridPreferencesNames={gridPreferencesNames}
            />
          </Col>
          <Col xs={24} sm={24} md={3} lg={3} xl={2}>
            <CustomButton
              type='primary'
              danger
              dataTestId='save-grid-preference-button'
              title='Save as New'
              onClick={saveGridPreference}
              style={{
                height: 'auto',
                fontSize: 'inherit',
                padding: '2px 15px',
              }}
            />
          </Col>
          <Col xs={1}>
            <StyledImage
              style={{ margin: 0 }}
              data-testid='logo-image-sub-header-id'
              src={imagotipo}
              preview={false}
              alt='Repfabric'
            />
          </Col>
        </StyledRow>
        <Row justify='space-between' style={{ marginTop: -4 }}>
          <Space size='middle' style={{ paddingLeft: '15px' }}>
            <CustomButton
              type='text'
              dataTestId='move-grid-button-id'
              onlyIcon
              icon={<DoubleRightOutlined rotate={iconRotation} />}
              style={{ padding: '0', height: '33px' }}
              onClick={handletableMovement}
            />
            <CustomSelect
              options={allCompanyFilterOptions.current}
              externalValue={selectedSideTableFilter}
              dataTestId='all-companies-select-id'
              style={{
                display: moveToRightTable ? 'block' : 'none',
                width: '175px',
              }}
              handleChange={sideTableFilterHandlerFunction}
            />
          </Space>
          <Space size='middle' style={{ paddingRight: '15px' }}>
            <CustomSelect
              handleChange={handleFilter}
              options={showFilterOptions as []}
              dataTestId='showFilterOptions-select-id'
              externalValue={activeFilterValue}
            />
            <CustomSelect
              dataTestId='columns-select-id'
              multiSel
              columnSelection
              selectedColumns={selectedColumns2}
              handleMultipleSelect={handleColumnSelect}
              columnsDefault={orderedColumnsToList?.current ?? []}
            />
            <CustomSelect
              handleChange={handleRowDisplayed}
              options={rowsDisplayedOptions as []}
              externalValue={rowsDisplay2}
              dataTestId='rowsDisplayedOptions-select-id'
            />
            <CustomButton
              shape='circle'
              icon={<DeleteOutlined />}
              onClick={deleteRowButtonAction}
              onlyIcon
              tooltipText='Delete Selection'
              tooltipColor='gray'
              danger
              ghost
            />
            <CustomButton
              shape='circle'
              icon={<CloseCircleOutlined />}
              onClick={clearAllFilters}
              onlyIcon
              tooltipText='Remove Filters'
              tooltipColor='gray'
              danger
              ghost
            />
            <CustomButton
              shape='circle'
              icon={<SaveFilled />}
              onClick={() => sendNewPayloadData(false)}
              onlyIcon
              tooltipText='Save Settings'
              tooltipColor='gray'
              danger
              dataTestId='save-settings-button-id'
              ghost
            />
            <CustomButton
              shape='circle'
              icon={<EditOutlined />}
              onClick={activeEditingColumnHandler}
              onlyIcon
              tooltipText='Edit'
              tooltipColor='gray'
              active={activeEditingColumn}
              danger
              ghost
              dataTestId='edit-button-id'
            />
            <CustomPagination totalData={totalDataSize2} currentPage={currentPage} />
          </Space>
        </Row>
      </StyledSpace>
      {showExportModal && (
        <ExportCompaniesModal
          onClose={() => setShowExportModal(false)}
          modalTitle='Export Companies'
          testId='export-companies-modal'
          leftContent={undefined}
          rightContent={undefined}
        />
      )}
    </>
  );
};

export default CompanySubHeader;
