import useEntityAdapter, { OperationSpecs } from 'src/hooks/useEntityAdapter';
import { GET_ALL_STATUS } from '../graphql/queries';
import { UPDATE_STATUS, CREATE_STATUS, REMOVE_STATUS } from '../graphql/mutations';
import { StatusModel } from '../models/status.model';
import { statusAdapter } from '../adapters/status.adapter';

const operations: OperationSpecs = {
  createOne: {
    op: CREATE_STATUS,
  },
  updateOne: {
    op: UPDATE_STATUS,
  },
  deleteOne: {
    op: REMOVE_STATUS,
  },
  fetchAll: {
    op: GET_ALL_STATUS,
    dataPath: 'GetAllOpportunityStatus',
  },
};

const useStatusStore = () => {
  const api = useEntityAdapter<StatusModel>({
    adapters: { entityAdapter: statusAdapter },
    operations,
    isPaginated: true,
    entityName: 'status',
  });

  return api;
};

export default useStatusStore;
