import { AutoComplete } from 'antd';
import { FC, useEffect, useState } from 'react';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import useActivityJournalsStore from 'src/hooks/stores/useActivityJournalsStore';

interface AutocompleteComponentProps {
  options: { value: any; label: string }[];
  onChange?: (e: string) => void;
}

const AutocompleteComponent: FC<AutocompleteComponentProps> = ({ options, onChange }) => {
  const [opt, setOpt] = useState([]);
  const handleSearch = (searching: string) => {
    const filteredOpt = options?.filter((el) =>
      el?.label?.toLowerCase().includes(searching.toLowerCase().trim()),
    );
    setOpt(filteredOpt as []);
  };
  useEffect(() => {
    setOpt(options as []);
  }, [options]);
  return (
    <AutoComplete
      onChange={(_, selectedOpt) => onChange?.(selectedOpt as any)}
      onSearch={handleSearch}
      options={opt}
      showArrow
      placeholder='Select options...'
    />
  );
};

const Purpose: React.FC = () => {
  const { getTypes, reset } = useActivityJournalsStore();

  useEffect(reset, []);

  const opt = getTypes?.map((el) => ({
    value: el?.name,
    label: el?.name,
    toSend: el?.id,
  }));

  const fields: FieldType<any>[] = [
    {
      name: 'purpose',
      label: 'Purpose',
      type: 'text',
      maxLength: 220,
      span: 24,
      autoFocus: true,
    },
    {
      name: 'type',
      label: 'Type',
      type: 'select',
      options: opt,
      render: () => <AutocompleteComponent options={opt} />,
      span: 24,
    },
  ];

  return <GenericForm fields={fields} />;
};

export default Purpose;
