import { gql } from '@apollo/client';

export const CREATE_OPPORTUNITY_TYPE = gql`
  mutation CreateOpportunityType($createOpportunityTypeInput: CreateTypeInput!) {
    CreateOpportunityType(createOpportunityTypeInput: $createOpportunityTypeInput) {
      boolean
      id
      name
    }
  }
`;

export const UPDATE_OPPORTUNITY_TYPE = gql`
  mutation UpdateOpportunityType($updateOpportunityTypeInput: UpdateTypeInput!) {
    UpdateOpportunityType(updateOpportunityTypeInput: $updateOpportunityTypeInput) {
      boolean
      id
      name
    }
  }
`;

export const REMOVE_OPPORTUNITY_TYPE = gql`
  mutation RemoveOpportunityType($id: Int!) {
    RemoveOpportunityType(id: $id)
  }
`;
