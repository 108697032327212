import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { PhoneModel } from 'src/models';
import { optional } from './common';
import phoneTypeAdapter from './phoneType.adapter';

const phoneAdapter: AdapterFunc<GQL.PhoneDbModel, PhoneModel> = (input) => ({
  id: input.id,
  phone: input.phone!,
  areaCode: input.areaCode!,
  extension: input.extension!,
  countryCode: input.countryCode!,
  phoneType: optional(phoneTypeAdapter, input.phoneType),
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
});

export default phoneAdapter;
