import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Row, Col, Input } from 'antd';
import React from 'react';
import CustomToolTip from '../customPopover';
import GridPreferences from '../gridPreferences';
import StyledSubHeaderAddButton from '../subTableV1/styles/subheaderAddButton';
import { StyledRow, StyledSpace, StyledTitle } from './styles/subheader.styles';

const SubHeaderComponent = (props: {
  totalButton: React.ReactNode | string;
  title: string;
  handleAdd?: () => void;
  hasSearch?: boolean;
  hasGridPreferences?: boolean;
  hasAddButton?: boolean;
  actions?: React.ReactNode[];
  tooltipText?: string;
}) => {
  const {
    totalButton,
    title,
    hasSearch,
    hasGridPreferences,
    actions,
    hasAddButton,
    handleAdd,
    tooltipText,
  } = props;
  return (
    <StyledSpace
      data-testid='opportunities-sub-header-id'
      direction='vertical'
      size='middle'
      style={{ marginBottom: '10px' }}
    >
      <Row style={{ padding: '0 1.5rem 0 0.7rem' }}>{totalButton}</Row>

      <StyledRow
        style={{ padding: '0 1.5rem' }}
        data-testid='styled-row-sub-header-id'
        align='middle'
        justify='space-between'
      >
        <Col style={{ maxWidth: 'unset !important', display: 'flex', gap: '10px' }}>
          <StyledTitle style={{ width: '90%', margin: 0 }} level={3}>
            {title}
          </StyledTitle>
          {hasAddButton && (
            <CustomToolTip title={tooltipText ?? ''}>
              <StyledSubHeaderAddButton
                type='primary'
                shape='circle'
                active={false}
                icon={<PlusOutlined />}
                onClick={handleAdd}
              />
            </CustomToolTip>
          )}
          {hasSearch && (
            <Input
              prefix={<SearchOutlined />}
              placeholder='Search'
              allowClear
              size='middle'
              style={{ width: '100%' }}
            />
          )}
          {hasGridPreferences && (
            <GridPreferences
              style={{ width: '100%' }}
              gridPreferenceOnChangeHandler={() => {}}
              defaultValue
            />
          )}
        </Col>
        <Col style={{ maxWidth: 'unset !important', display: 'flex', gap: '10px' }}>
          {actions?.map((e) => e)}
        </Col>
      </StyledRow>
    </StyledSpace>
  );
};

export default SubHeaderComponent;
