import { Route, Routes } from 'react-router';
import NotFoundPage from 'src/components/commons/notFound';
import UsersAndMembers from 'src/components/settingsTables/userAndMembers';
import SubTableRoutes from './modules/subTables';
import ApplicationSettingsRoutes from './modules/applicationSettings';
import PrivateTeam from './modules/privateTeams';
import Divisions from './modules/divisions';
import SalesTeams from './modules/salesTeams';

const SettingRoutes = () => (
  <Routes>
    <Route>
      <Route path='subtables/*' element={<SubTableRoutes />} />
      <Route path='application_settings/*' element={<ApplicationSettingsRoutes />} />
      <Route path='divisions/*' element={<Divisions />}>
        <Route index path='' element={<UsersAndMembers />} />
      </Route>
      <Route path='private_teams/*' element={<PrivateTeam />}>
        <Route index path='' element={<UsersAndMembers />} />
      </Route>
      <Route path='sales_teams/*' element={<SalesTeams />}>
        <Route index path='' element={<UsersAndMembers />} />
        <Route path='customer_split' element={<h2>table</h2>} />
        <Route path='internal_split' element={<h2>table</h2>} />
      </Route>
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  </Routes>
);

export default SettingRoutes;
