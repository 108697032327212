import { gql } from '@apollo/client';

export const UPDATE_TERRITORY = gql`
  mutation UpdateTerritory($updateTerritoryInput: UpdateTerritoryInput!) {
    UpdateTerritory(updateTerritoryInput: $updateTerritoryInput) {
      id
      name
    }
  }
`;

export const CREATE_TERRITORY = gql`
  mutation CreateTerritory($createTerritoryInput: CreateTerritoryInput!) {
    CreateTerritory(createTerritoryInput: $createTerritoryInput) {
      id
      name
    }
  }
`;

export const REMOVE_TERRITORY = gql`
  mutation RemoveTerritory($id: Int!) {
    RemoveTerritory(id: $id)
  }
`;
