import useEntityAdapter, { OperationSpecs } from 'src/hooks/useEntityAdapter';
import { GET_ALL_LEAD_SOURCES } from '../graphql/queries';
import { UPDATE_LEAD_SOURCE, CREATE_LEAD_SORCE, REMOVE_LEAD_SOURCE } from '../graphql/mutations';
import { LeadSourceModel } from '../models/leadSource.model';
import { leadSourceAdapter } from '../adapters/leadSource.adapter';

const operations: OperationSpecs = {
  createOne: {
    op: CREATE_LEAD_SORCE,
  },
  updateOne: {
    op: UPDATE_LEAD_SOURCE,
  },
  deleteOne: {
    op: REMOVE_LEAD_SOURCE,
  },
  fetchAll: {
    op: GET_ALL_LEAD_SOURCES,
    dataPath: 'GetAllLeadSources',
  },
};

const useLeadSourceStore = () => {
  const api = useEntityAdapter<LeadSourceModel>({
    adapters: { entityAdapter: leadSourceAdapter },
    operations,
    isPaginated: true,
    entityName: 'LeadSource',
  });

  return api;
};

export default useLeadSourceStore;
