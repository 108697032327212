import { useLazyQuery } from '@apollo/client';
import { GET_ALL_ADITIONAL_FIELDS, GET_ALL_CONTACT_ADITIONAL_FIELDS } from '../graphql/queries';
import { GetAllCustomFields, GetAllContactsCustomFields } from '../types/customFieldsTypes';

const useCustomFieldCard = () => {
  const [getAllCustomField, getAllCustomFieldResponse] = useLazyQuery<GetAllCustomFields>(
    GET_ALL_ADITIONAL_FIELDS,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [getAllContactCustomField, getAllContactCustomFieldResponse] =
    useLazyQuery<GetAllContactsCustomFields>(GET_ALL_CONTACT_ADITIONAL_FIELDS, {
      fetchPolicy: 'network-only',
    });

  const fetchCustomFields = (start: number, renderLen: number) =>
    getAllCustomField({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
      },
    });

  const fetchContactCustomFields = (id: number) => {
    getAllContactCustomField({
      variables: {
        contactId: id,
      },
    });
  };

  return {
    getAllCustomFieldResponse,
    fetchCustomFields,
    fetchContactCustomFields,
    getAllContactCustomFieldResponse,
  };
};

export default useCustomFieldCard;
