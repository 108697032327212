import { gql } from '@apollo/client';

export const CREATE_SALES_TEAM = gql`
  mutation CreateSalesTeam($createSalesTeamInput: CreateSalesTeamInput!) {
    CreateSalesTeam(createSalesTeamInput: $createSalesTeamInput) {
      id
      name
      companySplit
      specifierSplit
      type {
        id
        name
        description
        createdAt
        updatedAt
      }
      companySplitType {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const REMOVE_SALES_TEAM = gql`
  mutation RemoveSalesTeam($id: Int!) {
    RemoveSalesTeam(id: $id)
  }
`;

export const REMOVE_SALES_TEAM_MEMBER = gql`
  mutation RemoveSalesTeamMember($users: [Int!]!, $salesTeamId: Int!, $tenantId: Int!) {
    RemoveSalesTeamMember(users: $users, salesTeamId: $salesTeamId, tenantId: $tenantId)
  }
`;

export const UPDATE_SALES_TEAM = gql`
  mutation UpdateSalesTeam($updateSalesTeamInput: UpdateSalesTeamInput!) {
    UpdateSalesTeam(updateSalesTeamInput: $updateSalesTeamInput) {
      id
      name
      companySplit
      specifierSplit
      type {
        id
        name
        description
        createdAt
        updatedAt
      }
      companySplitType {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const REGISTER_SALES_TEAM_MEMBER = gql`
  mutation RegisterSalesTeamUser($createSalesTeamInput: RegisterSalesTeamUserInput!) {
    registerSalesTeamUser(createSalesTeamInput: $createSalesTeamInput) {
      id
      salesTeam
      user {
        id
        firstName
      }
    }
  }
`;
