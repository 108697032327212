import React, { useEffect, useMemo, useState } from 'react';
import useCompanyHeaders from 'src/hooks/useCompanyHeaders';
import { useLocation } from 'react-router';
import { CompaniesHeaderType2, CompanyHeaderOptionsType2 } from 'src/types/companiesTypes';
import { CreateEmailInput } from 'src/graphql/schema-types';
import { useContactContext } from 'src/context/contact.context';
import { useCompanyContext } from 'src/context/company.context';

export type Props = {
  value: CreateEmailInput[];
  onChange: (value: CreateEmailInput[]) => void;
  readOnly?: boolean;
  formName?: string;
};

const createNewEmail = () => ({ email: '' } as unknown as CreateEmailInput);

const getInitialValue = (value?: CreateEmailInput[]) => {
  if (!value) {
    return [createNewEmail()];
  }
  if (!value?.length) {
    value.push(createNewEmail());
  }
  return value;
};

const useEmailsSubForm = ({ value, onChange, readOnly, formName }: Props) => {
  const location = useLocation();
  const [emails, setEmails] = useState(getInitialValue(value));
  const headers = useCompanyHeaders();
  const options = useMemo(
    () =>
      headers
        ?.find(({ dataIndex }: CompaniesHeaderType2) => dataIndex === 'mainEmail.emailType')
        ?.options?.map(({ id, name }: CompanyHeaderOptionsType2) => ({
          value: id,
          label: name,
        })) ?? [],
    [headers],
  );
  const { saveEmailsToDelete } = useContactContext();
  const companyContext = useCompanyContext();
  const isCompany = location?.pathname?.includes('companies');

  useEffect(() => {
    setEmails(getInitialValue(value));
  }, [value]);

  useEffect(() => {
    if (value && value[0] && !value[0].emailType && options?.[0]?.value) {
      value[0].emailType = Number(options?.[0]?.value);
      setEmails(value);
    }
  }, [value, options]);

  const handleEmailChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const email = ev.target.value;
    const index = Number(ev.target.dataset.index);
    const newEmails = [...emails];
    newEmails[index].email = email;
    setEmails(newEmails);
    onChange(newEmails);
  };

  const handleEmailTypeChange = (index: number, val: number) => {
    const newEmails = [...emails];
    newEmails[index].emailType = val;
    setEmails(newEmails);
    onChange(newEmails);
  };

  const handleAddEmail = (index: number) => {
    const newEmails = [...emails];
    newEmails.splice(index + 1, 0, createNewEmail());
    setEmails(newEmails);
    onChange(newEmails);
  };

  const handleRemoveEmail = (id: number, index: number, type?: string) => {
    const newEmails = [...emails];
    if (id) {
      const innerIndex = newEmails.findIndex((email) => email.id === id);
      newEmails.splice(innerIndex, 1);
      setEmails(newEmails);
      onChange(newEmails);
      if (isCompany) {
        companyContext.saveCompanyEmailsToDelete([id]);
      } else {
        saveEmailsToDelete([id], type);
      }
    } else {
      newEmails.splice(index, 1);
      setEmails(newEmails);
      onChange(newEmails);
    }
  };

  return {
    emails,
    options,
    setEmails,
    handleAddEmail,
    handleRemoveEmail,
    handleEmailChange,
    handleEmailTypeChange,
    readOnly,
    formName,
  };
};

export default useEmailsSubForm;
