import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { LocationModel } from 'src/models';

const locationAdapter: AdapterFunc<GQL.LocationModel, LocationModel> = (input) => ({
  lat: input.lat,
  long: input.long,
});

export default locationAdapter;
