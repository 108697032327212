import { gql } from '@apollo/client';

export const EDIT_ADDITIONAL_FIELDS = gql`
  mutation UpdateContactAdditionalField(
    $updateContactAdditionalFieldsContactInput: UpdateContactAdditionalFieldsContactInput!
  ) {
    UpdateContactAdditionalField(
      updateContactAdditionalFieldsContactInput: $updateContactAdditionalFieldsContactInput
    ) {
      id
      value
      additionalField {
        description
        id
        module
        name
        dataStructure
      }
    }
  }
`;

export const CREATE_ADDITIONAL_FIELDS = gql`
  mutation CreateContactAdditionalFieldsContact(
    $createContactAdditionalFieldsContactInput: CreateContactAdditionalFieldsContactInput!
  ) {
    CreateContactAdditionalFieldsContact(
      createContactAdditionalFieldsContactInput: $createContactAdditionalFieldsContactInput
    ) {
      additionalField {
        id
      }
      id
      value
      contact {
        id
      }
    }
  }
`;
