import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { DivisionModel } from 'src/models';

const divisionAdapter: AdapterFunc<GQL.DivisionDbModel, DivisionModel> = (input) => ({
  id: input.id,
  name: input.name!,
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
  description: input.description!,
});

export default divisionAdapter;
