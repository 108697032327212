import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { SortModel } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import {
  AdditionalFieldModel,
  CreateAdditionalFieldInput,
  AdditionalFieldModuleEnum,
  AdditionalFieldDataTypeEnum,
  InputMaybe,
  OptionStructureType,
  Scalars,
} from 'src/graphql/schema-types';
import handleError from 'src/utils/functions/handleError';
import { notification } from 'antd';
import {
  CREATE_RELATED_COMPANY,
  UPDATE_RELATED_COMPANY,
  DELETE_RELATED_COMPANY,
} from '../graphql/mutations';
import COLUMNS, { formFieldTypes } from '../constants';
import useCustomFieldsStore from './useCustomFieldsStore';

const useCustomFieldsTable = () => {
  const [deleting, setDeleting] = useState(false);
  const [dataModule, setDataModule] = useState<{ value: string; label: string }[]>();
  const { getAll, getAllDataModule, loading, entities } = useCustomFieldsStore();

  const [create] = useMutation(CREATE_RELATED_COMPANY);
  const [update] = useMutation(UPDATE_RELATED_COMPANY);
  const [remove] = useMutation(DELETE_RELATED_COMPANY);
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState(COLUMNS);
  const [newRecord, setNewRecord] = useState<CreateAdditionalFieldInput>({
    name: '',
    module: AdditionalFieldModuleEnum.Company,
    dataType: formFieldTypes.text as AdditionalFieldDataTypeEnum,
    dataStructure: {
      options: null,
      optionStructure: null,
      required: false,
      selected: null,
    },
  });

  const uploadData: FetchFunc = ({ start, renderLen }) => {
    const variables = {
      criteria: {
        pagination: {
          from: start,
          size: renderLen,
        },
      },
      searchFields: {
        ids: null,
        names: null,
        descriptions: null,
        enabled: null,
      },
    };

    return getAll(variables).then((res) => ({
      results:
        res.data?.GetAllAdditionalFields?.results?.map((additionalField) => ({
          ...additionalField,
          dataType: formFieldTypes[additionalField.dataType as keyof typeof formFieldTypes],
        })) || [],
      total: res.data?.GetAllAdditionalFields?.total || 0,
    }));
  };

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(uploadData);

  useEffect(() => {
    getAllDataModule().then(({ data: { GetAllDataModule } }) =>
      setDataModule(GetAllDataModule.results),
    );
  }, []);

  const autocompleteFieldSearch = (searchField: string, search: string) => {
    let filterData = [];
    let mapData = [];

    if (searchField.includes('.')) {
      const keys = searchField.split('.');
      const [keyParent, keyPropertyParent] = keys;

      if (searchField === 'dataStructure.options') {
        filterData = dataSource.filter((item: any) => {
          if (item[keyParent][keyPropertyParent] && item[keyParent][keyPropertyParent].length) {
            const arrayJoined = item[keyParent][keyPropertyParent]
              .map((option: { id: string; name: string }) => option?.name?.toLocaleLowerCase())
              .join(' ');
            return arrayJoined.includes(search.toLocaleLowerCase());
          }

          return false;
        });
      } else if (searchField === 'dataStructure.selected') {
        filterData = dataSource.filter((item: any) => {
          if (item[keyParent][keyPropertyParent] && item[keyParent][keyPropertyParent].length) {
            const arrayJoined: string = item[keyParent][keyPropertyParent]
              .map((option: [string]) => option)
              .join(' ');

            return arrayJoined.toLocaleLowerCase().includes(search.toLocaleLowerCase());
          }

          return false;
        });
      } else if (searchField === 'dataStructure.required') {
        filterData = dataSource.filter((item: any) => {
          const arrayJoined = item[keyParent][keyPropertyParent] ? 'true' : 'false';
          return arrayJoined.includes(search.toLocaleLowerCase());
        });
      }
    } else {
      filterData = dataSource.filter((item: any) =>
        item[searchField].toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      );
    }

    if (filterData.length) {
      mapData = filterData.map((result: any) => {
        if (searchField.includes('.')) {
          let keys;
          let item;

          if (searchField === 'dataStructure.options') {
            keys = searchField.split('.');
            const [keyParent, keyPropertyParent] = keys;
            item = result[keyParent][keyPropertyParent]
              .map((option: { id: string; name: string }) => option?.name?.toLocaleLowerCase())
              .join(' ');
          } else if (searchField === 'dataStructure.selected') {
            keys = searchField.split('.');
            const [keyParent, keyPropertyParent] = keys;
            item = result[keyParent][keyPropertyParent].map((option: [string]) => option).join(' ');
          } else if (searchField === 'dataStructure.required') {
            keys = searchField.split('.');
            const [keyParent, keyPropertyParent] = keys;
            item = result[keyParent][keyPropertyParent] ? 'true' : 'false';
          }

          return {
            value: item,
            label: item,
          };
        }

        return {
          value: result[searchField],
          label: result[searchField],
        };
      });

      return Promise.resolve({
        results: filterData,
        filter: mapData,
      });
    }

    return Promise.resolve({
      results: [],
      filter: [],
    });
  };

  const filterByModule = (module: string) => {
    if (!module) {
      setDataSource([
        ...(entities.map((additionalField) => ({
          ...additionalField,
          dataType: formFieldTypes[additionalField.dataType as keyof typeof formFieldTypes],
        })) || []),
      ]);
      return;
    }

    const filteredDataSource =
      entities
        .filter((item) => item?.module?.toLowerCase() === module.toLowerCase())
        .map((additionalField) => ({
          ...additionalField,
          dataType: formFieldTypes[additionalField.dataType as keyof typeof formFieldTypes],
        })) || [];
    setDataSource([...filteredDataSource]);
  };

  const postData = (args: Record<string, unknown>) => {
    const dataPath = [args?.dataIndex, args?.dataKey].flat().filter(Boolean).join('.');

    if (args.dataIndex === 'dataStructure' && args.dataKey === 'required') {
      setNewRecord((prev) => ({
        ...prev,
        dataStructure: {
          ...prev.dataStructure,
          required: args?.value as unknown as boolean,
        },
      }));
      return;
    }

    if (args.dataIndex === 'dataStructure' && args.dataKey === 'options') {
      setNewRecord((prev) => ({
        ...prev,
        dataStructure: {
          ...prev.dataStructure,
          options: args?.value as unknown as InputMaybe<Array<OptionStructureType>>,
        },
      }));
      return;
    }

    if (args.dataIndex === 'dataStructure' && args.dataKey === 'selected') {
      setNewRecord((prev) => ({
        ...prev,
        dataStructure: {
          ...prev.dataStructure,
          selected: args?.value as unknown as InputMaybe<
            Array<InputMaybe<Scalars['String']['input']>>
          >,
        },
      }));
      return;
    }

    setNewRecord((prev) => ({
      ...prev,
      [dataPath]: args?.value,
      autoSave: !!args?.autoSave,
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
      module: AdditionalFieldModuleEnum.Company,
      dataType: formFieldTypes.text as AdditionalFieldDataTypeEnum,
      dataStructure: {
        options: null,
        optionStructure: null,
        required: false,
        selected: null,
      },
    });
  };

  const convertDataTypeToDatabase = (dataType: string) => {
    if (dataType === formFieldTypes.text) {
      return AdditionalFieldDataTypeEnum.ShortText;
    }

    if (dataType === formFieldTypes.checkbox) {
      return AdditionalFieldDataTypeEnum.Checkbox;
    }

    if (dataType === formFieldTypes.decimal) {
      return AdditionalFieldDataTypeEnum.Decimal;
    }

    if (dataType === formFieldTypes.integer) {
      return AdditionalFieldDataTypeEnum.Integer;
    }

    if (dataType === formFieldTypes.date) {
      return AdditionalFieldDataTypeEnum.Date;
    }

    if (dataType === formFieldTypes.date_time) {
      return AdditionalFieldDataTypeEnum.DateTime;
    }

    if (dataType === formFieldTypes.textarea) {
      return AdditionalFieldDataTypeEnum.LargeText;
    }

    if (dataType === formFieldTypes.hyperlink) {
      return AdditionalFieldDataTypeEnum.Hyperlink;
    }

    if (dataType === formFieldTypes.dropdown) {
      return AdditionalFieldDataTypeEnum.Dropdown;
    }

    if (dataType === formFieldTypes.multi_select_dropdown) {
      return AdditionalFieldDataTypeEnum.MultiselectDropdown;
    }

    return false;
  };

  const createNewRecord = async () => {
    const { name, dataType, module, dataStructure } = newRecord;

    const dataTypeDatabase = convertDataTypeToDatabase(dataType);

    if (name === '' || !dataTypeDatabase || !module) {
      return;
    }

    if (dataType === formFieldTypes.checkbox) {
      dataStructure.selected = [`${dataStructure.selected}`];
    }

    if (
      dataType === formFieldTypes.integer ||
      dataType === formFieldTypes.dropdown ||
      dataType === formFieldTypes.multi_select_dropdown
    ) {
      dataStructure.optionStructure = {
        id: 'id',
        name: 'name',
      };

      const newOptions = dataStructure.options as unknown as string;
      dataStructure.options = newOptions
        ? newOptions.split(',').map((option) => ({
            id: option.trim(),
            name: option.trim(),
          }))
        : [];
    }

    await create({
      variables: {
        createAdditionalFieldInput: {
          name: capitalizeFirstWord(name),
          dataType: dataTypeDatabase,
          module,
          dataStructure,
        },
      },
    })
      .then((additionalField) => {
        setDataSource([
          {
            ...additionalField.data?.CreateAdditionalField,
            dataType:
              formFieldTypes[
                additionalField.data?.CreateAdditionalField.dataType as keyof typeof formFieldTypes
              ],
          },
          ...dataSource,
        ]);
        notification.success({
          message: 'Custom fields was created',
          key: 'custom-fields-deleted',
        });
      })
      .catch((error) => {
        handleError(error);
        throw error;
      });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: AdditionalFieldModel[]) => {
    rows.forEach((row) => {
      const { id, name, dataType, dataStructure, module } = row;
      update({
        variables: {
          updateAdditionalFieldInput: {
            id,
            name,
            dataType,
            dataStructure: {
              selected: dataStructure.selected,
              required: dataStructure.required,
              options: dataStructure.options,
              optionsStructure: dataStructure.optionsStructure,
            },
            module,
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: AdditionalFieldModel[]) => {
    setDeleting(true);
    await Promise.all(
      rows.map((row) =>
        remove({ variables: { removeAdditionalFieldId: row.id } })
          .then(() => {
            setDeleting(false);
            notification.success({
              message: 'Custom fields was deleted',
              key: 'custom-fields-deleted',
            });
          })
          .catch((error) => {
            setDeleting(false);
            handleError(error);
            throw error;
          }),
      ),
    );

    reset();
  };

  const handleClearFilter = () => {
    reset();
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    onListRender,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    spinning: loading || deleting,
    postData,
    newRecord,
    createNewRecord,
    handleUpdateRows,
    resetNewRecord,
    dataSource,
    handleClearFilter,
    handleDeleteRows,
    dataModule,
    filterByModule,
  };
};

export default useCustomFieldsTable;
