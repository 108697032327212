/* eslint-disable @typescript-eslint/no-explicit-any */
import { CompaniesHeaderType2, FormElementsOptions } from 'src/types/companiesTypes';

const isRequired = (formElementsOptions: FormElementsOptions[] | undefined, name: string) =>
  formElementsOptions?.find((option) => option.name === name)?.required ?? false;

export const getOptions = (formElementsOptions: FormElementsOptions[] | undefined, name: string) =>
  formElementsOptions?.find((option) => option.name === name)?.options ?? [];

export const buildOptions = (headers: CompaniesHeaderType2[] | undefined, name: string) => {
  const options = headers?.find((header) => header.dataIndex === name)?.options ?? [];

  return options.map((option) => ({
    label: option.name || option.productName,
    value: option.id,
  }));
};

export const parseEventFromHeader = (value: any, fromHeaderName: string) => {
  const event = {
    fromHeaderName,
    value,
  } as unknown as React.FormEvent<HTMLInputElement>;

  return event;
};

export const isNil = (value: any) =>
  value === null || value === undefined || !String(value).trim() || Number.isNaN(value);

export const omitNil = (obj: any) => {
  const clean = { ...obj };

  Object.keys(clean).forEach((key) => {
    if (isNil(clean[key])) {
      delete clean[key];
    }
  });

  return clean;
};

export const mapObject = (obj: any, map: Record<string, { path: string; func?: any }> | string) => {
  if (typeof map === 'string') {
    return obj[map];
  }

  const result: any = {};

  const getValue = (path: string) => {
    const steps = path.split('.');
    let objRef = obj;

    steps.forEach((step) => {
      objRef = objRef[step];
    });

    return objRef;
  };

  const setValue = (path: string, value: any) => {
    const steps = path.split('.');
    let resRef = result;

    steps.forEach((step, index) => {
      if (index === steps.length - 1) {
        resRef[step] = value;
      } else {
        resRef = resRef[step];
      }
    });
  };

  Object.keys(map).forEach((key) => {
    const steps = key.split('.');
    steps.forEach((step) => {
      if (!result[step]) {
        result[step] = {};
      }
    });
  });

  Object.keys(map).forEach((key) => {
    let value = getValue(map[key].path);

    if (map[key].func) {
      value = map[key].func(value);
    }

    setValue(key, value);
  });

  return result;
};

export const parseAddress = (data: any[]) => {
  const schema = {
    address: { path: 'addressData.address' },
    addressType: { path: 'addressData.addressType.id', func: Number },
    region: { path: 'addressData.region.id' },
    territory: { path: 'addressData.territory.id' },
    country: { path: 'addressData.country.id' },
    city: { path: 'addressData.city' },
    state: { path: 'addressData.state.id' },
    zipCode: { path: 'addressData.zipCode' },
    pobox: { path: 'addressData.pobox' },
    geopositionLongitude: { path: 'addressData.geopositionLongitude' },
    geopositionLatitude: { path: 'addressData.geopositionLatitude' },
  };

  return data.map((item) => omitNil(mapObject(item, schema)));
};

export const parsePhone = (data: any[]) => {
  const schema = {
    phoneType: { path: 'phoneType.value', func: Number },
    countryCode: { path: 'countryCode' },
    areaCode: { path: 'areaCode' },
    phone: { path: 'phone' },
    extension: { path: 'phoneExt', func: Number },
  };

  return data.map((item) => omitNil(mapObject(item, schema)));
};

export const parseOption = (data: { id: string; name: string }) => Number(data.id);

export const parseOptions = (data: { id: string; name: string }[]) => data.map(parseOption);

export const parseEmails = (data: { emailData: string; emailType: { value: number } }[]) => {
  const schema = {
    email: { path: 'emailData' },
    emailType: { path: 'emailType.value', func: Number },
  };

  return data.map((item) => omitNil(mapObject(item, schema)));
};

export default isRequired;
