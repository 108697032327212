import { gql } from '@apollo/client';

const GET_ALL_BUYING_GROUP = gql`
  query GetAllBuyGroup2($criteria: BuyingGroupCriteriaInput!, $searchFields: FindBuyingGroupInput) {
    GetAllBuyGroup2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
        description
      }
      total
    }
  }
`;

export default GET_ALL_BUYING_GROUP;
