import { Option, SubTableColumn } from 'src/components/commons/subTable/types';

export const COLUMNS_TABLE: SubTableColumn[] = [
  {
    title: 'NAME',
    dataIndex: 'firstName',
    width: 200,
    minWidth: 200,
    dataType: 'string',
    editable: true,
    hidden: false,
    dataStructure: 'string',
    options: [],
    required: true,
  },
  {
    title: 'LAST NAME',
    dataIndex: 'lastName',
    width: 200,
    minWidth: 200,
    dataType: 'string',
    editable: true,
    hidden: false,
    dataStructure: 'string',
    options: [],
    required: true,
  },
  {
    title: 'JOB TITLE',
    dataIndex: 'title',
    width: 200,
    minWidth: 200,
    dataType: 'string',
    editable: true,
    hidden: false,
    dataStructure: 'string',
    options: [],
    required: true,
  },
  {
    key: 'name',
    title: 'COMPANY',
    dataIndex: 'businessInfo.company',
    width: 200,
    minWidth: 200,
    dataType: 'string',
    editable: true,
    hidden: false,
    options: [],
    required: true,
  },
  {
    key: 'phone',
    title: 'PHONE',
    dataIndex: 'personalInfo',
    width: 200,
    minWidth: 200,
    dataType: 'string',
    editable: true,
    hidden: false,
    dataStructure: 'string',
    options: [],
    required: true,
  },
  {
    key: 'email',
    title: 'EMAIL',
    dataIndex: 'personalInfo',
    width: 200,
    minWidth: 200,
    dataType: 'string',
    editable: true,
    hidden: false,
    dataStructure: 'string',
    options: [],
    required: true,
  },
];

export const COLUMNS_TO_SELECT_OPTIONS: Option[] = COLUMNS_TABLE.map((column) => ({
  label: column.title,
  value: [column.dataIndex, column.key].filter(Boolean).join('.'),
}));

export const COLUMNS_TABLE_SELECTED: string[] = ['name', 'job_title', 'company', 'phone', 'email'];

export const CONTACT_FILTERS = [
  {
    label: 'Alphabetich order',
    value: 'alphabetich_order',
    type: 'radio',
    options: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((letter) => ({
      label: letter,
      value: letter,
    })),
  },
  {
    label: 'Company',
    value: 'company',
    type: 'checkbox',
    options: [
      {
        label: 'Company 1',
        value: 'company_1',
      },
      {
        label: 'Company 2',
        value: 'company_2',
      },
      {
        label: 'Company 3',
        value: 'company_3',
      },
    ],
  },
  {
    label: 'Country',
    value: 'country',
    type: 'checkbox',
    options: [
      {
        label: 'Country 1',
        value: 'country_1',
      },
      {
        label: 'Country 2',
        value: 'country_2',
      },
      {
        label: 'Country 3',
        value: 'country_3',
      },
    ],
  },
  {
    label: 'Company Type',
    value: 'company_type',
    type: 'checkbox',
    options: [
      {
        label: 'Company Type 1',
        value: 'company_type_1',
      },
      {
        label: 'Company Type 2',
        value: 'company_type_2',
      },
      {
        label: 'Company Type 3',
        value: 'company_type_3',
      },
    ],
  },
  {
    label: 'Contact Group',
    value: 'contact_group',
    type: 'checkbox',
    options: [
      {
        label: 'Contact Group 1',
        value: 'contact_group_1',
      },
      {
        label: 'Contact Group 2',
        value: 'contact_group_2',
      },
      {
        label: 'Contact Group 3',
        value: 'contact_group_3',
      },
    ],
  },
  {
    label: 'Sales Team',
    value: 'sales_team',
    type: 'checkbox',
    options: [
      {
        label: 'Sales Team 1',
        value: 'sales_team_1',
      },
      {
        label: 'Sales Team 2',
        value: 'sales_team_2',
      },
      {
        label: 'Sales Team 3',
        value: 'sales_team_3',
      },
    ],
  },
  {
    label: 'Product Interest',
    value: 'product_interest',
    type: 'checkbox',
    options: [
      {
        label: 'Product Interest 1',
        value: 'product_interest_1',
      },
      {
        label: 'Product Interest 2',
        value: 'product_interest_2',
      },
      {
        label: 'Product Interest 3',
        value: 'product_interest_3',
      },
    ],
  },
];

export const COLUMNS_EXPANDED: SubTableColumn[] = [
  {
    title: 'NAME',
    dataIndex: 'firstName',
    width: 200,
    minWidth: 200,
    dataType: 'string',
    editable: true,
    hidden: false,
    dataStructure: 'string',
    options: [],
    required: true,
  },
  {
    title: 'JOB TITLE',
    dataIndex: 'title',
    width: 200,
    minWidth: 200,
    dataType: 'string',
    editable: true,
    hidden: false,
    dataStructure: 'string',
    options: [],
    required: true,
  },
  {
    key: 'name',
    title: 'COMPANY',
    dataIndex: 'businessInfo.company',
    width: 200,
    minWidth: 200,
    dataType: 'string',
    editable: true,
    hidden: false,
    options: [],
    required: true,
  },
  {
    key: 'phone',
    title: 'PHONE',
    dataIndex: 'personalInfo',
    width: 200,
    minWidth: 200,
    dataType: 'string',
    editable: true,
    hidden: false,
    dataStructure: 'string',
    options: [],
    required: true,
  },
  {
    key: 'email',
    title: 'EMAIL',
    dataIndex: 'personalInfo',
    width: 200,
    minWidth: 200,
    dataType: 'string',
    editable: true,
    hidden: false,
    dataStructure: 'string',
    options: [],
    required: true,
  },
];
