/* eslint-disable import/no-named-as-default */
import { Suspense, useEffect } from 'react';
import { Routes, Route } from 'react-router';
import { Layout } from 'antd';
import CustomHeader from './components/commons/customHeader';
import GlobalStyles from './styles/global';
import NotFoundPage from './components/commons/notFound';
import ContactRoutes from './modules/contacts';
import SettingRoutes from './modules/settings';
import { CustomLoadingModal } from './components/commons/customModal';
import UserSetupRoutes from './modules/users';
import TasksRoutes from './modules/tasks';
import ActivityRoutes from './modules/activity';
import OpportunitiesRoutes from './modules/opportunities';
import QuotesRoutes from './modules/quotes';
import MessagesRoutes from './modules/messages';
import CompaniesRoutes from './modules/companies';
import LandingRoutes from './modules/landing';
import MapModal from './components/commons/mapModal';
import 'react-quill/dist/quill.snow.css';
import useApolloSubscriptionController from './hooks/useApolloSubscriptionController';

const App = () => {
  const { Content } = Layout;
  useApolloSubscriptionController();
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Layout>
      <GlobalStyles />
      <CustomHeader />
      <Content>
        <Suspense fallback={<CustomLoadingModal modalStatus={false} />}>
          <Routes>
            <Route path='/'>
              <Route index element={<LandingRoutes />} />
              <Route path='companies/*' element={<CompaniesRoutes />} />
              <Route path='contacts/*' element={<ContactRoutes />} />
              <Route path='settings/*' element={<SettingRoutes />} />
              <Route path='tasks/*' element={<TasksRoutes />} />
              <Route path='activity/*' element={<ActivityRoutes />} />
              <Route path='opportunities/*' element={<OpportunitiesRoutes />} />
              <Route path='quotes/*' element={<QuotesRoutes />} />
              <Route path='messages/*' element={<MessagesRoutes />} />
              <Route path='user-setup/*' element={<UserSetupRoutes />} />
              <Route path='user-setup/*' element={<MapModal />} />
              <Route path='*' element={<NotFoundPage />} />
            </Route>
          </Routes>
        </Suspense>
      </Content>
    </Layout>
  );
};

export default App;
