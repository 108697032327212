import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { SortModel } from 'src/components/commons/subTable/types';
import { BuyGroupModel } from 'src/graphql/schema-types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import handleError from 'src/utils/functions/handleError';
import COLUMNS from '../constants';
import {
  CREATE_BUYING_GROUP,
  REMOVE_BUYING_GROUP,
  UPDATE_BUYING_GROUP,
} from '../graphql/mutations';
import GET_ALL_BUYING_GROUP from '../graphql/queries';
import { GetAllBuyingGroup, GetAllBuyingGroupResponse } from '../types';
import useFilterColumn from '../../callPattern/hooks/useFilterColumn';

const useTabBuyingGroupTable = () => {
  const { createdFilters, setColumnFilters } = useFilterColumn();
  const [getAll, queryResponse] = useLazyQuery<GetAllBuyingGroup>(GET_ALL_BUYING_GROUP, {
    fetchPolicy: 'network-only',
  });

  const [getAllFilter] = useLazyQuery<GetAllBuyingGroup>(GET_ALL_BUYING_GROUP, {
    fetchPolicy: 'cache-and-network',
  });

  const [update] = useMutation(UPDATE_BUYING_GROUP);
  const [create] = useMutation(CREATE_BUYING_GROUP);
  const [remove] = useMutation(REMOVE_BUYING_GROUP);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 1000 });
  const [columns, setColumns] = useState(COLUMNS);
  const [newRecord, setNewRecord] = useState({
    name: '',
    description: '',
  });

  const fetchPage: FetchFunc = ({ start, renderLen }) =>
    getAll({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllBuyGroup2.results || [],
      total: res.data?.GetAllBuyGroup2.total || 0,
    }));

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(fetchPage);

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(searchField, search, dataType);
    return getAllFilter({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },
          filter: filters,
        },
      },
    }).then((res) => ({
      results: res?.data?.GetAllBuyGroup2.results as any[],
      filter: res?.data?.GetAllBuyGroup2.results?.map((result: GetAllBuyingGroupResponse) => ({
        value: result[searchField],
        label: result[searchField],
      })) as [],
    }));
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
      description: '',
    });
  };

  const handleClearFilter = () => {
    reset();
    setColumnFilters([]);
  };

  const createNewRecord = async () => {
    const { name, description } = newRecord;
    if (name === '') return;
    await create({
      variables: {
        createBuyGroupInput: {
          name: capitalizeFirstWord(name),
          description,
        },
      },
    }).then(() => {
      setTimeout(() => {
        reset();
      }, 500);
    });
  };

  const handleUpdateRows = (rows: any[]) => {
    rows.forEach((row) => {
      const { id, name, description } = row;
      update({
        variables: {
          updateBuyGroupInput: {
            id,
            name: capitalizeFirstWord(name),
            description,
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: BuyGroupModel[]) => {
    const removeId = rows[0].id;

    const messages = {
      400: {
        title: 'This record cannot be deleted',
        message: 'This value is linked to the company. You can not delete it.',
      },
    };

    try {
      remove({ variables: { id: removeId } }).then(() => {
        setTimeout(() => {
          reset();
        }, 500);
      });
    } catch (error) {
      console.error('Error deleting records:', error);
      handleError(error, messages);
    }
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = async (sortModel: SortModel | undefined) => {
    if (!sortModel) {
      // Load original data without any sorting
      try {
        const response = await getAll({
          variables: {
            criteria: {
              pagination: {
                from: (pagination.page - 1) * pagination.pageSize,
                size: pagination.pageSize,
              },
            },
          },
        });

        // Update data source with the original data
        if (response.data && response.data.GetAllBuyGroup2) {
          setDataSource(response.data.GetAllBuyGroup2.results);
        }
      } catch (error) {
        // Handle the error scenario
        console.error('Failed to fetch original data:', error);
      }
      return; // Early return to skip sorting if sortModel is undefined
    }

    // Check if sortBy is "parent" and change it to "parent_name"
    const sortByField = sortModel.sortBy === 'parent' ? 'parent_name' : sortModel.sortBy;

    try {
      // Update pagination with the new sort model
      setPagination((prev) => ({
        ...prev,
        sort: sortModel,
      }));

      // Fetch sorted data
      const response = await getAll({
        variables: {
          criteria: {
            pagination: {
              from: (pagination.page - 1) * pagination.pageSize,
              size: pagination.pageSize,
            },
            orderBy: [
              {
                field: sortByField,
                sort: sortModel.sort.toUpperCase(),
              },
            ],
          },
        },
      });

      // Update data source with new sorted data
      if (response.data && response.data.GetAllBuyGroup2) {
        setDataSource(response.data.GetAllBuyGroup2.results);
      }
    } catch (error) {
      // Handle the error scenario
      console.error('Failed to fetch sorted data:', error);
    }
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    dataSource,
    onListRender,
    handleUpdateRows,
    handleDeleteRows,
    queryResponse,
    postData,
    newRecord,
    createNewRecord,
    resetNewRecord,
    handleClearFilter,
  };
};

export default useTabBuyingGroupTable;
