import { createGlobalStyle } from 'styled-components';

interface GlobalStyleProps {
  pinnedColumns: number;
}

export const CompanyGlobalStyles = createGlobalStyle<GlobalStyleProps>`
.ant-table-cell-fix-right-first {
  border-left: 1px solid #939393;
}
td.ant-table-cell {
  border: 1px solid #E4E4E4;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: 1px solid #939393;
}

.ant-table-wrapper .ant-table-tbody > tr > td:first-child {
  border-right: none !important;
}

.ant-table-wrapper .ant-table-tbody > tr > td:nth-child(2) {
  border-left: none !important;
  border-right: 1px solid #939393 !important;
}

//table cells 
.ant-table-wrapper .ant-table-tbody > tr > td {
  .ant-input-outlined {
    background: #ffffff00 !important;
    border: none !important;
    :focus-within {
      box-shadow: none;
    }
  }
  .ant-input-affix-wrapper {
    box-shadow: none !important;
  }
  .ant-picker-outlined {
    background: none !important;
    :focus-within {
      box-shadow: none;
    }
}

.ant-picker {
  border: none !important;
}
}


&& tbody > tr:hover > td {
  background: #E4E4E4 !important;
}


.dragAndDropLine {
  display: none !important;
}

${(props) =>
  props.pinnedColumns >= 1 &&
  `
  .ant-table-wrapper .ant-table-tbody > tr > td:nth-child(3),
  .ant-table-wrapper .ant-table-thead > tr > th:nth-child(3) {
    border-left: none !important;
    border-right: 1px solid #939393 !important;
    .react-resizable-handle-item {
      display: none;
    }
  }
`}

${(props) =>
  props.pinnedColumns === 2 &&
  `
  .ant-table-wrapper .ant-table-tbody > tr > td:nth-child(4),
  .ant-table-wrapper .ant-table-thead > tr > th:nth-child(4) {
    border-left: none !important;
    border-right: 1px solid #939393 !important;
    .react-resizable-handle-item {
      display: none;
    }
  }
`}

.ant-table-wrapper .ant-table-tbody > tr > td:last-child {
  border-left: 1px solid #939393;
}



//// tableheaders 
.ant-table-wrapper .ant-table-thead > tr > th:first-child {
  border-right: none !important;
}

.ant-table-wrapper .ant-table-thead > tr > th:nth-child(2) {
  border-right: 1px solid #939393;
}

.react-resizable-handle-item {
  width: 5px;
  height: 20px;
  bottom: -38px;
  right: -4px;
  cursor: col-resize;
  z-index: 1;
  background-color: #E4E4E4;
  position: relative;
}


////// form styles --------------------
.collapse-style {
  background-color: transparent !important;
  border: 0px !important;
  border-radius: 0px;
  border-bottom:0px;
  
}
.ant-collapse .ant-collapse-content {
  border-top: 0px !important;
}
.card-eye-style-active {
  font-size: 24px;
}
.card-eye-style-inactive {
  font-size: 24px;
}
.drag-holder-style {
  font-size: 24px;
  padding-right: 12px;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: rgba(0, 0, 0, 0.88);
}

.ant-table-body > div > table > tbody > tr > td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last:hover {
  border-bottom: 1px solid rgb(228, 228, 228);
  border-top: 1px solid rgb(228, 228, 228);
}

`;
