/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Flex, Radio } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
// eslint-disable-next-line no-duplicate-imports
import type { RadioChangeEvent } from 'antd';
import { StyledRadioButton } from '../styles/searchInput.style';

interface SelectSearchProps {
  handleFetchAll: () => void;
  data?: any;
  total: any;
  setSelectedSideData: (record: any) => void;
}

const InfinityScroll: React.FC<SelectSearchProps> = ({
  handleFetchAll,
  data,
  setSelectedSideData,
  total = 0,
}) => {
  const [selectedElement, setSelectedElement] = useState();

  const onChange = (e: RadioChangeEvent) => {
    setSelectedSideData(data.results.find((element: any) => element.company.id === e.target.value));
    setSelectedElement(e.target.value);
  };

  useEffect(() => {
    if (!selectedElement) {
      setSelectedSideData(data?.results[0]);
      setSelectedElement(data?.results[0].company.id);
    }
  }, [data]);

  return (
    <Radio.Group defaultValue={data?.results[0]?.company?.id} onChange={onChange}>
      <InfiniteScroll
        dataLength={total}
        next={handleFetchAll}
        hasMore
        loader={<h4>Loading...</h4>}
        height='65vh'
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>No more data!</b>
          </p>
        }
      >
        <Flex vertical style={{ width: '100%' }}>
          {data?.results?.map((element: any) => (
            <StyledRadioButton
              key={element?.company?.id}
              value={element?.company?.id}
              isSelected={selectedElement === element?.company?.id}
            >
              {element?.company?.name}
            </StyledRadioButton>
          ))}
        </Flex>
      </InfiniteScroll>
    </Radio.Group>
  );
};

export default InfinityScroll;
