import { gql } from '@apollo/client';

export const GET_CONTACT_BY_ID = gql`
  query GetContact($id: Int!) {
    GetContact(id: $id) {
      businessInfo {
        doNotEmail
        mainAddress {
          zipCode
          id
          city
          pobox
          formattedAddress
          address
          state {
            name
          }
          zipCode
          region {
            id
            name
          }
          territory {
            id
            name
          }
          continent {
            id
            name
          }
          country {
            id
            name
          }
        }
        addresses {
          id
          addressType {
            id
            name
          }
          region {
            id
            name
          }
          territory {
            id
            name
          }
          continent {
            id
            name
          }
          country {
            id
            name
          }
          state {
            id
            name
          }
          zipCode
          city
          pobox
          address
          formattedAddress
          isAddressInvalid
          geopositionLongitude
          geopositionLatitude
        }
        assistant
        company {
          name
          salesTeam {
            name
          }
          companyType {
            name
          }
        }
        context
        department
        mainEmail {
          id
          email
          emailType {
            id
            name
          }
        }
        emails {
          id
          email
          emailType {
            id
            name
          }
        }
        enabledForcedSync
        globallyVisible
        id
        isPrimary
        jobTitle
        referencedBy
        mainPhone {
          id
          areaCode
          countryCode
          extension
          phone
          phoneType {
            name
            id
          }
        }
        phones {
          id
          areaCode
          countryCode
          extension
          phone
          phoneType {
            name
            id
          }
        }
        manager
      }
      firstName
      groups {
        id
        name
      }
      products {
        id
        name
      }
      id
      notes
      title
      personalInfo {
        addresses {
          id
          address
          addressType {
            id
            name
          }
          city
          pobox
          continent {
            id
            name
          }
          country {
            id
            name
          }
          formattedAddress
          geopositionLatitude
          geopositionLongitude
          id
          region {
            id
            name
          }
          state {
            id
            name
          }
          territory {
            id
            name
          }
          zipCode
        }
        phones {
          id
          areaCode
          countryCode
          extension
          phone
          phoneType {
            id
            name
          }
        }
        emails {
          id
          email
          emailType {
            id
            name
          }
          id
        }
      }
      lastName
    }
  }
`;

export default GET_CONTACT_BY_ID;
