import { createGlobalStyle } from 'styled-components';

const StepperStyles = createGlobalStyle`
.stepper {
    display: flex;
    padding: 0;
    position: relative;
    border: none;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      content: "";
      width: calc(100% - 20px);
    }
    overflow-x: auto;
    overflow: -moz-scrollbars-none;
    padding-bottom: 2.5px;
  }

  .stepper__item {
    cursor: pointer;
    margin-left: -15px;
    min-width: 273.83px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    background: #C4C4C4
    margin: 0 0 0 -19px;
    -webkit-clip-path: polygon(20px 50%, 1% -1%, calc(100% - 20px) 0%, 99% 50%, calc(100% - 20px) 100%, 1% 100%);
    
    &.current {
      background: #CCE4F6;
      color: #0078D4;
    }
    
    &.complete {
      background: #0078D4;
      color: white;
    }
    
    &:first-child {
      margin-left: 0px;
      padding: 9px;
      -webkit-clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 99% 50%, calc(100% - 20px) 100%, 0% 100%);
      .inner-container {
        width: 90%;
        }
    }
    
    &:last-child {
      -webkit-clip-path: polygon(20px 50%, 1% -1%, 100% 0%, 100% 100%, 1% 100%);
    }

    .inner-container {
        width: 75%;
        display: flex;
        justify-content: space-between;
    }
  }
`;

export { StepperStyles };
