import { gql } from '@apollo/client';

export const CREATE_COUNTRY = gql`
  mutation CreateCountry($createCountryInput: CreateCountryInput!) {
    CreateCountry(createCountryInput: $createCountryInput) {
      id
      name
    }
  }
`;

export const UPDATE_COUNTRY = gql`
  mutation UpdateCountry($updateCountryInput: UpdateCountryInput!) {
    UpdateCountry(updateCountryInput: $updateCountryInput) {
      id
      name
    }
  }
`;

export const DELETE_COUNTRY = gql`
  mutation RemoveCountry($id: Int!) {
    RemoveCountry(id: $id)
  }
`;
