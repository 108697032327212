import React, { ReactNode } from 'react';
import { Space, Typography, Spin, Modal } from 'antd';
import {
  ExclamationCircleFilled,
  InfoCircleOutlined,
  LoadingOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import StyledCustomModal from './styles/customModal.style';

const { confirm } = Modal;

interface CustomModalProps {
  modalStatus: boolean;
  onCancelAction: () => void;
  onOkAction: () => void;
  modalTitle?: ReactNode | string;
  modalBody?: string | ReactNode;
  closable?: boolean;
  footer?: boolean | ReactNode;
  centered?: boolean;
  cancelText?: string;
  okText?: string;
  style?: object;
  customClass?: string;
  invalid?: boolean;
  testId?: string;
  headerHeight?: string;
  headerWidth?: string;
  bodyHeight?: string;
  bodyWidth?: string;
  footerHeight?: string;
  footerWidth?: string;
  width?: number | string;
  bodyPadding?: string;
}

const confirmModal = (
  modalTitle: string,
  modalBody: ReactNode | string,
  okAction: () => void,
  cancelAction: () => void,
  okText?: string,
  cancelText?: string,
) =>
  confirm({
    title: modalTitle || 'Undefined',
    icon: <ExclamationCircleFilled />,
    content: modalBody || 'Undefined',
    centered: true,
    okText: okText || 'Ok',
    cancelText: cancelText || 'Cancel',
    onOk() {
      okAction();
    },
    onCancel() {
      cancelAction();
    },
  });

const CustomModal: React.FC<CustomModalProps> = ({
  modalStatus,
  onOkAction,
  onCancelAction,
  modalTitle,
  modalBody,
  closable,
  footer,
  centered,
  cancelText,
  okText,
  style,
  customClass,
  invalid,
  testId = 'custom-modal',
  headerHeight,
  headerWidth,
  bodyHeight,
  bodyWidth,
  footerHeight,
  footerWidth,
  width,
  bodyPadding,
}) => (
  <StyledCustomModal
    zIndex={1070}
    title={modalTitle}
    centered={centered}
    closable={closable}
    open={modalStatus}
    onOk={onOkAction}
    onCancel={onCancelAction}
    okButtonProps={{ disabled: invalid || undefined }}
    footer={footer}
    okText={okText}
    cancelText={cancelText}
    style={style}
    className={customClass}
    destroyOnClose
    closeIcon={<CloseCircleOutlined style={{ fontSize: 'large', color: '#0078D4' }} />}
    modalRender={(node) => {
      const testableNode = React.cloneElement(node as JSX.Element, { 'data-testid': testId });
      return testableNode;
    }}
    headerHeight={headerHeight}
    headerWidth={headerWidth}
    bodyHeight={bodyHeight}
    bodyWidth={bodyWidth}
    footerHeight={footerHeight}
    footerWidth={footerWidth}
    bodyPadding={bodyPadding}
    width={width}
  >
    {modalBody}
  </StyledCustomModal>
);

interface CustomLoadingModalProps {
  modalStatus: boolean;
  modalTitle?: ReactNode | string;
}

interface CustomErrorModalProps {
  modalStatus: boolean;
  modalTitle?: ReactNode | string;
  modalBody?: ReactNode | string;
  cancelAction?: () => void;
  style?: React.CSSProperties;
}

const CustomLoadingModal: React.FC<CustomLoadingModalProps> = ({ modalStatus, modalTitle }) => {
  const customLoadingSpin = <LoadingOutlined style={{ fontSize: 40, color: '#000539' }} spin />;

  return (
    <Modal
      title={
        <Space>
          <InfoCircleOutlined style={{ fontSize: 'large', color: '#0078D4' }} />
          <Typography.Title level={5} style={{ marginBottom: '3px', marginTop: 0 }}>
            {modalTitle || 'Loading System...'}
          </Typography.Title>
        </Space>
      }
      centered
      closable={false}
      open={modalStatus}
      footer={false}
      data-testid='custom-loading-modal'
    >
      <Space align='center' style={{ width: '100%', justifyContent: 'center', height: '100px' }}>
        <Spin data-testid='modal-spin' indicator={customLoadingSpin} />
      </Space>
    </Modal>
  );
};

const CustomErrorModal: React.FC<CustomErrorModalProps> = ({
  modalStatus,
  modalTitle,
  modalBody,
  style,
  cancelAction,
}) => (
  <Modal
    title={
      <Space>
        <Typography.Title level={5} style={{ marginBottom: '3px', marginTop: 0 }}>
          {modalTitle || 'Error'}
        </Typography.Title>
      </Space>
    }
    centered
    onCancel={cancelAction}
    open={modalStatus}
    footer={false}
    data-testid='custom-loading-modal'
    closeIcon={<CloseCircleOutlined style={{ fontSize: 'large', color: '#0078D4' }} />}
    style={style}
  >
    <Space align='center' style={{ width: '100%', justifyContent: 'center', height: '100px' }}>
      <Typography.Text>
        {modalBody || 'Sorry, it looks like an error has occurred, try to reload the page'}
      </Typography.Text>
    </Space>
  </Modal>
);

export { CustomModal, confirmModal, CustomLoadingModal, CustomErrorModal };
