import { createGlobalStyle } from 'styled-components';

export const CloseOppGlobalStyles = createGlobalStyle`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding: 0 !important;
  }
`;
