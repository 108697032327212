import CustomFooter from 'src/components/commons/customFooter';
import CustomViewContainer from 'src/components/commons/customViewContainer';
import { OppContent } from 'src/components/opportunityDetail';
import OppHeader from 'src/components/opportunityDetail/components/oppHeader';
import { GlobalContextProvider } from 'src/context/global.context';
import OppContextProvider from 'src/context/opportunity.context';

const OppSetupLayout = () => (
  <GlobalContextProvider>
    <CustomViewContainer>
      <OppContextProvider>
        <OppHeader />
        <OppContent />
        <CustomFooter />
      </OppContextProvider>
    </CustomViewContainer>
  </GlobalContextProvider>
);

export default OppSetupLayout;
