import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Form, Input, Modal, notification } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import {
  REMOVE_GRID_PREFERENCE,
  CREATE_GRID_PREFERENCE,
  SET_DEFAULT_GRID_PREFERENCE,
  UPDATE_GRID_PREFERENCE,
} from 'src/graphql/mutations/grid/mutations';
import { GET_ALL_GRID_PREFERENCES } from 'src/graphql/queries/grid-preferences';
import { localGridPreferenceData2 } from 'src/services/global-variables';
import {
  CreateGridPreferenceInput,
  GridPreferenceModel,
  Mutation,
  Query,
  UpdateGridPreferenceInput,
} from 'src/graphql/schema-types';
import isArray from 'lodash/isArray';
import every from 'lodash/every';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import unset from 'lodash/unset';
import set from 'lodash/set';

const { confirm } = Modal;

export enum GridNames {
  Companies = 'companies',
  Contacts = 'contacts',
  Tasks = 'tasks',
  ActivityJournals = 'comercial',
  Opportunities = 'oportunities',
  Quotes = 'quotes',
  Messages = 'messages',
}

export type GridPreferenceOptions = {
  order: string[];
  selected: string[];
  pageSize?: number;
  tableActiveFilterPreferences?: string;
  tableSortOrderPreferences?: string;
  moveToRightTable?: boolean;
  selectedFilterField?: string;
  selectedFilterValues?: string;
  searchImputValueArr?: string;
  filters?: Record<string, string>;
  sorting?: Record<string, string>;
};

type GridPreference = GridPreferenceModel & {
  options: GridPreferenceOptions;
};

// function to validate a string json with the options schema
const isValidJson = (str: string) => {
  try {
    const json = JSON.parse(str);
    // order should be an array of strings
    if (!isArray(json.order)) return false;
    if (!every(json.order, isString)) return false;

    // selected should be an array of strings
    if (!isArray(json.selected)) return false;
    if (!every(json.selected, isString)) return false;

    // pageSize should be a number (optional, because it is not needed for infinity scroll)
    if (json.pageSize && !isNumber(json.pageSize)) return false;

    // filters should be an object { [key]: string } (optional)
    if (json.filters && !isObject(json.filters)) return false;
    if (!every(json.filters, isString)) return false;

    // sorting should be an object { [key]: string } (optional)
    if (json.sorting && !isObject(json.sorting)) return false;
    if (!every(json.sorting, isString)) return false;
  } catch (e) {
    return false;
  }

  return true;
};

const showCreatePreferenceDialog = (preferences: GridPreference[], cb: (name: string) => void) => {
  let name = '';
  const uuid = Math.random().toString(36);
  const dialog = confirm({
    centered: true,
    title: 'Create new grid preference',
    content: (
      <Form component={false} layout='vertical' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <Form.Item
          required
          name={`preference-name-${uuid}`}
          label='Name'
          style={{ marginRight: -34 }}
          rules={[
            {
              validator: async (_rule, value) => {
                name = value;
                let msg = '';
                if (!value) {
                  msg = 'Please input a name';
                } else if (preferences.find((p) => p.name === value)) {
                  msg = 'Name already exists';
                }

                if (msg) {
                  dialog.update({
                    okButtonProps: { disabled: true },
                  });
                  return Promise.reject(msg);
                }

                dialog.update({
                  okButtonProps: { disabled: false },
                });
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input data-testid='grid-preference-name-input' />
        </Form.Item>
      </Form>
    ),
    onOk() {
      cb(name);
    },
    okButtonProps: { disabled: true },
    icon: null,
    type: 'confirm',
  });
};

const useGridPreferences = (gridName: GridNames) => {
  const {
    data,
    loading: getAllLoading,
    error: getAllError,
  } = useQuery<Pick<Query, 'GetAllGridPreferences'>>(GET_ALL_GRID_PREFERENCES, {
    fetchPolicy: 'network-only',
    variables: { gridName },
  });
  const [createGridPreferenceMutation, { loading: createLoading, error: createError }] =
    useMutation<Pick<Mutation, 'CreateGridPreference'>>(CREATE_GRID_PREFERENCE);
  const [updateGridPreferenceMutation, { loading: updateLoading, error: updateError }] =
    useMutation<Pick<Mutation, 'UpdateGridPreference'>>(UPDATE_GRID_PREFERENCE);
  const [removeGridPreferenceMutation, { loading: removeLoading, error: removeError }] =
    useMutation<Pick<Mutation, 'RemoveGridPreference'>>(REMOVE_GRID_PREFERENCE);
  const [setDefaultGridPreferenceMutation] = useMutation<
    Pick<Mutation, 'SetDefaultGridPreference'>
  >(SET_DEFAULT_GRID_PREFERENCE);

  const [preferences, setPreferences] = useState<GridPreference[]>([]);
  const [selectedId, internalSetSelectedId] = useState<number | null>(null);

  const setSelectedId = (id: number | null, resetLocalStorage = true) => {
    internalSetSelectedId(id);
    if (resetLocalStorage) {
      const localSavedStr = localStorage.getItem('GRID_PREFERENCES') || '{}';
      const localSaved = JSON.parse(localSavedStr);
      delete localSaved[gridName];
      localStorage.setItem('GRID_PREFERENCES', JSON.stringify(localSaved));
    }
  };

  const selected = useMemo(() => {
    const localSavedStr = localStorage.getItem('GRID_PREFERENCES') || '{}';
    const localSaved = JSON.parse(localSavedStr);
    const localSavedOptions = localSaved[gridName] || {};
    const selectedPreference = preferences.find((p) => p.id === selectedId);

    if (!selectedPreference && !localSavedOptions) {
      return undefined;
    }

    return {
      id: selectedPreference?.id,
      options: {
        ...selectedPreference?.options,
        ...localSavedOptions,
      },
    };
  }, [preferences, selectedId]);

  useEffect(() => {
    if (data?.GetAllGridPreferences) {
      const toDelete: number[] = [];
      const gridPreferences = data.GetAllGridPreferences.filter((p) => p.gridName === gridName)
        .filter((p) => {
          if (!isValidJson(p.options)) {
            toDelete.push(p.id);
            return false;
          }
          return true;
        })
        .map((p) => ({
          ...p,
          options: JSON.parse(p.options) as GridPreferenceOptions,
        }));
      const defaultGridPreferenceId = gridPreferences.find((p) => p.isDefault)?.id;
      if (gridPreferences.length > 0) {
        setPreferences(gridPreferences);
        setSelectedId(defaultGridPreferenceId || gridPreferences[0]?.id || null, false);
      } else {
        const noGridPreferences = localGridPreferenceData2.map(
          (p) =>
            ({
              ...p,
              options: JSON.parse(p.options),
            } as GridPreference),
        );
        if (noGridPreferences) {
          setPreferences(noGridPreferences);
          setSelectedId(noGridPreferences[0]?.id ?? null, false);
        }
      }

      if (toDelete.length > 0) {
        toDelete.forEach((id) => {
          removeGridPreferenceMutation({
            variables: {
              removeGridPreferenceId: id,
            },
          });
        });
      }
    }
  }, [data]);

  const loading = useMemo(
    () => getAllLoading || createLoading || updateLoading || removeLoading,
    [getAllLoading, createLoading, updateLoading, removeLoading],
  );

  const error: ApolloError | undefined = useMemo(
    () => getAllError || createError || updateError || removeError,
    [getAllError, createError, updateError, removeError],
  );

  const createGridPreference = async (options: GridPreferenceOptions) => {
    const onConfirm = async (name: string) => {
      const payload: CreateGridPreferenceInput = {
        name,
        gridName,
        options: JSON.stringify(options),
      };

      const { data: result } = await createGridPreferenceMutation({
        variables: {
          createGridPreferenceInput: payload,
        },
      });

      if (result?.CreateGridPreference) {
        setPreferences((prev) => [
          ...prev,
          {
            ...result.CreateGridPreference,
            options: JSON.parse(result.CreateGridPreference.options) as GridPreferenceOptions,
          },
        ]);
        setSelectedId(result.CreateGridPreference.id);
        setDefaultGridPreferenceMutation({
          variables: {
            setDefaultGridPreferenceId: result.CreateGridPreference.id,
          },
        });
        notification.success({
          message: 'Grid preference created',
          key: 'grid-preference-created',
        });
      }
    };

    // clear the local saved
    const localSavedStr = localStorage.getItem('GRID_PREFERENCES') || '{}';
    const localSaved = JSON.parse(localSavedStr);
    delete localSaved[gridName];
    localStorage.setItem('GRID_PREFERENCES', JSON.stringify(localSaved));

    showCreatePreferenceDialog(preferences, onConfirm);
  };

  const setDefaultGridPreference = async (id: number) => {
    const { data: result } = await setDefaultGridPreferenceMutation({
      variables: {
        setDefaultGridPreferenceId: id,
      },
    });

    if (result?.SetDefaultGridPreference) {
      notification.success({
        message: 'Grid preference changed',
        key: 'grid-preference-changed',
      });
    }
    setSelectedId(id);
  };

  const updateGridPreference = async (
    args: Omit<UpdateGridPreferenceInput, 'gridName'> & { options: GridPreferenceOptions },
  ) => {
    const payload = {
      ...args,
      options: JSON.stringify(args.options),
      gridName,
    };

    const { data: result } = await updateGridPreferenceMutation({
      variables: {
        updateGridPreferenceInput: payload,
      },
    });

    if (result?.UpdateGridPreference) {
      setPreferences((prev) => [
        ...prev.filter((p) => p.id !== result.UpdateGridPreference.id),
        {
          ...result.UpdateGridPreference,
          options: JSON.parse(result.UpdateGridPreference.options) as GridPreferenceOptions,
        },
      ]);
      setSelectedId(result.UpdateGridPreference.id);
    }
  };

  const removeGridPreference = async (id: number) => {
    await removeGridPreferenceMutation({
      variables: {
        removeGridPreferenceId: id,
      },
    });

    setPreferences((prev) => prev.filter((p) => p.id !== id));
  };

  const localRemove = (key: string) => {
    const storageKey = `GRID_PREFERENCES.${gridName}.${key}`.split('.');

    const objStr = localStorage.getItem(storageKey[0]) || '{}';

    const options = JSON.parse(objStr);

    unset(options, storageKey.slice(1));

    localStorage.setItem(storageKey[0], JSON.stringify(options));
  };

  const localSave = (key: string, value: any) => {
    const storageKey = `GRID_PREFERENCES.${gridName}.${key}`.split('.');

    const objStr = localStorage.getItem(storageKey[0]) || '{}';

    const options = JSON.parse(objStr);

    set(options, storageKey.slice(1), value);

    localStorage.setItem(storageKey[0], JSON.stringify(options));
  };

  const prepareColumns = <
    T extends { dataIndex: string | number | string[]; key?: string | number },
  >(
    columns: T[],
  ) =>
    columns
      .map((column) => {
        const key: string = [column.dataIndex, column.key].filter(Boolean).flat().join('.');
        const isSelected: boolean | undefined = selected?.options?.selected?.includes(key);
        const index: number = selected?.options?.order?.indexOf(key) ?? -1;
        return {
          ...column,
          selected: isSelected,
          gridPreferenceKey: key,
          index,
        };
      })
      .sort((a, b) => a.index - b.index);

  const crud = useMemo(
    () => ({
      error,
      loading,
      selected,
      preferences,
      setSelectedId,
      prepareColumns,
      createGridPreference,
      updateGridPreference,
      removeGridPreference,
      setDefaultGridPreference,
      localSave,
      localRemove,
    }),
    [
      error,
      loading,
      selected,
      preferences,
      setSelectedId,
      prepareColumns,
      createGridPreference,
      updateGridPreference,
      removeGridPreference,
      setDefaultGridPreference,
    ],
  );

  return crud;
};

export default useGridPreferences;
