import capitalizeFirstLetter from 'src/utils/functions/capitalizeFirstLetter';
import { FC } from 'react';
import { Space, Typography } from 'antd';
import { SubTableColumn } from '../types';
import SortButton from './sortButton';

const Title: FC<{ column: SubTableColumn }> = ({ column }) => {
  const { sortable = true } = column;
  const title =
    typeof column.title === 'string' ? capitalizeFirstLetter(column.title) : column.title;
  return (
    <Space direction='horizontal' className='column-title'>
      <Typography.Text className='title-text'>{title}</Typography.Text>
      {sortable && <SortButton column={column} />}
    </Space>
  );
};

export default Title;
