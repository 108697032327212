import styled from 'styled-components';
import { Layout, Typography, Image } from 'antd';

const { Footer } = Layout;
const { Title } = Typography;

const StyledFooter = styled(Footer)`
  &&& {
    padding: 0 50px;
    border-top: 1px solid black;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
`;

const StyledTitle = styled(Title)`
  &&& {
    margin: auto;
    color: #448aa0;
  }
`;

const StyledImage = styled(Image)`
  &&& {
    width: 140px;
  }
`;

export { StyledFooter, StyledTitle, StyledImage };
