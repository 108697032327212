import styled from 'styled-components';
import { Tag } from 'antd';

const StyledCustomTag = styled(Tag)`
  &&& {
    color: #d6d5d6 !important;
    background-color: #fafafa !important;
  }
`;

export default StyledCustomTag;
