import { Resizable } from 'react-resizable';
import { CSSProperties, FC } from 'react';
import { ResizeHandler } from '../types';

type Props = {
  onResize: ResizeHandler;
  width: number;
  style?: CSSProperties;
};

const ResizableTitle: FC<Props> = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} style={{ ...restProps?.style }} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      minConstraints={[50, 0]}
      handle={
        <span
          className='react-resizable-handle'
          onClick={(e) => {
            e.stopPropagation();
          }}
          onDrag={(e) => e.stopPropagation()}
        >
          <div className='react-resizable-handle-item' />
        </span>
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} style={{ ...restProps?.style }} />
    </Resizable>
  );
};

export default ResizableTitle;
