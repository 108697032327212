/* eslint-disable react/no-unstable-nested-components */
import { Table as AntdTable } from 'antd';
import ReactDragListView from 'react-drag-listview';
import React, { useMemo } from 'react';
import { SubTableGridProps } from '../types';
import SubTableDetailsView from './SubTableDetailsView';
import useTable from '../hooks/useTable';
import CustomButton from '../../customButton';
import { useSubTableContext } from '../contexts/subtable.context';
import ModalCreateEdit from './modalCreateEdit';

const SubTableGrid: React.FC<SubTableGridProps> = (props) => {
  const {
    detailsTable,
    keyExpandableRow,
    addNewButtonDetailsTable,
    BodyModal,
    titleModalEdit,
    titleModalNew,
    saveNewRecord,
    handleUpdateRows,
    expandable,
  } = props;
  const { components, rowSelection, columns, onRow, handleDragEnd, pagination, dataSource } =
    useTable(props);
  const { setAddNewRecord, addNewRecord } = useSubTableContext();

  const handleOnClick = () => {
    setAddNewRecord((prev) => !prev);
  };

  const columnsToShow = useMemo(
    () => columns?.filter((column) => column.selected !== false),
    [columns],
  );

  return (
    <>
      {!!BodyModal && (
        <ModalCreateEdit
          BodyModal={BodyModal as React.ComponentType}
          titleEdit={titleModalEdit}
          titleNew={titleModalNew}
          saveNewRecord={saveNewRecord}
          handleUpdateRows={handleUpdateRows}
          dataSource={dataSource}
        />
      )}
      <ReactDragListView.DragColumn
        onDragEnd={handleDragEnd}
        nodeSelector='th'
        handleSelector='.sub-table-column-header'
        ignoreSelector='.react-resizable-handle'
      >
        <AntdTable
          {...props}
          components={components}
          rowSelection={rowSelection}
          dataSource={dataSource}
          columns={columnsToShow as []}
          onRow={onRow}
          pagination={pagination}
          rowKey={(record) => record.id || record.ID || record.key}
          data-testid='subtable'
          // rowClassName='editable-row'
          expandable={
            expandable ??
            (keyExpandableRow
              ? {
                  defaultExpandedRowKeys: ['0'],
                  expandedRowRender: (record) => (
                    <div style={{ height: 'fit-content' }}>
                      <SubTableDetailsView
                        useDetailsTable={detailsTable!}
                        data={
                          record?.activeNewRowExpandableOnNewItem
                            ? record[`${keyExpandableRow}`]
                            : [record]
                        }
                      />
                    </div>
                  ),
                  rowExpandable: (record) =>
                    !!record?.activeNewRowExpandableOnNewItem || !!record[`${keyExpandableRow}`],
                }
              : undefined)
          }
        />
      </ReactDragListView.DragColumn>
      {addNewButtonDetailsTable && (
        <CustomButton
          title='+ Add new'
          type='primary'
          style={{ margin: '1rem 2rem' }}
          onClick={handleOnClick}
          disabled={!!addNewRecord}
        />
      )}
    </>
  );
};

export default SubTableGrid;
