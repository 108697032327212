import { lazy } from 'react';

const loadPageSubTableCompanies = () => import('./PageSubTableCompanies');
export const PageSubTableCompanies = lazy(loadPageSubTableCompanies);

const loadPageSubTableContacts = () => import('./PageSubTableContacts');
export const PageSubTableContacts = lazy(loadPageSubTableContacts);

const loadPageSubTableActivityJournals = () => import('./PageSubTableActivityJournals');
export const PageSubTableActivityJournals = lazy(loadPageSubTableActivityJournals);

const loadPageSubTableOpportunities = () => import('./PageSubTableOpportunities');
export const PageSubTableOpportunities = lazy(loadPageSubTableOpportunities);

const loadPageSubTableDivisions = () => import('./PageSubTableDivisions');
export const PageSubTableDivisions = lazy(loadPageSubTableDivisions);
