import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { Option, SortModel } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { CompanyIndustryDbModel } from 'src/graphql/schema-types';
import COLUMNS from '../constants';
import { GetAllCompanyIndustriesResponse, GetAllCompanyIndustries } from '../types';
import GET_ALL_INDUSTRY from '../graphql/queries';
import { CREATE_INDUSTRY, DELETE_INDUSTRY, UPDATE_INDUSTRY } from '../graphql/mutations';
import useFilterColumn from '../../callPattern/hooks/useFilterColumn';

const useIndustryTable = () => {
  const { createdFilters, setColumnFilters } = useFilterColumn();

  const [loadIndustry, { loading }] = useLazyQuery<GetAllCompanyIndustries>(GET_ALL_INDUSTRY, {
    fetchPolicy: 'network-only',
  });

  const [loadIndustryFilter] = useLazyQuery<GetAllCompanyIndustries>(GET_ALL_INDUSTRY, {
    fetchPolicy: 'network-only',
  });

  const [create] = useMutation(CREATE_INDUSTRY);
  const [update] = useMutation(UPDATE_INDUSTRY);
  const [remove] = useMutation(DELETE_INDUSTRY);
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 1000 });
  const [columns, setColumns] = useState(COLUMNS);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newRecord, setNewRecord] = useState({
    name: '',
    description: '',
  });

  const uploadData: FetchFunc = ({ start, renderLen }) =>
    loadIndustry({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllIndustries2.results || [],
      total: res.data?.GetAllIndustries2.total || 0,
    }));

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(uploadData);

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(searchField, search, dataType);
    return loadIndustryFilter({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },

          filter: filters,
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res?.data?.GetAllIndustries2.results as any[],
      filter: res?.data?.GetAllIndustries2.results?.map(
        (result: GetAllCompanyIndustriesResponse) => ({
          value: result[searchField],
          label: result[searchField],
        }),
      ) as Option[],
    }));
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
      description: '',
    });
  };

  const createNewRecord = async () => {
    const { name, description } = newRecord;
    if (name === '') return;
    await create({
      variables: {
        createCompanyIndustryInput: {
          name: capitalizeFirstWord(name),
          description,
        },
      },
    }).then(() => {
      setTimeout(() => {
        reset();
      }, 500);
    });
  };

  const handleUpdateRows = (rows: CompanyIndustryDbModel[]) => {
    rows.forEach((row) => {
      const { id, name, description } = row;
      update({
        variables: {
          updateCompanyIndustryInput: {
            id,
            name: capitalizeFirstWord(name!),
            description,
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: CompanyIndustryDbModel[]) => {
    const removeId = rows[0].id;

    try {
      remove({ variables: { id: removeId } }).then(() => {
        setTimeout(() => {
          reset();
        }, 500);
      });
    } catch (error) {
      console.error('Error deleting records:', error);
    }
  };

  const handleClearFilter = () => {
    reset();
    setColumnFilters([]);
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = async (sortModel: SortModel | undefined) => {
    if (!sortModel) {
      // Load original data without any sorting
      try {
        const response = await loadIndustry({
          variables: {
            criteria: {
              pagination: {
                from: (pagination.page - 1) * pagination.pageSize,
                size: pagination.pageSize,
              },
            },
          },
        });

        // Update data source with the original data
        if (response.data && response.data.GetAllIndustries2) {
          setDataSource(response.data.GetAllIndustries2.results);
        }
      } catch (error) {
        // Handle the error scenario
        console.error('Failed to fetch original data:', error);
      }
      return; // Early return to skip sorting if sortModel is undefined
    }

    // Check if sortBy is "parent" and change it to "parent_name"
    const sortByField = sortModel.sortBy === 'parent' ? 'parent_name' : sortModel.sortBy;

    try {
      // Update pagination with the new sort model
      setPagination((prev) => ({
        ...prev,
        sort: sortModel,
      }));

      // Fetch sorted data
      const response = await loadIndustry({
        variables: {
          criteria: {
            pagination: {
              from: (pagination.page - 1) * pagination.pageSize,
              size: pagination.pageSize,
            },
            orderBy: [
              {
                field: sortByField,
                sort: sortModel.sort.toUpperCase(),
              },
            ],
          },
        },
      });

      // Update data source with new sorted data
      if (response.data && response.data.GetAllIndustries2) {
        setDataSource(response.data.GetAllIndustries2.results);
      }
    } catch (error) {
      // Handle the error scenario
      console.error('Failed to fetch sorted data:', error);
    }
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    onListRender,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    spinning: loading,
    postData,
    newRecord,
    createNewRecord,
    handleUpdateRows,
    resetNewRecord,
    dataSource,
    handleClearFilter,
    handleDeleteRows,
  };
};

export default useIndustryTable;
