import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { SalesTeamMemberModel } from 'src/models';

const salesTeamMemberAdapter: AdapterFunc<GQL.SalesTeamMemberModel, SalesTeamMemberModel> = (
  input,
) => ({
  id: input.id,
  name: input.name!,
});

export default salesTeamMemberAdapter;
