import { gql } from '@apollo/client';

export const GET_ALL_STATUS = gql`
  query GetAllOpportunityStatus($criteria: StatusCriteriaInput!) {
    GetAllOpportunityStatus(Criteria: $criteria) {
      results {
        id
        name
        boolean
      }
    }
  }
`;
