import useEntityAdapter, { OperationSpecs } from 'src/hooks/useEntityAdapter';
import { GET_ALL_OPP_FAILED_REASON } from '../graphql/queries';
import {
  UPDATE_OPP_FAILED_REASON,
  CREATE_OPP_FAILED_REASON,
  REMOVE_OPP_FAILED_REASON,
} from '../graphql/mutations';
import { FailedReasonModel } from '../models/failedReason.model';
import { failedReasonAdapter } from '../adapters/failedReason.adapter';

const operations: OperationSpecs = {
  createOne: {
    op: CREATE_OPP_FAILED_REASON,
  },
  updateOne: {
    op: UPDATE_OPP_FAILED_REASON,
  },
  deleteOne: {
    op: REMOVE_OPP_FAILED_REASON,
  },
  fetchAll: {
    op: GET_ALL_OPP_FAILED_REASON,
    dataPath: 'GetAllOpportunityFailedReasons',
  },
};

const useOpportunityFailedReasonsStore = () => {
  const api = useEntityAdapter<FailedReasonModel>({
    adapters: { entityAdapter: failedReasonAdapter },
    operations,
    isPaginated: true,
    entityName: 'OpportunityFailedReasons',
  });

  return api;
};

export default useOpportunityFailedReasonsStore;
