import styled from 'styled-components';

const StyledMapModal = styled.div`
  display: flex;
  gap: 2rem;
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .ant-form-item {
      margin: 0;
    }
    .ant-form-item-row {
      flex-direction: column;
      align-items: flex-start;
    }
    .ant-form-item-control {
      width: 100%;
    }
  }
  .map-container {
    height: 60vh;
    width: 100%;
  }

  && button {
    background: #0078d4;
    color: white;
    height: auto;
    padding: 2px 25px;
  }
`;

export default StyledMapModal;
