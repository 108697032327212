import { gql } from '@apollo/client';

export const GET_ALL_PREDEFINED_TYPES = gql`
  query GetCompaniesBaseTypes {
    GetCompaniesBaseTypes {
      id
      name
      color
      parent {
        id
        name
      }
    }
    GetCompanyPredefinedTypesGridHeader
  }
`;

export const GET_ALL_CUSTOM_TYPES = gql`
  query GetAllCompanyTypeAlias2(
    $criteria: CompanyTypeCriteriaInput!
    $searchFields: FindCompanyTypeInput
  ) {
    GetAllCompanyTypeAlias2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        color
        name
        parent {
          id
          name
        }
      }
      total
    }
  }
`;

export const GET_HEADERS_CUSTOM_TYPES = gql`
  query Query {
    GetCompanyCustomTypesGridHeaders
  }
`;
