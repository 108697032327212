import { FormInstance } from 'antd';
import { PropsWithChildren, createContext, useContext } from 'react';

const EditableContext = createContext<FormInstance>(null!);

const EditableProvider = ({ form, children }: PropsWithChildren<{ form: FormInstance }>) => (
  <EditableContext.Provider value={form}>{children}</EditableContext.Provider>
);

export const useEditableContext = () => useContext(EditableContext);

export default EditableProvider;
