import { contactAdapter } from 'src/adapters';
import {
  CREATE_ONE_CONTACT,
  DELETE_CONTACTS_IN_BULK,
  GET_ALL_GRID_CONTACTS,
  GET_CONTACT_BY_ID,
  REMOVE_ONE_CONTACT,
  UPDATE_ONE_CONTACT,
} from 'src/graphql';
import { ContactModel } from 'src/models';
import useEntityAdapter, { OperationSpecs } from '../useEntityAdapter';

const operations: OperationSpecs = {
  createOne: {
    op: CREATE_ONE_CONTACT,
  },
  updateOne: {
    op: UPDATE_ONE_CONTACT,
  },
  deleteOne: {
    op: REMOVE_ONE_CONTACT,
  },
  fetchAll: {
    op: GET_ALL_GRID_CONTACTS,
    dataPath: 'GetAllContactsForGrid',
  },
  fetchOne: {
    op: GET_CONTACT_BY_ID,
    dataPath: 'GetContact',
  },
  deleteInBulk: {
    op: DELETE_CONTACTS_IN_BULK,
  },
};

const useContactsStore = () => {
  const api = useEntityAdapter<ContactModel>({
    adapters: { entityAdapter: contactAdapter },
    operations,
    isPaginated: true,
    entityName: 'Contact',
  });

  return api;
};

export default useContactsStore;
