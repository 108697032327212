import styled from 'styled-components';

const SBasicInformation = styled.form`
  display: flex;
  gap: 1.5rem;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #4b4b4b;
    span {
      color: #e44f3b;
    }
  }

  .main-input-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .input-layout {
      display: flex;
      flex-direction: column;
      .checkbox-layout {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
        label {
          font-weight: 400;
          font-size: 14px;
          color: #4b4b4b;
        }
      }
      input {
        height: 2rem;
        margin: 0;
      }
      p {
        margin: 0;
      }
    }
  }

  .loginID {
    p {
      color: #c6c4c4 !important;
      span {
        color: #c6c4c4;
      }
    }
  }
`;
export default SBasicInformation;
