/* eslint-disable no-duplicate-imports */
import React, { useState } from 'react';
import type { RadioChangeEvent } from 'antd';
import { MERGE_COMPANY_TO } from 'src/components/company/mutations';
import { notification } from 'antd';
import { useMutation } from '@apollo/client';
import SelectMergeToCompanyInto from './selectMergeToCompanyInto';
import MergedCompanyConfirmation from './mergedCompanyConfirmation';

type Props = {
  handleOpenedMergeTo: () => void;
  show: boolean;
  companyName: string;
  companyId?: number;
};

const MergeTo: React.FC<Props> = ({ show, handleOpenedMergeTo, companyName, companyId }) => {
  const [openedMergedCompanyConfirmation, setOpenedMergedCompanyConfirmation] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [selectedRadio, setSelectedRadio] = useState();

  const handleOpenedMergedCompanyConfirmation = () => {
    setOpenedMergedCompanyConfirmation(!openedMergedCompanyConfirmation);
  };

  const handleMerge = () => {
    handleOpenedMergeTo();
    setOpenedMergedCompanyConfirmation(!openedMergedCompanyConfirmation);
  };

  const handleCompanySelection = (value: any) => {
    setSelectedCompany(value);
  };

  const handleRadioSelection = (value: RadioChangeEvent) => {
    setSelectedRadio(value.target.value);
  };

  const [mergeToCompany] = useMutation(MERGE_COMPANY_TO, {
    context: { clientName: 'elastic' },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      notification.success({
        message: 'The process started',
        key: 'change-sales-team-started',
      });
    },
    onError: (error) => {
      notification.success({
        message: error.message,
        key: 'change-sales-team-error',
      });
    },
  });

  const handleMergeTo = () => {
    const mergeCompanyInput = {
      delete: !!selectedRadio,
      idCompanyFrom: companyId,
      idCompanyTo: Number(selectedCompany?.id),
    };
    mergeToCompany({
      variables: {
        mergeCompanyInput,
      },
    });
    handleOpenedMergedCompanyConfirmation();
  };

  const selectMergeToCompanyIntoComponent = (
    <SelectMergeToCompanyInto
      {...{ show }}
      {...{ handleOpenedMergeTo }}
      {...{ handleMerge }}
      {...{ companyName }}
      {...{ handleCompanySelection }}
      {...{ selectedCompany }}
    />
  );

  return (
    <>
      {show && selectMergeToCompanyIntoComponent}
      <MergedCompanyConfirmation
        show={openedMergedCompanyConfirmation}
        handleOpenedMergedCompanyConfirmation={handleOpenedMergedCompanyConfirmation}
        {...{ selectedCompany }}
        {...{ selectedRadio }}
        {...{ handleRadioSelection }}
        {...{ handleMergeTo }}
      />
    </>
  );
};

export default MergeTo;
