import { Route, Routes } from 'react-router';
import NotFoundPage from 'src/components/commons/notFound';
import { PageSubTableDivisions } from '../../pages';

const SubTableDivisionsRoutes = () => (
  <Routes>
    <Route element={<PageSubTableDivisions />}>
      <Route index path='type' element={<h2>Divisions type</h2>} />
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  </Routes>
);

export default SubTableDivisionsRoutes;
