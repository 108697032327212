import _ from 'lodash';
import { Form } from 'antd';
import { FC, PropsWithChildren, useState, useEffect } from 'react';
import ModularModal, {
  Props as ModularModalProps,
} from 'src/components/commons/exportModal/ModularModal'; // Adjust the path accordingly
import ExportCompaniesProvider, {
  useExportCompaniesContext,
} from './contexts/export-companies.context';
import ExportCompaniesFilters from './components/filters';
import ExportCompaniesMoreFilters from './components/moreFilters';
import ExportCompaniesFields from './components/fields';
import ExportCompaniesPreview from './components/preview';

type Props = ModularModalProps & {
  onClose: () => void;
};

type LeftContentProps = {
  doExport: boolean;
  closeModal: () => void;
  setDoExport: (doExport: boolean) => void;
};

const LeftContent = (actions: LeftContentProps) => {
  const { doExport, closeModal, setDoExport } = actions;
  const { form, searchFields, setSearchFields, exportTable, dataSource, reset } =
    useExportCompaniesContext();

  useEffect(() => {
    if (doExport) {
      if (dataSource.length > 0) {
        exportTable();
        closeModal();
      }
      setDoExport(false);
    }
  }, [doExport]);

  return (
    <Form
      layout='vertical'
      form={form}
      onValuesChange={(_ch, fields) => {
        const values = Object.entries(_.omitBy(fields, _.isNil)).reduce((acc, [key, value]) => {
          _.set(acc, key, value);
          return acc;
        }, {}) as { searchFields: Record<string, string[]> };

        if (!_.isEqual(fields.searchFields, searchFields)) {
          setSearchFields(values.searchFields);
          reset();
        }
      }}
    >
      <ExportCompaniesFilters />
      <ExportCompaniesMoreFilters />
      <ExportCompaniesFields />
    </Form>
  );
};

const ProviderComponent: FC<PropsWithChildren> = ({ children }) => (
  <ExportCompaniesProvider>{children}</ExportCompaniesProvider>
);

const ExportCompaniesModal: FC<Props> = ({ onClose }) => {
  const [doExport, setDoExport] = useState<boolean>(false);
  return (
    <ModularModal
      onClose={onClose}
      modalTitle='Export Company'
      testId='export-companies-modal'
      leftContent={
        <LeftContent doExport={doExport} closeModal={onClose} setDoExport={setDoExport} />
      }
      rightContent={<ExportCompaniesPreview />}
      ProviderComponent={ProviderComponent}
      width={1400}
      onOkAction={() => setDoExport(true)}
      okText='Export'
      rowStyle={{ height: 'calc(100vh - 300px)', overflow: 'auto' }}
    />
  );
};

export default ExportCompaniesModal;
