import styled from 'styled-components';

const StyleDiv = styled.div`
  padding-top: 8px 50px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 295px;
`;

export default StyleDiv;
