import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { SortModel, SubTableColumn } from 'src/components/commons/subTable/types';
import { CompanyPitchesModel, ContactGroupModel } from 'src/graphql/schema-types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import handleError from 'src/utils/functions/handleError';
import { useParams } from 'react-router';
import COLUMNS from '../constants';
import {
  CREATE_COMPANY_PITCH,
  DELETE_COMPANY_PITCH,
  UPDATE_COMPANY_PITCH,
} from '../graphql/mutations';
import GET_ALL_COMPANY_PITCHES, { GET_HEADERS } from '../graphql/queries';
import { GetAllCompanyPitches, GetCompanyPitchGridHeaders } from '../types';

const usePitches = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id: companyId } = useParams();
  const [deleting, setDeleting] = useState(false);
  const [getAll, { loading }] = useLazyQuery<GetAllCompanyPitches>(GET_ALL_COMPANY_PITCHES, {
    fetchPolicy: 'network-only',
  });
  const [getHeaders] = useLazyQuery<GetCompanyPitchGridHeaders>(GET_HEADERS, {
    fetchPolicy: 'network-only',
  });

  const [create] = useMutation(CREATE_COMPANY_PITCH);
  const [update] = useMutation(UPDATE_COMPANY_PITCH);
  const [remove] = useMutation(DELETE_COMPANY_PITCH);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState<SubTableColumn[]>([]);
  const [newRecord, setNewRecord] = useState({
    name: '',
  });

  const fetchPage: FetchFunc = ({ start, renderLen }) =>
    getAll({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        companyId: Number(companyId),
      },
    }).then((res) => {
      if (res.data?.GetAllCompanyPitches) {
        return {
          results: res.data?.GetAllCompanyPitches.map((element) => ({
            ...element,
            title: `<strong>${element.name} ·</strong> ${
              element?.text?.replace(/<\/?[^>]+(>|$)/g, '') ?? ''
            }`,
          })),
          total: res.data?.GetAllCompanyPitches.length,
        };
      }
      return {
        results: [],
        total: 0,
      };
    });

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(fetchPage);

  useEffect(() => {
    getHeaders().then((res) => {
      const headerFiltered = res.data?.GetCompanyPitchGridHeaders.filter(
        (header) => header.dataIndex === 'name',
      ).map((header) => ({
        ...header,
        dataIndex: 'title',
        title: header.headerName?.toLocaleUpperCase(),
      }));
      setColumns(headerFiltered ?? COLUMNS);
    });
  }, []);

  const autocompleteFieldSearch = (searchField: string, search: string) => {
    let filterData = [];
    let mapData = [];

    if (searchField.includes('.')) {
      const keys = searchField.split('.');
      const [keyParent, keyPropertyParent] = keys;
      filterData = dataSource.filter((item: any) =>
        item[keyParent][keyPropertyParent].toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      );
    } else {
      filterData = dataSource.filter((item: any) =>
        item[searchField].toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      );
    }

    if (filterData.length) {
      mapData = filterData.map((result: any) => {
        if (searchField.includes('.')) {
          const keys = searchField.split('.');
          const [keyParent, keyPropertyParent] = keys;
          return {
            value: result[keyParent][keyPropertyParent],
            label: result[keyParent][keyPropertyParent],
          };
        }

        return {
          value: result[searchField],
          label: result[searchField],
        };
      });

      return Promise.resolve({
        results: filterData,
        filter: mapData,
      });
    }

    return Promise.resolve({
      results: [],
      filter: [],
    });
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
    });
  };

  const createNewRecord = async (record: CompanyPitchesModel) => {
    if (!record) return;
    const { name, text } = record;
    if (!name) return;
    setDeleting(true);
    await create({
      variables: {
        createCompanyPitchInput: {
          name: capitalizeFirstWord(name as string),
          text,
          companyId: Number(companyId),
        },
      },
    })
      .then((pitch) => {
        setDataSource([
          {
            title: `<strong>${pitch.data.CreateCompanyPitch.name} ·</strong> ${
              pitch.data?.CreateCompanyPitch?.text?.replace(/<\/?[^>]+(>|$)/g, '') ?? ''
            }`,
            id: pitch.data.CreateCompanyPitch.id,
            name: pitch.data.CreateCompanyPitch.name,
            text: pitch.data.CreateCompanyPitch.text,
          },
          ...dataSource,
        ]);
      })
      .finally(() => setDeleting(false));
  };

  const handleUpdateRows = async (rows: CompanyPitchesModel[]) => {
    setDeleting(true);
    await Promise.allSettled(
      rows.map((row) => {
        const { id, name, text } = row;
        return update({
          variables: {
            updateCompanyPitchInput: {
              companyId: Number(companyId),
              id,
              name: capitalizeFirstWord(name as string),
              text,
            },
          },
        });
      }),
    );
    setDeleting(false);
    reset();
  };

  const handleDeleteRows = async (rows: ContactGroupModel[]) => {
    setDeleting(true);
    await Promise.allSettled(
      rows.map((row) =>
        remove({ variables: { removeCompanyPitchId: row.id } }).catch((err) => {
          setDeleting(false);
          handleError(err);
          throw err;
        }),
      ),
    );
    setDeleting(false);
    reset();
  };

  const postData = (args: any) => {
    if (args?.dataIndex === 'relatedCategory') {
      if (args?.value?.toLowerCase() === 'commission split') {
        setNewRecord((prev) => ({
          ...prev,
          companyManufacturer: [],
          activeNewRowExpandableOnNewItem: true,
          [args?.dataIndex as string]: args?.value as string,
          autoSave: !!args?.autoSave,
        }));
      } else {
        setNewRecord((prev) => ({
          ...prev,
          companyManufacturer: null,
          activeNewRowExpandableOnNewItem: false,
          [args?.dataIndex as string]: args?.value as string,
          autoSave: !!args?.autoSave,
        }));
      }
    }

    if (args?.dataIndex === 'relatedCompany') {
      setNewRecord((prev) => ({
        ...prev,
        relatedCompanyId: args?.value[0]?.id,
        relatedCompany: args?.value[0]?.label,
        autoSave: !!args?.autoSave,
      }));

      return;
    }
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const handleClearFilter = () => {
    reset();
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    dataSource,
    onListRender,
    handleUpdateRows,
    spinning: loading || deleting,
    newRecord,
    postData,
    resetNewRecord,
    createNewRecord,
    handleClearFilter,
    handleDeleteRows,
  };
};

export default usePitches;
