import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState, useRef } from 'react';
import { Option, SortModel } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import { CompanyTypeAliasModel } from 'src/graphql/schema-types';
import handleError from 'src/utils/functions/handleError';
import {
  COLUMNS_TABLE_PREDEFINED_TYPES,
  COLUMNS_TABLE_SELECTED,
  COLUMNS_TO_SELECT_OPTIONS,
} from '../constants/table';
import { GetPredefinedTypesFromElasticSearchType, PredefinedTypesElasticTypes } from '../types';
import { GET_ALL_PREDEFINED_TYPES } from '../graphql/queries';
import { REMOVE_CUSTOM_TYPE, UPDATE_CUSTOM_TYPES } from '../graphql/mutations';
import { useSubTableTypeContext } from '../contexts/subtabletype.context';

const usePredefinedTypesTable = () => {
  const { setPredefinedTypeChanges } = useSubTableTypeContext();
  const [getAll, getAllPredefinedTypesResponse] =
    useLazyQuery<GetPredefinedTypesFromElasticSearchType>(GET_ALL_PREDEFINED_TYPES, {
      fetchPolicy: 'network-only',
    });

  const [getAllFilter] = useLazyQuery<GetPredefinedTypesFromElasticSearchType>(
    GET_ALL_PREDEFINED_TYPES,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [update] = useMutation(UPDATE_CUSTOM_TYPES);
  const [remove] = useMutation(REMOVE_CUSTOM_TYPE);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState(COLUMNS_TABLE_PREDEFINED_TYPES);
  const [columnsToSelectOption] = useState(COLUMNS_TO_SELECT_OPTIONS);
  const [columnsHeaderSelected] = useState(COLUMNS_TABLE_SELECTED);
  const predefinedTypesData = useRef<PredefinedTypesElasticTypes[]>();

  useEffect(() => {
    getAll().then((res) => {
      if (!predefinedTypesData.current) {
        predefinedTypesData.current = res.data?.GetCompaniesBaseTypes;
      }
    });
  }, [getAllPredefinedTypesResponse.data]);

  const autocompleteFieldSearch = (searchField: string, value: string) =>
    getAllFilter({
      variables: {
        criteria: {
          pagination: { size: 25 },
        },
        searchField: { [searchField]: [value] },
      },
    }).then((res) => {
      if (res.data?.GetCompaniesBaseTypes) {
        return {
          results: res.data.GetCompaniesBaseTypes as any[],
          filter: res.data.GetCompaniesBaseTypes.map((result: any) => ({
            value: result[searchField],
            label: result[searchField],
          })) as Option[],
        };
      }

      return {
        results: [],
        filter: [],
      };
    });

  const checkIfExistPredefinedTypesWithCustomNameChanged = async (rowsEdited: any[]) => {
    if (getAllPredefinedTypesResponse.data?.GetCompaniesBaseTypes && rowsEdited.length !== 0) {
      rowsEdited.forEach((rowEdited) => {
        const { id, name } = rowEdited;
        const predefinedTypeWithCustomNameChanged =
          getAllPredefinedTypesResponse.data?.GetCompaniesBaseTypes.find(
            (predefinedType) => predefinedType.name !== name && predefinedType.id === id,
          );

        if (predefinedTypeWithCustomNameChanged) {
          setPredefinedTypeChanges?.(true);
        }
      });
    }
  };

  const updateCustomTypesAlias = async (rowsEdited: any[]) => {
    rowsEdited.forEach((row) => {
      const { id, name, color } = row;
      update({
        variables: {
          updateCompanyTypeAliasInput: {
            id,
            name,
            color,
          },
        },
      });
    });
  };

  const handleUpdateRows = async (rowsEdited: any[]) => {
    await updateCustomTypesAlias(rowsEdited);
    await checkIfExistPredefinedTypesWithCustomNameChanged(rowsEdited);
    getAll();
  };

  const handleDeleteRows = async (rows: CompanyTypeAliasModel[]) => {
    const messages = {
      400: {
        title: 'This record cannot be deleted',
        message: 'This value is linked to the company. You can not delete it.',
      },
    };
    await Promise.all(
      rows.map((row) =>
        remove({ variables: { id: row.id } })
          .then(({ errors }) => {
            if (errors) {
              handleError(errors[0], messages);
            }
          })
          .catch((err) => {
            handleError(err, messages);
            throw err;
          }),
      ),
    );

    getAll();
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    handleUpdateRows,
    handleDeleteRows,
    pagination,
    columns,
    setColumns,
    columnsToSelectOption,
    columnsHeaderSelected,
    getAllPredefinedTypesResponse,
    predefinedTypesData,
  };
};

export default usePredefinedTypesTable;
