import styled from 'styled-components';

const StyledUnderConstruction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;

  .message {
    color: var(--neutral-dark-grey, #4b4b4b);
    font-size: var(--message-font-size, 40px); // default is 40px
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-align: var(--message-text-align, initial); // default is initial
  }

  svg {
    width: var(--svg-width, 76px); // default is 76px
    height: var(--svg-height, 76px); // default is 76px
    margin: var(--svg-margin, initial); // default is initial
  }
`;

export default StyledUnderConstruction;
