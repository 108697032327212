import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { EmailModel } from 'src/models';
import emailTypeAdapter from './emailType.adapter';
import { optional } from './common';

const emailAdapter: AdapterFunc<GQL.EmailModel, EmailModel> = (input) => ({
  id: input.id,
  email: input.email!,
  emailType: optional(emailTypeAdapter, input.emailType),
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
});

export default emailAdapter;
