import {
  CompanyModel,
  UpdateCompanyInput,
  CreateAddressInput,
  CreateEmailInput,
  CreatePhoneInput,
} from 'src/graphql/schema-types';

export const prepareDataForm = (
  company: CompanyModel,
  customFieldsValues: Record<string, string>,
) => {
  const companyPhones = company.phones ?? [];
  const phonesSortedById = [...companyPhones].sort((a, b) => {
    if (a?.id && b?.id) {
      return a.id - b.id;
    }
    return 0;
  });

  const phones =
    phonesSortedById.length > 0
      ? phonesSortedById.map((p) => {
          const phone: CreatePhoneInput = {
            phoneType: p?.phoneType?.id ?? 0,
            phone: p?.phone,
            extension: p?.extension,
            areaCode: p?.areaCode,
            countryCode: p?.countryCode,
            id: p?.id,
          };
          return phone;
        })
      : [];

  const addresses =
    company.addresses?.map((a) => {
      const address: CreateAddressInput = {
        address: a?.address,
        formattedAddress: a?.formattedAddress,
        city: a?.city,
        country: a?.country?.id,
        state: a?.state?.id,
        addressType: a?.addressType?.id,
        geopositionLatitude: a?.geopositionLatitude,
        geopositionLongitude: a?.geopositionLongitude,
        territory: a?.territory?.id,
        pobox: a?.pobox,
        zipCode: a?.zipCode,
        region: a?.region?.id,
        id: a?.id,
      };

      return address;
    }) ?? [];

  const emails = company.emails
    ? company.emails?.map((e) => {
        const email: CreateEmailInput = {
          email: e?.email ?? '',
          emailType: e?.emailType?.id ?? 0,
          id: e?.id,
        };
        return email;
      })
    : [];

  const initialValuesForm = {
    name: company.name,
    website: company.website,
    addresses,
    phones,
    emails,
    companyTypeId: company.companyType?.id,
    salesTeam: company.salesTeam?.id,
    privateTeamId: company.privateTeam?.id,
    parent: company.parent?.id,
    callPatternId: company.callPattern?.id,
    categoryId: company.category?.id,
    divisionsId: company.divisions?.map((division) => division!.id),
    tenantDivision: company.tenantDivision?.id,
    rankId: company.rank?.id,
    visitFrequency: company.visitFrequency,
    printName: company.printName,
    isActive: company.isActive,
    hasPosAccount: company.hasPosAccount,
    hasHolydayCard: company.hasHolydayCard,
    shipDetail: company.shipDetail,
    tags: company.tags?.map((tag) => tag!.id),
    comments: company.comments,
    industriesId: company.industries?.map((industry) => industry!.id),
    productPotentials: company.productPotentials?.map((productPotential) => productPotential!.id),
    ...customFieldsValues,
  };

  return initialValuesForm;
};

export const prepareDataFromServer = (company: UpdateCompanyInput) => {
  const initialValuesForm = {
    name: company.name,
    website: company.website,
    addresses: company.addresses ?? [],
    phones: company.phones,
    emails: company.emails,
    companyTypeId: company.companyTypeId,
    salesTeam: company.salesTeam,
    privateTeamId: company.privateTeamId,
    parent: company.parent,
    callPatternId: company.callPatternId,
    categoryId: company.categoryId,
    divisionsId: company.divisionsId,
    tenantDivision: company.tenantDivision,
    rankId: company.rankId,
    visitFrequency: company.visitFrequency && Number(company.visitFrequency),
    printName: company.printName,
    isActive: company.isActive,
    hasPosAccount: company.hasPosAccount,
    hasHolydayCard: company.hasHolydayCard,
    shipDetail: company.shipDetail,
    tags: company.tags,
    comments: company.comments,
    industriesId: company.industriesId,
    productPotentials: company.productPotentials ?? [],
  };

  return initialValuesForm;
};

export default prepareDataForm;
