import { gql } from '@apollo/client';

export const CREATE_PRODUCT_POTENTIAL = gql`
  mutation CreateProductPotential($createProductPotentialInput: CreateProductPotentialInput!) {
    CreateProductPotential(createProductPotentialInput: $createProductPotentialInput) {
      id
      name
      description
    }
  }
`;

export const UPDATE_PRODUCT_POTENTIAL = gql`
  mutation UpdateProductPotential($updateProductPotentialInput: UpdateProductPotentialInput!) {
    UpdateProductPotential(updateProductPotentialInput: $updateProductPotentialInput) {
      id
      name
      description
    }
  }
`;

export const REMOVE_PRODUCT_POTENTIAL = gql`
  mutation RemoveProductPotential($removeProductPotentialId: Int!) {
    RemoveProductPotential(id: $removeProductPotentialId)
  }
`;
