import { gql } from '@apollo/client';

const GET_ALL_INDUSTRY = gql`
  query GetAllIndustries2($criteria: IndustryCriteriaInput!, $searchFields: FindIndustryInput) {
    GetAllIndustries2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
        description
      }
      total
    }
  }
`;

export default GET_ALL_INDUSTRY;
