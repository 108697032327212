import { SubTableColumn } from 'src/components/commons/subTable/types';

const COLUMNS: SubTableColumn[] = [
  {
    title: 'NAME',
    dataIndex: 'firstName',
    editable: true,
    width: 200,
    unique: true,
    required: true,
    minWidth: 270,
    dataType: 'string',
    hidden: false,
    dataStructure: 'company',
    headerName: 'NAME',
    options: [],
    orderField: 'name',
    searchField: 'name',
  },
  {
    title: 'JOB',
    dataIndex: 'businessInfo',
    editable: true,
    width: 200,
    unique: true,
    required: true,
    key: 'jobTitle',
    minWidth: 270,
    dataType: 'string',
    hidden: false,
    dataStructure: 'string',
    headerName: 'JOB',
    options: [],
    orderField: 'category',
    searchField: 'category',
  },
  {
    title: 'PHONE',
    dataIndex: 'businessInfo.phones[0].phone',
    editable: true,
    width: 200,
    unique: true,
    required: true,
    // key: 'role',
    minWidth: 270,
    dataType: 'string',
    hidden: false,
    dataStructure: 'string',
    headerName: 'PHONE',
    options: [],
    orderField: 'role',
    searchField: 'role',
  },
  {
    title: 'EMAIL',
    dataIndex: 'businessInfo.emails[0].email',
    editable: true,
    width: 200,
    unique: true,
    required: true,
    // key: 'role',
    minWidth: 270,
    dataType: 'string',
    hidden: false,
    dataStructure: 'string',
    headerName: 'EMAIL',
    options: [],
    orderField: 'role',
    searchField: 'role',
  },
  {
    title: 'OPPORTUNITIES',
    dataIndex: 'opportunities',
    editable: true,
    width: 200,
    unique: true,
    required: true,
    key: 'role',
    minWidth: 270,
    dataType: 'string',
    hidden: false,
    dataStructure: 'string',
    headerName: 'OPPORTUNITIES',
    options: [],
    orderField: 'role',
    searchField: 'role',
  },
];

export default COLUMNS;
