import { Route, Routes } from 'react-router';
import NotFoundPage from 'src/components/commons/notFound';
import { PageApplicationSettings } from './pages';
import TabCustomLabelsTable from './tabs/customFields/TabCustomFieldsTable';
import TabFieldSettings from './tabs/fieldSettings/TabFieldSettings';
import TabConfiguration from './tabs/configurations/TabConfigurations';

const ApplicationSettingsRoutes = () => (
  <Routes>
    <Route element={<PageApplicationSettings />}>
      <Route index path='configuration' element={<TabConfiguration />} />
      <Route index path='custom_labels' element={<h2>Application Setting Custom Labels</h2>} />
      <Route index path='custom_fields' element={<TabCustomLabelsTable />} />
      <Route index path='app_custom_features' element={<h2>App Custom Features</h2>} />
      <Route
        index
        path='user_custom_features'
        element={<h2>Application Setting User Custom Features</h2>}
      />
      <Route index path='integrations' element={<h2>Application Setting Integrations</h2>} />
      <Route index path='field_settings' element={<TabFieldSettings />} />
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  </Routes>
);

export default ApplicationSettingsRoutes;
