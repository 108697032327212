import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_BUSINESS_INFO = gql`
  mutation CreateBusinessInfo($createBusinessInfoInput: CreateContactBusinessInfoInput!) {
    CreateBusinessInfo(createContactBusinessInfoInput: $createBusinessInfoInput) {
      id
    }
  }
`;
