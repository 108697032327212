import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { AdditionalFieldModel, Query } from 'src/graphql/schema-types';
import { fakeCreateOne, fakeDeleteOne, fakeUpdateOne, fakeGetDataModule } from '../mocks';
import GET_ALL_CUSTOM_FIELDS from '../graphql/queries';

type AllAdditionalFieldsResult = Pick<Query, 'GetAllAdditionalFields'>;

const useCustomFieldsStore = () => {
  const [entities, setEntities] = useState<AdditionalFieldModel[]>([]);
  const [entitiesDataModule, setEntitiesDataModule] = useState<any[]>([]);

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const [loadAll] = useLazyQuery<AllAdditionalFieldsResult>(GET_ALL_CUSTOM_FIELDS, {
    fetchPolicy: 'network-only',
  });

  const [loadAllFilter] = useLazyQuery<AllAdditionalFieldsResult>(GET_ALL_CUSTOM_FIELDS, {
    fetchPolicy: 'network-only',
  });

  const mutationDeleteOne = fakeDeleteOne;
  const mutationUpdateOne = fakeUpdateOne;
  const mutationCreateOne = fakeCreateOne;

  const queryGetAllDataModule = fakeGetDataModule;

  const getAll = (variables: any) => {
    setLoading(true);
    return loadAll({
      variables,
    })
      .then((result) => {
        if (result.data?.GetAllAdditionalFields?.results) {
          setEntities(result.data.GetAllAdditionalFields.results);
          setTotal(result.data.GetAllAdditionalFields.total!);
        }
        return result;
      })
      .catch((err) => {
        setEntities([]);
        setTotal(0);
        setError(err);
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getAllFilter = (variables: any) =>
    loadAllFilter({
      variables,
    })
      .then((result) => {
        if (result.data?.GetAllAdditionalFields?.results) {
          setEntities(result.data.GetAllAdditionalFields.results);
          setTotal(result.data.GetAllAdditionalFields.total!);
        }
        return result;
      })
      .catch((err) => {
        setEntities([]);
        setTotal(0);
        setError(err);
        throw err;
      });

  const getAllDataModule = () =>
    queryGetAllDataModule()
      .then((result) => {
        if (result.data?.GetAllDataModule?.results) {
          setEntitiesDataModule(result.data.GetAllDataModule.results);
        }
        return result;
      })
      .catch((err) => {
        setError(err);
        throw err;
      });

  const removeOne = (id: number) => {
    setDeleting(true);
    return mutationDeleteOne({
      variables: {
        id,
      },
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const updateOne = (updateCallPatternInput: any) => {
    setUpdating(true);
    mutationUpdateOne({
      variables: {
        updateCallPatternInput,
      },
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const createOne = (createCompanyCallPatternInput: any) => {
    setCreating(true);
    return mutationCreateOne({
      variables: {
        createCompanyCallPatternInput,
      },
    })
      .catch((err) => {
        setError(err);
        throw err;
      })
      .finally(() => {
        setCreating(false);
      });
  };

  return {
    entities,
    entitiesDataModule,
    total,
    loading,
    updating,
    deleting,
    creating,
    error,
    getAll,
    getAllFilter,
    removeOne,
    updateOne,
    createOne,
    getAllDataModule,
  };
};

export default useCustomFieldsStore;
