import React from 'react';
import CustomSelect from 'src/components/commons/customSelect';
import { GridPreferenceNamesTypes } from 'src/types/companiesTypes';

interface Props {
  gridPreferencesNames?: GridPreferenceNamesTypes[];
  gridPreferenceActive?: GridPreferenceNamesTypes | undefined;
  gridPreferenceOnChangeHandler: (e: React.FormEvent<HTMLInputElement>) => void;
  defaultValue?: boolean;
  style?: React.CSSProperties;
}

const GridPreferences = ({
  gridPreferencesNames,
  gridPreferenceActive,
  gridPreferenceOnChangeHandler,
  defaultValue,
  style,
  ...rest
}: Props) => {
  const handlePreference = (e: React.FormEvent<HTMLInputElement>) => {
    gridPreferenceOnChangeHandler(e);
  };

  return (
    <CustomSelect
      {...rest}
      options={gridPreferencesNames as []}
      externalValue={gridPreferenceActive}
      handleChange={handlePreference}
      defaultValue={defaultValue ? 'Grid Preferences' : undefined}
      style={{ width: '250px', color: '#0078D4', ...style }}
      dataTestId='gridPreferencesOptions-select-id'
    />
  );
};

export default GridPreferences;
