import { createGlobalStyle } from 'styled-components';

const CreateContactStyles = createGlobalStyle`
  .new-company-modal-header-container {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-grow: 1;
  }

  .ant-modal-footer {
    border-radius: 0 0 8px 8px !important;
  }

  .ant-form-item {
    margin-bottom: 6px !important;
  }
  
  .ant-form-item-label {
    padding: 0 !important;
  }

  .add-company-button {
    padding: 0; 
    margin: 0 !important!;
  }

  .progress-bar {
    flex-grow: 1;
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .ant-progress-text {
    color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
    /* Body/regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px
  }

  .new-company-modal-title {
    color: rgb(0, 10, 104) !important;
  }
  .progress-bar-title-container {
    display: block;
    color: #858585;
    /* Body/regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .progress-bar-container {
    display: block;
    flex-grow: 1;
  }


  .form-control {
    border-radius: 0 !important;
    width: 100% !important;
    height: 32px !important;
  }

  .new-company-modal-footer-container {
    display: flex;
    gap: 16px;
  }

  .section-title {
    color: var(--crm-primary-primary, #0078D4) !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 28x !important;
  }
`;

export default CreateContactStyles;
