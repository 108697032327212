import React from 'react';
import { Spin, Row, Form } from 'antd';
import { useContactContext } from 'src/context/contact.context';
import CustomFieldRenderer from './renderer';

const CustomField = () => {
  const {
    customFieldPayload,
    handleCustomFieldPayload,
    showSpinCustomFields,
    integratedCustomField,
    activeExpandedEdition,
  } = useContactContext();

  const form = Form.useFormInstance();

  const handlerInputs = (event: any, id: string, selectType?: string) => {
    let innerData: string | number | boolean | string[];
    const innerCustomFieldPayload = customFieldPayload;
    const multiSelData: string[] = [];
    const existCustomField = integratedCustomField?.find((item) => item.id === id);
    if (selectType === 'multi_select_dropdown') {
      if (existCustomField) {
        event.forEach((element: string) => {
          const existElement = existCustomField?.options?.find((item) => item.id === element);
          if (existElement) {
            multiSelData.push(existElement.id);
          }
        });
      }
    }

    switch (selectType) {
      case 'multi_select_dropdown': {
        innerData = JSON.stringify(multiSelData);
        break;
      }
      case 'checkbox': {
        innerData = JSON.stringify(event?.target?.checked);
        break;
      }
      case 'dropdown': {
        innerData = event;
        break;
      }
      case 'integer': {
        innerData = JSON.stringify(event);
        break;
      }
      case 'decimal': {
        innerData = event.toFixed(2);
        break;
      }
      case 'date': {
        innerData = event;
        break;
      }
      default:
        innerData = event?.target?.value as string;
        break;
    }

    if (customFieldPayload.length === 0) {
      handleCustomFieldPayload([
        {
          id,
          value: innerData,
          relationId: existCustomField?.relationId ?? undefined,
        },
      ]);
    } else {
      const index = innerCustomFieldPayload.findIndex((element) => element.id === id);
      if (index >= 0) {
        innerCustomFieldPayload.splice(index, 1);
        innerCustomFieldPayload.push({
          id,
          value: innerData,
          relationId: existCustomField?.relationId ?? undefined,
        });
        handleCustomFieldPayload(innerCustomFieldPayload);
      } else {
        innerCustomFieldPayload.push({
          id,
          value: innerData,
          relationId: existCustomField?.relationId ?? undefined,
        });
        handleCustomFieldPayload(innerCustomFieldPayload);
      }
    }
  };

  if (showSpinCustomFields) {
    return (
      <Row align='middle' justify='center' style={{ padding: '2rem 0' }}>
        <Spin size='large' />
      </Row>
    );
  }

  return (
    <Form name='formCustomFields' form={form} component={false}>
      {integratedCustomField?.map((element) => (
        <CustomFieldRenderer
          optionToRender={element.dataType}
          label={element.label}
          isRequired={element.isRequired}
          options={element.options}
          value={
            (element.dataType === 'hyperlink' || element.dataType === 'checkbox') && element.value
          }
          id={element.id}
          key={element.id}
          {...{ handlerInputs }}
          readOnly={!activeExpandedEdition}
        />
      ))}
    </Form>
  );
};

export default CustomField;
