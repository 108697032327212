import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import SubTableGrid from './table';
import { useSubTableContext } from '../contexts/subtable.context';

export const FILTER_SPAN = 4;

const AdvancedFilterWithSubTableGrid = (props) => {
  const { showLeftFilter } = useSubTableContext();

  const { leftFilters } = props;

  const Comp = leftFilters;

  const filterSpan = useMemo(() => (showLeftFilter ? FILTER_SPAN : 0), [showLeftFilter]);
  const tableSpan = useMemo(() => (showLeftFilter ? 24 - FILTER_SPAN : 24), [showLeftFilter]);

  return (
    <Row>
      <Col
        span={filterSpan}
        style={{ transition: 'max-width 200ms', borderRight: '1px solid black' }}
      >
        {showLeftFilter && <div>{leftFilters && <Comp />}</div>}
      </Col>
      <Col span={tableSpan} style={{ marginLeft: 'auto', transition: 'max-width 200ms' }}>
        <SubTableGrid {...props} />
      </Col>
    </Row>
  );
};

export default AdvancedFilterWithSubTableGrid;
