import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'antd';
import { CompaniesContext } from 'src/context/companies.context';
import { useLazyQuery } from '@apollo/client';
import CustomButton from '../customButton';
import { CustomStyledInput } from '../createCompany/createCompany.styles';
import StyledMapModal from './styles/styledMapModal.styles';
import MapComponent from './mapComponent';
import GET_COMPANIES_BY_LOCATION_DISTANCE from './graphql/queries';
import { GetCompaniesByLocationDistance } from './types';

declare let google: any;
const MapModal = () => {
  const [getCompaniesLocation] = useLazyQuery<GetCompaniesByLocationDistance>(
    GET_COMPANIES_BY_LOCATION_DISTANCE,
  );
  const inputRef: any = useRef(null);
  const [nearbyCompanies, setNearbyCompanies] = useState([]);
  const { setActiveMapSearch, activeMapSearch } = useContext(CompaniesContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState(false);
  const [inputs, setInputs] = useState({
    distance: 0.2,
    city: '',
    zip: 0,
  });
  const [location, setLocation] = useState({
    lat: 0,
    long: 0,
  });
  const [locationToMap, setLocationToMap] = useState({
    lat: 0,
    long: 0,
  });
  const [myLocationActive, setMyLocationActive] = useState(false);

  const getMyCoordinates = () => {
    if (!('geolocation' in navigator)) return;
    navigator.geolocation.getCurrentPosition(
      (curr) => {
        setLocationToMap({
          lat: curr.coords.latitude,
          long: curr.coords.longitude,
        });
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setLocationToMap({
          lat: 0,
          long: 0,
        });
      },
    );
  };
  const getLocations = () => {
    getCompaniesLocation({
      variables: {
        location: {
          latitude: locationToMap.lat,
          longitude: locationToMap.long,
        },
        distance: inputs.distance * 1.609,
      },
    }).then((res) => {
      setNearbyCompanies((res.data?.GetCompaniesByLocationDistance.results as []) ?? []);
    });
  };

  useEffect(() => {
    if (!activeMapSearch) {
      setLocationToMap(location);
      if (!myLocationActive) setMyLocationActive(true);
    }
  }, [activeMapSearch]);

  useEffect(() => {
    getLocations();
  }, [locationToMap, inputs.distance]);

  useEffect(() => {
    getMyCoordinates();
  }, []);

  const handleInputsChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.name === 'distance' &&
      (Number(e.target.value) > 50 || Number(e.target.value) < 0.2)
    ) {
      setActiveMapSearch(true);
      let dist = 0;
      if (Number(e.target.value) > 50) dist = 50;
      if (Number(e.target.value) < 0.2) dist = 1;
      setInputs((prev) => ({
        ...prev,
        [e?.target.name as string]: dist,
      }));
      return;
    }
    if (e.target.name === 'distance') {
      setInputs((prev) => ({
        ...prev,
        [e?.target.name as string]: Number(e.target.value),
      }));
    }
  };

  const autoCompleteRef: any = useRef(null);

  useEffect(() => {
    if (inputRef.current && !autoCompleteRef.current) {
      autoCompleteRef.current = new google.maps.places.Autocomplete(inputRef.current.input, {
        fields: ['address_components', 'geometry', 'formatted_address'],
        types: ['address'],
        componentRestrictions: {
          country: ['USA', 'CA'],
        },
      });

      autoCompleteRef?.current.addListener('place_changed', () => {
        const place = autoCompleteRef?.current.getPlace();
        setInputs((prev) => ({
          ...prev,
          city: place.formatted_address,
        }));
        setLocation({
          lat: place.geometry.location.lat(),
          long: place.geometry.location.lng(),
        });
        setActiveMapSearch(true);
      });
    }
  }, []);

  const googleSearchByZipCode = async (zipCode: string) => {
    if (Number(zipCode) > 50) {
      setError(true);
    } else {
      setError(false);
    }
    const req = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&components=country:US|country:CA&key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
    );
    const res = await req.json();
    setLocation({
      lat: res.results[0]?.geometry?.location?.lat,
      long: res.results[0]?.geometry?.location?.lng,
    });
    setActiveMapSearch(true);
  };

  const handleCityAndZipCode = (e: ChangeEvent<HTMLInputElement>) => {
    setInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name === 'zip') {
      googleSearchByZipCode(e.target.value);
    }
  };

  const handleMyLocation = () => {
    setMyLocationActive(false);
    getMyCoordinates();
  };

  return (
    <StyledMapModal>
      <div className='inputs-container'>
        <CustomButton
          ghost
          title='My Location'
          type='primary'
          active={!myLocationActive}
          onClick={handleMyLocation}
          dataTestId='find-my-location'
        />
        <Form.Item label='Company nearby search distance (Miles)' name='distance'>
          <CustomStyledInput
            name='distance'
            data-testid='distance-input'
            defaultValue={inputs.distance}
            value={inputs.distance}
            onChange={handleInputsChange}
            type='number'
          />
        </Form.Item>
        {/* <Form.Item label='City' name='city'> */}
        <div>
          <p style={{ marginBottom: '0.3rem' }}>City :</p>
          <CustomStyledInput
            name='city'
            ref={inputRef}
            data-testid='city-input'
            // defaultValue={inputs.city}
            value={inputs.city}
            onChange={(e) => handleCityAndZipCode(e)}
            autoComplete='off'
          />
        </div>
        {/* </Form.Item> */}
        <Form.Item label='Zip' name='zip'>
          <CustomStyledInput
            name='zip'
            data-testid='zip-input'
            type='number'
            value={inputs.zip}
            onChange={handleCityAndZipCode}
          />
        </Form.Item>
      </div>
      <div className='map-container'>
        <MapComponent
          distance={inputs.distance}
          coordinates={locationToMap}
          nearbyCompanies={nearbyCompanies}
        />
      </div>
    </StyledMapModal>
  );
};

export default MapModal;
