import Avatar from 'src/components/commons/avatar';
import { FC } from 'react';
import { CopyOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import copyToClipboard from 'src/utils/functions/copyClipboard';
import { useCompanyContext } from 'src/context/company.context';
import { English } from 'src/languages/english';
import { SCardCompanyContact, SH5CardCompanyContact } from './styles/index.styles';
import ContactInfo from './contactInfo';
import VisibleNoVisibleInfo from './visibleNoVisibleInfo';
import MapAndLocation from './mapAndLocation';

interface Props {
  isCollapsed: boolean;
}

const CardCompanyContact: FC<Props> = ({ isCollapsed }) => {
  const { company } = useCompanyContext();

  const name =
    company?.mainContact?.firstName || company?.mainContact?.lastName
      ? `${company?.mainContact?.firstName ?? ''} ${
          company?.mainContact?.lastName ?? English.noData
        }`
      : English.noData;
  const title = company?.mainContact?.title ?? English.noData;
  const mainEmail = company?.mainContact?.businessInfo?.mainEmail?.email ?? English.noData;
  const mainPhone = company?.mainContact?.businessInfo?.mainPhone?.phone ?? English.noData;

  return (
    <SCardCompanyContact>
      <div className='company-contact-main'>
        <Avatar img='' />
        <div>
          <ContactInfo sellerName={name} title={title} email={mainEmail} phone={mainPhone} />
        </div>
      </div>

      {!isCollapsed && (
        <div className='company-contact-visible'>
          <VisibleNoVisibleInfo
            location={company?.mainAddress?.address ?? ''}
            website={company?.website ?? English.noData}
          />
        </div>
      )}

      {!!company?.mainAddress?.address && (
        <div className='company-contact-map'>
          <MapAndLocation address={company?.mainAddress?.address} />
          {isCollapsed && (
            <SH5CardCompanyContact data-testid='company-location'>
              <EnvironmentOutlined />
              {company.mainAddress.address}
              {company.mainAddress.address && (
                <Button onClick={() => copyToClipboard(company.mainAddress?.address ?? '')}>
                  <CopyOutlined />
                </Button>
              )}
            </SH5CardCompanyContact>
          )}
        </div>
      )}
    </SCardCompanyContact>
  );
};
export default CardCompanyContact;
