import { notification } from 'antd';
import ButtonReloadClearCache from 'src/components/buttonReloadClearCache/ButtonReloadClearCache';

const handleError = (
  error: any,
  messagesByCode: Record<
    number,
    {
      title: string;
      message: string;
    }
  > = {},
) => {
  notification.config({
    placement: 'topRight',
  });
  const gqlErrors = Object.getOwnPropertyDescriptor(error, 'graphQLErrors');
  if (gqlErrors?.value?.length) {
    const err = gqlErrors.value[0];
    const res = err.extensions?.exception;

    if (String(err.extensions?.code).match(/^5\d{2}$/)) {
      const messageDesc = Object.getOwnPropertyDescriptor(res, 'message');
      let message = messageDesc?.value;
      if (!message) {
        // This validation is becouse the backend is returning a 500 in the `message` field and a description of the error in the `error` field
        message = /\d{3}/.test(res.message) ? res.error : res.message;
      }

      let title = !/\d{3}/.test(res.error) ? res.error : 'Error';

      const code = /\d{3}/.test(res.message) ? res.message : res.error;

      if (messagesByCode[code]) {
        message = messagesByCode[code].message;
        title = messagesByCode[code].title;
      }

      if (message) {
        notification.error({
          message: title,
          description: message,
          key: 'error-fetching-data',
          btn: <ButtonReloadClearCache />,
        });

        return;
      }
    }

    if (String(err.extensions?.exception?.status).match(/^4\d{2}$/)) {
      const message = Object.getOwnPropertyDescriptor(res, 'message');

      if (message) {
        notification.error({
          message: 'Error',
          description: message.value,
          key: 'error-fetching-data',
          btn: <ButtonReloadClearCache />,
        });

        return;
      }
    }

    const code = err.extensions?.exception?.status;

    if (messagesByCode[code]) {
      notification.error({
        message: messagesByCode[code].title,
        description: messagesByCode[code].message,
        key: 'error-fetching-data',
        btn: <ButtonReloadClearCache />,
      });

      return;
    }
  }
  notification.error({
    message: 'Error',
    description: 'Something went wrong',
    key: 'error-fetching-data',
    btn: <ButtonReloadClearCache />,
  });
};

export default handleError;
