import React, { useRef, useState, useEffect } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
  FormEmailElementsType,
  SeparateComponentDataHandlerType,
  CompaniesHeaderType2,
} from 'src/types/companiesTypes';
import { emailVerificator } from 'src/utils/functions/companiesFunctions';
import CompaniesColumns from 'src/utils/tables/companiesTable';
import CustomEmailForm from './renderer';

interface CustomEmailHandlerType {
  required?: boolean;
  separateComponentDataHandler?: (
    data: SeparateComponentDataHandlerType[] | '',
    name?: string,
  ) => void;
}

const CustomEmailHandler: React.FC<CustomEmailHandlerType> = ({
  required,
  separateComponentDataHandler,
}) => {
  const { companiesHeaderResponse2 } = CompaniesColumns();

  const emailsType: CompaniesHeaderType2 =
    companiesHeaderResponse2.data?.GetCompaniesDepthGridHeader.find(
      (item: CompaniesHeaderType2) => item.dataIndex === 'mainEmail.emailType',
    );

  const internalEmailTypes = useRef(
    emailsType?.options?.map((element) => ({
      label: element.name,
      value: String(element.id),
    })) || [],
  );

  const primaryEmailType = internalEmailTypes.current?.find((item) =>
    item.label?.toLocaleLowerCase().includes('primary' || 'main'),
  );

  const defaultEmailType =
    Number(internalEmailTypes?.current?.length) > 0
      ? internalEmailTypes?.current?.[0]
      : {
          label: '',
          value: '',
        };

  const formEmailData = useRef<FormEmailElementsType[]>([
    {
      formId: 'form-1',
      emailType: {
        value: String(primaryEmailType?.value || defaultEmailType?.value),
        label: String(primaryEmailType?.label || defaultEmailType?.label),
      },
      emailData: '',
      validEmail: true,
      verificationFeedBack: '',
    },
  ]);
  const [formElements, setFormElements] = useState<FormEmailElementsType[]>(formEmailData.current);
  const [emailRenderAction, setEmailRenderAction] = useState(false);

  const onInputChange = (formId: React.Key, data: string) => {
    const validEmail = emailVerificator(data);
    const index: number = formEmailData.current.findIndex((item) => item.formId === formId);
    formEmailData.current[index].emailData = data;
    if (validEmail) {
      formEmailData.current[index].validEmail = true;
      formEmailData.current[index].verificationFeedBack = 'success';
    } else {
      formEmailData.current[index].validEmail = false;
      formEmailData.current[index].verificationFeedBack = 'error';
    }
    if (data === '') {
      formEmailData.current[index].verificationFeedBack = '';
      formEmailData.current[index].validEmail = true;
    }
    setEmailRenderAction(!emailRenderAction);
  };

  const emailTypeAction = (elementId: React.Key, emailType: string) => {
    if (!internalEmailTypes.current) return;
    const innerElement = formEmailData.current;
    const index = innerElement.findIndex((item) => elementId === item.formId);
    const emailTypeIndex = internalEmailTypes.current.findIndex((item) => item.value === emailType);
    innerElement[index].emailType = {
      label: internalEmailTypes.current[emailTypeIndex].label,
      value: String(internalEmailTypes.current[emailTypeIndex].value),
    };
    formEmailData.current = innerElement;
    setEmailRenderAction(!emailRenderAction);
  };

  const addNewFormElement = () => {
    const innerElement = formEmailData.current;
    innerElement.push({
      formId: `form-${innerElement.length + 1}`,
      emailType: '',
      emailData: '',
      validEmail: false,
      verificationFeedBack: '',
    });
    formEmailData.current = innerElement;
    setEmailRenderAction(!emailRenderAction);
  };

  const deleteEmailAction = (elementId: React.Key) => {
    const innerElement = formEmailData.current;
    const index = innerElement.findIndex((item) => elementId === item.formId);
    innerElement.splice(index, 1);
    formEmailData.current = innerElement;
    setEmailRenderAction(!emailRenderAction);
  };

  useEffect(() => {
    setFormElements(formEmailData.current);
    if (separateComponentDataHandler) {
      let allowToSave = false;
      const innerValue = formEmailData.current.map((item) => {
        const { emailType, emailData, validEmail } = item;
        if (validEmail && emailData !== '') {
          allowToSave = true;
          return { emailType, emailData };
        }
        return { emailType, emailData: '' };
      });
      if (allowToSave) {
        separateComponentDataHandler(innerValue, 'emails');
      } else {
        separateComponentDataHandler('', 'emails');
      }
    }
  }, [emailRenderAction]);

  return (
    <>
      {React.Children.toArray(
        formElements.map((element) => (
          <CustomEmailForm
            deleteIcon={<DeleteOutlined />}
            elementData={element}
            {...{ addNewFormElement }}
            {...{ deleteEmailAction }}
            {...{ onInputChange }}
            {...{ required }}
            emailTypes={internalEmailTypes.current}
            {...{ emailTypeAction }}
          />
        )),
      )}
    </>
  );
};

export default CustomEmailHandler;
