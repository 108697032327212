import { Route, Routes } from 'react-router';
import NotFoundPage from 'src/components/commons/notFound';
import SubTableCompaniesRoutes from './modules/company';
import SubTableContactsRoutes from './modules/contacts';
import SubTableActivityJournalsRoutes from './modules/activityJournals';
import SubTableOpportunitiesRoutes from './modules/opportunities';
import SubTableDivisionsRoutes from './modules/divisions';

const SubTableRoutes = () => (
  <Routes>
    <Route index path='companies/*' element={<SubTableCompaniesRoutes />} />
    <Route index path='contacts/*' element={<SubTableContactsRoutes />} />
    <Route index path='activity-journals/*' element={<SubTableActivityJournalsRoutes />} />
    <Route index path='opportunities/*' element={<SubTableOpportunitiesRoutes />} />
    <Route index path='divisions/*' element={<SubTableDivisionsRoutes />} />
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

export default SubTableRoutes;
