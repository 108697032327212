import { Row } from 'antd';
import styled from 'styled-components';

export const StyledModalCreateEditActivity = styled(Row)`
  .ant-form-item-control {
    width: 100%;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-btn-compact-item {
    margin-bottom: 0 !important;
    border-radius: 50% !important;
    margin-left: 0.3rem !important;
  }
  .ant-col .ant-col-6 {
    display: flex;
    align-items: flex-end;
  }
  .companies-attendees-container {
    margin-top: 1.5rem;
    overflow-y: scroll;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    padding: 0.4rem;
  }
`;
