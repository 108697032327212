import { useLazyQuery, useMutation } from '@apollo/client';
import { useRef, useState } from 'react';
import {
  CallPatternModel,
  CreateCallPatternInput,
  Mutation,
  MutationCreateCallPatternArgs,
  MutationRemoveCallPatternArgs,
  MutationUpdateCallPatternArgs,
  Query,
  QueryGetAllCallPattern2Args,
} from 'src/graphql/schema-types';
import {
  CREATE_CALL_PATTERN,
  DELETE_CALL_PATTERN,
  UPDATE_CALL_PATTERN,
} from 'src/modules/settings/modules/subTables/modules/company/tabs/callPattern/graphql/mutations';
import GET_ALL_CALL_PATTERN from 'src/modules/settings/modules/subTables/modules/company/tabs/callPattern/graphql/queries';

type AllCallPatternsResult = Pick<Query, 'GetAllCallPattern2'>;
type RemoveCallPatternResult = Pick<Mutation, 'RemoveCallPattern'>;
type UpdateCallPatternResult = Pick<Mutation, 'UpdateCallPattern'>;
type CreateCallPatternResult = Pick<Mutation, 'CreateCallPattern'>;

const useCallPatternStore = () => {
  const currentMetaData = useRef<QueryGetAllCallPattern2Args | null>(null);
  const [entities, setEntities] = useState<CallPatternModel[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const [loadCallPattern] = useLazyQuery<AllCallPatternsResult>(GET_ALL_CALL_PATTERN, {
    fetchPolicy: 'network-only',
  });

  const [mutationDeleteOne] = useMutation<RemoveCallPatternResult, MutationRemoveCallPatternArgs>(
    DELETE_CALL_PATTERN,
  );

  const [mutationUpdateOne] = useMutation<UpdateCallPatternResult, MutationUpdateCallPatternArgs>(
    UPDATE_CALL_PATTERN,
  );

  const [mutationCreateOne] = useMutation<CreateCallPatternResult, MutationCreateCallPatternArgs>(
    CREATE_CALL_PATTERN,
  );

  const getAll = (variables: QueryGetAllCallPattern2Args) => {
    currentMetaData.current = variables;
    setLoading(true);
    return loadCallPattern({
      variables,
    })
      .then((result) => {
        if (result.data?.GetAllCallPattern2?.results) {
          setEntities(result.data.GetAllCallPattern2.results);
          setTotal(result.data.GetAllCallPattern2.total!);
        }
        return result;
      })
      .catch((err) => {
        setEntities([]);
        setTotal(0);
        setError(err);
        return err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllFilter = (variables: QueryGetAllCallPattern2Args) => {
    currentMetaData.current = variables;
    return loadCallPattern({
      variables,
    })
      .then((result) => {
        if (result.data?.GetAllCallPattern2?.results) {
          setEntities(result.data.GetAllCallPattern2.results);
          setTotal(result.data.GetAllCallPattern2.total!);
        }
        return result;
      })
      .catch((err) => {
        setEntities([]);
        setTotal(0);
        setError(err);
        return err;
      });
  };

  const removeOne = (id: number) => {
    setDeleting(true);
    return mutationDeleteOne({
      variables: {
        id,
      },
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const updateOne = (updateCallPatternInput: CallPatternModel) => {
    setUpdating(true);
    mutationUpdateOne({
      variables: {
        updateCallPatternInput,
      },
    })
      .then(() => {
        if (currentMetaData.current) {
          getAll(currentMetaData.current);
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const createOne = (createCompanyCallPatternInput: CreateCallPatternInput) => {
    setCreating(true);
    return mutationCreateOne({
      variables: {
        createCompanyCallPatternInput,
      },
    })
      .then((res) => {
        if (currentMetaData.current) {
          getAll(currentMetaData.current);
        }
        return res;
      })
      .catch((err) => {
        setError(err);
        return err;
      })
      .finally(() => {
        setCreating(false);
      });
  };

  return {
    entities,
    total,
    loading,
    updating,
    deleting,
    creating,
    error,
    getAll,
    removeOne,
    updateOne,
    createOne,
    getAllFilter,
  };
};

export default useCallPatternStore;
