import { Route, Routes } from 'react-router';
import NotFoundPage from 'src/components/commons/notFound';
import { PageSubTableOpportunities } from '../../pages';
import Stage from './tabs/stage';
import CloseReason from './tabs/closeReason';
import LeadSource from './tabs/leadSource';
import FailedReason from './tabs/failedReason';
import LineItemStatus from './tabs/lineItemStatus';
import Status from './tabs/status';
import StageGroup from './tabs/stageGroup';
import TypesSubtable from './tabs/typesSubtable';

const SubTableOpportunitiesRoutes = () => (
  <Routes>
    <Route element={<PageSubTableOpportunities />}>
      <Route index path='stage' element={<Stage />} />
      <Route index path='status' element={<Status />} />
      <Route index path='stage-group' element={<StageGroup />} />
      <Route index path='types' element={<TypesSubtable />} />
      <Route index path='close-reason' element={<CloseReason />} />
      <Route index path='failed-reason' element={<FailedReason />} />
      <Route index path='lead-source' element={<LeadSource />} />
      <Route index path='line-item-status' element={<LineItemStatus />} />
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  </Routes>
);

export default SubTableOpportunitiesRoutes;
