import { Button, Form } from 'antd';
import React, { FC, useState } from 'react';
import StyledPitchForm from 'src/components/companyDetails/components/pitches/styles/pitchForm.styles';
import { CustomModal } from '../../customModal';
import StyledModalCreateEdit from '../styles/modalCreateEdit.styles';
import EmailFields from './emailFields';

interface EmailModalProps {
  saveNewRecord?: (record?: any) => void;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmailModal: FC<EmailModalProps> = ({ saveNewRecord, setOpenModal }) => {
  const [isDisable, setIsDisable] = useState(false);
  const [form] = Form.useForm();

  // useEffect(() => {
  //   const setValues = () => {
  //     form.resetFields();
  //     if (record?.id) {
  //       form.setFieldsValue(record);
  //     }
  //   };
  //   setValues();
  // }, [record, openModal]);

  const onCancel = () => {
    setOpenModal?.(false);
  };

  const handleSave = async () => {
    setIsDisable(true);
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();
      await saveNewRecord?.(fields);
      onCancel();
    } catch (error) {
      console.error('Error in form validation:', error);
    } finally {
      setIsDisable(false);
    }
  };

  return (
    <StyledModalCreateEdit>
      <Form form={form} onFinish={handleSave}>
        <CustomModal
          modalStatus
          onCancelAction={onCancel}
          onOkAction={() => 0}
          centered
          customClass='custom-modal-edit'
          closable
          width={1400}
          modalTitle={<h3 style={{ fontWeight: 'normal' }}>New Mail</h3>}
          modalBody={
            <StyledPitchForm>
              <EmailFields />
            </StyledPitchForm>
          }
          footer={
            <>
              <Button type='default' onClick={onCancel}>
                Cancel
              </Button>
              <Button disabled={isDisable} type='primary' htmlType='submit' onClick={handleSave}>
                Send
              </Button>
            </>
          }
          style={{ height: 'max-content' }}
        />
      </Form>
    </StyledModalCreateEdit>
  );
};

export default EmailModal;
