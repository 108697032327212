/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DocumentNode,
  LazyQueryHookOptions,
  MutationHookOptions,
  OperationVariables,
  TypedDocumentNode,
  gql,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import useInfinityDataSource, { ListInfo } from 'src/hooks/useInfinityDataSource';
import buildInfinityHandler from 'src/utils/functions/buildInfinityHandler';
import handleError from 'src/utils/functions/handleError';
import getKeyValue from 'lodash/get';

type OpSpec = {
  op: DocumentNode | TypedDocumentNode<any, OperationVariables>;
  dataPath?: string;
};

type QuerySpec = OpSpec & {
  options?: LazyQueryHookOptions;
};

type MutationSpec = OpSpec & {
  options?: MutationHookOptions;
};

type FilterParam = {
  dataType: string;
  fieldName: string;
  operator: string;
  value: string;
};

type OrderParam = {
  field: string;
  sort: string;
};

type SearchFields = Record<string, string | number | boolean | null | string[]>;

export type AdapterFunc<TInput = any, TOutput = any> = (input: TInput) => TOutput;

export type OperationSpecs = {
  fetchAll: QuerySpec;
  fetchOne?: QuerySpec;
  createOne: MutationSpec;
  updateOne: MutationSpec;
  deleteOne?: MutationSpec;
  deleteInBulk?: MutationSpec;
};

type AdapterSpecs = {
  entityAdapter: AdapterFunc;
};

type ErrorHandlerFunc = typeof handleError;

type EntityAdapterParams = {
  entityName: string;
  operations: OperationSpecs;
  adapters: AdapterSpecs;
  isPaginated?: boolean;
  hasFilter?: boolean;
  hasSort?: boolean;
  errorHandler?: ErrorHandlerFunc;
};

const PLACEHOLDER_MUTATION = gql`
  mutation {
    FakeMutation {
      fakeMutation
    }
  }
`;

const PLACEHOLDER_QUERY = gql`
  query {
    FakeQuery {
      fakeQuery
    }
  }
`;

const useEntityAdapter = <TModel = any>({
  isPaginated,
  hasFilter = true,
  hasSort = true,
  operations,
  adapters: { entityAdapter },
  errorHandler = handleError,
}: EntityAdapterParams) => {
  const [fetchAll, { loading: fetchAllLoading }] = useLazyQuery(operations.fetchAll.op, {
    fetchPolicy: 'no-cache',
    ...operations.fetchAll.options,
  });
  const [fetchOne, { loading: fetchOneLoading }] = useLazyQuery(
    operations.fetchOne?.op || PLACEHOLDER_QUERY,
    operations.fetchOne?.options,
  );
  const [createOne, { loading: createOneLoading }] = useMutation(
    operations.createOne.op,
    operations.createOne.options,
  );
  const [updateOne, { loading: updateOneLoading }] = useMutation(
    operations.updateOne.op,
    operations.updateOne.options,
  );
  const [deleteSingle, { loading: deleteOneLoading }] = useMutation(
    operations.deleteOne?.op || PLACEHOLDER_MUTATION,
    operations.deleteOne?.options,
  );
  const [deleteInBulk] = useMutation(
    operations.deleteInBulk?.op || PLACEHOLDER_MUTATION,
    operations.deleteInBulk?.options,
  );
  const [filters, setFilters] = useState<FilterParam[]>([]);
  const [orderBy, setOrderBy] = useState<OrderParam[]>([]);
  const [searchFields, setSearchFields] = useState<SearchFields>({});
  const [totalValue, setTotalValue] = useState<any>(0);
  const [amount, setAmount] = useState<any>(0);

  const getPayload = (data: any) => {
    if (operations.fetchAll?.dataPath) {
      if (data[operations.fetchAll.dataPath].amount) {
        setAmount(data[operations.fetchAll.dataPath].amount);
      }
      setTotalValue(data[operations.fetchAll.dataPath].total);
      return getKeyValue(data, operations.fetchAll.dataPath);
    }

    return data;
  };

  const handleFetchAll = buildInfinityHandler({
    isPaginated,
    fetchAll,
    entityAdapter,
    errorHandler,
    getOptions(from, size) {
      const includeFilters = hasFilter && filters?.length > 0;
      const includeSort = hasSort && orderBy?.length > 0;
      const options: LazyQueryHookOptions = {};
      if (isPaginated) {
        options.variables = {
          criteria: {
            pagination: { from, size },
            filter: includeFilters ? filters : undefined,
            orderBy: includeSort ? orderBy : undefined,
          },
          searchFields,
        };
      }
      return options;
    },
    getPayload,
  });

  const { dataSource, reset, onListRender, setDataSource } = useInfinityDataSource(handleFetchAll);
  const [selected, setSelected] = useState<TModel | null>(null);

  const loading = useMemo(
    () =>
      fetchAllLoading ||
      fetchOneLoading ||
      createOneLoading ||
      updateOneLoading ||
      deleteOneLoading,
    [fetchAllLoading, fetchOneLoading, createOneLoading, updateOneLoading, deleteOneLoading],
  );

  useEffect(() => {
    reset(dataSource.length);
  }, [filters, searchFields, orderBy]);

  const loadById = async (id: string | number, idPath?: string) => {
    try {
      const { data, error } = await fetchOne({ variables: idPath ? { [idPath]: id } : { id } });

      if (error) throw error;
      let entityData = data;
      if (operations.fetchOne?.dataPath) {
        entityData = getKeyValue(data, operations.fetchOne.dataPath);
      }
      setSelected(entityAdapter(entityData));
    } catch (error) {
      errorHandler(error);
    }
  };

  const deleteBulk = async (ids: number[]) => {
    if (operations.deleteInBulk) {
      try {
        const { errors } = await deleteInBulk({ variables: { ids } });
        const error: any = errors?.[0] || errors;
        if (error) throw error;

        setTimeout(() => {
          reset(dataSource.length - ids.length);
        }, 1000);
      } catch (error) {
        errorHandler(error);
      }
    } else {
      console.warn('deleteInBulk operation is not defined');
    }
  };

  const deleteOne = async (id: any) => {
    try {
      const { errors } = await deleteSingle({ variables: { id } });
      const error: any = errors?.[0] || errors;
      if (error) throw error;
      setDataSource((prev) => prev.filter((data) => id !== data.id));
      return true;
    } catch (error) {
      errorHandler(error);

      return false;
    }
  };

  // eslint-disable-next-line consistent-return
  const createSingle = async (input: any, inputName?: string) => {
    try {
      const { data, errors } = await createOne({
        variables: inputName ? { [inputName]: input } : { input },
      });
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      if (errors) throw errors;

      setDataSource((prev) => [...prev, data]);

      return data;
    } catch (error) {
      errorHandler(error);
      return null;
    }
  };

  const adaptedFetchAll = (options?: Partial<LazyQueryHookOptions<any, OperationVariables>>) =>
    fetchAll(options).then((res) => {
      const data = getPayload(res.data);

      if (data.error || data.errors) {
        throw data.error || data.errors;
      }

      if (isPaginated) {
        return {
          ...data,
          results: data.results.map(entityAdapter),
        };
      }

      return data.map(entityAdapter);
    });

  const loadPage = (listInfo = { renderLen: 100, start: 1 } as ListInfo) => {
    onListRender(listInfo);
  };

  const reloadDataSource = async (setLoadingFunc: (boolean: boolean) => void) => {
    try {
      setLoadingFunc(true);
      setDataSource([]); // Clear the current dataSource temporarily
      const includeFilters = hasFilter && filters.length > 0;
      const includeSort = hasSort && orderBy.length > 0;
      const fetchOptions: LazyQueryHookOptions = {
        fetchPolicy: 'network-only', // Ensure fresh data from the server
        variables: isPaginated
          ? {
              criteria: {
                pagination: { from: 0, size: dataSource.length }, // Adjust according to your pagination schema
                filter: includeFilters ? filters : undefined,
                orderBy: includeSort ? orderBy : undefined,
              },
            }
          : {},
      };

      // Fetching data with updated options
      const { data } = await fetchAll(fetchOptions);
      setLoadingFunc(false);
      const newDataSource = getPayload(data);
      setDataSource(newDataSource?.results?.map(entityAdapter) ?? []); // Update dataSource with new data
    } catch (error) {
      console.error(error);
    }
  };

  return {
    dataSource,
    loading,
    selected,
    filters,
    orderBy,
    setOrderBy,
    searchFields,
    setSearchFields,
    reset,
    loadPage,
    fetchAll: adaptedFetchAll,
    loadById,
    createOne: createSingle,
    updateOne,
    deleteOne,
    setSelected,
    setFilters,
    deleteBulk,
    setDataSource,
    reloadDataSource,
    totalValue,
    amount,
  };
};

export default useEntityAdapter;
