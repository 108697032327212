import {
  CloseOutlined,
  EllipsisOutlined,
  FilterOutlined,
  ReloadOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { Badge, Tooltip } from 'antd';

import { useMemo, useState } from 'react';
import CheckboxMenu from 'src/components/checkboxMenu/CheckboxMenu';
import { removeDuplicates } from 'src/utils/functions/removeDuplicates';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import CustomCascader from 'src/components/commons/customCascader';
import { useContactsContext } from 'src/modules/contacts2/context/contacts.context';
import CustomButton from '../../customButton';
import SubHeaderComponent from '..';
import { StyledBadgeContainer, StyledSubHeaderActionButton } from './ContactsSubHeader.styles';
import GlobalCreate from '../../customHeader/GlobalCreate';

const ContactsSubHeader = () => {
  const [isHovering, setIsHovering] = useState(false);

  const [globalMenuActive, setGlobalMenuActive] = useState('');
  const {
    columns,
    columnsForFilterOptions,
    setColumns,
    handleOnPin,
    handleOnReload,
    totalValue,
    handleAllFilterSelection,
    multiFilterDefaultValue,
    multiFilterCount,
    handleClearMultiFilter,
    handleOpenCreateContact,
    openCreateContact,
  } = useContactsContext();

  const columnsToSelectOption = useMemo(() => {
    if (!columns) {
      return [];
    }
    return columns.map((column) => ({
      label: column.headerName,
      value: String(column.headerName),
      pinned: column.pinned,
    }));
  }, [columns]);

  const filterOption = useMemo(() => {
    if (!columnsForFilterOptions) {
      return [];
    }
    return columnsForFilterOptions
      ?.filter((col) => col.isFilterable)
      ?.map((column) => ({
        label: column.headerName,
        value: String(column.multiSearchField),
        children: Array.isArray(column.filterOptions)
          ? removeDuplicates(
              column?.filterOptions?.map((option: any) => ({
                label: option.name,
                value: option.id,
              })),
            )
          : [],
      }));
  }, [columnsForFilterOptions]);

  const columnsHeaderSelected = useMemo(() => {
    if (!columns) {
      return [];
    }
    return columns.filter((column) => column.selected !== false).map((column) => column.headerName);
  }, [columns]);

  const handleColumnsChange = (selectedValues: CheckboxValueType[]) => {
    if (setColumns) {
      const newColumns = columns?.map((column) => ({
        ...column,
        selected: selectedValues.includes(column.headerName),
        // unpin when user unselects the column to prevent invisible pinned columns
        pinned: selectedValues.includes(column.headerName) ? column.pinned : false,
      }));
      setColumns(newColumns);
    }
  };

  const handleOnOpenChange = (visible: boolean) => {
    if (!visible) {
      setGlobalMenuActive('');
    }
  };

  const handleColumnsIconClick = () => {
    setGlobalMenuActive(globalMenuActive === 'columns' ? '' : 'columns');
  };

  const totalVal = (
    <CustomButton
      type='text'
      title={`Total: ${totalValue ?? 0}`}
      onClick={() => {}}
      tooltipText={`Total: ${totalValue ?? 0}`}
      style={{ backgroundColor: '#ffffff', color: '#5D5D5D' }}
      tooltipColor='#FAFAFA'
      overlayInnerStyle={{ color: '#000' }}
    />
  );

  const filterButton = (
    <>
      <StyledBadgeContainer
        style={{ position: 'absolute', right: '118px' }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {isHovering ? (
          <Badge
            count={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  border: '2px solid #0078D4',
                  zIndex: 99999,
                  padding: '1px',
                }}
                onClick={handleClearMultiFilter}
              >
                <CloseOutlined
                  style={{
                    fontSize: '8px',
                  }}
                />
              </div>
            }
            className='badge-clear-position-active'
          />
        ) : (
          <Badge
            count={multiFilterCount}
            style={{
              zIndex: 9999999,
              display: multiFilterCount ? 'block' : 'none',
            }}
            className='badge-position-active'
            color='#0078D4'
          />
        )}
      </StyledBadgeContainer>
      <CustomCascader
        options={filterOption}
        handleOnChange={handleAllFilterSelection}
        defaultValue={multiFilterDefaultValue}
      >
        <StyledSubHeaderActionButton
          type='primary'
          shape='circle'
          active={false}
          icon={<FilterOutlined />}
        />
      </CustomCascader>
    </>
  );
  const reloadButton = (
    <Tooltip title='Update' color='#fff' overlayInnerStyle={{ color: '#000' }}>
      <StyledSubHeaderActionButton
        type='primary'
        shape='circle'
        active={false}
        onClick={handleOnReload}
        icon={<ReloadOutlined />}
      />
    </Tooltip>
  );
  const columnsButton = (
    <CheckboxMenu
      key='columns-menu'
      options={columnsToSelectOption || []}
      optionsSelected={columnsHeaderSelected}
      onCheckboxChange={handleColumnsChange}
      onOpenChange={handleOnOpenChange}
      noSearch
      hasPin
      onPinClick={handleOnPin}
    >
      <StyledSubHeaderActionButton
        key='columns'
        shape='circle'
        type='primary'
        icon={<TableOutlined />}
        onClick={handleColumnsIconClick}
        active={globalMenuActive === 'columns'}
      />
    </CheckboxMenu>
  );
  const elipsisButton = (
    <StyledSubHeaderActionButton
      type='primary'
      shape='circle'
      active={false}
      icon={<EllipsisOutlined />}
    />
  );
  const createMode = openCreateContact ? 'contact' : null;

  return (
    <>
      <GlobalCreate mode={createMode} onClose={() => handleOpenCreateContact()} />
      <SubHeaderComponent
        title='Contacts'
        actions={[reloadButton, filterButton, columnsButton, elipsisButton]}
        hasAddButton
        tooltipText='Create'
        handleAdd={handleOpenCreateContact}
        totalButton={totalVal}
      />
    </>
  );
};

export default ContactsSubHeader;
