import { gql } from '@apollo/client';

export const CREATE_CUSTOM_TYPES = gql`
  mutation CreateCompanyTypeAlias($createCompanyTypeAliasInput: CreateCompanyTypeAliasInput!) {
    CreateCompanyTypeAlias(createCompanyTypeAliasInput: $createCompanyTypeAliasInput) {
      id
      name
      color
      parent {
        id
        name
      }
    }
  }
`;

export const UPDATE_CUSTOM_TYPES = gql`
  mutation UpdateCompanyTypeAlias($updateCompanyTypeAliasInput: UpdateCompanyTypeAliasInput!) {
    UpdateCompanyTypeAlias(updateCompanyTypeAliasInput: $updateCompanyTypeAliasInput) {
      id
    }
  }
`;

export const REMOVE_CUSTOM_TYPE = gql`
  mutation RemoveCompanyTypeAlias($id: Int!) {
    RemoveCompanyTypeAlias(id: $id)
  }
`;
