import React, { FC } from 'react';

import { AllLeadSourceModel } from 'src/modules/settings/modules/subTables/modules/opportunities/tabs/leadSource/models/leadSource.model';

import GenericForm, { FieldType } from 'src/components/commons/genericForm';

type Props = {
  headers?: any;
  leadSourcesOptions?: AllLeadSourceModel;
  addDateAndTimeIntoTextFields?: (field: string) => void;
  status?: object[];
  oppStatusDefaultValue?: any;
};

const AdditionalInfoForm: FC<Props> = ({
  headers,
  leadSourcesOptions,
  status,
  addDateAndTimeIntoTextFields,
  oppStatusDefaultValue,
}) => {
  const formatedLeadSources = leadSourcesOptions?.GetAllLeadSources?.results?.map((leadSource) => ({
    value: leadSource.id,
    label: leadSource.name,
  }));

  const formatedSalesTeam = headers?.GetOpportunitiesGridHeaders?.find(
    (header: any) => header.dataIndex === 'salesTeam',
  )?.options?.map((element: any) => ({ value: element.id, label: element.name }));

  const formatedType = headers?.GetOpportunitiesGridHeaders?.find(
    (header: any) => header.dataIndex === 'type',
  )?.options?.map((element: any) => ({ value: element.id, label: element.name }));

  const fields: FieldType[] = [
    {
      name: 'priority',
      type: 'number',
      label: 'Priority',
      readOnly: false,
      span: 24,
      min: 1,
      max: 10,
    },
    {
      name: 'bidDate',
      readOnly: false,
      type: 'date',
      label: 'Bid Date',
      span: 12,
      style: { width: '100%' },
    },
    {
      name: 'salesTeam',
      readOnly: false,
      type: 'select',
      label: 'Sales Team',
      span: 12,
      options: formatedSalesTeam,
    },
    {
      name: 'leadSource',
      readOnly: false,
      type: 'select',
      label: 'Lead Source',
      span: 12,
      options: formatedLeadSources,
    },
    {
      name: 'type',
      readOnly: false,
      type: 'select',
      label: 'Type',
      span: 12,
      options: formatedType,
    },
    {
      name: 'competitors',
      readOnly: false,
      type: 'text',
      label: 'Competitors',
      span: 24,
      maxLength: 40,
    },
    {
      name: 'status',
      readOnly: false,
      type: 'select',
      label: 'Status',
      span: 12,
      options: status,
      defaultValue: oppStatusDefaultValue,
    },
    {
      name: 'estimatedCloseDate',
      readOnly: false,
      type: 'date',
      label: 'Estimated Close Date',
      span: 12,
      style: { width: '100%' },
    },
    {
      name: 'description',
      readOnly: false,
      type: 'textarea',
      label: 'Description',
      span: 24,
      placeholder: 'Autosize height based on content lines',
      maxLength: 2147483647,
    },
    {
      name: 'reportingComments',
      readOnly: false,
      type: 'textarea',
      addDateTimeButtom: true,
      span: 24,
      placeholder: 'Autosize height based on content lines',
      addDateAndTime: addDateAndTimeIntoTextFields,
    },
  ];
  return <GenericForm fields={fields} />;
};

export default AdditionalInfoForm;
