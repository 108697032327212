import React, { FC } from 'react';
import GenericForm from 'src/components/commons/genericForm';
import { validateEmptyInput } from 'src/utils/functions/validateEmptyInput';
import { AllUsersModel } from 'src/models/users.model';
import { CompanyOption, CustomSelectContactOptions } from '../context/newOpp.context';

type Props = {
  requiredFields?: string[];
  handleOnSearch?: (value: string, fieldName: string) => void;
  mfgContacts?: CustomSelectContactOptions[];
  mfgCompanies?: CompanyOption[];
  valueCompany?: string;
  stages?: any[];
  usersList?: AllUsersModel;
  distributorCompanies?: CompanyOption[];
  distributorContacts?: CustomSelectContactOptions[];
  distributorContactsValue?: string[];
  valueCompanyDistributor?: string[];

  customerCompaniesValues?: string[];
  customerContactsValues?: string[];
  customerCompaniesOptions?: CompanyOption[];
  customerContactsOptions?: CustomSelectContactOptions[];
  customSelectOnChange?: (value: any[], name: any) => void;
  hidden?: string[];
  opportunityOwner?: string;
  contactsMfg?: string[];
  loading?: boolean;
};

const OppInformation: FC<Props> = ({
  loading,
  requiredFields,
  handleOnSearch,
  mfgContacts,
  mfgCompanies,
  valueCompany,
  stages,
  usersList,
  valueCompanyDistributor,
  distributorCompanies,
  distributorContacts,
  distributorContactsValue,
  customerCompaniesValues,
  customerContactsValues,
  customerCompaniesOptions,
  customerContactsOptions,
  customSelectOnChange,
  hidden,
  contactsMfg,
  opportunityOwner,
}) => {
  const formattedUserList = usersList?.GetAllUsers?.map((user) => ({
    value: user.id,
    label: user.name,
  }));

  const fields: any[] = [
    {
      name: 'topic',
      label: 'Topic',
      rules:
        !loading && requiredFields?.includes('topic')
          ? [
              {
                required: true,
                message: 'Topic is required',
                validator: validateEmptyInput,
              },
            ]
          : [],
      type: 'text',
      span: 24,
      autoFocus: true,
      maxLength: 250,
      hidden: hidden?.includes('topic'),
    },
    {
      name: 'manufacturer',
      label: 'Mfg / Product Line',
      rules: [
        {
          required: requiredFields?.includes('manufacturer'),
          message: 'Mfg / Product Line is required',
        },
      ],
      type: 'selectSearch',
      span: 12,
      options: mfgCompanies,
      onSearch: handleOnSearch,
      defaultValue: valueCompany,
      hidden: hidden?.includes('manufacturer'),
      onChange: customSelectOnChange, // Add this line
    },
    {
      name: 'manufacturerContact',
      label: 'Contacts (Mfg / Product Line)',
      rules: [
        {
          required: requiredFields?.includes('manufacturerContact'),
          message: 'Contacts (Mfg / Product Line) is required',
        },
      ],
      type: 'multiSelectSearch',
      span: 12,
      options: mfgContacts,
      defaultValue: contactsMfg,
      hidden: hidden?.includes('manufacturerContact'),
    },
    {
      name: 'distributor',
      label: 'Distributor',
      rules: [
        {
          required: requiredFields?.includes('distributor'),
          message: 'Distributor is required',
        },
      ],
      type: 'multiSelectSearch',
      span: 12,
      onChange: customSelectOnChange, // Add this line
      options: distributorCompanies,
      onSearch: handleOnSearch,
      defaultValue: valueCompanyDistributor,
      hidden: hidden?.includes('distributor'),
    },
    {
      name: 'contactsDistributor',
      label: 'Contacts (Distributor)',
      rules: [
        {
          required: requiredFields?.includes('contactsDistributor'),
          message: 'Distributor is required',
        },
      ],
      type: 'multiSelectSearch',
      span: 12,
      onChange: customSelectOnChange, // Add this line
      options: distributorContacts,
      onSearch: handleOnSearch,
      defaultValue: distributorContactsValue,
      hidden: hidden?.includes('contactsDistributor'),
    },
    {
      name: 'customer',
      label: 'Customer',
      rules: [
        {
          required: requiredFields?.includes('customer'),
          message: 'Customer is required',
        },
      ],
      type: 'multiSelectSearch',
      span: 12,
      onChange: customSelectOnChange, // Add this line
      options: customerCompaniesOptions,
      onSearch: handleOnSearch,
      defaultValue: customerCompaniesValues,
      hidden: hidden?.includes('customer'),
    },
    {
      name: 'customerContact',
      label: 'Contacts (Customer)',
      rules: [
        {
          required: requiredFields?.includes('customerContact'),
          message: 'Customer is required',
        },
      ],
      type: 'multiSelectSearch',
      span: 12,
      onChange: customSelectOnChange, // Add this line
      options: customerContactsOptions,
      onSearch: handleOnSearch,
      defaultValue: customerContactsValues,
      hidden: hidden?.includes('customerContact'),
    },
    {
      name: 'followUp',
      label: 'Follow Up',
      rules: [
        {
          required: requiredFields?.includes('followUp'),
          message: 'Follow up is required',
        },
      ],
      type: 'date',
      span: 12,
      style: { width: '100%' },
      disabledDate: true,
      hidden: hidden?.includes('followUp'),
    },
    {
      name: 'ownerId',
      label: 'Opportunity Owner',
      rules: [
        {
          required: requiredFields?.includes('opportunityOwner'),
          message: 'Opportunity Owner is required',
        },
      ],
      type: 'selectSearch',
      span: 12,
      options: formattedUserList,
      hidden: hidden?.includes('ownerId'),
      defaultValue: opportunityOwner,
    },
    {
      name: 'stageId',
      label: 'Stage',
      rules: [
        {
          required: requiredFields?.includes('stage'),
          message: 'Stage is required',
        },
      ],
      options: stages,
      type: 'stepper',
      span: 24,
      hidden: hidden?.includes('stageId'),
    },
    {
      name: 'value',
      label: '$ Value',
      rules: [
        {
          required: requiredFields?.includes('value'),
          message: 'Value is required',
        },
        {
          validator: (_: any, value: number) => {
            if (value < 0) {
              return Promise.reject(new Error('Value cannot be negative'));
            }
            return Promise.resolve();
          },
        },
      ],
      type: 'number',
      placeholder: '0.00',
      span: 12,
      hidden: hidden?.includes('value'),
    },
    {
      name: 'estAnnualQty',
      label: 'Estimated Quantity',
      rules: [
        {
          required: requiredFields?.includes('estAnnualQty'),
          message: 'Estimated Quantity is required',
        },
        {
          validator: (_: any, value: number) => {
            if (value < 0) {
              return Promise.reject(new Error('Estimated Quantity cannot be negative'));
            }
            return Promise.resolve();
          },
        },
      ],
      type: 'number',
      span: 12,
      maxLength: 250,
      hidden: hidden?.includes('estAnnualQty'),
    },
    {
      name: 'nextStep',
      label: 'Next Step',
      rules: [
        {
          required: requiredFields?.includes('nextStep'),
          message: 'Next Step is required',
        },
      ],
      placeholder: 'Autosize height based on content lines',
      type: 'text',
      span: 12,
      maxLength: 250,
      hidden: hidden?.includes('nextStep'),
    },
    {
      name: 'watchers',
      label: 'Watchers',
      options: formattedUserList,
      rules: [
        {
          required: requiredFields?.includes('watchers'),
          message: 'Watchers is required',
        },
      ],
      placeholder: 'Type in the @ symbol for a selection list',
      type: 'selectionRenderWithTag',
      span: 12,
      hidden: hidden?.includes('watchers'),
    },
    {
      name: 'notifyWatchers',
      label: 'Notify Watchers',
      rules: [
        {
          required: requiredFields?.includes('notifyWatchers'),
          message: 'Notify Watchers is required',
        },
      ],
      type: 'checkboxEnd',
      span: 24,
      hidden: hidden?.includes('notifyWatchers'),
    },
  ];

  return <GenericForm fields={fields} />;
};

export default OppInformation;
