import styled from 'styled-components';

export const SPhoneFormInput = styled.div<{ mainRecord: boolean }>`
  margin-top: ${(props) => (props.mainRecord ? 8 : 0)};
  input {
    &:disabled {
      background-color: rgba(0, 0, 0, 0.04);
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      box-shadow: none;
      cursor: not-allowed;
      opacity: 1;
    }
  }
`;
