import { CalendarOutlined, MailOutlined } from '@ant-design/icons';
import { faker } from '@faker-js/faker';
import { Button, Col, DatePicker, Input, Row, Select } from 'antd';
import { FC, useState } from 'react';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';

interface Manufacturing {
  id: number;
  label: string;
  value: string;
}

interface InterfaceMfgField {
  field?: FieldType;
  value?: any;
  handleChange?: (value: any) => void;
  placeholder?: string;
  title?: string;
}

const MFG = Array.from({ length: 5 }).map(() => {
  const value = faker.lorem.words({ min: 1, max: 3 });
  return {
    label: value,
    value,
    toSend: faker.number.int(),
  };
});

const ManufacturingField: FC<InterfaceMfgField> = ({
  field,
  value,
  handleChange,
  placeholder,
  title,
}) => (
  <div style={{ margin: '0.5rem 0' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <p style={{ fontSize: '1.3rem' }}>{title}</p>
      <Row style={{ width: '80%' }} gutter={4}>
        <Col span={5}>
          <DatePicker />
        </Col>
        <Col span={5}>
          <Button style={{ maxWidth: '100%', fontSize: '0.7rem' }}>
            <CalendarOutlined />
            Add Event
          </Button>
        </Col>
        <Col span={5}>
          <Button style={{ maxWidth: '100%', fontSize: '0.7rem' }}>
            <MailOutlined />
            Send Email
          </Button>
        </Col>
        <Col span={5}>
          <Select placeholder='Related Opportunities' />
        </Col>
        <Col span={4}>
          <Select placeholder='Create Opportunities' disabled />
        </Col>
      </Row>
    </div>
    <Input.TextArea
      defaultValue={field?.initialValue}
      value={value}
      placeholder={placeholder ?? 'Type here...'}
      onChange={handleChange}
      disabled={field?.disabled}
      readOnly={field?.readOnly}
    />
  </div>
);

const MfgProduct: React.FC = () => {
  const [manufacturing, setManufacturing] = useState<Manufacturing[] | []>([]);

  const handleChange = (value?: any, option?: any) => {
    setManufacturing([...option]);
  };

  const fields: FieldType<any>[] = [
    {
      name: 'mfg_product',
      label: 'Mfg./Products Line',
      type: 'multiSelect',
      options: MFG ?? [],
      onChange: handleChange,
      span: 24,
    },
  ];

  manufacturing.forEach((element) => {
    const field: FieldType = {
      name: element.value,
      type: '',
      span: 24,
      render: () => <ManufacturingField title={element.value} />,
    };
    fields.push(field);
  });

  return <GenericForm fields={fields} />;
};

export default MfgProduct;
