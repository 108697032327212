import { faker } from '@faker-js/faker';

const FAKE_KEY = 'customFields';
const FAKE = {
  data: {
    GetAllCustomFields: {
      results: [
        ...[...Array(20)].map((_, i) => ({
          id: i,
          default_label: faker.word.noun(),
          type: faker.helpers.arrayElement([
            'Short Text',
            'Checkbox',
            'Decimal',
            'Integer',
            'Date',
            'Datetime',
            'Large Text',
            'Hyperlink',
            'Dropdown',
            'Multiselect Dropdown',
          ]),
          values: 'example',
          data_module: faker.helpers.arrayElement(['Companies', 'Contacts', 'Opportunities']),
          default: '',
          mandatory: faker.datatype.boolean(0.5),
        })),
      ],
      total: 20,
    },
  },
};
type FakeType = typeof FAKE;

const FAKE_DATA_MODULE_KEY = 'customFieldsDataModule';
const FAKE_DATA_MODULE = {
  data: {
    GetAllDataModule: {
      results: [
        {
          value: '',
          label: 'All Modules',
        },
        {
          value: 'Company',
          label: 'Companies',
        },
        {
          value: 'Contact',
          label: 'Contacts',
        },
        {
          value: 'Opportunity',
          label: 'Opportunities',
        },
      ],
      total: 3,
    },
  },
};

type FakeDataModuleType = typeof FAKE_DATA_MODULE;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fakeGetAll = (_variables: any) => {
  let data = sessionStorage.getItem(FAKE_KEY);
  if (!data) {
    sessionStorage.setItem(FAKE_KEY, JSON.stringify(FAKE));
    data = sessionStorage.getItem(FAKE_KEY);
  }

  return Promise.resolve<FakeType>(JSON.parse(data!));
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fakeGetDataModule = () => {
  let data = sessionStorage.getItem(FAKE_DATA_MODULE_KEY);
  if (!data) {
    sessionStorage.setItem(FAKE_DATA_MODULE_KEY, JSON.stringify(FAKE_DATA_MODULE));
    data = sessionStorage.getItem(FAKE_DATA_MODULE_KEY);
  }
  return Promise.resolve<FakeDataModuleType>(JSON.parse(data!));
};

export const fakeCreateOne = (createProductPotentialInput: any) => {
  const data = sessionStorage.getItem(FAKE_KEY);
  if (data) {
    const parsedData = JSON.parse(data);
    const newProductPotential = {
      ...createProductPotentialInput,
      id: parsedData.GetAllCustomFields.results.length + 1,
    };
    parsedData.GetAllCustomFields.results.push(newProductPotential);
    parsedData.GetAllCustomFields.total = parsedData.GetAllCustomFields.results.length;
    sessionStorage.setItem(FAKE_KEY, JSON.stringify(parsedData));
  }
  return Promise.resolve(createProductPotentialInput);
};

export const fakeUpdateOne = (updateProductPotentialInput: any) => {
  const data = sessionStorage.getItem(FAKE_KEY);
  if (data) {
    const parsedData = JSON.parse(data);
    const index = parsedData.GetAllCustomFields.results.findIndex(
      (item: any) => item.id === updateProductPotentialInput.id,
    );
    parsedData.GetAllCustomFields.results[index] = updateProductPotentialInput;
    sessionStorage.setItem(FAKE_KEY, JSON.stringify(parsedData));
  }
  return Promise.resolve(updateProductPotentialInput);
};

export const fakeDeleteOne = (payload: { variables: { id: number } }) => {
  const { id } = payload.variables;
  const data = sessionStorage.getItem(FAKE_KEY);
  if (data) {
    const parsedData = JSON.parse(data) as FakeType;
    const index = parsedData.data.GetAllCustomFields.results.findIndex(
      (item: any) => item.id === id,
    );
    parsedData.data.GetAllCustomFields.results.splice(index, 1);
    parsedData.data.GetAllCustomFields.total = parsedData.data.GetAllCustomFields.results.length;
    sessionStorage.setItem(FAKE_KEY, JSON.stringify(parsedData));
  }
  return Promise.resolve(id);
};
