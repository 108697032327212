import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_PERSONAL_INFO = gql`
  mutation CreatePersonalInfo($createPersonalInfoInput: CreateContactPersonalInfoInput!) {
    CreatePersonalInfo(createContactPersonalInfoInput: $createPersonalInfoInput) {
      id
    }
  }
`;
