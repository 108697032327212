import { gql } from '@apollo/client';

export const CREATE_OPP_FAILED_REASON = gql`
  mutation CreateOpportunityFailedReason($createFailedReasonInput: CreateFailedReasonInput!) {
    CreateOpportunityFailedReason(createFailedReasonInput: $createFailedReasonInput) {
      boolean
      id
      name
    }
  }
`;

export const UPDATE_OPP_FAILED_REASON = gql`
  mutation UpdateOpportunityFailedReason($updateFailedReasonInput: UpdateFailedReasonInput!) {
    UpdateOpportunityFailedReason(updateFailedReasonInput: $updateFailedReasonInput) {
      name
      id
      boolean
    }
  }
`;

export const REMOVE_OPP_FAILED_REASON = gql`
  mutation RemoveOpportunityFailedReason($id: Int!) {
    RemoveOpportunityFailedReason(id: $id)
  }
`;
