/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tooltip } from 'antd';
import { SubTableGridProps } from '../types';
import StyledRowActionButton from '../styles/rowActionButton.style';
import { showConfirmationMessage } from '../helpers';
import { useSubTableContext } from '../contexts/subtable.context';
import MultipleActions from './multipleActions';

type Props = Pick<
  SubTableGridProps,
  | 'actionCell'
  | 'editIcon'
  | 'deleteIcon'
  | 'detailsIcon'
  | 'ellipsisIcon'
  | 'onEditClick'
  | 'onDeleteClick'
  | 'onDetailsClick'
  | 'titleOnDelete'
  | 'contentOnDelete'
  | 'favIcon'
  | 'onFavClick'
  | 'deleteNewRecord'
  | 'items'
  | 'addNewRecord'
  | 'handleAllRowActions'
>;

const getActionsCellData = (props: Props) => {
  const {
    actionCell,
    editIcon,
    deleteIcon,
    detailsIcon,
    favIcon,
    onFavClick,
    onEditClick,
    onDeleteClick,
    onDetailsClick,
    titleOnDelete,
    contentOnDelete,
    deleteNewRecord,
    ellipsisIcon,
    items,
    addNewRecord,
    handleAllRowActions,
  } = props;

  let actions: any = actionCell;

  if (typeof actionCell === 'boolean') {
    actions = ['details', 'edit', 'delete', 'ellipsis'];
  }

  const render = (_: any, record: any) => {
    const { setOpenModal, setRecord } = useSubTableContext();

    if (record?.placeholder) {
      return null;
    }

    const handleEditClick = () => {
      if (onEditClick) {
        onEditClick([record]);
      } else {
        setOpenModal(true);
        setRecord(record);
      }
    };

    const handleDeleteClick = () => {
      showConfirmationMessage({
        title: titleOnDelete,
        content: contentOnDelete,
        onOk: () => {
          if (onDeleteClick) {
            if (record.newRecord) {
              deleteNewRecord?.(record);
              return;
            }
            onDeleteClick([record]);
          }
        },
      });
    };

    const handleDetailsClick = () => {
      if (onDetailsClick) {
        onDetailsClick(record);
      }
    };

    const optionSelectFunction = (id: string) => {
      if (id === 'delete') {
        handleDeleteClick();
      }
      if (handleAllRowActions) {
        handleAllRowActions(id, record);
      }
    };

    const isDeletable = record.isDeletable ? record.isDeletable : record.boolean;
    // replace condition with property that comes from the backend

    const buttons = {
      details: (
        <StyledRowActionButton
          data-testid='subtable-details-row-button'
          onClick={handleDetailsClick}
          key='details'
          type='text'
          shape='circle'
          icon={detailsIcon}
        />
      ),
      ellipsis: items ? (
        <MultipleActions options={items} optionSelect={optionSelectFunction}>
          <StyledRowActionButton
            data-testid='subtable-details-row-button'
            onClick={() => {}}
            key='details'
            type='text'
            shape='circle'
            icon={ellipsisIcon}
          />
        </MultipleActions>
      ) : (
        <StyledRowActionButton
          data-testid='subtable-details-row-button'
          onClick={() => {}}
          key='details'
          type='text'
          shape='circle'
          icon={ellipsisIcon}
        />
      ),
      edit: (
        <Tooltip title='Edit' color='gray'>
          <StyledRowActionButton
            data-testid='subtable-edit-row-button'
            onClick={handleEditClick}
            key='edit'
            type='text'
            shape='circle'
            icon={editIcon}
          />
        </Tooltip>
      ),
      delete: (
        <Tooltip
          placement='left'
          title={isDeletable && !addNewRecord ? 'Delete record' : 'This item cannot be deleted'}
          color='white'
          overlayInnerStyle={{
            color: 'black',
          }}
        >
          <StyledRowActionButton
            data-testid='subtable-delete-row-button'
            onClick={handleDeleteClick}
            key='delete'
            type='text'
            shape='circle'
            disabled={addNewRecord || !isDeletable}
            style={{
              display: 'flex',
              width: '32px',
              height: '32px',
              padding: '6px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '30px',
              gap: '8px',
            }}
            icon={
              typeof deleteIcon === 'function'
                ? deleteIcon(isDeletable && !addNewRecord ? '#C92C2C' : '')
                : deleteIcon
            }
          />
        </Tooltip>
      ),
      fav: (
        <StyledRowActionButton
          data-testid='subtable-fav-row-button'
          onClick={() => onFavClick?.(record)}
          key='fav'
          type='text'
          shape='circle'
          icon={favIcon}
        />
      ),
    };

    const actionsButtons = (actions as any[]).map((action) => {
      if (
        action === 'details' ||
        action === 'edit' ||
        action === 'delete' ||
        action === 'fav' ||
        action === 'ellipsis'
      ) {
        return buttons[action as keyof typeof buttons];
      }
      return action;
    });

    return (
      <div
        style={{
          display: 'flex',
          gap: 10,
          justifyContent: 'center',
        }}
      >
        {actionsButtons}
      </div>
    );
  };

  const width = actions.length * 32 + (actions.length - 1) * 10 + 32;

  return { render, width };
};

export default getActionsCellData;
