import React, { ReactNode, useMemo } from 'react';
import { Col, Button, Popover } from 'antd';
import { MultipleActionsGlobalStyles } from '../styles/MultipleActions.styles';

type MultipleActionsProps = {
  children?: ReactNode | undefined;
  options: { key: string; label: string }[];
  optionSelect?: (id: string) => void;
};

const MultipleActions: React.FC<MultipleActionsProps> = ({ children, options, optionSelect }) => {
  const checkboxRender = useMemo(
    () => (
      <Col className='elements-container'>
        {options.map((option) => (
          <Button
            style={{ minWidth: '155px', border: 'none' }}
            id={option.key}
            type='text'
            onClick={() => {
              if (optionSelect) {
                optionSelect(option.key);
              }
            }}
          >
            {option.label}
          </Button>
        ))}
      </Col>
    ),
    [],
  );

  return (
    <>
      <MultipleActionsGlobalStyles />
      <Popover
        content={checkboxRender}
        trigger='hover'
        placement='bottomLeft'
        arrow={false}
        overlayInnerStyle={{ padding: 0 }}
        style={{ zIndex: 9999 }}
      >
        {children}
      </Popover>
    </>
  );
};

export default MultipleActions;
