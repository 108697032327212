import { gql } from '@apollo/client';

const GET_BASE_COMPANIES_TYPES = gql`
  query GetCompaniesBaseTypesOptions {
    GetCompaniesBaseTypesOptions {
      additionalFields
      additionalRequiredFields
      options {
        id
        name
      }
      parent
    }
  }
`;

const GET_COMPANIES_DEPTH_HEADERS = gql`
  query GetCompaniesDepthGridHeader {
    GetCompaniesDepthGridHeader
  }
`;

const GET_COMPANIES_ELASTIC_2 = gql`
  query GetAllCompaniesFromElastic2(
    $searchFields: CompanyFindElasticInput2
    $criteria: CompanyCriteriaInput2!
  ) {
    GetCompaniesFromElastic2(SearchFields: $searchFields, Criteria: $criteria) {
      total
      results {
        id
        name
        website
        comments
        hasForecast
        hasHolydayCard
        hasPartNumberSplit
        hasSecondaryAlias
        category {
          id
          name
        }
        companyType {
          id
          name
          color
        }
        divisions {
          id
          name
        }
        hasPosAccount
        industries {
          id
          name
        }
        isActive
        mainAddress {
          address
          addressType {
            id
            name
          }
          city
          country {
            id
            name
          }
          geopositionLatitude
          geopositionLongitude
          id
          pobox
          zipCode
          region {
            id
            name
          }
          state {
            id
            name
          }
          territory {
            id
            name
          }
        }
        mainPhone {
          areaCode
          countryCode
          extension
          id
          phone
          phoneType {
            id
            name
          }
        }
        poFooter
        printName
        privateTeam {
          id
          name
        }
        productPotentials {
          id
          name
        }
        quoteFooter
        visitFrequency
        tags {
          id
          name
        }
        tenantDivision {
          id
          name
        }
        shortName
        shipDetail
        salesTeam {
          id
          name
        }
        callPattern {
          id
          name
        }
        parent {
          name
          id
        }
        rank {
          name
          id
        }
      }
    }
  }
`;

const CREATE_COMPANY_EXPORT = gql`
  query CreateCompanyExport(
    $criteria: CompanyCriteriaInput2!
    $searchFields: CompanyFindElasticInput2
  ) {
    CreateCompanyExport(Criteria: $criteria, SearchFields: $searchFields) {
      requestId
      status
      token
      link
    }
  }
`;

const GET_TYPE_AHEAD_RESULTS = gql`
  query GetCompanyUniqueValuesFromColumn(
    $column: FilterCompanyEnum!
    $filter: String!
    $enabled: Boolean!
  ) {
    GetCompanyUniqueValuesFromColumn(column: $column, filter: $filter, enabled: $enabled)
  }
`;

const GET_COMPANIES_ELASTIC = gql`
  query GetCompaniesFromElastic(
    $searchFields: CompanyFindElasticInput
    $criteria: CompanyCriteriaInput!
  ) {
    GetCompaniesFromElastic(SearchFields: $searchFields, Criteria: $criteria) {
      total
      results {
        ACTIVE
        ADDRESSES {
          ADDRESS
          CITY
          ID
          PO_BOX
          REGION
          REGION_ID
          STATE
        }
        CALL_PATTERN_ID
        CATEGORY {
          LABEL
          VALUE
        }
        CLASS {
          LABEL
          VALUE
        }
        COMMENTS
        CREATED_BY {
          ID
          NAME
        }
        DATA_SOURCE
        ES_ID
        HAS_HOLYDAY
        ID
        IMP_BATCH
        NAME
        PARENT_ID
        PARENT_NAME
        PHONES {
          ID
          PHONE
          TYPE
        }
        PRINT_NAME
        REF_NO
        SALES_TEAM {
          LABEL
          VALUE
        }
        SHORT_NAME
        TYPE {
          LABEL
          VALUE
        }
        UPDATED_BY {
          ID
          NAME
        }
        VISIT_FREQUENCY
        WEB_SITE
        UPDATED_BY {
          ID
          NAME
        }
      }
    }
  }
`;

const GET_STOCK_DATA_COMPANIES_ELASTIC = gql`
  query GetCompaniesFromElastic(
    $searchFields: CompanyFindElasticInput
    $criteria: CompanyCriteriaInput!
  ) {
    GetCompaniesFromElastic(SearchFields: $searchFields, Criteria: $criteria) {
      total
      results {
        NAME
      }
    }
  }
`;

const GET_COMPANIES_HEADERS = gql`
  query GetCompaniesHeader {
    GetCompaniesGridHeader
  }
`;

const GET_ALL_CALL_PATTERN = gql`
  query GetAllCallPattern {
    GetAllCallPattern {
      name
      id
    }
  }
`;

const GET_ALL_COMPANY_TYPE_ALIAS = gql`
  query GetAllCompanyTypeAlias {
    GetAllCompanyTypeAlias {
      id
      color
      name
    }
  }
`;

export {
  GET_COMPANIES_ELASTIC,
  GET_COMPANIES_HEADERS,
  GET_STOCK_DATA_COMPANIES_ELASTIC,
  GET_ALL_CALL_PATTERN,
  GET_ALL_COMPANY_TYPE_ALIAS,
  GET_BASE_COMPANIES_TYPES,
  GET_COMPANIES_DEPTH_HEADERS,
  GET_COMPANIES_ELASTIC_2,
  GET_TYPE_AHEAD_RESULTS,
  CREATE_COMPANY_EXPORT,
};
