import SectionCard from 'src/components/sectionCard/SectionCard';
import { Checkbox, Col, Row } from 'antd';
import React from 'react';
import { useExportCompaniesContext } from '../../contexts/export-companies.context';

const ExportCompaniesFields = () => {
  const { companyHeaders, selectedHeaders, areAllSelected, setSelectedHeaders } =
    useExportCompaniesContext();

  const handleToggleSelectAllCheckbox = (ev: any) => {
    const { checked } = ev.target as HTMLInputElement;
    const newSelectedHeaders = companyHeaders
      .filter((header) => !header.hidden && !!header.headerName.trim())
      .reduce((acc, header) => {
        acc[header.searchField] = checked;
        return acc;
      }, {} as Record<string, boolean>);

    setSelectedHeaders(newSelectedHeaders);
  };

  return (
    <SectionCard title='Fields'>
      <Row>
        <Col
          span={24}
          style={{
            paddingTop: 4,
            paddingBottom: 4,
            marginBottom: 4,
            borderBottom: '1px solid rgb(0, 120, 212)',
          }}
        >
          <Checkbox checked={areAllSelected} onChange={handleToggleSelectAllCheckbox}>
            Fields
          </Checkbox>
        </Col>
        {companyHeaders
          .filter((header) => !header.hidden && !!header.headerName.trim())
          .map((header) => (
            <Col span={24} key={header.headerName}>
              <Checkbox
                checked={selectedHeaders[header.searchField]}
                onChange={(ev) => {
                  const { checked } = ev.target;
                  setSelectedHeaders({
                    ...selectedHeaders,
                    [header.searchField]: checked,
                  });
                }}
              >
                {header.headerName}
              </Checkbox>
            </Col>
          ))}
      </Row>
    </SectionCard>
  );
};

export default ExportCompaniesFields;
