// eslint-disable-next-line import/no-unresolved
import { createGlobalStyle } from 'styled-components';

const UserTableGlobalStyles = createGlobalStyle`
  .ant-modal-content {
    // width: 416px !important;
    // height: 176px !important;
    // padding: 24px !important;
    border-radius: 10px !important;
  }
  .ant-table-content {
    color: #4B4B4B;
  }
  .ant-tooltip-inner {
    color: #4B4B4B !important;
    background: #FAFAFA !important;
  }
  .ant-tooltip-arrow {
    display: none !important;
  }
  .ant-modal-confirm-title {
    weight: 500 !important;
    line-height: 24px !important;
    width: 328px !important;
  }
  .question-button {
    width: 32px !important;
    height: 32px !important;
  }
  .ant-modal-confirm-btns {
    margin-top: auto !important;
    button {
      span {
        font-family: 'Libre Franklin';
        line-height: 24px;
      }
    }
  }
  .ant-modal-body {
    height: 100%;
  }
  .ant-modal-confirm-body-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export default UserTableGlobalStyles;
