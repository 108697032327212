import {
  CopyOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  LinkedinOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';
import copyToClipboard from 'src/utils/functions/copyClipboard';
import { English } from 'src/languages/english';
import { SH5CardCompanyContact } from './styles/index.styles';

interface Props {
  location: string;
  website: string;
  linkedin?: string;
}

const VisibleNoVisibleInfo: FC<Props> = ({ location, website, linkedin }) => (
  <>
    <SH5CardCompanyContact data-testid='company-location'>
      <EnvironmentOutlined />
      {location}
      {location !== English.noData && (
        <Button onClick={() => copyToClipboard(location)}>
          <CopyOutlined />
        </Button>
      )}
    </SH5CardCompanyContact>

    {website && (
      <SH5CardCompanyContact data-testid='company-website'>
        <GlobalOutlined />
        {website}
        {website !== English.noData && (
          <Button onClick={() => copyToClipboard(website)}>
            <CopyOutlined />
          </Button>
        )}
      </SH5CardCompanyContact>
    )}

    {linkedin && (
      <SH5CardCompanyContact data-testid='company-linkedin'>
        <LinkedinOutlined />
        {linkedin}
        {linkedin !== English.noData && (
          <Button onClick={() => copyToClipboard(linkedin)}>
            <CopyOutlined />
          </Button>
        )}
      </SH5CardCompanyContact>
    )}
  </>
);
export default VisibleNoVisibleInfo;
