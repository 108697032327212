import Avatar from 'src/components/commons/avatar';
import SAuditableInfo from './styles/auditableInfo.styles';
import avIMG from '../../../../../images/Avatar.png';

const imgStyles = {
  width: '1rem',
  height: '1rem',
};

const contStyles = {
  width: '1.5rem',
  height: '1.5rem',
};

const AuditableInfo = () => (
  <SAuditableInfo>
    <div className='flex-gap'>
      <p>Updated By</p>
      <div>
        <Avatar
          img={avIMG}
          name='Chris Farley User'
          customStyles={contStyles}
          customImageStyles={imgStyles}
        />
      </div>
    </div>
    <div className='flex-gap'>
      <p>Updated On</p>
      <p>4/4/4</p>
    </div>
    <div className='flex-gap'>
      <p>Last Logged In</p>
      <p>4/4/4</p>
    </div>
  </SAuditableInfo>
);
export default AuditableInfo;
