import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';
import { OptionType } from 'src/components/commons/customSelect';
import useContactsStore from 'src/hooks/stores/useContactsStore';
import useCompanyStore from 'src/hooks/stores/useCompanyStore';
import useDebouncedCallback from 'src/hooks/useDebouncedCallback';
import { useLazyQuery } from '@apollo/client';
import GET_ALL_CONTACTS_BY_COMPANY from 'src/components/companyDetails/contacts/contactsCompaniesExpanded/graphql/queries';
import { StyledCompaniesAttendees } from './styles/companiesAttendees.styles';

interface CompaniesAttendeesProps {
  company: OptionType;
  contactName: OptionType[];
  addAttendee: (idx: number, attendee: OptionType, contactIdx?: number) => void;
  deleteAttendee: (idx: number, attendeeIdx: number) => void;
  addCompany: (idx: number, option: OptionType) => void;
  deleteCompany: (idx: number) => void;
  currentIdx: number;
}

const optionsPayload = (filter: any[]) => ({
  variables: {
    criteria: {
      pagination: {
        from: 0,
        size: 25,
      },
      filter,
    },
  },
});

const CompaniesAttendees: FC<CompaniesAttendeesProps> = ({
  company,
  contactName,
  addAttendee,
  deleteAttendee,
  addCompany,
  deleteCompany,
  currentIdx,
}) => {
  const { fetchAll: getContacts } = useContactsStore();
  const { fetchAll: getCompanies } = useCompanyStore();
  const [getSingleContact] = useLazyQuery(GET_ALL_CONTACTS_BY_COMPANY, {
    fetchPolicy: 'cache-and-network',
  });

  const [companies, setCompanies] = useState<OptionType[] | []>([]);
  const [contacts, setContacts] = useState<OptionType[] | []>([]);
  const [loading, setLoading] = useState(false);
  const [currentCompany, setCurrentCompany] = useState<string>('');
  const [currentContact, setCurrentContact] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    if (typeof company.toSend === 'number') {
      getSingleContact({
        variables: {
          companyId: company?.toSend,
          criteria: {
            pagination: {
              from: 0,
              size: 25,
            },
          },
        },
      }).then((res) => {
        if (res.data) {
          const options: OptionType[] = res.data?.GetAllContactByCompanyId?.results?.map(
            (data: any) => ({
              toSend: data.id,
              value: `${data.firstName} ${data.lastName}`,
              label: `${data.firstName} ${data.lastName}`,
              jobTitle: data?.businessInfo?.jobTitle,
            }),
          );
          setContacts(options);
        }
      });
    } else {
      getContacts(optionsPayload([])).then((res) => {
        if (res.results) {
          const options: OptionType[] = res.results?.map((data: any) => ({
            toSend: data.id,
            value: `${data.firstName} ${data.lastName}`,
            label: `${data.firstName} ${data.lastName}`,
            jobTitle: data?.businessInfo?.jobTitle,
          }));
          setContacts(options);
        }
      });
    }
    getCompanies(optionsPayload([])).then((res) => {
      if (res.results) {
        const options: OptionType[] = res.results.map((data: any) => ({
          toSend: data.id,
          value: data.name,
          label: data.name,
        }));
        setCompanies(options);
      }
    });
    setLoading(false);
  }, []);

  const filterContact = async (value: any) => {
    setLoading(true);
    if (typeof company.toSend === 'number') {
      getSingleContact({
        variables: {
          companyId: company?.toSend,
          criteria: {
            pagination: {
              from: 0,
              size: 25,
            },
          },
        },
      })
        .then((res) => {
          if (res.data) {
            const options: OptionType[] = res.data?.GetAllContactByCompanyId?.results?.map(
              (data: any) => ({
                toSend: data.id,
                value: `${data.firstName} ${data.lastName}`,
                label: `${data.firstName} ${data.lastName}`,
                jobTitle: data?.businessInfo?.jobTitle,
              }),
            );
            setContacts(options);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      getContacts(
        optionsPayload([
          {
            dataType: 'string',
            fieldName: 'fullName',
            operator: 'CONTAINS',
            value,
          },
        ]),
      )
        .then((res) => {
          if (res.results) {
            const options: OptionType[] = res.results.map((data: any) => ({
              toSend: data.id,
              value: `${data.firstName} ${data.lastName}`,
              label: `${data.firstName} ${data.lastName}`,
              jobTitle: data?.businessInfo?.jobTitle,
            }));
            setContacts(options);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const filterCompany = async (value: any) => {
    setLoading(true);
    getCompanies(
      optionsPayload([
        {
          dataType: 'string',
          fieldName: 'name',
          operator: 'CONTAINS',
          value,
        },
      ]),
    )
      .then((res) => {
        if (res.results) {
          const options: OptionType[] = res.results.map((data: any) => ({
            toSend: data.id,
            value: data.name,
            label: data.name,
          }));
          setCompanies(options);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleAddNewAttendee = (
    value: any,
    option: OptionType | OptionType[],
    contactIdx?: number,
  ) => {
    if (contactIdx) {
      addAttendee(currentIdx, option as OptionType, contactIdx);
    } else {
      addAttendee(currentIdx, option as OptionType);
    }
  };
  const filterContactDebunce = useDebouncedCallback(filterContact, 300);
  const filterCompanyDebunce = useDebouncedCallback(filterCompany, 300);
  return (
    <StyledCompaniesAttendees>
      <div className='company-container'>
        {company.label ? (
          <h2>{company.label}</h2>
        ) : (
          <>
            <AutoComplete
              suffixIcon={<PlusOutlined />}
              style={{ width: '100%' }}
              placeholder='Add a company'
              onChange={(_, option) => addCompany(currentIdx, option as OptionType)}
              options={loading ? ([{ value: 'Loading...' }] as OptionType[]) : companies}
              onSearch={(value) => {
                filterCompanyDebunce(value);
                setCurrentCompany(value);
              }}
            />
            <Button
              type='primary'
              shape='circle'
              onClick={() => {
                addCompany(currentIdx, {
                  value: currentCompany,
                  label: currentCompany,
                  toSend: currentCompany,
                } as OptionType);
                setCurrentCompany('');
              }}
              style={{ borderRadius: 2 }}
            >
              <PlusOutlined />
            </Button>
          </>
        )}
        <Tooltip title='Delete' color='gray'>
          <Button onClick={() => deleteCompany(currentIdx)}>
            <DeleteOutlined />
          </Button>
        </Tooltip>
      </div>
      <div className='attendees-container'>
        {contactName.length === 0 && (
          <div className='inputs'>
            <AutoComplete
              suffixIcon={<PlusOutlined />}
              style={{ width: '100%' }}
              placeholder='Add attendee'
              options={loading ? ([{ value: 'Loading...' }] as OptionType[]) : contacts}
              onSearch={(value) => {
                setCurrentContact(value);
                filterContactDebunce(value);
              }}
              onSelect={(value, option) => handleAddNewAttendee(value, option)}
            />
            <Button
              type='primary'
              shape='circle'
              onClick={() => {
                handleAddNewAttendee('', {
                  value: currentContact,
                  label: currentContact,
                  toSend: currentContact,
                } as OptionType);
                setCurrentContact('');
              }}
              style={{ borderRadius: 2 }}
            >
              <PlusOutlined />
            </Button>
          </div>
        )}
        {contactName?.map((contact, idx) => (
          <div className='attendee-layout' key={contact.label}>
            {contact.label && (
              <div>
                {contactName.length - 1 === idx && (
                  <Tooltip title='Add another attendee' color='gray'>
                    <Button onClick={() => addAttendee(currentIdx, {} as OptionType)}>
                      <PlusOutlined />
                    </Button>
                  </Tooltip>
                )}
              </div>
            )}
            <div className='attendee'>
              {contact.label ? (
                <>
                  <p>{contact.label}</p>
                  {contact?.jobTitle && <p className='job-title'>{contact.jobTitle}</p>}
                </>
              ) : (
                <div className='inputs'>
                  <AutoComplete
                    suffixIcon={<PlusOutlined />}
                    style={{ width: '100%' }}
                    placeholder='Add attendee'
                    options={loading ? ([{ value: 'Loading...' }] as OptionType[]) : contacts}
                    onSearch={(value) => {
                      setCurrentContact(value);
                      filterContactDebunce(value);
                    }}
                    onSelect={(value, option) => handleAddNewAttendee(value, option, idx)}
                  />
                  <Button
                    type='primary'
                    shape='circle'
                    onClick={() => {
                      handleAddNewAttendee(
                        '',
                        {
                          value: currentContact,
                          label: currentContact,
                          toSend: currentContact,
                        } as OptionType,
                        idx,
                      );
                      setCurrentContact('');
                    }}
                    style={{ borderRadius: 2 }}
                  >
                    <PlusOutlined />
                  </Button>
                </div>
              )}
            </div>
            <Tooltip title='Delete' color='gray'>
              <Button onClick={() => deleteAttendee(currentIdx, idx)}>
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </div>
        ))}
      </div>
    </StyledCompaniesAttendees>
  );
};

export default CompaniesAttendees;
