import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { OpportunityModel } from 'src/models/opportunity.model';
import dayjs from 'dayjs';
import localDate from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);
dayjs.extend(localDate);

export const opportunityAdapter: AdapterFunc<GQL.OpportunitiesOutputDto, OpportunityModel> = (
  input: any,
) => ({
  approvedBy: input.approvedBy,
  approvedDate: input.approvedDate && String(dayjs(input.approvedDate).format('YYYY-MM-DD')),
  bidDate: input.bid && String(dayjs(input.bid).format('YYYY-MM-DD')),
  city: input.city,
  closedReason: input.closedReason,
  closedStatus: input.closedStatus,
  competitors: input.competitors,
  creationDate: input.createdAt && String(dayjs(input.createdAt).format('YYYY-MM-DD')),
  createdAt: input.createdAt && String(dayjs(input.createdAt).format('YYYY-MM-DD')),
  customer: input.customer,
  customerContact: input.customerContact,
  description: input.description,
  distributor: input.distributor,
  distributorContact: input.distributorContact,
  enabled: input.enabled,
  estAnnualQty: input.estAnnualQty,
  estimatedCloseDate:
    input.estimatedCloseDate && String(dayjs(input.estimatedCloseDate).format('YYYY-MM-DD')),
  expirationDate: input.expirationDate && String(dayjs(input.expirationDate).format('YYYY-MM-DD')),
  followUp: input.followUp && String(dayjs(input.followUp).format('YYYY-MM-DD')),
  id: input.id,
  lastModified: input.updatedAt && String(dayjs(input.updatedAt).format('YYYY-MM-DD')),
  leadSource: input.leadSource,
  manufacturer: input.manufacturer,
  manufacturerContact: input.manufacturerContact,
  nextStep: input.nextStep,
  notes: input.notes,
  notifyWatchers: input.notifyWatchers,
  oppOwner: input.oppOwner,
  oppStage: input.oppStage,
  oppStatus: input.oppStatus,
  potential: input.potential,
  priority: input.priority,
  productLine: input.productLine,
  registrationLevel: input.registrationLevel,
  registrationNumber: input.registrationNumber,
  registrationStatus: input.registrationStatus,
  reportingComments: input.reportingComments,
  salesTeam: input.salesTeam,
  state: input.state,
  topic: input.topic,
  type: input.type,
  updatedAt: input.updatedAt && String(dayjs(input.updatedAt).format('YYYY-MM-DD')),
  value: input.value,
});
