import { FC, useMemo, useState, useEffect } from 'react';
import { CustomModal } from 'src/components/commons/customModal';
import { Col, Form, Radio, Checkbox, Button } from 'antd';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import dayjs from 'dayjs';
import { CloseOppGlobalStyles } from './closeOpp.styles';

type Props = {
  onCancel?: () => void;
  show: boolean;
  handleOnSave?: (data: any) => void;
  opportunityClosedReasonOptions?: any[];
  opportunityFailedReasonOptions?: any[];
  closingLoading?: boolean;
};

const CloseOppModal: FC<Props> = ({
  show,
  onCancel,
  handleOnSave,
  opportunityClosedReasonOptions,
  opportunityFailedReasonOptions,
  closingLoading,
}) => {
  const titleNode = 'Close Opportunity';
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState('Won');

  const radioOnChange = (e: any) => {
    if (e.target.value === 'Won') {
      form.resetFields(['failedReasonId']);
    } else {
      form.resetFields(['totalPrice', 'commissionRate']);
    }
    setFormValues(e.target.value);
  };

  const onSave = () => {
    form.validateFields().then(async (values) => {
      const innerValuesData = {
        ...values,
        closeStatus: formValues,
        commissionRate: values.commissionRate ? Number(values.commissionRate) : undefined,
        totalPrice: values.totalPrice ? Number(values.totalPrice) : undefined,
        closeDate: values.closeDate ? values.closeDate.format('YYYY-MM-DD') : undefined,
      };
      if (handleOnSave) {
        handleOnSave(innerValuesData);
      }
    });
  };

  useEffect(() => {
    if (show) {
      setFormValues('Won');
      form.resetFields();
      form.setFieldsValue({ closeDate: dayjs() });
    }
  }, [show]);

  const defaultType: FieldType<any>[] = [
    {
      name: 'closeDate',
      label: 'Date',
      type: 'date',
      onChange: () => {},
      style: { width: '100%' },
    },
    {
      name: 'closeReasonId',
      label: 'Close Reason',

      type: 'select',
      options: opportunityClosedReasonOptions,
      onChange: () => {},
      span: 24,
    },
  ];

  const closeOrCancelledType: FieldType<any>[] = [
    {
      name: 'closeDate',
      label: 'Date',
      type: 'date',
      onChange: () => {},
      style: { width: '100%' },
    },
    {
      name: 'closeReasonId',
      label: 'Close Reason',

      type: 'select',
      options: opportunityClosedReasonOptions,
      onChange: () => {},
      span: 24,
    },
    {
      name: 'failedReasonId',
      label: 'Failed Reason',
      rules: [{ message: 'Please select a failed reason' }],
      type: 'select',
      options: opportunityFailedReasonOptions,
      onChange: () => {},
      span: 24,
    },
  ];

  const wonType: FieldType<any>[] = [
    {
      name: 'closeDate',
      label: 'Date',
      type: 'date',
      onChange: () => {},
      style: { width: '100%' },
    },
    {
      name: 'closeReasonId',
      label: 'Closed Reason',

      type: 'select',
      options: opportunityClosedReasonOptions,
      onChange: () => {},
      span: 24,
    },
    {
      name: 'commissionable-transaction',
      label: 'Commissionable Transaction',
      type: 'label',
      style: { marginTop: '24px', marginBotton: '6px', color: '#353535' },
    },
    {
      name: 'totalPrice',
      label: 'Total Price',
      type: 'number',
      onChange: () => {},
      span: 24,
    },
    {
      name: 'commissionRate',
      label: 'Commission Rate',
      type: 'number',
      onChange: () => {},
      span: 24,
    },
  ];

  const closeOppFormFields = {
    Default: defaultType,
    Won: wonType,
    Lost: closeOrCancelledType,
    Cancelled: closeOrCancelledType,
  };

  const bodyNode = useMemo(
    () => (
      <Col>
        <Form form={form} layout='vertical'>
          <Form.Item label='Status' style={{ padding: 0, marginBottom: 0 }}>
            <Radio.Group onChange={radioOnChange} value={formValues}>
              <Radio value='Won'>Won</Radio>
              <Radio value='Lost'>Lost</Radio>
              <Radio value='Cancelled'>Cancelled</Radio>
            </Radio.Group>
          </Form.Item>
          <GenericForm
            fields={closeOppFormFields[formValues as keyof typeof closeOppFormFields]}
            form={form}
          />
          <Form.Item valuePropName='checked' noStyle>
            <Checkbox>Cancel all pending actions?</Checkbox>
          </Form.Item>
        </Form>
      </Col>
    ),
    [formValues, opportunityClosedReasonOptions],
  );

  return (
    <>
      <CloseOppGlobalStyles />
      <CustomModal
        modalStatus={show}
        modalTitle={titleNode}
        width={360}
        headerHeight='72px'
        bodyHeight={formValues === 'Won' ? '436px' : '312px'}
        footerHeight='64px'
        bodyPadding='24px 32px'
        centered
        testId='select-parent-company-modal'
        modalBody={bodyNode}
        onOkAction={() => {}}
        onCancelAction={() => {
          if (onCancel) {
            onCancel();
            form.resetFields();
            setFormValues('Won');
            form.setFieldsValue({ closeDate: dayjs() });
          }
        }}
        footer={
          <div style={{ display: 'flex', gap: 16 }}>
            <Button
              type='text'
              style={{ width: '100px', border: '1px solid #939393' }}
              onClick={() => {
                if (onCancel) {
                  onCancel();
                  form.resetFields();
                  setFormValues('Won');
                  form.setFieldsValue({ closeDate: dayjs() });
                }
              }}
            >
              Cancel
            </Button>
            <Button
              type='primary'
              style={{ width: '100px' }}
              disabled={formValues === 'Default'}
              onClick={onSave}
              loading={closingLoading}
            >
              Save
            </Button>
          </div>
        }
      />
    </>
  );
};

export default CloseOppModal;
