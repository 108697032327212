import React, { ReactElement, useRef, useState } from 'react';
import get from 'lodash/get';
import { Tag, Typography } from 'antd';
import { CompaniesElasticTypes2, HeaderDropdownOptions } from 'src/types/companiesTypes';
import StyledCellSkeleton from './styles/CellSkeleton.style';
import InnerEditableCellRenderer from './components/renderEditableCell';
import { InterfaceStatus } from './components/innerCustomSelect';

const { Text } = Typography;

interface EditableCellProps {
  title: string;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string | keyof CompaniesElasticTypes2;
  record: any;
  dataKey: string;
  searchField: string;
  dataType: string;
  required: boolean;
  options: HeaderDropdownOptions[] | undefined;
  hasLink: boolean;
  handleNewData: (args: { [x: string]: unknown }) => void;
  handleSave: (record: any) => void;
  addNewRecord: boolean;
  setAddNewRecord: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddNewRecord: () => void;
}

const NewEditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  dataKey,
  record,
  searchField,
  dataType,
  options,
  required,
  hasLink,
  addNewRecord,
  setAddNewRecord,
  handleNewData,
  handleAddNewRecord,
  handleSave,
  ...restProps
}) => {
  const dataToRender = useRef<ReactElement | string>('');
  const multiDataToRender = useRef<{ id: string; name: string }[]>([]);
  const [editing, setEditing] = useState(false);
  const [status, setStatus] = useState<InterfaceStatus>(required ? 'error' : '');
  const innerPath = [dataIndex, dataKey].filter(Boolean).join('.');
  if (dataIndex?.includes('phone')) {
    const phoneNumber = get(record, innerPath);
    dataToRender.current = phoneNumber
      ? ((<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>) as ReactElement)
      : '';
  } else if (dataType?.toLocaleLowerCase() === 'multiselect') {
    const localMultiData = get(record, dataIndex);
    multiDataToRender.current = localMultiData?.map((element: { id: number; name: string }) => ({
      id: String(element.id),
      name: element.name,
    }));
  } else {
    dataToRender.current = get(record, innerPath);
  }

  const toggleEdit = () => {
    if (addNewRecord) {
      handleAddNewRecord();
    }
    if (editable) {
      setEditing(!editing);
    }
  };

  const handleStatus = (data: InterfaceStatus) => {
    setStatus(data);
  };

  const handleManageData = (event: any, inputType?: string) => {
    switch (inputType) {
      case 'dropdown': {
        const innerValue = options?.find((element) => String(element.VALUE) === event);
        handleStatus('');
        if (addNewRecord) {
          handleNewData({
            [dataIndex]: { id: Number(innerValue?.VALUE), name: innerValue?.LABEL },
          });
        } else {
          handleSave({
            ...record,
            [dataIndex]: { id: Number(innerValue?.VALUE), name: innerValue?.LABEL },
          });
        }
        break;
      }
      case 'multiselect': {
        // eslint-disable-next-line consistent-return, array-callback-return
        const tempInnerValue = event?.map((element: string) => {
          const selectionValue = options?.find((item) => element === String(item.VALUE));
          if (selectionValue) {
            return selectionValue;
          }
        });
        const innerValue = tempInnerValue?.map((element: HeaderDropdownOptions) => ({
          id: Number(element?.VALUE),
          name: element?.LABEL,
        }));
        if (innerValue.length === 0) {
          handleStatus('error');
        } else {
          handleStatus('');
        }
        if (addNewRecord) {
          handleNewData({ [dataIndex]: innerValue });
        } else {
          handleSave({ ...record, [dataIndex]: innerValue });
        }
        break;
      }
      case 'number': {
        const innerValue = event.target.value;
        if (innerValue === '' || !innerValue) {
          handleStatus('error');
        } else {
          handleStatus('');
        }
        if (addNewRecord) {
          handleNewData({ [dataIndex]: Number(innerValue) });
        } else {
          handleSave({ ...record, [dataIndex]: Number(innerValue) });
        }
        break;
      }
      case 'date': {
        const innerValue = event.target.value;
        if (innerValue === '' || !innerValue) {
          handleStatus('error');
        } else {
          handleStatus('');
        }
        if (addNewRecord) {
          handleNewData({ [dataIndex]: innerValue });
        } else {
          handleSave({ ...record, [dataIndex]: innerValue });
        }
        break;
      }
      case 'checkbox': {
        // put here the logic to catch and send the checkboxes data
        break;
      }
      case 'color': {
        // put here the logic to catch and send the color data
        break;
      }
      default: {
        const innerValue = event.target.value;
        if (innerValue === '' || !innerValue) {
          handleStatus('error');
        } else {
          handleStatus('');
        }
        if (addNewRecord) {
          handleNewData({ [dataIndex]: innerValue });
        } else {
          handleSave({ ...record, [dataIndex]: innerValue });
        }
      }
    }
    if (!addNewRecord) {
      toggleEdit();
    }
  };

  let childNode = children;

  if (record?.placeholder) {
    return (
      <td>
        <StyledCellSkeleton
          active
          paragraph={{ rows: 1 }}
          title={false}
          className='virtualist-skeleton'
        />
      </td>
    );
  }

  if (editable) {
    if (!editing) {
      if (record?.newRecord) {
        childNode = (
          <InnerEditableCellRenderer
            type={dataType?.toLocaleLowerCase()}
            options={options?.map((element) => ({
              label: String(element.LABEL),
              value: String(element.VALUE),
            }))}
            defaultValue={
              dataType?.toLocaleLowerCase() === 'multiselect'
                ? multiDataToRender.current?.map((element) => element.id)
                : (dataToRender.current as string)
            }
            handleCreateData={handleManageData}
            status={status}
            addNewRecord={addNewRecord}
            handleDataStatus={toggleEdit}
          />
        );
      } else {
        childNode =
          dataType?.toLocaleLowerCase() === 'multiselect' ? (
            <div onClick={toggleEdit} className='no-editable-cell'>
              {multiDataToRender.current?.length ? (
                multiDataToRender.current?.map((element) => (
                  <Tag key={element.id}>{element.name}</Tag>
                ))
              ) : (
                <Tag key='no-data'>No Data</Tag>
              )}
            </div>
          ) : (
            <div onClick={toggleEdit} className='no-editable-cell'>
              <Text disabled={addNewRecord}>{dataToRender.current ?? 'No Data'}</Text>
            </div>
          );
      }
    } else {
      childNode = (
        <InnerEditableCellRenderer
          type={dataType?.toLocaleLowerCase()}
          options={options?.map((element) => ({
            label: String(element.LABEL),
            value: String(element.VALUE),
          }))}
          defaultValue={
            dataType?.toLocaleLowerCase() === 'multiselect'
              ? multiDataToRender.current?.map((element) => element.id)
              : (dataToRender.current as string)
          }
          handleEditData={handleManageData}
          status={status}
          addNewRecord={addNewRecord}
          handleStatus={required ? handleStatus : undefined}
        />
      );
    }
  } else {
    childNode =
      dataType?.toLocaleLowerCase() === 'multiselect' ? (
        <div className='no-editable-cell'>
          {multiDataToRender.current?.map((element) => (
            <Tag key={element.id}>{element.name}</Tag>
          ))}
        </div>
      ) : (
        <div className='no-editable-cell'>
          {dataIndex && typeof dataToRender.current !== 'object' ? dataToRender.current : children}
        </div>
      );
  }

  const cellKey = `td-${[dataIndex, dataKey].filter(Boolean).join('.')}`;

  return (
    <td {...restProps} data-testid={cellKey}>
      <p>{childNode}</p>
    </td>
  );
};

export { NewEditableCell };
