import { NavLink, useParams } from 'react-router-dom';
import SCompanySetupNav from '../styles/companySetupNav.style';

const TABS = [
  { label: 'Basic', to: 'basic' },
  { label: 'Contacts', to: 'contacts' },
  // { label: 'Activity Journal', to: 'activity-journal' },
  // { label: 'Opportunities', to: 'opportunities' },
  { label: 'Pitches', to: 'pitches' },
  { label: 'Attachments', to: 'attachments' },
  // { label: 'Quotes', to: 'quotes' },
  // { label: 'Buying Form', to: 'buying-form' },
  // { label: 'Jobs', to: 'jobs' },
  // { label: 'Mails', to: 'mails' },
  // { label: 'Samples', to: 'samples' },
  // { label: 'Products', to: 'products' },
  // { label: 'Task', to: 'task' },
];

const CompanySetupNav = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <SCompanySetupNav>
      {TABS.map((link) => (
        <NavLink key={link.to} to={`/companies/${id}/${link.to}`}>
          {link.label}
        </NavLink>
      ))}
    </SCompanySetupNav>
  );
};
export default CompanySetupNav;
