/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Form, FormInstance, Row } from 'antd';
import React from 'react';
// import CustomToolTip from '../customPopover';
import StyledFormItem from './styles/formItem.style';
import InputGroup from './components/inputGroup';
import { FieldType } from './types';
import GenericInput from './components/genericInput';

type Props = {
  fields: FieldType<any>[];
  name?: string;
  form?: FormInstance;
  layout?: 'vertical';
  initialValues?: any;
};

const GenericForm: React.FC<Props> = ({ fields, name, form, layout, initialValues }) => {
  const wrapperFields = fields.map((field) => {
    const Wrapper = field.suffixButton || field.prefixButton ? InputGroup : React.Fragment;
    const className = field.type === 'radio' ? 'vertical' : '';
    const label =
      field.type === 'checkbox' || field.type === 'checkboxEnd' || field.type === 'label'
        ? undefined
        : field.label;
    return (
      <Col span={field.hidden ? 0 : field.span || 24} key={String(field.name)}>
        <StyledFormItem className={className}>
          <Wrapper>
            <Form.Item
              label={field.hidden ? null : label}
              name={field.type !== 'label' ? String(field.name) : undefined}
              rules={!field.readOnly && field.rules ? field.rules : []}
              shouldUpdate
            >
              <GenericInput
                field={field}
                addonBefore={
                  field.prefixButton ? (
                    <Button
                      type='primary'
                      shape={field.prefixType === 'square' ? 'circle' : 'default'}
                      onClick={field.onPrefixButtonClick}
                      style={{ borderRadius: 2 }}
                    >
                      {field.prefixButton}
                    </Button>
                  ) : undefined
                }
                addonAfter={
                  field.suffixButton ? (
                    <Button
                      type='primary'
                      shape={field.suffixType === 'square' ? 'circle' : 'default'}
                      onClick={field.onSuffixButtonClick}
                      style={{ padding: 0, margin: '0 !important!' }}
                      className='add-company-button'
                    >
                      {field.suffixButton}
                    </Button>
                  ) : undefined
                }
              />
            </Form.Item>
          </Wrapper>
        </StyledFormItem>
      </Col>
    );
  });

  if (form) {
    return (
      <Form name={name} form={form} layout={layout} component={false} initialValues={initialValues}>
        <Row gutter={16}>{wrapperFields}</Row>
      </Form>
    );
  }

  return <Row gutter={16}>{wrapperFields}</Row>;
};

export type { FieldType };

export default GenericForm;
