import { divisionAdapter } from 'src/adapters';
import {
  CREATE_DIVISION,
  GET_ALL_DIVISIONS,
  GET_DIVISION,
  REMOVE_DIVISION,
  UPDATE_DIVISION,
} from 'src/graphql';
import { DivisionModel } from 'src/models';
import useEntityAdapter, { OperationSpecs } from '../useEntityAdapter';

const operations: OperationSpecs = {
  createOne: {
    op: CREATE_DIVISION,
  },
  updateOne: {
    op: UPDATE_DIVISION,
  },
  deleteOne: {
    op: REMOVE_DIVISION,
  },
  fetchAll: {
    op: GET_ALL_DIVISIONS,
    dataPath: 'GetAllDivisions',
  },
  fetchOne: {
    op: GET_DIVISION,
    dataPath: 'GetDivision',
  },
};

const useDivisionsStore = () => {
  const api = useEntityAdapter<DivisionModel>({
    adapters: { entityAdapter: divisionAdapter },
    operations,
    isPaginated: true,
    entityName: 'Division',
  });

  return api;
};

export default useDivisionsStore;
