import styled, { createGlobalStyle } from 'styled-components';
import { Input } from 'antd';

const { Search } = Input;
export const CustomCascaderGlobalStyles = createGlobalStyle`
  .ant-cascader-dropdown .ant-cascader-menu {
    min-width: 200px;
    height: 500px;
  }

  .ant-cascader-menu-item-active {
    color: rgb(0, 120, 212) !important;
  }

  .ant-cascader-dropdown.ant-select-dropdown {
    position: unset !important;
  }
    
    
  .ant-cascader-dropdown.ant-select-dropdown {
    border-radius: 0 !important;
    inset-block-start: 160px !important;
  }
  .ant-cascader-menu-item-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-cascader {
    min-width: 205px !important;
    width: 100%;
  }
`;
export const StyledSearch = styled(Search)`
  &&& {
    .ant-input {
      border-radius: 0 !important;
    }
    .ant-input-search-button {
      border-radius: 0 !important;
    }
  }
`;
