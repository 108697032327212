import { Input, InputRef } from 'antd';
import React, { useRef, Ref } from 'react';
import { CreateAddressInput } from 'src/graphql/schema-types';
import useAddressesSubForm, { Props } from './useAddressesSubForm';

const IndividualAddress: any = (props: {
  index: number;
  address: CreateAddressInput;
  parentProps: Props;
}) => {
  const { index, address, parentProps } = props;
  const { handleChange, readOnly } = useAddressesSubForm(parentProps);

  const addressRef: Ref<InputRef> = useRef(null);

  return (
    <Input
      name='address'
      ref={addressRef}
      value={address.address!}
      onChange={(e) => handleChange(e, addressRef?.current?.input ?? undefined)}
      placeholder='Type here...'
      data-index={index}
      readOnly={readOnly}
      disabled={!address.addressType}
    />
  );
};

export default IndividualAddress;
