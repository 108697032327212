import { FC, KeyboardEventHandler, useState } from 'react';
import { AutoComplete } from 'antd';
import useDebouncedCallback from 'src/hooks/useDebouncedCallback';
import { clearFilters } from 'src/utils/functions/companiesFunctions';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { Option, SubTableColumn } from '../types';
import { useSubTableContext } from '../contexts/subtable.context';
import StyledTitle from '../styles/title.style';
import Title from './title';
import { LOADING_DATA_OPTION } from '../consts';

type Props = {
  column: SubTableColumn;
};

const getKey = (dataIndex: number | string | string[], field?: string) => {
  if (field) {
    return field;
  }

  if (Array.isArray(dataIndex)) {
    return dataIndex.join('.');
  }

  return String(dataIndex);
};

const FilterableColumnTitle: FC<Props> = ({ column }) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<unknown[]>([]);
  const {
    nonFilterable,
    isFiltering,
    autocompleteFieldProvider,
    onFilterChange,
    setFilterFields,
    filterFields,
    setDataTable,
    data,
  } = useSubTableContext();

  const { filterable = true, dataIndex, searchField, dataType, key } = column;

  const columnKey = getKey(dataIndex, searchField);

  const filterValue = filterFields?.[columnKey] || '';

  const composedDataIndex = [dataIndex, key].flat().filter(Boolean).join('.');

  const autocompleteValue = async (value: any) => {
    if (autocompleteFieldProvider) {
      try {
        setOptions(LOADING_DATA_OPTION);
        const { filter, results } = await autocompleteFieldProvider(
          searchField as string,
          value,
          dataType as string,
          composedDataIndex,
        );

        // get only with unique by value and remove null values
        const newFilter = filter.filter(
          (item, index, self) =>
            item.value !== null &&
            item.label !== null &&
            index === self.findIndex((t) => t.value === item.value),
        );

        if (newFilter.length) {
          setOptions(newFilter);
          setFilteredOptions(results);
        } else {
          setOptions([]);
          setFilteredOptions([]);
        }
      } catch {
        setOptions([]);
        setFilteredOptions([]);
      }
    } else {
      setOptions([]);
      setFilteredOptions([]);
    }
  };

  const autocomplete = useDebouncedCallback(autocompleteValue, 500);

  const safeSetFilterFields = (filters: Record<string, any>) => {
    const newFilters = clearFilters(filters);
    setFilterFields(newFilters);
  };

  const handleSearch = async (value: string) => {
    safeSetFilterFields({ ...filterFields, [getKey(dataIndex, searchField)]: value });

    let valueCapitalized: string = value;
    if (value && dataIndex === 'name') {
      valueCapitalized = capitalizeFirstWord(value);
    }

    autocomplete(valueCapitalized);
  };

  const handleClear = async () => {
    const results = await autocompleteFieldProvider?.(
      searchField as string,
      '',
      dataType as string,
    );

    const filters = clearFilters({ ...filterFields, [getKey(dataIndex, searchField)]: '' });
    setFilterFields(filters);

    if (onFilterChange) {
      onFilterChange(filters);
    }

    setDataTable(results?.results ?? []);
  };

  const handleSelect = async (value: string) => {
    const results = await autocompleteFieldProvider?.(
      searchField as string,
      value,
      dataType as string,
    );

    const filters = clearFilters({ ...filterFields, [getKey(dataIndex, searchField)]: value });
    setFilterFields(filters);

    if (onFilterChange) {
      onFilterChange(filters);
    }

    setDataTable(results?.results ?? []);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement;
    e.stopPropagation();
    if (e.key === 'Enter') {
      e.preventDefault();
      const filters = clearFilters({ ...filterFields, [columnKey]: target.value });
      if (filteredOptions.length) {
        setDataTable(filteredOptions);
      } else {
        setDataTable(data);
      }
      setFilterFields(filters);
      if (onFilterChange) {
        onFilterChange(filters);
      }
    }
  };

  let childNode = null;

  if (!filterable || nonFilterable) {
    childNode = <Title column={column} />;
  }

  childNode = (
    <>
      <Title column={column} />
      {isFiltering &&
        dataIndex !== 'color' &&
        (!filterable || nonFilterable ? (
          <div style={{ height: 32 }} />
        ) : (
          <AutoComplete
            data-testid={`column-${columnKey}-filter`}
            value={String(filterValue)}
            virtual
            options={options}
            onSearch={handleSearch}
            onSelect={handleSelect}
            onKeyDown={handleKeyDown}
            onClear={handleClear}
            notFoundContent='No results'
            allowClear
            title=''
          />
        ))}
      <div />
    </>
  );

  return (
    <StyledTitle
      data-testid={`column-${columnKey}-title`}
      direction='vertical'
      className='sub-table-column-header'
    >
      {childNode}
    </StyledTitle>
  );
};

export default FilterableColumnTitle;
