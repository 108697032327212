import { useLazyQuery } from '@apollo/client';
import { GET_ALL_ADITIONAL_FIELDS, GET_ALL_COMPANY_ADITIONAL_FIELDS } from '../graphql/queries';
import { GetAllCustomFields, GetAllCompanyCustomFields } from '../types/customFieldsTypes';

const useCustomFieldCard = () => {
  const [getAllCustomField, getAllCustomFieldResponse] = useLazyQuery<GetAllCustomFields>(
    GET_ALL_ADITIONAL_FIELDS,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [getAllCompanyCustomField, getAllCompanyCustomFieldResponse] =
    useLazyQuery<GetAllCompanyCustomFields>(GET_ALL_COMPANY_ADITIONAL_FIELDS, {
      fetchPolicy: 'network-only',
    });

  const fetchCustomFields = (start: number, renderLen: number) =>
    getAllCustomField({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
      },
    });

  const fetchCompanyCustomFields = (id: number) => {
    getAllCompanyCustomField({
      variables: {
        companyId: id,
      },
    });
  };

  return {
    getAllCustomFieldResponse,
    fetchCustomFields,
    fetchCompanyCustomFields,
    getAllCompanyCustomFieldResponse,
  };
};

export default useCustomFieldCard;
