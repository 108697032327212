/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Card, Checkbox, Tooltip } from 'antd';
import { useNavigate, useLocation } from 'react-router';
import FlexCol from 'src/components/companyDetails/styles/flexCol.style';
import useUnSaveChangesWarning from 'src/hooks/useUnSaveChangesWarning';
import { useOppContext } from 'src/context/opportunity.context';
import MultipleActions from 'src/components/commons/subTableV1/components/multipleActions';
import StyledOppHeader from '../styles/oppHeader.styles';

const ContactSetupHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    opportunity,
    processedSave,
    handleSave,
    submitData,
    handleDeleteModal,
    optionSelectFunction,
  } = useOppContext();

  const { setProccessSaving, setConfirmSaving } = useUnSaveChangesWarning(false, handleSave);

  useEffect(() => {
    if (processedSave) {
      setConfirmSaving(true);
    }
  }, [processedSave]);

  const hideEditButton = location.pathname.includes('basic');

  return (
    <StyledOppHeader>
      <Card className='card-container'>
        <FlexCol className='flex-col-container'>
          <FlexCol className='navButton' span={1}>
            <ArrowLeftOutlined
              style={{ fontSize: 16, color: '#9A9A9A', cursor: 'pointer' }}
              onClick={() => navigate('/opportunities')}
            />
          </FlexCol>
          <FlexCol span={11} className='avatar-company-container'>
            <div className='opportunity-info-container'>
              <h3 style={{ color: '#4B4B4B' }}>Opportunity</h3>
              <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                <h2 style={{ minWidth: 'fit-content' }}>{opportunity?.topic}</h2>
                <p style={{ color: '#4B4B4B', minWidth: 'fit-content' }}>
                  Owner: {opportunity?.oppOwner?.name}
                </p>
                <p style={{ color: '#4B4B4B', minWidth: 'fit-content' }}>
                  Value: ${opportunity?.value ? opportunity?.value.toLocaleString() : 0}
                </p>
              </div>
            </div>
          </FlexCol>
          <FlexCol span={12} className='inputs-buttons-container' style={{ justifyContent: 'end' }}>
            {/* <Input prefix={<SearchOutlined />} placeholder='Search' className='input-style' /> */}
            <Checkbox style={{ position: 'relative', top: 12 }}>Reporting</Checkbox>
            <Tooltip title='Delete' color='#fff' overlayInnerStyle={{ color: '#000' }}>
              <DeleteOutlined
                onClick={handleDeleteModal}
                style={{ fontSize: 24, color: 'red', position: 'relative', top: 12 }}
              />
            </Tooltip>
            {hideEditButton && (
              <SaveOutlined
                style={{
                  position: 'relative',
                  top: 12,
                  fontSize: 24,
                  color: submitData ? 'red' : '#9A9A9A',
                  cursor: submitData ? 'pointer' : 'not-allowed',
                }}
                onClick={() => (submitData ? setProccessSaving(true) : undefined)}
              />
            )}
            <MultipleActions
              options={[
                {
                  key: 'close',
                  label: 'Close opportunity',
                },
              ]}
              optionSelect={optionSelectFunction}
            >
              <EllipsisOutlined
                style={{ fontSize: 24, color: '#9A9A9A', position: 'relative', top: 12 }}
              />
            </MultipleActions>
          </FlexCol>
        </FlexCol>
      </Card>
    </StyledOppHeader>
  );
};
export default ContactSetupHeader;
