import { ContactModel } from 'src/models';
import { optional } from 'src/adapters/common';
import {
  addressToInputAdapter,
  emailToInputAdapter,
  list,
  phoneToInputAdapter,
} from 'src/adapters';
import _ from 'lodash';

export const prepareDataToDelete = (element: [number], passedList: number[]) => {
  const innerList = _.cloneDeep(passedList);
  return _.xor(innerList, element);
};

export const mainAndAditionalElement = (elementName: string, contact: ContactModel) => {
  switch (elementName) {
    case 'bussinessInfo.addresses': {
      const mainAddress: any = optional(addressToInputAdapter, contact.businessInfo?.mainAddress);
      const aditionalAddress = list(addressToInputAdapter, contact.businessInfo?.addresses);
      let fullAddress = [{ isMain: true }].concat(aditionalAddress || ([] as any[]));
      if (mainAddress && aditionalAddress) {
        mainAddress.isMain = true;
        fullAddress = [mainAddress].concat(
          aditionalAddress.filter((item) => item.id !== mainAddress.id),
        );
      }
      return fullAddress;
    }
    case 'bussinessInfo.phones': {
      const mainPhone: any = optional(phoneToInputAdapter, contact.businessInfo?.mainPhone);
      const aditionalPhones = list(phoneToInputAdapter, contact.businessInfo?.phones);
      let fullPhones = [{ isMain: true }].concat(aditionalPhones || ([] as any[]));
      if (mainPhone && aditionalPhones) {
        mainPhone.isMain = true;
        fullPhones = [mainPhone].concat(aditionalPhones.filter((item) => item.id !== mainPhone.id));
      }
      return fullPhones;
    }
    case 'bussinessInfo.emails': {
      const mainEmail: any = optional(emailToInputAdapter, contact.businessInfo?.mainEmail);
      const aditionalEmails = list(emailToInputAdapter, contact.businessInfo?.emails);
      let fullEmail = [{ isMain: true }].concat(aditionalEmails || ([] as any[]));
      if (mainEmail && aditionalEmails) {
        mainEmail.isMain = true;
        fullEmail = [mainEmail].concat(aditionalEmails.filter((item) => item.id !== mainEmail.id));
      }
      return fullEmail;
    }
    default:
      return undefined;
  }
};

export const filterContactEmailPhoneAddressInfo = (contact: ContactModel) => {
  const innerContactData = JSON.parse(JSON.stringify(contact));
  // businessInfo
  const innerBusinessInfoAddress = innerContactData.businessInfo?.addresses;
  const innerBusinessInfoEmails = innerContactData.businessInfo?.emails;
  const innerBusinessInfoPhones = innerContactData.businessInfo?.phones;
  // // personalInfo
  const innerPersonalInfoAddress = innerContactData.personalInfo?.addresses;
  const innerPersonalInfoEmails = innerContactData.personalInfo?.emails;
  const innerPersonalInfoPhones = innerContactData.personalInfo?.phones;

  if (innerBusinessInfoAddress) {
    const filteredInnerBusinessInfoAddress = _.filter(
      innerBusinessInfoAddress,
      (obj: any) => obj.address,
    );
    delete innerContactData.businessInfo.addresses;
    if (filteredInnerBusinessInfoAddress.length > 0) {
      filteredInnerBusinessInfoAddress.forEach((address: any) => {
        if (address.isMain) {
          innerContactData.businessInfo.mainAddress = address;
          delete innerContactData.businessInfo.mainAddress.isMain;
        } else if (address.id) {
          if (!innerContactData.businessInfo.updateAddresses) {
            innerContactData.businessInfo.updateAddresses = [address];
          } else {
            innerContactData.businessInfo.updateAddresses.push(address);
          }
        } else if (!innerContactData.businessInfo.addAddresses) {
          innerContactData.businessInfo.addAddresses = [address];
        } else {
          innerContactData.businessInfo.addAddresses.push(address);
        }
      });
    }
  }
  if (innerBusinessInfoEmails) {
    const filteredInnerBusinessInfoEmails = _.filter(
      innerBusinessInfoEmails,
      (obj: any) => obj.email,
    );
    delete innerContactData.businessInfo.emails;
    if (filteredInnerBusinessInfoEmails.length > 0) {
      filteredInnerBusinessInfoEmails.forEach((email: any) => {
        if (email.isMain) {
          innerContactData.businessInfo.mainEmail = email;
          delete innerContactData.businessInfo.mainEmail.isMain;
        } else if (email.id) {
          if (!innerContactData.businessInfo.updateEmails) {
            innerContactData.businessInfo.updateEmails = [email];
          } else {
            innerContactData.businessInfo.updateEmails.push(email);
          }
        } else if (!innerContactData.businessInfo.addEmails) {
          innerContactData.businessInfo.addEmails = [email];
        } else {
          innerContactData.businessInfo.addEmails.push(email);
        }
      });
    }
  }
  if (innerBusinessInfoPhones) {
    const filteredInnerBusinessInfoPhones = _.filter(
      innerBusinessInfoPhones,
      (obj: any) => obj.phone,
    );
    delete innerContactData.businessInfo.phones;
    if (filteredInnerBusinessInfoPhones.length > 0) {
      filteredInnerBusinessInfoPhones.forEach((phone: any) => {
        if (phone.isMain) {
          innerContactData.businessInfo.mainPhone = phone;
          delete innerContactData.businessInfo.mainPhone.isMain;
        } else if (phone.id) {
          if (!innerContactData.businessInfo.edditedPhones) {
            innerContactData.businessInfo.updatePhones = [phone];
          } else {
            innerContactData.businessInfo.updatePhones.push(phone);
          }
        } else if (!innerContactData.businessInfo.addPhones) {
          innerContactData.businessInfo.addPhones = [phone];
        } else {
          innerContactData.businessInfo.addPhones.push(phone);
        }
      });
    }
  }

  if (innerPersonalInfoAddress) {
    const filteredInnerPersonalInfoAddress = _.filter(
      innerPersonalInfoAddress,
      (obj: any) => obj.address,
    );
    delete innerContactData.personalInfo.addresses;
    if (filteredInnerPersonalInfoAddress.length > 0) {
      filteredInnerPersonalInfoAddress.forEach((address: any) => {
        if (address.id) {
          if (!innerContactData.personalInfo.updateAddresses) {
            innerContactData.personalInfo.updateAddresses = [address];
          } else {
            innerContactData.personalInfo.updateAddresses.push(address);
          }
        } else if (!innerContactData.personalInfo.addAddresses) {
          innerContactData.personalInfo.addAddresses = [address];
        } else {
          innerContactData.personalInfo.addAddresses.push(address);
        }
      });
    }
  }
  if (innerPersonalInfoEmails) {
    const filteredInnerPersonalInfoEmails = _.filter(
      innerPersonalInfoEmails,
      (obj: any) => obj.email > 0,
    );
    delete innerContactData.personalInfo.emails;
    if (filteredInnerPersonalInfoEmails.length > 0) {
      filteredInnerPersonalInfoEmails.forEach((email: any) => {
        if (email.id) {
          if (!innerContactData.personalInfo.updateEmails) {
            innerContactData.personalInfo.updateEmails = [email];
          } else {
            innerContactData.personalInfo.updateEmails.push(email);
          }
        } else if (!innerContactData.personalInfo.addEmails) {
          innerContactData.personalInfo.addEmails = [email];
        } else {
          innerContactData.personalInfo.addEmails.push(email);
        }
      });
    }
  }
  if (innerPersonalInfoPhones) {
    const filteredInnerPersonalInfoPhones = _.filter(
      innerPersonalInfoPhones,
      (obj: any) => obj.phone,
    );
    delete innerContactData.personalInfo.phones;
    if (filteredInnerPersonalInfoPhones.length > 0) {
      filteredInnerPersonalInfoPhones.forEach((phone: any) => {
        if (phone.id) {
          if (!innerContactData.personalInfo.updatePhones) {
            innerContactData.personalInfo.updatePhones = [phone];
          } else {
            innerContactData.personalInfo.updatePhones.push(phone);
          }
        } else if (!innerContactData.personalInfo.addPhones) {
          innerContactData.personalInfo.addPhones = [phone];
        } else {
          innerContactData.personalInfo.addPhones.push(phone);
        }
      });
    }
  }
  delete innerContactData.businessInfo.companyType;
  delete innerContactData.businessInfo.salesTeam;
  delete innerContactData.businessInfo.companyName;

  return innerContactData;
};
