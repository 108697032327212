import { gql } from '@apollo/client';

export const CREATE_PRIVATE_TEAM = gql`
  mutation CreatePrivateTeam($createPrivateTeamInput: CreatePrivateTeamInput!) {
    CreatePrivateTeam(createPrivateTeamInput: $createPrivateTeamInput) {
      id
    }
  }
`;

export const UPDATE_PRIVATE_TEAM = gql`
  mutation UpdatePrivateTeam($updatePrivateTeamInput: UpdatePrivateTeamInput!) {
    UpdatePrivateTeam(updatePrivateTeamInput: $updatePrivateTeamInput) {
      id
      name
      type {
        name
        id
      }
    }
  }
`;

export const REMOVE_PRIVATE_TEAM = gql`
  mutation RemovePrivateTeam($id: Int!) {
    RemovePrivateTeam(id: $id)
  }
`;
