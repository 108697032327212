import React, { useState } from 'react';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import CompaniesColumns from 'src/utils/tables/companiesTable';
import {
  CompaniesHeaderType2,
  CompanyHeaderOptionsType2,
  CompanyTypeSelectionCheckgroupType,
} from 'src/types/companiesTypes';
import { checkGroup } from 'src/services/global-variables';
import { useQuery } from '@apollo/client';
import { GET_ALL_CUSTOM_TYPES } from 'src/modules/settings/modules/subTables/modules/company/tabs/type/graphql/queries';
import CompanyTypeSelectionRenderer from './renderer';

interface CompanyTypeSelectionHandlerType {
  required?: boolean;
  dependencyCompanyTypeHandler?: (value: CompanyTypeSelectionCheckgroupType | undefined) => void;
  externalHandleSelectChange?: (e: React.FormEvent<HTMLInputElement>, name?: string) => void;
  checkGroupHandler?: (checkedValues: CheckboxValueType[]) => void;
}

const CompanyTypeSelectionHandler: React.FC<CompanyTypeSelectionHandlerType> = ({
  required,
  dependencyCompanyTypeHandler,
  externalHandleSelectChange,
  checkGroupHandler,
}) => {
  const { companiesHeaderResponse2 } = CompaniesColumns();
  const [companySelection, setCompanySelection] = useState<CompanyHeaderOptionsType2 | null>();
  const [chosenCheckboxes, setChosenCheckboxes] = useState<
    { label: string; value: string }[] | undefined
  >();
  const [selectedCheckOptions, setSelectedCheckOptions] = useState<CheckboxValueType[]>([]);
  const CompaniesCustomTypes = useQuery(GET_ALL_CUSTOM_TYPES, {
    fetchPolicy: 'network-only',
    variables: {
      criteria: {
        pagination: {
          from: 0,
          size: 100,
        },
      },
    },
  })?.data?.GetAllCompanyTypeAlias2?.results;
  const dropDownOption: CompaniesHeaderType2 =
    companiesHeaderResponse2.data?.GetCompaniesDepthGridHeader.find(
      (item: CompaniesHeaderType2) => item.dataIndex === 'companyType',
    );
  const handleSelectChange = (e: React.FormEvent<HTMLInputElement>, name?: string) => {
    const innerValue = e as unknown as string | number;
    const innerSelected = CompaniesCustomTypes!.find((item: any) => item.id === innerValue);
    const innerSelectedCheckboxes: CompanyTypeSelectionCheckgroupType | undefined = checkGroup.find(
      (item) => item.dependencyLabel === innerSelected?.parent?.name,
    );
    if (innerSelectedCheckboxes) {
      setChosenCheckboxes(innerSelectedCheckboxes.options);
      dependencyCompanyTypeHandler?.(innerSelectedCheckboxes);
    } else {
      setChosenCheckboxes(undefined);
      const innerUndefinedData = undefined;
      dependencyCompanyTypeHandler?.(innerUndefinedData);
    }
    setCompanySelection(innerSelected!);
    externalHandleSelectChange?.(e, name);
  };
  const handleCheckboxChange = (e: CheckboxValueType[]) => {
    setSelectedCheckOptions(e);
    if (checkGroupHandler) {
      checkGroupHandler(e);
    }
  };

  return (
    <CompanyTypeSelectionRenderer
      handleChange={handleSelectChange}
      {...{ companySelection }}
      options={
        dropDownOption?.options?.map((item) => ({
          label: item.name,
          value: item.id,
        })) ?? []
      }
      checkGroup={chosenCheckboxes}
      {...{ handleCheckboxChange }}
      {...{ selectedCheckOptions }}
      {...{ required }}
    />
  );
};

export default CompanyTypeSelectionHandler;
