import styled from 'styled-components';

const StyledFileItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 4px 2px;
  cursor: pointer;

  & > span:nth-child(1) {
    margin-right: 4px;
  }

  & > span:nth-child(2) {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #4096ff;
  }

  & > span:nth-child(3) {
    display: none;
  }

  &:hover {
    background: #f5f5f5;

    & > span:nth-child(3) {
      display: block;
    }
  }

  & > span:nth-child(1),
  & > span:nth-child(3) {
    font-size: 18px;
  }
`;

export default StyledFileItem;
