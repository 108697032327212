import React from 'react';
import ContactsContainer from '../containers/ContactsContainer';
import ContactsContextProvider from '../context/contacts.context';

const Contacts = () => (
  <ContactsContextProvider>
    <ContactsContainer />
  </ContactsContextProvider>
);

export default Contacts;
