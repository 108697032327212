import { gql } from '@apollo/client';

const GET_ALL_ADITIONAL_FIELDS = gql`
  query GetAllAdditionalFields(
    $criteria: AdditionalFieldsCriteriaInput!
    $searchFields: FindAdditionalFieldsInput
  ) {
    GetAllAdditionalFields(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        name
        id
        dataStructure
        dataType
        description
        module
      }
    }
  }
`;

const GET_ALL_COMPANY_ADITIONAL_FIELDS = gql`
  query GetAllCompanyAdditionalField($companyId: Int!) {
    GetAllCompanyAdditionalField(companyId: $companyId) {
      company {
        id
      }
      id
      additionalField {
        id
      }
      value
    }
  }
`;

export { GET_ALL_ADITIONAL_FIELDS, GET_ALL_COMPANY_ADITIONAL_FIELDS };
