import { FC, KeyboardEventHandler, useEffect, useState } from 'react';
import { AutoComplete } from 'antd';
import useDebouncedCallback from 'src/hooks/useDebouncedCallback';
import { clearFilters } from 'src/utils/functions/companiesFunctions';
import { SearchOutlined } from '@ant-design/icons';

import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { DefaultOptionType } from 'antd/es/select';
import { SubTableColumn } from '../types';
import { useSubTableContext } from '../contexts/subtable.context';
import StyledTitle from '../styles/title.style';
import Title from './title';
import { LOADING_DATA_OPTION } from '../consts';
import StyledSearchInput from '../styles/searchInput.style';

type Props = {
  column: SubTableColumn;
};

const getKey = (dataIndex: number | string | string[], field?: string) => {
  if (field) {
    return field;
  }

  if (Array.isArray(dataIndex)) {
    return dataIndex.join('.');
  }

  return String(dataIndex);
};

const FilterableColumnTitle: FC<Props> = ({ column }) => {
  const {
    nonFilterable,
    isFiltering,
    autocompleteFieldProvider,
    onFilterChange,
    setFilterFields,
    filterFields,
    setDataTable,
    data,
    useUniqueValues,
    setSearchFieldsOnStore,
    searchFieldsOnStore,
    options,
  } = useSubTableContext();
  const [filteredOptions, setFilteredOptions] = useState<unknown[]>([]);
  const [localOptions, setLocalOptions] = useState<unknown[]>(options);
  const { filterable = true, dataIndex, dataType, key, criteriaFilterField, searchField } = column;

  const columnKey = getKey(dataIndex, searchField);

  const [filterValue, setFilterValue] = useState<string>(
    filterFields?.[columnKey] || searchFieldsOnStore?.[columnKey] || '',
  );

  const composedDataIndex = [dataIndex, key].flat().filter(Boolean).join('.');

  const autocompleteValue = async (value: any) => {
    if (autocompleteFieldProvider) {
      try {
        if (useUniqueValues) {
          setLocalOptions(LOADING_DATA_OPTION);
          const { filter, results } = await autocompleteFieldProvider(
            !useUniqueValues ? (searchField as string) : (criteriaFilterField as string),
            value,
            dataType as string,
            composedDataIndex,
          );

          // get only with unique by value and remove null values
          const newFilter = filter.filter(
            (item, index, self) =>
              item.value !== null &&
              item.label !== null &&
              index === self.findIndex((t) => t.value === item.value),
          );

          if (newFilter.length) {
            setLocalOptions(newFilter);
            setFilteredOptions(results);
          } else {
            setLocalOptions([]);
            setFilteredOptions([]);
          }
        } else {
          setLocalOptions(LOADING_DATA_OPTION);
          const { filter, results } = await autocompleteFieldProvider(
            !useUniqueValues ? (searchField as string) : (criteriaFilterField as string),
            value,
            dataType as string,
            composedDataIndex,
          );

          // get only with unique by value and remove null values
          const newFilter = filter.filter(
            (item, index, self) =>
              item.value !== null &&
              item.label !== null &&
              index === self.findIndex((t) => t.value === item.value),
          );

          if (newFilter.length) {
            setLocalOptions(newFilter);
            setFilteredOptions(results);
          } else {
            setLocalOptions([]);
            setFilteredOptions([]);
          }
        }
      } catch {
        setLocalOptions([]);
        setFilteredOptions([]);
      }
    } else {
      setLocalOptions([]);
      setFilteredOptions([]);
    }
  };

  const autocomplete = useDebouncedCallback(autocompleteValue, 500);

  const safeSetFilterFields = (filters: Record<string, any>) => {
    const newFilters = clearFilters(filters);
    setFilterFields(newFilters);
  };

  const handleSearch = async (value: string) => {
    if (useUniqueValues) {
      safeSetFilterFields({ ...filterFields, [getKey(dataIndex, searchField)]: value });

      let valueCapitalized: string = value;
      if (value && dataIndex === 'name') {
        valueCapitalized = capitalizeFirstWord(value);
      }

      autocomplete(valueCapitalized);
    } else {
      safeSetFilterFields({ ...filterFields, [getKey(dataIndex, searchField)]: value });

      let valueCapitalized: string = value;
      if (value && dataIndex === 'name') {
        valueCapitalized = capitalizeFirstWord(value);
      }

      autocomplete(valueCapitalized);
    }
  };

  const handleClear = async () => {
    setFilterValue('');
    if (useUniqueValues) {
      if (!searchFieldsOnStore[getKey(dataIndex, searchField)]) return;
      const localFilters = clearFilters({
        ...searchFieldsOnStore,
        [getKey(dataIndex, searchField)]: '',
      });
      setFilterFields(localFilters);
      setSearchFieldsOnStore?.(localFilters);
    } else {
      const results = await autocompleteFieldProvider?.(
        searchField as string,
        '',
        dataType as string,
      );

      const filters = clearFilters({ ...filterFields, [getKey(dataIndex, searchField)]: '' });
      setFilterFields(filters);

      if (onFilterChange) {
        onFilterChange(filters);
      }

      setDataTable(results?.results ?? []);
    }
  };

  const handleSelect = async (value: string) => {
    setFilterValue(value);
    if (useUniqueValues) {
      const localFilters = clearFilters({
        ...searchFieldsOnStore,
        [getKey(dataIndex, searchField)]: value,
      });
      setFilterFields(localFilters);
      setSearchFieldsOnStore?.(localFilters);
    } else {
      const results = await autocompleteFieldProvider?.(
        searchField as string,
        value,
        dataType as string,
      );

      const filters = clearFilters({ ...filterFields, [getKey(dataIndex, searchField)]: value });
      setFilterFields(filters);

      if (onFilterChange) {
        onFilterChange(filters);
      }

      setDataTable(results?.results ?? []);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement;
    e.stopPropagation();

    if (e.key === 'Enter') {
      e.preventDefault();
      const filters = clearFilters({ ...filterFields, [columnKey]: target.value });

      setFilterValue(target.value); // Update filterValue state
      if (!useUniqueValues) {
        if (filteredOptions.length) {
          setDataTable(filteredOptions);
        } else {
          setDataTable(data);
        }
      }
      setFilterFields(filters);
      setSearchFieldsOnStore?.(filters);

      if (onFilterChange) {
        onFilterChange(filters);
      }
    }

    if (e.key === 'Backspace' || e.key === 'Delete') {
      if (target.value.length === 1) {
        handleClear();
      }
    }
  };

  let childNode = null;

  if (!filterable || nonFilterable) {
    childNode = <Title column={column} />;
  }

  useEffect(() => {
    if (!useUniqueValues) {
      setLocalOptions(options);
      setFilterValue('');
    }
  }, [data, options]);

  childNode = (
    <>
      <Title column={column} />
      {isFiltering &&
        dataIndex !== 'color' &&
        (!filterable || nonFilterable ? (
          <div style={{ height: 32 }} />
        ) : (
          <AutoComplete
            data-testid={`column-${columnKey}-filter`}
            value={String(filterValue)}
            virtual
            options={localOptions as unknown as DefaultOptionType[]}
            onSearch={handleSearch}
            onSelect={handleSelect}
            onKeyDown={handleKeyDown}
            onClear={handleClear}
            notFoundContent='No results'
            allowClear
            onChange={(value) => {
              setFilterValue(value);
            }}
            title=''
          >
            <StyledSearchInput placeholder='Search' prefix={<SearchOutlined />} />
          </AutoComplete>
        ))}
      <div />
    </>
  );

  return (
    <StyledTitle
      data-testid={`column-${columnKey}-title`}
      direction='vertical'
      className='sub-table-column-header'
    >
      {childNode}
    </StyledTitle>
  );
};

export default FilterableColumnTitle;
