import React from 'react';
import ContactsSubHeader from 'src/components/commons/subHeader/contacts2';
import PageContainer from 'src/components/pageContainer/PageContainer';
import ContactsTable from '../components/table';

const ContactsContainer = () => (
  <div>
    <PageContainer>
      <ContactsSubHeader />
      <ContactsTable />
    </PageContainer>
  </div>
);

export default ContactsContainer;
