import { FC, useEffect, useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
  address: string;
}
declare let google: any;
const MapAndLocation: FC<Props> = ({ address }) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [addedScript, setAddedScript] = useState(false);

  const mapRef = useRef(null);

  useEffect(() => {
    if (typeof google !== 'undefined') return;
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    const timer = setTimeout(() => {
      setAddedScript(true);
    }, 3000);
    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const getCoordinates = () => {
    if (address === '') return;

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results: any, status: any) => {
      if (status === 'OK') {
        const { lat, lng } = results[0].geometry.location;
        setLatitude(lat());
        setLongitude(lng());
      }
    });
  };

  useEffect(() => {
    if (typeof google !== 'undefined') getCoordinates();
  }, [addedScript]);

  useEffect(() => {
    if (latitude === null || longitude === null) return;
    const mapOptions = {
      center: { lat: latitude, lng: longitude },
      zoom: 13,
    };
    const map = new google.maps.Map(mapRef.current, mapOptions);
    // eslint-disable-next-line no-new
    new google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
    });
  }, [latitude, longitude]);

  return typeof google === 'undefined' ? (
    <>
      <LoadingOutlined style={{ fontSize: 32 }} />
      <div ref={mapRef} style={{ height: '100%', width: '100%' }} />
    </>
  ) : (
    <div ref={mapRef} style={{ height: '100%', width: '100%' }} data-testid='map' />
  );
};

export default MapAndLocation;
