import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete } from 'antd';
import { useEffect, useState } from 'react';
import StyledSearchInput from '../styles/searchInput.style';
import { useSubTableContext } from '../contexts/subtable.context';
import { Option } from '../types';
import { LOADING_DATA_OPTION, NO_DATA_OPTION } from '../consts';

const GlobalSearch = (globalSearchProps: any) => {
  const { placeholder } = globalSearchProps;

  const {
    setGlobalFilter,
    autocompleteGlobalProvider,
    onGlobalFilterChange,
    data,
    onRowClick,
    setRecord,
    onSearch,
    localGlobalSearch,
    setClickableRow,
  } = useSubTableContext();
  const [options, setOptions] = useState<Option[]>([]);
  const [currentValue, setCurrentValue] = useState<string>('');

  const performSearch = async (value: string) => {
    if (onGlobalFilterChange) {
      onGlobalFilterChange(value);
      if (autocompleteGlobalProvider) {
        try {
          setOptions(LOADING_DATA_OPTION);
          const results = await autocompleteGlobalProvider(value);
          if (results?.length) {
            setOptions(results);
          } else {
            setOptions(NO_DATA_OPTION);
          }
        } catch {
          setOptions(NO_DATA_OPTION);
        }
      }
    } else if (onRowClick) {
      if (autocompleteGlobalProvider) {
        const innerResult = await autocompleteGlobalProvider(value);
        const opts = innerResult;
        setOptions(opts.filter((e) => e.label.toLowerCase().includes(value.toLowerCase())));
      }
    } else if (localGlobalSearch) {
      // missing integration with onSearch because searchfields in salesTeams are not working
      setOptions(
        data
          .filter((e) => e.name.toLowerCase().includes(value.toLowerCase()))
          .map((e) => ({ label: e.name, value: e.name })),
      );
    } else {
      setOptions(NO_DATA_OPTION);
    }
  };

  const handleSearch = async (value: string) => {
    setGlobalFilter(value);
    setCurrentValue(value);
    if (!value) {
      setOptions([]);
      return;
    }
    performSearch(value);
  };

  useEffect(() => {
    if (data.length === 1) {
      const val = data.find((e) => e.company?.name === currentValue);
      onRowClick?.(val);
      setRecord(val);
    }
  }, [data]);

  const handleSelect = async (value: string) => {
    if (onRowClick) {
      await onSearch(value);
      setCurrentValue(value);
    } else {
      setGlobalFilter(value);
      if (localGlobalSearch) {
        const val = data.find((e) => e.name?.toLowerCase() === value.toLowerCase());
        setCurrentValue(value);
        setClickableRow(val.id || val.ID || val.key);
        onSearch(value);
      }
      if (onGlobalFilterChange) {
        onGlobalFilterChange(value);
      }
    }
  };

  const handleClear = async () => {
    setCurrentValue('');
    await onSearch('');
  };

  // Added onPressEnter prop to handle enter key without selection
  const handlePressEnter = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setCurrentValue(value);
    onSearch(value);
  };

  return (
    <AutoComplete
      data-testid='subtable-global-search'
      style={{ width: '100%' }}
      options={options}
      onSearch={handleSearch}
      onSelect={handleSelect}
      onClear={handleClear}
      value={currentValue}
      allowClear
    >
      <StyledSearchInput
        placeholder={placeholder || 'Search'}
        prefix={<SearchOutlined />}
        onPressEnter={handlePressEnter} // Listening for the enter key press
      />
    </AutoComplete>
  );
};

export default GlobalSearch;
