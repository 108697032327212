import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CREATE_SALES_TEAM, REMOVE_SALES_TEAM } from 'src/graphql/mutations/sales-team';
import { GET_ALL_SALES_TEAMS, GET_ALL_SALES_TEAMS_SHORT } from 'src/graphql/queries/sales-team';
import {
  CreateSalesTeamInput,
  Mutation,
  MutationCreateSalesTeamArgs,
  MutationRemoveSalesTeamArgs,
  Query,
  QueryGetAllSalesTeamArgs,
  SalesTeamModel,
} from 'src/graphql/schema-types';

type GetAllSaleTeamsResult = Pick<Query, 'getAllSalesTeams'>;
type RemoveSaleTeamResult = Pick<Mutation, 'RemoveSalesTeam'>;
type CreateSaleTeamResult = Pick<Mutation, 'CreateSalesTeam'>;

const useSaleTeamsStore = (autoInit = true) => {
  const [entities, setEntities] = useState<SalesTeamModel[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const [queryGetAll] = useLazyQuery<GetAllSaleTeamsResult, QueryGetAllSalesTeamArgs>(
    GET_ALL_SALES_TEAMS,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [queryGetAllSalesTeamOld, queryGetAllSalesTeamOldResponse] = useLazyQuery(
    GET_ALL_SALES_TEAMS_SHORT,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [mutationDeleteOne] = useMutation<RemoveSaleTeamResult, MutationRemoveSalesTeamArgs>(
    REMOVE_SALES_TEAM,
  );

  const [mutationCreateOne] = useMutation<CreateSaleTeamResult, MutationCreateSalesTeamArgs>(
    CREATE_SALES_TEAM,
  );

  const getAll = (variables: QueryGetAllSalesTeamArgs) => {
    setLoading(true);
    return queryGetAll({ variables })
      .then((result) => {
        if (result.data?.getAllSalesTeams) {
          const entitiesData = result.data.getAllSalesTeams.results! as unknown as SalesTeamModel[];
          setEntities(entitiesData!);
          setTotal(result.data.getAllSalesTeams.total!);
        }
        return result;
      })
      .catch((err) => {
        setEntities([]);
        setTotal(0);
        setError(err);
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllSalesTeamOld = () => {
    queryGetAllSalesTeamOld({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 1000,
          },
        },
      },
    });
  };

  const removeOne = (id: number) => {
    setDeleting(true);
    return mutationDeleteOne({
      variables: {
        id,
      },
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const createOne = (createSalesTeamInput: CreateSalesTeamInput) => {
    setCreating(true);
    return mutationCreateOne({
      variables: {
        createSalesTeamInput,
      },
    })
      .catch((err) => {
        setError(err);
        throw err;
      })
      .finally(() => {
        setCreating(false);
      });
  };

  useEffect(() => {
    if (autoInit) {
      getAll({
        Criteria: {
          pagination: {
            from: 0,
            size: 100,
          },
        },
      });
      getAllSalesTeamOld();
    }
  }, []);

  return {
    entities,
    total,
    loading,
    deleting,
    creating,
    error,
    getAll,
    removeOne,
    createOne,
    queryGetAllSalesTeamOldResponse,
  };
};

export default useSaleTeamsStore;
