/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlusOutlined, DeleteOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space, Tooltip } from 'antd';
import { FC } from 'react';
import { getDefaultValue, getValueFromSelectToInput } from 'src/utils/functions/getDefaultValues';
import InputGroup from '../commons/genericForm/components/inputGroup';
import useAddressesSubForm, { Props, buildSelectHandler } from './useAddressesSubForm';
import CustomSelect from '../commons/customSelect';
import IndividualAddress from './individualAddress';

const Addresses: FC<Props> = (props) => {
  const {
    options,
    addresses,
    visibility,
    handleChange,
    handleAddAddress,
    handleRemoveAddress,
    handleToggleVisibility,
    readOnly,
    formName,
  } = useAddressesSubForm(props);

  return (
    <Form component={false}>
      <Row gutter={16}>
        <Col span={24}>
          {addresses.map((address, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Row gutter={16} key={`address-${index}`}>
              {index > 0 && (
                <>
                  <Col span={6}>
                    <Form.Item label='Address Type'>
                      {readOnly ? (
                        <Input
                          readOnly
                          value={getValueFromSelectToInput(
                            address.addressType,
                            options.addressType as [],
                          )}
                          placeholder='Select address type'
                        />
                      ) : (
                        <CustomSelect
                          placeHolder='Select address type'
                          defaultValue={getDefaultValue(address.addressType, options.addressType)}
                          options={options.addressType ?? []}
                          handleChange={(e) =>
                            buildSelectHandler(index, 'addressType', handleChange)(e as any)
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col
                    span={18}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-end',
                      paddingBottom: '12px',
                    }}
                  >
                    <Space wrap>
                      <Tooltip title='Remove Address'>
                        <Button
                          shape='circle'
                          icon={<DeleteOutlined />}
                          onClick={
                            readOnly
                              ? (e) => {
                                  e.preventDefault();
                                  return false;
                                }
                              : () => handleRemoveAddress(address.id!, index, formName)
                          }
                        />
                      </Tooltip>{' '}
                      {visibility[index] ? (
                        <Tooltip title='Collapse Address'>
                          <Button
                            shape='circle'
                            icon={<EyeInvisibleOutlined />}
                            onClick={
                              readOnly
                                ? (e) => {
                                    e.preventDefault();
                                    return false;
                                  }
                                : () => handleToggleVisibility(index)
                            }
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title='Expand Address'>
                          <Button
                            shape='circle'
                            icon={<EyeOutlined />}
                            onClick={
                              readOnly
                                ? (e) => {
                                    e.preventDefault();
                                    return false;
                                  }
                                : () => handleToggleVisibility(index)
                            }
                          />
                        </Tooltip>
                      )}
                    </Space>
                  </Col>
                </>
              )}
              {visibility[index] && (
                <>
                  <Col span={24}>
                    <Form.Item>
                      <InputGroup>
                        <IndividualAddress index={index} address={address} parentProps={props} />
                        <Tooltip title='Add new Address' color='#CCCCCC'>
                          <Button
                            disabled={!address.addressType}
                            type='primary'
                            shape='circle'
                            style={{ borderRadius: 2 }}
                            onClick={
                              readOnly
                                ? (e) => {
                                    e.preventDefault();
                                    return false;
                                  }
                                : () => handleAddAddress(index)
                            }
                          >
                            <PlusOutlined />
                          </Button>
                        </Tooltip>
                      </InputGroup>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label='Po Box'>
                      <Input
                        disabled={!address.addressType}
                        name='pobox'
                        value={address.pobox!}
                        placeholder='Type here...'
                        onChange={handleChange}
                        data-index={index}
                        readOnly={readOnly}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label='Postal Code'>
                      <Input
                        disabled={!address.addressType}
                        name='zipCode'
                        value={address.zipCode!}
                        placeholder='Type here...'
                        onChange={handleChange}
                        data-index={index}
                        readOnly={readOnly}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label='Region'>
                      {readOnly ? (
                        <Input
                          disabled={!address.addressType}
                          readOnly
                          value={getValueFromSelectToInput(address.region, options.regions as [])}
                          placeholder='Select region'
                        />
                      ) : (
                        <CustomSelect
                          disabled={!address.addressType}
                          placeHolder='Select region'
                          externalValue={getDefaultValue(address.region, options.regions)}
                          options={options.regions ?? []}
                          addressOnChange={buildSelectHandler(index, 'region', handleChange)}
                          isAddress
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label='Territory'>
                      {readOnly ? (
                        <Input
                          readOnly
                          disabled={!address.addressType}
                          value={getValueFromSelectToInput(
                            address.territory,
                            options.territories as [],
                          )}
                          placeholder='Select territory'
                        />
                      ) : (
                        <CustomSelect
                          disabled={!address.addressType}
                          placeHolder='Select territory'
                          externalValue={getDefaultValue(address.territory, options.territories)}
                          options={options.territories ?? []}
                          addressOnChange={buildSelectHandler(index, 'territory', handleChange)}
                          isAddress
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label='City'>
                      <Input
                        disabled={!address.addressType}
                        name='city'
                        value={address.city!}
                        placeholder='Type here...'
                        onChange={handleChange}
                        data-index={index}
                        readOnly={readOnly}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label='State'>
                      {readOnly ? (
                        <Input
                          disabled={!address.addressType}
                          readOnly
                          value={getValueFromSelectToInput(address.state, options.states as [])}
                          placeholder='Select state'
                        />
                      ) : (
                        <CustomSelect
                          disabled={!address.addressType}
                          placeHolder='Select state'
                          externalValue={getDefaultValue(address.state, options.states)}
                          options={options?.states ?? []}
                          addressOnChange={buildSelectHandler(index, 'state', handleChange)}
                          isAddress
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label='Country'>
                      {readOnly ? (
                        <Input
                          readOnly
                          disabled={!address.addressType}
                          value={getValueFromSelectToInput(
                            address.country,
                            options.countries as [],
                          )}
                          placeholder='Select country'
                        />
                      ) : (
                        <CustomSelect
                          placeHolder='Select country'
                          disabled={!address.addressType}
                          externalValue={getDefaultValue(address.country, options.countries)}
                          options={options?.countries ?? []}
                          addressOnChange={buildSelectHandler(index, 'country', handleChange)}
                          isAddress
                        />
                      )}
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          ))}
        </Col>
      </Row>
    </Form>
  );
};

export default Addresses;
