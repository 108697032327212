import { useEffect, useState, useRef } from 'react';
import { Option, SortModel, SubTableColumn } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { AllFilters2 } from 'src/graphql/schema-types';
import { SalesTeamModel } from 'src/models';
import useSalesTeamsStore from 'src/hooks/stores/useSalesTeamsStore';
import { useLazyQuery } from '@apollo/client';
import { UsersModel, AllUsersModel } from 'src/models/users.model';
import { GET_ALL_USERS } from 'src/graphql/queries/users';
// import handleError from 'src/utils/functions/handleError';
import COLUMNS, { COLUMNS_MEMBERS, USERCOLUMNS } from '../constants';

const useSalesTeamsSettingsTable = () => {
  const [deleting, setDeleting] = useState(false);
  const {
    loading,
    reset,
    dataSource,
    loadPage: onListRender,
    fetchAll,
    deleteOne,
    updateOne,
    createOne,
    setDataSource,
  } = useSalesTeamsStore();

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState<SubTableColumn[]>([]);
  const [userColumns, setUserColumns] = useState<SubTableColumn[]>([]);
  const [columnsMembers, setColumnsMembers] = useState<SubTableColumn[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [allUsersData, setAllUsersData] = useState<UsersModel[]>();
  const [newRecord, setNewRecord] = useState({
    name: '',
  });
  const firstRender = useRef(true);
  const [getAllUsers] = useLazyQuery<AllUsersModel>(GET_ALL_USERS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (firstRender.current) {
      setSelectedRecord(dataSource[0]);
    }
  }, [dataSource]);

  useEffect(() => {
    if (selectedRecord?.id) {
      firstRender.current = false;
    }
  }, [selectedRecord]);

  useEffect(() => {
    setColumns(COLUMNS);
    setUserColumns(USERCOLUMNS);
    setColumnsMembers(COLUMNS_MEMBERS);
    getAllUsers().then((res) => setAllUsersData(res.data?.GetAllUsers));
  }, []);

  const autocompleteFieldSearch = (
    searchField: string,
    value: string,
    dataType: string | number | string[],
  ) =>
    fetchAll({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },
          filter: [
            {
              fieldName: searchField,
              value,
              dataType,
              operator: AllFilters2.Contains,
            },
          ],
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => {
      if (res.data) {
        return {
          results: res?.data?.GetAllDivisions as any[],
          filter: res?.data?.GetAllDivisions?.map((result: any) => ({
            value: result[searchField],
            label: result[searchField],
          })) as Option[],
        };
      }

      return {
        results: [],
        filter: [],
      };
    });

  const handleUpdateRows = (rows: SalesTeamModel[]) => {
    rows.forEach((row) => {
      const { id, name, companySplit } = row;
      updateOne({
        variables: {
          updateSalesTeamInput: {
            id,
            name: capitalizeFirstWord(name!),
            companySplit: Number(companySplit),
          },
        },
      });
    });
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
    });
  };

  const createNewRecord = async (record: SalesTeamModel) => {
    if (!record) return;
    const { name, companySplit } = record;
    if (name === '') return;
    try {
      await createOne({
        variables: {
          createSalesTeamInput: {
            name: capitalizeFirstWord(name as string),
            companySplit: Number(companySplit) / 100,
          },
        },
      }).then((res) => {
        setDataSource((prev) => [
          {
            name: res?.data?.CreateSalesTeam?.name,
            companySplit: res?.data?.CreateSalesTeam?.companySplit,
            id: res?.data?.CreateSalesTeam?.id,
          },
          ...prev,
        ]);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteRows = async (rows: SalesTeamModel[]) => {
    const innerElement = rows[0].id as any;
    setDeleting(true);
    deleteOne(innerElement).then(() => setDeleting(false));
  };

  const handleClearFilter = () => {
    reset();
  };

  const onSearch = (value: string) => {
    setSelectedRecord(dataSource.find((e) => e.name === value));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    handleUpdateRows,
    newRecord,
    postData,
    createNewRecord,
    resetNewRecord,
    spinning: loading || deleting,
    dataSource,
    onListRender,
    handleClearFilter,
    handleDeleteRows,
    columnsMembers,
    setSelectedRecord,
    selectedRecord,
    onSearch,
    allUsersData,
    setAllUsersData,
    userColumns,
  };
};

export default useSalesTeamsSettingsTable;
