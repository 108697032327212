import { gql } from '@apollo/client';

export const CREATE_OPP = gql`
  mutation CreateOpportunities($createOpportunitiesInput: CreateOpportunitiesInput!) {
    CreateOpportunities(createOpportunitiesInput: $createOpportunitiesInput) {
      id
      topic
      followUp
      value
      nextStep
      priority
      oppStatus {
        id
        name
      }
      oppStage {
        id
        name
      }
      customer {
        id
        name
      }
      manufacturer {
        id
        name
      }
      distributor {
        id
        name
      }
      salesTeam {
        id
        name
      }
      closedReason {
        id
        name
      }
      failedReason
      bid
      closedStatus {
        id
        name
      }
      estimatedCloseDate
      oppOwner {
        id
        name
      }
      type {
        id
        name
      }
      leadSource {
        id
        name
      }
      city
      productLine {
        id
        name
      }
      state {
        id
        name
      }
      commissionRate
      totalPrice
      manufacturerContact {
        id
        name
      }
      distributorContact {
        id
        name
      }
      customerContact {
        id
        name
      }
      enabled
      createdAt
      updatedAt
      notifyWatchers
      competitors
      description
      reportingComments
      registrationNumber
      approvedBy
      registrationLevel
      notes
      approvedDate
      expirationDate
      registrationStatus
      potential
      estAnnualQty
    }
  }
`;

export const CLOSE_OPP = gql`
  mutation CloseOpportunity($closeOpportunityData: CloseOpportunityInputDto!) {
    CloseOpportunity(closeOpportunityData: $closeOpportunityData) {
      id
    }
  }
`;
