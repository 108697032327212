import React from 'react';
import StyledUnderConstruction from './UnderConstruction.styles';

type CustomStyleProps = {
  messageFontSize?: string;
  messageTextAlign?: string;
  svgWidth?: string;
  svgHeight?: string;
  svgMargin?: string;
};

type Props = {
  message?: string;
  style?: CustomStyleProps;
};

const convertStyleToProps = (style?: CustomStyleProps) => {
  const cssVariables: { [key: string]: string } = {};

  if (style) {
    if (style.messageFontSize) {
      cssVariables['--message-font-size'] = style.messageFontSize;
    }

    if (style.messageTextAlign) {
      cssVariables['--message-text-align'] = style.messageTextAlign;
    }

    if (style.svgWidth) {
      cssVariables['--svg-width'] = style.svgWidth;
    }

    if (style.svgHeight) {
      cssVariables['--svg-height'] = style.svgHeight;
    }

    if (style.svgMargin) {
      cssVariables['--svg-margin'] = style.svgMargin;
    }
  }

  return cssVariables;
};

const UnderConstruction: React.FC<Props> = ({ message, style }) => {
  const cssVariables = convertStyleToProps(style);
  return (
    <StyledUnderConstruction style={cssVariables}>
      <div className='message'>{message || 'Under Construction'}</div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='76'
        height='76'
        viewBox='0 0 76 76'
        fill='none'
      >
        <mask
          id='mask0_5628_345001'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='76'
          height='76'
        >
          <rect width='76' height='76' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask0_5628_345001)'>
          <path
            d='M59.85 66.4991L42.5125 49.1616L49.1625 42.5116L66.5 59.8491L59.85 66.4991ZM16.15 66.4991L9.50002 59.8491L31.35 37.9991L25.9667 32.6158L23.75 34.8324L19.7125 30.7949V37.2866L17.4959 39.5033L7.91669 29.9241L10.1334 27.7074H16.625L12.6667 23.7491L23.9084 12.5074C24.9639 11.4519 26.0986 10.6866 27.3125 10.2116C28.5264 9.73659 29.7667 9.49909 31.0334 9.49909C32.3 9.49909 33.5403 9.73659 34.7542 10.2116C35.9681 10.6866 37.1028 11.4519 38.1584 12.5074L30.875 19.7908L34.8334 23.7491L32.6167 25.9658L38 31.3491L45.125 24.2241C44.9139 23.6435 44.7424 23.0366 44.6104 22.4033C44.4785 21.7699 44.4125 21.1366 44.4125 20.5033C44.4125 17.3894 45.4813 14.7637 47.6188 12.6262C49.7563 10.4887 52.382 9.41992 55.4959 9.41992C56.2875 9.41992 57.0396 9.49909 57.7521 9.65742C58.4646 9.81576 59.1903 10.0533 59.9292 10.3699L52.0917 18.2074L57.7917 23.9074L65.6292 16.0699C65.9986 16.8088 66.2493 17.5345 66.3813 18.247C66.5132 18.9595 66.5792 19.7116 66.5792 20.5033C66.5792 23.6171 65.5104 26.2428 63.3729 28.3803C61.2354 30.5178 58.6097 31.5866 55.4959 31.5866C54.8625 31.5866 54.2292 31.5338 53.5959 31.4283C52.9625 31.3227 52.3556 31.138 51.775 30.8741L16.15 66.4991Z'
            fill='#4B4B4B'
          />
        </g>
      </svg>
    </StyledUnderConstruction>
  );
};

export default UnderConstruction;
