import React from 'react';
import SubTable from 'src/components/commons/subTable';
import usePredefinedTypesTable from '../../hooks/usePredefinedTypesTable';
import StylePaginationLess from '../../styles/StyleCustomTab.style';

const TablePredefinedTypes = ({ handleCollapseToggle }: { handleCollapseToggle?: () => void }) => {
  const {
    autocompleteFieldSearch,
    handleFilterChange,
    handleSortModelChange,
    handleUpdateRows,
    columns,
    setColumns,
    columnsToSelectOption,
    columnsHeaderSelected,
    predefinedTypesData,
  } = usePredefinedTypesTable();

  return (
    <StylePaginationLess>
      <SubTable
        data={predefinedTypesData.current || []}
        columns={columns}
        setColumns={setColumns}
        columnsToSelectOption={columnsToSelectOption}
        columnsHeaderSelected={columnsHeaderSelected}
        autocompleteFieldProvider={autocompleteFieldSearch}
        onFilterChange={handleFilterChange}
        onSortChange={handleSortModelChange}
        handleUpdateRows={handleUpdateRows}
        scroll={{ y: 380, x: '100%' }}
        headerActions={['edit', 'collapsed']}
        id='predefined-types-table'
        nonGlobalFilterable
        nonAddable
        showOptionFilterLeft={false}
        showAutoIncrement
        allowGlobalEditActive={false}
        pagination
        editBySelected={false}
        handleCollapseToggle={handleCollapseToggle}
      />
    </StylePaginationLess>
  );
};

export default TablePredefinedTypes;
