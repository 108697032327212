import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { CloseReasonModel } from '../models/closeReason.model';

export const closeReasonAdapter: AdapterFunc<
  GQL.SubTableByCompanyElasticResponse,
  CloseReasonModel
> = (input) => ({
  id: input.id!,
  company: input.company!,
  data: input.data!,
});
