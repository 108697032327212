import { gql } from '@apollo/client';

const DELETE_ONE_ROW_MUTATION = gql`
  mutation DeleteMyObject($id: ID!) {
    deleteMyObject(id: $id)
  }
`;

const DELETE_ROWS_MUTATION = gql`
  mutation CompanyBatchRemove($ids: [Int!]!) {
    CompanyBatchRemove(ids: $ids) {
      processed
      company {
        id
        name
      }
      error {
        message
      }
    }
  }
`;

const SETTING_UP_DEFAULT_STATUS = gql`
  mutation settingUpDefaultStatus($id: String!, $status: Boolean!) {
    settingUpDefaultStatus(id: $id, status: $status)
  }
`;

export const UPDATE_COMPANY_BATCH = gql`
  mutation CompanyBatchUpdate($updateCompanyBatchInput: UpdateCompanyBatchInput!) {
    CompanyBatchUpdate(updateCompanyBatchInput: $updateCompanyBatchInput) {
      company {
        id
        name
      }
      processed
    }
  }
`;

const UPDATE_COMPANIES = gql`
  mutation UpdateCompaniesInline($data: UpdateCompanyElasticInputList2!) {
    UpdateCompaniesInline(data: $data)
  }
`;

const CREATE_NEW_COMPANIES = gql`
  mutation CreateCompany($createCompanyInput: CreateCompanyInput!) {
    CreateCompany(createCompanyInput: $createCompanyInput) {
      addresses {
        address
        city
        country {
          name
          id
        }
        pobox
        region {
          name
          id
        }
        state {
          name
          id
        }
        territory {
          name
          id
        }
        geopositionLongitude
        geopositionLatitude
        addressType {
          id
          name
        }
      }
      callPattern {
        id
        name
      }
      category {
        id
        name
      }
      comments
      companyClass {
        name
        id
      }
      companyType {
        id
        name
      }
      industries {
        id
        name
      }
      divisions {
        name
        id
      }
      website
      visitFrequency
      rank {
        id
        name
      }
      privateTeam {
        name
        id
      }
      printName
      name
      phones {
        phoneType {
          id
          name
        }
        countryCode
        phone
        areaCode
      }
      hasHolydayCard
      hasForecast
      hasSecondaryAlias
      hasPartNumberSplit
    }
  }
`;

export const CREATE_NEW_COMPANY = gql`
  mutation CreateCompany($createCompanyInput: CreateCompanyInput!) {
    CreateCompany(createCompanyInput: $createCompanyInput) {
      id
    }
  }
`;

export const CHANGE_SALES_TEAM = gql`
  mutation ChangeSalesTeam($changeSalesTeamInput: ChangeSalesTeamInput!) {
    ChangeSalesTeam(changeSalesTeamInput: $changeSalesTeamInput)
  }
`;

export const MERGE_COMPANY_TO = gql`
  mutation MergeCompanyTo($mergeCompanyInput: MergeCompanyInput!) {
    MergeCompanyTo(mergeCompanyInput: $mergeCompanyInput)
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($updateCompanyInput: UpdateCompanyInput!) {
    UpdateCompany(updateCompanyInput: $updateCompanyInput) {
      id
      name
      website
      addresses {
        id
        addressType {
          id
          name
          description
          isCompany
          isContact
        }
        zipCode
        region {
          id
          name
        }
        territory {
          id
          name
        }
        country {
          id
          name
        }
        state {
          id
          name
        }
        city
        pobox
        address
        formattedAddress
        isAddressInvalid
      }
      mainContact {
        id
        lastName
        firstName
        title
        businessInfo {
          mainEmail {
            email
          }
          mainPhone {
            phone
          }
        }
      }
      mainAddress {
        address
        zipCode
      }
      salesTeam {
        id
        name
      }
      phones {
        id
        phone
        areaCode
        countryCode
        extension
        phoneType {
          id
        }
      }
      emails {
        id
        email
        emailType {
          id
          name
          description
        }
      }
      companyType {
        id
        name
      }
      comments
      industries {
        id
        name
      }
      productPotentials {
        id
        name
      }
      tags {
        id
        name
      }
      privateTeam {
        id
        name
      }
      parent {
        name
        id
      }
      callPattern {
        id
        name
      }
      category {
        id
        name
      }
      shipDetail
      printName
      isActive
      hasPosAccount
      hasHolydayCard
      visitFrequency
      rank {
        id
        name
      }
    }
  }
`;

export {
  DELETE_ONE_ROW_MUTATION,
  DELETE_ROWS_MUTATION,
  SETTING_UP_DEFAULT_STATUS,
  UPDATE_COMPANIES,
  CREATE_NEW_COMPANIES,
};
