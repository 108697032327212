import { gql } from '@apollo/client';
import * as GQL from './schema-types';

export * from './mutations';
export * from './queries';
export { GQL };

export const PLACEHOLDER_MUTATION = gql`
  mutation PlaceholderMutation {
    placeholderMutation {
      id
    }
  }
`;

export const PLACEHOLDER_QUERY = gql`
  query PlaceholderQuery {
    placeholderQuery {
      id
    }
  }
`;
