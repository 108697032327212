import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { StageGroupModel } from '../models/stageGroup.model';

export const stageGroupAdapter: AdapterFunc<GQL.StageGroupOutputDto, StageGroupModel> = (
  input,
) => ({
  id: input.id,
  name: input.name!,
  isDeletable: input.boolean!,
});
