import { gql } from '@apollo/client';

export const GET_ALL_PRIVATE_TEAMS = gql`
  query GetAllPrivateTeams {
    GetAllPrivateTeams {
      id
      name
      type {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_PRIVATE_TEAM = gql`
  query GetPrivateTeam($getPrivateTeamId: Int!) {
    GetPrivateTeam(id: $getPrivateTeamId) {
      id
      name
    }
  }
`;

export default GET_ALL_PRIVATE_TEAMS;
