import { notification } from 'antd';
import { useState, useEffect } from 'react';
import { prepareSubtableColums } from 'src/utils/functions/prepareSubtableColumn';
import { useLazyQuery } from '@apollo/client';
import useFilterColumn from 'src/modules/settings/modules/subTables/modules/company/tabs/callPattern/hooks/useFilterColumn';
import useLocalSearchAndSort from 'src/hooks/useLocalSearchAndSort';
import { GET_TYPE_AHEAD_RESULTS } from 'src/components/company/queries';
import { Option } from 'src/components/commons/subTableV1/types';
import ButtonReloadClearCache from 'src/components/buttonReloadClearCache/ButtonReloadClearCache';
import { cloneDeep } from 'lodash';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { successModal } from 'src/utils/modals';
import { GetAllOpportunityStagesResponse } from '../types';
import { GET_ALL_OPP_STAGE } from '../graphql/queries';
import { COLUMN } from '../const';
import useStageHeaders from './useStageHeaders';
import useStageStore from './useStageStore';

const useStage = () => {
  const StageHeaders = useStageHeaders({ fetchPolicy: 'network-only' });

  const [fetchAll] = useLazyQuery<GetAllOpportunityStagesResponse>(GET_ALL_OPP_STAGE, {
    fetchPolicy: 'network-only',
  });

  const [columns, setColumns] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any>();
  const [page, setPage] = useState<number>(0);
  const leftSideTableColumn = COLUMN;
  const { createdFilters } = useFilterColumn();
  const { localSearchStageProvider, localSortStageProvider } = useLocalSearchAndSort();
  const { reset, createOne, deleteOne, updateOne } = useStageStore();
  const [selectedSideData, setSelectedSideData] = useState<any>({});
  const [getTypeaheadData] = useLazyQuery(GET_TYPE_AHEAD_RESULTS, {
    context: { clientName: 'elastic' },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setColumns(prepareSubtableColums(StageHeaders) ?? []);
  }, [StageHeaders]);
  // Function to handle local sorting of data

  const resetData = () => {
    fetchAll({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 100,
          },
        },
      },
    }).then((res) => {
      if (res) {
        const innerResponse = res?.data?.GetAllOpportunityStages;
        setDataSource(innerResponse);
        setPage(1);
      }
    });
  };

  const handleFetchAll = (companyNames?: string) => {
    const innerSize = 100;
    const innerFrom = companyNames ? 0 : page * innerSize;
    fetchAll({
      variables: {
        criteria: {
          pagination: {
            from: innerFrom,
            size: innerSize,
          },
        },
        searchFields: {
          company_names: [companyNames || ''],
        },
      },
    }).then((res) => {
      if (res) {
        if (companyNames) {
          const innerResponse = res?.data?.GetAllOpportunityStages;

          setDataSource(innerResponse);
          setPage((prevPage) => prevPage + 1);
        } else {
          const innerResponse = res?.data?.GetAllOpportunityStages;
          const innerDataSource = cloneDeep(dataSource);
          const innerNewData = {
            total: innerResponse?.total,
            results: innerDataSource?.results
              ? innerDataSource?.results?.concat(innerResponse?.results)
              : innerResponse?.results,
          };
          setDataSource(innerNewData);
          setPage((prevPage) => prevPage + 1);
        }
      }
    });
  };

  useEffect(() => {
    handleFetchAll();
  }, []);

  const localSort = (sortData: any) => {
    if (selectedSideData && selectedSideData.data) {
      const sortedData = localSortStageProvider(selectedSideData, sortData);

      setSelectedSideData((prev: any) => {
        const newData = sortedData.data.map((item) => ({ ...item }));
        return {
          ...prev,
          data: newData,
        };
      });
    }
  };
  // Function for local search within the stage data
  const localSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(searchField, search, dataType);

    const fieldMapping: { [key: string]: string } = {
      opportunity_stages: 'name',
      opportunity_status: 'status.name',
      opportunity_stage_groups: 'stageGroup.name',
      potential: 'potential',
      opportunity_close_status: 'closeStatus.name',
    };

    const getValueFromPath = (result: any, path: string) =>
      path.split('.').reduce((acc, part) => acc && acc[part], result);

    return localSearchStageProvider(filters, selectedSideData.data).then((res) => ({
      results: res?.results,
      filter: res?.results
        ?.map((result: any) => ({
          value: getValueFromPath(result, fieldMapping[searchField]),
          label: getValueFromPath(result, fieldMapping[searchField]),
        }))
        .filter((e: any) => e.value !== null) as Option[],
    }));
  };
  // Function to provide global autocomplete suggestions based on the query
  const autocompleteGlobalProvider = async (filter: string) =>
    getTypeaheadData({
      variables: {
        column: 'name',
        filter,
        enabled: true,
      },
    }).then((res) =>
      res.data.GetCompanyUniqueValuesFromColumn.map((element: string) => ({
        label: element,
        value: element,
      })),
    );

  // Functions to manage CRUD operations and search interactions
  const onSearch = (value: string) => handleFetchAll(value);

  const addNewRecord = async (recordData: any) => {
    const createStageInput = {
      companyId: selectedSideData.id === 0 ? undefined : selectedSideData.id,
      statusId: recordData?.status?.id,
      name: recordData?.name,
      potential: recordData?.potential,
      stageGroupId: recordData?.stageGroup?.id,
      closeStatusId: recordData?.closeStatus?.id,
    };
    try {
      await createOne(createStageInput, 'createStageInput').then((res) => {
        setSelectedSideData((prevState: { data: any }) => {
          const updatedData = [...prevState.data, res?.CreateOpportunityStage];

          updatedData.sort((a, b) => a.name.localeCompare(b.name));
          return {
            ...prevState,
            data: updatedData,
          };
        });
        successModal({ onClose: () => resetData() });
      });
    } catch (error) {
      notification.error({
        message: 'Error creating record',
        key: 'error-fetching-data',
        btn: <ButtonReloadClearCache />,
      });
      reset();
    }
  };

  const editRecord = async (recordData: any) => {
    const updateStageInput = {
      id: recordData?.id,
      statusId: recordData?.status?.id,
      name: recordData?.name,
      potential: recordData?.potential,
      stageGroupId: recordData?.stageGroup?.id,
      closeStatusId: recordData?.closeStatus?.id,
    };

    try {
      updateOne({
        variables: {
          updateStageInput,
        },
      });
    } catch (error) {
      notification.error({
        message: 'Error editing record',
        key: 'error-fetching-data',
        btn: <ButtonReloadClearCache />,
      });
      reset();
    }
  };
  const deleteRecord = (recordData: any) => {
    const removeOpportunityStageId = recordData[0].id;

    try {
      deleteOne(removeOpportunityStageId).then(() => {
        setSelectedSideData((prevState: any) => {
          const filteredData = prevState.data.filter(
            (item: any) => item.id !== removeOpportunityStageId,
          );

          return {
            ...prevState,
            data: filteredData,
          };
        });
        setTimeout(() => {
          reset();
        }, 1000);
      });
    } catch (error) {
      notification.error({
        message: 'Error deleting record',
        key: 'error-fetching-data',
        btn: <ButtonReloadClearCache />,
      });
      reset();
    }
  };

  return {
    columns,
    handleFetchAll,
    leftSideTableColumn,
    dataSource,
    addNewRecord,
    editRecord,
    deleteRecord,
    autocompleteGlobalProvider,
    localSort,
    localSearch,
    onSearch,
    selectedSideData,
    setSelectedSideData,
    resetData,
  };
};

export default useStage;
