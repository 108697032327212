import { FC } from 'react';
import RightTable from 'src/components/commons/subTableV1/components/rightTable';
import { Row, Card, Col } from 'antd';
import FlexCol from 'src/components/companyDetails/styles/flexCol.style';
import StyledLefTableContainer from 'src/components/commons/subTableV1/styles/StyledLefTableContainer.style';
import SideSelectableComponent from 'src/components/commons/sideSelectableComponent';
import useFailedReason from './hooks/useFailedReason';

const FailedReason: FC = () => {
  const {
    columns,
    dataSource,
    handleFetchAll,
    // leftSideTableColumn,
    addNewRecord,
    editRecord,
    deleteRecord,
    resetData,
    onSearch,
    localSearch,
    localSort,
    setSelectedSideData,
    selectedSideData,
    autocompleteGlobalProvider,
    // newRecord,
  } = useFailedReason();
  return (
    <Row style={{ height: '75vh' }}>
      <FlexCol span={5} style={{ maxHeight: '100%', height: '100%' }}>
        <StyledLefTableContainer className='table-container' style={{ maxHeight: '100%' }}>
          <Card
            style={{ maxHeight: '100%', borderRadius: '12px', border: '1px solid #939393' }}
            className='ant-custom-card-body'
          >
            <SideSelectableComponent
              onSearch={onSearch}
              autoCompleteGlobalProvider={autocompleteGlobalProvider}
              onClear={resetData}
              total
              data={dataSource}
              handleFetchAll={handleFetchAll}
              setSelectedSideData={setSelectedSideData}
            />
          </Card>
        </StyledLefTableContainer>
      </FlexCol>
      <Col span={19} style={{ padding: '0 0.5rem' }}>
        <Card
          style={{
            maxHeight: '100%',
            height: '100%',
            borderRadius: '12px',
            border: '1px solid #939393',
          }}
        >
          <RightTable
            columns={columns}
            addNewRecord={addNewRecord}
            editRecord={editRecord}
            deleteRecord={deleteRecord}
            localSearch={localSearch}
            localSort={localSort}
            selectedSideData={selectedSideData}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default FailedReason;
