import { gql } from '@apollo/client';

export const UPDATE_EMAIL_TYPE = gql`
  mutation UpdateEmailType($updateEmailTypeInput: UpdateEmailTypeInput!) {
    UpdateEmailType(updateEmailTypeInput: $updateEmailTypeInput) {
      name
      id
    }
  }
`;

export const CREATE_EMAIL_TYPE = gql`
  mutation CreateEmailType($createEmailTypeInput: CreateEmailTypeInput!) {
    CreateEmailType(createEmailTypeInput: $createEmailTypeInput) {
      name
      id
    }
  }
`;

export const REMOVE_EMAIL_TYPE = gql`
  mutation RemoveEmailType($id: Int!) {
    RemoveEmailType(id: $id)
  }
`;
