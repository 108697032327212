import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';

/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { ApolloProvider } from '@apollo/client';
import App from './App';
import client from './services/api/index';

import './index.css';
import { AppLogin } from './modules/authentication/pages';
import RouteValidator from './auth/routeValidator';
import DeepLinkMobile from './modules/deepLinkMobile';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/login' element={<AppLogin />} />
      <Route path='/deep-link-app' element={<DeepLinkMobile />} />
      <Route path='*' element={<RouteValidator />}>
        <Route path='*' index element={<App />} />
      </Route>
    </>,
  ),
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ApolloProvider client={client}>
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Libre Franklin ,Libre Franklin Bold',
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  </ApolloProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
