import { useEffect, useState } from 'react';
import { SortModel } from 'src/components/commons/subTable/types';
import useActivityJournalsStore from 'src/hooks/stores/useActivityJournalsStore';
import { useCallTypeHeaders } from 'src/hooks/useCallTypeHeader';
import { ActivityJournalModel } from 'src/models';

const useCallTypeTable = () => {
  const { dataSource, loadPage, deleteBulk, updateOne, reset } = useActivityJournalsStore();
  const { columns, setColumns } = useCallTypeHeaders();
  const viewportHeight = window.innerHeight - 260;
  const [types, setTypes] = useState([]);

  useEffect(() => {
    const typesCopy = dataSource.map((el) => !el.placeholder && el.type);
    setTypes(typesCopy as []);
  }, [dataSource]);

  const handleSortChange = (sort: SortModel | undefined) => {
    console.log(sort);
  };
  const handleFilterChange = (filter: any) => {
    console.log(filter);
  };

  const handleDeleteRows = (rows: ActivityJournalModel[]) => {
    deleteBulk(rows.map((row) => row.id));
  };

  const handleEditRows = (rows: ActivityJournalModel[]) => {
    rows.forEach((row) => updateOne(row));
  };

  const handleClearFilter = () => {
    reset();
  };

  const autocompleteFieldSearch = async (
    field: string,
    search: string,
    dataType: string | number | string[],
    dataIndex?: string,
  ) => {
    const lowercased = search.toLowerCase();
    const filtered = dataSource
      .filter((row: any) => row.note?.toLowerCase()?.includes(lowercased))
      .splice(0, 200);
    return {
      results: filtered,
      filter: filtered.map((row) => ({
        value: row[dataIndex || field],
        label: row[dataIndex || field],
      })),
    };
  };

  return {
    viewportHeight,
    columns,
    dataSource: types,
    loadPage,
    setColumns,
    handleSortChange,
    handleFilterChange,
    handleDeleteRows,
    handleEditRows,
    autocompleteFieldSearch,
    handleClearFilter,
  };
};

export default useCallTypeTable;
