import styled from 'styled-components';

const StyledPitchForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  .ant-form-item-required {
    ::after {
      content: '*' !important;
      color: red;
    }
    ::before {
      display: none !important;
      content: none;
    }
  }
  .ant-row {
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
  }

  .ant-row .ant-col {
    width: 100%;
  }
  .ant-row .ant-form-item-label,
  .ant-row .ant-col .ant-form-item-label {
    width: fit-content;
  }

  .ant-form-item,
  .ant-form-item-control,
  .ant-form-item-control-input,
  .ant-form-item-control-input-content {
    height: 100%;
  }

  .ant-form-item-control {
    width: 100%;
  }
  .ant-input-affix-wrapper {
    border-radius: 0;
  }
  .file-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .button-container {
    .custom-file-upload {
      margin-bottom: 0.3rem;
      border: 1px solid lightgrey;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.3rem 0;
      width: 100%;
      color: grey;
      cursor: pointer;
      &:hover {
        border-color: #4096ff;
        color: #4096ff;
        transition: ease-in-out 200ms;
      }
      a {
        color: #4096ff;
      }
    }
  }

  .wysiwyn-container {
    height: 50vh;
    .quill {
      height: 100%;
      .ql-container {
        height: 90%;
      }
    }
  }
`;

export default StyledPitchForm;
