import styled from 'styled-components';

const SCompanySetupNav = styled.nav`
  margin-bottom: 20px;
  padding: 0.3rem 1rem;
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding: 0.55rem 0.7rem;
    color: #4b4b4b;
    display: block;
    white-space: nowrap;

    &.active {
      font-weight: 700;
      color: #0078d4;
      box-shadow: inset 0 -2px 0 #0078d4;
    }
  }
`;

export default SCompanySetupNav;
