import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { CompanySplitTypeModel } from 'src/models';

const companySplitTypeAdapter: AdapterFunc<GQL.CompanySplitTypesModel, CompanySplitTypeModel> = (
  input,
) => ({
  id: input.id,
  name: input.name!,
  description: input.description!,
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
});

export default companySplitTypeAdapter;
