import { useEffect, useMemo, useState } from 'react';
import { SortModel } from 'src/components/commons/subTable/types';
import { COLUMNS_EXPANDED } from 'src/modules/contacts/constants';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import useContactsStore from 'src/hooks/stores/useContactsStore';

const useReassignContact = () => {
  const { fetchAll, loadPage, dataSource, reset } = useContactsStore();

  useEffect(() => {
    fetchAll({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },
          orderBy: [
            {
              sort: 'ASC',
              field: 'firstName',
            },
          ],
        },
      },
    });
    reset();
  }, []);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState(COLUMNS_EXPANDED);

  const viewportHeight = useMemo(() => window.innerHeight - 225, []);

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    viewportHeight,
    loadPage,
    dataSource,
  };
};

export default useReassignContact;
