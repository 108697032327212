import { PlusOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import { FC, useEffect } from 'react';
import handleKeyboardDown from 'src/utils/functions/handleKeyboardEvents';
import StyledAddButton from '../styles/addButton.style';
import { useSubTableContext } from '../contexts/subtable.context';

type Props = {
  addWithModal?: boolean;
  onAddClick?: () => void;
  headerMessage?: { name: string; message: string };
};

const SubTableHeader: FC<Props> = ({ addWithModal, onAddClick, headerMessage }) => {
  const { nonAddable, setAddNewRecord, addNewRecord, setOpenModal, setRecord, setIsEditing } =
    useSubTableContext();

  const handleNewRecord = () => {
    if (addWithModal) {
      setOpenModal(true);
      setRecord(undefined);
    } else if (onAddClick) {
      onAddClick();
    } else {
      setIsEditing((prev) => !prev);
      setAddNewRecord((prev) => !prev);
    }
  };

  useEffect(() => {
    const handleKeyBoardDown = (event: KeyboardEvent) => {
      if (!nonAddable && event.key === 'Escape' && !addWithModal) {
        event.preventDefault();
        setAddNewRecord(false);
        setIsEditing(true);
        return;
      }

      if (addWithModal && handleKeyboardDown(event) && event.ctrlKey && event.key === 'n') {
        handleNewRecord();
      }

      if (!nonAddable && handleKeyboardDown(event) && !addWithModal)
        setAddNewRecord((prev) => !prev);
    };
    window.addEventListener('keydown', handleKeyBoardDown);

    return () => {
      window.addEventListener('keydown', handleKeyBoardDown);
    };
  }, [addNewRecord]);

  return (
    <Row style={{ position: 'relative', zIndex: 10 }} data-testid='subtable-header'>
      <Col style={{ display: 'flex', gap: 16, alignItems: 'center', flex: '1' }}>
        {!nonAddable && (
          <Tooltip
            title='New'
            color='#FFF'
            overlayInnerStyle={{ color: '#000' }}
            placement='bottom'
          >
            <StyledAddButton
              data-testid='subtable-add-button'
              type='primary'
              shape='circle'
              active={addNewRecord}
              icon={<PlusOutlined />}
              onClick={handleNewRecord}
            />
          </Tooltip>
        )}
        {headerMessage && (
          <Row>
            <h3 style={{ margin: 0, fontSize: '14px', fontWeight: 600, color: '#C6C4C4' }}>
              {headerMessage.name}:
            </h3>
            <h3 style={{ marginLeft: '5px', fontSize: '14px', fontWeight: 600, color: '#4B4B4B' }}>
              {headerMessage.message}
            </h3>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default SubTableHeader;
