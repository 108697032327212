import styled from 'styled-components';

export const StyledCompaniesAttendees = styled.div`
  .attendees-container,
  .company-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.3rem;
  }
  .company-container {
    margin-left: 1rem;
  }
  .attendees-container {
    flex-direction: column;
    margin-left: 2rem;
    margin-bottom: 0.5rem;
    .inputs {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      width: 100%;
    }
    .attendee-layout {
      width: 100%;
      display: flex;
      gap: 0.3rem;
      justify-content: space-between;
      align-items: center;
      .attendee {
        width: 100%;
        .job-title {
          color: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  h2 {
    font-size: 14px;
    white-space: break-spaces;
  }
  svg {
    fill: rgba(0, 0, 0, 0.3);
  }
  .ant-btn-default {
    background-color: transparent;
    border: none;
    padding: 0;
    line-height: 0;
    width: max-content;
    height: max-content;
  }
  .ant-btn-circle {
    border-radius: 50% !important;
    svg {
      fill: currentColor;
    }
  }
`;
