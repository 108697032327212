import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { ContactModel, ProductInterestModel } from 'src/models';
import { ContactGroupModel } from 'src/models/contactGroup.model';
import { optional } from './common';
// eslint-disable-next-line import/no-cycle
import contactBusinessInfoAdapter from './contactBusinessInfo.adapter';
import contactPersonalInfoAdapter from './contactPersonalInfo.adapter';

const contactAdapter: AdapterFunc<GQL.ContactOutputDto, ContactModel> = (input) => ({
  id: input.id,
  firstName: input.firstName!,
  lastName: input.lastName!,
  title: input.title!,
  notes: input.notes!,
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
  businessInfo: optional(contactBusinessInfoAdapter, input.businessInfo),
  personalInfo: optional(contactPersonalInfoAdapter, input.personalInfo),
  products: input.products as unknown as ProductInterestModel[] | undefined,
  groups: input.groups as unknown as ContactGroupModel[] | undefined,
});

export default contactAdapter;
