/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from 'react';
import useGoogleAddressAutocomplete from 'src/hooks/useGoogleAddressAutocomplete';
import useCompanyHeaders from 'src/hooks/useCompanyHeaders';
import { CreateAddressInput } from 'src/graphql/schema-types';
import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router';
import {
  GetAllRegions,
  GetAllRegionsResponse,
} from 'src/modules/settings/modules/subTables/modules/company/tabs/region/types';
import { GET_ALL_REGION_REGION } from 'src/modules/settings/modules/subTables/modules/company/tabs/region/graphql/queries';
import { useContactContext } from 'src/context/contact.context';
import { useCompanyContext } from 'src/context/company.context';

export type Props = {
  value: CreateAddressInput[] | null | undefined;
  onChange: (value: CreateAddressInput[] | null | undefined) => void;
  readOnly?: boolean;
  formName?: string;
};

const getNewAddress = () => ({} as unknown as CreateAddressInput);

export const buildSelectHandler =
  (index: number, name: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void) =>
  (e: string | number) => {
    const event = {
      target: {
        name,
        type: 'select',
        value: { id: e },
        dataset: {
          index,
        },
      },
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    onChange(event);
  };

const ADDRESS_SELECT_HEADERS = {
  addressType: 'mainAddress.addressType',
  regions: 'mainAddress.region',
  territories: 'mainAddress.territory',
  states: 'mainAddress.state',
  countries: 'mainAddress.country',
};

const useAddressesSubForm = ({ value, onChange, readOnly, formName }: Props) => {
  const location = useLocation();
  const [getAllRegion] = useLazyQuery<GetAllRegions>(GET_ALL_REGION_REGION, {
    fetchPolicy: 'network-only',
  });
  const [region, setRegion] = useState<GetAllRegionsResponse[]>();
  // eslint-disable-next-line no-param-reassign
  value = value || [];
  const { saveAddressToDelete } = useContactContext();
  const companyContext = useCompanyContext();
  const isCompany = location?.pathname?.includes('companies');
  const headers = useCompanyHeaders();

  useEffect(() => {
    getAllRegion({
      variables: {
        criteria: {
          pagination: {
            size: -1,
          },
        },
      },
    }).then((res) => {
      const asOpt = res.data?.GetAllRegions2.results.map((el) => ({
        label: el.name,
        value: el.id,
      }));
      setRegion(asOpt ?? []);
    });
  }, []);

  if (!value.length) {
    value.push(getNewAddress());
  }
  const options = useMemo(
    () =>
      Object.entries(ADDRESS_SELECT_HEADERS).reduce<any>((acc, [key, dataIndex]) => {
        const opts = headers.find((header: any) => header.dataIndex === dataIndex)?.options ?? [];
        if (key === 'regions') {
          return {
            ...acc,
            [key]: region,
          };
        }
        return {
          ...acc,
          [key]: opts.map((option: any) => ({ label: option.name, value: option.id })),
        };
      }, {}),
    [headers, region],
  );

  const [visibility, setVisibility] = useState<boolean[]>([]);
  const [addresses, setAddresses] = useState<any[]>([]);
  const { autocompleteAddress } = useGoogleAddressAutocomplete(options as any);

  useEffect(() => {
    if (value) {
      setVisibility(value.map(() => true));
      setAddresses(value);
    }
  }, [value]);

  useEffect(() => {
    if (value && value[0] && !value[0].addressType && options.addressType?.[0]?.value) {
      value[0].addressType = options.addressType?.[0]?.value;
      setAddresses([...value]);
      onChange(value);
    }
  }, [value, options.addressType]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, ref?: HTMLInputElement) => {
    const { target } = e;
    if (Number(target.dataset.index) >= 0) {
      const newAddresses = [...addresses];
      const index = Number(target.dataset.index);
      if (ref) {
        const refIndex = Number(ref.dataset.index);
        newAddresses[refIndex]['address' as keyof CreateAddressInput] = ref.value as never;
      } else {
        const { name, value: inputValue, type } = target;
        if (type === 'select') {
          newAddresses[index][name as keyof CreateAddressInput] = (inputValue as any)?.id as never;
        } else {
          newAddresses[index][name as keyof CreateAddressInput] = inputValue as never;
        }
      }
      newAddresses[0].addressType = options.addressType?.[0]?.value ?? 0;
      setAddresses(newAddresses);
      onChange(newAddresses);

      if (ref) {
        autocompleteAddress(ref, index).then((chosen) => {
          if (chosen) {
            const address = addresses[index];
            address.zipCode = chosen.zipCode!;
            address.city = chosen.city!;
            address.state = Number(chosen.state!);
            address.country = Number(chosen.country!);
            address.region = Number(chosen.region!);
            address.address = chosen.address!;
            setAddresses([...addresses]);
            onChange([...addresses]);
          }
        });
      }
    }
  };

  const handleAddAddress = (index: number) => {
    addresses.splice(index + 1, 0, getNewAddress());
    visibility.splice(index + 1, 0, true);
    setAddresses([...addresses]);
    setVisibility([...visibility]);
    onChange(addresses);
  };

  const handleRemoveAddress = (id: number, index: number, type?: string) => {
    if (id) {
      const innerIndex = addresses.findIndex((address) => address.id === id);
      addresses.splice(innerIndex, 1);
      visibility.splice(innerIndex, 1);
      setAddresses([...addresses]);
      setVisibility([...visibility]);
      onChange(addresses);
      if (isCompany) {
        companyContext.saveContactAddressToDelete([id]);
      } else {
        saveAddressToDelete([id], type);
      }
    } else {
      addresses.splice(index, 1);
      visibility.splice(index, 1);
      setAddresses([...addresses]);
      setVisibility([...visibility]);
      onChange(addresses);
    }
  };

  const handleToggleVisibility = (index: number) => {
    const newVisibility = [...visibility];
    newVisibility[index] = !newVisibility[index];
    setVisibility(newVisibility);
  };

  return {
    addresses,
    visibility,
    options,
    handleChange,
    handleAddAddress,
    handleRemoveAddress,
    handleToggleVisibility,
    readOnly,
    formName,
  };
};

export default useAddressesSubForm;
