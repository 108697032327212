import { Input } from 'antd';
import styled from 'styled-components';

const StyledSearchInput = styled(Input)`
  flex: 1;
  background: #eeeeee;
  font-size: 14px;

  input {
    background: inherit;
  }

  .ant-input-prefix {
    padding: 0 8px;
    font-size: 18px;
    color: #858585;
  }
`;

export default StyledSearchInput;
