import { Button, Col, Form, Input, Row, Select, Space, Tooltip } from 'antd';
import React from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { getDefaultValue, getValueFromSelectToInput } from 'src/utils/functions/getDefaultValues';
import useEmailsSubForm, { Props } from './useEmailsSubForm';
import InputGroup from '../commons/genericForm/components/inputGroup';

const Emails: React.FC<Props> = (props) => {
  const {
    emails,
    options,
    handleAddEmail,
    handleEmailChange,
    handleRemoveEmail,
    handleEmailTypeChange,
    readOnly,
    formName,
  } = useEmailsSubForm(props);

  return (
    <Row gutter={16}>
      {emails &&
        emails.map((email, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col span={24} key={`${index}-`}>
            <Row gutter={16}>
              {index > 0 && (
                <>
                  <Col span={6}>
                    <Form.Item label='Email Type'>
                      {readOnly ? (
                        <Input
                          readOnly
                          value={getValueFromSelectToInput(email.emailType, options as [])}
                          placeholder='Please select'
                        />
                      ) : (
                        <Select
                          placeholder='Select email type'
                          value={getDefaultValue(email.emailType, options)}
                          options={options}
                          onChange={(val) => handleEmailTypeChange(index, val)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col
                    span={18}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-end',
                      paddingBottom: '12px',
                    }}
                  >
                    <Space wrap>
                      <Tooltip title='Remove Phone'>
                        <Button
                          shape='circle'
                          icon={<DeleteOutlined />}
                          onClick={
                            readOnly
                              ? (e) => {
                                  e.preventDefault();
                                  return false;
                                }
                              : () => handleRemoveEmail(email.id!, index, formName)
                          }
                        />
                      </Tooltip>{' '}
                    </Space>
                  </Col>
                </>
              )}
              <Col span={24}>
                <InputGroup>
                  <Input
                    name='email'
                    placeholder='Type here...'
                    onChange={handleEmailChange}
                    value={email.email}
                    data-index={index}
                    readOnly={readOnly}
                    disabled={index !== 0 && !email.emailType}
                  />
                  <Tooltip title='Add new Email' color='#CCCCCC'>
                    <Button
                      type='primary'
                      shape='circle'
                      style={{ borderRadius: 2 }}
                      onClick={
                        readOnly
                          ? (e) => {
                              e.preventDefault();
                              return false;
                            }
                          : () => handleAddEmail(index)
                      }
                      disabled={index !== 0 && !email.emailType}
                    >
                      <PlusOutlined />
                    </Button>
                  </Tooltip>
                </InputGroup>
              </Col>
            </Row>
          </Col>
        ))}
    </Row>
  );
};

export default Emails;
