import { Col, Form, Row } from 'antd';
import { CustomModal } from 'src/components/commons/customModal';
import { FC, ReactNode, CSSProperties } from 'react';

type ModalContentProps = {
  leftContent: ReactNode;
  rightContent: ReactNode;
  rowStyle?: CSSProperties;
};

const ModalContent: FC<ModalContentProps> = ({ leftContent, rightContent, rowStyle }) => (
  <Row className='rf-content rf-align-cards' style={rowStyle}>
    <Col span={7} style={{ height: 'calc(100vh - 300px)', padding: '20px', overflow: 'auto' }}>
      <Form layout='vertical'>{leftContent}</Form>
    </Col>
    <Col span={17} style={{ paddingLeft: '20px' }}>
      {rightContent}
    </Col>
  </Row>
);

type ProviderPropsWithChildren<T = unknown> = T & { children?: ReactNode; onOkAction?: () => void };

export type Props<T = unknown> = {
  onClose: () => void;
  modalTitle: string;
  testId: string;
  leftContent: ReactNode;
  rightContent: ReactNode;
  ProviderComponent?: React.ComponentType<ProviderPropsWithChildren<T>>;
  onOkAction?: () => void;
  rowStyle?: CSSProperties;
  modalStatus?: boolean;
  closable?: boolean;
  footer?: ReactNode;
  centered?: boolean;
  cancelText?: string;
  okText?: string;
  width?: number | string;
  style?: object;
  customClass?: string;
  invalid?: boolean;
};
// eslint-disable-next-line react/jsx-no-useless-fragment
const DefaultProvider: React.FC<ProviderPropsWithChildren> = ({ children }) => <>{children}</>;

const ModularModal: FC<Props> = ({
  onClose,
  modalTitle,
  leftContent,
  rightContent,
  ProviderComponent = DefaultProvider, // Default provider does nothing
  onOkAction = () => {},
  rowStyle,
  modalStatus = true,
  closable = true,
  footer,
  centered = true,
  cancelText,
  okText,
  width = 1300,
  style,
  customClass = 'custom-modal',
  invalid,
  testId = 'custom-modal',
}) => (
  <CustomModal
    modalStatus={modalStatus}
    modalTitle={modalTitle}
    testId={testId}
    modalBody={
      <ProviderComponent>
        <ModalContent leftContent={leftContent} rightContent={rightContent} rowStyle={rowStyle} />
      </ProviderComponent>
    }
    onOkAction={onOkAction}
    onCancelAction={onClose}
    footer={footer}
    closable={closable}
    centered={centered}
    cancelText={cancelText}
    okText={okText}
    width={width}
    style={style}
    customClass={customClass}
    invalid={invalid}
    bodyPadding='0 20px 0 0'
  />
);

export default ModularModal;
