import React, { useRef, useContext } from 'react';
import { Space, Typography, Table, Row, Tag } from 'antd';
import { CompaniesContext } from 'src/context/companies.context';
import CustomButton from 'src/components/commons/customButton';
import type { ColumnsType } from 'antd/es/table';
import { EditingConfirmationDataType } from 'src/types/companiesTypes';
import StyleDiv from './editingConfirmation.styles';
import { DataType } from './utils';

const EditingConfirmation = () => {
  const { editingCompaniesResponse, editingReferencesData, cancelActiveMetadataDropdownHandler } =
    useContext(CompaniesContext);
  const columns: ColumnsType<DataType> = [
    {
      title: 'Company Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      fixed: 'left',
    },
    {
      title: editingReferencesData.current.optionChanged,
      children: [
        {
          title: 'From',
          dataIndex: 'from',
          key: 'from',
          width: 100,
          fixed: 'left',
          render: (_, renderData) => (
            <Typography.Text type='secondary'>{renderData.from}</Typography.Text>
          ),
        },
        {
          title: 'To',
          dataIndex: 'to',
          key: 'to',
          width: 100,
          fixed: 'left',
          render: (_, renderData) => (
            <Tag color={renderData.processed ? 'green' : 'volcano'}>{renderData.to}</Tag>
          ),
        },
      ],
    },
    Table.EXPAND_COLUMN,
  ];

  const innerData = useRef<EditingConfirmationDataType[]>([]);
  const errorAmount = useRef(0);
  const processed = useRef(0);

  if (editingReferencesData.current.options && editingCompaniesResponse.data) {
    innerData.current = editingReferencesData.current.options.map((element, index: number) => {
      const innerProcessed = editingCompaniesResponse?.data?.UpdateCompaniesInline?.results?.find(
        (item: { id: number; status: 'UPDATED' | 'ERROR'; error?: string }) =>
          item.id === element.id,
      );
      if (innerProcessed?.error) {
        errorAmount.current += 1;
      }
      return {
        key: index,
        name: element.name as unknown as string,
        from: (element.from as unknown as string) || 'empty',
        to: element.to as unknown as string,
        processed: !innerProcessed?.error,
        error: innerProcessed?.error || null,
      };
    });
    if (editingCompaniesResponse.data?.UpdateCompaniesInline?.total) {
      processed.current =
        editingCompaniesResponse.data.UpdateCompaniesInline.total - errorAmount.current;
    }
  }

  const errorRender = (record: DataType) => <p style={{ color: 'red' }}>{record.error}</p>;

  return (
    <Space>
      <StyleDiv>
        <table style={{ paddingTop: '70px' }}>
          <tbody>
            <tr>
              <td>
                <Typography.Text strong>Total Selected:</Typography.Text>
              </td>
              <td style={{ paddingLeft: '20px' }}>
                <Typography.Text strong data-testid='total-selected-amount'>
                  {editingReferencesData.current.options?.length}
                </Typography.Text>
              </td>
            </tr>
            <tr>
              <td style={{ paddingTop: '10px' }}>
                <Typography.Text strong>Processed:</Typography.Text>
              </td>
              <td style={{ paddingLeft: '20px', paddingTop: '10px' }}>
                <Typography.Text strong data-testid='total-processed-amount'>
                  {processed.current || 0}
                </Typography.Text>
              </td>
            </tr>
            <tr>
              <td style={{ paddingTop: '10px' }}>
                <Typography.Text strong>Skipped:</Typography.Text>
              </td>
              <td style={{ paddingLeft: '20px', paddingTop: '10px' }}>
                <Typography.Text strong data-testid='total-skipped-amount'>
                  {errorAmount.current}
                </Typography.Text>
              </td>
            </tr>
          </tbody>
        </table>
        <Space direction='vertical' style={{ marginTop: 'auto' }}>
          <Typography.Text>Not the changes you wanted?</Typography.Text>
          <Row justify='space-between'>
            <CustomButton
              type='text'
              title='Close'
              noborder={false}
              dataTestId='close-button'
              onClick={cancelActiveMetadataDropdownHandler}
              ghost
              style={{
                fontSize: '17px',
                borderRadius: 0,
              }}
            />
            <CustomButton
              type='primary'
              title='Undo'
              dataTestId='undo-button'
              ghost
              style={{
                fontSize: '17px',
                borderRadius: 0,
              }}
              disabled
            />
          </Row>
        </Space>
      </StyleDiv>
      <Space>
        <Table
          style={{ maxWidth: '500px' }}
          columns={columns}
          expandable={{
            expandedRowRender: errorRender,
            rowExpandable: (record) => !record.processed,
          }}
          dataSource={innerData.current}
          bordered
          size='middle'
          scroll={{ y: 200 }}
        />
      </Space>
    </Space>
  );
};

export default EditingConfirmation;
