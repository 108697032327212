import { useEffect } from 'react';

const DeepLinkMobile = () => {
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const confirmationCode = urlParams.get('confirmation_code');
    const userName = urlParams.get('user_name');
    const scriptElm = document.createElement('script');
    const inlineCode = document.createTextNode(
      `window.location.replace("repfabric://reset-password?confirmation_code=${confirmationCode}&user_name=${userName}");`,
    );
    scriptElm.appendChild(inlineCode);
    document.body.appendChild(scriptElm);

    return () => {
      scriptElm.removeChild(scriptElm);
    };
  }, []);

  return <div style={{ display: 'none' }}>Deep Link</div>;
};

export default DeepLinkMobile;
