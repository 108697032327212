import React from 'react';
import { Typography, Row, Col } from 'antd';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ListTypeOptionType } from 'src/types/companiesTypes';
import CustomSelect from '../customSelect';
import CustomButton from '../customButton';

interface EditingCompanyAttrRendererType {
  cancelAction: () => void;
  firstSelectOptions: ListTypeOptionType[];
  firstSelectOptionsChange: (e: React.FormEvent<HTMLInputElement>) => void;
  firstSelection: string;
  secondSelectOptions: ListTypeOptionType[] | undefined;
  secondSelectOptionsChange: (e: React.FormEvent<HTMLInputElement>) => void;
  sendNewPayloadData: () => void;
  allowUpdate: boolean;
}

const EditingCompanyAttrRenderer: React.FC<EditingCompanyAttrRendererType> = ({
  cancelAction,
  firstSelectOptions,
  firstSelectOptionsChange,
  firstSelection,
  secondSelectOptions,
  secondSelectOptionsChange,
  sendNewPayloadData,
  allowUpdate,
}) => {
  const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

  return (
    <>
      <table style={{ margin: '10px auto 94px auto', height: '120px' }}>
        <tbody>
          <tr>
            <td style={{ textAlign: 'end' }}>
              <Typography.Text>Change</Typography.Text>
            </td>
            <td style={{ paddingLeft: '32px' }}>
              <CustomSelect
                handleChange={firstSelectOptionsChange}
                options={firstSelectOptions as []}
                defaultValue='Select'
                style={{ width: '326px' }}
                customClassName='no-border-radius-select'
                dataTestId='first-EditingCompanyAttrRenderer-select'
              />
            </td>
          </tr>
          <tr
            style={{ visibility: firstSelection.length === 0 ? 'hidden' : undefined }}
            data-testid='tr-secondStep-container'
          >
            <td style={{ textAlign: 'end' }}>
              <Typography.Text data-testid='second-select-element-label'>
                {capitalizeFirstLetter(firstSelection.toLocaleLowerCase())}
              </Typography.Text>
            </td>
            <td style={{ paddingLeft: '32px' }}>
              <CustomSelect
                handleChange={secondSelectOptionsChange}
                options={secondSelectOptions as []}
                defaultValue='Select'
                style={{ width: '326px' }}
                customClassName='no-border-radius-select'
                dataTestId='second-EditingCompanyAttrRenderer-select'
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Row justify='center'>
        <Col style={{ paddingRight: '14px' }}>
          <CustomButton
            title='Cancel'
            noborder={false}
            onClick={cancelAction}
            dataTestId='cancel-button'
            style={{
              borderRadius: 0,
              border: '1px solid #0078D4',
            }}
          />
        </Col>
        <Col style={{ paddingLeft: '14px' }}>
          <CustomButton
            title='Update'
            onClick={sendNewPayloadData}
            disabled={!allowUpdate}
            dataTestId='update-button'
            type='primary'
            style={{
              borderRadius: 0,
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default EditingCompanyAttrRenderer;
