import { FC } from 'react';
import CustomButton from 'src/components/commons/customButton';
import { Input } from 'antd';
import CustomSelect from 'src/components/commons/customSelect';
import SBasicInformation from './styles/basicInformation.styles';
import { IBasicInformation } from './interfaces/basicInformation.interface';

const BasicInformation: FC<IBasicInformation> = ({ buttonAction }) => (
  <SBasicInformation>
    <div className='main-input-container'>
      <div className='loginID input-layout' data-testid='loginID'>
        <p>
          Login ID<span>*</span>
        </p>
        <Input disabled data-testid='loginID-input' />
      </div>

      <div className='input-layout' data-testid='username'>
        <p>
          Username<span>*</span>
        </p>
        <Input data-testid='username-input' autoFocus />
      </div>

      <div className='input-layout' data-testid='category'>
        <p>
          Category<span>*</span>
        </p>
        <CustomSelect
          dataTestId='category-input'
          options={[...Array(5)].map(() => ({ label: 'Label', value: 'value' }))}
          defaultValue='Owner'
        />
      </div>
      <CustomButton
        onClick={buttonAction}
        title='Change User Password'
        dataTestId='change-password-button'
      />
    </div>

    <div className='main-input-container'>
      <div className='input-layout' data-testid='status'>
        <p>Status</p>
        <div className='checkbox-layout'>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor='status'>Enable</label>
          <input type='checkbox' id='status' data-testid='status-input' />
        </div>
      </div>

      <div className='input-layout' data-testid='admin'>
        <p>Admin</p>
        <div className='checkbox-layout'>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor='admin'>Enable</label>
          <input type='checkbox' id='admin' data-testid='admin-input' />
        </div>
      </div>

      <div className='input-layout' data-testid='personalEmail'>
        <p>Personal Email</p>
        <Input data-testid='personal-email-input' />
      </div>
    </div>
  </SBasicInformation>
);
export default BasicInformation;
