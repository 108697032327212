import { SubTableColumn } from 'src/components/commons/subTable/types';

const COLUMNS: SubTableColumn[] = [
  {
    title: 'NAME',
    dataIndex: 'name',
    editable: true,
    unique: true,
    required: true,
  },
];

export default COLUMNS;
