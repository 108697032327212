import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { CompanyModel } from '../models/company.model';
import { list, optional } from './common';
import addressAdapter from './address.adapter';
import callPatternAdapter from './callPattern.adapter';
import companyCategoryAdapter from './companyCategory.adapter';
import companyClassAdapter from './companyClass.adapter';
import companyTypeAliasAdapter from './companyTypeAlias.adapter';
import divisionAdapter from './division.adapter';
import emailAdapter from './email.adapter';
import companyIndustryAdapter from './companyIndustry.adapter';
import locationAdapter from './location.adapter';
// eslint-disable-next-line import/no-cycle
import contactAdapter from './contact.adapter';
import phoneAdapter from './phone.adapter';
import privateTeamAdapter from './privateTeam.adapter';
import companyProductPotentialAdapter from './companyProductPotential.adapter';
import companyRankAdapter from './companyRank.adapter';
import salesTeamAdapter from './salesTeam.adapter';
import companyTagAdapter from './companyTag.adapter';

const companyAdapter: AdapterFunc<GQL.CompanyModel, CompanyModel> = (input) => ({
  id: input.id,
  name: input.name!,
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
  addresses: list(addressAdapter, input.addresses),
  callPattern: optional(callPatternAdapter, input.callPattern),
  category: optional(companyCategoryAdapter, input.category),
  comments: input.comments!,
  companyClass: optional(companyClassAdapter, input.companyClass),
  companyType: optional(companyTypeAliasAdapter, input.companyType),
  datasource: input.datasource!,
  divisions: list(divisionAdapter, input.divisions),
  emails: list(emailAdapter, input.emails),
  hasDwin: input.hasDwin!,
  hasForecast: input.hasForecast!,
  hasHolydayCard: input.hasHolydayCard!,
  hasPartNumberSplit: input.hasPartNumberSplit!,
  hasPosAccount: input.hasPosAccount!,
  hasQuot: input.hasQuot!,
  hasRegi: input.hasRegi!,
  hasSamp: input.hasSamp!,
  hasSecondaryAlias: input.hasSecondaryAlias!,
  impBatch: input.impBatch!,
  industries: list(companyIndustryAdapter, input.industries),
  isActive: input.isActive!,
  location: optional(locationAdapter, input.location),
  mainAddress: optional(addressAdapter, input.mainAddress),
  mainContact: optional(contactAdapter, input.mainContact),
  mainPhone: optional(phoneAdapter, input.mainPhone),
  parent: optional(companyAdapter, input.parent),
  phones: list(phoneAdapter, input.phones),
  poFooter: input.poFooter!,
  printName: input.printName!,
  privateTeam: optional(privateTeamAdapter, input.privateTeam),
  productPotentials: list(companyProductPotentialAdapter, input.productPotentials),
  quoteFooter: input.quoteFooter!,
  rank: optional(companyRankAdapter, input.rank),
  refNumber: input.refNumber!,
  salesTeam: optional(salesTeamAdapter, input.salesTeam),
  shipDetail: input.shipDetail!,
  shortName: input.shortName!,
  tags: list(companyTagAdapter, input.tags),
  tenantDivision: optional(divisionAdapter, input.tenantDivision),
  visitFrequency: input.visitFrequency!,
  website: input.website!,
});

export default companyAdapter;
