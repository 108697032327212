import styled from 'styled-components';

const StyledFormItem = styled.div`
  & > .ant-space-compact {
    align-items: flex-end;

    & > .ant-form-item {
      flex: 1;
    }

    & > .ant-btn-compact-item {
      margin-bottom: 12px;
    }
  }

  &.vertical .ant-form-item-row {
    gap: 16px;

    .ant-form-item-label {
      padding-bottom: 0;
      display: inline-flex;
      align-items: center;
    }

    .ant-form-item-control {
      flex: 1;
    }
  }
`;

export default StyledFormItem;
