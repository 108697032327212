import { gql } from '@apollo/client';

export const CREATE_CALL_PATTERN = gql`
  mutation CreateCallPattern($createCompanyCallPatternInput: CreateCallPatternInput!) {
    CreateCallPattern(createCompanyCallPatternInput: $createCompanyCallPatternInput) {
      id
      name
      description
    }
  }
`;

export const UPDATE_CALL_PATTERN = gql`
  mutation UpdateCallPattern($updateCallPatternInput: UpdateCallPatternInput!) {
    UpdateCallPattern(updateCallPatternInput: $updateCallPatternInput) {
      name
    }
  }
`;

export const DELETE_CALL_PATTERN = gql`
  mutation RemoveCallPattern($id: Int!) {
    RemoveCallPattern(id: $id)
  }
`;
