import { gql } from '@apollo/client';

const GET_ALL_CALL_PATTERN = gql`
  query GetAllCallPattern2(
    $Criteria: CallPatternCriteriaInput!
    $SearchFields: FindCallPatternInput
  ) {
    GetAllCallPattern2(Criteria: $Criteria, SearchFields: $SearchFields) {
      results {
        id
        name
        description
      }
      total
    }
  }
`;

export default GET_ALL_CALL_PATTERN;
