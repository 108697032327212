import styled from 'styled-components';

// transparent gray backdrop with fixed position
export const CenteredPopoverContainer = styled.div`
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CenteredPopoverContent = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: white;
  border-radius: 2px;
  background-color: #fff1f0;
  border: 1px solid #ffccc7;
  padding: 16px;
`;

export const CenteredPopoverHeader = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: #e44f3b;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const CenteredPopoverBody = styled.div`
  padding-left: 24px;
  color: #4b4b4b;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

export const OverlayStyled = styled.div`
  background-color: red !important;
  top: 0 !important;
`;
