import styled from 'styled-components';

const StyledSubTable = styled.div<{ $hideBorders?: boolean; $clickable?: boolean }>`
  height: 100%;

  .ant-btn.edit-button,
  .ant-btn.filter-button {
    color: #ff4d4f !important;
    background-color: transparent !important;

    &:hover,
    &:active {
      color: #ff4d4f !important;
      background-color: transparent !important;
    }

    &.active {
      color: #ff7875 !important;
      background-color: #fff2f0 !important;
    }
  }

  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    padding-top: 16px;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    border-bottom: ${(props) => (props.$hideBorders ? 'none' : '1px solid #f0f0f0')};
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    &::before {
      display: none;
    }
  }

  .ant-table-expanded-row {
    height: max-content !important;
    min-height: auto !important;
  }

  .ant-table-row {
    cursor: pointer;

    .ant-table-cell {
      padding: 0 10px !important;
    }
  }

  .ant-table-body {
    height: 100vh;
  }

  .ant-table-row-selected > .ant-table-cell {
    background-color: ${(props) => (props.$clickable ? ' #CCE4F6 !important' : '')};
    color: ${(props) => (props.$clickable ? '#0078D4 !important' : '')};

    div > button {
      color: ${(props) => (props.$clickable ? '#fff !important' : '')};
    }
  }
`;

export default StyledSubTable;
