import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { FC, useMemo } from 'react';
import { Tooltip } from 'antd';
import StyledSortButton from '../styles/sortButton.style';
import { SortModel, SubTableColumn } from '../types';
import { useSubTableContext } from '../contexts/subtable.context';

type Props = {
  column: SubTableColumn;
};

const getSortKey = (column: SubTableColumn) => {
  if (!column.sortKey) {
    if (column.filterKey) {
      return column.filterKey;
    }

    return String(column.orderField);
  }

  return column.sortKey;
};

const SortButton: FC<Props> = ({ column }) => {
  const { sortModel, setSortModel, onSortChange } = useSubTableContext();
  const sort = useMemo(() => sortModel?.sort, [sortModel]);
  const sortBy = useMemo(() => sortModel?.sortBy, [sortModel]);

  const sortKey = getSortKey(column);

  const icon =
    sort === 'ASC' || sortBy !== sortKey ? <SortAscendingOutlined /> : <SortDescendingOutlined />;
  const className = sortBy === sortKey ? 'active' : undefined;

  const onClick = () => {
    let newSortModel: SortModel | undefined;

    if (sort === undefined || sortKey !== sortBy) {
      newSortModel = {
        sort: 'ASC',
        sortBy: sortKey,
      };
    } else if (sort === 'ASC') {
      newSortModel = {
        sort: 'DESC',
        sortBy: sortKey,
      };
    } else {
      newSortModel = undefined;
    }

    setSortModel(newSortModel);

    if (onSortChange) {
      onSortChange(newSortModel);
    }
  };

  const handleTooltipMessage = (sortText: string | undefined) => {
    if (!sortText) {
      return 'Sort A - Z';
    }

    if (sortText === 'ASC') {
      return 'Sort Z - A';
    }
    return 'Default';
  };

  return (
    <Tooltip
      title={handleTooltipMessage(sort)}
      color='white'
      overlayInnerStyle={{
        color: 'black',
      }}
    >
      <StyledSortButton
        style={{ backgroundColor: '#fff' }}
        size='small'
        type='text'
        onClick={onClick}
        icon={icon}
        className={className}
        title=''
      />
    </Tooltip>
  );
};

export default SortButton;
