import { Form, Input, InputRef } from 'antd';
import { FC, useEffect, useRef } from 'react';
import StyledPitchForm from 'src/components/companyDetails/components/pitches/styles/pitchForm.styles';

const AddPrivateTeam: FC<Record<any, any>> = (props) => {
  const { validateNameUnique } = props;
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);
  return (
    <StyledPitchForm>
      <Form.Item
        style={{
          margin: 0,
        }}
        name='name'
        label='Private Name'
        className='name'
        rules={[
          {
            required: true,
            message: 'This is a required field',
          },
          {
            validator: validateNameUnique,
          },
        ]}
        hasFeedback
      >
        <Input
          ref={inputRef}
          autoComplete='off'
          className='input-new-record-js'
          placeholder='Type here...'
        />
      </Form.Item>
    </StyledPitchForm>
  );
};

export default AddPrivateTeam;
