import { gql } from '@apollo/client';

export const CREATE_STAGE_GROUP = gql`
  mutation CreateStageGroup($createStageGroupInput: CreateStageGroupInput!) {
    CreateStageGroup(createStageGroupInput: $createStageGroupInput) {
      boolean
      id
      name
    }
  }
`;

export const UPDATE_STAGE_GROUP = gql`
  mutation UpdateStageGroup($updateStageGroupInput: UpdateStageGroupInput!) {
    UpdateStageGroup(updateStageGroupInput: $updateStageGroupInput) {
      id
      boolean
      name
    }
  }
`;

export const REMOVE_STAGE_GROUP = gql`
  mutation RemoveStageGroup($id: Int!) {
    RemoveStageGroup(id: $id)
  }
`;
