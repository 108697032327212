import { useState } from 'react';
import { SubTableColumn } from 'src/components/commons/subTable/types';

export const COLUMNS: SubTableColumn[] = [
  {
    title: 'LABEL',
    headerName: 'Label',
    dataIndex: 'label',
    editable: false,
    dataType: 'string',
    filterable: true,
    hidden: false,
    dataStructure: 'string',
    options: [],
    required: true,
    searchField: 'label',
  },
  {
    title: 'REQUIRED',
    headerName: 'Required',
    dataIndex: 'required',
    editable: true,
    dataType: 'checkbox',
    filterable: false,
    hidden: false,
    dataStructure: 'boolean',
    options: [],
    required: true,
    searchField: 'required',
    width: 150,
  },
  {
    title: 'DISABLED',
    headerName: 'Disabled',
    dataIndex: 'disabled',
    editable: true,
    dataType: 'checkbox',
    filterable: false,
    hidden: false,
    dataStructure: 'boolean',
    options: [],
    required: true,
    searchField: 'disabled',
    width: 150,
  },
];

export const useFielSettingsHeaders = () => {
  const [columns, setColumns] = useState(COLUMNS);

  return {
    columns,
    setColumns,
  };
};
