import { FC, createContext, useContext, useState } from 'react';
import { SubTableTypeContextProps } from '../types';

const SubTableTypeContext = createContext<SubTableTypeContextProps>({} as SubTableTypeContextProps);

const SubTableTypeProvider: FC<SubTableTypeContextProps> = ({ children }) => {
  const [predefinedTypeChanged, setPredefinedTypeChanges] = useState(false);
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    predefinedTypeChanged,
    setPredefinedTypeChanges,
  };

  return <SubTableTypeContext.Provider value={value}>{children}</SubTableTypeContext.Provider>;
};

export const useSubTableTypeContext = () => useContext(SubTableTypeContext);

export default SubTableTypeProvider;
