import { cloneDeep, reject, isNil } from 'lodash';
import { SubTableColumn } from 'src/components/commons/subTableV1/types';

const prepareColumns = (headerList: SubTableColumn[]) => {
  const innerHeaderList = cloneDeep(headerList);
  const columns = innerHeaderList.map((element: any, index) => {
    if (element.dataIndex === 'id' || !element.gridVisible) {
      return null;
    }
    const innerOptions = element?.options?.map((option: any) => ({
      VALUE: option.id,
      LABEL: option.name,
    }));
    const innerColumn = {
      relabelIndex: element?.relabelIndex,
      title: element?.headerName,
      headerName: element?.headerName,
      key: element?.key ?? '',
      dataIndex: element?.dataIndex,
      searchField: element?.searchField,
      orderField: element?.orderField,
      hasLink: element?.hasLink,
      dataType: element?.dataType,
      gridVisible: element.dataIndex !== 'id',
      required: element?.required,
      options: innerOptions ?? [],
      criteriaFilterField: element?.criteriaFilterField,
      minWidth: element?.minWidth || 270,
      width: element?.width || 270,
      editable: element?.editable,
      selected: true,
      pinned: false,
      originalIndex: index,
      isFilterable: element?.isFilterable || false,
      filterOptions: element?.filterOptions,
      render: element.render ?? null,
    };
    return innerColumn;
  });
  return reject(columns, isNil);
};

export const prepareColumnsForFilterOptions = (headerList: SubTableColumn[]) => {
  const innerHeaderList = cloneDeep(headerList);
  const columns = innerHeaderList.map((element: any, index) => {
    if (element.dataIndex === 'id') {
      return null;
    }
    const innerOptions = element?.options?.map((option: any) => ({
      VALUE: option.id,
      LABEL: option.name,
    }));
    const innerColumn = {
      relabelIndex: element?.relabelIndex,
      title: element?.headerName,
      headerName: element?.headerName,
      key: element?.key ?? '',
      dataIndex: element?.dataIndex,
      searchField: element?.searchField,
      orderField: element?.orderField,
      hasLink: element?.hasLink,
      dataType: element?.dataType,
      gridVisible: element.dataIndex !== 'id',
      required: element?.required,
      options: innerOptions ?? [],
      criteriaFilterField: element?.criteriaFilterField,
      minWidth: element?.minWidth || 270,
      width: element?.width || 270,
      editable: element?.editable,
      selected: true,
      pinned: false,
      originalIndex: index,
      isFilterable: element?.isFilterable || false,
      filterOptions: element?.filterOptions,
      multiSearchField: element?.multiSearchField,
    };
    return innerColumn;
  });
  return reject(columns, isNil);
};

/// create it just for stage subtale
export const prepareSubtableColums = (headerList: SubTableColumn[]) => {
  const innerHeaderList = cloneDeep(headerList);

  const columns = innerHeaderList.map((element: any) => {
    if (element.dataIndex === 'id' || !element.gridVisible) {
      return null;
    }
    const innerOptions = element?.options?.map((option: any) => ({
      VALUE: option.id,
      LABEL: option.name,
    }));
    const innerColumn = {
      relabelIndex: element?.relabelIndex,
      title: element?.headerName,
      headerName: element?.headerName,
      key: element?.key ?? '',
      dataIndex: element?.dataIndex,
      searchField: element?.searchField,
      orderField: element?.orderField,
      hasLink: element?.hasLink,
      dataType: element.dataIndex === 'potential' ? 'number' : element?.dataType,
      gridVisible: element.dataIndex !== 'id',
      required: element?.required,
      options: innerOptions ?? [],
      criteriaFilterField: element?.criteriaFilterField,
      minWidth: element?.minWidth || null,
      width: element?.width || null,
      editable: element?.editable,
      selected: true,
    };
    return innerColumn;
  });

  return reject(columns, isNil);
};

export default prepareColumns;
