import { useMutation } from '@apollo/client';
import _ from 'lodash';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SubTableColumn } from 'src/components/commons/subTable/types';
import {
  REGISTER_SALES_TEAM_MEMBER,
  REMOVE_SALES_TEAM_MEMBER,
} from 'src/graphql/mutations/sales-team';
import useSalesTeamsStore from 'src/hooks/stores/useSalesTeamsStore';
import { UsersModel } from 'src/models/users.model';
import { notification } from 'antd';

type ContextProps = {
  data: any[];
  columns: SubTableColumn[];
  userColumns?: SubTableColumn[] | [];
  selectedRecord?: Record<any, any>;
  columnsMembers: SubTableColumn[] | [];
  allUsersData?: UsersModel[];
  setAllUsersData?: React.Dispatch<React.SetStateAction<UsersModel[] | undefined>>;
  handleUpdateRows?: (rows: any[]) => void;
  setSelectedRecord?: (arg: Record<any, any>) => void;
} & PropsWithChildren;

type SettingTableContextProps = {
  currentRecordTitle: string;
  handleRecordData: (record: any) => any;
  columns: SubTableColumn[] | [];
  userColumns: SubTableColumn[] | [];
  columnsMembers: SubTableColumn[] | [];
  hasPrimaryContact?: boolean;
  primaryContact?: number;
  handlePrimaryContact: (id: number) => void;
  selectedRows:
    | {
        id: number;
        name: string;
      }[]
    | [];
  setSelectedRows: React.Dispatch<
    React.SetStateAction<
      | {
          id: number;
          name: string;
        }[]
      | []
    >
  >;
  infoIconTextUser?: string;
  infoIconTextMembers?: string;
  usersMembersTitle?: Record<'users' | 'members', string>;
  allUsersData?: UsersModel[];
  setAllUsersData?: React.Dispatch<React.SetStateAction<UsersModel[] | undefined>>;
  data: any[];
  selectedRecord: any;
  setSelectedRecord?: (arg0: Record<any, any>) => void;
};

const initialState: SettingTableContextProps = {} as SettingTableContextProps;

const settingTableContext = createContext(initialState);

const SettingTableContextProvider: FC<ContextProps> = ({
  children,
  data,
  columns: cols,
  userColumns,
  selectedRecord,
  columnsMembers,
  allUsersData,
  setAllUsersData,
  handleUpdateRows,
  setSelectedRecord,
  ...rest
}) => {
  const { updateOne } = useSalesTeamsStore();
  const [currentRecordTitle, setCurrentRecordTitle] = useState<string>('');
  const [columns, setColumns] = useState<SubTableColumn[] | []>([]);
  const [primaryContact, setPrimaryContact] = useState<number | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<{ id: number; name: number }[] | []>([]);
  const [registerMember] = useMutation(REGISTER_SALES_TEAM_MEMBER);
  const [removeSalesTeamMember] = useMutation(REMOVE_SALES_TEAM_MEMBER, {
    onError: (error) => {
      notification.error({
        message: error.message,
        key: 'remove-sales-error',
      });
    },
  });

  useEffect(() => {
    setColumns(cols);
  }, [cols, data]);

  useEffect(() => {
    setSelectedRows(selectedRecord?.teamMembers ?? []);
    setPrimaryContact(selectedRecord?.leaderSalesTeamId);
  }, [selectedRecord]);

  useEffect(() => {
    const newSelectedRows = _.difference(selectedRows, selectedRecord?.teamMembers);
    const newDeselectedRows = _.difference(selectedRecord?.teamMembers, selectedRows);
    if (newDeselectedRows.length) {
      removeSalesTeamMember({
        variables: {
          tenantId: 3,
          users: newDeselectedRows.map((element) => element.id),
          salesTeamId: selectedRecord?.id,
        },
        onCompleted: () => {
          const updatedTeamMembers = _.differenceBy(
            selectedRecord?.teamMembers,
            newDeselectedRows,
            'id',
          );
          setSelectedRecord?.({
            ...selectedRecord,
            teamMembers: updatedTeamMembers,
          });
        },
      });
    }
    if (newSelectedRows.length) {
      registerMember({
        variables: {
          createSalesTeamInput: {
            tenantId: 3,
            users: newSelectedRows.map((e) => e.id),
            salesTeamId: selectedRecord?.id,
          },
        },
      }).then(() => {
        if (selectedRecord) {
          setSelectedRecord?.({
            ...selectedRecord,
            teamMembers: [...selectedRecord.teamMembers, ...newSelectedRows],
          });
        }
      });
    }
  }, [selectedRows]);

  const handleRecordData = (record: any) => {
    setCurrentRecordTitle(record?.name as string);
  };

  const handlePrimaryContact = (id: number) => {
    if (primaryContact === id) {
      setPrimaryContact(undefined);
      updateOne({
        variables: {
          updateSalesTeamInput: {
            id: selectedRecord?.id,
            leaderSalesTeamId: -1,
          },
        },
      });
      data.find((e) => e.id === selectedRecord?.id).leaderSalesTeamId = null;
    } else {
      setPrimaryContact(id);
      updateOne({
        variables: {
          updateSalesTeamInput: {
            id: selectedRecord?.id,
            leaderSalesTeamId: id,
          },
        },
      });
      data.find((e) => e.id === selectedRecord?.id).leaderSalesTeamId = id;
    }
  };

  const value = useMemo(
    () => ({
      currentRecordTitle,
      handleRecordData,
      columns,
      userColumns,
      columnsMembers,
      primaryContact,
      handlePrimaryContact,
      selectedRows,
      setSelectedRows,
      allUsersData,
      setAllUsersData,
      data,
      selectedRecord,
      setSelectedRecord,
      ...rest,
    }),
    [
      currentRecordTitle,
      columns,
      userColumns,
      columnsMembers,
      primaryContact,
      handlePrimaryContact,
      selectedRows,
      setSelectedRecord,
      setSelectedRows,
      allUsersData,
      setAllUsersData,
      data,
      selectedRecord,
    ],
  ) as SettingTableContextProps;

  return <settingTableContext.Provider value={value}>{children}</settingTableContext.Provider>;
};

export const useSettingTableContext = () => useContext(settingTableContext);

export default SettingTableContextProvider;
