import { gql } from '@apollo/client';

export const CREATE_OPP_CLOSE_REASON = gql`
  mutation CreateOpportunityCloseReason($createCloseReasonInput: CreateCloseReasonInput!) {
    CreateOpportunityCloseReason(createCloseReasonInput: $createCloseReasonInput) {
      boolean
      id
      name
    }
  }
`;

export const UPDATE_OPP_CLOSE_REASON = gql`
  mutation UpdateOpportunityCloseReason($updateCloseReasonInput: UpdateCloseReasonInput!) {
    UpdateOpportunityCloseReason(updateCloseReasonInput: $updateCloseReasonInput) {
      name
      id
      boolean
    }
  }
`;

export const REMOVE_OPP_CLOSE_REASON = gql`
  mutation RemoveOpportunityCloseReason($id: Int!) {
    RemoveOpportunityCloseReason(id: $id)
  }
`;
