import React, { useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import SectionCard from 'src/components/sectionCard/SectionCard';
import StyleCustomTab from 'src/modules/settings/modules/styles/StyleCustomTab.style';
import DRAG_LIST_CONFIGURATIONS_SECTIONS from './constants';

const TabCompanyType = () => {
  const [sections, setSections] = useState(DRAG_LIST_CONFIGURATIONS_SECTIONS);

  const handleDragEnd = (fromIndex: number, toIndex: number) => {
    const data = [...sections];
    const item = data.splice(fromIndex, 1)[0];
    data.splice(toIndex, 0, item);
    setSections(data);
  };

  return (
    <StyleCustomTab>
      <ReactDragListView
        onDragEnd={handleDragEnd}
        nodeSelector='.section-card'
        handleSelector='.anticon-holder'
      >
        <div>
          {sections.map((section) => (
            <SectionCard title={section.title} key={section.title}>
              {section.content}
            </SectionCard>
          ))}
        </div>
      </ReactDragListView>
    </StyleCustomTab>
  );
};

export default TabCompanyType;
