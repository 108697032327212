import { CloseCircleFilled } from '@ant-design/icons';
import { Input } from 'antd';
import StyledParentCompanyInput from './styledParentCompanyInput';

type Props = {
  name: string;
  onClear: () => void;
};

const ParentCompanyInput: React.FC<Props> = ({ name, onClear }) => (
  <StyledParentCompanyInput>
    <Input value={name} disabled />
    {!!name && (
      <span className='reset-button' onClick={onClear}>
        <CloseCircleFilled />
      </span>
    )}
  </StyledParentCompanyInput>
);

export default ParentCompanyInput;
