import styled from 'styled-components';

const StyledAvatar = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  gap: 0.5rem;
  .container-styles {
    width: 5.4rem;
    height: 5.4rem;
    border: 2px solid #0078d4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .img-styles {
      width: 4.8rem;
      height: 4.8rem;
      border-radius: 50%;
    }
    .svg-styles {
      svg {
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 50%;
        fill: #858585;
      }
    }
  }
`;

export default StyledAvatar;
