import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { TerritoryModel } from 'src/models';

const territoryAdapter: AdapterFunc<GQL.TerritoryDbModel, TerritoryModel> = (input) => ({
  id: input.id,
  name: input.name!,
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
});

export default territoryAdapter;
