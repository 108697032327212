import React, { FC } from 'react';
import SubTable from 'src/components/commons/subTable';
import StyleInfinityScroll from 'src/modules/settings/modules/styles/StyleCustomTab.style';
import StyledPaginationLess from 'src/modules/settings/modules/subTables/modules/company/tabs/type/styles/StyleCustomTab.style';

interface Props {
  useDetailsTable: (dataSource: any[]) => any;
  data: any[];
}

const SubTableDetailsView: FC<Props> = ({ useDetailsTable, data }) => {
  const {
    columns,
    setColumns,
    handleFilterChange,
    handleSortModelChange,
    onListRender,
    handleDeleteRows,
    newRecord,
    resetNewRecord,
    postData,
    createNewRecord,
    handleClearFilter,
    dataSource,
  } = useDetailsTable(data);

  return (
    <StyleInfinityScroll isDetailTable>
      <StyledPaginationLess>
        <SubTable
          data={dataSource}
          columns={columns}
          setColumns={setColumns}
          onFilterChange={handleFilterChange}
          onSortChange={handleSortModelChange}
          onListRender={onListRender}
          actionCell={['delete']}
          showOptionFilterLeft={false}
          pagination
          addNewButtonDetailsTable
          allowGlobalEditActive
          activeOnSelectRowsDisableEdit
          nonAddable
          nonGlobalFilterable
          onDeleteClick={handleDeleteRows}
          newRecord={newRecord}
          resetNewRecord={resetNewRecord}
          postData={postData}
          saveNewRecord={createNewRecord}
          clearFilter={handleClearFilter}
        />
      </StyledPaginationLess>
    </StyleInfinityScroll>
  );
};
export default SubTableDetailsView;
