import OffCanvas from 'src/components/commons/offcanvas';
import { Outlet } from 'react-router';
import ContactSetupNav from './contactSetupNav';
import ContactsMainContactCard from './contactsMainContactCard';

const ContactContent = () => {
  const header = () => <ContactsMainContactCard />;
  const content = () => (
    <>
      <ContactSetupNav />
      <Outlet />
    </>
  );
  const offCanvas = () => 'Under Construction';

  return <OffCanvas header={header} content={content} offCanvas={offCanvas} />;
};

export default ContactContent;
