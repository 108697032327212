import { gql } from '@apollo/client';

export const REMOVE_PRODUCT_POTENTIAL = gql`
  mutation RemoveProductPotential($id: Int!) {
    RemoveProductPotential(id: $id)
  }
`;

export const CREATE_PRODUCT_POTENTIAL = gql`
  mutation CreateProductPotential($createProductPotentialInput: CreateProductPotentialInput!) {
    CreateProductPotential(createProductPotentialInput: $createProductPotentialInput) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_PRODUCT_POTENTIAL = gql`
  mutation UpdateProductPotential($updateProductPotentialInput: UpdateProductPotentialInput!) {
    UpdateProductPotential(updateProductPotentialInput: $updateProductPotentialInput) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
