/* eslint-disable consistent-return */
import { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { SubTableColumn } from 'src/components/commons/subTableV1/types';
import prepareColumns, {
  prepareColumnsForFilterOptions,
} from 'src/utils/functions/prepareSubtableColumn';
import { notification } from 'antd';
import { useNavigate } from 'react-router';
import { ListInfo } from 'src/hooks/useInfinityDataSource';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import useLocalStorage from 'src/hooks/useLocalStorage';
import useCompanyStore from 'src/hooks/stores/useCompanyStore';
import { isNil, omitBy } from 'lodash';
import {
  GET_COMPANIES_DEPTH_HEADERS,
  GET_TYPE_AHEAD_RESULTS,
} from 'src/components/company/queries';
import { GetCompanyUniqueValuesFromColumn } from 'src/components/companyDetails/contacts/contactsCompaniesExpanded/types';

dayjs.extend(utc);

interface InitialStateProps {
  filters: unknown;
  columns: any[];
  viewportHeight: number;
  setColumns?: React.Dispatch<any>;
  handleOnPin?: (arg0: SubTableColumn) => void;
  pinnedColumnsNumber?: number;
  handleDeleteRows?: (rows: any) => void;
  handleOnDetailsCick?: (rows: any) => void;
  handleOnReload?: () => void;
  handleClearFilter?: () => void;
  filterCount?: number;
  items?: { key: string; label: string }[];
  loading: boolean;
  autocompleteFieldSearch: any;
  setSearchFields: (prop: any) => void;
  handleSort?: (sortData: any) => void;
  dataSource?: any;
  searchFields: any;
  totalValue?: number;
  closingLoading?: boolean;
  loadPage?: (listInfo?: ListInfo) => void;
  handleAllFilterSelection?: (data: any[]) => void;
  multiFilterDefaultValue?: any;
  columnsForFilterOptions?: any[];
  multiFilterCount?: number;
  handleClearMultiFilter?: () => void;
  openCreateCompany?: boolean;
  handleOpenCreateCompany: () => void;
}

export const CompaniesContext = createContext({} as InitialStateProps);

const CompaniesContextProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    filters,
    reloadDataSource,
    setSearchFields,
    searchFields,
    dataSource,
    setOrderBy,
    loadPage,
    deleteOne,
    totalValue,
  } = useCompanyStore();
  const [columnsForFilterOptions, setColumnsForFilterOptions] = useState<any[]>([]);
  const [openCreateCompany, setOpenCreateCompany] = useState(false);

  const multiFilteredFields = Object.keys(searchFields).filter(
    (key) =>
      key.includes('ids') &&
      columnsForFilterOptions.find(
        (col: { searchField: string; multiSearchField: string }) => col.multiSearchField === key,
      ),
  );

  const [columns, setColumns] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [multiFilterCount, setMultiFilterCount] = useState<number>(multiFilteredFields.length);
  const [pinnedColumnsNumber, setPinnedColumnsNumber] = useState<number>(0);
  const filteredFields = Object.keys(searchFields).filter(
    (key) =>
      !key.includes('ids') &&
      columns.find(
        (col: { searchField: string; multiSearchField: string }) => col.searchField === key,
      ),
  );
  const [filterCount, setFilterCount] = useState<number>(filteredFields.length);
  const viewportHeight = useMemo(() => window.innerHeight - 230, []);
  const navigate = useNavigate();
  const { storeData, data: multiFiltersData } = useLocalStorage('allFiltersComp', []);
  const multiFilterDefaultValue = localStorage.getItem('allFiltersComp') || '[]';

  useQuery(GET_COMPANIES_DEPTH_HEADERS, {
    onCompleted: (headersData) => {
      const localColumnsPreference = localStorage.getItem('ColumnsPreferences');
      setColumnsForFilterOptions(
        prepareColumnsForFilterOptions(headersData.GetCompaniesDepthGridHeader),
      );
      const preparedColumns = prepareColumns(
        headersData.GetCompaniesDepthGridHeader as unknown as SubTableColumn[],
      );

      // Assuming each column has a unique `headerName` which we use to identify it
      let columnsToUpdate = [...preparedColumns];

      if (localColumnsPreference) {
        const savedPreferences = JSON.parse(localColumnsPreference);

        // Update the preparedColumns with saved preferences
        columnsToUpdate = preparedColumns.map((col) => {
          const savedColumn = savedPreferences.find(
            (c: { headerName: string }) => c.headerName === col?.headerName,
          );
          if (savedColumn) {
            // Only update these three properties, keep the rest unchanged
            return {
              ...col,
              selected: savedColumn.selected,
              pinned: savedColumn.pinned,
            };
          }
          return col;
        }) as unknown as any;

        // Sort based on an assumed `order` property you saved in preferences, you might need to adjust this
        // If you don't store order explicitly, consider a strategy to determine the order from savedPreferences
        columnsToUpdate.sort(
          (a, b) =>
            savedPreferences.findIndex(
              (pref: { headerName: string }) => pref.headerName === a?.headerName,
            ) -
            savedPreferences.findIndex(
              (pref: { headerName: string }) => pref.headerName === b?.headerName,
            ),
        );
      } else {
        localStorage.setItem('compColumnsPreferences', JSON.stringify(columnsToUpdate));
      }
      setLoading(false);
      setColumns(columnsToUpdate);
    },
  });

  const [getFilterCompany] = useLazyQuery<GetCompanyUniqueValuesFromColumn>(GET_TYPE_AHEAD_RESULTS);

  const handleOpenCreateCompany = () => {
    setOpenCreateCompany(!openCreateCompany);
  };

  const handleDeleteRows = (rows: any[]) => {
    const removeCompanyId = rows[0].id as number;

    try {
      deleteOne(removeCompanyId).then(() => {
        notification.success({
          message: 'Success',
          description: 'Company deleted successfully.',
          key: 'deleteCompanySuccess',
          duration: 3,
        });
      });
    } catch (error) {
      console.error(error, 'error on delete comp');
    }
  };

  const handleAllFilterSelection = (data: any[]) => {
    const newData = data.reduce((acc: any, item: any) => {
      const [column, value] = item;
      // Check if value is undefined and find default value from columns state object
      let finalValue = value;
      if (typeof value === 'undefined' && columnsForFilterOptions) {
        const columnObj = columnsForFilterOptions.find(
          (c: { multiSearchField: any }) => c.multiSearchField === column,
        );
        if (columnObj && columnObj.filterOptions) {
          // Check if filterOptions is an array or not
          if (Array.isArray(columnObj.filterOptions) && columnObj.filterOptions.length > 0) {
            finalValue = columnObj.filterOptions[0].value; // Assuming filterOptions is an array of objects with a value property
          } else {
            // If filterOptions is not an array, use it directly
            finalValue = columnObj.filterOptions;
          }
        }
      }

      if (acc[column]) {
        if (Array.isArray(acc[column])) {
          acc[column].push(finalValue);
        } else {
          acc[column] = [acc[column], finalValue];
        }
      } else {
        acc[column] = finalValue;
      }
      return acc;
    }, {});
    // when setting the searchfields, I need to remove duplicates but also keep the previous values because they are also managed from another component
    setSearchFields((prev: any) => {
      const newSearchFields = { ...prev, ...newData };
      return newSearchFields;
    });
    storeData(data);
  };

  useEffect(() => {
    if (columns.length > 0) {
      setPinnedColumnsNumber(columns.filter((c: { pinned: boolean }) => c.pinned).length);
      const columnsForStorage = columns.map(({ headerName, selected, pinned }: any) => ({
        headerName,
        selected,
        pinned,
      }));
      localStorage.setItem('compColumnsPreferences', JSON.stringify(columnsForStorage));
    }
  }, [columns]);

  useEffect(() => {
    // I need to exclude from the filter count the fields which keys include 'ids' and the ones that are not a searchfield in the columns object
    const filteredFields2 = Object.keys(searchFields).filter(
      (key) =>
        !key.includes('ids') &&
        columns.find(
          (col: { searchField: string; multiSearchField: string }) => col.searchField === key,
        ),
    );
    setFilterCount(filteredFields2.length);
    setMultiFilterCount(
      multiFiltersData.reduce((acc: number, item: any) => {
        if (item.length === 1) {
          return acc + 1;
        }
        return acc + item.length - 1;
      }, 0),
    );
  }, [searchFields]);

  useEffect(() => {
    if (multiFilterDefaultValue) {
      const newData = JSON.parse(multiFilterDefaultValue).reduce((acc: any, item: any) => {
        const [column, value] = item;
        // Check if value is undefined and find default value from columns state object
        let finalValue = value;
        if (typeof value === 'undefined' && columnsForFilterOptions) {
          const columnObj = columnsForFilterOptions.find(
            (c: { multiSearchField: any }) => c.multiSearchField === column,
          );
          if (columnObj && columnObj.filterOptions) {
            // Check if filterOptions is an array or not
            if (Array.isArray(columnObj.filterOptions) && columnObj.filterOptions.length > 0) {
              finalValue = columnObj.filterOptions[0].value; // Assuming filterOptions is an array of objects with a value property
            } else {
              // If filterOptions is not an array, use it directly
              finalValue = columnObj.filterOptions;
            }
          }
        }

        if (acc[column]) {
          if (Array.isArray(acc[column])) {
            acc[column].push(finalValue);
          } else {
            acc[column] = [acc[column], finalValue];
          }
        } else {
          acc[column] = finalValue;
        }
        return acc;
      }, {});
      // when setting the searchfields, I need to remove duplicates but also keep the previous values because they are also managed from another component
      setSearchFields((prev: any) => {
        const newSearchFields = { ...prev, ...newData };
        return newSearchFields;
      });
    }
  }, [columnsForFilterOptions]);

  const handleOnPin = (targetColumn: SubTableColumn) => {
    setColumns(() => {
      const updatedColumns = columns.map((col: { headerName: string | undefined; pinned: any }) => {
        if (col.headerName === targetColumn.headerName) {
          const pinnedCount = columns.filter(
            (c: { pinned: any; headerName: any }) => c.pinned && c.headerName !== col.headerName,
          ).length;
          // If trying to pin and already two pinned, return without toggling
          if (!col.pinned && pinnedCount >= 2) {
            return col;
          }
          // Otherwise, toggle the pinned state
          return { ...col, pinned: !col.pinned };
        }
        return col;
      });

      // Sort the columns based on pinned status while maintaining the original order within each group
      return updatedColumns.sort(
        (a: { pinned: any; originalIndex: number }, b: { pinned: any; originalIndex: number }) => {
          if (a.pinned === b.pinned) {
            return a.originalIndex - b.originalIndex; // Keep original order for unpinned columns
          }
          return a.pinned ? -1 : 1; // Move pinned columns to the start
        },
      );
    });
  };

  const autocompleteFieldSearch = (field: string, search: string) =>
    getFilterCompany({
      variables: {
        column: field,
        filter: search,
        enabled: true,
      },
    })
      .then((data: any) => {
        const results = data.data.GetCompanyUniqueValuesFromColumn || [];
        const filter = results.map((result: string) => ({
          value: result,
          label: result,
        }));

        return {
          results,
          filter,
        };
      })
      .catch((error) => {
        console.error(error);
        return {
          results: [],
          filter: [],
        };
      });
  const handleOnReload = () => {
    reloadDataSource(setLoading);
  };
  const handleOnDetailsCick = (recordId: any) => {
    localStorage.setItem('new_companies_path', JSON.stringify(true));
    navigate(`/companies/${recordId}/basic`);
  };

  const handleClearMultiFilter = () => {
    const newSearchFields = Object.keys(searchFields).reduce((acc: any, key: string) => {
      if (key.includes('ids')) {
        acc[key] = undefined;
      }
      return acc;
    }, {});
    setSearchFields((prev) => omitBy({ ...prev, ...newSearchFields }, isNil));
    storeData([]);
  };

  const items: { key: string; label: string }[] = [
    {
      key: 'delete',
      label: 'Delete',
    },
  ];
  const handleSort = (sortData: any) => {
    // Log the incoming data for debugging

    // Check if sortData is undefined or not provided
    if (sortData === undefined) {
      // Optionally set default sorting, or return to skip setting state
      setOrderBy([]);

      return; // You can return early if you don't want to proceed with undefined sortData
    }

    // Proceed if sortData is defined
    const sortInput = {
      sort: sortData.sort,
      field: sortData.sortBy,
    };
    setOrderBy([sortInput]);
  };

  const handleClearFilter = () => {
    setSearchFields({});
  };

  const companiesValue = useMemo(
    () => ({
      filters,
      columns,
      viewportHeight,
      setColumns,
      handleOnPin,
      pinnedColumnsNumber,
      handleOpenCreateCompany,
      handleDeleteRows,
      handleClearFilter,
      filterCount,
      handleOnReload,
      handleOnDetailsCick,
      items,
      loading,
      autocompleteFieldSearch,
      setSearchFields,
      handleSort,
      dataSource,
      searchFields,
      totalValue,
      loadPage,
      handleAllFilterSelection,
      multiFilterDefaultValue,
      columnsForFilterOptions,
      handleClearMultiFilter,
      multiFilterCount,
      openCreateCompany,
    }),
    [
      filters,
      columns,
      viewportHeight,
      setColumns,
      handleOnPin,
      handleDeleteRows,
      handleClearFilter,
      filterCount,
      handleOnReload,
      handleOnDetailsCick,
      items,
      loading,
      autocompleteFieldSearch,
      setSearchFields,
      handleSort,
      dataSource,
      searchFields,
      totalValue,
      loadPage,
      handleAllFilterSelection,
      multiFilterDefaultValue,
      columnsForFilterOptions,
      multiFilterCount,
      openCreateCompany,
    ],
  );

  return <CompaniesContext.Provider value={companiesValue}>{children}</CompaniesContext.Provider>;
};

export const useCompaniesContext = () => {
  const context = useContext(CompaniesContext);

  if (context === undefined) {
    throw new Error('useCompaniesContext must be used within a CompaniesContextProvider');
  }

  return context;
};

export default CompaniesContextProvider;
