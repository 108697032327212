import { useState, useEffect, useCallback } from 'react';
import { StatusHeader } from 'src/components/commons/subTableV1/consts/mockSubTableV1Data';
import { SubTableColumn, Option } from 'src/components/commons/subTableV1/types';
import { prepareSubtableColums } from 'src/utils/functions/prepareSubtableColumn';
import useFilterColumn from 'src/modules/settings/modules/subTables/modules/company/tabs/callPattern/hooks/useFilterColumn';
import useStatusStore from './useStatusStore';
import { PREDEFINED_STATUS_NEED_REVIEW } from '../const';

interface Criteria {
  pagination: {
    from: number;
    size: number;
  };
  orderBy?: {
    field: string;
    sort: 'ASC' | 'DESC';
  }[];
}
const useStatus = () => {
  // Mocked Data:

  const tempHeaderResponse = StatusHeader;
  const tempPredefinedStatus = PREDEFINED_STATUS_NEED_REVIEW;
  const { createdFilters } = useFilterColumn();
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const { fetchAll, dataSource, createOne, reset, updateOne, deleteOne } = useStatusStore();

  const addNewRecord = (recordData: any) => {
    const createOpportunityStatusInput = {
      name: recordData.name,
      isPredefined: false,
    };
    try {
      createOne(createOpportunityStatusInput, 'createOpportunityStatusInput').then(() => {
        setTimeout(() => {
          reset();
        }, 1000);
      });
    } catch (error) {
      reset();
    }
  };

  const editRecord = (recordData: any) => {
    const updateStatusInput = {
      id: recordData.id,
      name: recordData.name,
    };
    try {
      updateOne({
        variables: {
          updateStatusInput,
        },
      }).then(() => {
        setTimeout(() => {
          reset();
        }, 1000);
      });
    } catch (error) {
      reset();
    }
  };

  const deleteRecord = (recordData: any) => {
    const removeOpportunityStatusId = recordData[0].id as number;

    try {
      deleteOne(removeOpportunityStatusId).then(() => {
        setTimeout(() => {
          reset();
        }, 1000);
      });
    } catch (error) {
      console.error(error);
      reset();
    }
  };

  const sortRecord = useCallback(
    (sortData: any) => {
      const criteria: Criteria = {
        pagination: {
          from: 0,
          size: 100,
        },
      };

      if (sortData) {
        criteria.orderBy = [
          {
            field: sortData.sortBy,
            sort: sortData.sort,
          },
        ];
      }

      fetchAll({ variables: { criteria } })
        .then((response) => setData(response.results))
        .catch((error) => {
          console.error('Error sorting table:', error);
          setData(dataSource); // Reset to original data in case of error
        });
    },
    [dataSource, fetchAll, setData],
  );

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(searchField, search, dataType);
    return fetchAll({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 100,
          },
          filter: filters,
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res?.results,
      filter: res?.results
        ?.map((result: any) => ({
          value: result[searchField],
          label: result[searchField],
        }))
        .filter((e: any) => e.value !== null) as Option[],
    }));
  };

  useEffect(() => {
    fetchAll({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 100,
          },
        },
      },
    });
    setColumns(prepareSubtableColums(tempHeaderResponse as unknown as SubTableColumn[]) ?? []);
  }, []);

  useEffect(() => {
    if (dataSource) setData(dataSource);
  }, [dataSource]);

  return {
    data,
    columns,
    addNewRecord,
    editRecord,
    deleteRecord,
    autocompleteFieldSearch,
    sortRecord,
    tempPredefinedStatus,
  };
};

export default useStatus;
