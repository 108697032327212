import React from 'react';
import { Form, Checkbox, Col, Row } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import CustomSelect from 'src/components/commons/customSelect';
import { CompanyHeaderOptionsType2 } from 'src/types/companiesTypes';

interface OptionType {
  label: string;
  value: string | number;
}

interface CompanyTypeSelectionRendererProps {
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  options: OptionType[];
  dataTestId?: string;
  checkGroup?: { label: string; value: string }[];
  companySelection?: CompanyHeaderOptionsType2 | null;
  handleCheckboxChange: (e: CheckboxValueType[]) => void;
  selectedCheckOptions?: CheckboxValueType[];
  required?: boolean;
}

const CompanyTypeSelectionRenderer: React.FC<CompanyTypeSelectionRendererProps> = ({
  options,
  handleChange,
  dataTestId = 'commpanyType-select-id',
  checkGroup,
  companySelection,
  handleCheckboxChange,
  selectedCheckOptions,
  required,
}) => (
  <>
    <Form.Item label='Company Type' name='companyType' required={required}>
      <CustomSelect
        placeHolder='Select a Company type'
        handleChange={handleChange}
        externalValue={companySelection?.id}
        options={options}
        dataTestId={dataTestId}
        customClassName='customSelect'
        externalName='companyTypeId'
        id='companyTypeId'
      />
    </Form.Item>
    {checkGroup && (
      <Checkbox.Group
        style={{ width: '100%', paddingBottom: '20px' }}
        onChange={handleCheckboxChange}
        value={selectedCheckOptions}
        data-testid='companySelection-checkbox-id'
      >
        <Row>
          {React.Children.toArray(
            checkGroup?.map((item) => (
              <Col span={24}>
                <Checkbox value={item.value}>{item.label}</Checkbox>
              </Col>
            )),
          )}
        </Row>
      </Checkbox.Group>
    )}
  </>
);

export default CompanyTypeSelectionRenderer;
