import React, { FC, ReactNode } from 'react';
import { Form, Input, InputProps, InputRef, Tooltip } from 'antd';
import { InterfaceStatus } from '../types';

export type Props = {
  dataIndex: any;
  validateStatus: InterfaceStatus;
  helpInput: ReactNode;
  inputRef: React.RefObject<InputRef>;
} & InputProps;

const InputNumber: FC<Props> = ({
  dataIndex,
  validateStatus,
  value,
  onBlur,
  onChange,
  inputRef,
  helpInput,
  ...props
}) => (
  <Tooltip
    overlayInnerStyle={{
      color: 'red',
    }}
    color='white'
    trigger='hover'
    title={helpInput}
    placement='bottomLeft'
  >
    <Form.Item
      style={{
        margin: 0,
      }}
      name={dataIndex}
      validateStatus={validateStatus}
      hasFeedback
    >
      <Input
        ref={inputRef}
        type='number'
        defaultValue={value as number}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={(event) => {
          if (!/[0-9.]/.test(event.key) && event.key !== 'Backspace') {
            event.preventDefault();
          }
        }}
        id='input-new-number-record-id'
        {...props}
      />
    </Form.Item>
  </Tooltip>
);

export default InputNumber;
