import { Navigate, Route, Routes } from 'react-router';
import NotFoundPage from 'src/components/commons/notFound';
import ContactSetupLayout from 'src/layouts/contactSetup.layout';
import { ContactBasicTab } from 'src/components/contactDetails';
import PageContacts from './pages';
import NewContacts from '../contacts2/pages/Contacts';

const ContactRoutes = () => (
  <Routes>
    <Route path='/'>
      <Route path='/' element={<PageContacts />} />
      <Route path='/migration' element={<NewContacts />} />
      <Route path='/:id' element={<ContactSetupLayout />}>
        <Route index path='basic' element={<ContactBasicTab />} />
        <Route index path='*' element={<Navigate to='basic' replace />} />
      </Route>
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  </Routes>
);

export default ContactRoutes;
