import useEntityAdapter, { OperationSpecs } from 'src/hooks/useEntityAdapter';
import { GET_ALL_OPP_STAGE } from '../graphql/queries';
import { CREATE_OPP_STAGE, UPDATE_OPP_CLOSE_REASON, REMOVE_OPP_STAGE } from '../graphql/mutation';
import { StageModel } from '../models/stage.models';
import { stageAdapter } from '../adapters/stage.adapter';

const operations: OperationSpecs = {
  createOne: {
    op: CREATE_OPP_STAGE,
  },
  updateOne: {
    op: UPDATE_OPP_CLOSE_REASON,
  },
  deleteOne: {
    op: REMOVE_OPP_STAGE,
  },
  fetchAll: {
    op: GET_ALL_OPP_STAGE,
    dataPath: 'GetAllOpportunityStages',
  },
};

const useStageStore = () => {
  const api = useEntityAdapter<StageModel>({
    adapters: { entityAdapter: stageAdapter },
    operations,
    isPaginated: true,
    entityName: 'OpportunityStage',
  });

  return api;
};

export default useStageStore;
