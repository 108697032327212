import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CREATE_PRIVATE_TEAM, REMOVE_PRIVATE_TEAM } from 'src/graphql/mutations/private-team';
import GET_ALL_PRIVATE_TEAMS from 'src/graphql/queries/private-teams';
import {
  CreatePrivateTeamInput,
  Mutation,
  MutationCreatePrivateTeamArgs,
  MutationRemovePrivateTeamArgs,
  PrivateTeamModel,
  Query,
} from 'src/graphql/schema-types';

type GetAllPrivateTeamsResult = Pick<Query, 'GetAllPrivateTeams'>;
type RemovePrivateTeamResult = Pick<Mutation, 'RemovePrivateTeam'>;
type CreatePrivateTeamResult = Pick<Mutation, 'CreatePrivateTeam'>;

const usePrivateTeamStore = (autoInit = true) => {
  const [entities, setEntities] = useState<PrivateTeamModel[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const [queryGetAll] = useLazyQuery<GetAllPrivateTeamsResult>(GET_ALL_PRIVATE_TEAMS, {
    fetchPolicy: 'network-only',
  });

  const [mutationDeleteOne] = useMutation<RemovePrivateTeamResult, MutationRemovePrivateTeamArgs>(
    REMOVE_PRIVATE_TEAM,
  );

  const [mutationCreateOne] = useMutation<CreatePrivateTeamResult, MutationCreatePrivateTeamArgs>(
    CREATE_PRIVATE_TEAM,
  );

  const getAll = () => {
    setLoading(true);
    return queryGetAll()
      .then((result) => {
        if (result.data?.GetAllPrivateTeams) {
          setEntities(result.data.GetAllPrivateTeams);
          setTotal(result.data.GetAllPrivateTeams.length!);
        }
        return result;
      })
      .catch((err) => {
        setEntities([]);
        setTotal(0);
        setError(err);
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeOne = (id: number) => {
    setDeleting(true);
    return mutationDeleteOne({
      variables: {
        id,
      },
    })
      .then(() => {
        getAll();
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const createOne = (createPrivateTeamInput: CreatePrivateTeamInput) => {
    setCreating(true);
    return mutationCreateOne({
      variables: {
        createPrivateTeamInput,
      },
    })
      .then((res) => {
        getAll();
        return res;
      })
      .catch((err) => {
        setError(err);
        throw err;
      })
      .finally(() => {
        setCreating(false);
      });
  };

  useEffect(() => {
    if (autoInit) getAll();
  }, []);

  return {
    entities,
    total,
    loading,
    deleting,
    creating,
    error,
    getAll,
    removeOne,
    createOne,
  };
};

export default usePrivateTeamStore;
