import { jwtDecode } from 'jwt-decode';

export const isTokenValid = (token: string) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now().valueOf() / 1000;
    if (decoded.exp! < currentTime) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};
