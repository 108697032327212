import { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import FormSection from 'src/components/formSection';
import useContactGroupStore from 'src/hooks/stores/useContactGroupStores';
import { useContactContext } from 'src/context/contact.context';
import { useContactsContext } from 'src/modules/contacts/contexts/contacts.contexts';
import CustomSelect from 'src/components/commons/customSelect';

type Props = { showTitle?: boolean; requiredFields?: string[] };

interface AutocompleteComponentProps {
  options: { value: number; label: string }[];
  onChange?: (e: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
  selectedOptions: any[] | undefined;
}

const AutocompleteComponent: FC<AutocompleteComponentProps> = ({
  options,
  onChange,
  readOnly,
  disabled,
  selectedOptions,
}) => (
  <CustomSelect
    multiSel
    handleMultipleSelect={(_, opt) => onChange?.((opt as any) || (_ as string[]))}
    listDefault={options}
    disabled={disabled || readOnly}
    externalWidth='100%'
    customClassName={readOnly ? 'readOnly-ant-multisel' : ''}
    selectedColumns={selectedOptions}
  />
);

const ContactFormContactGroup: FC<Props> = ({ showTitle = true, requiredFields }) => {
  const createForm = Form.useFormInstance();
  const { dataSource } = useContactGroupStore();
  const [opt, setOpt] = useState<{ value: number; label: string }[] | []>([]);
  const { activeExpandedEdition, form, contact } = useContactContext();
  const { createContactModalActive } = useContactsContext();
  const formName = 'contactGroup';

  const handleContactGroupValues = (options: any) => {
    const values = options.map((option: string) => {
      const selectedValue = option.substring(option.indexOf('{') + 3, option.lastIndexOf('%'));
      const isString = Number.isNaN(selectedValue);
      return isString ? selectedValue : Number(selectedValue);
    });

    if (form) {
      form.setFieldValue(formName, values);
    } else {
      createForm.setFieldValue(formName, values);
    }
  };

  useEffect(() => {
    const data = dataSource?.map((group) => ({
      value: group?.id,
      label: group?.name,
      toSend: group.id,
    }));
    setOpt(data);
  }, [dataSource]);

  const fields: FieldType[] = [
    {
      name: formName,
      type: 'select',
      options: opt,
      render: () => (
        <AutocompleteComponent
          options={opt}
          readOnly={!activeExpandedEdition && !createContactModalActive}
          onChange={(options) => handleContactGroupValues(options)}
          selectedOptions={contact?.groups}
        />
      ),
      rules: [
        {
          required: requiredFields?.includes('contactGroup'),
          message: 'Contact Group is required',
        },
      ],
      span: 24,
    },
  ];

  if (!showTitle) {
    return <GenericForm fields={fields} />;
  }

  return (
    <FormSection title='Contact Group'>
      <GenericForm fields={fields} />
    </FormSection>
  );
};

export default ContactFormContactGroup;
