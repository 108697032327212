import { gql } from '@apollo/client';

export const GET_ALL_GRID_PREFERENCES = gql`
  query GetAllGridPreferences($gridName: String!) {
    GetAllGridPreferences(gridName: $gridName) {
      id
      userId
      gridName
      options
      name
      isDefault
      createdAt
      updatedAt
    }
  }
`;

export default {
  GET_ALL_GRID_PREFERENCES,
};
