import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import { useCompanyContext } from 'src/context/company.context';

const CompanyCommentsSection = () => {
  const { company, activeExpandedEdition } = useCompanyContext();
  const fields: FieldType[] = [
    {
      name: 'comments',
      type: 'textarea',
      readOnly: !activeExpandedEdition,
      span: 24,
      initialValue: company?.comments,
    },
  ];

  return <GenericForm fields={fields} name='formComments' />;
};

export default CompanyCommentsSection;
