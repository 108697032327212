import React from 'react';
import { Input, Checkbox, Form, InputNumber, Select, DatePicker } from 'antd';

const { Option } = Select;

type Props = {
  optionToRender?: string;
  value?: string | number | boolean | string[] | Date;
  options?: { id: string; name: string }[];
  label?: string;
  isRequired?: boolean;
  id: string;
  handlerInputs: (event: any, id: string, selectType?: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
};

const CustomFieldRenderer: React.FC<Props> = ({
  optionToRender,
  options,
  label = '',
  isRequired,
  id,
  handlerInputs,
  value,
  readOnly,
  disabled,
}) => {
  let elementToRender;
  switch (optionToRender) {
    case 'text': {
      elementToRender = <Input onChange={(e) => handlerInputs(e, id, 'text')} />;
      break;
    }
    case 'dropdown': {
      elementToRender = readOnly ? (
        <Input readOnly placeholder='Please select' />
      ) : (
        <Select
          options={
            options?.map((element) => ({
              value: element.id,
              label: element.name,
            })) ?? []
          }
          onChange={(e) => handlerInputs(e, id, 'dropdown')}
        />
      );
      break;
    }
    case 'multi_select_dropdown': {
      elementToRender = (
        <Select
          showArrow
          showSearch
          mode='multiple'
          onChange={(e) => handlerInputs(e, id, 'multi_select_dropdown')}
          disabled={disabled || readOnly}
          className={readOnly ? 'readOnly-ant-multisel' : undefined}
        >
          {options?.map((element) => (
            <Option key={element.id}>{element.name}</Option>
          ))}
        </Select>
      );
      break;
    }
    case 'checkbox': {
      elementToRender = (
        <p style={{ paddingTop: '2.2rem' }}>
          <Checkbox
            defaultChecked={value === 'true'}
            style={{ pointerEvents: readOnly ? 'none' : undefined }}
            onChange={(e) => handlerInputs(e, id, 'checkbox')}
          >
            {label}
          </Checkbox>
        </p>
      );
      break;
    }
    case 'textarea': {
      elementToRender = (
        <Input.TextArea onChange={(e) => handlerInputs(e, id, 'textarea')} readOnly={readOnly} />
      );
      break;
    }
    case 'integer': {
      const innerMin = options?.find((element) => element.id === 'min')?.name
        ? Number(options?.find((element) => element.id === 'min')?.name)
        : undefined;
      const innerMax = options?.find((element) => element.id === 'max')?.name
        ? Number(options?.find((element) => element.id === 'max')?.name)
        : undefined;
      elementToRender = (
        <InputNumber
          style={{ width: '100%' }}
          min={innerMin}
          max={innerMax}
          onChange={(e) => handlerInputs(e, id, 'integer')}
          readOnly={readOnly}
        />
      );
      break;
    }
    case 'decimal': {
      const innerMin = options?.find((element) => element.id === 'min')?.name
        ? Number(options?.find((element) => element.id === 'min')?.name)
        : undefined;
      const innerMax = options?.find((element) => element.id === 'max')?.name
        ? Number(options?.find((element) => element.id === 'max')?.name)
        : undefined;
      elementToRender = (
        <InputNumber
          style={{ width: '100%' }}
          min={innerMin}
          max={innerMax}
          onChange={(e) => handlerInputs(e, id, 'decimal')}
          step={0.01}
          readOnly={readOnly}
        />
      );
      break;
    }
    case 'date': {
      elementToRender = (
        <DatePicker
          format='MM/DD/YYYY'
          style={{ width: '100%' }}
          onChange={(date, dateString) => handlerInputs(dateString, id, 'date')}
          disabled={disabled || readOnly}
          className='readOnly-datePicker'
        />
      );
      break;
    }
    case 'date_time': {
      elementToRender = (
        <DatePicker
          format='MM/DD/YYYY HH:mm:ss'
          style={{ width: '100%' }}
          onChange={(date, dateString) => handlerInputs(dateString, id, 'date')}
          showTime
          disabled={disabled || readOnly}
          className='readOnly-datePicker'
        />
      );
      break;
    }
    case 'hyperlink': {
      elementToRender = (
        <p style={{ paddingTop: '2.2rem' }}>
          {label}: <a href={value as string}>{value as string}</a>
        </p>
      );
      break;
    }
    default:
      return null;
  }

  const customWithStyle =
    optionToRender !== 'textarea'
      ? {
          display: 'inline-block',
          width: 'calc(45% - 8px)',
        }
      : undefined;
  let labelToRender: string | number | boolean | string[] | Date | undefined = label;
  if (optionToRender === 'checkbox') {
    labelToRender = undefined;
  }
  if (optionToRender === 'hyperlink') {
    labelToRender = undefined;
  }

  return (
    <Form.Item
      id={id}
      name={label}
      label={labelToRender}
      valuePropName={optionToRender === 'checkbox' ? 'checked' : 'value'}
      rules={[{ required: isRequired && optionToRender !== 'checkbox' }]}
      style={{
        ...customWithStyle,
        margin: label?.length === 0 ? '30px 8px 0 8px' : '0 8px 10px 8px',
      }}
    >
      {elementToRender}
    </Form.Item>
  );
};

export default CustomFieldRenderer;
