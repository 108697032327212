import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { CompanyRankModel } from 'src/models';

const companyRankAdapter: AdapterFunc<GQL.CompanyRankDbModel, CompanyRankModel> = (input) => ({
  id: input.id,
  name: input.name!,
  description: input.description!,
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
});

export default companyRankAdapter;
