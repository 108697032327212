import React, { useEffect, useMemo } from 'react';
import { Typography, Form } from 'antd';
import CustomSelect from 'src/components/commons/customSelect';
import useProductPotentialsStore from 'src/hooks/useProductPotentialsStore';
import { CreateCompanyBodyRendererTypes } from '../types';
import isRequired from '../utils/functions';

const ProductPotentialsRenderer: React.FC<CreateCompanyBodyRendererTypes> = ({
  formElementsOptions,
  handleMultiSelection,
}) => {
  const { entities, getAll } = useProductPotentialsStore();
  const options = useMemo(
    () =>
      entities.map((entity) => ({
        label: entity.name!,
        value: entity.id,
      })),
    [entities],
  );

  useEffect(() => {
    getAll({ Criteria: { pagination: { from: 0, size: 100 } } });
  }, []);

  return (
    <div style={{ marginBottom: '49px' }}>
      <Typography.Title level={4} className='section-title'>
        Product Potentials{' '}
      </Typography.Title>
      <Form.Item
        name='productPotentials'
        required={isRequired(formElementsOptions, 'productPotentials')}
      >
        <CustomSelect
          customClassName='customSelect'
          handleMultipleSelect={handleMultiSelection}
          allowClear
          multiSel
          externalWidth='100%'
          listDefault={options}
          externalName='productPotentials'
          dataTestId='product-potentials-select'
        />
      </Form.Item>
    </div>
  );
};

export default ProductPotentialsRenderer;
