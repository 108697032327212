import {
  PLACEHOLDER_MUTATION,
  GET_ALL_OPPORTUNITIES,
  GET_OPPORTUNITY,
  REMOVE_ONE_OPPORTUNITY,
  CREATE_OPP,
} from 'src/graphql';
import { opportunityAdapter } from 'src/adapters/opportunity.adapter';
import useEntityAdapter, { OperationSpecs } from '../useEntityAdapter';

const operations: OperationSpecs = {
  createOne: {
    op: CREATE_OPP,
  },
  updateOne: {
    op: PLACEHOLDER_MUTATION,
  },
  deleteOne: {
    op: REMOVE_ONE_OPPORTUNITY,
  },
  fetchAll: {
    op: GET_ALL_OPPORTUNITIES,
    dataPath: 'GetAllOpportunities',
  },
  fetchOne: {
    op: GET_OPPORTUNITY,
    dataPath: 'GetOpportunity',
  },
  /* deleteInBulk: {
    op: DELETE_OPPORTUNITIES_IN_BULK,
  }, */
};

const useOpportunitiesStore = () => {
  const api = useEntityAdapter({
    adapters: { entityAdapter: opportunityAdapter },
    operations,
    isPaginated: true,
    entityName: 'Opportunity',
    hasSort: true,
  });

  return api;
};

export default useOpportunitiesStore;
