import { privateTeamAdapter } from 'src/adapters';
import { GET_ALL_PRIVATE_TEAMS, GET_PRIVATE_TEAM } from 'src/graphql';
import { PrivateTeamModel } from 'src/models';
import {
  CREATE_PRIVATE_TEAM,
  REMOVE_PRIVATE_TEAM,
  UPDATE_PRIVATE_TEAM,
} from 'src/graphql/mutations/private-team';
import useEntityAdapter, { OperationSpecs } from '../useEntityAdapter';

const operations: OperationSpecs = {
  createOne: {
    op: CREATE_PRIVATE_TEAM,
  },
  updateOne: {
    op: UPDATE_PRIVATE_TEAM,
  },
  deleteOne: {
    op: REMOVE_PRIVATE_TEAM,
  },
  fetchAll: {
    op: GET_ALL_PRIVATE_TEAMS,
    dataPath: 'GetAllPrivateTeams',
  },
  fetchOne: {
    op: GET_PRIVATE_TEAM,
    dataPath: 'GetPrivateTeam',
  },
};

const usePrivateTeamsStore = () => {
  const api = useEntityAdapter<PrivateTeamModel>({
    adapters: { entityAdapter: privateTeamAdapter },
    operations,
    isPaginated: true,
    entityName: 'Division',
  });

  return api;
};

export default usePrivateTeamsStore;
