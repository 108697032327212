import { notification } from 'antd';
import { useState, useEffect } from 'react';
import { CloseReasonHeader } from 'src/components/commons/subTableV1/consts/mockSubTableV1Data';
import { GET_TYPE_AHEAD_RESULTS } from 'src/components/company/queries';
import { useLazyQuery } from '@apollo/client';
import useFilterColumn from 'src/modules/settings/modules/subTables/modules/company/tabs/callPattern/hooks/useFilterColumn';
import useLocalSearchAndSort from 'src/hooks/useLocalSearchAndSort';
import { SubTableColumn, Option } from 'src/components/commons/subTableV1/types';
import ButtonReloadClearCache from 'src/components/buttonReloadClearCache/ButtonReloadClearCache';
import { prepareSubtableColums } from 'src/utils/functions/prepareSubtableColumn';
import { cloneDeep } from 'lodash';
import { successModal } from 'src/utils/modals';
import useOpportunityCloseReasonsStore from './useCloseReasonStore';
import { GET_ALL_OPP_CLOSE_REASON } from '../graphql/queries';
import { GetAllOpportunityCloseReasonsResponse } from '../types';
import { COLUMN } from '../const';

const useCloseReason = () => {
  const [fetchAll] = useLazyQuery<GetAllOpportunityCloseReasonsResponse>(GET_ALL_OPP_CLOSE_REASON, {
    fetchPolicy: 'network-only',
  });
  const tempHeaderResponse = CloseReasonHeader;
  const { createdFilters } = useFilterColumn();
  const { localSearchProvider, localSortProvider } = useLocalSearchAndSort();
  const { createOne, reset, updateOne, deleteOne } = useOpportunityCloseReasonsStore();
  const [selectedSideData, setSelectedSideData] = useState<any>({});
  const [columns, setColumns] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any>();
  const [page, setPage] = useState<number>(0);
  const leftSideTableColumn = COLUMN;
  const [getTypeaheadData] = useLazyQuery(GET_TYPE_AHEAD_RESULTS, {
    context: { clientName: 'elastic' },
    fetchPolicy: 'network-only',
  });

  const resetData = () => {
    fetchAll({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 100,
          },
        },
      },
    }).then((res) => {
      if (res) {
        const innerResponse = res?.data?.GetAllOpportunityCloseReasons;
        setDataSource(innerResponse);
        setPage(1);
      }
    });
  };

  const handleFetchAll = (companyNames?: string) => {
    const innerSize = 100;
    const innerFrom = companyNames ? 0 : page * innerSize;
    fetchAll({
      variables: {
        criteria: {
          pagination: {
            from: innerFrom,
            size: innerSize,
          },
        },
        searchFields: {
          company_names: [companyNames || ''],
        },
      },
    }).then((res) => {
      if (res) {
        if (companyNames) {
          const innerResponse = res?.data?.GetAllOpportunityCloseReasons;

          setDataSource(innerResponse);
          setPage((prevPage) => prevPage + 1);
        } else {
          const innerResponse = res?.data?.GetAllOpportunityCloseReasons;
          const innerDataSource = cloneDeep(dataSource);
          const innerNewData = {
            total: innerResponse?.total,
            results: innerDataSource?.results
              ? innerDataSource?.results?.concat(innerResponse?.results)
              : innerResponse?.results,
          };
          setDataSource(innerNewData);
          setPage((prevPage) => prevPage + 1);
        }
      }
    });
  };

  useEffect(() => {
    handleFetchAll();
  }, []);

  const localSort = (sortData: any) => {
    if (selectedSideData && selectedSideData.data) {
      const sortedData = localSortProvider(selectedSideData, sortData);
      setSelectedSideData(sortedData);
    }
  };

  const localSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(searchField, search, dataType);
    return localSearchProvider(filters, selectedSideData.data).then((res) => ({
      results: res?.results,
      filter: res?.results
        ?.map((result: any) => ({
          value: result[searchField],
          label: result[searchField],
        }))
        .filter((e: any) => e.value !== null) as Option[],
    }));
  };

  const autocompleteGlobalProvider = async (filter: string) =>
    getTypeaheadData({
      variables: {
        column: 'name',
        filter,
        enabled: true,
      },
    }).then((res) =>
      res.data.GetCompanyUniqueValuesFromColumn.map((element: string) => ({
        label: element,
        value: element,
      })),
    );

  const onSearch = (value: string) => handleFetchAll(value);

  const addNewRecord = async (recordData: any) => {
    const createCloseReasonInput = {
      companyId: selectedSideData.id === 0 ? undefined : selectedSideData.id,
      name: recordData.name,
    };
    try {
      createOne(createCloseReasonInput, 'createCloseReasonInput').then((res) => {
        setSelectedSideData((prevState: { data: any }) => {
          const updatedData = [...prevState.data, res?.CreateOpportunityCloseReason];
          updatedData.sort((a, b) => a.name.localeCompare(b.name));
          return {
            ...prevState,
            data: updatedData,
          };
        });
        successModal({ onClose: () => resetData() });
      });
    } catch (error) {
      reset();
    }
  };

  const editRecord = (recordData: any) => {
    const updateCloseReasonInput = {
      id: recordData.id,
      name: recordData.name,
    };
    try {
      updateOne({
        variables: {
          updateCloseReasonInput,
        },
      }).then(() => {
        setTimeout(() => {
          reset();
        }, 1000);
      });
    } catch (error) {
      notification.error({
        message: 'Error editing record',
        key: 'error-fetching-data',
        btn: <ButtonReloadClearCache />,
      });
      reset();
    }
  };

  const deleteRecord = (recordData: any) => {
    const removeOpportunityCloseReasonId = recordData[0].id as number;
    try {
      deleteOne(removeOpportunityCloseReasonId).then(() => {
        setSelectedSideData((prevState: any) => {
          const filteredData = prevState.data.filter(
            (item: any) => item.id !== removeOpportunityCloseReasonId,
          );

          return {
            ...prevState,
            data: filteredData,
          };
        });
        setTimeout(() => {
          reset();
        }, 1000);
      });
    } catch (error) {
      notification.error({
        message: 'Error deleting record',
        key: 'error-fetching-data',
        btn: <ButtonReloadClearCache />,
      });
      reset();
    }
  };

  useEffect(() => {
    setColumns(prepareSubtableColums(tempHeaderResponse as unknown as SubTableColumn[]) ?? []);
  }, []);

  return {
    dataSource,
    columns,
    leftSideTableColumn,
    addNewRecord,
    handleFetchAll,
    editRecord,
    deleteRecord,
    autocompleteGlobalProvider,
    localSort,
    localSearch,
    setSelectedSideData,
    selectedSideData,
    onSearch,
    resetData,
  };
};

export default useCloseReason;
