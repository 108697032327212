import styled from 'styled-components';

const SToggleCollapse = styled.div`
  & > span {
    position: absolute;
    top: 18px;
    right: 12px;
    font-size: 20px;
    color: #4b4b4b;
    cursor: pointer;
  }
`;

export default SToggleCollapse;
