import React from 'react';
import SubTable from 'src/components/commons/subTable';
import StyleInfinityScroll from 'src/modules/settings/modules/styles/StyleCustomTab.style';
import usePitches from './hooks/usePitches';
import PitchForm from './components/pitchForm';

const Pitches = () => {
  const {
    autocompleteFieldSearch,
    handleFilterChange,
    handleSortModelChange,
    handleUpdateRows,
    setColumns,
    onListRender,
    dataSource,
    columns,
    spinning,
    newRecord,
    postData,
    resetNewRecord,
    createNewRecord,
    handleClearFilter,
    handleDeleteRows,
  } = usePitches();

  return (
    <StyleInfinityScroll isDetailTable>
      <SubTable
        data={dataSource}
        columns={columns}
        setColumns={setColumns}
        autocompleteFieldProvider={autocompleteFieldSearch}
        onFilterChange={handleFilterChange}
        onSortChange={handleSortModelChange}
        onListRender={onListRender}
        scroll={{ y: '50vh', x: '100%' }}
        actionCell={['delete']}
        headerActions={['filter', 'delete']}
        id='company-pitches-table'
        showOptionFilterLeft={false}
        allowGlobalEditActive
        activeOnSelectRowsDisableEdit
        total
        handleUpdateRows={handleUpdateRows}
        onDeleteClick={handleDeleteRows}
        newRecord={newRecord}
        resetNewRecord={resetNewRecord}
        postData={postData}
        saveNewRecord={createNewRecord}
        clearFilter={handleClearFilter}
        loading={spinning}
        BodyModal={PitchForm}
        addWithModal
        titleModalEdit='Edit Pitch'
        titleModalNew='New Pitch'
        contentOnDelete=''
        columnFilterShown
      />
    </StyleInfinityScroll>
  );
};
export default Pitches;
