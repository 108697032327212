import _ from 'lodash';

import { extractValidIds } from './extractValidIds';

type FieldsMapping = { [key: string]: string };

export const preparePayload = (dataWithChanges: any, formattedFormData: any) => {
  const fieldsMapping: FieldsMapping = {
    manufacturer: 'Manufacturers',
    manufacturerContact: 'ManufacturerContacts',
    distributor: 'Distributors',
    contactsDistributor: 'DistributorsContacts',
    customer: 'Customers',
    customerContact: 'CustomersContacts',
  };

  const payload: any = {};

  // Loop through specified fields to detect differences
  Object.keys(fieldsMapping).forEach((field) => {
    const changesField = dataWithChanges[field];
    const formattedField = formattedFormData[field];

    if (_.isArray(changesField) && _.isArray(formattedField)) {
      // Detect additions
      const added = extractValidIds(_.difference(changesField, formattedField));
      if (added.length > 0) {
        payload[`add${fieldsMapping[field]}`] = added;
      }

      // Detect removals
      const removed = extractValidIds(_.difference(formattedField, changesField));
      if (removed.length > 0) {
        payload[`remove${fieldsMapping[field]}`] = removed;
      }
    }
  });

  // Merge additional properties from dataWithChanges into the payload
  const additionalProperties = _.omit(dataWithChanges, Object.keys(fieldsMapping));
  return { ...payload, ...additionalProperties };
};
