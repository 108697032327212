import { gql } from '@apollo/client';

export const GET_ALL_COMPANY_RANK = gql`
  query GetAllCompanyRanks2(
    $criteria: CompanyRankCriteriaInput!
    $searchFields: FindCompanyRankInput
  ) {
    GetAllCompanyRanks2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
        description
      }
      total
    }
  }
`;

export const GET_COMPANY_RANK_HEADERS = gql`
  query Query {
    GetCompanyRankGridHeaders
  }
`;
