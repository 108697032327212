import { gql } from '@apollo/client';

export const CREATE_STATUS = gql`
  mutation CreateOpportunityStatus($createOpportunityStatusInput: CreateStatusInput!) {
    CreateOpportunityStatus(createOpportunityStatusInput: $createOpportunityStatusInput) {
      boolean
      id
      name
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation UpdateOpportunityStatus($updateStatusInput: UpdateStatusInput!) {
    UpdateOpportunityStatus(updateStatusInput: $updateStatusInput) {
      id
      name
    }
  }
`;

export const REMOVE_STATUS = gql`
  mutation RemoveOpportunityStatus($id: Int!) {
    RemoveOpportunityStatus(id: $id)
  }
`;
