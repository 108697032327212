import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    GetAllUsers {
      cognitoId
      enabled
      id
      name
      status
      tenantId
      typeId
    }
  }
`;
