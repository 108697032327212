import { gql } from '@apollo/client';

export const GET_OPP_HEADERS = gql`
  query GetOppHeaders {
    GetOpportunitiesGridHeaders
  }
`;

export const GET_OPP_COLUMNS_UNIQUE_VALUES = gql`
  query GetOpportunitiesUniqueValuesFromColumn(
    $column: FilterOpportunitiesEnum!
    $filter: String!
  ) {
    GetOpportunitiesUniqueValuesFromColumn(column: $column, filter: $filter)
  }
`;

export const UPDATE_OPPORTUNITY = gql`
  mutation UpdateOpportunities($updateStageInput: UpdateOpportunitiesInput!) {
    UpdateOpportunities(updateStageInput: $updateStageInput) {
      id
      topic
      followUp
      value
      nextStep
      priority
      oppStatus {
        id
      }
      oppStage {
        id
        name
      }
      customer {
        id
        name
      }
      manufacturer {
        id
        name
      }
      distributor {
        id
        name
      }
      closedReason {
        id
        name
      }
      failedReason
      bid
      closedStatus {
        id
        name
      }
      estimatedCloseDate
      oppOwner {
        id
        name
      }
      city
      productLine {
        id
        name
      }
      commissionRate
      totalPrice
      manufacturerContact {
        id
        name
      }
      distributorContact {
        id
        name
      }
      customerContact {
        id
        name
      }
      enabled
      createdAt
      updatedAt
      leadSource {
        id
        name
      }
      salesTeam {
        id
        name
      }
      state {
        id
        name
      }
      type {
        id
        name
      }
      approvedBy
      approvedDate
      competitors
      description
      estAnnualQty
      expirationDate
      notes
      notifyWatchers
      potential
      registrationLevel
      registrationNumber
      registrationStatus
      reportingComments
    }
  }
`;

export const GET_ALL_OPPORTUNITIES = gql`
  query GetAllOpportunities(
    $criteria: OpportunitiesCriteriaInput!
    $searchFields: FindOpportunitiesInput
  ) {
    GetAllOpportunities(Criteria: $criteria, SearchFields: $searchFields) {
      total
      amount
      results {
        id
        topic
        followUp
        value
        nextStep
        priority
        oppStatus {
          id
          name
        }
        oppStage {
          id
          name
        }
        customer {
          id
          name
        }
        manufacturer {
          id
          name
        }
        distributor {
          id
          name
        }
        salesTeam {
          id
          name
        }
        closedReason {
          id
          name
        }
        bid
        closedStatus {
          id
          name
        }
        estimatedCloseDate
        oppOwner {
          id
          name
        }
        type {
          id
          name
        }
        leadSource {
          id
          name
        }
        city
        productLine {
          id
          name
        }
        state {
          id
          name
        }
        manufacturerContact {
          id
          name
        }
        distributorContact {
          id
          name
        }
        customerContact {
          id
          name
        }
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_OPPORTUNITY = gql`
  query GetOpportunity($getOpportunityId: Int!) {
    GetOpportunity(id: $getOpportunityId) {
      id
      topic
      followUp
      value
      nextStep
      priority
      oppStatus {
        id
      }
      oppStage {
        id
        name
      }
      customer {
        id
        name
      }
      manufacturer {
        id
        name
      }
      distributor {
        id
        name
      }
      closedReason {
        id
        name
      }
      failedReason
      bid
      closedStatus {
        id
        name
      }
      estimatedCloseDate
      oppOwner {
        id
        name
      }
      city
      productLine {
        id
        name
      }
      commissionRate
      totalPrice
      manufacturerContact {
        id
        name
      }
      distributorContact {
        id
        name
      }
      customerContact {
        id
        name
      }
      enabled
      createdAt
      updatedAt
      leadSource {
        id
        name
      }
      salesTeam {
        id
        name
      }
      state {
        id
        name
      }
      type {
        id
        name
      }
      approvedBy
      approvedDate
      competitors
      description
      estAnnualQty
      expirationDate
      notes
      notifyWatchers
      potential
      registrationLevel
      registrationNumber
      registrationStatus
      reportingComments
    }
  }
`;

export const GET_ALL_OPPORTUNITIES_STAGES = gql`
  query GetAllOpportunityStages($criteria: StageCriteriaInput!, $searchFields: FindStageInput) {
    GetAllOpportunityStages(Criteria: $criteria, SearchFields: $searchFields) {
      total
      results {
        data {
          status {
            id
            name
          }
          id
          name
        }
        company {
          name
          id
        }
      }
    }
  }
`;

export const REMOVE_ONE_OPPORTUNITY = gql`
  mutation RemoveOpportunities($id: Int!) {
    RemoveOpportunities(id: $id)
  }
`;
