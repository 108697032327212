import { Route, Routes } from 'react-router';
import CustomViewContainer from 'src/components/commons/customViewContainer';
import NotFoundPage from 'src/components/commons/notFound';
import Landing from 'src/components/landing';

const LandingRoutes = () => (
  <Routes>
    <Route
      index
      element={
        <CustomViewContainer>
          <Landing />
        </CustomViewContainer>
      }
    />
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

export default LandingRoutes;
