export const extractIds = (arr: string[]): number[] => {
  const regex = /{{%(\d+)%}}/;

  const ids = arr.map((item) => {
    const match = item.match(regex);
    return match ? parseInt(match[1], 10) : null;
  });

  // Use a Set to remove duplicates
  const uniqueIds = Array.from(
    new Set(ids.filter((companyID): companyID is number => companyID !== null)),
  );

  return uniqueIds;
};
