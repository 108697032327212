import { Button, Checkbox } from 'antd';
import { FC, useState } from 'react';
import PageContainer from 'src/components/pageContainer/PageContainer';
import StyleInfinityScroll from 'src/modules/settings/modules/subTables/styles/StyleInfinityScroll.style';
import { useCompanyContext } from 'src/context/company.context';
import useReassignContact from './useReassignContact';
import { CustomModal } from '../../../customModal';
// eslint-disable-next-line import/no-cycle
import SubTable from '../..';
import { showConfirmationMessage } from '../../helpers';

interface ReassignModalProps {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReassignModal: FC<ReassignModalProps> = ({ setOpenModal }) => {
  const {
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    columns,
    setColumns,
    viewportHeight,
    loadPage,
    dataSource,
  } = useReassignContact();
  const { company } = useCompanyContext();
  const [warningModal, setWarningModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState<any>({});

  const onCancel = () => {
    setOpenModal(false);
  };

  const handleRecord = (record: any) => {
    if (record.length === 0) return;
    setCurrentRecord(record[record.length - 1]);
    setWarningModal(true);
  };

  if (warningModal)
    showConfirmationMessage({
      title: `Are you sure you want to link ${currentRecord?.firstName} to ${company?.name}`,
      content: (
        <>
          <Checkbox /> Copy business fields of {company?.name}
        </>
      ),
      onOk: () => {
        setOpenModal(false);
      },
      onCancel: () => setWarningModal(false),
    });

  return (
    <CustomModal
      modalStatus
      onCancelAction={onCancel}
      onOkAction={() => 0}
      centered
      customClass='custom-modal-edit'
      closable
      width={1400}
      modalTitle={<h3 style={{ fontWeight: 'normal' }}>Reassign Contacts to {company?.name}</h3>}
      modalBody={
        <PageContainer margin='2rem'>
          <StyleInfinityScroll>
            <SubTable
              data={dataSource ?? []}
              onListRender={loadPage}
              columns={columns}
              setColumns={setColumns}
              onPaginationChange={handlePaginationChange}
              onFilterChange={handleFilterChange}
              onSortChange={handleSortModelChange}
              scroll={{ y: viewportHeight }}
              id='reassign-contacts-table'
              nonAddable
              actionCell={false}
              nonDeletable
              showOptionFilterLeft={false}
              onSelectRecord={handleRecord}
              nonEditable
              nonFilterable
              total
            />
          </StyleInfinityScroll>
        </PageContainer>
      }
      footer={
        <>
          <Button type='default' onClick={onCancel}>
            Cancel
          </Button>
          <Button type='primary' htmlType='submit' onClick={() => setWarningModal(true)}>
            Reassign
          </Button>
        </>
      }
      style={{ height: 'max-content' }}
    />
  );
};

export default ReassignModal;
