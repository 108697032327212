import { companyAdapter } from 'src/adapters';
import { useEffect } from 'react';
import {
  CREATE_CONTACT_GROUP,
  DELETE_CONTACT_GROUP,
  UPDATE_CONTACT_GROUP,
} from 'src/graphql/mutations/contactGroup';
import { GET_CONTACT_GROUP } from 'src/graphql/queries/contactGroup';
import { GET_COMPANIES_ELASTIC_2 } from 'src/components/company/queries';
import useEntityAdapter, { OperationSpecs } from '../useEntityAdapter';

const operations: OperationSpecs = {
  createOne: {
    op: CREATE_CONTACT_GROUP,
  },
  updateOne: {
    op: UPDATE_CONTACT_GROUP,
  },
  deleteOne: {
    op: DELETE_CONTACT_GROUP,
  },
  fetchAll: {
    op: GET_COMPANIES_ELASTIC_2,
    dataPath: 'GetCompaniesFromElastic2',
  },
  fetchOne: {
    op: GET_CONTACT_GROUP,
    dataPath: 'GetContactGroup',
  },
};

const useCompanyStore = () => {
  const api = useEntityAdapter({
    adapters: { entityAdapter: companyAdapter },
    operations,
    isPaginated: true,
    entityName: 'Company',
  });

  useEffect(() => {
    api.loadPage();
  }, []);

  return api;
};

export default useCompanyStore;
