import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Row, Upload, notification } from 'antd';
import useClientinfo from 'src/hooks/useClientInfo';
import { useMutation } from '@apollo/client';
import { UploadChangeParam, RcFile } from 'antd/es/upload';
import { UPLOAD_LOGO } from 'src/graphql/mutations/clientInformation';
import _ from 'lodash';

const ClientInformationCard = () => {
  const { logo } = useClientinfo();
  const [first, setFirst] = useState(false);
  const [uploadLogoMutation] = useMutation(UPLOAD_LOGO);

  const debouncedUpload = _.debounce((file: RcFile) => {
    if (file.size > 100 * 1024) {
      notification.error({
        message: 'Error',
        description: 'File size exceeds 100KB',
      });
      return;
    }
    uploadLogoMutation({
      variables: {
        file,
      },
    }).then(() => {
      setFirst(true);
      notification.success({
        message: 'File uploaded successfully',
      });
    });
  }, 300);

  const handleUpload = ({ file }: UploadChangeParam) => {
    const originFile = file.originFileObj as RcFile;
    if (originFile) {
      debouncedUpload(originFile);
    }
  };

  useEffect(() => {
    if (first) {
      setTimeout(() => {
        //  eslint-disable-next-line no-restricted-globals
        location.reload();
      }, 350);
    }
  }, [first]);

  return (
    <Col style={{ maxWidth: 300, padding: 24 }}>
      <Row justify='start' style={{ marginBottom: 16 }}>
        <div style={{ fontSize: 14 }}>Logo</div>
      </Row>
      <Row style={{ marginBottom: 24 }}>
        <Image src={logo} preview={false} style={{ width: 50, height: 50 }} key={logo} />
      </Row>
      <Row>
        <Upload
          name='logo'
          listType='picture'
          accept='image/png, image/jpeg'
          showUploadList={false}
          onChange={handleUpload}
        >
          <Button style={{ height: 40 }} type='primary'>
            Change Logo
          </Button>
        </Upload>
      </Row>
    </Col>
  );
};

export default ClientInformationCard;
