import { gql } from '@apollo/client';

const GET_ALL_PRODUCT_POTENTIAL = gql`
  query GetAllProductPotentials(
    $criteria: ProductPotentialCriteriaInput!
    $searchFields: FindProductPotentialInput
  ) {
    GetAllProductPotentials(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
        description
      }
    }
  }
`;

export default GET_ALL_PRODUCT_POTENTIAL;
