export function objectAreEquals(
  obj1: Record<string, any>,
  obj2: Record<string, any>,
  checkKeyLength = false,
): boolean {
  const keys1 = Object.keys(obj1 ?? {});
  const keys2 = Object.keys(obj2 ?? {});

  if (checkKeyLength && keys1.length !== keys2.length) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    if (checkKeyLength && !keys2.includes(key)) {
      return false;
    }

    const value1 = obj1[key];
    const value2 = obj2[key];

    if (
      Array.isArray(value1) &&
      value1.length === 0 &&
      Array.isArray(value2) &&
      value2.length > 0
    ) {
      return false;
    }

    if (Array.isArray(value1) && Array.isArray(value2)) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < value1.length; i++) {
        if (typeof value1 === 'object' && typeof value2 === 'object') {
          if (!objectAreEquals(value1, value2, checkKeyLength)) {
            return false;
          }
        } else if (value1[i] !== value2[i]) {
          return false;
        }
      }
    } else if (typeof value1 === 'object' && typeof value2 === 'object') {
      if (!objectAreEquals(value1, value2, checkKeyLength)) {
        return false;
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'object') {
      const dateTime = value2.toISOString();
      const [date, time] = dateTime.split('T');

      if (value1 !== date && time === '00:00:00.000Z') {
        return false;
      }

      if (value1 !== dateTime) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }

  return true;
}

const updateValueJavascriptObject = (obj: any, keys: string[], newValue: string) => {
  const cloned = JSON.parse(JSON.stringify(obj));
  let currentObj = cloned;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    // eslint-disable-next-line no-prototype-builtins
    if (currentObj.hasOwnProperty(key)) {
      currentObj = currentObj[key];
    } else {
      // Handle the case where the key path is not valid
      return;
    }
  }

  const targetKey = keys[keys.length - 1];
  // eslint-disable-next-line no-prototype-builtins
  if (currentObj.hasOwnProperty(targetKey)) {
    currentObj[targetKey] = newValue;
  }

  // eslint-disable-next-line consistent-return
  return cloned;
};

export default updateValueJavascriptObject;
