import _ from 'lodash';
import GenericForm from 'src/components/commons/genericForm';
import SectionCard from 'src/components/sectionCard/SectionCard';
import { CompaniesHeaderType2, CompanyHeaderOptionsType2 } from 'src/types/companiesTypes';
import { useExportCompaniesContext } from '../../contexts/export-companies.context';

const ExportCompaniesMoreFilters = () => {
  const { companyHeaders } = useExportCompaniesContext();

  const desiredDataIndices = [
    'companyType',
    'salesTeam',
    'category',
    'callPattern',
    'rank',
    'mainAddress.region',
  ];

  const fields = companyHeaders
    .filter(
      (header: CompaniesHeaderType2) =>
        desiredDataIndices.includes(header.dataIndex) && !header.hidden,
    )
    .map((header: CompaniesHeaderType2) => {
      const sortedOptions = header.options ? _.orderBy(header.options, ['name'], ['asc']) : [];
      return {
        label: header.headerName!,
        name: `searchFields.${header.searchField}`,
        type: 'multiSelect',
        options:
          sortedOptions?.map((option: CompanyHeaderOptionsType2) => ({
            value: option.name || option.productName!,
            label: option.name || option.productName!,
          })) ?? [],
      };
    });

  return (
    <SectionCard title='More Filters'>
      <GenericForm fields={fields} />
    </SectionCard>
  );
};
export default ExportCompaniesMoreFilters;
