import styled from 'styled-components';

const StyledMainContactCard = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 0.63rem;
  padding: 1rem 1.5rem;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  @media (max-width: 820px) {
    flex-direction: column;
  }
  .text-data {
    color: #1677ff !important;
    font-size: 1rem;
    display: blockquote;
  }
  .data-group-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .data-container {
      p {
        color: #858585;
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
      a {
        font-size: 1rem;
      }
      span {
        button {
          background-color: transparent;
          border: none;
          height: max-content;
          width: max-content;
          padding: 0;
          margin-left: 0.3rem;
          svg {
            fill: #e44f3b;
            font-size: 1rem;
          }
        }
      }
    }
  }
`;

export default StyledMainContactCard;
