import { omitBy, isNil } from 'lodash';
import dayjs from 'dayjs';

export const formattingData = (data: any) => {
  const updateStageInput = omitBy(
    {
      followUp: data?.followUp ? dayjs(data?.followUp).format('YYYY-MM-DD') : undefined,
      bidDate: data?.bidDate ? dayjs(data?.bidDate).format('YYYY-MM-DD') : undefined,
      cityId: data?.cityId,
      closeReasonId: data?.closeReasonId,
      closeStatusId: data?.closeStatusId,
      manufacturer: data?.manufacturer,
      manufacturerContact: data?.manufacturerContact,
      distributor: data?.distributor,
      contactsDistributor: data?.contactsDistributor,
      customer: data?.customer,
      customerContact: data?.customerContact,
      oppStage: data?.oppStage,
      estimatedCloseDate: data?.estimatedCloseDate
        ? dayjs(data.estimatedCloseDate).format('YYYY-MM-DD')
        : undefined,
      leadSourceId: data?.leadSource?.id || data?.leadSource,
      opportunityStageId: data?.opportunityStageId || data?.stageId,
      opportunityStatusId: data?.opportunityStatusId,
      ownerId: data?.oppOwner?.id || data?.ownerId,
      priority: data?.priority,
      productLineId: data?.productLineId,
      salesTeamId: data?.salesTeam?.id || data?.salesTeam,
      nextStep: data?.nextStep,
      stateId: data?.stateId,
      topic: data?.topic,
      typeId: data?.type?.id || data?.type,
      // watchers: data?.watchers,
      estAnnualQty: data?.estAnnualQty ? Number(data?.estAnnualQty) : undefined,
      notifyWatchers: data?.notifyWatchers,
      competitors: data?.competitors,
      notes: data?.notes,
      approvedBy: data?.approvedBy,
      registrationLevel: data?.registrationLevel,
      registrationNumber: data?.registrationNumber,
      reportingComments: data?.reportingComments,
      description: data?.description,
      expirationDate: data?.expirationDate
        ? dayjs(data.expirationDate).format('YYYY-MM-DD')
        : undefined,
      approvedDate: data?.approvedDate ? dayjs(data.approvedDate).format('YYYY-MM-DD') : undefined,
      value: data?.value ? Number(data?.value) : undefined,
      id: data.id,
    },
    isNil,
  );
  return updateStageInput;
};
