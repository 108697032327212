/* eslint-disable jsx-a11y/label-has-associated-control */
import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { FC, useState } from 'react';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import QuillInput from 'src/components/companyDetails/components/pitches/components/quillInput';
import FormSection from 'src/components/formSection';

type Props = { showTitle?: boolean };

const Wysiwyg: FC<{ onChange?: (date: string) => void }> = () => (
  <div className='wysiwyn-container'>
    <QuillInput />
  </div>
);

const Upload: FC<{ onChange?: (date: string) => void }> = () => {
  const [files, setFiles] = useState<File[] | undefined>(undefined);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (!selectedFile) return;
    setFiles((prev) => {
      if (prev) {
        return [...prev, selectedFile];
      }
      return [selectedFile];
    });
  };
  const filterFiles = (idx: number) => {
    if (!files) return;
    const copiedFiles = [...files].filter((_, ind) => ind !== idx);
    setFiles(copiedFiles);
  };
  return (
    <div className='button-container'>
      <label htmlFor='file-upload' className='custom-file-upload'>
        <UploadOutlined style={{ marginRight: '0.3rem' }} /> Upload
        <input
          type='file'
          id='file-upload'
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </label>
      {files?.map((file, idx) => (
        <div className='file-container'>
          <p>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <PaperClipOutlined /> <a>{file.name}</a>
          </p>
          <DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => filterFiles(idx)} />
        </div>
      ))}
    </div>
  );
};

const EmailFields: React.FC<Props> = ({ showTitle = false }) => {
  const fields: FieldType<any>[] = [
    {
      name: 'to',
      label: 'To',
      type: 'text',
      span: 24,
      rules: [{ required: true, message: 'This field is required' }],
    },
    {
      name: 'subject',
      label: 'Subject',
      type: 'text',
      span: 24,
      rules: [{ required: true, message: 'This field is required' }],
    },
    {
      name: 'message',
      type: 'wysiwyg',
      span: 24,
      render: ({ onChange }) => <Wysiwyg onChange={onChange!} />,
    },
    {
      name: 'files',
      type: 'text',
      span: 24,
      render: ({ onChange }) => <Upload onChange={onChange!} />,
    },
  ];

  if (!showTitle) {
    return <GenericForm fields={fields} />;
  }

  return (
    <FormSection title='Custom Fields'>
      <GenericForm fields={fields} />
    </FormSection>
  );
};

export default EmailFields;
