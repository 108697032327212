import {
  CREATE_PRODUCT_INTEREST,
  REMOVE_PRODUCT_INTEREST,
  UPDATE_PRODUCT_INTEREST,
} from 'src/modules/settings/modules/subTables/modules/contacts/tabs/productInterest/graphql/mutations';
import {
  GET_ALL_PRODUCT_INTEREST,
  GET_PRODUCT_INTEREST,
} from 'src/modules/settings/modules/subTables/modules/contacts/tabs/productInterest/graphql/queries';
import productInterestAdapter from 'src/adapters/productInterest.adapter';
import { ProductInterest } from 'src/modules/settings/modules/subTables/modules/contacts/tabs/productInterest/types';
import useEntityAdapter, { OperationSpecs } from '../useEntityAdapter';

const operations: OperationSpecs = {
  createOne: {
    op: CREATE_PRODUCT_INTEREST,
  },
  updateOne: {
    op: UPDATE_PRODUCT_INTEREST,
  },
  deleteOne: {
    op: REMOVE_PRODUCT_INTEREST,
  },
  fetchAll: {
    op: GET_ALL_PRODUCT_INTEREST,
    dataPath: 'GetAllProductInterest2',
  },
  fetchOne: {
    op: GET_PRODUCT_INTEREST,
    dataPath: 'GetProductInterest',
  },
};

const useProductInterestStore = () => {
  const api = useEntityAdapter<ProductInterest>({
    adapters: { entityAdapter: productInterestAdapter },
    operations,
    isPaginated: true,
    entityName: 'ProductInterest',
  });

  return api;
};

export default useProductInterestStore;
