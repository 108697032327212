import { QueryHookOptions, useQuery } from '@apollo/client';
import { GET_COMPANIES_DEPTH_HEADERS } from 'src/components/company/queries';

const useCompanyHeaders = (options?: Partial<QueryHookOptions>) => {
  const { data } = useQuery(GET_COMPANIES_DEPTH_HEADERS, options);

  return data?.GetCompaniesDepthGridHeader || [];
};

export default useCompanyHeaders;
