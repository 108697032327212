import { gql } from '@apollo/client';

export const GET_COMPANY_BY_TYPE_ID = gql`
  query GetCompaniesByType(
    $criteria: CompanyByTypeCriteriaInput!
    $searchFields: CompanyByTypeInput
  ) {
    GetCompaniesByType(Criteria: $criteria, SearchFields: $searchFields) {
      total
      results {
        id
        name
        mainAddress {
          id
          city
          pobox
          address
        }
      }
    }
  }
`;
