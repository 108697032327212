import { Button } from 'antd';
import styled from 'styled-components';

const StyledRowActionButton = styled(Button)`
  border: none !important;
  color: #858585;
  font-size: 18px;
  padding: 0;
`;

export default StyledRowActionButton;
