import { createGlobalStyle } from 'styled-components';

const SelectSearchStyles = createGlobalStyle`
  .multi-select-search-container {
    .ant-select-selector {
        border-radius: 6px;
    }
  }
`;

export { SelectSearchStyles };
