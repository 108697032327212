import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';

const addressToInputAdapter: AdapterFunc<GQL.AddressDbModel, GQL.CreateAddressInput> = (input) => ({
  address: input?.address,
  formattedAddress: input?.formattedAddress,
  city: input?.city,
  id: input?.id,
  country: input?.country?.id,
  state: input?.state?.id,
  addressType: input?.addressType?.id,
  geopositionLatitude: input?.geopositionLatitude,
  geopositionLongitude: input?.geopositionLongitude,
  territory: input?.territory?.id,
  pobox: input?.pobox,
  zipCode: input?.zipCode,
  region: input?.region?.id,
});

export default addressToInputAdapter;
