import React from 'react';
import { Row, Col, Form, Space, Typography, Input } from 'antd';
import CustomSelect from 'src/components/commons/customSelect';
import CustomButton from 'src/components/commons/customButton';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { PlusOutlined } from '@ant-design/icons';
import { FormPhoneElementsType, PhoneModel } from 'src/types/companiesTypes';
import { SPhoneFormInput } from '../genericForm/styles/phoneForm.styles';

interface CustomPhoneFormType {
  deleteIcon: React.ReactNode;
  elementData: FormPhoneElementsType;
  addNewFormElement?: () => void;
  deletePhoneAction?: (elementId: React.Key) => void;
  onInputChange?: (formId: React.Key, data: string, ext?: boolean) => void;
  phoneTypes?: { value: string; label: string }[];
  phoneTypeAction?: (elementId: React.Key, phoneType: string) => void;
  required?: boolean;
  // country?: string;
  expanded?: boolean;
  onBlurPhoneData?: (formId: React.Key, data: string) => void;
  phones?: PhoneModel;
}

interface PhoneInputDataType {
  countryCode: string;
  dialCode: string;
  format: string;
  name: string;
}

const CustomPhoneForm: React.FC<CustomPhoneFormType> = ({
  deleteIcon,
  elementData,
  addNewFormElement,
  deletePhoneAction,
  onInputChange,
  phoneTypes,
  phoneTypeAction,
  // country = 'us',
  required,
  expanded,
  onBlurPhoneData,
  phones,
}) => {
  const phoneDataHandler = (
    value: string,
    data: PhoneInputDataType,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (onInputChange) {
      const innerData = event.target.value;
      onInputChange(elementData.formId, innerData || `+${data.dialCode}`);
    }
  };

  const phoneTypeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    const phoneType = e as unknown as string;
    if (phoneTypeAction) {
      phoneTypeAction(elementData.formId, phoneType);
    }
  };

  const validator = async (phone?: string) => {
    if (!phone) {
      return Promise.reject(new Error('This phone is required'));
    }
    return Promise.resolve();
  };

  return (
    <Col span={24}>
      <Row align='middle' justify='space-between'>
        {elementData.formId === 'form-1' && (
          <Typography.Text style={{ marginRight: 10 }}>Main Phone</Typography.Text>
        )}
        {elementData.formId !== 'form-1' && (
          <CustomSelect
            style={{ width: 165, marginRight: 'auto' }}
            defaultValue='Select Phone Type'
            handleChange={phoneTypeHandler}
            options={phoneTypes ?? []}
            customClassName='no-border-radius-select'
            dataTestId='phone-type-select'
          />
        )}
        <Col span={6} style={{ marginRight: expanded ? 33 : 0 }}>
          <Row align='middle' justify='space-between'>
            <Typography.Text>Ext.</Typography.Text>
            {elementData.formId !== 'form-1' && (
              <CustomButton
                shape='circle'
                icon={deleteIcon}
                onlyIcon
                style={{ textAlign: 'end', paddingRight: 7 }}
                onClick={() => {
                  if (deletePhoneAction) {
                    deletePhoneAction(elementData.formId);
                  }
                }}
                dataTestId='delete-phone-button'
              />
            )}
          </Row>
        </Col>
      </Row>
      <SPhoneFormInput mainRecord={elementData.formId === 'form-1'}>
        <Form.Item
          style={{ marginBottom: 15 }}
          required={required}
          rules={[
            {
              required: true,
              message: 'this field is requiered',
            },
            {
              validator: () => validator(elementData.phone),
            },
          ]}
        >
          <Space.Compact style={{ width: '100%' }}>
            <PhoneInput
              isValid={elementData.validPhone}
              country='us'
              value={phones?.PHONE ?? elementData.formatedNumber}
              disabled={elementData.phoneType === ''}
              onChange={(value: string, data: PhoneInputDataType, event) => {
                phoneDataHandler(value, data, event);
              }}
              onBlur={(e) => {
                if (onBlurPhoneData) {
                  onBlurPhoneData(elementData.formId, e.target.value);
                }
              }}
            />
            <Col span={expanded ? 6 : 5}>
              <Input
                style={{ height: '32px' }}
                onChange={(e) => {
                  if (onInputChange) {
                    onInputChange(elementData.formId, e.target.value, true);
                  }
                }}
                disabled={elementData.phoneType === ''}
                data-testid={`ext-input-${elementData.formId}`}
                type='number'
                onKeyDown={(event) => {
                  if (!/[0-9]/.test(event.key) && event.key !== 'Backspace') {
                    event.preventDefault();
                  }
                }}
              />
            </Col>
            <CustomButton
              type='primary'
              icon={<PlusOutlined />}
              style={{ borderRadius: 0 }}
              onClick={addNewFormElement}
              onlyIcon
              dataTestId='add-new-phone-button'
              placement='topRight'
              tooltipColor='#CCCCCC'
              tooltipText='Add new phone'
              tooltipClassName='custom-tooltip'
              disabled={elementData.phoneType === ''}
            />
          </Space.Compact>
        </Form.Item>
      </SPhoneFormInput>
    </Col>
  );
};

export default CustomPhoneForm;
