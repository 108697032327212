import styled from 'styled-components';

const Tag = styled.span`
  background-color: #dcdffb;
  border: 1px solid #808bef;
  border-radius: 2px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 16px;
`;

export default Tag;
