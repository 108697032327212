import { Modal, ModalFuncProps } from 'antd';

const { confirm } = Modal;

const convertPropToNumber = (prop: string) => {
  const div = document.createElement('div');
  div.style.position = 'absolute';
  div.style.height = prop;
  document.body.appendChild(div);
  // get the computed value
  const computedHeight = window.getComputedStyle(div).height;
  // remove the div from the body
  document.body.removeChild(div);
  return parseInt(computedHeight, 10);
};

export const customRound = (number: number, decimalPlaces = 2): number => {
  if (!Number.isInteger(number) && number.toString().split('.')[1]?.length > decimalPlaces) {
    const factor: number = 10 ** decimalPlaces;
    const roundedNumber: number = Math.round(number * factor) / factor;
    return roundedNumber;
  }
  return number;
};

export const showConfirmationMessage = ({
  title = 'Are you sure you want to remove this record?',
  content = 'Any information linked to this record will be lost.  ',
  onOk,
  onCancel,
  cancelText,
  okButtonProps,
  cancelButtonProps,
}: Partial<ModalFuncProps>) => {
  confirm({
    centered: true,
    title,
    content,
    okText: 'Yes',
    cancelText: cancelText || 'No',
    type: 'warn',
    onOk,
    onCancel,
    okButtonProps: {
      'data-testid': 'confirmation-ok-button',
      ...okButtonProps,
    },
    cancelButtonProps: {
      'data-testid': 'confirmation-cancel-button',
      ...cancelButtonProps,
    },
  });
};

export default convertPropToNumber;
