import { gql } from '@apollo/client';

export const UPLOAD_LOGO = gql`
  mutation UploadLogo($file: Upload!) {
    UploadLogo(file: $file) {
      id
      tenantId
      logo
    }
  }
`;
