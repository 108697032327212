import { SubTableColumn } from 'src/components/commons/subTable/types';

export const COLUMN: SubTableColumn[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    editable: true,
    width: 160,
    unique: true,
    required: true,
    dataType: 'string',
    hidden: false,
    dataStructure: 'string',
    headerName: 'Name',
  },
];

export const PREDEFINED_STATUS_NEED_REVIEW = {
  name: 'Predefined Status',
  message: 'Needs Review',
};
