import { FC } from 'react';
import SubTableV1 from 'src/components/commons/subTableV1';

import { SubTableColumn, Option } from 'src/components/commons/subTableV1/types';
import PageContainer from 'src/components/pageContainer/PageContainer';

interface RightTableTypes {
  columns?: SubTableColumn[];
  addNewRecord?: (recordData: any) => void;
  editRecord?: (recordData: any) => void;
  deleteRecord?: (recordData: any) => void;
  localSearch?: (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => Promise<{ results: unknown[]; filter: Option[] }>;
  localSort?: (sortData: any) => void;
  selectedSideData?: any;
}

const RightTable: FC<RightTableTypes> = ({
  columns,
  addNewRecord,
  editRecord,
  deleteRecord,
  localSearch,
  localSort,
  selectedSideData,
}) => (
  <PageContainer>
    <SubTableV1
      data={selectedSideData?.data ?? []}
      columns={columns ?? []}
      columnFilterShown
      total
      nonSelectable
      showAutoIncrement
      actionCell={['delete']}
      scroll={{ y: '55vh', x: 'auto' }}
      postData={addNewRecord}
      editRecord={editRecord}
      onDeleteClick={deleteRecord}
      autocompleteFieldProvider={localSearch}
      onSortChange={localSort}
      allowGlobalEditActive
    />
  </PageContainer>
);

export default RightTable;
