import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';

const phoneToInputAdapter: AdapterFunc<GQL.PhoneDbModel, GQL.CreatePhoneInput> = (input) => ({
  phoneType: input?.phoneType?.id ?? 0,
  phone: input?.phone,
  id: input?.id,
  extension: input?.extension,
  areaCode: input?.areaCode,
  countryCode: input?.countryCode,
});

export default phoneToInputAdapter;
