import React from 'react';
import { useActivityJournalContext } from 'src/modules/activity/contexts/activityJournals.context';
import { FilterOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip } from 'antd';
import SubHeaderComponent from '..';
import CustomButton from '../../customButton';
import { StyledSubHeaderActionButton } from '../styles/subheader.styles';

const ActivitySubHeader = () => {
  const { setOpenCreateNew } = useActivityJournalContext();
  const total = // this was made as a button in order to comply with future requirements
    (
      <CustomButton
        type='text'
        title='Total: 15254'
        onClick={() => {}}
        style={{ backgroundColor: '#ffffff', color: '#5D5D5D' }}
        disabled
      />
    );

  const checkboxButton = <Checkbox>Only show my AJs</Checkbox>;

  const filterButton = (
    <Tooltip title='Filters' color='#fff' overlayInnerStyle={{ color: '#000' }}>
      <StyledSubHeaderActionButton
        type='primary'
        shape='circle'
        active={false}
        icon={<FilterOutlined />}
      />
    </Tooltip>
  );
  const reloadButton = (
    <Tooltip title='Update' color='#fff' overlayInnerStyle={{ color: '#000' }}>
      <StyledSubHeaderActionButton
        type='primary'
        shape='circle'
        active={false}
        onClick={() => {}}
        icon={<ReloadOutlined />}
      />
    </Tooltip>
  );
  const exportButton = (
    <Tooltip title='Export' color='#fff' overlayInnerStyle={{ color: '#000' }}>
      <StyledSubHeaderActionButton
        type='primary'
        shape='circle'
        active={false}
        onClick={() => {}}
        icon={<UploadOutlined />}
      />
    </Tooltip>
  );

  return (
    <SubHeaderComponent
      title='Activity Journal'
      hasAddButton
      handleAdd={() => setOpenCreateNew(true)}
      totalButton={total}
      actions={[checkboxButton, filterButton, reloadButton, exportButton]}
    />
  );
};

export default ActivitySubHeader;
