import React, { useEffect, useMemo, useRef, useState } from 'react';
import { VList, scrollTo } from 'virtuallist-antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { DeleteOutlined, EditOutlined, EyeOutlined, StarFilled } from '@ant-design/icons';
import { PredefinedTypesElasticTypes } from 'src/modules/settings/modules/subTables/modules/company/tabs/type/types';
import { Table, notification } from 'antd';
import { ResizeHandlerBuilder, SubTableColumn, SubTableGridProps } from '../types';
import convertPropToNumber from '../helpers';
import getEditableCellComponent from '../components/editableCell';
import getEditableRowComponent from '../components/editableRow';
import FilterableColumnTitle from '../components/filterableTitle';
import getActionsCellData from '../components/actionsCell';
import ResizableTitle from '../components/resizableTitle';
import { useSubTableContext } from '../contexts/subtable.context';
import { RecordType } from '../../createCompany/types';
import useNavigationPerformance from '../../../../hooks/useNavigationPerformance';

const makeClickable = (column: SubTableColumn) => ({
  ...column,
  onRow: (record: RecordType) => ({
    ...column,
    record,
  }),
});

const makeEditable = (column: SubTableColumn) =>
  ({
    ...column,
    onCell: (record: RecordType) => ({
      record,
      editable: column.editable,
      dataIndex: column.dataIndex,
      hasLink: column?.hasLink,
      dataKey: column.dataKey,
      title: column.title,
      render: column.render,
      // implement here the handleSave callback
    }),
  } as SubTableColumn);

const makeFilterable = (column: SubTableColumn) =>
  ({
    ...column,
    filterable: column.filterable,
    title: <FilterableColumnTitle column={column} />,
  } as SubTableColumn);

const saveScrollPosition = (id: string) => {
  const scrollViewport = document.querySelector(`#${id} .ant-table-body`);
  const scrollTop = scrollViewport?.scrollTop || 0;
  sessionStorage.setItem(`subtable-${id}-position`, JSON.stringify(scrollTop));
};

const getLastScrollPosition = (id: string) => {
  const scrollPosition = sessionStorage.getItem(`subtable-${id}-position`);
  return scrollPosition ? JSON.parse(scrollPosition) : 0;
};

const makeResizable = (handler: ResizeHandlerBuilder) => (column: SubTableColumn, index: number) =>
  ({
    ...column,
    onHeaderCell: (col: SubTableColumn) => ({
      ...column,
      width: col.width || column.width,
      onResize: handler(index),
    }),
  } as SubTableColumn);

const useTable = (props: SubTableGridProps) => {
  const vidRef = useRef(Math.random().toString(36));
  const wasEditedRef = useRef(false);
  const initializedUnsaved = useRef(false);
  const initializedData = useRef(false);
  const {
    scroll,
    components,
    columns,
    nonSelectable,
    nonDeletable,
    showAutoIncrement = false,
    pagination,
    total,
    page,
    pageSize,
    actionCell,
    deleteIcon = <DeleteOutlined />,
    favIcon = <StarFilled />,
    editIcon = <EditOutlined />,
    detailsIcon = <EyeOutlined />,
    onFavClick,
    onEditClick,
    onDeleteClick,
    onDetailsClick,
    onPaginationChange,
    allowGlobalEditActive,
    handleUpdateRows,
    postData,
    data,
    newRecord,
    resetNewRecord,
    saveNewRecord,
    dataToCompare,
    setColumns,
    contentOnDelete,
    titleOnDelete,
    keyExpandableRow,
    addWithModal,
    notEditWithModal,
    clickable,
    rowTooltip,
  } = props;

  const {
    setSelectedRows,
    dataTable,
    setDataTable,
    addNewRecord,
    setAddNewRecord,
    isEditing,
    storageKey,
    storageType,
    rowsEdited,
    setRowsEdited,
    onRowClick,
    setOpenModal,
    setRecord,
    setOptionSelectedFromSelect,
    record: recordFromContext,
    clickableRow,
    setClickableRow,
  } = useSubTableContext();

  const [tableColumns, setTableColumns] = useState<SubTableColumn[]>(columns);
  const [tableDataSource, setTableDataSource] = useState(dataTable);
  const lastListInfoRef = useRef({ start: 0, renderLen: 0 });
  const amountEnter = useRef(0);

  const { isReload, navigationType } = useNavigationPerformance();

  const handleResize: ResizeHandlerBuilder =
    (index) =>
    (e, { size }) => {
      setTableColumns((prevColumns) => {
        const nextColumns = [...prevColumns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const deleteNewRecord = () => {
    setAddNewRecord(false);
  };

  useEffect(() => {
    const cols = columns
      .map(makeClickable)
      .map(makeEditable)
      .map(makeFilterable)
      .map(makeResizable(handleResize));

    if (actionCell) {
      const { width, render } = getActionsCellData({
        actionCell,
        editIcon,
        deleteIcon,
        detailsIcon,
        favIcon,
        onFavClick,
        onEditClick,
        onDeleteClick,
        onDetailsClick,
        contentOnDelete,
        titleOnDelete,
        deleteNewRecord,
      });

      cols.push({
        title: '',
        dataIndex: '',
        key: 'x',
        fixed: 'right',
        width,
        render,
      });
    }

    setTableColumns(cols as []);
  }, [columns, nonDeletable]);

  useEffect(() => {
    if (addNewRecord) {
      resetNewRecord?.();
      setOptionSelectedFromSelect('');
      const recordObj: any = { ...newRecord, newRecord: true };
      if (dataTable !== undefined) {
        setTableDataSource([recordObj, ...dataTable]);
        if (document?.getElementsByClassName('ant-table-body').length === 2) {
          document
            ?.getElementsByClassName('ant-table-body')
            ?.item(1)
            ?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
          document
            ?.getElementsByClassName('ant-table-body')
            ?.item(0)
            ?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
      }
    } else {
      setTableDataSource(dataTable);
    }
    if (clickableRow === undefined || clickableRow === -1) {
      if (!dataTable?.[0]?.placeholder) {
        setClickableRow(dataTable?.[0]?.id || dataTable?.[0]?.ID || dataTable?.[0]?.key);
      }
    }
    if (recordFromContext) {
      if (!dataTable?.[0]?.placeholder) {
        setClickableRow(recordFromContext?.id || recordFromContext?.ID || recordFromContext?.key);
      }
    }
  }, [addNewRecord, dataTable, recordFromContext]);

  useEffect(() => {
    if (!addNewRecord) saveNewRecord?.();
  }, [addNewRecord]);

  useEffect(() => {
    if (newRecord && newRecord[`${keyExpandableRow}`]) {
      let recordObj: any = {};
      recordObj = { ...newRecord, newRecord: true };
      if (dataTable !== undefined) {
        setTableDataSource([recordObj, ...dataTable]);
      }
    } else if (newRecord && newRecord[`${keyExpandableRow}`] === null) {
      let recordObj: any = {};
      recordObj = { ...newRecord, newRecord: true };
      if (dataTable !== undefined) {
        setTableDataSource([recordObj, ...dataTable]);
      }
    }
  }, [newRecord, setTableDataSource]);

  useEffect(() => {
    if (newRecord?.autoSave) {
      amountEnter.current = 0;
      setAddNewRecord(false);
    }
  }, [newRecord, setAddNewRecord]);

  useEffect(() => {
    if (!isEditing) {
      handleUpdateRows?.(rowsEdited);
      setRowsEdited([]);
    }
  }, [isEditing]);

  // Listen to enter key press to save data(for autoSave)
  useEffect(() => {
    window.addEventListener('keypress', (e) => {
      if (e.key === 'Enter' && amountEnter.current === 0) {
        amountEnter.current += 1;
        postData?.({ autoSave: true });
      }
    });

    return window.removeEventListener('keypress', () => {});
  }, [amountEnter.current]);

  const height = useMemo(() => {
    if (scroll?.y) {
      if (typeof scroll.y === 'number') {
        return scroll.y;
      }
      if (typeof scroll.y === 'string') {
        return convertPropToNumber(scroll.y);
      }
    }
    return 0;
  }, [scroll]);

  const handleListRender = (listInfo: { start: number; renderLen: number }) => {
    const { onListRender } = props;

    if (
      lastListInfoRef.current.start === listInfo.start &&
      lastListInfoRef.current.renderLen === listInfo.renderLen
    ) {
      return;
    }

    if (onListRender) {
      if (initializedData.current) {
        saveScrollPosition(props.id || 'subtable');
      }
      onListRender(listInfo);
    }
    lastListInfoRef.current = listInfo;
  };

  const handleRowClick = (record: RecordType, index?: number) => {
    setClickableRow(record.id || record.ID || record.key);
    if (onRowClick) {
      onRowClick(record, index);
    }
  };

  const VComponents = useMemo(
    () =>
      !pagination
        ? VList({
            vid: vidRef.current,
            height,
            onListRender: handleListRender,
            resetTopWhenDataChange: false,
          })
        : {},
    [],
  );

  const handleDragEnd = (fromIndex: number, toIndex: number) => {
    if (!nonSelectable) {
      // eslint-disable-next-line no-param-reassign
      fromIndex -= 1;
      // eslint-disable-next-line no-param-reassign
      toIndex -= 1;
    }
    const visibleColumns = columns.filter((col) => col.selected !== false);
    const globalFromIndex = columns.findIndex((col) => col === visibleColumns[fromIndex])!;
    const globalToIndex = columns.findIndex((col) => col === visibleColumns[toIndex])!;
    const table = [...columns];
    const item = table.splice(globalFromIndex, 1)[0];
    table.splice(globalToIndex, 0, item);
    setColumns?.(table);
  };

  const addEditedRow = (editedRow: PredefinedTypesElasticTypes) => {
    const index = rowsEdited.findIndex((item) => editedRow.id === item.id);
    if (index !== -1) {
      const item = rowsEdited[index];
      rowsEdited.splice(index, 1, {
        ...item,
        ...editedRow,
      });
    } else {
      rowsEdited.push(editedRow);
    }
    setRowsEdited([...rowsEdited]);
  };

  const updateGridData = (editedRow: PredefinedTypesElasticTypes) => {
    const newData = [...(dataTable as any[])] as unknown as PredefinedTypesElasticTypes[];
    const index = newData.findIndex((item) => editedRow.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...editedRow,
    });
    setDataTable(newData);
  };

  const handleSave = (row: PredefinedTypesElasticTypes) => {
    updateGridData(row);
    addEditedRow(row);
  };

  const storage = useMemo(() => {
    if (!storageKey) {
      return null;
    }
    if (storageType === 'session') {
      return window.sessionStorage;
    }
    return window.localStorage;
  }, [storageType, storageKey]);

  useEffect(() => {
    setDataTable(data);
  }, [data]);

  useEffect(() => {
    if (isEditing) {
      wasEditedRef.current = true;
    } else if (wasEditedRef.current) {
      if (storageKey) {
        storage?.removeItem(storageKey);
      }
    }
  }, [isEditing]);

  useEffect(() => {
    if (isEditing) setAddNewRecord(false);
  }, [isEditing]);

  useEffect(() => {
    if (dataTable?.length && !initializedUnsaved.current) {
      initializedUnsaved.current = true;
      if (storage) {
        const unsavedSerialized = storage.getItem(storageKey!);
        if (unsavedSerialized) {
          try {
            const unsavedData = JSON.parse(unsavedSerialized);
            if (unsavedData) {
              let count = 0;
              Object.entries<Record<string, any>>(unsavedData).forEach(([key, value]) => {
                if (Object.keys(value).length) {
                  handleSave({ id: Number(key), ...value } as any);
                  count += 1;
                }
              });
              if (count > 0) {
                notification.warning({
                  message: 'Unsaved data',
                  description: 'You have unsaved data. Enter to edit mode to save them.',
                });
              }
            }
          } catch {
            storage.removeItem(storageKey!);
          }
        }
      }
    }
  }, [dataTable]);

  // Adding extra information to the onCell method
  const tableColumnsModified = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      width: col.dataIndex === 'companySplit' ? 170 : undefined,
      onCell: (record: SubTableColumn) => ({
        record,
        editable: col?.editable,
        dataIndex: col.dataIndex,
        title: col.headerName || col.title,
        dataKey: col.key,
        hasLink: col?.hasLink,
        dataType: col.dataType,
        options: col.options,
        unique: col?.unique,
        render: col?.render,
        handleSave,
        postData,
        newRecord,
        dataToCompare,
        data,
        tableColumns,
        onClick: () => {
          if (addWithModal && !notEditWithModal) {
            setOpenModal(true);
            if (!isEditing) setRecord(record);
          } else if (!isEditing) setRecord(record);
        },
      }),
    };
  });

  if (showAutoIncrement) {
    if (!nonSelectable) {
      tableColumnsModified.unshift(Table.SELECTION_COLUMN as any);
    }

    tableColumnsModified.unshift({
      width: 50,
      minWidth: 50,
      render(value, record, index) {
        return (
          <span
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: '#0078D4',
            }}
          >
            {index + 1}
          </span>
        );
      },
      title: ' ',
      dataIndex: undefined!,
    });
  }

  useEffect(() => {
    if (dataTable?.length && !initializedData.current) {
      setTimeout(() => {
        if (navigationType === 'POP' || isReload) {
          const lastScrollPosition = getLastScrollPosition(props.id || 'subtable');
          initializedData.current = true;
          scrollTo({ y: lastScrollPosition });
        }
      }, 100);
    }
  }, [dataTable]);

  const TrComponent = VComponents?.body?.row || 'tr';
  const TdComponent = VComponents?.body?.cell || 'td';

  const EditableRow = components?.body?.row
    ? components.body.row(TrComponent)
    : getEditableRowComponent(TrComponent, !!rowTooltip);

  const EditableCell = components?.body?.cell
    ? components.body.cell(TdComponent)
    : getEditableCellComponent(TdComponent, !!allowGlobalEditActive);

  const tableComponents = {
    ...VComponents,
    header: {
      ...VComponents.header,
      cell: ResizableTitle,
    },
    body: {
      ...VComponents.body,
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const rowSelection: TableRowSelection<RecordType> | undefined =
    nonSelectable && !props.selectedRows
      ? undefined
      : {
          type: nonSelectable ? 'radio' : 'checkbox',
          onChange: (_, rows) => {
            setSelectedRows(rows);
          },
          onSelect: (record) => {
            if (onRowClick) {
              onRowClick(record);
            }
          },
          fixed: 'left',
          columnWidth: clickable ? 4 : 50,
          selectedRowKeys: clickable ? [clickableRow] : undefined,
        };

  const paginationSetup = pagination
    ? {
        total: typeof total === 'number' ? total : dataTable?.length || 0,
        pageSizeOptions: [10, 25, 50, 100],
        defaultPageSize: 25,
        showSizeChanger: true,
        hideOnSinglePage: false,
        position: ['bottomCenter'] as unknown as [],
        pageSize,
        current: page,
        onChange: (p: number, s: number) => {
          if (onPaginationChange) {
            onPaginationChange(p, s);
          }
        },
      }
    : (false as const);

  return {
    components: tableComponents,
    columns: tableColumnsModified,
    dataSource: tableDataSource,
    rowSelection,
    total: total || dataTable?.length || 0,
    pagination: paginationSetup,
    onRow: (record: RecordType, index?: number) => ({
      onClick: () => handleRowClick(record, index),
    }),
    handleDragEnd,
    setClickableRow,
  };
};

export default useTable;
