import { gql } from '@apollo/client';

export const GET_ALL_REGION_REGION = gql`
  query GetAllRegions2($criteria: RegionCriteriaInput!, $searchFields: FindRegionInput) {
    GetAllRegions2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
      }
      total
    }
  }
`;

export const GET_REGION_HEADERS = gql`
  query Query {
    GetRegionGridHeaders
  }
`;
