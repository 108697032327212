import { faker } from '@faker-js/faker';
import useInfinityDataSource, { FetchFunc } from '../useInfinityDataSource';

const fakeLoadCannedNotes: (size: number) => any[] = (size: number) =>
  Array.from({ length: size }).map(() => ({
    id: Math.random() + Math.random(),
    label: faker.lorem.paragraph(),
    required: Math.random() < 0.5,
    disabled: Math.random() < 0.5,
  }));

const useFieldSettingsStore = () => {
  const fetchCannedNotes: FetchFunc = async ({ renderLen, start }) => {
    const size = start + renderLen > 120 ? 120 - start : renderLen;
    return {
      total: 120,
      results: fakeLoadCannedNotes(size),
    };
  };
  const { dataSource, onListRender, setDataSource, reset } =
    useInfinityDataSource(fetchCannedNotes);

  const deleteBulk = (ids: number[]) => {
    setDataSource((prev) => prev.filter((data) => !ids.includes(data.id)));
  };

  const deleteOne = (id: number) => {
    setDataSource((prev) => prev.filter((data) => id !== data.id));
  };

  const updateOne = (data: any) => {
    setDataSource((prev) => prev.map((item) => (item.id === data.id ? data : item)));
  };

  const createOne = (data: Partial<any>) => {
    data.id = Math.random() + Math.random();
    setDataSource((prev) => [data, ...prev]);
  };

  const api = {
    dataSource,
    loadPage: onListRender,
    reset,
    deleteBulk,
    deleteOne,
    updateOne,
    createOne,
  };

  return api;
};

export default useFieldSettingsStore;
