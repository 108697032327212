import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { PrivateTeamModel } from 'src/models';
import { optional } from './common';
import privateTeamTypeAdapter from './privateTeamType.adapter';

const privateTeamAdapter: AdapterFunc<GQL.PrivateTeamModel, PrivateTeamModel> = (input) => ({
  id: input.id,
  name: input.name!,
  type: optional(privateTeamTypeAdapter, input.type),
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
});

export default privateTeamAdapter;
