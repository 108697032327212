import React from 'react';
import CompaniesSubHeader from 'src/components/commons/subHeader/company2';
import PageContainer from 'src/components/pageContainer/PageContainer';
import CompaniesTable from '../components/table';

const CompaniesContainer = () => (
  <div>
    <PageContainer>
      <CompaniesSubHeader />
      <CompaniesTable />
    </PageContainer>
  </div>
);

export default CompaniesContainer;
