import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import GlobalSearch from './components/globalSearch';
import InfinityScroll from './components/infinityScroll';

interface SideSelectableComponentProps {
  data?: any;
  total?: boolean | number;
  onSearch?: (value: string) => void;
  handleFetchAll: () => void;
  setSelectedSideData: (record: any) => void;
  autoCompleteGlobalProvider?: any;
  onClear?: any;
}

const SideSelectableComponent: React.FC<SideSelectableComponentProps> = ({
  data,
  total,
  onSearch,
  handleFetchAll,
  setSelectedSideData,
  autoCompleteGlobalProvider,
  onClear,
}) => {
  const totalForShow = useMemo(() => {
    if (total === true) {
      return data?.total;
    }

    return total;
  }, [total, data]);

  return (
    <Col>
      <Row
        style={{ position: 'relative', zIndex: 10, paddingBottom: 10 }}
        data-testid='subtable-header'
      >
        <Col style={{ display: 'flex', gap: 16, alignItems: 'center', flex: '1' }}>
          <GlobalSearch
            autoCompleteGlobalProvider={autoCompleteGlobalProvider}
            placeholder='Search'
            onSearch={onSearch}
            onClear={onClear}
          />
        </Col>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            paddingLeft: 10,
          }}
        >
          <span style={{ whiteSpace: 'nowrap' }} data-testid='sutable-header-total'>
            <span style={{ fontSize: 16 }}>{totalForShow} </span>
            <span style={{ color: '#858585' }}>in total</span>
          </span>
        </Col>
      </Row>
      <Col>
        <InfinityScroll
          handleFetchAll={handleFetchAll}
          data={data}
          total={data?.results?.length}
          setSelectedSideData={setSelectedSideData}
        />
      </Col>
    </Col>
  );
};

export default SideSelectableComponent;
