import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { StageModel } from '../models/stage.models';

export const stageAdapter: AdapterFunc<GQL.SubTableByCompanyElasticResponse, StageModel> = (
  input,
) => ({
  id: input.id!,
  company: input.company!,
  data: input.data!,
});
