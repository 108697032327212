import { Popover, PopoverProps } from 'antd';
import { FC } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import {
  CenteredPopoverBody,
  CenteredPopoverContent,
  CenteredPopoverHeader,
} from './styles/centeredPopoverContent.style';

type Props = {
  message: string;
  title: string;
} & PopoverProps;

const CenteredPopover: FC<Props> = ({ message, title, ...props }) => (
  <Popover
    {...props}
    overlayInnerStyle={{ padding: 0, borderRadius: 2 }}
    content={
      <CenteredPopoverContent>
        <CenteredPopoverHeader>
          <CloseCircleOutlined />
          <span>{title}</span>
        </CenteredPopoverHeader>
        <CenteredPopoverBody>{message}</CenteredPopoverBody>
      </CenteredPopoverContent>
    }
  />
);

export default CenteredPopover;
