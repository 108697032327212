import { FC } from 'react';
import ContactsCompaniesExpanded from './contactsCompaniesExpanded/contactsCompaniesExpandedTable';
import ContactsContextProvider from '../../../modules/contacts/contexts/contacts.contexts';

const ContactCompaniesExpandedInfo: FC = () => (
  <ContactsContextProvider>
    <ContactsCompaniesExpanded />
  </ContactsContextProvider>
);

export default ContactCompaniesExpandedInfo;
