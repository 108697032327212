import styled from 'styled-components';

const SUserInfo = styled.section`
  margin: 0 1.5rem 0 3.13rem;
  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 2rem 0 1rem;
  }
  .divider {
    height: 1px;
    width: 100%;
    background-color: #c6c4c4;
    margin: 2rem 0 1rem;
  }
  .ant-btn-default {
    background-color: #0078d4;
    color: #fff;
    font-size: 14px;
    margin-top: 1.25rem;
    width: max-content;
    border-radius: 2px;
  }
`;
export default SUserInfo;
