import { useEffect, useState } from 'react';
import { SortModel } from 'src/components/commons/subTable/types';
import useFieldSettingsStore from 'src/hooks/stores/useFieldSettingsStore';
import { useFielSettingsHeaders } from 'src/hooks/useFieldSettingsHeaders';
import { CannedNoteModel } from 'src/models/cannedNote.model';
import PageInfo from '../../../subTables/types';

const useFieldSettings = () => {
  const { dataSource, loadPage, deleteBulk, updateOne, createOne, reset } = useFieldSettingsStore();
  const { columns, setColumns } = useFielSettingsHeaders();
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [newRecord, setNewRecord] = useState({ note: '' });
  const viewportHeight = window.innerHeight - 275;

  const handleFilterChange = (filter: any) => {
    console.log(filter);
  };

  const handleDeleteRows = (rows: CannedNoteModel[]) => {
    deleteBulk(rows.map((row) => row.id));
  };

  const handleEditRows = (rows: CannedNoteModel[]) => {
    rows.forEach((row) => updateOne(row));
  };

  const autocompleteFieldSearch = async (
    field: string,
    search: string,
    dataType: string | number | string[],
    dataIndex?: string,
  ) => {
    const lowercased = search.toLowerCase();
    const filtered = dataSource
      .filter((row: CannedNoteModel) => row.note?.toLowerCase()?.includes(lowercased))
      .splice(0, 200);
    return {
      results: filtered,
      filter: filtered.map((row) => ({
        value: row[dataIndex || field],
        label: row[dataIndex || field],
      })),
    };
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({ note: '' });
  };

  const createNewRecord = async () => {
    const { note } = newRecord;
    if (!note) return;
    await createOne({ note });
    resetNewRecord();
  };

  const dataModule = [
    {
      label: 'Company',
      value: 'company',
    },
    {
      label: 'Contacts',
      value: 'contacts',
    },
    {
      label: 'Opportunities',
      value: 'opportunities',
    },
  ];

  const filterByModule = (search: string) => {
    console.log(search);
    reset();
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  useEffect(() => {
    loadPage({ start: 0, renderLen: 120 });
  }, []);

  return {
    viewportHeight,
    reset,
    columns,
    dataSource,
    newRecord,
    resetNewRecord,
    loadPage,
    setColumns,
    handleFilterChange,
    handleDeleteRows,
    handleEditRows,
    autocompleteFieldSearch,
    postData,
    createNewRecord,
    dataModule,
    filterByModule,
    handleSortModelChange,
  };
};

export default useFieldSettings;
