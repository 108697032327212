import { gql } from '@apollo/client';

export const CREATE_LEAD_SORCE = gql`
  mutation CreateLeadSource($createLeadSourceInput: CreateLeadSourceInput!) {
    CreateLeadSource(createLeadSourceInput: $createLeadSourceInput) {
      boolean
      id
      name
    }
  }
`;

export const UPDATE_LEAD_SOURCE = gql`
  mutation UpdateLeadSource($updateLeadSourceInput: UpdateLeadSourceInput!) {
    UpdateLeadSource(updateLeadSourceInput: $updateLeadSourceInput) {
      boolean
      id
      name
    }
  }
`;
export const REMOVE_LEAD_SOURCE = gql`
  mutation RemoveLeadSource($id: Int!) {
    RemoveLeadSource(id: $id)
  }
`;
