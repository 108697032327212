// eslint-disable-next-line import/no-unresolved
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
    margin: 0;
    padding: 0;
    font-family: 'Libre Franklin';
    src: local('Libre Franklin'),
      url('../fonts/LibreFranklin-Light.ttf') format('truetype');
  }
  main{
    background-color: #ffffff;
  }
  .dont-show .ant-select-selection-item {
    display: none !important;
  }
  .ant-table-content{
    color: #858585;

  }
  .ant-table-wrapper .ant-table {
  background-color: transparent;
  color: #858585;
}
.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
  width: 5%;
  padding-top: 52px;
}

.custom-dropdown-render-container {
  .ant-select-item-option-content {
    padding: 4px 10px;
  }
}

.customSelect .ant-select-selector {
  border-radius: 6px !important;
}

.ant-form-item-control-input .ant-select-selector {
  border-radius: 6px !important;
}

.ant-form-item-control-input .ant-picker {
  border-radius: 6px !important;
}

.ant-form-item-control-input .ant-input {
  border-radius: 6px !important;
}

.ant-form-item-control-input .react-tel-input {
  border-radius: 6px !important;
  .flag-dropdown {
    height: 32px;
  }
}

.successModal {
  top: 40%;
  width: 170px !important;
  height: 100px;
  .ant-modal-content {
    background-color: #dcfddc;
    border: 2px solid #82C785;
    padding: 5px 5px 5px 5px;
    .ant-modal-confirm-paragraph {
      row-gap: 1px;
    }
  }
}

.ant-form-item-control-input {
  .ant-btn {
    height: 32px;
    width: 32px !important;
    line-height: 0.5px;
  }
}


.ant-form-item-control-input .ant-input-affix-wrapper {
  border-radius: 6px !important;
}

.customSelect.ant-select-open .ant-select-selector {
  border-radius: 6px !important;
}

.custom-add-new-button {
  width: 100%;
  border-bottom: 1px solid #E4E4E4;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  background-color: transparent !important;
  border-radius: 0 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px;
  color: #0078D4;
  font-weight: 600;
  box-shadow: none;
  cursor: pointer;
  .ant-select-item-option-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;
    color: #0078D4;
    border-radius: 0;
    font-weight: 600;
  }
  &:hover {
    color: #0078D4 !important;
    border-color: #E4E4E4 !important;
  }
  &:active {
    color: #0078D4 !important;
    border-color: #E4E4E4 !important;
  }
}

.ant-select-item-option-state {
  display: none !important;
}

.pac-container {
  z-index: 1090 !important
}

.ant-notification-center {
  left: 42%;
  right: 18% !important;
  top: 45% !important;
  bottom: 50% !important;
  transform: translate(-50%, -50%);
}

.ant-table-wrapper .ant-table-thead > tr > th {
  &:nth-child(17) {
    background: transparent;
 };
}

.ant-select-dropdown {
  z-index: 1090;
}

.ant-input-group-addon:has(button.add-company-button) {
  background-color: rgb(0, 120, 212) !important;
  padding: 0 !important;
  border-radius: 0 !important;
  border: none !important
}

.ant-select-selection-overflow-item-rest {
  .ant-select-selection-item {
    background: none !important;
    border: none !important
    padding-left: 0px !important;
    padding-right: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

input {
  caret-color: #0078D4;
}

.ant-table-wrapper .ant-table-filter-trigger.active{
  display: none;
}



.ant-table-wrapper .ant-table-ping-right .ant-table-cell-fix-right-first::after{
  box-shadow: none;
}
.ant-table-wrapper .ant-table-thead >tr>td{
  background: white !important;
  border-bottom: 1px solid black;
}
.ant-table-wrapper .ant-table-thead >tr>th{
  font-weight: 500 !important;
}
.ant-picker-dropdown {
    z-index: 1070;
  }
.ant-modal-root {
  .ant-modal-wrap {
    z-index: 1070;
  }
  .ant-modal-mask {
    z-index: 1060;
  }
}
.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child
.ant-checkbox-inner {
  width: 22px !important;
  height: 22px !important;
  border: 1px solid #C6C4C4 !important;
  border-radius: 5px !important; 
}
.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #ffffff;
  position: sticky;
  top: 0;
}
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

*::-webkit-scrollbar-thumb {
  background: #858585;
  border: 1px solid #858585;
  border-radius: 5px;
  transition: .5s;
}

*::-webkit-scrollbar-thumb:hover {
  background: #858585;
}

*::-webkit-scrollbar-thumb:active {
  background: #858585;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 0;
}

*::-webkit-scrollbar-track:hover {
  background: #ffffff;
}

*::-webkit-scrollbar-track:active {
  background: #ffffff;
}

*::-webkit-scrollbar-corner {
  background: transparent;
}
.ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: #D9D9D9;
  top: 69%;
}

.ant-table-pagination-right {
  display: none !important;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected {
  background-color: #E4E4E4 !important;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-table-wrapper .ant-table-column-sorter {
  margin-top: auto;
  margin-bottom: 3px;
  margin-inline-start: 7px !important;
}

.ant-modal-content {
  border-radius: 10px !important;
}

.confirmModal {
  .ant-modal-confirm-content {
    position: relative;
    left: -34px;
    width: 110%;
    padding-bottom: 10px;
  }
}
.ant-btn-primary {
  background-color: #0078D4;
}

.ant-btn-text {
  border: 1px solid #0078D4;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 5px 12px;
  border: 1px solid #0078D4;
  border-radius: 2px;
}

.ant-select-item {
  padding: 0px !important;
  .ant-select-item-option {
    padding: 0px !important;
  }
}

.editable-row .editable-cell-active {
  padding: 5px 12px;
  border: 1px solid #0078D4;
  border-radius: 2px;
}


.no-border-radius-select .ant-select-selector {
  border-radius: 0
}

.no-border-input .ant-select-selector {
  border-radius: 0
}

.no-editable {
  color: #85858542 !important
}

.custom-tooltip .ant-tooltip-inner {
  color: #20201f;
  background-color: #cccccc;
  font-size: 8;
}

.custom-tooltip .ant-tooltip-arrow-content .ant-tooltip-arrow {
  color: #20201f;
  background-color: #cccccc;
  font-size: 8;
}

.custom-modal .ant-modal-content {
  padding: 0;
}

.custom-modal .ant-modal-footer {
  margin-top: 0;
}

.custom-message .ant-message-notice-content {
  max-width: 400px;
}

.custom-message .ant-message-custom-content {
  display: flex;
  align-items: flex-start;
}

.custom-message .custom-message-title {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #52C41A;
  margin-bottom: 8px;
  text-align: left;
}

.custom-message .custom-message-body {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  text-align: left;
}

.custom-message [role=img] {
  margin-top: 6px;
}
.ant-table-tbody > tr > td {
  padding: 0 2px !important;
}
.ant-table-thead > tr > th {
  padding: 0 2px !important;
}
.ant-tooltip-inner {
  width: max-content;
}

:where(.css-dev-only-do-not-override-1m62vyb)[class^="ant-typography"], :where(.css-dev-only-do-not-override-1m62vyb)[class*=" ant-typography"] {
  font-size: inherit;
  font-family: inherit;
}

.readOnly-ant-multisel .ant-select-selector {
  background: transparent !important;
  cursor: default !important;
}
.readOnly-ant-multisel .ant-select-selector .ant-select-selection-overflow-item .ant-select-selection-item {
  color: rgb(0 0 0 / 88%) !important;
  cursor: default !important;
}
.readOnly-ant-multisel .ant-select-arrow {
  display: none !important;
}

.readOnly-datePicker {
  background: transparent !important;
  cursor: default !important;
}

.readOnly-datePicker .ant-picker-input input {
  color: rgb(0 0 0 / 88%) !important;
  cursor: default !important;
}

.ant-custom-card-body .ant-card-body {
  padding: 24px 10px;
}

.rf-content {
  &.rf-align-cards {
    .section-card.ant-card .ant-card-body {
      margin-left: -1.5rem;
    }
  }

  .section-card.ant-card {
    padding: 16px 24px 16px 48px;
    background: #ffffff;
    border: 1px solid #c6c4c4;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .ant-card-head {
      border-bottom: none;
      min-height: 0;
      padding: 0;

      .ant-card-head-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #4b4b4b;
      }
    }

    .ant-card-body {
      padding: 0;
    }

    .anticon-holder {
      position: absolute;
      top: 19px;
      left: 12px;
      font-size: 20px;
      color: #4b4b4b;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    .ant-collapse {
      border: none;
    }

    .ant-collapse-item {
      border-bottom: none;
    }

    .ant-collapse-header {
      display: none;
    }

    .ant-collapse-content {
      border-radius: 0;
      border-top: none;
    }

    .ant-collapse-content-box {
      padding: 24px 0 0 0;
    }

    .ant-form-item {
      margin-bottom: 12px;
    }

    .ant-form-item-required {
      flex-direction: row-reverse;
    }
  }
}

.ant-progress-text {
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
  /* Body/regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px
}

.new-company-modal-title {
  color: rgb(0, 10, 104) !important;
}

#canned-notes-table .virtuallist .ant-table-tbody>tr>td>div {
  white-space: normal;
}
`;

export default GlobalStyles;
