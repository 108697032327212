import { SubTableColumn } from 'src/components/commons/subTable/types';

export const formFieldTypes = {
  text: 'Short Text',
  checkbox: 'Checkbox',
  decimal: 'Decimal',
  integer: 'Integer',
  date: 'Date',
  date_time: 'Datetime',
  textarea: 'Large Text',
  hyperlink: 'Hyperlink',
  dropdown: 'Dropdown',
  multi_select_dropdown: 'Multiselect Dropdown',
};

const COLUMNS: SubTableColumn[] = [
  {
    key: '',
    title: 'DEFAULT LABEL',
    dataIndex: 'name',
    editable: true,
    width: 200,
    minWidth: 200,
    dataType: 'string',
    unique: true,
    required: true,
    hidden: false,
    dataStructure: 'string',
    headerName: 'DEFAULT LABEL',
    options: [],
    orderField: 'name',
    searchField: 'name',
    filterable: true,
  },
  {
    key: '',
    title: 'TYPE',
    dataIndex: 'dataType',
    editable: true,
    width: 200,
    minWidth: 150,
    dataType: 'dropdown',
    unique: false,
    required: false,
    hidden: false,
    dataStructure: 'dropdown',
    headerName: 'TYPE',
    options: [
      {
        LABEL: 'Short Text',
        VALUE: 'Short Text',
      },
      {
        LABEL: 'Checkbox',
        VALUE: 'Checkbox',
      },
      {
        LABEL: 'Decimal',
        VALUE: 'Decimal',
      },
      {
        LABEL: 'Integer',
        VALUE: 'Integer',
      },
      {
        LABEL: 'Date',
        VALUE: 'Date',
      },
      {
        LABEL: 'Datetime',
        VALUE: 'Datetime',
      },
      {
        LABEL: 'Large Text',
        VALUE: 'Large Text',
      },
      {
        LABEL: 'Hyperlink',
        VALUE: 'Hyperlink',
      },
      {
        LABEL: 'Dropdown',
        VALUE: 'Dropdown',
      },
      {
        LABEL: 'Multiselect Dropdown',
        VALUE: 'Multiselect Dropdown',
      },
    ],
    orderField: 'dataType',
    searchField: 'dataType',
    filterable: true,
  },
  {
    key: 'options',
    title: 'VALUES',
    dataIndex: 'dataStructure',
    editable: true,
    width: 300,
    minWidth: 200,
    dataType: 'string',
    unique: false,
    required: false,
    hidden: false,
    dataStructure: 'string',
    headerName: 'VALUES',
    options: [],
    orderField: 'dataStructure.options',
    searchField: 'dataStructure.options',
    filterable: true,
  },
  {
    key: '',
    title: 'DATA MODULE',
    dataIndex: 'module',
    editable: true,
    width: 150,
    minWidth: 200,
    dataType: 'dropdown',
    unique: false,
    required: false,
    hidden: false,
    dataStructure: 'string',
    headerName: 'DATA MODULE',
    options: [
      {
        LABEL: 'Companies',
        VALUE: 'Company',
      },
      {
        LABEL: 'Contacts',
        VALUE: 'Contact',
      },
      {
        LABEL: 'Opportunities',
        VALUE: 'Opportunity',
      },
    ],
    orderField: 'module',
    searchField: 'module',
    filterable: true,
  },
  {
    key: 'selected',
    title: 'DEFAULT',
    dataIndex: 'dataStructure',
    editable: true,
    width: 200,
    minWidth: 200,
    dataType: 'string',
    unique: false,
    required: false,
    hidden: false,
    dataStructure: 'string',
    headerName: 'DEFAULT',
    options: [],
    orderField: 'dataStructure.selected',
    searchField: 'dataStructure.selected',
    filterable: true,
  },
  {
    key: 'required',
    title: 'MANDATORY',
    dataIndex: 'dataStructure',
    editable: true,
    width: 200,
    minWidth: 200,
    dataType: 'checkbox',
    unique: false,
    required: false,
    hidden: false,
    dataStructure: 'checkbox',
    headerName: 'MANDATORY',
    options: [],
    orderField: 'dataStructure.required',
    searchField: 'dataStructure.required',
    filterable: true,
  },
];

export default COLUMNS;
