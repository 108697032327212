import { ContactModel } from 'src/models';
import _ from 'lodash';

export const prepareContactCreationPayload = (contact: ContactModel) => {
  const innerContactData = JSON.parse(JSON.stringify(contact));
  // businessInfo
  const innerBusinessInfoAddress = innerContactData.businessInfo?.addresses;
  const innerBusinessInfoEmails = innerContactData.businessInfo?.emails;
  const innerBusinessInfoPhones = innerContactData.businessInfo?.phones;
  // // personalInfo
  const innerPersonalInfoAddress = innerContactData.personalInfo?.addresses;
  const innerPersonalInfoEmails = innerContactData.personalInfo?.emails;
  const innerPersonalInfoPhones = innerContactData.personalInfo?.phones;

  if (innerBusinessInfoAddress) {
    const filteredInnerBusinessInfoAddress = _.filter(
      innerBusinessInfoAddress,
      (obj: any) => obj.address,
    );
    innerContactData.businessInfo.addresses.shift();
    if (filteredInnerBusinessInfoAddress.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      innerContactData.businessInfo.mainAddress = filteredInnerBusinessInfoAddress[0];
    }
    if (innerContactData.businessInfo?.addresses?.length === 0) {
      delete innerContactData.businessInfo.addresses;
    }
  }
  if (innerBusinessInfoEmails) {
    const filteredInnerBusinessInfoEmails = _.filter(
      innerBusinessInfoEmails,
      (obj: any) => obj.email.length > 0,
    );
    innerContactData.businessInfo.emails.shift();
    if (filteredInnerBusinessInfoEmails.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      innerContactData.businessInfo.mainEmail = filteredInnerBusinessInfoEmails[0];
    }
    if (innerContactData.businessInfo?.emails?.length === 0) {
      delete innerContactData.businessInfo.emails;
    }
  }
  if (innerBusinessInfoPhones) {
    const filteredInnerBusinessInfoPhones = _.filter(
      innerBusinessInfoPhones,
      (obj: any) => obj.phone,
    );
    innerContactData.businessInfo.phones.shift();
    if (filteredInnerBusinessInfoPhones.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      innerContactData.businessInfo.mainPhone = filteredInnerBusinessInfoPhones[0];
    }
    if (innerContactData.businessInfo?.phones?.length === 0) {
      delete innerContactData.businessInfo.phones;
    }
  }

  if (innerPersonalInfoAddress) {
    const filteredInnerPersonalInfoAddress = _.filter(
      innerPersonalInfoAddress,
      (obj: any) => obj.address,
    );
    delete innerContactData.personalInfo.addresses;
    if (filteredInnerPersonalInfoAddress.length > 0) {
      filteredInnerPersonalInfoAddress.forEach((address: any) => {
        if (address.id) {
          if (!innerContactData.personalInfo.updateAddresses) {
            innerContactData.personalInfo.updateAddresses = [address];
          } else {
            innerContactData.personalInfo.updateAddresses.push(address);
          }
        } else if (!innerContactData.personalInfo.addAddresses) {
          innerContactData.personalInfo.addresses = [address];
        } else {
          innerContactData.personalInfo.addAddresses.push(address);
        }
      });
    }
  }
  if (innerPersonalInfoEmails) {
    const filteredInnerPersonalInfoEmails = _.filter(
      innerPersonalInfoEmails,
      (obj: any) => obj.email,
    );
    delete innerContactData.personalInfo.emails;
    if (filteredInnerPersonalInfoEmails.length > 0) {
      filteredInnerPersonalInfoEmails.forEach((email: any) => {
        if (email.id) {
          if (!innerContactData.personalInfo.updateEmails) {
            innerContactData.personalInfo.updateEmails = [email];
          } else {
            innerContactData.personalInfo.updateEmails.push(email);
          }
        } else if (!innerContactData.personalInfo.addEmails) {
          innerContactData.personalInfo.emails = [email];
        } else {
          innerContactData.personalInfo.addEmails.push(email);
        }
      });
    }
  }
  if (innerPersonalInfoPhones) {
    const filteredInnerPersonalInfoPhones = _.filter(
      innerPersonalInfoPhones,
      (obj: any) => obj.phone,
    );
    delete innerContactData.personalInfo.phones;
    if (filteredInnerPersonalInfoPhones.length > 0) {
      filteredInnerPersonalInfoPhones.forEach((phone: any) => {
        if (phone.id) {
          if (!innerContactData.personalInfo.updatePhones) {
            innerContactData.personalInfo.updatePhones = [phone];
          } else {
            innerContactData.personalInfo.updatePhones.push(phone);
          }
        } else if (!innerContactData.personalInfo.addPhones) {
          innerContactData.personalInfo.phones = [phone];
        } else {
          innerContactData.personalInfo.addPhones.push(phone);
        }
      });
    }
  }

  if (Object.keys(innerContactData.personalInfo).length === 0) {
    delete innerContactData.personalInfo;
  }
  return innerContactData;
};
