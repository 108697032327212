import { useCallback, useRef } from 'react';

const useDebouncedCallback = (func: (...args: unknown[]) => unknown, wait = 500) => {
  const timeout = useRef<NodeJS.Timeout>();

  return useCallback(
    (...args: unknown[]) => {
      const later = () => {
        clearTimeout(timeout.current);
        func(...args);
      };

      clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
    },
    [func, wait],
  );
};

export default useDebouncedCallback;
