import { gql } from '@apollo/client';

const GET_COMPANIES_BY_LOCATION_DISTANCE = gql`
  query GetCompaniesByLocationDistance($location: LocationPoint!, $distance: Float!) {
    GetCompaniesByLocationDistance(location: $location, distance: $distance) {
      results {
        location {
          lat
          long
        }
        name
        id
        addresses {
          address
          city
        }
      }
    }
  }
`;

export default GET_COMPANIES_BY_LOCATION_DISTANCE;
