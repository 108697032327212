import { gql } from '@apollo/client';

export const GET_ALL_OPPORTUNITY_TYPES = gql`
  query GetAllOpportunityTypes($criteria: TypeCriteriaInput!) {
    GetAllOpportunityTypes(Criteria: $criteria) {
      results {
        id
        name
        boolean
      }
      total
    }
  }
`;
