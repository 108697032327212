/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { Table, Space, Typography, Row } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {
  CreateCompanyHeaderRenderer,
  CreateCommpanyFooterRenderer,
} from 'src/components/commons/createCompany/renderer';
import { CustomModal, CustomLoadingModal } from 'src/components/commons/customModal';
import ReactDragListView from 'react-drag-listview';
import { EditableRow2, EditableCell2 } from 'src/components/commons/editableCell2';
import { CompaniesElasticTypes2, CompaniesHeaderType2 } from 'src/types/companiesTypes';
import DeletingConfirmation from 'src/components/commons/deletingConfirmation';
import SideTableFilter from 'src/components/commons/sideTableFilter';
import CreateCompany from 'src/components/commons/createCompany';
import { CustomTableTypes } from 'src/types/customTableTypes';
import StyledCol from './CustomTable.styles';
import ResizableTitle from '../resizableTitle';
import CustomButton from '../customButton';
import EditingCompanyAttr from '../editCompanyAttr';
import EditingConfirmation from '../editingConfirmation';
import MapModal from '../mapModal';
import FooterButtonsModal from '../mapModal/footerButtons';

interface OnHeaderCellData {
  width: number;
  minWidth: number;
  onResize?: (e: React.FormEvent<HTMLInputElement>, { size }: any) => void;
}

const CustomTable = ({
  data2,
  entityColumns2,
  tableLoading,
  rowsDisplay2,
  noSelectedRowModal,
  moveToRightTable,
  enterPage,
  totalPages,
  handleSetCompaniesCol2,
  calculateRealPositionColumn2,
  selectedSideTableFilter,
  handleNoSelectedRowModal,
  handleAllDataSelection,
  saveCompanyData2,
  warningStatusError,
  warningStatusErrorHandler,
  openConfirmationModal,
  deletedDataResponse,
  closeDeleteConfirmationAction,
  activeMetadataDropdown,
  openEditConfirmationModal,
  cancelActiveMetadataDropdownHandler,
  createCompanyModalActive,
  activeCreateCompany,
  progressCreationCompany,
  openMap,
  setOpenMap,
}: CustomTableTypes) => {
  const components2 = {
    header: {
      cell: ResizableTitle,
    },
    body: {
      row: EditableRow2,
      cell: EditableCell2,
    },
  };

  const calculateTableHeight = () => {
    const headerHeight =
      Number(document?.getElementById('company-sub-header')?.offsetHeight) +
      Number(document?.getElementById('custom-header')?.offsetHeight) +
      50;
    const footerHeight = Number(document?.getElementById('custom-footer')?.offsetHeight);
    return window.innerHeight - headerHeight - footerHeight - 13;
  };

  const [tableHeight, setTableHeight] = useState(calculateTableHeight());

  useEffect(() => {
    const handleResize = () => {
      setTableHeight(calculateTableHeight());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const customLoadingModalElement = <CustomLoadingModal modalStatus={tableLoading} />;

  const noSelectedRowModalElement = (
    <CustomModal
      modalStatus={noSelectedRowModal}
      onCancelAction={() => 0}
      onOkAction={() => 0}
      centered
      closable={false}
      modalTitle={
        <Space>
          <ExclamationCircleFilled style={{ fontSize: 'large', color: '#faad14' }} />
          <Typography.Title level={5} style={{ marginBottom: '3px', marginTop: 0 }}>
            Warning
          </Typography.Title>
        </Space>
      }
      modalBody={
        <Space>
          <Typography.Text>You need to select at least one item to remove it.</Typography.Text>
        </Space>
      }
      footer={
        <Space>
          <CustomButton
            type='primary'
            title='OK'
            onClick={handleNoSelectedRowModal}
            style={{
              fontSize: '14px',
              height: '32px',
              padding: '4px 15px',
            }}
          />
        </Space>
      }
    />
  );

  const deletingConfirmationElement = (
    <DeletingConfirmation
      modalStatus={openConfirmationModal}
      data={deletedDataResponse.current}
      closeAction={closeDeleteConfirmationAction}
    />
  );

  const warningStatusModalElement = (
    <CustomModal
      modalStatus={warningStatusError}
      onCancelAction={() => 0}
      onOkAction={() => 0}
      centered
      closable={false}
      width={365}
      modalTitle={
        <Space>
          <ExclamationCircleFilled style={{ fontSize: 'large', color: 'red' }} />
          <Typography.Title level={5} style={{ marginBottom: '3px', marginTop: 0 }}>
            An Error Ocurred
          </Typography.Title>
        </Space>
      }
      modalBody={
        <Space>
          <Typography.Text>
            Two or more different companies can&apos;t have the same name, please edit before
            saving.
          </Typography.Text>
        </Space>
      }
      footer={
        <Space>
          <CustomButton
            type='primary'
            title='OK'
            onClick={warningStatusErrorHandler}
            style={{
              fontSize: '14px',
              height: '32px',
              padding: '4px 15px',
            }}
          />
        </Space>
      }
    />
  );

  const activeMetadataDropdownModalElement = (
    <CustomModal
      modalStatus={activeMetadataDropdown}
      onCancelAction={() => 0}
      onOkAction={() => 0}
      centered
      closable={false}
      width={762}
      footer={false}
      modalBody={<EditingCompanyAttr />}
    />
  );

  const createNewCompayModalElement = (
    <CustomModal
      modalStatus={createCompanyModalActive}
      onCancelAction={activeCreateCompany}
      onOkAction={() => 0}
      headerHeight='72px'
      bodyHeight='589px'
      footerHeight='64px'
      bodyPadding='16px 32px 24px 32px'
      centered
      closable
      width={1200}
      customClass='custom-modal'
      modalTitle={<CreateCompanyHeaderRenderer percentage={progressCreationCompany} />}
      modalBody={<CreateCompany />}
      footer={<CreateCommpanyFooterRenderer />}
    />
  );

  const nearbyCompaniesMapModal = (
    <CustomModal
      modalStatus={openMap}
      onCancelAction={() => setOpenMap(false)}
      modalTitle='Map'
      modalBody={<MapModal />}
      onOkAction={() => 0}
      closable
      centered
      width={1400}
      bodyPadding='24px 32px'
      footer={<FooterButtonsModal />}
    />
  );

  const EditingConfirmationModalElement = (
    <CustomModal
      modalStatus={openEditConfirmationModal}
      onCancelAction={cancelActiveMetadataDropdownHandler}
      onOkAction={() => 0}
      closable
      centered
      modalTitle='Updated Data'
      width={762}
      footer={false}
      modalBody={<EditingConfirmation />}
    />
  );

  const onDragEnd2 = (fromIndex: number, toIndex: number) => {
    if (entityColumns2) {
      const nextColumn = [...entityColumns2];
      const fromItem: CompaniesHeaderType2 = nextColumn.splice(fromIndex - 1, 1)[0];
      const toItem: CompaniesHeaderType2 = entityColumns2[toIndex - 1];
      if (calculateRealPositionColumn2) {
        calculateRealPositionColumn2(fromItem, toItem);
      }
      nextColumn.splice(toIndex - 1, 0, fromItem);
      if (handleSetCompaniesCol2) {
        handleSetCompaniesCol2(nextColumn);
      }
      const innerColumnsKey: any = [];
      nextColumn.forEach((element: CompaniesHeaderType2) =>
        innerColumnsKey.push(
          element.key ? `${element.dataIndex}.${element.key}` : element.dataIndex,
        ),
      );
    }
  };

  const handleSave2 = (row: CompaniesElasticTypes2) => {
    if (data2 && saveCompanyData2) {
      const newData = [...data2] as unknown as CompaniesElasticTypes2[];
      const index = newData.findIndex((item) => row.id === item.id);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      saveCompanyData2(newData);
    }
  };

  const handleResize2 =
    (index: number) =>
    (e: React.FormEvent<HTMLInputElement>, { size }: any) => {
      if (entityColumns2 && handleSetCompaniesCol2) {
        const nextColumns = [...entityColumns2];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };

        handleSetCompaniesCol2(nextColumns);
      }
    };
  const columnsData2 = entityColumns2?.map((col, index) => ({
    ...col,
    // eslint-disable-next-line consistent-return
    onCell: (record: CompaniesElasticTypes2) => {
      const onCellObject = {
        record,
        editable: col?.editable,
        dataIndex: col?.dataIndex,
        title: col?.headerName,
        dataKey: col?.key,
        searchField: col?.searchField,
        dataType: col?.dataType,
        options: col?.options,
        hasLink: col?.hasLink,
        handleSave2,
      };
      if (col?.gridVisible) {
        return onCellObject;
      }
    },
    onHeaderCell: (column: CompaniesHeaderType2): OnHeaderCellData => ({
      width: column.width,
      minWidth: column.minWidth,
      onResize: column.dataType !== 'color' ? handleResize2(index) : undefined,
    }),
  }));
  return (
    <Row justify='space-between' style={{ position: 'relative' }}>
      {moveToRightTable && selectedSideTableFilter !== 'all' && <SideTableFilter />}
      <StyledCol span={24} moveToRight={moveToRightTable}>
        {noSelectedRowModalElement}
        {warningStatusModalElement}
        {customLoadingModalElement}
        {deletingConfirmationElement}
        {activeMetadataDropdown && activeMetadataDropdownModalElement}
        {EditingConfirmationModalElement}
        {createNewCompayModalElement}
        {nearbyCompaniesMapModal}
        <ReactDragListView.DragColumn
          onDragEnd={onDragEnd2}
          nodeSelector='th'
          handleSelector='.dragHandler'
          ignoreSelector='react-resizable-handle'
        >
          <Table
            id='export-table'
            pagination={{
              defaultPageSize: 25,
              pageSize: rowsDisplay2,
              position: ['topRight'],
              simple: true,
              onChange: (e: number) => {
                enterPage(e);
              },
              current: rowsDisplay2,
              showTotal: (total) =>
                totalPages(rowsDisplay2 ? Math.ceil(total / rowsDisplay2) : total)!,
            }}
            rowKey={(record: { id: string }) => record.id}
            components={components2}
            rowSelection={{
              onChange: (e) => {
                handleAllDataSelection(e as unknown as number[]);
              },
              fixed: 'left',
            }}
            size='middle'
            dataSource={data2 as []}
            columns={columnsData2 as []}
            rowClassName='editable-row'
            scroll={{ y: tableHeight, x: '100vw' }}
            data-testid='custom-table-id'
          />
        </ReactDragListView.DragColumn>
      </StyledCol>
    </Row>
  );
};
export default CustomTable;
