import { Skeleton } from 'antd';
import styled from 'styled-components';

const StyledCellSkeleton = styled(Skeleton)`
  &.virtualist-skeleton {
    margin: 0;
  }

  &.virtualist-skeleton .ant-skeleton-paragraph {
    margin: 8px 0 !important;
  }
`;

export default StyledCellSkeleton;
