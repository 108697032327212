// Close Reasons DATA --------------------------------------------------------------------------------

export const CloseReasonData = [
  {
    id: 1,
    company: {
      id: 1,
      name: 'Default',
    },
    data: [
      {
        id: 1,
        name: 'Time',
        isDeletable: true,
      },
      {
        id: 2,
        name: 'Price',
        isDeletable: false,
      },
      {
        id: 3,
        name: 'Quality',
        isDeletable: true,
      },
    ],
    dropdownOptions: [],
  },
  {
    id: 2,
    company: {
      id: 2,
      name: 'Bosch',
    },
    data: [
      {
        id: 1,
        name: 'Time',
        isDeletable: true,
      },
      {
        id: 2,
        name: 'Price',
        isDeletable: false,
      },
      {
        id: 3,
        name: 'Quality',
        isDeletable: true,
      },
      {
        id: 3,
        name: 'Max Ammount',
        isDeletable: true,
      },
    ],
    dropdownOptions: [],
  },
  {
    id: 3,
    company: {
      id: 3,
      name: 'Cable Maker',
    },
    data: [
      {
        id: 1,
        name: 'Time',
        isDeletable: true,
      },
      {
        id: 2,
        name: 'Price',
        isDeletable: false,
      },
      {
        id: 3,
        name: 'Quality',
        isDeletable: true,
      },
    ],
    dropdownOptions: [],
  },
];

export const CloseReasonHeader = [
  {
    relabelIndex: 'relabelIndexForCloseReasonGridId',
    dataIndex: 'id',
    dataStructure: 'id',
    dataType: 'number',
    headerName: 'id',
    searchField: 'ids',
    criteriaField: 'id',
    hidden: true,
    gridVisible: false,
    editable: false,
    required: false,
    hasLink: false,
  },
  {
    relabelIndex: 'relabelIndexForCloseReasonGridCloseReason',
    dataIndex: 'name',
    dataStructure: 'name',
    dataType: 'string',
    headerName: 'Opportunity Close Reason',
    searchField: 'name',
    criteriaField: 'name',
    hidden: false,
    gridVisible: true,
    editable: true,
    required: true,
    hasLink: false,
  },
];

// Failed Reasons DATA --------------------------------------------------------------------------------

export const FailedReasonData = [
  {
    id: 1,
    company: {
      id: 1,
      name: 'Default',
    },
    data: [
      {
        id: 1,
        failedReason: 'Pricing too high',
        isDeletable: true,
      },
      {
        id: 2,
        failedReason: 'Out of stock',
        isDeletable: false,
      },
      {
        id: 3,
        failedReason: 'Outsold',
        isDeletable: true,
      },
    ],
    dropdownOptions: [],
  },
  {
    id: 2,
    company: {
      id: 2,
      name: 'Bosch',
    },
    data: [
      {
        id: 1,
        failedReason: 'Pricing too high',
        isDeletable: true,
      },
      {
        id: 2,
        failedReason: 'Out of stock',
        isDeletable: false,
      },
      {
        id: 4,
        failedReason: 'Bad product quality',
        isDeletable: false,
      },
      {
        id: 3,
        failedReason: 'Outsold',
        isDeletable: true,
      },
    ],
    dropdownOptions: [],
  },
  {
    id: 3,
    company: {
      id: 3,
      name: 'Cable Maker',
    },
    data: [
      {
        id: 1,
        failedReason: 'Pricing too high',
        isDeletable: true,
      },
      {
        id: 2,
        failedReason: 'Out of stock',
        isDeletable: false,
      },
      {
        id: 3,
        failedReason: 'Outsold',
        isDeletable: true,
      },
      {
        id: 5,
        failedReason: 'Too thin cables',
        isDeletable: true,
      },
      {
        id: 6,
        failedReason: 'No return policy',
        isDeletable: true,
      },
    ],
    dropdownOptions: [],
  },
  {
    id: 4,
    company: {
      id: 4,
      name: 'De-Walt',
    },
    data: [
      {
        id: 1,
        failedReason: 'Pricing too high',
        isDeletable: true,
      },
      {
        id: 2,
        failedReason: 'Out of stock',
        isDeletable: false,
      },
      {
        id: 3,
        failedReason: 'Outsold',
        isDeletable: true,
      },
    ],
    dropdownOptions: [],
  },
];

export const FailedReasonHeader = [
  {
    relabelIndex: 'relabelIndexForFailedReasonGridId',
    dataIndex: 'id',
    dataStructure: 'id',
    dataType: 'number',
    headerName: 'id',
    searchField: 'ids',
    criteriaField: 'id',
    hidden: true,
    gridVisible: false,
    editable: false,
    required: false,
    hasLink: false,
  },
  {
    relabelIndex: 'relabelIndexForFailedReasonGridCloseReason',
    dataIndex: 'name',
    dataStructure: 'name',
    dataType: 'string',
    headerName: 'Opportunity Failed Reason',
    searchField: 'name',
    criteriaField: 'name',
    hidden: false,
    gridVisible: true,
    editable: true,
    required: true,
    hasLink: false,
  },
];

// Line Item Status DATA -----------------------------------------------------------------------------------------------

export const LineItemStatusData = [
  {
    id: 1,
    company: {
      id: 1,
      name: 'Default',
    },
    data: [
      {
        id: 1,
        lineItemStatus: 'Won',
        isDeletable: false,
      },
      {
        id: 2,
        lineItemStatus: 'Lost',
        isDeletable: false,
      },
      {
        id: 3,
        lineItemStatus: 'Open',
        isDeletable: true,
      },
    ],
    dropdownOptions: [],
  },
  {
    id: 2,
    company: {
      id: 2,
      name: 'Bosch',
    },
    data: [
      {
        id: 1,
        lineItemStatus: 'Won',
        isDeletable: true,
      },
      {
        id: 2,
        lineItemStatus: 'Lost',
        isDeletable: false,
      },
      {
        id: 3,
        lineItemStatus: 'Open',
        isDeletable: true,
      },
      {
        id: 3,
        lineItemStatus: 'Awaiting answer',
        isDeletable: true,
      },
    ],
    dropdownOptions: [],
  },
  {
    id: 3,
    company: {
      id: 3,
      name: 'Cable Maker',
    },
    data: [
      {
        id: 1,
        lineItemStatus: 'Won',
        isDeletable: true,
      },
      {
        id: 2,
        lineItemStatus: 'Lost',
        isDeletable: false,
      },
      {
        id: 3,
        lineItemStatus: 'Open',
        isDeletable: true,
      },
    ],
    dropdownOptions: [],
  },
  {
    id: 4,
    company: {
      id: 1,
      name: 'De-Walt',
    },
    data: [
      {
        id: 1,
        lineItemStatus: 'Won',
        isDeletable: true,
      },
      {
        id: 2,
        lineItemStatus: 'Lost',
        isDeletable: false,
      },
      {
        id: 3,
        lineItemStatus: 'Open',
        isDeletable: true,
      },
    ],
    dropdownOptions: [],
  },
];

export const LineItemStatusHeader = [
  {
    relabelIndex: 'relabelIndexForlineItemStatusGridId',
    dataIndex: 'id',
    dataStructure: 'id',
    dataType: 'number',
    headerName: 'id',
    searchField: 'ids',
    criteriaField: 'id',
    hidden: true,
    gridVisible: false,
    editable: false,
    required: false,
    hasLink: false,
  },
  {
    relabelIndex: 'relabelIndexForlineItemStatusGridlineItemStatus',
    dataIndex: 'name',
    dataStructure: 'lineItemStatuses',
    dataType: 'string',
    headerName: 'Opportunity Line Item Status',
    searchField: 'name',
    criteriaField: 'name',
    hidden: false,
    gridVisible: true,
    editable: true,
    required: true,
    hasLink: false,
  },
];

// Lead Source DATA -----------------------------------------------------------------------------------------------------

export const LeadSourceData = [
  {
    id: 1,
    leadSource: 'Advertisement',
    isDeletable: true,
  },
  {
    id: 2,
    leadSource: 'Referral',
    isDeletable: false,
  },
  {
    id: 3,
    leadSource: 'Website Lead',
    isDeletable: true,
  },
  {
    id: 4,
    leadSource: 'Cameo 1',
    isDeletable: false,
  },
];

export const LeadSourceHeader = [
  {
    relabelIndex: 'relabelIndexForOpportunityTypeId',
    dataIndex: 'id',
    dataStructure: 'id',
    dataType: 'number',
    headerName: 'id',
    searchField: 'ids',
    criteriaField: 'id',
    hidden: true,
    gridVisible: false,
    editable: false,
    required: false,
    hasLink: false,
  },
  {
    relabelIndex: 'relabelIndexForOpportunityTypeOpportunityType',
    dataIndex: 'name',
    dataStructure: 'name',
    dataType: 'string',
    headerName: 'Opportunity Lead Sources',
    searchField: 'name',
    criteriaField: 'name',
    orderField: 'name',
    hidden: false,
    gridVisible: true,
    editable: true,
    required: true,
    hasLink: false,
  },
];

// Status DATA ------------------------------

export const StatusData = [
  {
    id: 1,
    opportunityStatus: 'Costumer Info Required',
    isDeletable: true,
  },
  {
    id: 2,
    opportunityStatus: 'Manufacturer Info Required',
    isDeletable: false,
  },
  {
    id: 3,
    opportunityStatus: 'Needs Review',
    isDeletable: true,
  },
  {
    id: 4,
    opportunityStatus: 'Non-Aplicable',
    isDeletable: true,
  },
  {
    id: 5,
    opportunityStatus: 'Quote Pending',
    isDeletable: true,
  },
  {
    id: 6,
    opportunityStatus: 'Sample Delivered',
    isDeletable: true,
  },
];

export const StatusHeader = [
  {
    relabelIndex: 'relabelIndexForOpportunityStatusId',
    dataIndex: 'id',
    dataStructure: 'id',
    dataType: 'number',
    headerName: 'id',
    searchField: 'ids',
    criteriaField: 'id',
    hidden: true,
    gridVisible: false,
    editable: false,
    required: false,
    hasLink: false,
  },
  {
    relabelIndex: 'relabelIndexForOpportunityTypeOpportunityStatus',
    dataIndex: 'name',
    dataStructure: 'name',
    dataType: 'string',
    headerName: 'Opportunity Status',
    searchField: 'name',
    criteriaField: 'name',
    hidden: false,
    gridVisible: true,
    editable: true,
    required: true,
    hasLink: false,
  },
];

// Stage Group DATA ------------------------------

export const StageGroupData = [
  {
    id: 1,
    opportunityStageGroup: 'Lost 0%',
    isDeletable: true,
  },
  {
    id: 2,
    opportunityStageGroup: 'Lead 20%',
    isDeletable: false,
  },
  {
    id: 3,
    opportunityStageGroup: 'Information Sharing 40%',
    isDeletable: true,
  },
  {
    id: 4,
    opportunityStageGroup: 'Sample Submitted 60%',
    isDeletable: true,
  },
  {
    id: 5,
    opportunityStageGroup: 'Quote Stage 80%',
    isDeletable: true,
  },
  {
    id: 6,
    opportunityStageGroup: 'Win 100%',
    isDeletable: true,
  },
];

export const StageGroupHeader = [
  {
    relabelIndex: 'relabelIndexForOpportunityStageGroupId',
    dataIndex: 'id',
    dataStructure: 'id',
    dataType: 'number',
    headerName: 'id',
    searchField: 'ids',
    criteriaField: 'id',
    hidden: true,
    gridVisible: false,
    editable: false,
    required: false,
    hasLink: false,
  },
  {
    relabelIndex: 'relabelIndexForOpportunityTypeOpportunityStageGroup',
    dataIndex: 'name',
    dataStructure: 'name',
    dataType: 'string',
    headerName: 'Opportunity Stage Group',
    searchField: 'name',
    criteriaField: 'name',
    orderField: 'name',
    hidden: false,
    gridVisible: true,
    editable: true,
    required: true,
    hasLink: false,
  },
];

// Types DATA ------------------------------

export const TypesData = [
  {
    id: 1,
    opportunityTypes: 'Account Growth',
    isDeletable: true,
  },
  {
    id: 2,
    opportunityTypes: 'Competitor Convertion',
    isDeletable: false,
  },
  {
    id: 3,
    opportunityTypes: 'Demolition',
    isDeletable: true,
  },
  {
    id: 4,
    opportunityTypes: 'Engineering Written Spec',
    isDeletable: true,
  },
  {
    id: 5,
    opportunityTypes: 'Existing Business',
    isDeletable: true,
  },
  {
    id: 6,
    opportunityTypes: 'LEED Certification',
    isDeletable: true,
  },
];

export const TypesHeader = [
  {
    relabelIndex: 'relabelIndexForOpportunitySTypesId',
    dataIndex: 'id',
    dataStructure: 'id',
    dataType: 'number',
    headerName: 'id',
    searchField: 'ids',
    criteriaField: 'id',
    hidden: true,
    gridVisible: false,
    editable: false,
    required: false,
    hasLink: false,
  },
  {
    relabelIndex: 'relabelIndexForOpportunityTypeOpportunityTypes',
    dataIndex: 'name',
    dataStructure: 'name',
    dataType: 'string',
    headerName: 'Opportunity Types',
    searchField: 'name',
    criteriaField: 'name',
    orderField: 'name',
    hidden: false,
    gridVisible: true,
    editable: true,
    required: true,
    hasLink: false,
  },
];

// Stage DATA ------------------------------

export const StageData = [
  {
    id: 1,
    name: 'Default',
    data: [
      {
        id: 1,
        opportunityStage: 'Lead',
        opportunityStatus: {
          id: 1,
          name: 'Customer Info Required',
        },
        stageGroup: {
          id: 1,
          name: 'Lead 20%',
        },
        isDeletable: true,
      },
      {
        id: 2,
        opportunityStage: 'Sample Submitted',
        opportunityStatus: {
          id: 1,
          name: 'Customer Info Required',
        },
        stageGroup: {
          id: 1,
          name: 'Lead 20%',
        },
        isDeletable: false,
      },
      {
        id: 3,
        opportunityStage: 'Won',
        opportunityStatus: {
          id: 2,
          name: 'Manufacturer Info Required',
        },
        stageGroup: {
          id: 1,
          name: 'Lead 20%',
        },
        isDeletable: false,
      },
    ],
    dropdownOptions: [
      {
        options: [
          {
            id: 1,
            name: 'Customer Info Required',
          },
          {
            id: 2,
            name: 'Manufacturer Info Required',
          },
          {
            id: 3,
            name: 'Dealer Info Required',
          },
        ],
        asociatedHeaderId: 2,
      },
      {
        options: [
          {
            id: 1,
            name: 'Lead 20%',
          },
          {
            id: 2,
            name: 'Lead 25%',
          },
          {
            id: 3,
            name: 'Lead 10%',
          },
        ],
        asociatedHeaderId: 3,
      },
    ],
  },
  {
    id: 2,
    name: 'Bosch',
    data: [
      {
        id: 1,
        opportunityStage: 'Lead',
        opportunityStatus: {
          id: 1,
          name: 'Customer Info Required',
        },
        stageGroup: {
          id: 1,
          name: 'Lead 20%',
        },
        isDeletable: false,
      },
      {
        id: 2,
        opportunityStage: 'Sample Submitted',
        opportunityStatus: {
          id: 1,
          name: 'Customer Info Required',
        },
        stageGroup: {
          id: 1,
          name: 'Lead 20%',
        },
        isDeletable: true,
      },
      {
        id: 3,
        opportunityStage: 'Won',
        opportunityStatus: {
          id: 2,
          name: 'Manufacturer Info Required',
        },
        stageGroup: {
          id: 1,
          name: 'Lead 20%',
        },
        isDeletable: true,
      },
      {
        id: 4,
        opportunityStage: 'Quoting',
        opportunityStatus: {
          id: 2,
          name: 'Manufacturer Info Required',
        },
        stageGroup: {
          id: 1,
          name: 'Lead 20%',
        },
        isDeletable: false,
      },
      {
        id: 5,
        opportunityStage: 'Negotiating',
        opportunityStatus: {
          id: 2,
          name: 'Manufacturer Info Required',
        },
        stageGroup: {
          id: 1,
          name: 'Lead 20%',
        },
        isDeletable: true,
      },
    ],
    dropdownOptions: [
      {
        options: [
          {
            id: 1,
            name: 'Customer Info Required',
          },
          {
            id: 2,
            name: 'Manufacturer Info Required',
          },
          {
            id: 3,
            name: 'Dealer Info Required',
          },
        ],
        asociatedHeaderId: 2,
      },
      {
        options: [
          {
            id: 1,
            name: 'Lead 20%',
          },
          {
            id: 2,
            name: 'Lead 25%',
          },
          {
            id: 3,
            name: 'Lead 10%',
          },
        ],
        asociatedHeaderId: 3,
      },
    ],
  },
  {
    id: 3,
    name: 'Cable Maker',
    data: [
      {
        id: 1,
        opportunityStage: 'Lead',
        opportunityStatus: {
          id: 1,
          name: 'Customer Info Required',
        },
        stageGroup: {
          id: 1,
          name: 'Lead 20%',
        },
        isDeletable: false,
      },
      {
        id: 2,
        opportunityStage: 'Sample Submitted',
        opportunityStatus: {
          id: 1,
          name: 'Customer Info Required',
        },
        stageGroup: {
          id: 1,
          name: 'Lead 20%',
        },
        isDeletable: true,
      },
      {
        id: 4,
        opportunityStage: 'Quoting',
        opportunityStatus: {
          id: 2,
          name: 'Manufacturer Info Required',
        },
        stageGroup: {
          id: 1,
          name: 'Lead 20%',
        },
        isDeletable: false,
      },
      {
        id: 5,
        opportunityStage: 'Negotiating',
        opportunityStatus: {
          id: 2,
          name: 'Manufacturer Info Required',
        },
        stageGroup: {
          id: 1,
          name: 'Lead 20%',
        },
        isDeletable: true,
      },
      {
        id: 3,
        opportunityStage: 'Won',
        opportunityStatus: {
          id: 2,
          name: 'Manufacturer Info Required',
        },
        stageGroup: {
          id: 1,
          name: 'Lead 20%',
        },
        isDeletable: true,
      },
    ],
    dropdownOptions: [
      {
        options: [
          {
            id: 1,
            name: 'Customer Info Required',
          },
          {
            id: 2,
            name: 'Manufacturer Info Required',
          },
          {
            id: 3,
            name: 'Dealer Info Required',
          },
        ],
        asociatedHeaderId: 2,
      },
      {
        options: [
          {
            id: 1,
            name: 'Lead 20%',
          },
          {
            id: 2,
            name: 'Lead 25%',
          },
          {
            id: 3,
            name: 'Lead 10%',
          },
        ],
        asociatedHeaderId: 3,
      },
    ],
  },
];

export const StageHeaders = [
  {
    relabelIndex: 'relabelIndexForOpportunityStageId',
    dataIndex: 'id',
    dataStructure: 'id',
    dataType: 'number',
    headerName: 'id',
    searchField: 'ids',
    criteriaField: 'id',
    hidden: true,
    gridVisible: false,
    editable: false,
    required: false,
    hasLink: false,
  },
  {
    relabelIndex: 'relabelIndexForOpportunityStageOpportunityStage',
    dataIndex: 'opportunityStage',
    dataStructure: 'opportunityStage',
    dataType: 'string',
    headerName: 'Opportunity Stage',
    searchField: 'opportunityStages',
    criteriaField: 'opportunityStages',
    hidden: false,
    gridVisible: true,
    editable: true,
    width: 200,
    required: true,
    hasLink: false,
  },
  {
    relabelIndex: 'relabelIndexForOpportunityStageOpportunityStatus',
    dataIndex: 'opportunityStatus',
    dataStructure: {
      id: 'string',
      name: 'string',
    },
    key: 'name',
    dataType: 'dropDown',
    headerName: 'Opportunity Status',
    searchField: 'opportunityStatus',
    criteriaField: 'opportunityStatus',
    hidden: false,
    gridVisible: true,
    editable: true,
    width: 200,
    required: true,
    hasLink: false,
    options: [
      {
        id: 1,
        name: 'Customer Info Required',
      },
      {
        id: 2,
        name: 'Manufacturer Info Required',
      },
      {
        id: 3,
        name: 'Dealer Info Required',
      },
      {
        id: 4,
        name: 'Quote Pending',
      },
      {
        id: 5,
        name: 'Sample Delivered',
      },
    ],
  },
  {
    relabelIndex: 'relabelIndexForOpportunityStageStageGroup',
    dataIndex: 'stageGroup',
    dataStructure: {
      id: 'string',
      name: 'string',
    },
    key: 'name',
    dataType: 'dropDown',
    headerName: 'Opportunity Stage Group',
    searchField: 'opportunityStageGroups',
    criteriaField: 'opportunityStageGroups',
    hidden: false,
    gridVisible: true,
    editable: true,
    width: 200,
    required: true,
    hasLink: false,
    options: [
      {
        id: 1,
        name: 'Lead 20%',
      },
      {
        id: 2,
        name: 'Lead 25%',
      },
      {
        id: 3,
        name: 'Lead 10%',
      },
      {
        id: 4,
        name: 'Lead 30%',
      },
    ],
  },
];
