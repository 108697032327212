import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_DEPARTMENTS = gql`
  query GetAllDepartments {
    GetAllDepartments {
      id
      name
    }
  }
`;
