import React, { useEffect, useState } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { StepperStyles } from './stepper.styles';

export type Step = {
  name: string;
  id: number;
  selected: boolean;
};

interface StepperProps {
  steps: Step[];
  setStepValue?: (value: number) => void;
  externalSetSteps?: (value: Step[]) => void;
}

const Stepper: React.FC<StepperProps> = ({ steps, setStepValue, externalSetSteps }) => {
  const [Steps, setSteps] = useState<Step[]>(steps);

  useEffect(() => {
    setSteps(steps);
    externalSetSteps?.(steps);
  }, [steps]);

  const handleSetCurrentStep = (selectedId: number) => {
    const newSteps = Steps?.map((step) => ({
      ...step,
      selected: step.id === selectedId,
    }));
    if (setStepValue) {
      setStepValue(selectedId);
    }
    setSteps(newSteps);
    externalSetSteps?.(newSteps);
  };

  return (
    <>
      <StepperStyles />
      <div className='stepper'>
        {Steps?.map((step, index, array) => {
          const isComplete = array.findIndex((s) => s.selected) > index;
          let className = 'stepper__item';
          if (step.selected) className += ' current';
          else if (isComplete) className += ' complete';

          return (
            <button
              type='button'
              key={step.id}
              className={className}
              onClick={() => handleSetCurrentStep(step.id)}
            >
              <div className='inner-container'>
                {step.name}
                {isComplete && (
                  <CheckCircleOutlined style={{ fontSize: 16, position: 'relative' }} />
                )}
              </div>
            </button>
          );
        })}
      </div>
    </>
  );
};

export default Stepper;
