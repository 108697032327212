import { Route, Routes } from 'react-router';
import CustomViewContainer from 'src/components/commons/customViewContainer';
import NotFoundPage from 'src/components/commons/notFound';

const QuotesRoutes = () => (
  <Routes>
    <Route index element={<CustomViewContainer>quotes table</CustomViewContainer>} />
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

export default QuotesRoutes;
