import OffCanvas from 'src/components/commons/offcanvas';
import { Outlet } from 'react-router';
import Stepper from 'src/components/commons/stepper';
import { useOppContext } from 'src/context/opportunity.context';
import OppSetupNav from './oppSetupNav';

const OppContent = () => {
  const { stepperInfo, setStepperInfo, saveStepperExternalValue } = useOppContext();
  const header = () => (
    <div>
      <h4 style={{ fontWeight: 400 }}>Stage</h4>
      <Stepper
        steps={stepperInfo}
        externalSetSteps={setStepperInfo}
        setStepValue={saveStepperExternalValue}
      />
    </div>
  );
  const content = () => (
    <>
      <OppSetupNav />
      <Outlet />
    </>
  );
  const offCanvas = () => 'Under Construction';

  return (
    <OffCanvas
      headerStyles={{ border: 'none' }}
      header={header}
      content={content}
      offCanvas={offCanvas}
    />
  );
};

export default OppContent;
