import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
  JSONObject: { input: any; output: any };
  Upload: { input: any; output: any };
};

export enum AdditionalFieldDataTypeEnum {
  Checkbox = 'Checkbox',
  Date = 'Date',
  DateTime = 'DateTime',
  Decimal = 'Decimal',
  Dropdown = 'Dropdown',
  Hyperlink = 'Hyperlink',
  Integer = 'Integer',
  LargeText = 'LargeText',
  MultiselectDropdown = 'MultiselectDropdown',
  ShortText = 'ShortText',
}

export type AdditionalFieldFilterInput = {
  dataType: ValueType;
  fieldName: FilterAdditionalFieldsEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type AdditionalFieldModel = {
  __typename?: 'AdditionalFieldModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dataStructure?: Maybe<Scalars['JSON']['output']>;
  dataType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  module?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum AdditionalFieldModuleEnum {
  Company = 'Company',
  Contact = 'Contact',
}

export type AdditionalFieldOrderByInput = {
  field: OrderAdditionalFieldEnum;
  sort: OrderTypes;
};

export type AdditionalFieldsCriteriaInput = {
  filter?: InputMaybe<Array<AdditionalFieldFilterInput>>;
  orderBy?: InputMaybe<Array<AdditionalFieldOrderByInput>>;
  pagination: PaginationInput2;
};

export type AdditionalFieldsResponse = {
  __typename?: 'AdditionalFieldsResponse';
  results?: Maybe<Array<AdditionalFieldModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AddressDbModel = {
  __typename?: 'AddressDBModel';
  address?: Maybe<Scalars['String']['output']>;
  addressType?: Maybe<AddressTypeModel>;
  city?: Maybe<Scalars['String']['output']>;
  continent?: Maybe<ContinentDbModel>;
  country?: Maybe<CountryDbModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  formattedAddress?: Maybe<Scalars['String']['output']>;
  geopositionLatitude?: Maybe<Scalars['Float']['output']>;
  geopositionLongitude?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  isAddressInvalid?: Maybe<Scalars['Boolean']['output']>;
  pobox?: Maybe<Scalars['String']['output']>;
  region?: Maybe<RegionModel>;
  state?: Maybe<StateDbModel>;
  territory?: Maybe<TerritoryDbModel>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type AddressFast = {
  __typename?: 'AddressFast';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  region?: Maybe<RegionModel>;
  state?: Maybe<StateDbModel>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type AddressModel = {
  __typename?: 'AddressModel';
  ADDRESS?: Maybe<Scalars['String']['output']>;
  CITY?: Maybe<Scalars['String']['output']>;
  ID: Scalars['Int']['output'];
  PO_BOX?: Maybe<Scalars['String']['output']>;
  REGION?: Maybe<Scalars['String']['output']>;
  REGION_ID?: Maybe<Scalars['Int']['output']>;
  STATE?: Maybe<Scalars['String']['output']>;
};

export type AddressModelEsInput = {
  ADDRESS?: InputMaybe<Scalars['String']['input']>;
  CITY?: InputMaybe<Scalars['String']['input']>;
  ID: Scalars['Int']['input'];
  PO_BOX?: InputMaybe<Scalars['String']['input']>;
  REGION?: InputMaybe<Scalars['String']['input']>;
  REGION_ID?: InputMaybe<Scalars['Int']['input']>;
  STATE?: InputMaybe<Scalars['String']['input']>;
};

export type AddressTypeCriteriaInput = {
  filter?: InputMaybe<Array<AddressTypeFilterInput>>;
  orderBy?: InputMaybe<Array<AddressTypeOrderByInput>>;
  pagination: PaginationInput2;
};

export type AddressTypeElasticResponse = {
  __typename?: 'AddressTypeElasticResponse';
  results?: Maybe<Array<AddressTypeModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AddressTypeFilterInput = {
  dataType: ValueType;
  fieldName: FilterAddressTypeEnum;
  operator: StringFilters2;
  value: Scalars['String']['input'];
};

export type AddressTypeModel = {
  __typename?: 'AddressTypeModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  isCompany?: Maybe<Scalars['Boolean']['output']>;
  isContact?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AddressTypeOrderByInput = {
  field: OrderAddressTypeEnum;
  sort: OrderTypes;
};

export type AggregationsCriteriaInput = {
  dateRange: DateRangeInput;
  filters?: InputMaybe<Scalars['JSONObject']['input']>;
  from?: InputMaybe<Scalars['Float']['input']>;
  orderBy?: InputMaybe<AggregationsCriteriaOrderByInput>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type AggregationsCriteriaOrderByInput = {
  sort: OrderTypes;
};

export enum AllFilters {
  Between = 'between',
  Contains = 'contains',
  EndsWith = 'endsWith',
  Equals = 'equals',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  In = 'in',
  IsEmpty = 'isEmpty',
  IsNotEmpty = 'isNotEmpty',
  IsNotNull = 'isNotNull',
  IsNull = 'isNull',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  NotContains = 'notContains',
  NotEquals = 'notEquals',
  NotIn = 'notIn',
  StartsWith = 'startsWith',
}

export enum AllFilters2 {
  Between = 'BETWEEN',
  Contains = 'CONTAINS',
  Endswith = 'ENDSWITH',
  Equals = 'EQUALS',
  Greaterthan = 'GREATERTHAN',
  Greaterthanorequal = 'GREATERTHANOREQUAL',
  In = 'IN',
  Isempty = 'ISEMPTY',
  Isnotempty = 'ISNOTEMPTY',
  Isnotnull = 'ISNOTNULL',
  Isnull = 'ISNULL',
  Lessthan = 'LESSTHAN',
  Lessthanorequal = 'LESSTHANOREQUAL',
  Notcontains = 'NOTCONTAINS',
  Notequals = 'NOTEQUALS',
  Notin = 'NOTIN',
  Startwith = 'STARTWITH',
}

export type AnalyticsWideSale = {
  __typename?: 'AnalyticsWideSale';
  exampleField: Scalars['Int']['output'];
};

export type AssignRoleInput = {
  roleId: Scalars['Float']['input'];
  tenantId: Scalars['Float']['input'];
  userId: Scalars['Float']['input'];
};

export type AttachmentTypeModel = {
  __typename?: 'AttachmentTypeModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AuroraSalesReport = {
  __typename?: 'AuroraSalesReport';
  results?: Maybe<Array<AuroraSalesReportResult>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AuroraSalesReportResult = {
  __typename?: 'AuroraSalesReportResult';
  customer?: Maybe<Scalars['String']['output']>;
  dateRangeSales?: Maybe<Scalars['Float']['output']>;
  finalDateRangeMonthSales?: Maybe<Scalars['Float']['output']>;
  lastYearDateRangeSales?: Maybe<Scalars['Float']['output']>;
  lastYearFinalDateRangeMonthSales?: Maybe<Scalars['Float']['output']>;
  lastYearSales?: Maybe<Scalars['Float']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  percentageDiff?: Maybe<Scalars['Float']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  saleTeam?: Maybe<Scalars['String']['output']>;
};

export type BatchErrorModel = {
  __typename?: 'BatchErrorModel';
  context?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  errorCode?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type BuyGroupModel = {
  __typename?: 'BuyGroupModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BuyingGroupCriteriaInput = {
  filter?: InputMaybe<Array<BuyingGroupFilterInput>>;
  orderBy?: InputMaybe<Array<BuyingGroupOrderByInput>>;
  pagination: PaginationInput2;
};

export type BuyingGroupFilterInput = {
  dataType: ValueType;
  fieldName: FilterBuyGroupEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type BuyingGroupOrderByInput = {
  field: OrderBuyGroupEnum;
  sort: OrderTypes;
};

export type BuyingGroupResponse = {
  __typename?: 'BuyingGroupResponse';
  results?: Maybe<Array<BuyGroupModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CallPatternCriteriaInput = {
  filter?: InputMaybe<Array<CallPatternFilterInput>>;
  orderBy?: InputMaybe<Array<CallPatternOrderByInput>>;
  pagination: PaginationInput2;
};

export type CallPatternFilterInput = {
  dataType: ValueType;
  fieldName: FilterCallPatternEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type CallPatternModel = {
  __typename?: 'CallPatternModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CallPatternOrderByInput = {
  field: OrderCallPatternEnum;
  sort: OrderTypes;
};

export type CallPatternResponse = {
  __typename?: 'CallPatternResponse';
  results?: Maybe<Array<CallPatternModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CategoryCriteriaInput = {
  filter?: InputMaybe<Array<CategoryFilterInput>>;
  orderBy?: InputMaybe<Array<CategoryOrderByInput>>;
  pagination: PaginationInput2;
};

export type CategoryFilterInput = {
  dataType: ValueType;
  fieldName: FilterCategoryEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type CategoryOrderByInput = {
  field: OrderCategoryEnum;
  sort: OrderTypes;
};

export type CategoryResponse = {
  __typename?: 'CategoryResponse';
  results?: Maybe<Array<CompanyCategoryDbModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum ChangeSalesTeamEnum {
  Future = 'future',
  Historical = 'historical',
  Pending = 'pending',
}

export type ChangeSalesTeamInput = {
  idSalesTeamFrom: Scalars['Int']['input'];
  idSalesTeamTo: Scalars['Int']['input'];
  option?: ChangeSalesTeamEnum;
  poExceptions?: Scalars['Boolean']['input'];
};

export type CloseReasonCriteriaInput = {
  filter?: InputMaybe<Array<CloseReasonFilterInput>>;
  orderBy?: InputMaybe<Array<CloseReasonOrderByInput>>;
  pagination: PaginationInput2;
};

export type CloseReasonElasticResponse = {
  __typename?: 'CloseReasonElasticResponse';
  results?: Maybe<Array<SubTableByCompanyElasticResponse>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CloseReasonFilterInput = {
  dataType: ValueType;
  fieldName: FilterCloseReasonEnum;
  operator: StringFilters2;
  value: Scalars['String']['input'];
};

export type CloseReasonOrderByInput = {
  field: OrderCloseReasonEnum;
  sort: OrderTypes;
};

export type CloseReasonOutputDto = {
  __typename?: 'CloseReasonOutputDto';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  company?: Maybe<CompanyModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CloseStatusModel = {
  __typename?: 'CloseStatusModel';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ClosedReason = {
  __typename?: 'ClosedReason';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ClosedStatus = {
  __typename?: 'ClosedStatus';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CognitoConfirmResponseModel = {
  __typename?: 'CognitoConfirmResponseModel';
  cognito_id: Scalars['String']['output'];
  cognito_token?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Array<EmailUserModel>>;
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<Scalars['String']['output']>;
  tenant_id: Scalars['Int']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type ColumnModel = {
  __typename?: 'ColumnModel';
  aggregable: Scalars['Boolean']['output'];
  caption: Scalars['String']['output'];
  dataType: Scalars['String']['output'];
  description: Scalars['String']['output'];
  elastic_search_field: Scalars['String']['output'];
  filtrable: Scalars['Boolean']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  reportId: Scalars['Int']['output'];
};

export type CompanyAdditionalFieldModel = {
  __typename?: 'CompanyAdditionalFieldModel';
  additionalField?: Maybe<AdditionalFieldModel>;
  company?: Maybe<CompanyModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type CompanyAddressModel = {
  __typename?: 'CompanyAddressModel';
  address?: Maybe<AddressDbModel>;
  company?: Maybe<CompanyModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyAttachmentModel = {
  __typename?: 'CompanyAttachmentModel';
  companyID?: Maybe<CompanyModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  typeID?: Maybe<AttachmentTypeModel>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum CompanyBTypeEnum {
  Customer = 'customer',
  Distributor = 'distributor',
  External = 'external',
  Manufacturer = 'manufacturer',
}

export type CompanyBaseType = {
  __typename?: 'CompanyBaseType';
  additionalFields: Array<Scalars['String']['output']>;
  additionalRequiredFields: Array<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  options: Array<Options>;
  parent: Scalars['String']['output'];
};

export type CompanyBatchResultModel = {
  __typename?: 'CompanyBatchResultModel';
  company?: Maybe<UpdateCompanyBatchResponseModel>;
  error?: Maybe<BatchErrorModel>;
  new_data?: Maybe<UpdateCompanyBatchModel>;
  prev_data?: Maybe<UpdateCompanyBatchModel>;
  processed?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyByTypeCriteriaInput = {
  orderBy?: InputMaybe<Array<CompanyByTypeOrderInput>>;
  pagination: PaginationInput2;
};

export type CompanyByTypeInput = {
  companyType: CompanyBTypeEnum;
};

export type CompanyByTypeOrderInput = {
  field: OrderCompanyTypeEnum;
  sort: OrderTypes;
};

export type CompanyCategoryDbModel = {
  __typename?: 'CompanyCategoryDBModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyCategoryEsInput = {
  LABEL?: InputMaybe<Scalars['String']['input']>;
  VALUE?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyCategoryModel = {
  __typename?: 'CompanyCategoryModel';
  LABEL?: Maybe<Scalars['String']['output']>;
  VALUE?: Maybe<Scalars['Int']['output']>;
};

export type CompanyClassDbModel = {
  __typename?: 'CompanyClassDBModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyClassEsInput = {
  LABEL?: InputMaybe<Scalars['String']['input']>;
  VALUE?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyClassModel = {
  __typename?: 'CompanyClassModel';
  LABEL?: Maybe<Scalars['String']['output']>;
  VALUE?: Maybe<Scalars['Int']['output']>;
};

export type CompanyCriteriaInput = {
  filter?: InputMaybe<Array<CompanyFilterInput>>;
  orderBy?: InputMaybe<CompanyOrderByInput>;
  pagination: PaginationInput;
};

export type CompanyCriteriaInput2 = {
  filter?: InputMaybe<Array<CompanyFilterInput2>>;
  orderBy?: InputMaybe<Array<CompanyOrderByInput2>>;
  pagination: PaginationInput2;
};

export type CompanyDivisionModel = {
  __typename?: 'CompanyDivisionModel';
  companyID?: Maybe<CompanyModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  divisionID?: Maybe<DivisionDbModel>;
  id: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyDocNotesModel = {
  __typename?: 'CompanyDocNotesModel';
  companyID?: Maybe<CompanyModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  purchaseOrderTerms?: Maybe<Scalars['String']['output']>;
  quoteTerms?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyElasticModel = {
  __typename?: 'CompanyElasticModel';
  ACTIVE: Scalars['Boolean']['output'];
  ADDRESSES?: Maybe<AddressModel>;
  CALL_PATTERN_ID?: Maybe<Scalars['Int']['output']>;
  CATEGORY?: Maybe<CompanyCategoryModel>;
  CLASS?: Maybe<CompanyClassModel>;
  COMMENTS?: Maybe<Scalars['String']['output']>;
  CREATED_BY?: Maybe<UserModel>;
  CREATED_DATE?: Maybe<Scalars['DateTime']['output']>;
  DATA_SOURCE?: Maybe<Scalars['String']['output']>;
  ES_ID: Scalars['String']['output'];
  HAS_HOLYDAY?: Maybe<Scalars['Boolean']['output']>;
  ID: Scalars['Float']['output'];
  IMP_BATCH?: Maybe<Scalars['Float']['output']>;
  NAME?: Maybe<Scalars['String']['output']>;
  PARENT_ID?: Maybe<Scalars['Float']['output']>;
  PARENT_NAME?: Maybe<Scalars['String']['output']>;
  PHONES?: Maybe<PhoneModel>;
  PRINT_NAME?: Maybe<Scalars['String']['output']>;
  REF_NO?: Maybe<Scalars['String']['output']>;
  SALES_TEAM?: Maybe<CompanySalesEsTeamModel>;
  SHORT_NAME?: Maybe<Scalars['String']['output']>;
  TYPE?: Maybe<CompanyTypeModel>;
  UPDATED_BY?: Maybe<UserModel>;
  UPDATED_DATE?: Maybe<Scalars['DateTime']['output']>;
  VISIT_FREQUENCY?: Maybe<Scalars['Float']['output']>;
  WEB_SITE?: Maybe<Scalars['String']['output']>;
};

export type CompanyFastModel = {
  __typename?: 'CompanyFastModel';
  companyType?: Maybe<CompanyTypeAliasModel>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  salesTeam?: Maybe<SalesTeamModel>;
};

export enum CompanyFields {
  Active = 'ACTIVE',
  AdditionalField = 'ADDITIONAL_FIELD',
  AdditionalFieldName = 'ADDITIONAL_FIELD_NAME',
  AddressType = 'ADDRESS_TYPE',
  AddressTypeName = 'ADDRESS_TYPE_NAME',
  AttachmentType = 'ATTACHMENT_TYPE',
  AttachmentTypeName = 'ATTACHMENT_TYPE_NAME',
  CallPattern = 'CALL_PATTERN',
  CallPatternId = 'CALL_PATTERN_ID',
  Category = 'CATEGORY',
  CategoryName = 'CATEGORY_NAME',
  Class = 'CLASS',
  ClassName = 'CLASS_NAME',
  Comments = 'COMMENTS',
  CompanyAddress = 'COMPANY_ADDRESS',
  CompanyCategoryDescription = 'COMPANY_CATEGORY_DESCRIPTION',
  CompanyCategoryId = 'COMPANY_CATEGORY_ID',
  CompanyCategoryName = 'COMPANY_CATEGORY_NAME',
  CompanyCity = 'COMPANY_CITY',
  CompanyPhone = 'COMPANY_PHONE',
  CompanyPoBox = 'COMPANY_PO_BOX',
  CompanyRegion = 'COMPANY_REGION',
  CompanySalesTeamId = 'COMPANY_SALES_TEAM_ID',
  CompanySalesTeamName = 'COMPANY_SALES_TEAM_NAME',
  CompanyState = 'COMPANY_STATE',
  CompanyTypeDescription = 'COMPANY_TYPE_DESCRIPTION',
  CompanyTypeId = 'COMPANY_TYPE_ID',
  CompanyTypeName = 'COMPANY_TYPE_NAME',
  Country = 'COUNTRY',
  CountryName = 'COUNTRY_NAME',
  CreatedById = 'CREATED_BY_ID',
  CreatedByName = 'CREATED_BY_NAME',
  CreatedDate = 'CREATED_DATE',
  DataSource = 'DATA_SOURCE',
  Division = 'DIVISION',
  DivisionName = 'DIVISION_NAME',
  Email = 'EMAIL',
  EmailName = 'EMAIL_NAME',
  EmailType = 'EMAIL_TYPE',
  EmailTypeName = 'EMAIL_TYPE_NAME',
  HasHolyday = 'HAS_HOLYDAY',
  Id = 'ID',
  ImpBatch = 'IMP_BATCH',
  Industry = 'INDUSTRY',
  IndustryName = 'INDUSTRY_NAME',
  Name = 'NAME',
  ParentId = 'PARENT_ID',
  ParentName = 'PARENT_NAME',
  PhoneType = 'PHONE_TYPE',
  PhoneTypeName = 'PHONE_TYPE_NAME',
  PrintName = 'PRINT_NAME',
  PrivateTeam = 'PRIVATE_TEAM',
  PrivateTeamName = 'PRIVATE_TEAM_NAME',
  ProductPotentials = 'PRODUCT_POTENTIALS',
  ProductPotentialsName = 'PRODUCT_POTENTIALS_NAME',
  Rank = 'RANK',
  RankName = 'RANK_NAME',
  RefNo = 'REF_NO',
  RegionId = 'REGION_ID',
  ShortName = 'SHORT_NAME',
  Tag = 'TAG',
  TagName = 'TAG_NAME',
  TeeritoryName = 'TEERITORY_NAME',
  Tenant = 'TENANT',
  TenantName = 'TENANT_NAME',
  Territory = 'TERRITORY',
  Type = 'TYPE',
  UpdatedById = 'UPDATED_BY_ID',
  UpdatedByName = 'UPDATED_BY_NAME',
  UpdatedDate = 'UPDATED_DATE',
  VisitFrequency = 'VISIT_FREQUENCY',
  WebSite = 'WEB_SITE',
}

export type CompanyFilterInput = {
  dataType: ValueType;
  fieldName: CompanyFields;
  operator: AllFilters;
  value: Scalars['String']['input'];
};

export type CompanyFilterInput2 = {
  dataType: ValueType;
  fieldName: FilterCompanyEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type CompanyFindElasticInput = {
  ADDRESSES?: InputMaybe<Array<Scalars['String']['input']>>;
  ALL_COMPANY_NAMES?: InputMaybe<Scalars['String']['input']>;
  ALL_TEXT_FIELDS?: InputMaybe<Scalars['String']['input']>;
  CATEGORY_NAMES?: InputMaybe<Array<Scalars['String']['input']>>;
  CITIES?: InputMaybe<Array<Scalars['String']['input']>>;
  CLASS_NAMES?: InputMaybe<Array<Scalars['String']['input']>>;
  COMMENTS?: InputMaybe<Scalars['String']['input']>;
  CREATED_BY?: InputMaybe<Array<Scalars['String']['input']>>;
  DATA_SOURCES?: InputMaybe<Array<Scalars['String']['input']>>;
  HAS_HOLYDAY_CARD?: InputMaybe<Scalars['Boolean']['input']>;
  IDS?: InputMaybe<Array<Scalars['Int']['input']>>;
  IMP_BATCHES?: InputMaybe<Array<Scalars['Float']['input']>>;
  IS_ACTIVE?: InputMaybe<Scalars['Boolean']['input']>;
  NAMES?: InputMaybe<Array<Scalars['String']['input']>>;
  PARENT_NAMES?: InputMaybe<Array<Scalars['String']['input']>>;
  PHONES?: InputMaybe<Array<Scalars['String']['input']>>;
  PO_BOXES?: InputMaybe<Array<Scalars['String']['input']>>;
  PRINT_NAMES?: InputMaybe<Array<Scalars['String']['input']>>;
  REF_NUMBERS?: InputMaybe<Array<Scalars['String']['input']>>;
  REGIONS?: InputMaybe<Array<Scalars['String']['input']>>;
  SALES_TEAMS?: InputMaybe<Array<Scalars['String']['input']>>;
  SHORT_NAMES?: InputMaybe<Array<Scalars['String']['input']>>;
  STATES?: InputMaybe<Array<Scalars['String']['input']>>;
  TYPE_NAMES?: InputMaybe<Array<Scalars['String']['input']>>;
  UPDATED_BY?: InputMaybe<Array<Scalars['String']['input']>>;
  USERS?: InputMaybe<Scalars['String']['input']>;
  VISIT_FREQUENCIES?: InputMaybe<Array<Scalars['Float']['input']>>;
  WEB_SITES?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CompanyFindElasticInput2 = {
  all_company_names?: InputMaybe<Scalars['String']['input']>;
  all_text_fields?: InputMaybe<Scalars['String']['input']>;
  all_text_fields2?: InputMaybe<Scalars['String']['input']>;
  call_pattern_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  call_pattern_names?: InputMaybe<Array<Scalars['String']['input']>>;
  category_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  category_names?: InputMaybe<Array<Scalars['String']['input']>>;
  class_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  class_names?: InputMaybe<Array<Scalars['String']['input']>>;
  comments?: InputMaybe<Scalars['String']['input']>;
  datasources?: InputMaybe<Array<Scalars['String']['input']>>;
  divisions?: InputMaybe<Array<Scalars['Float']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  hasDwin?: InputMaybe<Scalars['Boolean']['input']>;
  hasForecast?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartNumberSplit?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuot?: InputMaybe<Scalars['Boolean']['input']>;
  hasRegi?: InputMaybe<Scalars['Boolean']['input']>;
  hasSamp?: InputMaybe<Scalars['Boolean']['input']>;
  has_holyday_card?: InputMaybe<Scalars['Boolean']['input']>;
  has_pos_account?: InputMaybe<Scalars['Boolean']['input']>;
  has_secondary_alias?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  imp_batchs?: InputMaybe<Array<Scalars['Float']['input']>>;
  industries?: InputMaybe<Array<Scalars['Float']['input']>>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  main_address_address_type_names?: InputMaybe<Array<Scalars['String']['input']>>;
  main_address_addresses?: InputMaybe<Array<Scalars['String']['input']>>;
  main_address_cities?: InputMaybe<Array<Scalars['String']['input']>>;
  main_address_country_names?: InputMaybe<Array<Scalars['String']['input']>>;
  main_address_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  main_address_poboxes?: InputMaybe<Array<Scalars['String']['input']>>;
  main_address_region_names?: InputMaybe<Array<Scalars['String']['input']>>;
  main_address_state_names?: InputMaybe<Array<Scalars['String']['input']>>;
  main_address_territory_names?: InputMaybe<Array<Scalars['String']['input']>>;
  main_address_zip_codes?: InputMaybe<Array<Scalars['String']['input']>>;
  main_contact_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  main_phone_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  main_phone_phones?: InputMaybe<Array<Scalars['String']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  parent_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  parent_names?: InputMaybe<Array<Scalars['String']['input']>>;
  po_footers?: InputMaybe<Array<Scalars['String']['input']>>;
  print_names?: InputMaybe<Array<Scalars['String']['input']>>;
  private_team_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  private_team_names?: InputMaybe<Array<Scalars['String']['input']>>;
  quote_footers?: InputMaybe<Array<Scalars['String']['input']>>;
  rank_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  rank_names?: InputMaybe<Array<Scalars['String']['input']>>;
  ref_numbers?: InputMaybe<Scalars['String']['input']>;
  salesTeams?: InputMaybe<Array<Scalars['Float']['input']>>;
  sales_team_names?: InputMaybe<Array<Scalars['String']['input']>>;
  ship_details?: InputMaybe<Array<Scalars['String']['input']>>;
  short_names?: InputMaybe<Array<Scalars['String']['input']>>;
  type_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  type_names?: InputMaybe<Array<Scalars['String']['input']>>;
  visit_frequencies?: InputMaybe<Array<Scalars['Float']['input']>>;
  websites?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CompanyIndustryDbModel = {
  __typename?: 'CompanyIndustryDBModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyIndustryResponse = {
  __typename?: 'CompanyIndustryResponse';
  results?: Maybe<Array<CompanyIndustryDbModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CompanyModel = {
  __typename?: 'CompanyModel';
  addresses?: Maybe<Array<Maybe<AddressDbModel>>>;
  callPattern?: Maybe<CallPatternModel>;
  category?: Maybe<CompanyCategoryDbModel>;
  comments?: Maybe<Scalars['String']['output']>;
  companyClass?: Maybe<CompanyClassDbModel>;
  companyType?: Maybe<CompanyTypeAliasModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  datasource?: Maybe<Scalars['String']['output']>;
  divisions?: Maybe<Array<Maybe<DivisionDbModel>>>;
  emails?: Maybe<Array<Maybe<EmailModel>>>;
  hasDwin?: Maybe<Scalars['Boolean']['output']>;
  hasForecast?: Maybe<Scalars['Boolean']['output']>;
  hasHolydayCard?: Maybe<Scalars['Boolean']['output']>;
  hasPartNumberSplit?: Maybe<Scalars['Boolean']['output']>;
  hasPosAccount?: Maybe<Scalars['Boolean']['output']>;
  hasQuot?: Maybe<Scalars['Boolean']['output']>;
  hasRegi?: Maybe<Scalars['Boolean']['output']>;
  hasSamp?: Maybe<Scalars['Boolean']['output']>;
  hasSecondaryAlias?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  impBatch?: Maybe<Scalars['Float']['output']>;
  industries?: Maybe<Array<Maybe<CompanyIndustryDbModel>>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<LocationModel>;
  mainAddress?: Maybe<AddressDbModel>;
  mainContact?: Maybe<ContactOutputDto>;
  mainEmail?: Maybe<EmailModel>;
  mainPhone?: Maybe<PhoneDbModel>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<CompanyModel>;
  phones?: Maybe<Array<Maybe<PhoneDbModel>>>;
  poFooter?: Maybe<Scalars['String']['output']>;
  printName?: Maybe<Scalars['String']['output']>;
  privateTeam?: Maybe<PrivateTeamModel>;
  productPotentials?: Maybe<Array<Maybe<CompanyProductPotentialModel>>>;
  quoteFooter?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<CompanyRankDbModel>;
  refNumber?: Maybe<Scalars['String']['output']>;
  salesTeam?: Maybe<SalesTeamModel>;
  shipDetail?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<CompanyTagsModel>>>;
  tenantDivision?: Maybe<DivisionDbModel>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  visitFrequency?: Maybe<Scalars['Float']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type CompanyModelElasticResponse = {
  __typename?: 'CompanyModelElasticResponse';
  results?: Maybe<Array<CompanyElasticModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CompanyModelElasticResponse2 = {
  __typename?: 'CompanyModelElasticResponse2';
  results?: Maybe<Array<CompanyModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CompanyOrderByInput = {
  field: CompanyFields;
  sort: OrderTypes;
};

export type CompanyOrderByInput2 = {
  field: OrderCompanyEnum2;
  sort: OrderTypes;
};

export type CompanyPhoneModel = {
  __typename?: 'CompanyPhoneModel';
  company?: Maybe<CompanyModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  phone?: Maybe<PhoneDbModel>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyPitchesModel = {
  __typename?: 'CompanyPitchesModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyProductPotentialModel = {
  __typename?: 'CompanyProductPotentialModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyRankCriteriaInput = {
  filter?: InputMaybe<Array<CompanyRankFilterInput>>;
  orderBy?: InputMaybe<Array<RankOrderByInput>>;
  pagination: PaginationInput2;
};

export type CompanyRankDbModel = {
  __typename?: 'CompanyRankDBModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyRankFilterInput = {
  dataType: ValueType;
  fieldName: FilterCompanyRankEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type CompanyRankResponse = {
  __typename?: 'CompanyRankResponse';
  results?: Maybe<Array<CompanyRankDbModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CompanySalesEsTeamModel = {
  __typename?: 'CompanySalesESTeamModel';
  LABEL?: Maybe<Scalars['String']['output']>;
  VALUE?: Maybe<Scalars['Int']['output']>;
};

export type CompanySalesTeamEsInput = {
  LABEL?: InputMaybe<Scalars['String']['input']>;
  VALUE?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanySalesTeamModel = {
  __typename?: 'CompanySalesTeamModel';
  companyCustomer?: Maybe<CompanyModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  salesTeam?: Maybe<SalesTeamModel>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanySplitTypesCriteriaInput = {
  filter?: InputMaybe<Array<SplitTypesFilterInput>>;
  orderBy?: InputMaybe<SplitTypesOrderByInput>;
  pagination: PaginationInput2;
};

export type CompanySplitTypesModel = {
  __typename?: 'CompanySplitTypesModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyTagsModel = {
  __typename?: 'CompanyTagsModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyTierCriteriaInput = {
  filter?: InputMaybe<Array<CompanyTierFilterInput>>;
  orderBy?: InputMaybe<Array<CompanyTierOrderByInput>>;
  pagination: PaginationInput2;
};

export type CompanyTierFilterInput = {
  dataType: ValueType;
  fieldName: FilterCompanyTierEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type CompanyTierModel = {
  __typename?: 'CompanyTierModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyTierOrderByInput = {
  field: OrderCompanyTierEnum;
  sort: OrderTypes;
};

export type CompanyTierResponse = {
  __typename?: 'CompanyTierResponse';
  results?: Maybe<Array<CompanyTierModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CompanyTypeAliasModel = {
  __typename?: 'CompanyTypeAliasModel';
  color?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<CompanyTypeDbModel>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyTypeCriteriaInput = {
  filter?: InputMaybe<Array<CompanyTypeFilterInput>>;
  orderBy?: InputMaybe<Array<CompanyTypeOrderByInput>>;
  pagination: PaginationInput2;
};

export type CompanyTypeDbModel = {
  __typename?: 'CompanyTypeDBModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyTypeEsInput = {
  LABEL?: InputMaybe<Scalars['String']['input']>;
  VALUE?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyTypeFilterInput = {
  dataType: ValueType;
  fieldName: FilterCompanyTypeEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type CompanyTypeModel = {
  __typename?: 'CompanyTypeModel';
  LABEL?: Maybe<Scalars['String']['output']>;
  VALUE?: Maybe<Scalars['Int']['output']>;
};

export type CompanyTypeModelElasticResponse = {
  __typename?: 'CompanyTypeModelElasticResponse';
  results?: Maybe<Array<CompanyTypeAliasModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CompanyTypeOrderByInput = {
  field: OrderCompanyTypeEnum;
  sort: OrderTypes;
};

export type ConfirmUserInput = {
  emailTypeId: Scalars['Int']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role: Scalars['String']['input'];
  tenant: Scalars['String']['input'];
  type: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export enum ConstactsGridFieldInputEnum {
  BusinessInfoCompanyMainAddressAddress = 'businessInfoCompanyMainAddressAddress',
  BusinessInfoCompanyMainAddressCity = 'businessInfoCompanyMainAddressCity',
  BusinessInfoCompanyMainAddressRegionName = 'businessInfoCompanyMainAddressRegionName',
  BusinessInfoCompanyMainAddressStateName = 'businessInfoCompanyMainAddressStateName',
  BusinessInfoCompanyMainAddressZipCode = 'businessInfoCompanyMainAddressZipCode',
  BusinessInfoCompanyName = 'businessInfoCompanyName',
  BusinessInfoCompanySalesTeamName = 'businessInfoCompanySalesTeamName',
  BusinessInfoCompanyTypeName = 'businessInfoCompanyTypeName',
  BusinessInfoJobTitle = 'businessInfoJobTitle',
  BusinessInfoMainPhonePhone = 'businessInfoMainPhonePhone',
  FirstName = 'firstName',
  FullName = 'fullName',
  GroupId = 'groupId',
  GroupName = 'groupName',
  LastName = 'lastName',
  Title = 'title',
}

export type ContactAdditionalFieldsContactModel = {
  __typename?: 'ContactAdditionalFieldsContactModel';
  additionalField?: Maybe<AdditionalFieldModel>;
  contact?: Maybe<ContactOutputDto>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ContactBatchResponseModel = {
  __typename?: 'ContactBatchResponseModel';
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type ContactBatchResultModel = {
  __typename?: 'ContactBatchResultModel';
  contact?: Maybe<ContactBatchResponseModel>;
  error?: Maybe<BatchErrorModel>;
  new_data?: Maybe<UpdateContactBatchModel>;
  prev_data?: Maybe<UpdateContactBatchModel>;
  processed?: Maybe<Scalars['Boolean']['output']>;
};

export type ContactBusinessInfoFastModel = {
  __typename?: 'ContactBusinessInfoFastModel';
  company?: Maybe<CompanyFastModel>;
  id: Scalars['Int']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  mainAddress?: Maybe<AddressFast>;
  mainEmail?: Maybe<EmailModel>;
  mainPhone?: Maybe<PhoneFast>;
};

export type ContactBusinessInfoModel = {
  __typename?: 'ContactBusinessInfoModel';
  addresses?: Maybe<Array<Maybe<AddressDbModel>>>;
  assistant?: Maybe<Scalars['String']['output']>;
  company?: Maybe<CompanyModel>;
  context?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  doNotEmail?: Maybe<Scalars['Boolean']['output']>;
  emails?: Maybe<Array<Maybe<EmailModel>>>;
  enabledForcedSync?: Maybe<Scalars['Boolean']['output']>;
  globallyVisible?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  mainAddress?: Maybe<AddressDbModel>;
  mainEmail?: Maybe<EmailModel>;
  mainPhone?: Maybe<PhoneDbModel>;
  manager?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Maybe<PhoneDbModel>>>;
  referencedBy?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContactCriteriaInput = {
  filter?: InputMaybe<Array<ContactFilterInput>>;
  orderBy?: InputMaybe<Array<ContactOrderByInput>>;
  pagination: PaginationInput2;
};

export type ContactCriteriaNestInput = {
  filter?: InputMaybe<Array<ContactNestFilterInput>>;
  orderBy?: InputMaybe<Array<ContactOrderByNestInput>>;
  pagination: PaginationInput2;
};

export type ContactFastGridResponseOutputDto = {
  __typename?: 'ContactFastGridResponseOutputDto';
  results?: Maybe<Array<ContactFastOutputDto>>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type ContactFastOutputDto = {
  __typename?: 'ContactFastOutputDto';
  businessInfo?: Maybe<ContactBusinessInfoFastModel>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ContactFilterInput = {
  dataType: ValueType;
  fieldName: FilterContactEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export enum ContactGridExportFormatEnum {
  Csv = 'CSV',
  Excel = 'EXCEL',
}

export enum ContactGridExportNameEnum {
  BusinessinfoCompanyCompanytypeId = 'businessinfo_company_companytype_id',
  BusinessinfoCompanyCompanytypeName = 'businessinfo_company_companytype_name',
  BusinessinfoCompanyId = 'businessinfo_company_id',
  BusinessinfoCompanyName = 'businessinfo_company_name',
  BusinessinfoCompanySalesteamId = 'businessinfo_company_salesteam_id',
  BusinessinfoCompanySalesteamName = 'businessinfo_company_salesteam_name',
  BusinessinfoId = 'businessinfo_id',
  BusinessinfoJobtitle = 'businessinfo_jobtitle',
  BusinessinfoMainaddressAddress = 'businessinfo_mainaddress_address',
  BusinessinfoMainaddressCity = 'businessinfo_mainaddress_city',
  BusinessinfoMainaddressId = 'businessinfo_mainaddress_id',
  BusinessinfoMainaddressRegionId = 'businessinfo_mainaddress_region_id',
  BusinessinfoMainaddressRegionName = 'businessinfo_mainaddress_region_name',
  BusinessinfoMainaddressStateId = 'businessinfo_mainaddress_state_id',
  BusinessinfoMainaddressStateName = 'businessinfo_mainaddress_state_name',
  BusinessinfoMainaddressZipcode = 'businessinfo_mainaddress_zipcode',
  BusinessinfoMainemailEmail = 'businessinfo_mainemail_email',
  BusinessinfoMainemailId = 'businessinfo_mainemail_id',
  BusinessinfoMainmobileId = 'businessinfo_mainmobile_id',
  BusinessinfoMainmobilePhone = 'businessinfo_mainmobile_phone',
  BusinessinfoMainphoneId = 'businessinfo_mainphone_id',
  BusinessinfoMainphonePhone = 'businessinfo_mainphone_phone',
  Enabled = 'enabled',
  FirstName = 'first_name',
  FullName = 'full_name',
  GroupId = 'group_id',
  GroupName = 'group_name',
  Id = 'id',
  LastName = 'last_name',
  Title = 'title',
}

export type ContactGridResponseOutputDto = {
  __typename?: 'ContactGridResponseOutputDto';
  results?: Maybe<Array<ContactOutputDto>>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type ContactGroupContactModel = {
  __typename?: 'ContactGroupContactModel';
  contact?: Maybe<ContactOutputDto>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  groupContact?: Maybe<ContactGroupModel>;
  id?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContactGroupCriteriaInput = {
  filter?: InputMaybe<Array<ContactGroupFilterInput>>;
  orderBy?: InputMaybe<Array<ContactGroupOrderByInput>>;
  pagination: PaginationInput2;
};

export type ContactGroupElasticResponse = {
  __typename?: 'ContactGroupElasticResponse';
  results?: Maybe<Array<ContactGroupModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ContactGroupFilterInput = {
  dataType: ValueType;
  fieldName: FilterContactGroupEnum;
  operator: StringFilters2;
  value: Scalars['String']['input'];
};

export type ContactGroupModel = {
  __typename?: 'ContactGroupModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContactGroupOrderByInput = {
  field: OrderContactGroupEnum;
  sort: OrderTypes;
};

export type ContactNestFilterInput = {
  dataType: ValueType;
  fieldName: FilterContactNestEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type ContactOrderByInput = {
  field: FilterContactEnum;
  sort: OrderTypes;
};

export type ContactOrderByNestInput = {
  field: OrderContactNestEnum;
  sort: OrderTypes;
};

export type ContactOutputDto = {
  __typename?: 'ContactOutputDto';
  businessInfo?: Maybe<ContactBusinessInfoModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<ContactGroupModel>>;
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  personalInfo?: Maybe<ContactPersonalInfoModel>;
  products?: Maybe<Array<ProductInterestModel>>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContactPersonalInfoModel = {
  __typename?: 'ContactPersonalInfoModel';
  addresses?: Maybe<Array<Maybe<AddressDbModel>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  emails?: Maybe<Array<Maybe<EmailModel>>>;
  id: Scalars['Int']['output'];
  mainAddress?: Maybe<AddressDbModel>;
  mainEmail?: Maybe<EmailModel>;
  mainPhone?: Maybe<PhoneDbModel>;
  phones?: Maybe<Array<Maybe<PhoneDbModel>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContinentDbModel = {
  __typename?: 'ContinentDBModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CountryCriteriaInput = {
  filter?: InputMaybe<Array<CountryFilterInput>>;
  orderBy?: InputMaybe<Array<CountryOrderByInput>>;
  pagination: PaginationInput2;
};

export type CountryDbModel = {
  __typename?: 'CountryDBModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CountryElasticResponse = {
  __typename?: 'CountryElasticResponse';
  results?: Maybe<Array<CountryDbModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type CountryFilterInput = {
  dataType: ValueType;
  fieldName: FilterCountryEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type CountryOrderByInput = {
  field: OrderCountryEnum;
  sort: OrderTypes;
};

export type CreateAdditionalFieldInput = {
  dataStructure: CustomFieldDataStructureType;
  dataType: AdditionalFieldDataTypeEnum;
  description?: InputMaybe<Scalars['String']['input']>;
  module: AdditionalFieldModuleEnum;
  name: Scalars['String']['input'];
};

export type CreateAddressInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  addressType?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  continent?: InputMaybe<Scalars['Int']['input']>;
  country?: InputMaybe<Scalars['Int']['input']>;
  formattedAddress?: InputMaybe<Scalars['String']['input']>;
  geopositionLatitude?: InputMaybe<Scalars['Float']['input']>;
  geopositionLongitude?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isAddressInvalid?: InputMaybe<Scalars['Boolean']['input']>;
  pobox?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['Int']['input']>;
  territory?: InputMaybe<Scalars['Int']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAddressTypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isCompany?: InputMaybe<Scalars['Boolean']['input']>;
  isContact?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type CreateAnalyticsWideSaleInput = {
  exampleField: Scalars['Int']['input'];
};

export type CreateAppTokenInput = {
  cognitoToken: Scalars['String']['input'];
  issuer: Issuer;
  tenantId: Scalars['Float']['input'];
  tenantName: Scalars['String']['input'];
};

export type CreateAttachmentTypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateBuyGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateCallPatternInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateCloseReasonInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type CreateColumnInput = {
  aggregable: Scalars['Boolean']['input'];
  caption: Scalars['String']['input'];
  dataType: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  reportId: Scalars['Int']['input'];
};

export type CreateCompanyAdditionalFieldInput = {
  additionalFieldsId: Scalars['Int']['input'];
  companyId: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export type CreateCompanyAttachmentInput = {
  companyId: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  typeId: Scalars['Float']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateCompanyClassInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateCompanyDivisionInput = {
  companyId: Scalars['Int']['input'];
  divisionId: Scalars['Int']['input'];
};

export type CreateCompanyDocNotesInput = {
  companyId: Scalars['Float']['input'];
  purchaseOrderTerms?: InputMaybe<Scalars['String']['input']>;
  quoteTerms?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyIndustryInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateCompanyInput = {
  addresses?: InputMaybe<Array<InputMaybe<CreateAddressInput>>>;
  callPatternId?: InputMaybe<Scalars['Float']['input']>;
  categoryId?: InputMaybe<Scalars['Float']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  companyClassId?: InputMaybe<Scalars['Float']['input']>;
  companyTypeId: Scalars['Float']['input'];
  datasource?: InputMaybe<Scalars['String']['input']>;
  divisionsId?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  emails?: InputMaybe<Array<InputMaybe<CreateEmailInput>>>;
  hasDwin?: InputMaybe<Scalars['Boolean']['input']>;
  hasForecast?: InputMaybe<Scalars['Boolean']['input']>;
  hasHolydayCard?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartNumberSplit?: InputMaybe<Scalars['Boolean']['input']>;
  hasPosAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuot?: InputMaybe<Scalars['Boolean']['input']>;
  hasRegi?: InputMaybe<Scalars['Boolean']['input']>;
  hasSamp?: InputMaybe<Scalars['Boolean']['input']>;
  hasSecondaryAlias?: InputMaybe<Scalars['Boolean']['input']>;
  impBatch?: InputMaybe<Scalars['Float']['input']>;
  industriesId?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  mainAddress?: InputMaybe<UpdateAddressInput>;
  mainEmail?: InputMaybe<UpdateEmailInput>;
  mainPhone?: InputMaybe<UpdatePhoneInput>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['Float']['input']>;
  phones?: InputMaybe<Array<InputMaybe<CreatePhoneInput>>>;
  poFooter?: InputMaybe<Scalars['String']['input']>;
  printName?: InputMaybe<Scalars['String']['input']>;
  privateTeamId?: InputMaybe<Scalars['Float']['input']>;
  productPotentials?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  quoteFooter?: InputMaybe<Scalars['String']['input']>;
  rankId?: InputMaybe<Scalars['Float']['input']>;
  refNumber?: InputMaybe<Scalars['String']['input']>;
  salesTeam?: InputMaybe<Scalars['Float']['input']>;
  shipDetail?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['Float']['input']>>;
  tenantDivision?: InputMaybe<Scalars['Float']['input']>;
  visitFrequency?: InputMaybe<Scalars['Float']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyPitchInput = {
  companyId: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyRankInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateCompanySalesTeamInput = {
  companyCustomerId: Scalars['Int']['input'];
  salesTeamId: Scalars['Int']['input'];
};

export type CreateCompanySplitTypesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateCompanyTagInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateCompanyTierInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateCompanyTypeAliasInput = {
  color: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parent: Scalars['Int']['input'];
};

export type CreateContactAdditionalFieldsContactInput = {
  additionalFieldsContactId: Scalars['Int']['input'];
  contactId: Scalars['Int']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContactBusinessInfoInput = {
  addresses?: InputMaybe<Array<CreateAddressInput>>;
  assistant?: InputMaybe<Scalars['String']['input']>;
  company: Scalars['Int']['input'];
  context?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  doNotEmail?: InputMaybe<Scalars['Boolean']['input']>;
  emails?: InputMaybe<Array<CreateEmailInput>>;
  enabledForcedSync?: InputMaybe<Scalars['Boolean']['input']>;
  globallyVisible?: InputMaybe<Scalars['Boolean']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  mainAddress?: InputMaybe<UpdateAddressInput>;
  mainEmail?: InputMaybe<UpdateEmailInput>;
  mainPhone?: InputMaybe<UpdatePhoneInput>;
  manager?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<CreatePhoneInput>>;
  referencedBy?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContactGroupContactInput = {
  contactId: Scalars['Int']['input'];
  groupContactId: Scalars['Int']['input'];
};

export type CreateContactGroupInput = {
  name: Scalars['String']['input'];
};

export type CreateContactInput = {
  businessInfo: CreateContactBusinessInfoInput;
  firstName: Scalars['String']['input'];
  groups?: InputMaybe<Array<Scalars['Int']['input']>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  personalInfo?: InputMaybe<CreateContactPersonalInfoInput>;
  products?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContactPersonalInfoInput = {
  addresses?: InputMaybe<Array<CreateAddressInput>>;
  emails?: InputMaybe<Array<CreateEmailInput>>;
  mainAddress?: InputMaybe<UpdateAddressInput>;
  mainEmail?: InputMaybe<UpdateEmailInput>;
  mainPhone?: InputMaybe<UpdatePhoneInput>;
  phones?: InputMaybe<Array<CreatePhoneInput>>;
};

export type CreateContinentsInput = {
  name: Scalars['String']['input'];
};

export type CreateCountryInput = {
  name: Scalars['String']['input'];
};

export type CreateCustomerPriceCodesInput = {
  codes?: InputMaybe<Scalars['String']['input']>;
  companyCustomerId?: InputMaybe<Scalars['Float']['input']>;
  companyManufacturerId: Scalars['Float']['input'];
  multiplier?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateCustomerPriceGroupsInput = {
  buyGroupId?: InputMaybe<Scalars['Float']['input']>;
  companyCustomerId?: InputMaybe<Scalars['Float']['input']>;
  companyManufacturerId: Scalars['Float']['input'];
  companyTierId?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateDepartmentInput = {
  divisionId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type CreateDivisionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateEmailInput = {
  email: Scalars['String']['input'];
  emailType: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateEmailServerInput = {
  incomingPort: Scalars['Float']['input'];
  incomingProtocol: Scalars['String']['input'];
  incomingSSL: Scalars['Boolean']['input'];
  incomingServer: Scalars['String']['input'];
  outgoingPort: Scalars['Int']['input'];
  outgoingProtocol: Scalars['String']['input'];
  outgoingSSL: Scalars['Boolean']['input'];
  outgoingServer: Scalars['String']['input'];
  providerName: Scalars['String']['input'];
};

export type CreateEmailTypeInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateFailedReasonInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type CreateGridPreferenceInput = {
  gridName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  options: Scalars['String']['input'];
};

export type CreateLeadSourceInput = {
  name: Scalars['String']['input'];
};

export type CreateLineItemStatusInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type CreateOpportunitiesInput = {
  bidDate?: InputMaybe<Scalars['DateTime']['input']>;
  closeReasonId?: InputMaybe<Scalars['Float']['input']>;
  customers?: InputMaybe<Array<Scalars['Int']['input']>>;
  customersContacts?: InputMaybe<Array<Scalars['Int']['input']>>;
  distributors?: InputMaybe<Array<Scalars['Int']['input']>>;
  distributorsContacts?: InputMaybe<Array<Scalars['Int']['input']>>;
  estimatedCloseDate?: InputMaybe<Scalars['DateTime']['input']>;
  followUp?: InputMaybe<Scalars['DateTime']['input']>;
  leadSourceId?: InputMaybe<Scalars['Int']['input']>;
  manufacturerContacts?: InputMaybe<Array<Scalars['Int']['input']>>;
  manufacturers?: InputMaybe<Array<Scalars['Int']['input']>>;
  nextStep?: InputMaybe<Scalars['String']['input']>;
  opportunityStageId?: InputMaybe<Scalars['Float']['input']>;
  ownerId: Scalars['Int']['input'];
  priority?: InputMaybe<Scalars['Float']['input']>;
  salesTeamId?: InputMaybe<Scalars['Float']['input']>;
  stageId?: InputMaybe<Scalars['Int']['input']>;
  topic: Scalars['String']['input'];
  typeId?: InputMaybe<Scalars['Int']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type CreatePhoneInput = {
  areaCode?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  extension?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneType: Scalars['Int']['input'];
};

export type CreatePhoneTypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isCompany?: InputMaybe<Scalars['Boolean']['input']>;
  isContact?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
};

export type CreatePrivateTeamInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type CreatePrivateTeamTypeInput = {
  name: Scalars['String']['input'];
};

export type CreateProductInterestInput = {
  name: Scalars['String']['input'];
};

export type CreateProductPotentialInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateRegionInput = {
  name: Scalars['String']['input'];
};

export type CreateRelatedCategoryInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateRelatedCompanyInput = {
  company?: InputMaybe<Scalars['Float']['input']>;
  companyManufacturer?: InputMaybe<Scalars['Float']['input']>;
  customerSplit?: InputMaybe<Scalars['String']['input']>;
  relatedCategory?: InputMaybe<Scalars['Float']['input']>;
  relatedCompany?: InputMaybe<Scalars['Float']['input']>;
  type: Scalars['String']['input'];
  valueRole?: InputMaybe<Scalars['String']['input']>;
};

export type CreateReportListInput = {
  description: Scalars['String']['input'];
  extraParams?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  reportRoute: Scalars['String']['input'];
  reportTag: Scalars['String']['input'];
};

export type CreateRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  tenantId: Scalars['Int']['input'];
};

export type CreateSalesTeamInput = {
  companySplit?: InputMaybe<Scalars['Float']['input']>;
  companySplitType?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  specifierSplit?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateSalesTeamTypesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateStageGroupInput = {
  name: Scalars['String']['input'];
};

export type CreateStageInput = {
  closeStatusId: Scalars['Int']['input'];
  companyId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  potential: Scalars['Float']['input'];
  stageGroupId: Scalars['Int']['input'];
  statusId: Scalars['Int']['input'];
};

export type CreateStateInput = {
  name: Scalars['String']['input'];
};

export type CreateStatusInput = {
  isPredefined: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type CreateTenantInstanceInput = {
  clientId: Scalars['Int']['input'];
  cognitoClientId: Scalars['String']['input'];
  cognitoPoolId: Scalars['String']['input'];
  elasticSearchKey: Scalars['String']['input'];
  instanceName: Scalars['String']['input'];
  instanceSettings: InstanceSettingsInput;
  isActive: Scalars['Boolean']['input'];
  legacyElasticSearchKey: Scalars['String']['input'];
  tenantId: Scalars['Int']['input'];
  tenantName: Scalars['String']['input'];
};

export type CreateTerritoryInput = {
  name: Scalars['String']['input'];
};

export type CreateTypeInput = {
  name: Scalars['String']['input'];
};

export type CreateUserAdditionalFieldInput = {
  dataType: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateUserAdditionalFieldsUserInput = {
  additionalFieldId: Scalars['Int']['input'];
  fieldValue: Scalars['String']['input'];
};

export type CreateUserLandingWidget = {
  columnMinHeight: Scalars['Int']['input'];
  columnNumber: Scalars['Int']['input'];
  enabled: Scalars['Boolean']['input'];
  isDefault: Scalars['Boolean']['input'];
  savedName: Scalars['String']['input'];
  tenantId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
  widgetList: Array<LandingWidgetInput>;
};

export type CreateUserMenuInput = {
  hasLink: Scalars['Boolean']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  linkAddress?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateUserReportPreferenceInput = {
  configParams: Scalars['JSON']['input'];
  dateRange: DateRangeInput;
  enabled: Scalars['Boolean']['input'];
  gridItems: Array<GridItemInput>;
  isDefault: Scalars['Boolean']['input'];
  reportId: Scalars['Int']['input'];
  savedName: Scalars['String']['input'];
  tenantId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};

export type CreateUserReportWidgetInput = {
  extraParams?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  reportId: Scalars['Float']['input'];
  tenantId: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateUserRoleInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateUserTypeInput = {
  canLogin?: InputMaybe<Scalars['Boolean']['input']>;
  emailAccess?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  walletAccess?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CustomFieldDataStructureType = {
  optionStructure?: InputMaybe<OptionStructureType>;
  options?: InputMaybe<Array<OptionStructureType>>;
  required?: Scalars['Boolean']['input'];
  selected?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CustomerPriceCodesModel = {
  __typename?: 'CustomerPriceCodesModel';
  code?: Maybe<Scalars['String']['output']>;
  companyCustomer?: Maybe<CompanyModel>;
  companyManufacturer?: Maybe<CompanyModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  multiplier?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerPriceGroupsModel = {
  __typename?: 'CustomerPriceGroupsModel';
  buyGroup?: Maybe<BuyGroupModel>;
  companyCustomer?: Maybe<CompanyModel>;
  companyManufacturer?: Maybe<CompanyModel>;
  companyTier: CompanyTierModel;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DateRangeInput = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type DeletedOkModel = {
  __typename?: 'DeletedOkModel';
  deleted: Scalars['Boolean']['output'];
};

export type DepartmentModel = {
  __typename?: 'DepartmentModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  division?: Maybe<DivisionDbModel>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DivisionDbModel = {
  __typename?: 'DivisionDBModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DivisionsCompanyModel = {
  __typename?: 'DivisionsCompanyModel';
  designatedMember?: Maybe<MemberModel>;
  division?: Maybe<DivisionDbModel>;
  members?: Maybe<Array<MemberModel>>;
};

export type EmailModel = {
  __typename?: 'EmailModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailType?: Maybe<EmailTypeModel>;
  id: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EmailServerModel = {
  __typename?: 'EmailServerModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  incomingPort?: Maybe<Scalars['Int']['output']>;
  incomingProtocol?: Maybe<Scalars['String']['output']>;
  incomingSSL?: Maybe<Scalars['Boolean']['output']>;
  incomingServer?: Maybe<Scalars['String']['output']>;
  outgoingPort?: Maybe<Scalars['Int']['output']>;
  outgoingProtocol?: Maybe<Scalars['String']['output']>;
  outgoingSSL?: Maybe<Scalars['Boolean']['output']>;
  outgoingServer?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EmailTypeCriteriaInput = {
  filter?: InputMaybe<Array<EmailTypeFilterInput>>;
  orderBy?: InputMaybe<Array<EmailTypeOrderByInput>>;
  pagination: PaginationInput2;
};

export type EmailTypeElasticResponse = {
  __typename?: 'EmailTypeElasticResponse';
  results?: Maybe<Array<EmailTypeModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type EmailTypeFilterInput = {
  dataType: ValueType;
  fieldName: FilterEmailTypeEnum;
  operator: StringFilters2;
  value: Scalars['String']['input'];
};

export type EmailTypeModel = {
  __typename?: 'EmailTypeModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EmailTypeOrderByInput = {
  field: OrderEmailTypeEnum;
  sort: OrderTypes;
};

export type EmailUserModel = {
  __typename?: 'EmailUserModel';
  email?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

export type ExportRequestResponseDto = {
  __typename?: 'ExportRequestResponseDTO';
  link?: Maybe<Scalars['String']['output']>;
  requestId?: Maybe<Scalars['String']['output']>;
  requestedFields?: Maybe<Array<CompanyModel>>;
  status?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type ExportSearchInput = {
  export_fields: Array<ContactGridExportNameEnum>;
  export_format?: ContactGridExportFormatEnum;
};

export type FactoryTouchesReport = {
  __typename?: 'FactoryTouchesReport';
  results?: Maybe<Array<FactoryTouchesResults>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type FactoryTouchesResults = {
  __typename?: 'FactoryTouchesResults';
  company: Scalars['String']['output'];
  touches: Scalars['Int']['output'];
};

export type FailedReasonCriteriaInput = {
  filter?: InputMaybe<Array<FailedReasonFilterInput>>;
  orderBy?: InputMaybe<Array<FailedReasonOrderByInput>>;
  pagination: PaginationInput2;
};

export type FailedReasonElasticResponse = {
  __typename?: 'FailedReasonElasticResponse';
  results?: Maybe<Array<SubTableByCompanyElasticResponse>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type FailedReasonFilterInput = {
  dataType: ValueType;
  fieldName: FilterFailedReasonEnum;
  operator: StringFilters2;
  value: Scalars['String']['input'];
};

export type FailedReasonOrderByInput = {
  field: OrderFailedReasonEnum;
  sort: OrderTypes;
};

export type FailedReasonOutputDto = {
  __typename?: 'FailedReasonOutputDto';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  company?: Maybe<CompanyModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum FilterAdditionalFieldsEnum {
  Description = 'description',
  Name = 'name',
}

export enum FilterAddressTypeEnum {
  Description = 'description',
  Name = 'name',
}

export enum FilterBuyGroupEnum {
  Description = 'description',
  Name = 'name',
}

export enum FilterCallPatternEnum {
  Description = 'description',
  Name = 'name',
}

export enum FilterCategoryEnum {
  Description = 'description',
  Name = 'name',
}

export enum FilterCloseReasonEnum {
  Name = 'name',
}

export enum FilterCompanyEnum {
  CallPattern = 'callPattern',
  CallPatternName = 'callPatternName',
  Category = 'category',
  CategoryName = 'categoryName',
  Comments = 'comments',
  CompanyClass = 'companyClass',
  CompanyClassName = 'companyClassName',
  CompanyType = 'companyType',
  CompanyTypeName = 'companyTypeName',
  Datasource = 'datasource',
  HasDwin = 'hasDwin',
  HasForecast = 'hasForecast',
  HasHolydayCard = 'hasHolydayCard',
  HasPartNumberSplit = 'hasPartNumberSplit',
  HasPosAccount = 'hasPosAccount',
  HasQuot = 'hasQuot',
  HasRegi = 'hasRegi',
  HasSamp = 'hasSamp',
  HasSecondaryAlias = 'hasSecondaryAlias',
  ImpBatch = 'impBatch',
  IsActive = 'isActive',
  MainAddress = 'mainAddress',
  MainAddressAddress = 'mainAddressAddress',
  MainAddressAddressInvalid = 'mainAddressAddressInvalid',
  MainAddressCity = 'mainAddressCity',
  MainAddressContinent = 'mainAddressContinent',
  MainAddressContinentName = 'mainAddressContinentName',
  MainAddressCountry = 'mainAddressCountry',
  MainAddressCountryName = 'mainAddressCountryName',
  MainAddressFormattedAddress = 'mainAddressFormattedAddress',
  MainAddressLatitude = 'mainAddressLatitude',
  MainAddressLongitude = 'mainAddressLongitude',
  MainAddressPoBox = 'mainAddressPoBox',
  MainAddressRegion = 'mainAddressRegion',
  MainAddressRegionName = 'mainAddressRegionName',
  MainAddressState = 'mainAddressState',
  MainAddressStateName = 'mainAddressStateName',
  MainAddressTerritory = 'mainAddressTerritory',
  MainAddressTerritoryName = 'mainAddressTerritoryName',
  MainAddressType = 'mainAddressType',
  MainAddressTypeName = 'mainAddressTypeName',
  MainAddressZipCode = 'mainAddressZipCode',
  MainContactId = 'mainContactId',
  MainPhone = 'mainPhone',
  MainPhoneAreaCode = 'mainPhoneAreaCode',
  MainPhoneCountryCode = 'mainPhoneCountryCode',
  MainPhoneExtension = 'mainPhoneExtension',
  MainPhonePhone = 'mainPhonePhone',
  MainPhoneType = 'mainPhoneType',
  MainPhoneTypeName = 'mainPhoneTypeName',
  Name = 'name',
  Parent = 'parent',
  ParentName = 'parentName',
  PoFooter = 'poFooter',
  PrintName = 'printName',
  PrivateTeam = 'privateTeam',
  PrivateTeamName = 'privateTeamName',
  QuoteFooter = 'quoteFooter',
  Rank = 'rank',
  RankName = 'rankName',
  RefNumber = 'refNumber',
  SalesTeamName = 'salesTeamName',
  ShipDetail = 'shipDetail',
  ShortName = 'shortName',
  VisitFrequency = 'visitFrequency',
  Website = 'website',
}

export enum FilterCompanyRankEnum {
  Description = 'description',
  Name = 'name',
}

export enum FilterCompanySplitTypesEnum {
  Description = 'description',
  Name = 'name',
}

export enum FilterCompanyTierEnum {
  Description = 'description',
  Name = 'name',
}

export enum FilterCompanyTypeEnum {
  Color = 'color',
  CompanyTypeAliasId = 'company_type_alias_id',
  CompanyTypeAliasName = 'company_type_alias_name',
  IsPredefined = 'is_predefined',
  Name = 'name',
  ParentId = 'parent_id',
  ParentName = 'parent_name',
}

export enum FilterContactEnum {
  BusinessInfoCompanyMainAddressAddress = 'businessInfoCompanyMainAddressAddress',
  BusinessInfoCompanyMainAddressCity = 'businessInfoCompanyMainAddressCity',
  BusinessInfoCompanyMainAddressRegionNames = 'businessInfoCompanyMainAddressRegionNames',
  BusinessInfoCompanyMainAddressState = 'businessInfoCompanyMainAddressState',
  BusinessInfoCompanyMainAddressZipCode = 'businessInfoCompanyMainAddressZipCode',
  BusinessInfoCompanyName = 'businessInfoCompanyName',
  BusinessInfoCompanySalesTeamName = 'businessInfoCompanySalesTeamName',
  BusinessInfoCompanyTypeName = 'businessInfoCompanyTypeName',
  BusinessInfoMainEmail = 'businessInfoMainEmail',
  BusinessInfoMainPhone = 'businessInfoMainPhone',
  BusinessInfoMobile = 'businessInfoMobile',
  FirstName = 'firstName',
  LastName = 'lastName',
  Notes = 'notes',
  Title = 'title',
}

export enum FilterContactGroupEnum {
  Name = 'name',
}

export enum FilterContactNestEnum {
  BusinessInfoCompanyId = 'businessInfoCompanyId',
  BusinessInfoCompanyName = 'businessInfoCompanyName',
  BusinessInfoCompanySalesTeamId = 'businessInfoCompanySalesTeamId',
  BusinessInfoCompanySalesTeamName = 'businessInfoCompanySalesTeamName',
  BusinessInfoCompanyTypeId = 'businessInfoCompanyTypeId',
  BusinessInfoCompanyTypeName = 'businessInfoCompanyTypeName',
  BusinessInfoId = 'businessInfoId',
  BusinessInfoJobTitle = 'businessInfoJobTitle',
  BusinessInfoMainAddressAddress = 'businessInfoMainAddressAddress',
  BusinessInfoMainAddressCity = 'businessInfoMainAddressCity',
  BusinessInfoMainAddressId = 'businessInfoMainAddressId',
  BusinessInfoMainAddressRegionId = 'businessInfoMainAddressRegionId',
  BusinessInfoMainAddressRegionName = 'businessInfoMainAddressRegionName',
  BusinessInfoMainAddressStateId = 'businessInfoMainAddressStateId',
  BusinessInfoMainAddressStateName = 'businessInfoMainAddressStateName',
  BusinessInfoMainAddressZipCode = 'businessInfoMainAddressZipCode',
  BusinessInfoMainPhoneId = 'businessInfoMainPhoneId',
  BusinessInfoMainPhonePhone = 'businessInfoMainPhonePhone',
  Enabled = 'enabled',
  FirstName = 'firstName',
  FullName = 'fullName',
  GroupId = 'groupId',
  GroupName = 'groupName',
  Id = 'id',
  LastName = 'lastName',
  Title = 'title',
}

export enum FilterCountryEnum {
  Name = 'name',
}

export enum FilterEmailTypeEnum {
  Description = 'description',
  Name = 'name',
}

export enum FilterFailedReasonEnum {
  Name = 'name',
}

export enum FilterIndustryEnum {
  Description = 'description',
  Name = 'name',
}

export enum FilterLeadSourceEnum {
  Name = 'name',
}

export enum FilterLineItemStatusEnum {
  Name = 'name',
}

export enum FilterOpportunitiesEnum {
  Name = 'name',
}

export enum FilterPhoneTypeEnum {
  Description = 'description',
  Name = 'name',
}

export enum FilterPrivateTeamEnum {
  Name = 'name',
}

export enum FilterProductInterestEnum {
  Name = 'name',
}

export enum FilterProductPotentialEnum {
  Description = 'description',
  Name = 'name',
}

export enum FilterRegionEnum {
  Name = 'name',
}

export enum FilterRelatedCategoryEnum {
  Description = 'description',
  Name = 'name',
}

export enum FilterRelatedCompanyEnum {
  Company = 'company',
  CompanyManufacturer = 'companyManufacturer',
  CustomerSplit = 'customerSplit',
  RelatedCategory = 'relatedCategory',
  RelatedCompany = 'relatedCompany',
  Type = 'type',
  ValueRole = 'valueRole',
}

export enum FilterSalesTeamEnum {
  CompanySplit = 'companySplit',
  CompanySplitTypeName = 'companySplitTypeName',
  Name = 'name',
  SalesTeamTypeName = 'salesTeamTypeName',
  SpecifierSplit = 'specifierSplit',
}

export enum FilterSalesTeamTypesEnum {
  Description = 'description',
  Name = 'name',
}

export enum FilterStageEnum {
  Name = 'name',
}

export enum FilterStageGroupEnum {
  Name = 'name',
}

export enum FilterStatusEnum {
  Name = 'name',
}

export enum FilterTerritoryEnum {
  Name = 'name',
}

export enum FilterTypeEnum {
  Name = 'name',
}

export type FindAdditionalFieldsInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindAddressTypeInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindBuyingGroupInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindCallPatternInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindCategoryInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindCloseReasonInput = {
  company_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  company_names?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindCompanyRankInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindCompanySplitTypesInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindCompanyTierInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindCompanyTypeInput = {
  colors?: InputMaybe<Array<Scalars['String']['input']>>;
  company_type_alias_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  company_type_alias_names?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  parent_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  parent_names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindContactGroupInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindContactInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindContactNestInput = {
  businessinfo_company_companytype_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  businessinfo_company_companytype_names?: InputMaybe<Array<Scalars['String']['input']>>;
  businessinfo_company_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  businessinfo_company_names?: InputMaybe<Array<Scalars['String']['input']>>;
  businessinfo_company_salesteam_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  businessinfo_company_salesteam_names?: InputMaybe<Array<Scalars['String']['input']>>;
  businessinfo_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  businessinfo_job_titles?: InputMaybe<Array<Scalars['String']['input']>>;
  businessinfo_mainaddress_addresses?: InputMaybe<Array<Scalars['String']['input']>>;
  businessinfo_mainaddress_cities?: InputMaybe<Array<Scalars['String']['input']>>;
  businessinfo_mainaddress_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  businessinfo_mainaddress_region_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  businessinfo_mainaddress_region_names?: InputMaybe<Array<Scalars['String']['input']>>;
  businessinfo_mainaddress_state_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  businessinfo_mainaddress_state_names?: InputMaybe<Array<Scalars['String']['input']>>;
  businessinfo_mainaddress_zipcodes?: InputMaybe<Array<Scalars['String']['input']>>;
  businessinfo_mainemail_email_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  businessinfo_mainemail_emails?: InputMaybe<Array<Scalars['String']['input']>>;
  businessinfo_mainphone_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  businessinfo_mainphone_phones?: InputMaybe<Array<Scalars['Int']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  first_names?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  last_names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindCountryInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindEmailTypeInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindFailedReasonInput = {
  company_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  company_names?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindIndustryInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindLeadSourceInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindLineItemStatusInput = {
  company_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  company_names?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindOpportunitiesInput = {
  bids?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  closed_reason_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  closed_reason_names?: InputMaybe<Array<Scalars['Float']['input']>>;
  closed_status_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  closed_status_names?: InputMaybe<Array<Scalars['String']['input']>>;
  create_at_dates?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  customer_cities?: InputMaybe<Array<Scalars['String']['input']>>;
  customer_company_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  customer_company_names?: InputMaybe<Array<Scalars['String']['input']>>;
  customer_contact_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  customer_contact_names?: InputMaybe<Array<Scalars['String']['input']>>;
  customer_states?: InputMaybe<Array<Scalars['String']['input']>>;
  distributor_company_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  distributor_company_names?: InputMaybe<Array<Scalars['String']['input']>>;
  distributor_contact_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  distributor_contact_names?: InputMaybe<Array<Scalars['String']['input']>>;
  estimated_close_dates?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  follow_ups?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lead_source_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  lead_source_names?: InputMaybe<Array<Scalars['String']['input']>>;
  manufacturer_company_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  manufacturer_company_names?: InputMaybe<Array<Scalars['String']['input']>>;
  manufacturer_contact_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  manufacturer_contact_names?: InputMaybe<Array<Scalars['String']['input']>>;
  next_steps?: InputMaybe<Array<Scalars['String']['input']>>;
  opp_owner_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  opp_owner_names?: InputMaybe<Array<Scalars['String']['input']>>;
  opp_stage_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  opp_stage_names?: InputMaybe<Array<Scalars['Float']['input']>>;
  opp_status_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  opp_status_names?: InputMaybe<Array<Scalars['String']['input']>>;
  priorities?: InputMaybe<Array<Scalars['Float']['input']>>;
  sales_team_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  sales_team_names?: InputMaybe<Array<Scalars['String']['input']>>;
  topics?: InputMaybe<Array<Scalars['String']['input']>>;
  type_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  type_names?: InputMaybe<Array<Scalars['String']['input']>>;
  update_by_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  updated_at_dates?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  values?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FindPhoneTypeInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindPrivateTeamInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindProductInterestInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindProductPotentialInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindRegionInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindRelatedCategoryInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindRelatedCompanyInput = {
  companyManufacturers?: InputMaybe<Array<Scalars['Int']['input']>>;
  customerSplits?: InputMaybe<Array<Scalars['Int']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  relatedCategories?: InputMaybe<Array<Scalars['Int']['input']>>;
  relatedCompanies?: InputMaybe<Array<Scalars['Int']['input']>>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
  valueRoles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindReportListInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  extraParams?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  reportRoutes?: InputMaybe<Array<Scalars['String']['input']>>;
  reportTags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindRolesInput = {
  createdByUsers?: InputMaybe<Array<Scalars['Float']['input']>>;
  disabledByUsers?: InputMaybe<Array<Scalars['Float']['input']>>;
  enabled?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  tenantIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  updatedByUsers?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FindSalesTeamInput = {
  companySplits?: InputMaybe<Array<Scalars['Float']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  specifierSplits?: InputMaybe<Array<Scalars['Float']['input']>>;
  splitType_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  splitType_names?: InputMaybe<Array<Scalars['String']['input']>>;
  type_ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  type_names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindSalesTeamTypesInput = {
  descriptions?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindStageGroupInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindStageInput = {
  company_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  company_names?: InputMaybe<Array<Scalars['String']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindStatusInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindTenantInstanceInput = {
  clientIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  cognitoClientIds?: InputMaybe<Array<Scalars['String']['input']>>;
  cognitoPoolIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['Float']['input']>>;
  instanceNames?: InputMaybe<Array<Scalars['String']['input']>>;
  tenantIds?: InputMaybe<Array<Scalars['Float']['input']>>;
  tenantNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindTerritoryInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindTypeInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindUserLandingWidget = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  isDefault?: InputMaybe<Scalars['String']['input']>;
  savedNames?: InputMaybe<Array<Scalars['String']['input']>>;
  tenantIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type FindUserReportPreferenceInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  savedNames?: InputMaybe<Array<Scalars['String']['input']>>;
  tenantIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type FindUserReportWidgetInput = {
  extraParams?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  images?: InputMaybe<Array<Scalars['String']['input']>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  reportIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  tenantIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  userIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type FindUsersInput = {
  createdByUsers?: InputMaybe<Array<Scalars['Int']['input']>>;
  disabledByUsers?: InputMaybe<Array<Scalars['Int']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
  roles?: InputMaybe<Array<Scalars['Int']['input']>>;
  tenantIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  updatedByUsers?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type GetAllSalesTeamMembersResponseModel = {
  __typename?: 'GetAllSalesTeamMembersResponseModel';
  results?: Maybe<Array<GetAllSalesTeamModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type GetAllSalesTeamModel = {
  __typename?: 'GetAllSalesTeamModel';
  companySplit?: Maybe<Scalars['Float']['output']>;
  companySplitType?: Maybe<CompanySplitTypesModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  leaderSalesTeamId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  specifierSplit?: Maybe<Scalars['Float']['output']>;
  teamMembers?: Maybe<Array<Maybe<SalesTeamMemberModel>>>;
  type?: Maybe<SalesTeamTypesModel>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GridPreferenceModel = {
  __typename?: 'GridPreferenceModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  gridName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Scalars['JSON']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId: Scalars['String']['output'];
};

export type IIndustry = {
  __typename?: 'IIndustry';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type IndustryCriteriaInput = {
  filter?: InputMaybe<Array<IndustryFilterInput>>;
  orderBy?: InputMaybe<Array<IndustryOrderByInput>>;
  pagination: PaginationInput2;
};

export type IndustryFilterInput = {
  dataType: ValueType;
  fieldName: FilterIndustryEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type IndustryOrderByInput = {
  field: OrderIndustryEnum;
  sort: OrderTypes;
};

export type InstanceSettings = {
  __typename?: 'InstanceSettings';
  allowActivityJournals?: Maybe<Scalars['Boolean']['output']>;
  allowCommissions?: Maybe<Scalars['Boolean']['output']>;
  allowOrders?: Maybe<Scalars['Boolean']['output']>;
  allowQuotes?: Maybe<Scalars['Boolean']['output']>;
  allowSales?: Maybe<Scalars['Boolean']['output']>;
  allowSalesAndCommissions?: Maybe<Scalars['Boolean']['output']>;
};

export type InstanceSettingsInput = {
  allowActivityJournals?: InputMaybe<Scalars['Boolean']['input']>;
  allowCommissions?: InputMaybe<Scalars['Boolean']['input']>;
  allowOrders?: InputMaybe<Scalars['Boolean']['input']>;
  allowQuotes?: InputMaybe<Scalars['Boolean']['input']>;
  allowSales?: InputMaybe<Scalars['Boolean']['input']>;
  allowSalesAndCommissions?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum Issuer {
  BackOffice = 'BackOffice',
  Mobile = 'Mobile',
  Saas = 'Saas',
  ThirdParty = 'ThirdParty',
}

export type LandingWidget = {
  __typename?: 'LandingWidget';
  height: Scalars['Float']['output'];
  maxH: Scalars['Float']['output'];
  maxW: Scalars['Float']['output'];
  minH: Scalars['Float']['output'];
  minW: Scalars['Float']['output'];
  widgetId: Scalars['Int']['output'];
  width: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type LandingWidgetInput = {
  height: Scalars['Float']['input'];
  maxH: Scalars['Float']['input'];
  maxW: Scalars['Float']['input'];
  minH: Scalars['Float']['input'];
  minW: Scalars['Float']['input'];
  widgetId: Scalars['Float']['input'];
  width: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type LeadSource = {
  __typename?: 'LeadSource';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type LeadSourceCriteriaInput = {
  filter?: InputMaybe<Array<LeadSourceFilterInput>>;
  orderBy?: InputMaybe<Array<LeadSourceOrderByInput>>;
  pagination: PaginationInput2;
};

export type LeadSourceElasticResponse = {
  __typename?: 'LeadSourceElasticResponse';
  results?: Maybe<Array<LeadSourceOutputDto>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type LeadSourceFilterInput = {
  dataType: ValueType;
  fieldName: FilterLeadSourceEnum;
  operator: StringFilters2;
  value: Scalars['String']['input'];
};

export type LeadSourceOrderByInput = {
  field: OrderLeadSourceEnum;
  sort: OrderTypes;
};

export type LeadSourceOutputDto = {
  __typename?: 'LeadSourceOutputDto';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LineItemStatusCriteriaInput = {
  filter?: InputMaybe<Array<LineItemStatusFilterInput>>;
  orderBy?: InputMaybe<Array<LineItemStatusOrderByInput>>;
  pagination: PaginationInput2;
};

export type LineItemStatusElasticResponse = {
  __typename?: 'LineItemStatusElasticResponse';
  results?: Maybe<Array<SubTableByCompanyElasticResponse>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type LineItemStatusFilterInput = {
  dataType: ValueType;
  fieldName: FilterLineItemStatusEnum;
  operator: StringFilters2;
  value: Scalars['String']['input'];
};

export type LineItemStatusOrderByInput = {
  field: OrderLineItemStatusEnum;
  sort: OrderTypes;
};

export type LineItemStatusOutputDto = {
  __typename?: 'LineItemStatusOutputDto';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  company?: Maybe<CompanyModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LocationModel = {
  __typename?: 'LocationModel';
  lat: Scalars['Float']['output'];
  long: Scalars['Float']['output'];
};

export type LocationPoint = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type MemberModel = {
  __typename?: 'MemberModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MenuReport = {
  __typename?: 'MenuReport';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  extraParams?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  reportRoute: Scalars['String']['output'];
  reportTag: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  widgets: Array<UserReportWidgetList>;
};

export type MergeCompanyInput = {
  delete?: Scalars['Boolean']['input'];
  idCompanyFrom: Scalars['Int']['input'];
  idCompanyTo: Scalars['Int']['input'];
};

export type MonthlySalesReport = {
  __typename?: 'MonthlySalesReport';
  results?: Maybe<Array<MonthlySalesReportResult>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type MonthlySalesReportResult = {
  __typename?: 'MonthlySalesReportResult';
  month?: Maybe<Scalars['String']['output']>;
  sales?: Maybe<Scalars['String']['output']>;
  salesAvg?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  AssignRole: Role;
  ChangeSalesTeam: Scalars['Boolean']['output'];
  CompanyBatchRemove: Array<CompanyBatchResultModel>;
  CompanyBatchUpdate: CompanyBatchResultModel;
  ConfirmUser?: Maybe<CognitoConfirmResponseModel>;
  ContactsBatchRemove: Array<ContactBatchResultModel>;
  ContactsBatchUpdate: Array<ContactBatchResultModel>;
  CreateAdditionalField: AdditionalFieldModel;
  CreateAddressType: AddressTypeModel;
  CreateAttachmentType: AttachmentTypeModel;
  CreateBuyGroup: BuyGroupModel;
  CreateCallPattern: CallPatternModel;
  CreateColumn: ColumnModel;
  CreateCompany: CompanyModel;
  CreateCompanyAdditionalField: CompanyAdditionalFieldModel;
  CreateCompanyAttachment: CompanyAttachmentModel;
  CreateCompanyCategory: CompanyCategoryDbModel;
  CreateCompanyClass: CompanyClassDbModel;
  CreateCompanyDivision: CompanyDivisionModel;
  CreateCompanyDocNotes: CompanyDocNotesModel;
  CreateCompanyPitch: CompanyPitchesModel;
  CreateCompanyRank: CompanyRankDbModel;
  CreateCompanySalesTeam: CompanySalesTeamModel;
  CreateCompanySplitType: CompanySplitTypesModel;
  CreateCompanyTag: CompanyTagsModel;
  CreateCompanyTier: CompanyTierModel;
  CreateCompanyTypeAlias: CompanyTypeAliasModel;
  CreateContact: ContactOutputDto;
  CreateContactAdditionalFieldsContact: ContactAdditionalFieldsContactModel;
  CreateContactGroup: ContactGroupModel;
  CreateContactGroupContact: ContactGroupContactModel;
  CreateContinent: ContinentDbModel;
  CreateCountry: CountryDbModel;
  CreateCustomerPriceCode: CustomerPriceCodesModel;
  CreateCustomerPriceGroup: CustomerPriceGroupsModel;
  CreateDepartment: DepartmentModel;
  CreateDivision: DivisionDbModel;
  CreateEmailServer: EmailServerModel;
  CreateEmailType: EmailTypeModel;
  CreateGridPreference: GridPreferenceModel;
  CreateIndustry: CompanyIndustryDbModel;
  CreateLeadSource: LeadSourceOutputDto;
  CreateOpportunities: OpportunitiesDto;
  CreateOpportunityCloseReason: CloseReasonOutputDto;
  CreateOpportunityFailedReason: FailedReasonOutputDto;
  CreateOpportunityLineItemStatus: LineItemStatusOutputDto;
  CreateOpportunityStage: StageOutputDto;
  CreateOpportunityStatus: StatusOutputDto;
  CreateOpportunityType: TypeOutputDto;
  CreatePhoneType: PhoneTypeModel;
  CreatePrivateTeam: PrivateTeamModel;
  CreatePrivateTeamType: PrivateTeamTypeModel;
  CreateProductInterest: ProductInterestModel;
  CreateProductPotential: CompanyProductPotentialModel;
  CreateRegion: RegionModel;
  CreateRelatedCategory: RelatedCategoryModel;
  CreateRelatedCompany: RelatedCompanyDbModel;
  CreateRole: Role;
  CreateSalesTeam: SalesTeamModel;
  CreateSalesTeamType: SalesTeamTypesModel;
  CreateStageGroup: StageGroupOutputDto;
  CreateState: StateDbModel;
  CreateTerritory: TerritoryDbModel;
  CreateUser: User;
  CreateUserAdditionalField: UserAdditionalFieldModel;
  CreateUserAdditionalFieldsUser: UserAdditionalFieldsUserModel;
  CreateUserLandingWidget: UserLandingWidget;
  CreateUserMenu: UserMenuModel;
  CreateUserReportList: ReportListModel;
  CreateUserReportPreference: UserReportPreference;
  CreateUserReportWidgets: UserReportWidgetList;
  CreateUserRole: UserRoleModel;
  CreateUserType: UserTypeModel;
  DeleteColumn: DeletedOkModel;
  DeleteOneUserLandingWidget: UserLandingWidget;
  DeleteOneUserReportWidgetList: UserReportWidgetList;
  DisableRole: Role;
  DisableUser: User;
  EnableRole: Role;
  EnableUser: User;
  FindRoles: Array<Role>;
  FindUsers: Array<User>;
  MergeCompanyTo: Scalars['Boolean']['output'];
  RegisterUser: Scalars['Boolean']['output'];
  RemoveAdditionalField: Scalars['Boolean']['output'];
  RemoveAddressType: Scalars['Boolean']['output'];
  RemoveAttachmentType: Scalars['Boolean']['output'];
  RemoveBuyGroup: Scalars['Boolean']['output'];
  RemoveCallPattern: Scalars['Boolean']['output'];
  RemoveCompany: Scalars['Boolean']['output'];
  RemoveCompanyAdditionalField: Scalars['Boolean']['output'];
  RemoveCompanyAddress: Scalars['Boolean']['output'];
  RemoveCompanyAttachment: Scalars['Boolean']['output'];
  RemoveCompanyCategory: Scalars['Boolean']['output'];
  RemoveCompanyClass: Scalars['Boolean']['output'];
  RemoveCompanyDivision: Scalars['Boolean']['output'];
  RemoveCompanyDocNotes: Scalars['Boolean']['output'];
  RemoveCompanyFromElastic: Scalars['Boolean']['output'];
  RemoveCompanyPhone: Scalars['Boolean']['output'];
  RemoveCompanyPitch: Scalars['Boolean']['output'];
  RemoveCompanyRank: Scalars['Boolean']['output'];
  RemoveCompanySalesTeam: Scalars['Boolean']['output'];
  RemoveCompanySplitType: Scalars['Boolean']['output'];
  RemoveCompanyTag: Scalars['Boolean']['output'];
  RemoveCompanyTier: Scalars['Boolean']['output'];
  RemoveCompanyTypeAlias: Scalars['Boolean']['output'];
  RemoveContact: Scalars['Boolean']['output'];
  RemoveContactAdditionalFieldsContact: Scalars['Boolean']['output'];
  RemoveContactGroup: Scalars['Boolean']['output'];
  RemoveContactGroupContact: Scalars['Boolean']['output'];
  RemoveContinent: Scalars['Boolean']['output'];
  RemoveCountry: Scalars['Boolean']['output'];
  RemoveCustomerPriceCode: Scalars['Boolean']['output'];
  RemoveCustomerPriceGroup: Scalars['Boolean']['output'];
  RemoveDepartment: Scalars['Boolean']['output'];
  RemoveDivision: Scalars['Boolean']['output'];
  RemoveEmailServer: Scalars['Boolean']['output'];
  RemoveEmailType: Scalars['Boolean']['output'];
  RemoveGridPreference: Scalars['Boolean']['output'];
  RemoveIndustry: Scalars['Boolean']['output'];
  RemoveLeadSource: Scalars['Boolean']['output'];
  RemoveOpportunities: Scalars['Boolean']['output'];
  RemoveOpportunityCloseReason: Scalars['Boolean']['output'];
  RemoveOpportunityFailedReason: Scalars['Boolean']['output'];
  RemoveOpportunityLineItemStatus: Scalars['Boolean']['output'];
  RemoveOpportunityStage: Scalars['Boolean']['output'];
  RemoveOpportunityStatus: Scalars['Boolean']['output'];
  RemoveOpportunityType: Scalars['Boolean']['output'];
  RemovePhoneType: Scalars['Boolean']['output'];
  RemovePrivateTeam: Scalars['Boolean']['output'];
  RemovePrivateTeamType: Scalars['Boolean']['output'];
  RemoveProductInterest: Scalars['Boolean']['output'];
  RemoveProductPotential: Scalars['Boolean']['output'];
  RemoveRegion: Scalars['Boolean']['output'];
  RemoveRelatedCategory: Scalars['Boolean']['output'];
  RemoveRelatedCompany: Scalars['Boolean']['output'];
  RemoveSalesTeam: Scalars['Boolean']['output'];
  RemoveSalesTeamMember: Scalars['Boolean']['output'];
  RemoveSalesTeamType: Scalars['Boolean']['output'];
  RemoveStageGroup: Scalars['Boolean']['output'];
  RemoveState: Scalars['Boolean']['output'];
  RemoveTenantInstance: TenantInstance;
  RemoveTerritory: Scalars['Boolean']['output'];
  RemoveUser: Scalars['Boolean']['output'];
  RemoveUserAdditionalField: Scalars['Boolean']['output'];
  RemoveUserAdditionalFieldsUser: Scalars['Boolean']['output'];
  RemoveUserMenu: Scalars['Boolean']['output'];
  RemoveUserReportListModel: ReportListModel;
  RemoveUserReportPreference: UserReportPreference;
  RemoveUserRole: Scalars['Boolean']['output'];
  RemoveUserType: Scalars['Boolean']['output'];
  SetDefaultGridPreference: Scalars['String']['output'];
  UpdateAdditionalField: AdditionalFieldModel;
  UpdateAddressType: AddressTypeModel;
  UpdateAttachmentType: AttachmentTypeModel;
  UpdateBuyGroup: BuyGroupModel;
  UpdateCallPattern: CallPatternModel;
  UpdateColumn: ColumnModel;
  UpdateCompanies: Scalars['JSON']['output'];
  UpdateCompaniesInline: Scalars['JSON']['output'];
  UpdateCompany: CompanyModel;
  UpdateCompanyAdditionalField: CompanyAdditionalFieldModel;
  UpdateCompanyAttachment: CompanyAttachmentModel;
  UpdateCompanyCategory: CompanyCategoryDbModel;
  UpdateCompanyClass: CompanyClassDbModel;
  UpdateCompanyDivision: CompanyDivisionModel;
  UpdateCompanyDocNotes: CompanyDocNotesModel;
  UpdateCompanyPitch: CompanyPitchesModel;
  UpdateCompanyRank: CompanyRankDbModel;
  UpdateCompanySalesTeam: CompanySalesTeamModel;
  UpdateCompanySplitType: CompanySplitTypesModel;
  UpdateCompanyTag: CompanyTagsModel;
  UpdateCompanyTier: CompanyTierModel;
  UpdateCompanyTypeAlias: CompanyTypeAliasModel;
  UpdateContact: ContactOutputDto;
  UpdateContactAdditionalField: ContactAdditionalFieldsContactModel;
  UpdateContactGroup: ContactGroupModel;
  UpdateContactGroupContact: ContactGroupContactModel;
  UpdateContinent: ContinentDbModel;
  UpdateCountry: CountryDbModel;
  UpdateCustomerPriceCode: CustomerPriceCodesModel;
  UpdateCustomerPriceGroup: CustomerPriceGroupsModel;
  UpdateDepartment: DepartmentModel;
  UpdateDivision: DivisionDbModel;
  UpdateEmailServer: EmailServerModel;
  UpdateEmailType: EmailTypeModel;
  UpdateGridPreference: GridPreferenceModel;
  UpdateIndustry: CompanyIndustryDbModel;
  UpdateLeadSource: LeadSourceOutputDto;
  UpdateOneTenantInstance: TenantInstance;
  UpdateOpportunities: OpportunitiesDto;
  UpdateOpportunityCloseReason: CloseReasonOutputDto;
  UpdateOpportunityFailedReason: FailedReasonOutputDto;
  UpdateOpportunityLineItemStatus: LineItemStatusOutputDto;
  UpdateOpportunityStage: StageOutputDto;
  UpdateOpportunityStatus: StatusOutputDto;
  UpdateOpportunityType: TypeOutputDto;
  UpdatePhoneType: PhoneTypeModel;
  UpdatePrivateTeam: PrivateTeamModel;
  UpdatePrivateTeamType: PrivateTeamTypeModel;
  UpdateProductInterest: ProductInterestModel;
  UpdateProductPotential: CompanyProductPotentialModel;
  UpdateRegion: RegionModel;
  UpdateRelatedCategory: RelatedCategoryModel;
  UpdateRelatedCompany: RelatedCompanyDbModel;
  UpdateRole: Role;
  UpdateSalesTeam: SalesTeamModel;
  UpdateSalesTeamType: SalesTeamTypesModel;
  UpdateStageGroup: StageGroupOutputDto;
  UpdateState: StateDbModel;
  UpdateTerritory: TerritoryDbModel;
  UpdateUser: User;
  UpdateUserAdditionalField: UserAdditionalFieldModel;
  UpdateUserAdditionalFieldsUser: UserAdditionalFieldsUserModel;
  UpdateUserLandingWidget: UserLandingWidget;
  UpdateUserMenu: UserMenuModel;
  UpdateUserReportListModel: ReportListModel;
  UpdateUserReportPreference: UserReportPreference;
  UpdateUserReportWidgetList: UserReportWidgetList;
  UpdateUserRole: UserRoleModel;
  UpdateUserType: UserTypeModel;
  UploadLogo: TenantInstance;
  createAnalyticsWideSale: AnalyticsWideSale;
  createTenantInstance: TenantInstance;
  registerSalesTeamUser: Array<RegisterUserSalesTeamModel>;
  removeAnalyticsWideSale: AnalyticsWideSale;
  updateAnalyticsWideSale: AnalyticsWideSale;
};

export type MutationAssignRoleArgs = {
  assignRole: AssignRoleInput;
};

export type MutationChangeSalesTeamArgs = {
  changeSalesTeamInput: ChangeSalesTeamInput;
};

export type MutationCompanyBatchRemoveArgs = {
  ids: Array<Scalars['Int']['input']>;
};

export type MutationCompanyBatchUpdateArgs = {
  updateCompanyBatchInput: UpdateCompanyBatchInput;
};

export type MutationConfirmUserArgs = {
  confirmUserInput: ConfirmUserInput;
};

export type MutationContactsBatchRemoveArgs = {
  ids: Array<Scalars['Int']['input']>;
};

export type MutationContactsBatchUpdateArgs = {
  updateContactBatchInput: UpdateContactBatchInput;
};

export type MutationCreateAdditionalFieldArgs = {
  createAdditionalFieldInput: CreateAdditionalFieldInput;
};

export type MutationCreateAddressTypeArgs = {
  createAddressTypeInput: CreateAddressTypeInput;
};

export type MutationCreateAttachmentTypeArgs = {
  createAttachmentTypeInput: CreateAttachmentTypeInput;
};

export type MutationCreateBuyGroupArgs = {
  createBuyGroupInput: CreateBuyGroupInput;
};

export type MutationCreateCallPatternArgs = {
  createCompanyCallPatternInput: CreateCallPatternInput;
};

export type MutationCreateColumnArgs = {
  createColumnInput: CreateColumnInput;
};

export type MutationCreateCompanyArgs = {
  createCompanyInput: CreateCompanyInput;
};

export type MutationCreateCompanyAdditionalFieldArgs = {
  createCompanyAdditionalFieldInput: CreateCompanyAdditionalFieldInput;
};

export type MutationCreateCompanyAttachmentArgs = {
  createCompanyAttachmentInput: CreateCompanyAttachmentInput;
};

export type MutationCreateCompanyCategoryArgs = {
  createCompanyCategoryInput: CreateCompanyCategoryInput;
};

export type MutationCreateCompanyClassArgs = {
  createCompanyClassInput: CreateCompanyClassInput;
};

export type MutationCreateCompanyDivisionArgs = {
  createCompanyDivisionInput: CreateCompanyDivisionInput;
};

export type MutationCreateCompanyDocNotesArgs = {
  createCompanyDocNotesInput: CreateCompanyDocNotesInput;
};

export type MutationCreateCompanyPitchArgs = {
  createCompanyPitchInput: CreateCompanyPitchInput;
};

export type MutationCreateCompanyRankArgs = {
  createCompanyRankInput: CreateCompanyRankInput;
};

export type MutationCreateCompanySalesTeamArgs = {
  createCompanySalesTeamInput: CreateCompanySalesTeamInput;
};

export type MutationCreateCompanySplitTypeArgs = {
  createCompanySplitTypesInput: CreateCompanySplitTypesInput;
};

export type MutationCreateCompanyTagArgs = {
  createCompanyTagInput: CreateCompanyTagInput;
};

export type MutationCreateCompanyTierArgs = {
  createCompanyTierInput: CreateCompanyTierInput;
};

export type MutationCreateCompanyTypeAliasArgs = {
  createCompanyTypeAliasInput: CreateCompanyTypeAliasInput;
};

export type MutationCreateContactArgs = {
  createContactInput: CreateContactInput;
};

export type MutationCreateContactAdditionalFieldsContactArgs = {
  createContactAdditionalFieldsContactInput: CreateContactAdditionalFieldsContactInput;
};

export type MutationCreateContactGroupArgs = {
  createContactGroupInput: CreateContactGroupInput;
};

export type MutationCreateContactGroupContactArgs = {
  createContactGroupContactInput: CreateContactGroupContactInput;
};

export type MutationCreateContinentArgs = {
  createContinentsInput: CreateContinentsInput;
};

export type MutationCreateCountryArgs = {
  createCountryInput: CreateCountryInput;
};

export type MutationCreateCustomerPriceCodeArgs = {
  createCustomerPriceCodesInput: CreateCustomerPriceCodesInput;
};

export type MutationCreateCustomerPriceGroupArgs = {
  createCustomerPriceGroupsInput: CreateCustomerPriceGroupsInput;
};

export type MutationCreateDepartmentArgs = {
  createDepartmentInput: CreateDepartmentInput;
};

export type MutationCreateDivisionArgs = {
  createDivisionInput: CreateDivisionInput;
};

export type MutationCreateEmailServerArgs = {
  createEmailServerInput: CreateEmailServerInput;
};

export type MutationCreateEmailTypeArgs = {
  createEmailTypeInput: CreateEmailTypeInput;
};

export type MutationCreateGridPreferenceArgs = {
  createGridPreferenceInput: CreateGridPreferenceInput;
};

export type MutationCreateIndustryArgs = {
  createCompanyIndustryInput: CreateCompanyIndustryInput;
};

export type MutationCreateLeadSourceArgs = {
  createLeadSourceInput: CreateLeadSourceInput;
};

export type MutationCreateOpportunitiesArgs = {
  createOpportunitiesInput: CreateOpportunitiesInput;
};

export type MutationCreateOpportunityCloseReasonArgs = {
  createCloseReasonInput: CreateCloseReasonInput;
};

export type MutationCreateOpportunityFailedReasonArgs = {
  createFailedReasonInput: CreateFailedReasonInput;
};

export type MutationCreateOpportunityLineItemStatusArgs = {
  createLineItemStatusInput: CreateLineItemStatusInput;
};

export type MutationCreateOpportunityStageArgs = {
  createStageInput: CreateStageInput;
};

export type MutationCreateOpportunityStatusArgs = {
  createOpportunityStatusInput: CreateStatusInput;
};

export type MutationCreateOpportunityTypeArgs = {
  createOpportunityTypeInput: CreateTypeInput;
};

export type MutationCreatePhoneTypeArgs = {
  createPhoneTypeInput: CreatePhoneTypeInput;
};

export type MutationCreatePrivateTeamArgs = {
  createPrivateTeamInput: CreatePrivateTeamInput;
};

export type MutationCreatePrivateTeamTypeArgs = {
  createPrivateTeamTypeInput: CreatePrivateTeamTypeInput;
};

export type MutationCreateProductInterestArgs = {
  createProductInterestInput: CreateProductInterestInput;
};

export type MutationCreateProductPotentialArgs = {
  createProductPotentialInput: CreateProductPotentialInput;
};

export type MutationCreateRegionArgs = {
  createRegionInput: CreateRegionInput;
};

export type MutationCreateRelatedCategoryArgs = {
  relatedCategoryTerritoryInput: CreateRelatedCategoryInput;
};

export type MutationCreateRelatedCompanyArgs = {
  createRelatedCompanyInput: CreateRelatedCompanyInput;
};

export type MutationCreateRoleArgs = {
  createRoleInput: CreateRoleInput;
};

export type MutationCreateSalesTeamArgs = {
  createSalesTeamInput: CreateSalesTeamInput;
};

export type MutationCreateSalesTeamTypeArgs = {
  createSalesTeamTypesInput: CreateSalesTeamTypesInput;
};

export type MutationCreateStageGroupArgs = {
  createStageGroupInput: CreateStageGroupInput;
};

export type MutationCreateStateArgs = {
  createStateInput: CreateStateInput;
};

export type MutationCreateTerritoryArgs = {
  createTerritoryInput: CreateTerritoryInput;
};

export type MutationCreateUserArgs = {
  createUserInput: UserRegisterInput;
};

export type MutationCreateUserAdditionalFieldArgs = {
  createUserAdditionalFieldInput: CreateUserAdditionalFieldInput;
};

export type MutationCreateUserAdditionalFieldsUserArgs = {
  createUserAdditionalFieldsUserInput: CreateUserAdditionalFieldsUserInput;
};

export type MutationCreateUserLandingWidgetArgs = {
  CreateUserLandingWidget: CreateUserLandingWidget;
};

export type MutationCreateUserMenuArgs = {
  createUserMenuInput: CreateUserMenuInput;
};

export type MutationCreateUserReportListArgs = {
  createReportListInput: CreateReportListInput;
};

export type MutationCreateUserReportPreferenceArgs = {
  createUserReportPreferenceInput: CreateUserReportPreferenceInput;
};

export type MutationCreateUserReportWidgetsArgs = {
  createUserReportWidgetInput: CreateUserReportWidgetInput;
};

export type MutationCreateUserRoleArgs = {
  createUserRoleInput: CreateUserRoleInput;
};

export type MutationCreateUserTypeArgs = {
  createUserTypeInput: CreateUserTypeInput;
};

export type MutationDeleteColumnArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteOneUserLandingWidgetArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteOneUserReportWidgetListArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDisableRoleArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDisableUserArgs = {
  id: Scalars['Int']['input'];
};

export type MutationEnableRoleArgs = {
  id: Scalars['Int']['input'];
};

export type MutationEnableUserArgs = {
  id: Scalars['Int']['input'];
};

export type MutationFindRolesArgs = {
  findRolesInput: FindRolesInput;
};

export type MutationFindUsersArgs = {
  findUsersInput: FindUsersInput;
};

export type MutationMergeCompanyToArgs = {
  mergeCompanyInput: MergeCompanyInput;
};

export type MutationRegisterUserArgs = {
  registerUserInput: RegisterUserInput;
};

export type MutationRemoveAdditionalFieldArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveAddressTypeArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveAttachmentTypeArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveBuyGroupArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCallPatternArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyAdditionalFieldArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyAddressArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyAttachmentArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyCategoryArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyClassArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyDivisionArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyDocNotesArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyFromElasticArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyPhoneArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyPitchArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyRankArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanySalesTeamArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanySplitTypeArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyTagArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyTierArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCompanyTypeAliasArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveContactArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveContactAdditionalFieldsContactArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveContactGroupArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveContactGroupContactArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveContinentArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCountryArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCustomerPriceCodeArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveCustomerPriceGroupArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveDepartmentArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveDivisionArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveEmailServerArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveEmailTypeArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveGridPreferenceArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveIndustryArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveLeadSourceArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveOpportunitiesArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveOpportunityCloseReasonArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveOpportunityFailedReasonArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveOpportunityLineItemStatusArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveOpportunityStageArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveOpportunityStatusArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveOpportunityTypeArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemovePhoneTypeArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemovePrivateTeamArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemovePrivateTeamTypeArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveProductInterestArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveProductPotentialArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveRegionArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveRelatedCategoryArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveRelatedCompanyArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveSalesTeamArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveSalesTeamMemberArgs = {
  salesTeamId: Scalars['Int']['input'];
  tenantId: Scalars['Int']['input'];
  users: Array<Scalars['Int']['input']>;
};

export type MutationRemoveSalesTeamTypeArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveStageGroupArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveStateArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveTenantInstanceArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveTerritoryArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveUserArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveUserAdditionalFieldArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveUserAdditionalFieldsUserArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveUserMenuArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveUserReportListModelArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveUserReportPreferenceArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveUserRoleArgs = {
  id: Scalars['Int']['input'];
};

export type MutationRemoveUserTypeArgs = {
  id: Scalars['Int']['input'];
};

export type MutationSetDefaultGridPreferenceArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUpdateAdditionalFieldArgs = {
  updateAdditionalFieldInput: UpdateAdditionalFieldInput;
};

export type MutationUpdateAddressTypeArgs = {
  updateAddressTypeInput: UpdateAddressTypeInput;
};

export type MutationUpdateAttachmentTypeArgs = {
  updateAttachmentTypeInput: UpdateAttachmentTypeInput;
};

export type MutationUpdateBuyGroupArgs = {
  updateBuyGroupInput: UpdateBuyGroupInput;
};

export type MutationUpdateCallPatternArgs = {
  updateCallPatternInput: UpdateCallPatternInput;
};

export type MutationUpdateColumnArgs = {
  updateColumnInput: UpdateColumnInput;
};

export type MutationUpdateCompaniesArgs = {
  Data: UpdateCompanyElasticInputList;
};

export type MutationUpdateCompaniesInlineArgs = {
  data: UpdateCompanyElasticInputList2;
};

export type MutationUpdateCompanyArgs = {
  updateCompanyInput: UpdateCompanyInput;
};

export type MutationUpdateCompanyAdditionalFieldArgs = {
  updateCompanyAdditionalFieldInput: UpdateCompanyAdditionalFieldInput;
};

export type MutationUpdateCompanyAttachmentArgs = {
  updateCompanyAttachmentInput: UpdateCompanyAttachmentInput;
};

export type MutationUpdateCompanyCategoryArgs = {
  updateCompanyCategoryInput: UpdateCompanyCategoryInput;
};

export type MutationUpdateCompanyClassArgs = {
  updateCompanyClassInput: UpdateCompanyClassInput;
};

export type MutationUpdateCompanyDivisionArgs = {
  updateCompanyDivisionInput: UpdateCompanyDivisionInput;
};

export type MutationUpdateCompanyDocNotesArgs = {
  updateCompanyDocNotesInput: UpdateCompanyDocNotesInput;
};

export type MutationUpdateCompanyPitchArgs = {
  updateCompanyPitchInput: UpdateCompanyPitchInput;
};

export type MutationUpdateCompanyRankArgs = {
  updateCompanyRankInput: UpdateCompanyRankInput;
};

export type MutationUpdateCompanySalesTeamArgs = {
  updateCompanySalesTeamInput: UpdateCompanySalesTeamInput;
};

export type MutationUpdateCompanySplitTypeArgs = {
  updateCompanySplitTypesInput: UpdateCompanySplitTypesInput;
};

export type MutationUpdateCompanyTagArgs = {
  updateCompanyTagInput: UpdateCompanyTagInput;
};

export type MutationUpdateCompanyTierArgs = {
  updateCompanyTierInput: UpdateCompanyTierInput;
};

export type MutationUpdateCompanyTypeAliasArgs = {
  updateCompanyTypeAliasInput: UpdateCompanyTypeAliasInput;
};

export type MutationUpdateContactArgs = {
  updateContactInput: UpdateContactInput;
};

export type MutationUpdateContactAdditionalFieldArgs = {
  updateContactAdditionalFieldsContactInput: UpdateContactAdditionalFieldsContactInput;
};

export type MutationUpdateContactGroupArgs = {
  updateContactGroupInput: UpdateContactGroupInput;
};

export type MutationUpdateContactGroupContactArgs = {
  updateContactGroupContactInput: UpdateContactGroupContactInput;
};

export type MutationUpdateContinentArgs = {
  updateContinentsInput: UpdateContinentsInput;
};

export type MutationUpdateCountryArgs = {
  updateCountryInput: UpdateCountryInput;
};

export type MutationUpdateCustomerPriceCodeArgs = {
  updateCustomerPriceCodesInput: UpdateCustomerPriceCodesInput;
};

export type MutationUpdateCustomerPriceGroupArgs = {
  updateCustomerPriceGroupsInput: UpdateCustomerPriceGroupsInput;
};

export type MutationUpdateDepartmentArgs = {
  updateDepartmentInput: UpdateDepartmentInput;
};

export type MutationUpdateDivisionArgs = {
  updateDivisionInput: UpdateDivisionInput;
};

export type MutationUpdateEmailServerArgs = {
  updateEmailServerInput: UpdateEmailServerInput;
};

export type MutationUpdateEmailTypeArgs = {
  updateEmailTypeInput: UpdateEmailTypeInput;
};

export type MutationUpdateGridPreferenceArgs = {
  updateGridPreferenceInput: UpdateGridPreferenceInput;
};

export type MutationUpdateIndustryArgs = {
  updateCompanyIndustryInput: UpdateCompanyIndustryInput;
};

export type MutationUpdateLeadSourceArgs = {
  updateLeadSourceInput: UpdateLeadSourceInput;
};

export type MutationUpdateOneTenantInstanceArgs = {
  updateTenantInstanceInput: UpdateTenantInstanceInput;
};

export type MutationUpdateOpportunitiesArgs = {
  updateStageInput: UpdateOpportunitiesInput;
};

export type MutationUpdateOpportunityCloseReasonArgs = {
  updateCloseReasonInput: UpdateCloseReasonInput;
};

export type MutationUpdateOpportunityFailedReasonArgs = {
  updateFailedReasonInput: UpdateFailedReasonInput;
};

export type MutationUpdateOpportunityLineItemStatusArgs = {
  updateLineItemStatusInput: UpdateLineItemStatusInput;
};

export type MutationUpdateOpportunityStageArgs = {
  updateStageInput: UpdateStageInput;
};

export type MutationUpdateOpportunityStatusArgs = {
  updateStatusInput: UpdateStatusInput;
};

export type MutationUpdateOpportunityTypeArgs = {
  updateOpportunityTypeInput: UpdateTypeInput;
};

export type MutationUpdatePhoneTypeArgs = {
  updatePhoneTypeInput: UpdatePhoneTypeInput;
};

export type MutationUpdatePrivateTeamArgs = {
  updatePrivateTeamInput: UpdatePrivateTeamInput;
};

export type MutationUpdatePrivateTeamTypeArgs = {
  updatePrivateTeamTypeInput: UpdatePrivateTeamTypeInput;
};

export type MutationUpdateProductInterestArgs = {
  updateProductInterestInput: UpdateProductInterestInput;
};

export type MutationUpdateProductPotentialArgs = {
  updateProductPotentialInput: UpdateProductPotentialInput;
};

export type MutationUpdateRegionArgs = {
  updateRegionInput: UpdateRegionInput;
};

export type MutationUpdateRelatedCategoryArgs = {
  updateRelatedCategoryInput: UpdateRelatedCategoryInput;
};

export type MutationUpdateRelatedCompanyArgs = {
  updateRelatedCompanyInput: UpdateRelatedCompanyInput;
};

export type MutationUpdateRoleArgs = {
  updateRoleInput: UpdateRoleInput;
};

export type MutationUpdateSalesTeamArgs = {
  updateSalesTeamInput: UpdateSalesTeamInput;
};

export type MutationUpdateSalesTeamTypeArgs = {
  updateSalesTeamTypesInput: UpdateSalesTeamTypesInput;
};

export type MutationUpdateStageGroupArgs = {
  updateStageGroupInput: UpdateStageGroupInput;
};

export type MutationUpdateStateArgs = {
  updateStateInput: UpdateStateInput;
};

export type MutationUpdateTerritoryArgs = {
  updateTerritoryInput: UpdateTerritoryInput;
};

export type MutationUpdateUserArgs = {
  updateUserInput: UpdateAnUserInput;
};

export type MutationUpdateUserAdditionalFieldArgs = {
  updateUserAdditionalFieldInput: UpdateUserAdditionalFieldInput;
};

export type MutationUpdateUserAdditionalFieldsUserArgs = {
  updateUserAdditionalFieldsUserInput: UpdateUserAdditionalFieldsUserInput;
};

export type MutationUpdateUserLandingWidgetArgs = {
  UpdateLandingWidgetInput: UpdateUserLandingWidget;
};

export type MutationUpdateUserMenuArgs = {
  updateUserMenuInput: UpdateUserMenuInput;
};

export type MutationUpdateUserReportListModelArgs = {
  updateReportListInput: UpdateReportListInput;
};

export type MutationUpdateUserReportPreferenceArgs = {
  updateUserReportPreferenceInput: UpdateUserReportPreferenceInput;
};

export type MutationUpdateUserReportWidgetListArgs = {
  updateUserReportWidgetInput: UpdateUserReportWidgetInput;
};

export type MutationUpdateUserRoleArgs = {
  updateUserRoleInput: UpdateUserRoleInput;
};

export type MutationUpdateUserTypeArgs = {
  updateUserTypeInput: UpdateUserTypeInput;
};

export type MutationUploadLogoArgs = {
  file: Scalars['Upload']['input'];
};

export type MutationCreateAnalyticsWideSaleArgs = {
  createAnalyticsWideSaleInput: CreateAnalyticsWideSaleInput;
};

export type MutationCreateTenantInstanceArgs = {
  createTenantInstanceInput: CreateTenantInstanceInput;
};

export type MutationRegisterSalesTeamUserArgs = {
  createSalesTeamInput: RegisterSalesTeamUserInput;
};

export type MutationRemoveAnalyticsWideSaleArgs = {
  id: Scalars['Int']['input'];
};

export type MutationUpdateAnalyticsWideSaleArgs = {
  updateAnalyticsWideSaleInput: UpdateAnalyticsWideSaleInput;
};

export type OppOwner = {
  __typename?: 'OppOwner';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type OppStage = {
  __typename?: 'OppStage';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type OppStatus = {
  __typename?: 'OppStatus';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type OpportunitiesCompanyOutputDto = {
  __typename?: 'OpportunitiesCompanyOutputDto';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type OpportunitiesContactOutputDto = {
  __typename?: 'OpportunitiesContactOutputDto';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type OpportunitiesCriteriaInput = {
  filter?: InputMaybe<Array<OpportunitiesFilterInput>>;
  orderBy?: InputMaybe<Array<OpportunitiesOrderByInput>>;
  pagination: PaginationInput2;
};

export type OpportunitiesDto = {
  __typename?: 'OpportunitiesDto';
  bidDate?: Maybe<Scalars['String']['output']>;
  cityId?: Maybe<Scalars['Int']['output']>;
  closeReasonId?: Maybe<Scalars['Int']['output']>;
  closeStatusId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  customers?: Maybe<Array<Scalars['Int']['output']>>;
  customersContacts?: Maybe<Array<Scalars['Int']['output']>>;
  distributors?: Maybe<Array<Scalars['Int']['output']>>;
  distributorsContacts?: Maybe<Array<Scalars['Int']['output']>>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  estimatedCloseDate?: Maybe<Scalars['String']['output']>;
  followUp?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  leadSourceId?: Maybe<Scalars['Int']['output']>;
  manufacturerContacts?: Maybe<Array<Scalars['Int']['output']>>;
  manufacturers?: Maybe<Array<Scalars['Int']['output']>>;
  nextStep?: Maybe<Scalars['String']['output']>;
  opportunityStageId?: Maybe<Scalars['Int']['output']>;
  opportunityStatusId?: Maybe<Scalars['Int']['output']>;
  ownerId?: Maybe<Scalars['Int']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  productLineId?: Maybe<Scalars['Int']['output']>;
  salesTeamId?: Maybe<Scalars['Int']['output']>;
  stateId?: Maybe<Scalars['Int']['output']>;
  topic: Scalars['String']['output'];
  typeId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type OpportunitiesElasticResponse = {
  __typename?: 'OpportunitiesElasticResponse';
  results?: Maybe<Array<OpportunitiesOutputDto>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type OpportunitiesFilterInput = {
  dataType: ValueType;
  fieldName: FilterOpportunitiesEnum;
  operator: StringFilters2;
  value: Scalars['String']['input'];
};

export type OpportunitiesOrderByInput = {
  field: OrderOpportunitiesEnum;
  sort: OrderTypes;
};

export type OpportunitiesOutputDto = {
  __typename?: 'OpportunitiesOutputDto';
  bid?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['Int']['output']>;
  closedReason?: Maybe<ClosedReason>;
  closedStatus?: Maybe<ClosedStatus>;
  createdAt?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Array<OpportunitiesCompanyOutputDto>>;
  customerContact?: Maybe<Array<OpportunitiesContactOutputDto>>;
  distributor?: Maybe<Array<OpportunitiesCompanyOutputDto>>;
  distributorContact?: Maybe<Array<OpportunitiesContactOutputDto>>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  estimatedCloseDate?: Maybe<Scalars['String']['output']>;
  followUp?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  leadSource?: Maybe<LeadSource>;
  manufacturer?: Maybe<Array<OpportunitiesCompanyOutputDto>>;
  manufacturerContact?: Maybe<Array<OpportunitiesContactOutputDto>>;
  nextStep?: Maybe<Scalars['String']['output']>;
  oppOwner?: Maybe<OppOwner>;
  oppStage?: Maybe<OppStage>;
  oppStatus?: Maybe<OppStatus>;
  priority?: Maybe<Scalars['Int']['output']>;
  productLine?: Maybe<ProductLine>;
  salesTeam?: Maybe<SalesTeam>;
  state?: Maybe<State>;
  topic: Scalars['String']['output'];
  type?: Maybe<Type>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type OptionStructureType = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type Options = {
  __typename?: 'Options';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum OrderAdditionalFieldEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
}

export enum OrderAddressTypeEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
}

export enum OrderBuyGroupEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
}

export enum OrderCallPatternEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
}

export enum OrderCategoryEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
}

export enum OrderCloseReasonEnum {
  Id = 'id',
  Name = 'name',
}

export enum OrderCompanyEnum2 {
  CallPattern = 'callPattern',
  CallPatternName = 'callPatternName',
  Category = 'category',
  CategoryName = 'categoryName',
  Comments = 'comments',
  CompanyClass = 'companyClass',
  CompanyClassName = 'companyClassName',
  CompanyType = 'companyType',
  CompanyTypeName = 'companyTypeName',
  Datasource = 'datasource',
  HasDwin = 'hasDwin',
  HasForecast = 'hasForecast',
  HasHolydayCard = 'hasHolydayCard',
  HasPartNumberSplit = 'hasPartNumberSplit',
  HasPosAccount = 'hasPosAccount',
  HasQuot = 'hasQuot',
  HasRegi = 'hasRegi',
  HasSamp = 'hasSamp',
  HasSecondaryAlias = 'hasSecondaryAlias',
  ImpBatch = 'impBatch',
  IsActive = 'isActive',
  MainAddress = 'mainAddress',
  MainAddressAddress = 'mainAddressAddress',
  MainAddressAddressInvalid = 'mainAddressAddressInvalid',
  MainAddressCity = 'mainAddressCity',
  MainAddressContinent = 'mainAddressContinent',
  MainAddressContinentName = 'mainAddressContinentName',
  MainAddressCountry = 'mainAddressCountry',
  MainAddressCountryName = 'mainAddressCountryName',
  MainAddressFormattedAddress = 'mainAddressFormattedAddress',
  MainAddressLatitude = 'mainAddressLatitude',
  MainAddressLongitude = 'mainAddressLongitude',
  MainAddressPoBox = 'mainAddressPoBox',
  MainAddressRegion = 'mainAddressRegion',
  MainAddressRegionName = 'mainAddressRegionName',
  MainAddressState = 'mainAddressState',
  MainAddressStateName = 'mainAddressStateName',
  MainAddressTerritory = 'mainAddressTerritory',
  MainAddressTerritoryName = 'mainAddressTerritoryName',
  MainAddressType = 'mainAddressType',
  MainAddressTypeName = 'mainAddressTypeName',
  MainAddressZipCode = 'mainAddressZipCode',
  MainContact = 'mainContact',
  MainPhone = 'mainPhone',
  MainPhoneAreaCode = 'mainPhoneAreaCode',
  MainPhoneCountryCode = 'mainPhoneCountryCode',
  MainPhoneExtension = 'mainPhoneExtension',
  MainPhonePhone = 'mainPhonePhone',
  MainPhoneType = 'mainPhoneType',
  MainPhoneTypeName = 'mainPhoneTypeName',
  Name = 'name',
  Parent = 'parent',
  ParentName = 'parentName',
  PoFooter = 'poFooter',
  PrintName = 'printName',
  PrivateTeam = 'privateTeam',
  PrivateTeamName = 'privateTeamName',
  QuoteFooter = 'quoteFooter',
  Rank = 'rank',
  RankName = 'rankName',
  RefNumber = 'refNumber',
  SalesTeamName = 'salesTeamName',
  ShipDetail = 'shipDetail',
  ShortName = 'shortName',
  VisitFrequency = 'visitFrequency',
  Website = 'website',
}

export enum OrderCompanyRankEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
}

export enum OrderCompanySplitTypesEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
}

export enum OrderCompanyTierEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
}

export enum OrderCompanyTypeEnum {
  Color = 'color',
  CompanyTypeAliasId = 'company_type_alias_id',
  CompanyTypeAliasName = 'company_type_alias_name',
  Id = 'id',
  IsPredefined = 'is_predefined',
  Name = 'name',
  ParentId = 'parent_id',
  ParentName = 'parent_name',
}

export enum OrderContactGroupEnum {
  Id = 'id',
  Name = 'name',
}

export enum OrderContactNestEnum {
  BusinessInfoCompanyId = 'businessInfoCompanyId',
  BusinessInfoCompanyName = 'businessInfoCompanyName',
  BusinessInfoCompanySalesTeamId = 'businessInfoCompanySalesTeamId',
  BusinessInfoCompanySalesTeamName = 'businessInfoCompanySalesTeamName',
  BusinessInfoCompanyTypeId = 'businessInfoCompanyTypeId',
  BusinessInfoCompanyTypeName = 'businessInfoCompanyTypeName',
  BusinessInfoId = 'businessInfoId',
  BusinessInfoJobTitle = 'businessInfoJobTitle',
  BusinessInfoMainAddressAddress = 'businessInfoMainAddressAddress',
  BusinessInfoMainAddressCity = 'businessInfoMainAddressCity',
  BusinessInfoMainAddressId = 'businessInfoMainAddressId',
  BusinessInfoMainAddressRegionId = 'businessInfoMainAddressRegionId',
  BusinessInfoMainAddressRegionName = 'businessInfoMainAddressRegionName',
  BusinessInfoMainAddressStateId = 'businessInfoMainAddressStateId',
  BusinessInfoMainAddressStateName = 'businessInfoMainAddressStateName',
  BusinessInfoMainAddressZipCode = 'businessInfoMainAddressZipCode',
  BusinessInfoMainPhoneId = 'businessInfoMainPhoneId',
  BusinessInfoMainPhonePhone = 'businessInfoMainPhonePhone',
  Enabled = 'enabled',
  FirstName = 'firstName',
  FullName = 'fullName',
  GroupId = 'groupId',
  GroupName = 'groupName',
  LastName = 'lastName',
  Title = 'title',
}

export enum OrderCountryEnum {
  Id = 'id',
  Name = 'name',
}

export enum OrderEmailTypeEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
}

export enum OrderFailedReasonEnum {
  Id = 'id',
  Name = 'name',
}

export enum OrderIndustryEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
}

export enum OrderLeadSourceEnum {
  Id = 'id',
  Name = 'name',
}

export enum OrderLineItemStatusEnum {
  Id = 'id',
  Name = 'name',
}

export enum OrderOpportunitiesEnum {
  Id = 'id',
  Name = 'name',
}

export enum OrderPhoneTypeEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
}

export enum OrderPrivateTeamEnum {
  Id = 'id',
  Name = 'name',
}

export enum OrderProductInterestEnum {
  Id = 'id',
  Name = 'name',
}

export enum OrderProductPotentialEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
}

export enum OrderRegionEnum {
  Id = 'id',
  Name = 'name',
}

export enum OrderRelatedCompanyEnum {
  Company = 'company',
  CompanyManufacturer = 'companyManufacturer',
  CustomerSplit = 'customerSplit',
  Id = 'id',
  RelatedCategory = 'relatedCategory',
  RelatedCompany = 'relatedCompany',
  Type = 'type',
  ValueRole = 'valueRole',
}

export enum OrderSalesTeamEnum {
  CompanySplit = 'companySplit',
  CompanySplitTypeName = 'companySplitTypeName',
  Id = 'id',
  Name = 'name',
  SalesTeamTypeName = 'salesTeamTypeName',
  SpecifierSplit = 'specifierSplit',
}

export enum OrderSalesTeamTypesEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
}

export enum OrderStageEnum {
  Id = 'id',
  Name = 'name',
}

export enum OrderStageGroupEnum {
  Id = 'id',
  Name = 'name',
}

export enum OrderStatusEnum {
  Id = 'id',
  Name = 'name',
}

export enum OrderTerritoryEnum {
  Id = 'id',
  Name = 'name',
}

export enum OrderTypeEnum {
  Id = 'id',
  Name = 'name',
}

export enum OrderTypes {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type PaginationInput = {
  from?: InputMaybe<Scalars['Float']['input']>;
  searchAfter?: InputMaybe<Scalars['String']['input']>;
  size: Scalars['Float']['input'];
};

export type PaginationInput2 = {
  from?: InputMaybe<Scalars['Int']['input']>;
  size: Scalars['Int']['input'];
};

export type PhoneDbModel = {
  __typename?: 'PhoneDBModel';
  areaCode?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  extension?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  phoneType?: Maybe<PhoneTypeModel>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PhoneFast = {
  __typename?: 'PhoneFast';
  id: Scalars['Int']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type PhoneTypeCriteriaInput = {
  filter?: InputMaybe<Array<PhoneTypeFilterInput>>;
  orderBy?: InputMaybe<Array<PhoneTypeOrderByInput>>;
  pagination: PaginationInput2;
};

export type PhoneTypeElasticResponse = {
  __typename?: 'PhoneTypeElasticResponse';
  results?: Maybe<Array<PhoneTypeModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PhoneTypeFilterInput = {
  dataType: ValueType;
  fieldName: FilterPhoneTypeEnum;
  operator: StringFilters2;
  value: Scalars['String']['input'];
};

export type PhoneTypeModel = {
  __typename?: 'PhoneTypeModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  isCompany?: Maybe<Scalars['Boolean']['output']>;
  isContact?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PhoneTypeOrderByInput = {
  field: OrderPhoneTypeEnum;
  sort: OrderTypes;
};

export type PrimaryCustomerWideSales = {
  __typename?: 'PrimaryCustomerWideSales';
  monthlySales?: Maybe<Array<SalesByMonth>>;
  name: Scalars['String']['output'];
};

export type PrivateTeamCriteriaInput = {
  filter?: InputMaybe<Array<PrivateTeamFilterInput>>;
  orderBy?: InputMaybe<Array<PrivateTeamOrderByInput>>;
  pagination: PaginationInput2;
};

export type PrivateTeamFilterInput = {
  dataType: ValueType;
  fieldName: FilterPrivateTeamEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type PrivateTeamModel = {
  __typename?: 'PrivateTeamModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<PrivateTeamTypeModel>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PrivateTeamOrderByInput = {
  field: OrderPrivateTeamEnum;
  sort: OrderTypes;
};

export type PrivateTeamResponse = {
  __typename?: 'PrivateTeamResponse';
  results?: Maybe<Array<Maybe<PrivateTeamModel>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PrivateTeamTypeModel = {
  __typename?: 'PrivateTeamTypeModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProductInterestCriteriaInput = {
  filter?: InputMaybe<Array<ProductInterestFilterInput>>;
  orderBy?: InputMaybe<Array<ProductInterestOrderByInput>>;
  pagination: PaginationInput2;
};

export type ProductInterestElasticResponse = {
  __typename?: 'ProductInterestElasticResponse';
  results?: Maybe<Array<ProductInterestModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ProductInterestFilterInput = {
  dataType: ValueType;
  fieldName: FilterProductInterestEnum;
  operator: StringFilters2;
  value: Scalars['String']['input'];
};

export type ProductInterestModel = {
  __typename?: 'ProductInterestModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProductInterestOrderByInput = {
  field: OrderProductInterestEnum;
  sort: OrderTypes;
};

export type ProductLine = {
  __typename?: 'ProductLine';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ProductPotentialCriteriaInput = {
  filter?: InputMaybe<Array<ProductPotentialFilterInput>>;
  orderBy?: InputMaybe<Array<ProductPotentialOrderByInput>>;
  pagination: PaginationInput2;
};

export type ProductPotentialFilterInput = {
  dataType: ValueType;
  fieldName: FilterProductPotentialEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type ProductPotentialOrderByInput = {
  field: OrderProductPotentialEnum;
  sort: OrderTypes;
};

export type ProductPotentialResponse = {
  __typename?: 'ProductPotentialResponse';
  results?: Maybe<Array<CompanyProductPotentialModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  CreateCompanyExport: ExportRequestResponseDto;
  ExportContacts2Nest: Scalars['JSON']['output'];
  /** @deprecated This ElasticSearch is outdated */
  FindOneCompanyFromElastic: CompanyElasticModel;
  /** @deprecated Name will be changed to GetCompany */
  FindOneCompanyFromElastic2?: Maybe<CompanyModel>;
  FindOneUserLandingWidget: UserLandingWidget;
  FindOneUserReportListModel: ReportListModel;
  FindOneUserReportPreference: UserReportPreference;
  FindOneUserReportWidgetList: UserReportWidgetList;
  FindTenantInstances?: Maybe<Array<TenantInstance>>;
  FindUserLandingWidgetsByArgs: Array<UserLandingWidget>;
  FindUserReportListModels?: Maybe<Array<ReportListModel>>;
  FindUserReportPreferenceByArgs: Array<UserReportPreference>;
  FindUserReportWidgetListByArgs: Array<UserReportWidgetList>;
  ForgetSecurityToken?: Maybe<SecurityTokenModel>;
  GetAdditionalField?: Maybe<AdditionalFieldModel>;
  GetAddressType?: Maybe<AddressTypeModel>;
  GetAddressTypeGridHeaders: Scalars['JSON']['output'];
  GetAddressTypeUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetAllAdditionalFields?: Maybe<AdditionalFieldsResponse>;
  GetAllAddressType?: Maybe<Array<Maybe<AddressTypeModel>>>;
  /** @deprecated This query will be renamed to GetAllAddressType. */
  GetAllAddressType2: AddressTypeElasticResponse;
  GetAllAddressTypeCompany?: Maybe<Array<AddressTypeModel>>;
  GetAllAddressTypeContact?: Maybe<Array<AddressTypeModel>>;
  GetAllAttachmentType?: Maybe<Array<AttachmentTypeModel>>;
  GetAllBuyGroup?: Maybe<Array<BuyGroupModel>>;
  GetAllBuyGroup2?: Maybe<BuyingGroupResponse>;
  GetAllCallPattern?: Maybe<Array<Maybe<CallPatternModel>>>;
  GetAllCallPattern2?: Maybe<CallPatternResponse>;
  GetAllColumns: Array<ColumnModel>;
  GetAllCompanies?: Maybe<Array<CompanyModel>>;
  GetAllCompanyAdditionalField?: Maybe<Array<CompanyAdditionalFieldModel>>;
  GetAllCompanyAddresses?: Maybe<Array<CompanyAddressModel>>;
  GetAllCompanyAddressesByCompany?: Maybe<Array<CompanyAddressModel>>;
  GetAllCompanyAttachment?: Maybe<Array<CompanyAttachmentModel>>;
  GetAllCompanyCategory?: Maybe<Array<Maybe<CompanyCategoryDbModel>>>;
  GetAllCompanyCategory2?: Maybe<CategoryResponse>;
  GetAllCompanyClass: Array<CompanyClassDbModel>;
  GetAllCompanyDivision?: Maybe<Array<CompanyDivisionModel>>;
  GetAllCompanyDocNotes?: Maybe<Array<CompanyDocNotesModel>>;
  GetAllCompanyPhones?: Maybe<Array<CompanyPhoneModel>>;
  GetAllCompanyPhonesByCompany?: Maybe<Array<CompanyPhoneModel>>;
  GetAllCompanyPitches?: Maybe<Array<CompanyPitchesModel>>;
  GetAllCompanyRanks?: Maybe<Array<CompanyRankDbModel>>;
  GetAllCompanyRanks2?: Maybe<CompanyRankResponse>;
  GetAllCompanySalesTeam?: Maybe<Array<CompanySalesTeamModel>>;
  GetAllCompanySplitTypes: SplitTypeResponse;
  GetAllCompanyTags?: Maybe<Array<CompanyTagsModel>>;
  GetAllCompanyTier?: Maybe<Array<CompanyTierModel>>;
  GetAllCompanyTier2?: Maybe<CompanyTierResponse>;
  GetAllCompanyTypeAlias?: Maybe<Array<Maybe<CompanyTypeAliasModel>>>;
  GetAllCompanyTypeAlias2?: Maybe<CompanyTypeModelElasticResponse>;
  GetAllContact?: Maybe<Array<ContactOutputDto>>;
  GetAllContactAdditionalField?: Maybe<Array<ContactAdditionalFieldsContactModel>>;
  GetAllContactByCompanyId?: Maybe<ContactGridResponseOutputDto>;
  GetAllContactGroupContact?: Maybe<Array<ContactGroupContactModel>>;
  GetAllContactGroupContactRelations?: Maybe<Array<ContactGroupContactModel>>;
  GetAllContactGroups: Array<ContactGroupModel>;
  /** @deprecated This query will be renamed to GetAllContactGroups. */
  GetAllContactGroups2: ContactGroupElasticResponse;
  GetAllContacts2Nest?: Maybe<ContactGridResponseOutputDto>;
  GetAllContactsForGrid?: Maybe<ContactFastGridResponseOutputDto>;
  GetAllContinents?: Maybe<Array<ContinentDbModel>>;
  GetAllCountries?: Maybe<Array<CountryDbModel>>;
  GetAllCountries2: CountryElasticResponse;
  GetAllCustomerPriceCodes?: Maybe<Array<CustomerPriceCodesModel>>;
  GetAllCustomerPriceGroups?: Maybe<Array<CustomerPriceGroupsModel>>;
  GetAllDepartments?: Maybe<Array<DepartmentModel>>;
  GetAllDivisions?: Maybe<Array<DivisionDbModel>>;
  GetAllEmailServers?: Maybe<Array<EmailServerModel>>;
  GetAllEmailTypes?: Maybe<Array<EmailTypeModel>>;
  /** @deprecated This query will be renamed to GetAllEmailTypes. */
  GetAllEmailTypes2: EmailTypeElasticResponse;
  GetAllGridPreferences?: Maybe<Array<GridPreferenceModel>>;
  GetAllIndustries: Array<CompanyIndustryDbModel>;
  GetAllIndustries2: CompanyIndustryResponse;
  GetAllLeadSources: LeadSourceElasticResponse;
  GetAllOpportunities: OpportunitiesElasticResponse;
  GetAllOpportunityCloseReasons: CloseReasonElasticResponse;
  GetAllOpportunityFailedReasons: FailedReasonElasticResponse;
  GetAllOpportunityLineItemStatus: LineItemStatusElasticResponse;
  GetAllOpportunityStages: StageElasticResponse;
  GetAllOpportunityStatus: StatusElasticResponse;
  GetAllOpportunityTypes: TypeElasticResponse;
  GetAllPhoneTypeCompany?: Maybe<Array<PhoneTypeModel>>;
  GetAllPhoneTypeContact?: Maybe<Array<PhoneTypeModel>>;
  GetAllPhoneTypes?: Maybe<Array<PhoneTypeModel>>;
  /** @deprecated This query will be renamed to GetAllPhoneTypes. */
  GetAllPhoneTypes2: PhoneTypeElasticResponse;
  GetAllPrivateTeamTypes?: Maybe<Array<PrivateTeamTypeModel>>;
  GetAllPrivateTeams?: Maybe<Array<PrivateTeamModel>>;
  GetAllPrivateTeams2?: Maybe<PrivateTeamResponse>;
  GetAllProductInterest: Array<ProductInterestModel>;
  /** @deprecated This query will be renamed to GetAllProductInterest. */
  GetAllProductInterest2: ProductInterestElasticResponse;
  GetAllProductPotentials?: Maybe<ProductPotentialResponse>;
  GetAllRegions?: Maybe<Array<RegionModel>>;
  /** @deprecated This query will be renamed to GetAllRegions. */
  GetAllRegions2?: Maybe<RegionElasticResponse>;
  GetAllRelatedCategories?: Maybe<RelatedCategoryElasticResponse>;
  GetAllRelatedCompanies?: Maybe<RelatedCompanyElasticResponse>;
  GetAllRoles: Array<RoleModel>;
  GetAllRolesCrm: Array<Role>;
  GetAllSalesTeam: SalesTeamResponse;
  GetAllSalesTeamTypes: SalesTeamTypeResponse;
  GetAllStageGroup: StageGroupElasticResponse;
  GetAllStates?: Maybe<Array<StateDbModel>>;
  GetAllTenantInstances?: Maybe<Array<TenantInstance>>;
  GetAllTerritories?: Maybe<Array<TerritoryDbModel>>;
  GetAllTerritories2: TerritoryElasticResponse;
  GetAllUser: Array<UserDbModel>;
  GetAllUserAdditionalFields?: Maybe<Array<UserAdditionalFieldModel>>;
  GetAllUserAdditionalFieldsUser?: Maybe<Array<UserAdditionalFieldsUserModel>>;
  GetAllUserLandingWidgets: Array<UserLandingWidget>;
  GetAllUserMenu: Array<UserMenuModel>;
  GetAllUserReportListModels: Array<ReportListModel>;
  GetAllUserReportPreference: Array<UserReportPreference>;
  GetAllUserReportWidgets: Array<UserReportWidgetList>;
  GetAllUserRole: Array<UserRoleModel>;
  GetAllUserType: Array<UserTypeModel>;
  GetAllUsers: Array<User>;
  GetAttachmentType?: Maybe<AttachmentTypeModel>;
  GetBusinessInfo?: Maybe<ContactBusinessInfoModel>;
  GetBuyGroup?: Maybe<BuyGroupModel>;
  GetBuyGroupUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetCallPattern?: Maybe<CallPatternModel>;
  GetCallPatternUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetCategoryUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetColumnById: ColumnModel;
  GetColumnByName: ColumnModel;
  GetCompaniesBaseTypes: Array<CompanyTypeAliasModel>;
  GetCompaniesBaseTypesOptions: Array<CompanyBaseType>;
  GetCompaniesByLocationDistance: CompanyModelElasticResponse2;
  GetCompaniesByType: CompanyTypeModelElasticResponse;
  GetCompaniesDepthGridHeader: Scalars['JSON']['output'];
  GetCompaniesDynamicGridHeader: Scalars['JSON']['output'];
  GetCompaniesFromElastic: CompanyModelElasticResponse;
  /** @deprecated Name will be changed to GetAllCompanies */
  GetCompaniesFromElastic2: CompanyModelElasticResponse2;
  /** @deprecated Name will be changed to GetAllCompanies */
  GetCompaniesFromElasticProtected: CompanyModelElasticResponse2;
  GetCompaniesGridHeader: Scalars['JSON']['output'];
  GetCompany?: Maybe<CompanyModel>;
  GetCompanyAdditionalField?: Maybe<CompanyAdditionalFieldModel>;
  GetCompanyAddress?: Maybe<CompanyAddressModel>;
  GetCompanyAttachment?: Maybe<CompanyAttachmentModel>;
  GetCompanyBuyingGroupGridHeaders: Scalars['JSON']['output'];
  GetCompanyCallPatternGridHeaders: Scalars['JSON']['output'];
  GetCompanyCategory?: Maybe<CompanyCategoryDbModel>;
  GetCompanyCategoryGridHeaders: Scalars['JSON']['output'];
  GetCompanyClass: CompanyClassDbModel;
  GetCompanyCustomTypesGridHeaders: Scalars['JSON']['output'];
  GetCompanyDivision?: Maybe<CompanyDivisionModel>;
  GetCompanyDocNotes?: Maybe<CompanyDocNotesModel>;
  GetCompanyPhone?: Maybe<CompanyPhoneModel>;
  GetCompanyPitch?: Maybe<CompanyPitchesModel>;
  GetCompanyPitchGridHeaders: Scalars['JSON']['output'];
  GetCompanyPredefinedTypesGridHeader: Scalars['JSON']['output'];
  GetCompanyRank?: Maybe<CompanyRankDbModel>;
  GetCompanyRankGridHeaders: Scalars['JSON']['output'];
  GetCompanyRankUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetCompanySalesTeam?: Maybe<CompanySalesTeamModel>;
  GetCompanySplitType?: Maybe<CompanySplitTypesModel>;
  GetCompanySplitTypeUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetCompanySplitTypesGridHeaders: Scalars['JSON']['output'];
  GetCompanyTag?: Maybe<CompanyTagsModel>;
  GetCompanyTier?: Maybe<CompanyTierModel>;
  GetCompanyTierGridHeaders: Scalars['JSON']['output'];
  GetCompanyTierUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetCompanyTypeAlias: CompanyTypeAliasModel;
  GetCompanyTypeUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetCompanyUniqueValuesFromColumn: Scalars['JSON']['output'];
  GetContact?: Maybe<ContactOutputDto>;
  GetContactAdditionalField?: Maybe<ContactAdditionalFieldsContactModel>;
  GetContactGroup: ContactGroupModel;
  GetContactGroupContact?: Maybe<ContactGroupContactModel>;
  GetContactGroupGridHeaders: Scalars['JSON']['output'];
  GetContactGroupNest: ContactGroupModel;
  GetContactGroupUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetContactsAmountByFirstLetter: Scalars['JSON']['output'];
  GetContactsCompanyGridHeader: Scalars['JSON']['output'];
  GetContactsDynamicGridHeader: Scalars['JSON']['output'];
  GetContactsGridHeader: Scalars['JSON']['output'];
  GetContactsUniqueValuesFromColumn: Scalars['JSON']['output'];
  GetContactsUniqueValuesFromColumnWithId: Scalars['JSON']['output'];
  GetContinent?: Maybe<ContinentDbModel>;
  GetCountry?: Maybe<CountryDbModel>;
  GetCountryGridHeaders: Scalars['JSON']['output'];
  GetCountryUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetCustomerPriceCode?: Maybe<CustomerPriceCodesModel>;
  GetCustomerPriceGroup?: Maybe<CustomerPriceGroupsModel>;
  GetDepartment?: Maybe<DepartmentModel>;
  GetDivision?: Maybe<DivisionDbModel>;
  GetDivisionsByCompany?: Maybe<Array<DivisionsCompanyModel>>;
  GetEmailServer?: Maybe<EmailServerModel>;
  GetEmailType?: Maybe<EmailTypeModel>;
  GetEmailTypeGridHeaders: Scalars['JSON']['output'];
  GetEmailTypeUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetExportFileLink: ExportRequestResponseDto;
  GetGridPreference?: Maybe<GridPreferenceModel>;
  GetIndustry?: Maybe<CompanyIndustryDbModel>;
  GetIndustryGridHeaders: Scalars['JSON']['output'];
  GetLeadSource: LeadSourceOutputDto;
  GetLeadSourceGridHeaders: Scalars['JSON']['output'];
  GetLeadSourceUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetLogo: Scalars['String']['output'];
  GetOneTenantInstance: TenantInstance;
  GetOpportunities: OpportunitiesDto;
  GetOpportunitiesGridHeaders: Scalars['JSON']['output'];
  GetOpportunitiesUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetOpportunityCloseReason: CloseReasonOutputDto;
  GetOpportunityCloseReasonGridHeaders: Scalars['JSON']['output'];
  GetOpportunityCloseReasonUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetOpportunityFailedReason: FailedReasonOutputDto;
  GetOpportunityFailedReasonGridHeaders: Scalars['JSON']['output'];
  GetOpportunityFailedReasonUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetOpportunityLineItemStatus: LineItemStatusOutputDto;
  GetOpportunityLineItemStatusGridHeaders: Scalars['JSON']['output'];
  GetOpportunityLineItemStatusUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetOpportunityStage: StageOutputDto;
  GetOpportunityStageGridHeaders: Scalars['JSON']['output'];
  GetOpportunityStageUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetOpportunityStatus: StatusOutputDto;
  GetOpportunityStatusGridHeaders: Scalars['JSON']['output'];
  GetOpportunityStatusUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetOpportunityType: TypeOutputDto;
  GetOpportunityTypeGridHeaders: Scalars['JSON']['output'];
  GetOpportunityTypeUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetPersonalInfo?: Maybe<ContactPersonalInfoModel>;
  GetPhoneType?: Maybe<PhoneTypeModel>;
  GetPhoneTypeGridHeaders: Scalars['JSON']['output'];
  GetPhoneTypeUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetPrivateTeam?: Maybe<PrivateTeamModel>;
  GetPrivateTeamGridHeaders: Scalars['JSON']['output'];
  GetPrivateTeamType?: Maybe<PrivateTeamTypeModel>;
  GetPrivateTeamsUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetProductInterest: ProductInterestModel;
  GetProductInterestGridHeaders: Scalars['JSON']['output'];
  GetProductInterestNest: ProductInterestModel;
  GetProductInterestUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetProductPotential?: Maybe<CompanyProductPotentialModel>;
  GetProductPotentialGridHeaders: Scalars['JSON']['output'];
  GetProductPotentialUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetRegion?: Maybe<RegionModel>;
  GetRegionGridHeaders: Scalars['JSON']['output'];
  GetRegionUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetRelatedCategory?: Maybe<RelatedCategoryModel>;
  GetRelatedCategoryGridHeaders: Scalars['JSON']['output'];
  GetRelatedCompany?: Maybe<RelatedCompanyDbModel>;
  GetReportsOfRole: Array<ReportListModel>;
  GetRole: Role;
  GetSalesTeam?: Maybe<SalesTeamModel>;
  GetSalesTeamGridHeaders: Scalars['JSON']['output'];
  GetSalesTeamType?: Maybe<SalesTeamTypesModel>;
  GetSalesTeamTypesGridHeaders: Scalars['JSON']['output'];
  GetSalesTeamTypesUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetSalesTeamUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetSecurityToken?: Maybe<SecurityTokenModel>;
  GetSomeColumns: SomeColumnModel;
  GetStageGroup: StageGroupOutputDto;
  GetStageGroupGridHeaders: Scalars['JSON']['output'];
  GetStageGroupUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetState?: Maybe<StateDbModel>;
  GetTerritory?: Maybe<TerritoryDbModel>;
  GetTerritoryGridHeaders: Scalars['JSON']['output'];
  GetTerritoryUniqueValuesFromColumn: Array<Scalars['String']['output']>;
  GetUser: User;
  GetUserAdditionalField?: Maybe<UserAdditionalFieldModel>;
  GetUserAdditionalFieldsUser?: Maybe<UserAdditionalFieldsUserModel>;
  GetUserMenu: UserMenuModel;
  GetUserRole: UserRoleModel;
  GetUserType: UserTypeModel;
  InitializeUser: Scalars['JSON']['output'];
  TestGuardFunction: Scalars['Boolean']['output'];
  UpdateDesignatedByDivision?: Maybe<Scalars['Boolean']['output']>;
  analyticsWideSale: AnalyticsWideSale;
  analyticsWideSales: Array<AnalyticsWideSale>;
  auroraSalesReport: AuroraSalesReport;
  auroraSalesReportHeader: Scalars['JSON']['output'];
  contactsGlobalSearchQuery?: Maybe<ContactGridResponseOutputDto>;
  downloadReportFile: Scalars['String']['output'];
  factoryTouches: FactoryTouchesReport;
  factoryTouchesHeader: Scalars['JSON']['output'];
  getAJIndexStatus: Scalars['JSON']['output'];
  getAllSalesTeams: GetAllSalesTeamMembersResponseModel;
  getCommissionsAndSalesIndexStatus: Scalars['JSON']['output'];
  getCommissionsIndexStatus: Scalars['JSON']['output'];
  getDynamicPropertiesFromCompanyModel: Scalars['JSON']['output'];
  getOrdersIndexStatus: Scalars['JSON']['output'];
  getQuotesIndexStatus: Scalars['JSON']['output'];
  getResultIndices: Scalars['JSON']['output'];
  getSalesIndexStatus: Scalars['JSON']['output'];
  monthlySalesReport: MonthlySalesReport;
  monthlySalesReportHeader: Scalars['JSON']['output'];
  paginateWideAJReportResults: Scalars['JSON']['output'];
  paginateWideCommissionsAndSalesReportResults: Scalars['JSON']['output'];
  paginateWideCommissionsReportResults: Scalars['JSON']['output'];
  paginateWideOrdersReportResults: Scalars['JSON']['output'];
  paginateWideQuotesReportResults: Scalars['JSON']['output'];
  paginateWideSalesReportResults: Scalars['JSON']['output'];
  sideBarMenu: Array<Section>;
  wideAJReport: WideAjReport;
  wideAJReportHeader: Scalars['JSON']['output'];
  wideAJReportLastInvoiceDate: Scalars['JSON']['output'];
  wideAJReportV2: WideAjReport;
  wideCommissionReport: WideCommissionReport;
  wideCommissionReportHeader: Scalars['JSON']['output'];
  wideCommissionReportLastInvoiceDate: Scalars['JSON']['output'];
  wideCommissionReportV2: WideCommissionReport;
  wideCommissionSaleReport: WideCommissionSalesReport;
  wideCommissionSaleReportHeader: Scalars['JSON']['output'];
  wideCommissionSaleReportLastInvoiceDate: Scalars['JSON']['output'];
  wideCommissionSaleReportV2: WideCommissionSalesReport;
  widePOReport: WidePoReport;
  widePOReportHeader: Scalars['JSON']['output'];
  widePOReportLastInvoiceDate: Scalars['JSON']['output'];
  widePOReportV2: WidePoReport;
  wideQuotesReport: WideQuotesReport;
  wideQuotesReportHeader: Scalars['JSON']['output'];
  wideQuotesReportLastQuoteDate: Scalars['JSON']['output'];
  wideQuotesReportV2: WideQuotesReport;
  wideSalesReport: WideSalesReport;
  wideSalesReportHeader: Scalars['JSON']['output'];
  wideSalesReportLastInvoiceDate: Scalars['JSON']['output'];
  wideSalesReportV2: WideSalesReport;
};

export type QueryCreateCompanyExportArgs = {
  Criteria: CompanyCriteriaInput2;
  FileFormat?: InputMaybe<Scalars['String']['input']>;
  SearchFields?: InputMaybe<CompanyFindElasticInput2>;
};

export type QueryExportContacts2NestArgs = {
  Criteria: ContactCriteriaNestInput;
  SearchFields?: InputMaybe<FindContactNestInput>;
  exportFields?: InputMaybe<ExportSearchInput>;
};

export type QueryFindOneCompanyFromElasticArgs = {
  id: Scalars['String']['input'];
};

export type QueryFindOneCompanyFromElastic2Args = {
  id: Scalars['Float']['input'];
};

export type QueryFindOneUserLandingWidgetArgs = {
  id: Scalars['Int']['input'];
};

export type QueryFindOneUserReportListModelArgs = {
  id: Scalars['Int']['input'];
};

export type QueryFindOneUserReportPreferenceArgs = {
  id: Scalars['Int']['input'];
};

export type QueryFindOneUserReportWidgetListArgs = {
  id: Scalars['Int']['input'];
};

export type QueryFindTenantInstancesArgs = {
  findTenantInstanceInput: FindTenantInstanceInput;
};

export type QueryFindUserLandingWidgetsByArgsArgs = {
  FindUserLandingWidget: FindUserLandingWidget;
};

export type QueryFindUserReportListModelsArgs = {
  FindReportListDto: FindReportListInput;
};

export type QueryFindUserReportPreferenceByArgsArgs = {
  FindUserReportPreferenceByArgs: FindUserReportPreferenceInput;
};

export type QueryFindUserReportWidgetListByArgsArgs = {
  FindUserReportWidgetListByArgs: FindUserReportWidgetInput;
};

export type QueryGetAdditionalFieldArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetAddressTypeArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetAddressTypeUniqueValuesFromColumnArgs = {
  column: FilterAddressTypeEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetAllAdditionalFieldsArgs = {
  Criteria: AdditionalFieldsCriteriaInput;
  SearchFields?: InputMaybe<FindAdditionalFieldsInput>;
};

export type QueryGetAllAddressType2Args = {
  Criteria: AddressTypeCriteriaInput;
  SearchFields?: InputMaybe<FindAddressTypeInput>;
};

export type QueryGetAllBuyGroup2Args = {
  Criteria: BuyingGroupCriteriaInput;
  SearchFields?: InputMaybe<FindBuyingGroupInput>;
};

export type QueryGetAllCallPattern2Args = {
  Criteria: CallPatternCriteriaInput;
  SearchFields?: InputMaybe<FindCallPatternInput>;
};

export type QueryGetAllColumnsArgs = {
  reportId: Scalars['Int']['input'];
};

export type QueryGetAllCompanyAdditionalFieldArgs = {
  companyId: Scalars['Int']['input'];
};

export type QueryGetAllCompanyAddressesByCompanyArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetAllCompanyCategory2Args = {
  Criteria: CategoryCriteriaInput;
  SearchFields?: InputMaybe<FindCategoryInput>;
};

export type QueryGetAllCompanyPhonesByCompanyArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetAllCompanyPitchesArgs = {
  companyId: Scalars['Int']['input'];
};

export type QueryGetAllCompanyRanks2Args = {
  Criteria: CompanyRankCriteriaInput;
  SearchFields?: InputMaybe<FindCompanyRankInput>;
};

export type QueryGetAllCompanySplitTypesArgs = {
  Criteria: CompanySplitTypesCriteriaInput;
  SearchFields?: InputMaybe<FindCompanySplitTypesInput>;
};

export type QueryGetAllCompanyTier2Args = {
  Criteria: CompanyTierCriteriaInput;
  SearchFields?: InputMaybe<FindCompanyTierInput>;
};

export type QueryGetAllCompanyTypeAlias2Args = {
  Criteria: CompanyTypeCriteriaInput;
  SearchFields?: InputMaybe<FindCompanyTypeInput>;
};

export type QueryGetAllContactAdditionalFieldArgs = {
  contactId: Scalars['Int']['input'];
};

export type QueryGetAllContactByCompanyIdArgs = {
  Criteria: ContactCriteriaInput;
  SearchFields?: InputMaybe<FindContactInput>;
  companyId: Scalars['Int']['input'];
};

export type QueryGetAllContactGroups2Args = {
  Criteria: ContactGroupCriteriaInput;
  SearchFields?: InputMaybe<FindContactGroupInput>;
};

export type QueryGetAllContacts2NestArgs = {
  Criteria: ContactCriteriaNestInput;
  SearchFields?: InputMaybe<FindContactNestInput>;
};

export type QueryGetAllContactsForGridArgs = {
  Criteria: ContactCriteriaNestInput;
  SearchFields?: InputMaybe<FindContactNestInput>;
};

export type QueryGetAllCountries2Args = {
  Criteria: CountryCriteriaInput;
  SearchFields?: InputMaybe<FindCountryInput>;
};

export type QueryGetAllEmailTypes2Args = {
  Criteria: EmailTypeCriteriaInput;
  SearchFields?: InputMaybe<FindEmailTypeInput>;
};

export type QueryGetAllGridPreferencesArgs = {
  gridName: Scalars['String']['input'];
};

export type QueryGetAllIndustries2Args = {
  Criteria: IndustryCriteriaInput;
  SearchFields?: InputMaybe<FindIndustryInput>;
};

export type QueryGetAllLeadSourcesArgs = {
  Criteria: LeadSourceCriteriaInput;
  SearchFields?: InputMaybe<FindLeadSourceInput>;
};

export type QueryGetAllOpportunitiesArgs = {
  Criteria: OpportunitiesCriteriaInput;
  SearchFields?: InputMaybe<FindOpportunitiesInput>;
};

export type QueryGetAllOpportunityCloseReasonsArgs = {
  Criteria: CloseReasonCriteriaInput;
  SearchFields?: InputMaybe<FindCloseReasonInput>;
};

export type QueryGetAllOpportunityFailedReasonsArgs = {
  Criteria: FailedReasonCriteriaInput;
  SearchFields?: InputMaybe<FindFailedReasonInput>;
};

export type QueryGetAllOpportunityLineItemStatusArgs = {
  Criteria: LineItemStatusCriteriaInput;
  SearchFields?: InputMaybe<FindLineItemStatusInput>;
};

export type QueryGetAllOpportunityStagesArgs = {
  Criteria: StageCriteriaInput;
  SearchFields?: InputMaybe<FindStageInput>;
};

export type QueryGetAllOpportunityStatusArgs = {
  Criteria: StatusCriteriaInput;
  SearchFields?: InputMaybe<FindStatusInput>;
};

export type QueryGetAllOpportunityTypesArgs = {
  Criteria: TypeCriteriaInput;
  SearchFields?: InputMaybe<FindTypeInput>;
};

export type QueryGetAllPhoneTypes2Args = {
  Criteria: PhoneTypeCriteriaInput;
  SearchFields?: InputMaybe<FindPhoneTypeInput>;
};

export type QueryGetAllPrivateTeams2Args = {
  Criteria: PrivateTeamCriteriaInput;
  SearchFields?: InputMaybe<FindPrivateTeamInput>;
};

export type QueryGetAllProductInterest2Args = {
  Criteria: ProductInterestCriteriaInput;
  SearchFields?: InputMaybe<FindProductInterestInput>;
};

export type QueryGetAllProductPotentialsArgs = {
  Criteria: ProductPotentialCriteriaInput;
  SearchFields?: InputMaybe<FindProductPotentialInput>;
};

export type QueryGetAllRegions2Args = {
  Criteria: RegionCriteriaInput;
  SearchFields?: InputMaybe<FindRegionInput>;
};

export type QueryGetAllRelatedCategoriesArgs = {
  Criteria: RelatedCategoryCriteriaInput;
  SearchFields?: InputMaybe<FindRelatedCategoryInput>;
};

export type QueryGetAllRelatedCompaniesArgs = {
  Criteria: RelatedCompanyCriteriaInput;
  SearchFields?: InputMaybe<FindRelatedCompanyInput>;
  company: Scalars['Float']['input'];
};

export type QueryGetAllSalesTeamArgs = {
  Criteria: SalesTeamCriteriaInput;
  SearchFields?: InputMaybe<FindSalesTeamInput>;
};

export type QueryGetAllSalesTeamTypesArgs = {
  Criteria: SalesTeamTypesCriteriaInput;
  SearchFields?: InputMaybe<FindSalesTeamTypesInput>;
};

export type QueryGetAllStageGroupArgs = {
  Criteria: StageGroupCriteriaInput;
  SearchFields?: InputMaybe<FindStageGroupInput>;
};

export type QueryGetAllTerritories2Args = {
  Criteria: TerritoryCriteriaInput;
  SearchFields?: InputMaybe<FindTerritoryInput>;
};

export type QueryGetAttachmentTypeArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetBusinessInfoArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetBuyGroupArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetBuyGroupUniqueValuesFromColumnArgs = {
  column: FilterBuyGroupEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetCallPatternArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCallPatternUniqueValuesFromColumnArgs = {
  column: FilterCallPatternEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetCategoryUniqueValuesFromColumnArgs = {
  column: FilterCategoryEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetColumnByIdArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetColumnByNameArgs = {
  name: Scalars['String']['input'];
  reportId: Scalars['Int']['input'];
};

export type QueryGetCompaniesByLocationDistanceArgs = {
  distance: Scalars['Float']['input'];
  location: LocationPoint;
};

export type QueryGetCompaniesByTypeArgs = {
  Criteria: CompanyByTypeCriteriaInput;
  SearchFields?: InputMaybe<CompanyByTypeInput>;
};

export type QueryGetCompaniesFromElasticArgs = {
  Criteria: CompanyCriteriaInput;
  SearchFields?: InputMaybe<CompanyFindElasticInput>;
};

export type QueryGetCompaniesFromElastic2Args = {
  Criteria: CompanyCriteriaInput2;
  SearchFields?: InputMaybe<CompanyFindElasticInput2>;
};

export type QueryGetCompaniesFromElasticProtectedArgs = {
  Criteria: CompanyCriteriaInput2;
  SearchFields?: InputMaybe<CompanyFindElasticInput2>;
};

export type QueryGetCompanyArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanyAdditionalFieldArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanyAddressArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanyAttachmentArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanyCategoryArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanyClassArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanyDivisionArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanyDocNotesArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanyPhoneArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanyPitchArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanyRankArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanyRankUniqueValuesFromColumnArgs = {
  column: FilterCompanyRankEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetCompanySalesTeamArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanySplitTypeArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanySplitTypeUniqueValuesFromColumnArgs = {
  column: FilterCompanySplitTypesEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetCompanyTagArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanyTierArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanyTierUniqueValuesFromColumnArgs = {
  column: FilterCompanyTierEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetCompanyTypeAliasArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCompanyTypeUniqueValuesFromColumnArgs = {
  column: FilterCompanyTypeEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetCompanyUniqueValuesFromColumnArgs = {
  column: FilterCompanyEnum;
  enabled?: Scalars['Boolean']['input'];
  filter: Scalars['String']['input'];
};

export type QueryGetContactArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetContactAdditionalFieldArgs = {
  additionalFieldId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type QueryGetContactGroupArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetContactGroupContactArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetContactGroupNestArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetContactGroupUniqueValuesFromColumnArgs = {
  column: FilterContactGroupEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetContactsUniqueValuesFromColumnArgs = {
  column: ConstactsGridFieldInputEnum;
  enabled?: Scalars['Boolean']['input'];
  filter: Scalars['String']['input'];
};

export type QueryGetContactsUniqueValuesFromColumnWithIdArgs = {
  column: ConstactsGridFieldInputEnum;
  enabled?: Scalars['Boolean']['input'];
  filter: Scalars['String']['input'];
};

export type QueryGetContinentArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCountryArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCountryUniqueValuesFromColumnArgs = {
  column: FilterCountryEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetCustomerPriceCodeArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCustomerPriceGroupArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetDepartmentArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetDivisionArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetDivisionsByCompanyArgs = {
  companyId: Scalars['Int']['input'];
};

export type QueryGetEmailServerArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetEmailTypeArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetEmailTypeUniqueValuesFromColumnArgs = {
  column: FilterEmailTypeEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetExportFileLinkArgs = {
  reportId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type QueryGetGridPreferenceArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetIndustryArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetLeadSourceArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetLeadSourceUniqueValuesFromColumnArgs = {
  column: FilterLeadSourceEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetOneTenantInstanceArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetOpportunitiesArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetOpportunitiesUniqueValuesFromColumnArgs = {
  column: FilterOpportunitiesEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetOpportunityCloseReasonArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetOpportunityCloseReasonUniqueValuesFromColumnArgs = {
  column: FilterCloseReasonEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetOpportunityFailedReasonArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetOpportunityFailedReasonUniqueValuesFromColumnArgs = {
  column: FilterFailedReasonEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetOpportunityLineItemStatusArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetOpportunityLineItemStatusUniqueValuesFromColumnArgs = {
  column: FilterLineItemStatusEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetOpportunityStageArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetOpportunityStageUniqueValuesFromColumnArgs = {
  column: FilterStageEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetOpportunityStatusArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetOpportunityStatusUniqueValuesFromColumnArgs = {
  column: FilterStatusEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetOpportunityTypeArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetOpportunityTypeUniqueValuesFromColumnArgs = {
  column: FilterTypeEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetPersonalInfoArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetPhoneTypeArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetPhoneTypeUniqueValuesFromColumnArgs = {
  column: FilterPhoneTypeEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetPrivateTeamArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetPrivateTeamTypeArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetPrivateTeamsUniqueValuesFromColumnArgs = {
  column: FilterPrivateTeamEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetProductInterestArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetProductInterestNestArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetProductInterestUniqueValuesFromColumnArgs = {
  column: FilterProductInterestEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetProductPotentialArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetProductPotentialUniqueValuesFromColumnArgs = {
  column: FilterProductPotentialEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetRegionArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetRegionUniqueValuesFromColumnArgs = {
  column: FilterRegionEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetRelatedCategoryArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetRelatedCompanyArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetRoleArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetSalesTeamArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetSalesTeamTypeArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetSalesTeamTypesUniqueValuesFromColumnArgs = {
  column: FilterSalesTeamTypesEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetSalesTeamUniqueValuesFromColumnArgs = {
  column: FilterSalesTeamEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetSecurityTokenArgs = {
  createAppTokenInput: CreateAppTokenInput;
};

export type QueryGetSomeColumnsArgs = {
  ids: Scalars['String']['input'];
};

export type QueryGetStageGroupArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetStageGroupUniqueValuesFromColumnArgs = {
  column: FilterStageGroupEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetStateArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetTerritoryArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetTerritoryUniqueValuesFromColumnArgs = {
  column: FilterTerritoryEnum;
  filter: Scalars['String']['input'];
};

export type QueryGetUserArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetUserAdditionalFieldArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetUserAdditionalFieldsUserArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetUserMenuArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetUserRoleArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetUserTypeArgs = {
  id: Scalars['Int']['input'];
};

export type QueryUpdateDesignatedByDivisionArgs = {
  divisionId: Scalars['Int']['input'];
};

export type QueryAnalyticsWideSaleArgs = {
  id: Scalars['Int']['input'];
};

export type QueryAuroraSalesReportArgs = {
  aggsCriteria: AggregationsCriteriaInput;
};

export type QueryContactsGlobalSearchQueryArgs = {
  Criteria: ContactCriteriaNestInput;
  SearchFields?: InputMaybe<FindContactNestInput>;
};

export type QueryDownloadReportFileArgs = {
  tmp_index: Scalars['String']['input'];
};

export type QueryFactoryTouchesArgs = {
  aggsCriteria: AggregationsCriteriaInput;
};

export type QueryGetAjIndexStatusArgs = {
  tmp_index: Scalars['String']['input'];
};

export type QueryGetCommissionsAndSalesIndexStatusArgs = {
  tmp_index: Scalars['String']['input'];
};

export type QueryGetCommissionsIndexStatusArgs = {
  tmp_index: Scalars['String']['input'];
};

export type QueryGetOrdersIndexStatusArgs = {
  tmp_index: Scalars['String']['input'];
};

export type QueryGetQuotesIndexStatusArgs = {
  tmp_index: Scalars['String']['input'];
};

export type QueryGetSalesIndexStatusArgs = {
  tmp_index: Scalars['String']['input'];
};

export type QueryMonthlySalesReportArgs = {
  aggsCriteria: AggregationsCriteriaInput;
};

export type QueryPaginateWideAjReportResultsArgs = {
  from: Scalars['Float']['input'];
  size: Scalars['Float']['input'];
  tmp_index: Scalars['String']['input'];
};

export type QueryPaginateWideCommissionsAndSalesReportResultsArgs = {
  from: Scalars['Float']['input'];
  size: Scalars['Float']['input'];
  tmp_index: Scalars['String']['input'];
};

export type QueryPaginateWideCommissionsReportResultsArgs = {
  from: Scalars['Float']['input'];
  size: Scalars['Float']['input'];
  tmp_index: Scalars['String']['input'];
};

export type QueryPaginateWideOrdersReportResultsArgs = {
  from: Scalars['Float']['input'];
  size: Scalars['Float']['input'];
  tmp_index: Scalars['String']['input'];
};

export type QueryPaginateWideQuotesReportResultsArgs = {
  from: Scalars['Float']['input'];
  size: Scalars['Float']['input'];
  tmp_index: Scalars['String']['input'];
};

export type QueryPaginateWideSalesReportResultsArgs = {
  from: Scalars['Float']['input'];
  size: Scalars['Float']['input'];
  tmp_index: Scalars['String']['input'];
};

export type QueryWideAjReportArgs = {
  aggsCriteria: AggregationsCriteriaInput;
};

export type QueryWideAjReportV2Args = {
  aggsCriteria: AggregationsCriteriaInput;
};

export type QueryWideCommissionReportArgs = {
  aggsCriteria: AggregationsCriteriaInput;
  groupBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWideCommissionReportV2Args = {
  aggsCriteria: AggregationsCriteriaInput;
  groupBy?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWideCommissionSaleReportArgs = {
  aggsCriteria: AggregationsCriteriaInput;
};

export type QueryWideCommissionSaleReportV2Args = {
  aggsCriteria: AggregationsCriteriaInput;
};

export type QueryWidePoReportArgs = {
  aggsCriteria: AggregationsCriteriaInput;
};

export type QueryWidePoReportV2Args = {
  aggsCriteria: AggregationsCriteriaInput;
};

export type QueryWideQuotesReportArgs = {
  aggsCriteria: AggregationsCriteriaInput;
};

export type QueryWideQuotesReportV2Args = {
  aggsCriteria: AggregationsCriteriaInput;
};

export type QueryWideSalesReportArgs = {
  aggsCriteria: AggregationsCriteriaInput;
};

export type QueryWideSalesReportV2Args = {
  aggsCriteria: AggregationsCriteriaInput;
};

export type RankOrderByInput = {
  field: OrderCompanyRankEnum;
  sort: OrderTypes;
};

export type RegionCriteriaInput = {
  filter?: InputMaybe<Array<RegionFilterInput>>;
  orderBy?: InputMaybe<Array<RegionOrderByInput>>;
  pagination: PaginationInput2;
};

export type RegionElasticResponse = {
  __typename?: 'RegionElasticResponse';
  results?: Maybe<Array<RegionModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type RegionFilterInput = {
  dataType: ValueType;
  fieldName: FilterRegionEnum;
  operator: StringFilters2;
  value: Scalars['String']['input'];
};

export type RegionModel = {
  __typename?: 'RegionModel';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type RegionOrderByInput = {
  field: OrderRegionEnum;
  sort: OrderTypes;
};

export type RegionWideSales = {
  __typename?: 'RegionWideSales';
  name: Scalars['String']['output'];
  primaryCustomers?: Maybe<Array<PrimaryCustomerWideSales>>;
};

export type RegisterSalesTeamUserInput = {
  salesTeamId: Scalars['Float']['input'];
  tenantId?: InputMaybe<Scalars['Float']['input']>;
  users?: Array<Scalars['Float']['input']>;
};

export type RegisterUserInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  tenant: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type RegisterUserSalesTeamModel = {
  __typename?: 'RegisterUserSalesTeamModel';
  id: Scalars['Float']['output'];
  salesTeam?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type RelatedCategoryCriteriaInput = {
  filter?: InputMaybe<Array<RelatedCategoryFilterInput>>;
  orderBy?: InputMaybe<Array<RelatedCategoryOrderByInput>>;
  pagination: PaginationInput2;
};

export type RelatedCategoryElasticResponse = {
  __typename?: 'RelatedCategoryElasticResponse';
  results?: Maybe<Array<RelatedCategoryModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum RelatedCategoryEnum {
  Description = 'description',
  Id = 'id',
  Name = 'name',
}

export type RelatedCategoryFilterInput = {
  dataType: ValueType;
  fieldName: FilterRelatedCategoryEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type RelatedCategoryModel = {
  __typename?: 'RelatedCategoryModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RelatedCategoryOrderByInput = {
  field: RelatedCategoryEnum;
  sort: OrderTypes;
};

export type RelatedCompanyCriteriaInput = {
  filter?: InputMaybe<Array<RelatedCompanyFilterInput>>;
  orderBy?: InputMaybe<RelatedCompanyOrderByInput>;
  pagination: PaginationInput2;
};

export type RelatedCompanyDbModel = {
  __typename?: 'RelatedCompanyDBModel';
  company?: Maybe<CompanyModel>;
  companyManufacturer?: Maybe<CompanyModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerSplit?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  relatedCategory?: Maybe<RelatedCategoryModel>;
  relatedCompany?: Maybe<CompanyModel>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  valueRole?: Maybe<Scalars['String']['output']>;
};

export type RelatedCompanyElasticResponse = {
  __typename?: 'RelatedCompanyElasticResponse';
  results?: Maybe<Array<RelatedCompanyDbModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type RelatedCompanyFilterInput = {
  dataType: ValueType;
  fieldName: FilterRelatedCompanyEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type RelatedCompanyOrderByInput = {
  field: OrderRelatedCompanyEnum;
  sort: OrderTypes;
};

export type ReportListModel = {
  __typename?: 'ReportListModel';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  extraParams?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  reportRoute: Scalars['String']['output'];
  reportTag: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReportResponseDto = {
  __typename?: 'ReportResponseDTO';
  link?: Maybe<Scalars['String']['output']>;
  requestId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Role = {
  __typename?: 'Role';
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disabledBy?: Maybe<User>;
  disabledDate?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['Int']['output']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['String']['output']>;
};

export type RoleBasicInformation = {
  __typename?: 'RoleBasicInformation';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RoleModel = {
  __typename?: 'RoleModel';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type SalesByMonth = {
  __typename?: 'SalesByMonth';
  name: Scalars['String']['output'];
  sales: Scalars['Float']['output'];
};

export type SalesTeam = {
  __typename?: 'SalesTeam';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SalesTeamCriteriaInput = {
  filter?: InputMaybe<Array<SalesTeamFilterInput>>;
  orderBy?: InputMaybe<SalesTeamOrderByInput>;
  pagination: PaginationInput2;
};

export type SalesTeamFilterInput = {
  dataType: ValueType;
  fieldName: FilterSalesTeamEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type SalesTeamMemberModel = {
  __typename?: 'SalesTeamMemberModel';
  id: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type SalesTeamModel = {
  __typename?: 'SalesTeamModel';
  companySplit?: Maybe<Scalars['Float']['output']>;
  companySplitType?: Maybe<CompanySplitTypesModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  leaderSalesTeamId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  specifierSplit?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<SalesTeamTypesModel>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SalesTeamOrderByInput = {
  field: OrderSalesTeamEnum;
  sort: OrderTypes;
};

export type SalesTeamResponse = {
  __typename?: 'SalesTeamResponse';
  results?: Maybe<Array<SalesTeamModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SalesTeamTypeResponse = {
  __typename?: 'SalesTeamTypeResponse';
  results?: Maybe<Array<SalesTeamTypesModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SalesTeamTypesCriteriaInput = {
  filter?: InputMaybe<Array<SalesTeamTypesFilterInput>>;
  orderBy?: InputMaybe<SalesTeamTypesOrderByInput>;
  pagination: PaginationInput2;
};

export type SalesTeamTypesFilterInput = {
  dataType: ValueType;
  fieldName: FilterSalesTeamTypesEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type SalesTeamTypesModel = {
  __typename?: 'SalesTeamTypesModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SalesTeamTypesOrderByInput = {
  field: OrderSalesTeamTypesEnum;
  sort: OrderTypes;
};

export type Section = {
  __typename?: 'Section';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  reports: Array<MenuReport>;
};

export type SecurityTokenModel = {
  __typename?: 'SecurityTokenModel';
  success: Scalars['Boolean']['output'];
  tokenApp?: Maybe<Scalars['String']['output']>;
};

export type SomeColumnModel = {
  __typename?: 'SomeColumnModel';
  data: Array<ColumnModel>;
  total_found: Scalars['Int']['output'];
  total_requested: Scalars['Int']['output'];
};

export type SplitTypeResponse = {
  __typename?: 'SplitTypeResponse';
  results?: Maybe<Array<CompanySplitTypesModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SplitTypesFilterInput = {
  dataType: ValueType;
  fieldName: FilterCompanySplitTypesEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type SplitTypesOrderByInput = {
  field: OrderCompanySplitTypesEnum;
  sort: OrderTypes;
};

export type StageCriteriaInput = {
  filter?: InputMaybe<Array<StageFilterInput>>;
  orderBy?: InputMaybe<Array<StageOrderByInput>>;
  pagination: PaginationInput2;
};

export type StageElasticResponse = {
  __typename?: 'StageElasticResponse';
  results?: Maybe<Array<SubTableStageByCompanyElasticResponse>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type StageFilterInput = {
  dataType: ValueType;
  fieldName: FilterStageEnum;
  operator: StringFilters2;
  value: Scalars['String']['input'];
};

export type StageGroupCriteriaInput = {
  filter?: InputMaybe<Array<StageGroupFilterInput>>;
  orderBy?: InputMaybe<Array<StageGroupOrderByInput>>;
  pagination: PaginationInput2;
};

export type StageGroupElasticResponse = {
  __typename?: 'StageGroupElasticResponse';
  results?: Maybe<Array<StageGroupOutputDto>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type StageGroupFilterInput = {
  dataType: ValueType;
  fieldName: FilterStageGroupEnum;
  operator: StringFilters2;
  value: Scalars['String']['input'];
};

export type StageGroupModel = {
  __typename?: 'StageGroupModel';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type StageGroupOrderByInput = {
  field: OrderStageGroupEnum;
  sort: OrderTypes;
};

export type StageGroupOutputDto = {
  __typename?: 'StageGroupOutputDto';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type StageOrderByInput = {
  field: OrderStageEnum;
  sort: OrderTypes;
};

export type StageOutputDto = {
  __typename?: 'StageOutputDto';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  closeStatus?: Maybe<CloseStatusModel>;
  company?: Maybe<CompanyModel>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  potential?: Maybe<Scalars['Float']['output']>;
  stageGroup?: Maybe<StageGroupModel>;
  status?: Maybe<StatusModel>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type State = {
  __typename?: 'State';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type StateDbModel = {
  __typename?: 'StateDBModel';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type StatusCriteriaInput = {
  filter?: InputMaybe<Array<StatusFilterInput>>;
  orderBy?: InputMaybe<Array<StatusOrderByInput>>;
  pagination: PaginationInput2;
};

export type StatusElasticResponse = {
  __typename?: 'StatusElasticResponse';
  results?: Maybe<Array<StatusOutputDto>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type StatusFilterInput = {
  dataType: ValueType;
  fieldName: FilterStatusEnum;
  operator: StringFilters2;
  value: Scalars['String']['input'];
};

export type StatusModel = {
  __typename?: 'StatusModel';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type StatusOrderByInput = {
  field: OrderStatusEnum;
  sort: OrderTypes;
};

export type StatusOutputDto = {
  __typename?: 'StatusOutputDto';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  isPredefined?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum StringFilters2 {
  Contains = 'CONTAINS',
  Endswith = 'ENDSWITH',
  Equals = 'EQUALS',
  In = 'IN',
  Isempty = 'ISEMPTY',
  Isnotempty = 'ISNOTEMPTY',
  Isnotnull = 'ISNOTNULL',
  Isnull = 'ISNULL',
  Notcontains = 'NOTCONTAINS',
  Notequals = 'NOTEQUALS',
  Notin = 'NOTIN',
  Startwith = 'STARTWITH',
}

export type SubTableByCompanyElasticResponse = {
  __typename?: 'SubTableByCompanyElasticResponse';
  company: CompanyModel;
  data?: Maybe<Array<Maybe<CloseReasonOutputDto>>>;
  id?: Maybe<Scalars['Float']['output']>;
};

export type SubTableStageByCompanyElasticResponse = {
  __typename?: 'SubTableStageByCompanyElasticResponse';
  company: CompanyModel;
  data?: Maybe<Array<Maybe<StageOutputDto>>>;
  id?: Maybe<Scalars['Float']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  reports: ReportResponseDto;
};

export type TeamWideSales = {
  __typename?: 'TeamWideSales';
  name: Scalars['String']['output'];
  primaryCustomers?: Maybe<Array<PrimaryCustomerWideSales>>;
};

export type TenantInstance = {
  __typename?: 'TenantInstance';
  clientId: Scalars['Int']['output'];
  cognitoClientId?: Maybe<Scalars['String']['output']>;
  cognitoPoolId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  elasticSearchKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  instanceName?: Maybe<Scalars['String']['output']>;
  instanceSettings?: Maybe<InstanceSettings>;
  isActive: Scalars['Boolean']['output'];
  legacyElasticSearchKey?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  tenantId: Scalars['Int']['output'];
  tenantName: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TerritoryCriteriaInput = {
  filter?: InputMaybe<Array<TerritoryFilterInput>>;
  orderBy?: InputMaybe<Array<TerritoryOrderByInput>>;
  pagination: PaginationInput2;
};

export type TerritoryDbModel = {
  __typename?: 'TerritoryDBModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TerritoryElasticResponse = {
  __typename?: 'TerritoryElasticResponse';
  results?: Maybe<Array<TerritoryDbModel>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type TerritoryFilterInput = {
  dataType: ValueType;
  fieldName: FilterTerritoryEnum;
  operator: AllFilters2;
  value: Scalars['String']['input'];
};

export type TerritoryOrderByInput = {
  field: OrderTerritoryEnum;
  sort: OrderTypes;
};

export type Type = {
  __typename?: 'Type';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type TypeCriteriaInput = {
  filter?: InputMaybe<Array<TypeFilterInput>>;
  orderBy?: InputMaybe<Array<TypeOrderByInput>>;
  pagination: PaginationInput2;
};

export type TypeElasticResponse = {
  __typename?: 'TypeElasticResponse';
  results?: Maybe<Array<TypeOutputDto>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type TypeFilterInput = {
  dataType: ValueType;
  fieldName: FilterTypeEnum;
  operator: StringFilters2;
  value: Scalars['String']['input'];
};

export type TypeOrderByInput = {
  field: OrderTypeEnum;
  sort: OrderTypes;
};

export type TypeOutputDto = {
  __typename?: 'TypeOutputDto';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UpdateAdditionalFieldInput = {
  dataStructure?: InputMaybe<CustomFieldDataStructureType>;
  dataType?: InputMaybe<AdditionalFieldDataTypeEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  module?: InputMaybe<AdditionalFieldModuleEnum>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAddressInlineInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  formattedAddress?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  pobox?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<UpdateRegionInput>;
  state?: InputMaybe<UpdateStateInput>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAddressInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  addressType?: InputMaybe<Scalars['Int']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  continent?: InputMaybe<Scalars['Int']['input']>;
  country?: InputMaybe<Scalars['Int']['input']>;
  formattedAddress?: InputMaybe<Scalars['String']['input']>;
  geopositionLatitude?: InputMaybe<Scalars['Float']['input']>;
  geopositionLongitude?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isAddressInvalid?: InputMaybe<Scalars['Boolean']['input']>;
  pobox?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Scalars['Int']['input']>;
  territory?: InputMaybe<Scalars['Int']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAddressTypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isCompany?: InputMaybe<Scalars['Boolean']['input']>;
  isContact?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAnUserInput = {
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  cognitoPoolId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mainEmail?: InputMaybe<Scalars['String']['input']>;
  mainPhone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['Int']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateAnalyticsWideSaleInput = {
  exampleField?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

export type UpdateAttachmentTypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBuyGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCallPatternInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCloseReasonInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateColumnInput = {
  aggregable?: InputMaybe<Scalars['Boolean']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  dataType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  elastic_search_field?: InputMaybe<Scalars['String']['input']>;
  filtrable?: InputMaybe<Scalars['Boolean']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyAdditionalFieldInput = {
  additionalFieldsId: Scalars['Int']['input'];
  companyId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export type UpdateCompanyAttachmentInput = {
  companyId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyBatchInput = {
  callPatternId?: InputMaybe<Scalars['Float']['input']>;
  categoryId?: InputMaybe<Scalars['Float']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  companyClassId?: InputMaybe<Scalars['Float']['input']>;
  companyTypeId?: InputMaybe<Scalars['Float']['input']>;
  datasource?: InputMaybe<Scalars['String']['input']>;
  divisionsId?: InputMaybe<Array<Scalars['Float']['input']>>;
  emails?: InputMaybe<Array<CreateEmailInput>>;
  hasHolydayCard?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartNumberSplit?: InputMaybe<Scalars['Boolean']['input']>;
  hasPosAccount?: InputMaybe<Scalars['Boolean']['input']>;
  ids: Array<Scalars['Int']['input']>;
  impBatch?: InputMaybe<Scalars['Float']['input']>;
  industriesId?: InputMaybe<Array<Scalars['Float']['input']>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  mainAddress?: InputMaybe<UpdateAddressInput>;
  mainEmail?: InputMaybe<UpdateEmailInput>;
  mainPhone?: InputMaybe<UpdatePhoneInput>;
  parent?: InputMaybe<Scalars['Float']['input']>;
  poFooter?: InputMaybe<Scalars['String']['input']>;
  privateTeamId?: InputMaybe<Scalars['Float']['input']>;
  productPotentials?: InputMaybe<Array<Scalars['Float']['input']>>;
  quoteFooter?: InputMaybe<Scalars['String']['input']>;
  rankId?: InputMaybe<Scalars['Float']['input']>;
  refNumber?: InputMaybe<Scalars['String']['input']>;
  salesTeam?: InputMaybe<Scalars['Float']['input']>;
  shipDetail?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['Float']['input']>>;
  tenantDivision?: InputMaybe<Scalars['Float']['input']>;
  visitFrequency?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCompanyBatchModel = {
  __typename?: 'UpdateCompanyBatchModel';
  addresses?: Maybe<Array<AddressDbModel>>;
  hasDwin?: Maybe<Scalars['Boolean']['output']>;
  hasForecast?: Maybe<Scalars['Boolean']['output']>;
  hasQuot?: Maybe<Scalars['Boolean']['output']>;
  hasRegi?: Maybe<Scalars['Boolean']['output']>;
  hasSamp?: Maybe<Scalars['Boolean']['output']>;
  hasSecondaryAlias?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<PhoneDbModel>>;
  printName?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type UpdateCompanyBatchResponseModel = {
  __typename?: 'UpdateCompanyBatchResponseModel';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UpdateCompanyCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyClassInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyDivisionInput = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  divisionId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

export type UpdateCompanyDocNotesInput = {
  companyId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Int']['input'];
  purchaseOrderTerms?: InputMaybe<Scalars['String']['input']>;
  quoteTerms?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyElasticInput = {
  ACTIVE?: InputMaybe<Scalars['Boolean']['input']>;
  ADDRESSES?: InputMaybe<AddressModelEsInput>;
  CALL_PATTERN_ID?: InputMaybe<Scalars['Int']['input']>;
  CATEGORY?: InputMaybe<CompanyCategoryEsInput>;
  CLASS?: InputMaybe<CompanyClassEsInput>;
  COMMENTS?: InputMaybe<Scalars['String']['input']>;
  CREATED_BY?: InputMaybe<UserEsInput>;
  CREATED_DATE?: InputMaybe<Scalars['DateTime']['input']>;
  DATA_SOURCE?: InputMaybe<Scalars['String']['input']>;
  ES_ID?: InputMaybe<Scalars['String']['input']>;
  HAS_HOLYDAY?: InputMaybe<Scalars['Boolean']['input']>;
  ID: Scalars['Float']['input'];
  IMP_BATCH?: InputMaybe<Scalars['Float']['input']>;
  NAME?: InputMaybe<Scalars['String']['input']>;
  PARENT_ID?: InputMaybe<Scalars['Float']['input']>;
  PARENT_NAME?: InputMaybe<Scalars['String']['input']>;
  PHONES?: InputMaybe<PhoneModelEsInput>;
  PRINT_NAME?: InputMaybe<Scalars['String']['input']>;
  REF_NO?: InputMaybe<Scalars['String']['input']>;
  SALES_TEAM?: InputMaybe<CompanySalesTeamEsInput>;
  SHORT_NAME?: InputMaybe<Scalars['String']['input']>;
  TYPE?: InputMaybe<CompanyTypeEsInput>;
  UPDATED_BY?: InputMaybe<UserEsInput>;
  UPDATED_DATE?: InputMaybe<Scalars['DateTime']['input']>;
  VISIT_FREQUENCY?: InputMaybe<Scalars['Float']['input']>;
  WEB_SITE?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyElasticInputList = {
  companies: Array<UpdateCompanyElasticInput>;
};

export type UpdateCompanyElasticInputList2 = {
  companies: Array<UpdateCompanyInlineInput>;
};

export type UpdateCompanyIndustryInput = {
  description: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type UpdateCompanyInlineInput = {
  callPattern?: InputMaybe<UpdateCallPatternInput>;
  category?: InputMaybe<UpdateCompanyCategoryInput>;
  companyType?: InputMaybe<UpdateCompanyTypeAliasInput>;
  hasForecast?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  impBatch?: InputMaybe<Scalars['Float']['input']>;
  mainAddress?: InputMaybe<UpdateAddressInlineInput>;
  mainPhone?: InputMaybe<UpdatePhoneInlineInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<UpdateCompanyRankInput>;
  refNumber?: InputMaybe<Scalars['String']['input']>;
  salesTeam?: InputMaybe<UpdateSalesTeamInput>;
  visitFrequency?: InputMaybe<Scalars['Float']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyInput = {
  addAddresses?: InputMaybe<Array<CreateAddressInput>>;
  addEmails?: InputMaybe<Array<CreateEmailInput>>;
  addPhones?: InputMaybe<Array<CreatePhoneInput>>;
  addresses?: InputMaybe<Array<CreateAddressInput>>;
  callPatternId?: InputMaybe<Scalars['Float']['input']>;
  categoryId?: InputMaybe<Scalars['Float']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  companyClassId?: InputMaybe<Scalars['Float']['input']>;
  companyTypeId?: InputMaybe<Scalars['Float']['input']>;
  datasource?: InputMaybe<Scalars['String']['input']>;
  deleteAddresses?: InputMaybe<Array<Scalars['Int']['input']>>;
  deleteEmails?: InputMaybe<Array<Scalars['Int']['input']>>;
  deletePhones?: InputMaybe<Array<Scalars['Int']['input']>>;
  divisionsId?: InputMaybe<Array<Scalars['Float']['input']>>;
  emails?: InputMaybe<Array<CreateEmailInput>>;
  hasDwin?: InputMaybe<Scalars['Boolean']['input']>;
  hasForecast?: InputMaybe<Scalars['Boolean']['input']>;
  hasHolydayCard?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartNumberSplit?: InputMaybe<Scalars['Boolean']['input']>;
  hasPosAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuot?: InputMaybe<Scalars['Boolean']['input']>;
  hasRegi?: InputMaybe<Scalars['Boolean']['input']>;
  hasSamp?: InputMaybe<Scalars['Boolean']['input']>;
  hasSecondaryAlias?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  impBatch?: InputMaybe<Scalars['Float']['input']>;
  industriesId?: InputMaybe<Array<Scalars['Float']['input']>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  mainAddress?: InputMaybe<UpdateAddressInput>;
  mainContact?: InputMaybe<Scalars['Int']['input']>;
  mainEmail?: InputMaybe<UpdateEmailInput>;
  mainPhone?: InputMaybe<UpdatePhoneInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['Float']['input']>;
  phones?: InputMaybe<Array<CreatePhoneInput>>;
  poFooter?: InputMaybe<Scalars['String']['input']>;
  printName?: InputMaybe<Scalars['String']['input']>;
  privateTeamId?: InputMaybe<Scalars['Float']['input']>;
  productPotentials?: InputMaybe<Array<Scalars['Float']['input']>>;
  quoteFooter?: InputMaybe<Scalars['String']['input']>;
  rankId?: InputMaybe<Scalars['Float']['input']>;
  refNumber?: InputMaybe<Scalars['String']['input']>;
  salesTeam?: InputMaybe<Scalars['Float']['input']>;
  shipDetail?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<Scalars['Float']['input']>>;
  tenantDivision?: InputMaybe<Scalars['Float']['input']>;
  updateAddresses?: InputMaybe<Array<UpdateAddressInput>>;
  updateEmails?: InputMaybe<Array<UpdateEmailInput>>;
  updatePhones?: InputMaybe<Array<UpdatePhoneInput>>;
  visitFrequency?: InputMaybe<Scalars['Float']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyPitchInput = {
  companyId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyRankInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanySalesTeamInput = {
  companyCustomerId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  salesTeamId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateCompanySplitTypesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyTagInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyTierInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyTypeAliasInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateContactAdditionalFieldsContactInput = {
  additionalFieldsContactId?: InputMaybe<Scalars['Int']['input']>;
  contactId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactBatchInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['Int']['input']>>;
  ids: Array<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  products?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactBatchModel = {
  __typename?: 'UpdateContactBatchModel';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type UpdateContactBusinessInfoInput = {
  addAddresses?: InputMaybe<Array<CreateAddressInput>>;
  addEmails?: InputMaybe<Array<CreateEmailInput>>;
  addPhones?: InputMaybe<Array<CreatePhoneInput>>;
  assistant?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['Int']['input']>;
  context?: InputMaybe<Scalars['String']['input']>;
  deleteAddresses?: InputMaybe<Array<Scalars['Int']['input']>>;
  deleteEmails?: InputMaybe<Array<Scalars['Int']['input']>>;
  deletePhones?: InputMaybe<Array<Scalars['Int']['input']>>;
  department?: InputMaybe<Scalars['String']['input']>;
  doNotEmail?: InputMaybe<Scalars['Boolean']['input']>;
  enabledForcedSync?: InputMaybe<Scalars['Boolean']['input']>;
  globallyVisible?: InputMaybe<Scalars['Boolean']['input']>;
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  mainAddress?: InputMaybe<UpdateAddressInput>;
  mainEmail?: InputMaybe<UpdateEmailInput>;
  mainPhone?: InputMaybe<UpdatePhoneInput>;
  manager?: InputMaybe<Scalars['String']['input']>;
  referencedBy?: InputMaybe<Scalars['String']['input']>;
  updateAddresses?: InputMaybe<Array<UpdateAddressInput>>;
  updateEmails?: InputMaybe<Array<UpdateEmailInput>>;
  updatePhones?: InputMaybe<Array<UpdatePhoneInput>>;
};

export type UpdateContactGroupContactInput = {
  contactId?: InputMaybe<Scalars['Int']['input']>;
  groupContactId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

export type UpdateContactGroupInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactInput = {
  businessInfo?: InputMaybe<UpdateContactBusinessInfoInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['Int']['input']>>;
  id: Scalars['Int']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  personalInfo?: InputMaybe<UpdateContactPersonalInfoInput>;
  products?: InputMaybe<Array<Scalars['Int']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactPersonalInfoInput = {
  addAddresses?: InputMaybe<Array<CreateAddressInput>>;
  addEmails?: InputMaybe<Array<CreateEmailInput>>;
  addPhones?: InputMaybe<Array<CreatePhoneInput>>;
  deleteAddresses?: InputMaybe<Array<Scalars['Int']['input']>>;
  deleteEmails?: InputMaybe<Array<Scalars['Int']['input']>>;
  deletePhones?: InputMaybe<Array<Scalars['Int']['input']>>;
  mainAddress?: InputMaybe<UpdateAddressInput>;
  mainEmail?: InputMaybe<UpdateEmailInput>;
  mainPhone?: InputMaybe<UpdatePhoneInput>;
  updateAddresses?: InputMaybe<Array<UpdateAddressInput>>;
  updateEmails?: InputMaybe<Array<UpdateEmailInput>>;
  updatePhones?: InputMaybe<Array<UpdatePhoneInput>>;
};

export type UpdateContinentsInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCountryInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerPriceCodesInput = {
  codes?: InputMaybe<Scalars['String']['input']>;
  companyCustomerId?: InputMaybe<Scalars['Float']['input']>;
  companyManufacturerId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Int']['input'];
  multiplier?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCustomerPriceGroupsInput = {
  buyGroupId?: InputMaybe<Scalars['Float']['input']>;
  companyCustomerId?: InputMaybe<Scalars['Float']['input']>;
  companyManufacturerId?: InputMaybe<Scalars['Float']['input']>;
  companyTierId?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Int']['input'];
};

export type UpdateDepartmentInput = {
  divisionId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDivisionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmailInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  emailType?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateEmailServerInput = {
  id: Scalars['Int']['input'];
  incomingPort?: InputMaybe<Scalars['Float']['input']>;
  incomingProtocol?: InputMaybe<Scalars['String']['input']>;
  incomingSSL?: InputMaybe<Scalars['Boolean']['input']>;
  incomingServer?: InputMaybe<Scalars['String']['input']>;
  outgoingPort?: InputMaybe<Scalars['Int']['input']>;
  outgoingProtocol?: InputMaybe<Scalars['String']['input']>;
  outgoingSSL?: InputMaybe<Scalars['Boolean']['input']>;
  outgoingServer?: InputMaybe<Scalars['String']['input']>;
  providerName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmailTypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFailedReasonInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGridPreferenceInput = {
  gridName: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  options: Scalars['String']['input'];
};

export type UpdateLeadSourceInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLineItemStatusInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOpportunitiesInput = {
  id: Scalars['Int']['input'];
};

export type UpdatePhoneInlineInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePhoneInput = {
  areaCode?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  extension?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneType?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePhoneTypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  isCompany?: InputMaybe<Scalars['Boolean']['input']>;
  isContact?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePrivateTeamInput = {
  id: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePrivateTeamTypeInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductInterestInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductPotentialInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRegionInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRelatedCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRelatedCompanyInput = {
  company?: InputMaybe<Scalars['Float']['input']>;
  companyManufacturer?: InputMaybe<Scalars['Float']['input']>;
  customerSplit?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  relatedCategory?: InputMaybe<Scalars['Float']['input']>;
  relatedCompany?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  valueRole?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReportListInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  extraParams?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reportRoute?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSalesTeamInput = {
  companySplit?: InputMaybe<Scalars['Float']['input']>;
  companySplitType?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  leaderSalesTeamId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  specifierSplit?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateSalesTeamTypesInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStageGroupInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStageInput = {
  closeStatusId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  potential?: InputMaybe<Scalars['Float']['input']>;
  stageGroupId?: InputMaybe<Scalars['Int']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateStateInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStatusInput = {
  id: Scalars['Int']['input'];
  isPredefined?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTenantInstanceInput = {
  clientId?: InputMaybe<Scalars['Int']['input']>;
  cognitoClientId?: InputMaybe<Scalars['String']['input']>;
  cognitoPoolId?: InputMaybe<Scalars['String']['input']>;
  elasticSearchKey?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  instanceName?: InputMaybe<Scalars['String']['input']>;
  instanceSettings?: InputMaybe<InstanceSettingsInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  legacyElasticSearchKey?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['Int']['input']>;
  tenantName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTerritoryInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTypeInput = {
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserAdditionalFieldInput = {
  dataType?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserAdditionalFieldsUserInput = {
  fieldValue: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type UpdateUserLandingWidget = {
  columnMinHeight?: InputMaybe<Scalars['Int']['input']>;
  columnNumber?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  savedName?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  widgetList?: InputMaybe<Array<LandingWidgetInput>>;
};

export type UpdateUserMenuInput = {
  hasLink?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  linkAddress?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUserReportPreferenceInput = {
  configParams?: InputMaybe<Scalars['JSON']['input']>;
  dateRange?: InputMaybe<DateRangeInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  gridItems?: InputMaybe<Array<GridItemInput>>;
  id: Scalars['Int']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  range_from?: InputMaybe<Scalars['DateTime']['input']>;
  range_to?: InputMaybe<Scalars['DateTime']['input']>;
  reportId?: InputMaybe<Scalars['Int']['input']>;
  savedName?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUserReportWidgetInput = {
  extraParams?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reportId?: InputMaybe<Scalars['Float']['input']>;
  tenantId?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateUserRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserTypeInput = {
  canLogin?: InputMaybe<Scalars['Boolean']['input']>;
  emailAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  walletAccess?: InputMaybe<Scalars['Boolean']['input']>;
};

export type User = {
  __typename?: 'User';
  cognitoId?: Maybe<Scalars['String']['output']>;
  cognitoPoolId?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['String']['output']>;
  disabledBy?: Maybe<User>;
  disabledDate?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  mainEmail?: Maybe<Scalars['String']['output']>;
  mainPhone?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<RoleBasicInformation>;
  status?: Maybe<Scalars['Int']['output']>;
  tenantId?: Maybe<Scalars['Int']['output']>;
  typeId?: Maybe<Scalars['Int']['output']>;
  updatedBy?: Maybe<User>;
  updatedDate?: Maybe<Scalars['String']['output']>;
};

export type UserAdditionalFieldModel = {
  __typename?: 'UserAdditionalFieldModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dataType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserAdditionalFieldsUserModel = {
  __typename?: 'UserAdditionalFieldsUserModel';
  additionalFieldId?: Maybe<Scalars['Int']['output']>;
  additionalFieldName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fieldValue?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type UserDbModel = {
  __typename?: 'UserDBModel';
  cognitoId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  emails?: Maybe<Array<Maybe<UserEmailModel>>>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserEsInput = {
  ID?: InputMaybe<Scalars['Int']['input']>;
  NAME?: InputMaybe<Scalars['String']['input']>;
};

export type UserEmailModel = {
  __typename?: 'UserEmailModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  type: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserLandingWidget = {
  __typename?: 'UserLandingWidget';
  columnMinHeight: Scalars['Int']['output'];
  columnNumber: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  savedName: Scalars['String']['output'];
  tenantId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId: Scalars['Int']['output'];
  widgetList: Array<LandingWidget>;
};

export type UserMenuModel = {
  __typename?: 'UserMenuModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  hasLink?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  level?: Maybe<Scalars['Int']['output']>;
  linkAddress?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserModel = {
  __typename?: 'UserModel';
  ID?: Maybe<Scalars['Int']['output']>;
  NAME?: Maybe<Scalars['String']['output']>;
};

export type UserRegisterInput = {
  cognitoId?: InputMaybe<Scalars['String']['input']>;
  cognitoPoolId: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mainEmail: Scalars['String']['input'];
  mainPhone: Scalars['String']['input'];
  status: Scalars['Int']['input'];
  tenantId: Scalars['Int']['input'];
  typeId: Scalars['Int']['input'];
};

export type UserReportPreference = {
  __typename?: 'UserReportPreference';
  configParams: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  gridItems: Array<GridItem>;
  id: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  range_from: Scalars['DateTime']['output'];
  range_to: Scalars['DateTime']['output'];
  reportId: Scalars['Int']['output'];
  savedName: Scalars['String']['output'];
  tenantId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId: Scalars['Int']['output'];
};

export type UserReportWidgetList = {
  __typename?: 'UserReportWidgetList';
  createdAt: Scalars['DateTime']['output'];
  extraParams?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  reportId: Scalars['Int']['output'];
  tenantId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId: Scalars['Int']['output'];
};

export type UserRoleModel = {
  __typename?: 'UserRoleModel';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserTypeModel = {
  __typename?: 'UserTypeModel';
  canLogin?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  emailAccess?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  walletAccess?: Maybe<Scalars['Boolean']['output']>;
};

export enum ValueType {
  Boolean = 'boolean',
  Date = 'date',
  Number = 'number',
  String = 'string',
}

export type WideAjReport = {
  __typename?: 'WideAJReport';
  date?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Array<WideAjReportResult>>;
  tmp_index?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  totalAmounts?: Maybe<Array<WideAjReportResult>>;
};

export type WideAjReportResult = {
  __typename?: 'WideAJReportResult';
  activeFlag?: Maybe<Scalars['Float']['output']>;
  activityJournalDate?: Maybe<Scalars['DateTime']['output']>;
  ajTypeId?: Maybe<Scalars['Float']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  commentsWordCount?: Maybe<Scalars['Float']['output']>;
  contactId?: Maybe<Scalars['Float']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['Float']['output']>;
  followUpDate?: Maybe<Scalars['DateTime']['output']>;
  hasOpportunity?: Maybe<Scalars['Float']['output']>;
  headerId?: Maybe<Scalars['Float']['output']>;
  manufacturerCategoryId?: Maybe<Scalars['Float']['output']>;
  manufacturerCategoryName?: Maybe<Scalars['String']['output']>;
  manufacturerCity?: Maybe<Scalars['String']['output']>;
  manufacturerClassId?: Maybe<Scalars['Float']['output']>;
  manufacturerClassName?: Maybe<Scalars['String']['output']>;
  manufacturerCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  manufacturerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  manufacturerId?: Maybe<Scalars['Float']['output']>;
  manufacturerIndustries?: Maybe<Array<IIndustry>>;
  manufacturerName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCategoryId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentCategoryName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCity?: Maybe<Scalars['String']['output']>;
  manufacturerParentClassId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentClassName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  manufacturerParentId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentIndustries?: Maybe<Array<IIndustry>>;
  manufacturerParentName?: Maybe<Scalars['String']['output']>;
  manufacturerParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  manufacturerParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerParentRegionId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentRegionName?: Maybe<Scalars['String']['output']>;
  manufacturerParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerParentShortName?: Maybe<Scalars['String']['output']>;
  manufacturerParentState?: Maybe<Scalars['String']['output']>;
  manufacturerParentZip?: Maybe<Scalars['String']['output']>;
  manufacturerPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  manufacturerPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerRegionId?: Maybe<Scalars['Float']['output']>;
  manufacturerRegionName?: Maybe<Scalars['String']['output']>;
  manufacturerSalesTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerSalesTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerShortName?: Maybe<Scalars['String']['output']>;
  manufacturerState?: Maybe<Scalars['String']['output']>;
  manufacturerZip?: Maybe<Scalars['String']['output']>;
  monthDaysRange?: Maybe<Scalars['String']['output']>;
  monthsRange?: Maybe<Scalars['String']['output']>;
  opportunityId?: Maybe<Scalars['Float']['output']>;
  percentageDiff?: Maybe<Scalars['String']['output']>;
  posDistributorCategoryId?: Maybe<Scalars['Float']['output']>;
  posDistributorCategoryName?: Maybe<Scalars['String']['output']>;
  posDistributorCity?: Maybe<Scalars['String']['output']>;
  posDistributorClassId?: Maybe<Scalars['Float']['output']>;
  posDistributorClassName?: Maybe<Scalars['String']['output']>;
  posDistributorCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  posDistributorCompanyTypeName?: Maybe<Scalars['Float']['output']>;
  posDistributorIndustries?: Maybe<Array<IIndustry>>;
  posDistributorName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCategoryId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentCategoryName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCity?: Maybe<Scalars['String']['output']>;
  posDistributorParentClassId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentClassName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  posDistributorParentId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentIndustries?: Maybe<Array<IIndustry>>;
  posDistributorParentName?: Maybe<Scalars['String']['output']>;
  posDistributorParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  posDistributorParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorParentRegionId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentRegionName?: Maybe<Scalars['String']['output']>;
  posDistributorParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorParentShortName?: Maybe<Scalars['String']['output']>;
  posDistributorParentState?: Maybe<Scalars['String']['output']>;
  posDistributorParentZip?: Maybe<Scalars['String']['output']>;
  posDistributorPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  posDistributorPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorPrivateTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorRegionId?: Maybe<Scalars['Float']['output']>;
  posDistributorRegionName?: Maybe<Scalars['String']['output']>;
  posDistributorSalesTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorSalesTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorShortName?: Maybe<Scalars['String']['output']>;
  posDistributorState?: Maybe<Scalars['String']['output']>;
  posDistributorZip?: Maybe<Scalars['String']['output']>;
  previousYearMonthDaysRange?: Maybe<Scalars['String']['output']>;
  previousYearMonthsRange?: Maybe<Scalars['String']['output']>;
  previousYearRange?: Maybe<Scalars['String']['output']>;
  primaryCustomerCategoryId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerCategoryName?: Maybe<Scalars['String']['output']>;
  primaryCustomerCity?: Maybe<Scalars['String']['output']>;
  primaryCustomerClassId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerClassName?: Maybe<Scalars['String']['output']>;
  primaryCustomerCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  primaryCustomerId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerIndustries?: Maybe<Array<IIndustry>>;
  primaryCustomerName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCategoryId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentCategoryName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCity?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentClassId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentClassName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentIndustries?: Maybe<Array<IIndustry>>;
  primaryCustomerParentName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentRegionId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentRegionName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentShortName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentState?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentZip?: Maybe<Scalars['String']['output']>;
  primaryCustomerPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  primaryCustomerPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerRegionId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerRegionName?: Maybe<Scalars['String']['output']>;
  primaryCustomerSalesTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerSalesTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerShortName?: Maybe<Scalars['String']['output']>;
  primaryCustomerState?: Maybe<Scalars['String']['output']>;
  primaryCustomerZip?: Maybe<Scalars['String']['output']>;
  quarter?: Maybe<Scalars['String']['output']>;
  recId?: Maybe<Scalars['Float']['output']>;
  salesTeamId?: Maybe<Scalars['Float']['output']>;
  salesTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerCategoryId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCategoryName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerCity?: Maybe<Scalars['String']['output']>;
  secondaryCustomerClassId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerClassName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerIndustries?: Maybe<Array<IIndustry>>;
  secondaryCustomerName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCategoryId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentCategoryName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCity?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentClassId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentClassName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentIndustries?: Maybe<Array<IIndustry>>;
  secondaryCustomerParentName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentRegionId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentRegionName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentShortName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentState?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentZip?: Maybe<Scalars['String']['output']>;
  secondaryCustomerPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerRegionId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerRegionName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerSalesTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerSalesTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerShortName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerState?: Maybe<Scalars['String']['output']>;
  secondaryCustomerZip?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  typeName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
  yearMonth?: Maybe<Scalars['String']['output']>;
};

export type WideCommissionReport = {
  __typename?: 'WideCommissionReport';
  date?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Array<WideCommissionsReportResult>>;
  tmp_index?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  totalAmounts?: Maybe<Array<WideCommissionsReportResult>>;
};

export type WideCommissionSalesReport = {
  __typename?: 'WideCommissionSalesReport';
  date?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Array<WideCommissionsSalesReportResult>>;
  tmp_index?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  totalAmounts?: Maybe<Array<WideCommissionsSalesReportResult>>;
};

export type WideCommissionsReportResult = {
  __typename?: 'WideCommissionsReportResult';
  activeFlag?: Maybe<Scalars['Float']['output']>;
  checkDate?: Maybe<Scalars['DateTime']['output']>;
  checkNumber?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  commissionAmount?: Maybe<Scalars['Float']['output']>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  companyAmount?: Maybe<Scalars['Float']['output']>;
  conversionRate?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  creditedSales?: Maybe<Scalars['Float']['output']>;
  customerReference?: Maybe<Scalars['String']['output']>;
  divisionId?: Maybe<Scalars['Float']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  externalAccountName?: Maybe<Scalars['String']['output']>;
  importBatchNumber?: Maybe<Scalars['Float']['output']>;
  importProcessDate?: Maybe<Scalars['DateTime']['output']>;
  importProductRecordId?: Maybe<Scalars['Float']['output']>;
  importSource?: Maybe<Scalars['Float']['output']>;
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  manufacturerCategoryId?: Maybe<Scalars['Float']['output']>;
  manufacturerCategoryName?: Maybe<Scalars['String']['output']>;
  manufacturerCity?: Maybe<Scalars['String']['output']>;
  manufacturerClassId?: Maybe<Scalars['Float']['output']>;
  manufacturerClassName?: Maybe<Scalars['String']['output']>;
  manufacturerCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  manufacturerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  manufacturerId?: Maybe<Scalars['Float']['output']>;
  manufacturerIndustries?: Maybe<Array<IIndustry>>;
  manufacturerName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCategoryId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentCategoryName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCity?: Maybe<Scalars['String']['output']>;
  manufacturerParentClassId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentClassName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  manufacturerParentId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentIndustries?: Maybe<Array<IIndustry>>;
  manufacturerParentName?: Maybe<Scalars['String']['output']>;
  manufacturerParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  manufacturerParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerParentRegionId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentRegionName?: Maybe<Scalars['String']['output']>;
  manufacturerParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerParentShortName?: Maybe<Scalars['String']['output']>;
  manufacturerParentState?: Maybe<Scalars['String']['output']>;
  manufacturerParentZip?: Maybe<Scalars['String']['output']>;
  manufacturerPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  manufacturerPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerRegionId?: Maybe<Scalars['Float']['output']>;
  manufacturerRegionName?: Maybe<Scalars['String']['output']>;
  manufacturerSalesTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerSalesTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerShortName?: Maybe<Scalars['String']['output']>;
  manufacturerState?: Maybe<Scalars['String']['output']>;
  manufacturerZip?: Maybe<Scalars['String']['output']>;
  marketId?: Maybe<Scalars['Float']['output']>;
  marketName?: Maybe<Scalars['String']['output']>;
  month?: Maybe<Scalars['Float']['output']>;
  monthDaysRange?: Maybe<Scalars['String']['output']>;
  monthsRange?: Maybe<Scalars['String']['output']>;
  originalSalesTeamId?: Maybe<Scalars['Float']['output']>;
  originalSalesTeamName?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['Float']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  percentageDiff?: Maybe<Scalars['String']['output']>;
  poNumber?: Maybe<Scalars['String']['output']>;
  posDistributorCategoryId?: Maybe<Scalars['Float']['output']>;
  posDistributorCategoryName?: Maybe<Scalars['String']['output']>;
  posDistributorCity?: Maybe<Scalars['String']['output']>;
  posDistributorClassId?: Maybe<Scalars['Float']['output']>;
  posDistributorClassName?: Maybe<Scalars['String']['output']>;
  posDistributorCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  posDistributorCompanyTypeName?: Maybe<Scalars['Float']['output']>;
  posDistributorId?: Maybe<Scalars['Float']['output']>;
  posDistributorIndustries?: Maybe<Array<IIndustry>>;
  posDistributorName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCategoryId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentCategoryName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCity?: Maybe<Scalars['String']['output']>;
  posDistributorParentClassId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentClassName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  posDistributorParentId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentIndustries?: Maybe<Array<IIndustry>>;
  posDistributorParentName?: Maybe<Scalars['String']['output']>;
  posDistributorParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  posDistributorParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorParentRegionId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentRegionName?: Maybe<Scalars['String']['output']>;
  posDistributorParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorParentShortName?: Maybe<Scalars['String']['output']>;
  posDistributorParentState?: Maybe<Scalars['String']['output']>;
  posDistributorParentZip?: Maybe<Scalars['String']['output']>;
  posDistributorPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  posDistributorPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorPrivateTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorRegionId?: Maybe<Scalars['Float']['output']>;
  posDistributorRegionName?: Maybe<Scalars['String']['output']>;
  posDistributorSalesTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorSalesTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorShortName?: Maybe<Scalars['String']['output']>;
  posDistributorState?: Maybe<Scalars['String']['output']>;
  posDistributorZip?: Maybe<Scalars['String']['output']>;
  posFlag?: Maybe<Scalars['Float']['output']>;
  previousYearMonthDaysRange?: Maybe<Scalars['String']['output']>;
  previousYearMonthsRange?: Maybe<Scalars['String']['output']>;
  previousYearRange?: Maybe<Scalars['String']['output']>;
  primaryCustomerCategoryId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerCategoryName?: Maybe<Scalars['String']['output']>;
  primaryCustomerCity?: Maybe<Scalars['String']['output']>;
  primaryCustomerClassId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerClassName?: Maybe<Scalars['String']['output']>;
  primaryCustomerCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  primaryCustomerId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerIndustries?: Maybe<Array<IIndustry>>;
  primaryCustomerName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCategoryId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentCategoryName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCity?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentClassId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentClassName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentIndustries?: Maybe<Array<IIndustry>>;
  primaryCustomerParentName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentRegionId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentRegionName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentShortName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentState?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentZip?: Maybe<Scalars['String']['output']>;
  primaryCustomerPartNumber?: Maybe<Scalars['String']['output']>;
  primaryCustomerPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  primaryCustomerPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerRegionId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerRegionName?: Maybe<Scalars['String']['output']>;
  primaryCustomerSalesTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerSalesTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerShortName?: Maybe<Scalars['String']['output']>;
  primaryCustomerState?: Maybe<Scalars['String']['output']>;
  primaryCustomerZip?: Maybe<Scalars['String']['output']>;
  productFamilyId?: Maybe<Scalars['Float']['output']>;
  productFamilyName?: Maybe<Scalars['String']['output']>;
  productLine?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  quarter?: Maybe<Scalars['String']['output']>;
  recId?: Maybe<Scalars['Float']['output']>;
  relatedCommissionId?: Maybe<Scalars['Float']['output']>;
  salesAmount?: Maybe<Scalars['Float']['output']>;
  salesOrderNumber?: Maybe<Scalars['String']['output']>;
  salesTeamId?: Maybe<Scalars['Float']['output']>;
  salesTeamName?: Maybe<Scalars['String']['output']>;
  salesTeamsSplitAmount?: Maybe<Scalars['Float']['output']>;
  salesTeamsSplitPercent?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCategoryId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCategoryName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerCity?: Maybe<Scalars['String']['output']>;
  secondaryCustomerCity1?: Maybe<Scalars['String']['output']>;
  secondaryCustomerClassId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerClassName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerIndustries?: Maybe<Array<IIndustry>>;
  secondaryCustomerName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCategoryId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentCategoryName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCity?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentClassId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentClassName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentIndustries?: Maybe<Array<IIndustry>>;
  secondaryCustomerParentName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentRegionId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentRegionName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentShortName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentState?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentZip?: Maybe<Scalars['String']['output']>;
  secondaryCustomerPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerRegionId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerRegionName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerSalesTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerSalesTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerShortName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerState?: Maybe<Scalars['String']['output']>;
  secondaryCustomerState1?: Maybe<Scalars['String']['output']>;
  secondaryCustomerZip?: Maybe<Scalars['String']['output']>;
  secondaryCustomerZip1?: Maybe<Scalars['String']['output']>;
  somethingRowId?: Maybe<Scalars['String']['output']>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
  yearMonth?: Maybe<Scalars['String']['output']>;
};

export type WideCommissionsSalesReportResult = {
  __typename?: 'WideCommissionsSalesReportResult';
  activeFlag?: Maybe<Scalars['Float']['output']>;
  bookingCost?: Maybe<Scalars['Float']['output']>;
  buyGroupName?: Maybe<Scalars['String']['output']>;
  checkDate?: Maybe<Scalars['String']['output']>;
  checkNumber?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  commissionAmount?: Maybe<Scalars['Float']['output']>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  companyAmount?: Maybe<Scalars['Float']['output']>;
  conversionRate?: Maybe<Scalars['Float']['output']>;
  creditedSales?: Maybe<Scalars['Float']['output']>;
  customerReference?: Maybe<Scalars['String']['output']>;
  divisionID?: Maybe<Scalars['Float']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  externalAccountName?: Maybe<Scalars['String']['output']>;
  importBatchNumber?: Maybe<Scalars['Float']['output']>;
  importProcessDate?: Maybe<Scalars['String']['output']>;
  importProductRecordID?: Maybe<Scalars['Float']['output']>;
  importSource?: Maybe<Scalars['Float']['output']>;
  invoiceDate?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  manufacturerCategoryID?: Maybe<Scalars['Float']['output']>;
  manufacturerCategoryName?: Maybe<Scalars['String']['output']>;
  manufacturerCity?: Maybe<Scalars['String']['output']>;
  manufacturerClassID?: Maybe<Scalars['Float']['output']>;
  manufacturerClassName?: Maybe<Scalars['String']['output']>;
  manufacturerCompanyTypeID?: Maybe<Scalars['Float']['output']>;
  manufacturerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  manufacturerID?: Maybe<Scalars['Float']['output']>;
  manufacturerIndustries?: Maybe<Array<IIndustry>>;
  manufacturerName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCategoryID?: Maybe<Scalars['Float']['output']>;
  manufacturerParentCategoryName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCity?: Maybe<Scalars['String']['output']>;
  manufacturerParentClassID?: Maybe<Scalars['Float']['output']>;
  manufacturerParentClassName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCompanyTypeID?: Maybe<Scalars['Float']['output']>;
  manufacturerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  manufacturerParentID?: Maybe<Scalars['Float']['output']>;
  manufacturerParentIndustries?: Maybe<Array<IIndustry>>;
  manufacturerParentName?: Maybe<Scalars['String']['output']>;
  manufacturerParentPrivateTeamID?: Maybe<Scalars['Float']['output']>;
  manufacturerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerParentRegionID?: Maybe<Scalars['Float']['output']>;
  manufacturerParentRegionName?: Maybe<Scalars['String']['output']>;
  manufacturerParentState?: Maybe<Scalars['String']['output']>;
  manufacturerParentZip?: Maybe<Scalars['String']['output']>;
  manufacturerPrivateTeamID?: Maybe<Scalars['Float']['output']>;
  manufacturerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerRegionID?: Maybe<Scalars['Float']['output']>;
  manufacturerRegionName?: Maybe<Scalars['String']['output']>;
  manufacturerState?: Maybe<Scalars['String']['output']>;
  manufacturerZip?: Maybe<Scalars['String']['output']>;
  marketID?: Maybe<Scalars['Float']['output']>;
  marketName?: Maybe<Scalars['String']['output']>;
  month?: Maybe<Scalars['Float']['output']>;
  monthDaysRange?: Maybe<Scalars['String']['output']>;
  monthsRange?: Maybe<Scalars['String']['output']>;
  originalSalesTeamID?: Maybe<Scalars['Float']['output']>;
  originalSalesTeamName?: Maybe<Scalars['String']['output']>;
  paid?: Maybe<Scalars['Float']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  percentageDiff?: Maybe<Scalars['String']['output']>;
  poNumber?: Maybe<Scalars['String']['output']>;
  posDistributorCategoryID?: Maybe<Scalars['Float']['output']>;
  posDistributorCategoryName?: Maybe<Scalars['String']['output']>;
  posDistributorCity?: Maybe<Scalars['String']['output']>;
  posDistributorClassID?: Maybe<Scalars['Float']['output']>;
  posDistributorClassName?: Maybe<Scalars['String']['output']>;
  posDistributorCompanyTypeID?: Maybe<Scalars['Float']['output']>;
  posDistributorCompanyTypeName?: Maybe<Scalars['String']['output']>;
  posDistributorID?: Maybe<Scalars['Float']['output']>;
  posDistributorIndustries?: Maybe<Array<IIndustry>>;
  posDistributorName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCategoryID?: Maybe<Scalars['Float']['output']>;
  posDistributorParentCategoryName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCity?: Maybe<Scalars['String']['output']>;
  posDistributorParentClassID?: Maybe<Scalars['Float']['output']>;
  posDistributorParentClassName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCompanyTypeID?: Maybe<Scalars['Float']['output']>;
  posDistributorParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  posDistributorParentID?: Maybe<Scalars['Float']['output']>;
  posDistributorParentIndustries?: Maybe<Array<IIndustry>>;
  posDistributorParentName?: Maybe<Scalars['String']['output']>;
  posDistributorParentPrivateTeamID?: Maybe<Scalars['Float']['output']>;
  posDistributorParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorParentRegionID?: Maybe<Scalars['Float']['output']>;
  posDistributorParentRegionName?: Maybe<Scalars['String']['output']>;
  posDistributorParentState?: Maybe<Scalars['String']['output']>;
  posDistributorParentZip?: Maybe<Scalars['String']['output']>;
  posDistributorPrivateTeamID?: Maybe<Scalars['Float']['output']>;
  posDistributorPrivateTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorRegionID?: Maybe<Scalars['Float']['output']>;
  posDistributorRegionName?: Maybe<Scalars['String']['output']>;
  posDistributorState?: Maybe<Scalars['String']['output']>;
  posDistributorZip?: Maybe<Scalars['String']['output']>;
  posFlag?: Maybe<Scalars['Float']['output']>;
  posSplitAmount?: Maybe<Scalars['Float']['output']>;
  previousYearMonthDaysRange?: Maybe<Scalars['String']['output']>;
  previousYearMonthsRange?: Maybe<Scalars['String']['output']>;
  previousYearRange?: Maybe<Scalars['String']['output']>;
  primaryCustomerCategoryID?: Maybe<Scalars['Float']['output']>;
  primaryCustomerCategoryName?: Maybe<Scalars['String']['output']>;
  primaryCustomerCity?: Maybe<Scalars['String']['output']>;
  primaryCustomerCity1?: Maybe<Scalars['String']['output']>;
  primaryCustomerClassID?: Maybe<Scalars['Float']['output']>;
  primaryCustomerClassName?: Maybe<Scalars['String']['output']>;
  primaryCustomerCompanyTypeID?: Maybe<Scalars['Float']['output']>;
  primaryCustomerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  primaryCustomerID?: Maybe<Scalars['Float']['output']>;
  primaryCustomerIndustries?: Maybe<Array<IIndustry>>;
  primaryCustomerName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCategoryID?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentCategoryName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCity?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentClassID?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentClassName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCompanyTypeID?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentID?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentIndustries?: Maybe<Array<IIndustry>>;
  primaryCustomerParentName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentPrivateTeamID?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentRegionID?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentRegionName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentState?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentZip?: Maybe<Scalars['String']['output']>;
  primaryCustomerPartNumber?: Maybe<Scalars['String']['output']>;
  primaryCustomerPrivateTeamID?: Maybe<Scalars['Float']['output']>;
  primaryCustomerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerRegionID?: Maybe<Scalars['Float']['output']>;
  primaryCustomerRegionName?: Maybe<Scalars['String']['output']>;
  primaryCustomerState?: Maybe<Scalars['String']['output']>;
  primaryCustomerState1?: Maybe<Scalars['String']['output']>;
  primaryCustomerZip?: Maybe<Scalars['String']['output']>;
  primaryCustomerZip1?: Maybe<Scalars['String']['output']>;
  productFamilyID?: Maybe<Scalars['String']['output']>;
  productFamilyName?: Maybe<Scalars['String']['output']>;
  productLine?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  quarter?: Maybe<Scalars['String']['output']>;
  realCost?: Maybe<Scalars['Float']['output']>;
  recID?: Maybe<Scalars['Float']['output']>;
  relatedCommissionID?: Maybe<Scalars['Float']['output']>;
  salesAmount?: Maybe<Scalars['Float']['output']>;
  salesOrderNumber?: Maybe<Scalars['String']['output']>;
  salesTeamID?: Maybe<Scalars['Float']['output']>;
  salesTeamName?: Maybe<Scalars['String']['output']>;
  salesTeamsSplitAmount?: Maybe<Scalars['Float']['output']>;
  salesTeamsSplitPercent?: Maybe<Scalars['Float']['output']>;
  secondCustomerSales?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCategoryID?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCategoryName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerCity?: Maybe<Scalars['String']['output']>;
  secondaryCustomerClassID?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerClassName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerCompanyTypeID?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerID?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerIndustries?: Maybe<Array<IIndustry>>;
  secondaryCustomerName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCategoryID?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentCategoryName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCity?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentClassID?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentClassName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCompanyTypeID?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentID?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentIndustries?: Maybe<Array<IIndustry>>;
  secondaryCustomerParentName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentPrivateTeamID?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentRegionID?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentRegionName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentState?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentZip?: Maybe<Scalars['String']['output']>;
  secondaryCustomerPrivateTeamID?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerRegionID?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerRegionName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerState?: Maybe<Scalars['String']['output']>;
  secondaryCustomerZip?: Maybe<Scalars['String']['output']>;
  somethingRowID?: Maybe<Scalars['String']['output']>;
  totalPrice?: Maybe<Scalars['Float']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
  yearMonth?: Maybe<Scalars['String']['output']>;
};

export type WidePoReport = {
  __typename?: 'WidePOReport';
  date?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Array<WidePoReportResult>>;
  tmp_index?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  totalAmounts?: Maybe<Array<WidePoReportResult>>;
};

export type WidePoReportResult = {
  __typename?: 'WidePOReportResult';
  accountingDate?: Maybe<Scalars['DateTime']['output']>;
  activeFlag?: Maybe<Scalars['Float']['output']>;
  billToAddress?: Maybe<Scalars['String']['output']>;
  billToCity?: Maybe<Scalars['String']['output']>;
  billToCountry?: Maybe<Scalars['String']['output']>;
  billToState?: Maybe<Scalars['String']['output']>;
  billToType?: Maybe<Scalars['String']['output']>;
  billToZip?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  closeStatus?: Maybe<Scalars['String']['output']>;
  commissionProjectedDetail?: Maybe<Scalars['Float']['output']>;
  commissionProjectedHeader?: Maybe<Scalars['Float']['output']>;
  commissionRateDetail?: Maybe<Scalars['Float']['output']>;
  commissionRateHeader?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerPartNumber?: Maybe<Scalars['String']['output']>;
  customerReference?: Maybe<Scalars['String']['output']>;
  detailInsertUser?: Maybe<Scalars['Float']['output']>;
  detailMatchingSalesId?: Maybe<Scalars['Float']['output']>;
  detailUpdateDate?: Maybe<Scalars['DateTime']['output']>;
  detailUpdateUser?: Maybe<Scalars['Float']['output']>;
  freightDetails?: Maybe<Scalars['String']['output']>;
  headerId?: Maybe<Scalars['Float']['output']>;
  headerInsertDate?: Maybe<Scalars['DateTime']['output']>;
  headerInsertUser?: Maybe<Scalars['Float']['output']>;
  headerMatchingSalesId?: Maybe<Scalars['Float']['output']>;
  headerUpdateDate?: Maybe<Scalars['DateTime']['output']>;
  headerUpdateUser?: Maybe<Scalars['Float']['output']>;
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  jobId?: Maybe<Scalars['Float']['output']>;
  manufacturerCategoryId?: Maybe<Scalars['Float']['output']>;
  manufacturerCategoryName?: Maybe<Scalars['String']['output']>;
  manufacturerCity?: Maybe<Scalars['String']['output']>;
  manufacturerClassId?: Maybe<Scalars['Float']['output']>;
  manufacturerClassName?: Maybe<Scalars['String']['output']>;
  manufacturerCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  manufacturerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  manufacturerId?: Maybe<Scalars['Float']['output']>;
  manufacturerIndustries?: Maybe<Array<IIndustry>>;
  manufacturerName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCategoryId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentCategoryName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCity?: Maybe<Scalars['String']['output']>;
  manufacturerParentClassId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentClassName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  manufacturerParentId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentIndustries?: Maybe<Array<IIndustry>>;
  manufacturerParentName?: Maybe<Scalars['String']['output']>;
  manufacturerParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  manufacturerParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerParentRegionId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentRegionName?: Maybe<Scalars['String']['output']>;
  manufacturerParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerParentShortName?: Maybe<Scalars['String']['output']>;
  manufacturerParentState?: Maybe<Scalars['String']['output']>;
  manufacturerParentZip?: Maybe<Scalars['String']['output']>;
  manufacturerPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  manufacturerPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerRegionId?: Maybe<Scalars['Float']['output']>;
  manufacturerRegionName?: Maybe<Scalars['String']['output']>;
  manufacturerSalesTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerSalesTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerShortName?: Maybe<Scalars['String']['output']>;
  manufacturerState?: Maybe<Scalars['String']['output']>;
  manufacturerZip?: Maybe<Scalars['String']['output']>;
  month?: Maybe<Scalars['Float']['output']>;
  monthDaysRange?: Maybe<Scalars['String']['output']>;
  monthsRange?: Maybe<Scalars['String']['output']>;
  multiplier?: Maybe<Scalars['Float']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  opportunityId?: Maybe<Scalars['Float']['output']>;
  orderDate?: Maybe<Scalars['DateTime']['output']>;
  orderDateYear?: Maybe<Scalars['DateTime']['output']>;
  orderQuantity?: Maybe<Scalars['Float']['output']>;
  overageAmount?: Maybe<Scalars['Float']['output']>;
  overagePercentage?: Maybe<Scalars['Float']['output']>;
  partDescription?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  percentageDiff?: Maybe<Scalars['String']['output']>;
  planDate?: Maybe<Scalars['DateTime']['output']>;
  planQuantity?: Maybe<Scalars['Float']['output']>;
  poBilltoType?: Maybe<Scalars['Float']['output']>;
  poNumber?: Maybe<Scalars['String']['output']>;
  posDistributorCategoryId?: Maybe<Scalars['Float']['output']>;
  posDistributorCategoryName?: Maybe<Scalars['String']['output']>;
  posDistributorCity?: Maybe<Scalars['String']['output']>;
  posDistributorClassId?: Maybe<Scalars['Float']['output']>;
  posDistributorClassName?: Maybe<Scalars['String']['output']>;
  posDistributorCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  posDistributorCompanyTypeName?: Maybe<Scalars['Float']['output']>;
  posDistributorId?: Maybe<Scalars['Float']['output']>;
  posDistributorIndustries?: Maybe<Array<IIndustry>>;
  posDistributorName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCategoryId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentCategoryName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCity?: Maybe<Scalars['String']['output']>;
  posDistributorParentClassId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentClassName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  posDistributorParentId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentIndustries?: Maybe<Array<IIndustry>>;
  posDistributorParentName?: Maybe<Scalars['String']['output']>;
  posDistributorParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  posDistributorParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorParentRegionId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentRegionName?: Maybe<Scalars['String']['output']>;
  posDistributorParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorParentShortName?: Maybe<Scalars['String']['output']>;
  posDistributorParentState?: Maybe<Scalars['String']['output']>;
  posDistributorParentZip?: Maybe<Scalars['String']['output']>;
  posDistributorPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  posDistributorPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorPrivateTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorRegionId?: Maybe<Scalars['Float']['output']>;
  posDistributorRegionName?: Maybe<Scalars['String']['output']>;
  posDistributorSalesTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorSalesTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorShortName?: Maybe<Scalars['String']['output']>;
  posDistributorState?: Maybe<Scalars['String']['output']>;
  posDistributorZip?: Maybe<Scalars['String']['output']>;
  previousYearMonthDaysRange?: Maybe<Scalars['String']['output']>;
  previousYearMonthsRange?: Maybe<Scalars['String']['output']>;
  previousYearRange?: Maybe<Scalars['String']['output']>;
  primaryCustomerCategoryId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerCategoryName?: Maybe<Scalars['String']['output']>;
  primaryCustomerCity?: Maybe<Scalars['String']['output']>;
  primaryCustomerClassId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerClassName?: Maybe<Scalars['String']['output']>;
  primaryCustomerCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  primaryCustomerId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerIndustries?: Maybe<Array<IIndustry>>;
  primaryCustomerName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCategoryId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentCategoryName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCity?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentClassId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentClassName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentIndustries?: Maybe<Array<IIndustry>>;
  primaryCustomerParentName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentRegionId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentRegionName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentShortName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentState?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentZip?: Maybe<Scalars['String']['output']>;
  primaryCustomerPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  primaryCustomerPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerRegionId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerRegionName?: Maybe<Scalars['String']['output']>;
  primaryCustomerSalesTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerSalesTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerShortName?: Maybe<Scalars['String']['output']>;
  primaryCustomerState?: Maybe<Scalars['String']['output']>;
  primaryCustomerZip?: Maybe<Scalars['String']['output']>;
  productFamilyName?: Maybe<Scalars['String']['output']>;
  program?: Maybe<Scalars['String']['output']>;
  quarter?: Maybe<Scalars['String']['output']>;
  quoteDate?: Maybe<Scalars['DateTime']['output']>;
  quoteId?: Maybe<Scalars['Float']['output']>;
  quoteNumber?: Maybe<Scalars['String']['output']>;
  recId?: Maybe<Scalars['Float']['output']>;
  recordStatus?: Maybe<Scalars['String']['output']>;
  salesOrderDate?: Maybe<Scalars['DateTime']['output']>;
  salesOrderNumber?: Maybe<Scalars['String']['output']>;
  salesTeamId?: Maybe<Scalars['Float']['output']>;
  salesTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerCategoryId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCategoryName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerCity?: Maybe<Scalars['String']['output']>;
  secondaryCustomerClassId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerClassName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerIndustries?: Maybe<Array<IIndustry>>;
  secondaryCustomerName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCategoryId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentCategoryName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCity?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentClassId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentClassName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentIndustries?: Maybe<Array<IIndustry>>;
  secondaryCustomerParentName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentRegionId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentRegionName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentShortName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentState?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentZip?: Maybe<Scalars['String']['output']>;
  secondaryCustomerPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerRegionId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerRegionName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerSalesTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerSalesTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerShortName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerState?: Maybe<Scalars['String']['output']>;
  secondaryCustomerZip?: Maybe<Scalars['String']['output']>;
  sequenceNumber?: Maybe<Scalars['Float']['output']>;
  shipAgent?: Maybe<Scalars['String']['output']>;
  shipDetails?: Maybe<Scalars['String']['output']>;
  shipToAddress?: Maybe<Scalars['String']['output']>;
  shipToCity?: Maybe<Scalars['String']['output']>;
  shipToCountry?: Maybe<Scalars['String']['output']>;
  shipToState?: Maybe<Scalars['String']['output']>;
  shipToType?: Maybe<Scalars['String']['output']>;
  shipToZip?: Maybe<Scalars['String']['output']>;
  shippingDate?: Maybe<Scalars['DateTime']['output']>;
  shippingQuantity?: Maybe<Scalars['Float']['output']>;
  shippingStatus?: Maybe<Scalars['String']['output']>;
  standardRate?: Maybe<Scalars['Float']['output']>;
  totalPriceDetail?: Maybe<Scalars['Float']['output']>;
  totalPriceHeader?: Maybe<Scalars['Float']['output']>;
  transactionDetailId?: Maybe<Scalars['Float']['output']>;
  transportId?: Maybe<Scalars['Float']['output']>;
  unitOfMeasure?: Maybe<Scalars['String']['output']>;
  unitOfMeasureUnits?: Maybe<Scalars['Float']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
  yearMonth?: Maybe<Scalars['DateTime']['output']>;
};

export type WideQuotesReport = {
  __typename?: 'WideQuotesReport';
  date?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Array<WideQuotesReportResult>>;
  tmp_index?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  totalAmounts?: Maybe<Array<WideQuotesReportResult>>;
};

export type WideQuotesReportResult = {
  __typename?: 'WideQuotesReportResult';
  application?: Maybe<Scalars['String']['output']>;
  bidDueDate?: Maybe<Scalars['DateTime']['output']>;
  bidOrBuy?: Maybe<Scalars['String']['output']>;
  buyGroupId?: Maybe<Scalars['Float']['output']>;
  buyGroupName?: Maybe<Scalars['String']['output']>;
  closeDate?: Maybe<Scalars['DateTime']['output']>;
  competitorManufacturer?: Maybe<Scalars['String']['output']>;
  competitorPartNumber?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerPartNumber?: Maybe<Scalars['String']['output']>;
  customerPriceFlag?: Maybe<Scalars['String']['output']>;
  customerProgram?: Maybe<Scalars['String']['output']>;
  customerReferenceNumber?: Maybe<Scalars['String']['output']>;
  dataSource?: Maybe<Scalars['String']['output']>;
  deliveryStatus?: Maybe<Scalars['String']['output']>;
  distributorContactId?: Maybe<Scalars['Float']['output']>;
  distributorContactName?: Maybe<Scalars['String']['output']>;
  emailFlag?: Maybe<Scalars['Float']['output']>;
  estimatedAnnualUnits?: Maybe<Scalars['Float']['output']>;
  expiryDate?: Maybe<Scalars['DateTime']['output']>;
  extendedPrice?: Maybe<Scalars['Float']['output']>;
  followUpDate?: Maybe<Scalars['DateTime']['output']>;
  headerComments?: Maybe<Scalars['String']['output']>;
  headerNotes?: Maybe<Scalars['String']['output']>;
  headerSequenceNumber?: Maybe<Scalars['Float']['output']>;
  insertDate?: Maybe<Scalars['DateTime']['output']>;
  insertUserId?: Maybe<Scalars['Float']['output']>;
  itemAction?: Maybe<Scalars['String']['output']>;
  itemCode?: Maybe<Scalars['String']['output']>;
  itemCost?: Maybe<Scalars['Float']['output']>;
  leadTime?: Maybe<Scalars['String']['output']>;
  lineItemNotes?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  manufacturerCategoryId?: Maybe<Scalars['Float']['output']>;
  manufacturerCategoryName?: Maybe<Scalars['String']['output']>;
  manufacturerCity?: Maybe<Scalars['String']['output']>;
  manufacturerClassId?: Maybe<Scalars['Float']['output']>;
  manufacturerClassName?: Maybe<Scalars['String']['output']>;
  manufacturerCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  manufacturerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  manufacturerContactId?: Maybe<Scalars['Float']['output']>;
  manufacturerContactName?: Maybe<Scalars['String']['output']>;
  manufacturerId?: Maybe<Scalars['Float']['output']>;
  manufacturerIdHeader?: Maybe<Scalars['Float']['output']>;
  manufacturerIndustries?: Maybe<Array<IIndustry>>;
  manufacturerName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCategoryId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentCategoryName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCity?: Maybe<Scalars['String']['output']>;
  manufacturerParentClassId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentClassName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  manufacturerParentId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentIndustries?: Maybe<Array<IIndustry>>;
  manufacturerParentName?: Maybe<Scalars['String']['output']>;
  manufacturerParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  manufacturerParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerParentRegionId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentRegionName?: Maybe<Scalars['String']['output']>;
  manufacturerParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerParentShortName?: Maybe<Scalars['String']['output']>;
  manufacturerParentState?: Maybe<Scalars['String']['output']>;
  manufacturerParentZip?: Maybe<Scalars['String']['output']>;
  manufacturerPartNumber?: Maybe<Scalars['String']['output']>;
  manufacturerPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  manufacturerPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerRegionId?: Maybe<Scalars['Float']['output']>;
  manufacturerRegionName?: Maybe<Scalars['String']['output']>;
  manufacturerSalesTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerSalesTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerShortName?: Maybe<Scalars['String']['output']>;
  manufacturerState?: Maybe<Scalars['String']['output']>;
  manufacturerZip?: Maybe<Scalars['String']['output']>;
  monthDaysRange?: Maybe<Scalars['String']['output']>;
  monthsRange?: Maybe<Scalars['String']['output']>;
  multiplier?: Maybe<Scalars['Float']['output']>;
  openStatus?: Maybe<Scalars['String']['output']>;
  opportunityId?: Maybe<Scalars['Float']['output']>;
  opportunityItemId?: Maybe<Scalars['Float']['output']>;
  parentQuoteId?: Maybe<Scalars['Float']['output']>;
  partDescription?: Maybe<Scalars['String']['output']>;
  percentageDiff?: Maybe<Scalars['String']['output']>;
  posDistributorCategoryId?: Maybe<Scalars['Float']['output']>;
  posDistributorCategoryName?: Maybe<Scalars['String']['output']>;
  posDistributorCity?: Maybe<Scalars['String']['output']>;
  posDistributorClassId?: Maybe<Scalars['Float']['output']>;
  posDistributorClassName?: Maybe<Scalars['String']['output']>;
  posDistributorCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  posDistributorCompanyTypeName?: Maybe<Scalars['Float']['output']>;
  posDistributorId?: Maybe<Scalars['Float']['output']>;
  posDistributorIndustries?: Maybe<Array<IIndustry>>;
  posDistributorName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCategoryId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentCategoryName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCity?: Maybe<Scalars['String']['output']>;
  posDistributorParentClassId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentClassName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  posDistributorParentId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentIndustries?: Maybe<Array<IIndustry>>;
  posDistributorParentName?: Maybe<Scalars['String']['output']>;
  posDistributorParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  posDistributorParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorParentRegionId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentRegionName?: Maybe<Scalars['String']['output']>;
  posDistributorParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorParentShortName?: Maybe<Scalars['String']['output']>;
  posDistributorParentState?: Maybe<Scalars['String']['output']>;
  posDistributorParentZip?: Maybe<Scalars['String']['output']>;
  posDistributorPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  posDistributorPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorPrivateTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorRegionId?: Maybe<Scalars['Float']['output']>;
  posDistributorRegionName?: Maybe<Scalars['String']['output']>;
  posDistributorSalesTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorSalesTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorShortName?: Maybe<Scalars['String']['output']>;
  posDistributorState?: Maybe<Scalars['String']['output']>;
  posDistributorZip?: Maybe<Scalars['String']['output']>;
  previousYearMonthDaysRange?: Maybe<Scalars['String']['output']>;
  previousYearMonthsRange?: Maybe<Scalars['String']['output']>;
  previousYearRange?: Maybe<Scalars['String']['output']>;
  primaryCustomerCategoryId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerCategoryName?: Maybe<Scalars['String']['output']>;
  primaryCustomerCity?: Maybe<Scalars['String']['output']>;
  primaryCustomerClassId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerClassName?: Maybe<Scalars['String']['output']>;
  primaryCustomerCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  primaryCustomerContactId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerContactName?: Maybe<Scalars['String']['output']>;
  primaryCustomerId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerIndustries?: Maybe<Array<IIndustry>>;
  primaryCustomerName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCategoryId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentCategoryName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCity?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentClassId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentClassName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentIndustries?: Maybe<Array<IIndustry>>;
  primaryCustomerParentName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentRegionId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentRegionName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentShortName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentState?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentZip?: Maybe<Scalars['String']['output']>;
  primaryCustomerPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  primaryCustomerPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerRegionId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerRegionName?: Maybe<Scalars['String']['output']>;
  primaryCustomerSalesTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerSalesTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerShortName?: Maybe<Scalars['String']['output']>;
  primaryCustomerState?: Maybe<Scalars['String']['output']>;
  primaryCustomerZip?: Maybe<Scalars['String']['output']>;
  probability?: Maybe<Scalars['Float']['output']>;
  productLine?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  quarter?: Maybe<Scalars['String']['output']>;
  quoteDate?: Maybe<Scalars['DateTime']['output']>;
  quoteHeaderId?: Maybe<Scalars['Float']['output']>;
  quoteNumber?: Maybe<Scalars['String']['output']>;
  quoteOwnerId?: Maybe<Scalars['Float']['output']>;
  quoteRecipient?: Maybe<Scalars['String']['output']>;
  quoteValue?: Maybe<Scalars['Float']['output']>;
  recId?: Maybe<Scalars['Float']['output']>;
  requestForQuoteNumber?: Maybe<Scalars['String']['output']>;
  resellPrice?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCategoryId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCategoryName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerCity?: Maybe<Scalars['String']['output']>;
  secondaryCustomerClassId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerClassName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerContactId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerContactName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerIndustries?: Maybe<Array<IIndustry>>;
  secondaryCustomerName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCategoryId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentCategoryName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCity?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentClassId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentClassName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentIndustries?: Maybe<Array<IIndustry>>;
  secondaryCustomerParentName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentRegionId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentRegionName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentShortName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentState?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentZip?: Maybe<Scalars['String']['output']>;
  secondaryCustomerPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerRegionId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerRegionName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerSalesTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerSalesTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerShortName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerState?: Maybe<Scalars['String']['output']>;
  secondaryCustomerZip?: Maybe<Scalars['String']['output']>;
  sequenceNumber?: Maybe<Scalars['String']['output']>;
  standardRate?: Maybe<Scalars['Float']['output']>;
  targetCost?: Maybe<Scalars['Float']['output']>;
  territory?: Maybe<Scalars['String']['output']>;
  tsLinkIdDetail?: Maybe<Scalars['String']['output']>;
  tsLinkIdHeader?: Maybe<Scalars['String']['output']>;
  unitOfMeasure?: Maybe<Scalars['String']['output']>;
  unitOfMeasureUnits?: Maybe<Scalars['String']['output']>;
  updateDate?: Maybe<Scalars['DateTime']['output']>;
  updateUserId?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  yearMonth?: Maybe<Scalars['String']['output']>;
};

export type WideSalesReport = {
  __typename?: 'WideSalesReport';
  date?: Maybe<Scalars['String']['output']>;
  results?: Maybe<Array<WideSalesReportResult>>;
  tmp_index?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  totalAmounts?: Maybe<Array<WideSalesReportResult>>;
};

export type WideSalesReportResult = {
  __typename?: 'WideSalesReportResult';
  bookingCost?: Maybe<Scalars['Float']['output']>;
  buyGroupId?: Maybe<Scalars['Float']['output']>;
  buyGroupName?: Maybe<Scalars['String']['output']>;
  checkNumber?: Maybe<Scalars['String']['output']>;
  commissionRate?: Maybe<Scalars['Float']['output']>;
  commissionableTableId?: Maybe<Scalars['Float']['output']>;
  conversionRate?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerPartNumber?: Maybe<Scalars['String']['output']>;
  divisionId?: Maybe<Scalars['Float']['output']>;
  divisionName?: Maybe<Scalars['String']['output']>;
  externalAccountName?: Maybe<Scalars['String']['output']>;
  importBatchNumber?: Maybe<Scalars['Float']['output']>;
  importBatchRowId?: Maybe<Scalars['Float']['output']>;
  importProcessDate?: Maybe<Scalars['DateTime']['output']>;
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  manufacturerCategoryId?: Maybe<Scalars['Float']['output']>;
  manufacturerCategoryName?: Maybe<Scalars['String']['output']>;
  manufacturerCity?: Maybe<Scalars['String']['output']>;
  manufacturerClassId?: Maybe<Scalars['Float']['output']>;
  manufacturerClassName?: Maybe<Scalars['String']['output']>;
  manufacturerCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  manufacturerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  manufacturerId?: Maybe<Scalars['Float']['output']>;
  manufacturerIndustries?: Maybe<Array<IIndustry>>;
  manufacturerName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCategoryId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentCategoryName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCity?: Maybe<Scalars['String']['output']>;
  manufacturerParentClassId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentClassName?: Maybe<Scalars['String']['output']>;
  manufacturerParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  manufacturerParentId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentIndustries?: Maybe<Array<IIndustry>>;
  manufacturerParentName?: Maybe<Scalars['String']['output']>;
  manufacturerParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  manufacturerParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerParentRegionId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentRegionName?: Maybe<Scalars['String']['output']>;
  manufacturerParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerParentShortName?: Maybe<Scalars['String']['output']>;
  manufacturerParentState?: Maybe<Scalars['String']['output']>;
  manufacturerParentZip?: Maybe<Scalars['String']['output']>;
  manufacturerPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  manufacturerPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerRegionId?: Maybe<Scalars['Float']['output']>;
  manufacturerRegionName?: Maybe<Scalars['String']['output']>;
  manufacturerSalesTeamId?: Maybe<Scalars['Float']['output']>;
  manufacturerSalesTeamName?: Maybe<Scalars['String']['output']>;
  manufacturerShortName?: Maybe<Scalars['String']['output']>;
  manufacturerState?: Maybe<Scalars['String']['output']>;
  manufacturerZip?: Maybe<Scalars['String']['output']>;
  marketId?: Maybe<Scalars['Float']['output']>;
  marketName?: Maybe<Scalars['String']['output']>;
  month?: Maybe<Scalars['Float']['output']>;
  monthDaysRange?: Maybe<Scalars['String']['output']>;
  monthsRange?: Maybe<Scalars['String']['output']>;
  originalSalesTeamId?: Maybe<Scalars['Float']['output']>;
  originalSalesTeamName?: Maybe<Scalars['String']['output']>;
  partNumber?: Maybe<Scalars['String']['output']>;
  percentageDiff?: Maybe<Scalars['String']['output']>;
  poNumber?: Maybe<Scalars['String']['output']>;
  posDistributorCategoryId?: Maybe<Scalars['Float']['output']>;
  posDistributorCategoryName?: Maybe<Scalars['String']['output']>;
  posDistributorCity?: Maybe<Scalars['String']['output']>;
  posDistributorClassId?: Maybe<Scalars['Float']['output']>;
  posDistributorClassName?: Maybe<Scalars['String']['output']>;
  posDistributorCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  posDistributorCompanyTypeName?: Maybe<Scalars['Float']['output']>;
  posDistributorId?: Maybe<Scalars['Float']['output']>;
  posDistributorIndustries?: Maybe<Array<IIndustry>>;
  posDistributorName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCategoryId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentCategoryName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCity?: Maybe<Scalars['String']['output']>;
  posDistributorParentClassId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentClassName?: Maybe<Scalars['String']['output']>;
  posDistributorParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  posDistributorParentId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentIndustries?: Maybe<Array<IIndustry>>;
  posDistributorParentName?: Maybe<Scalars['String']['output']>;
  posDistributorParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  posDistributorParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorParentRegionId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentRegionName?: Maybe<Scalars['String']['output']>;
  posDistributorParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorParentShortName?: Maybe<Scalars['String']['output']>;
  posDistributorParentState?: Maybe<Scalars['String']['output']>;
  posDistributorParentZip?: Maybe<Scalars['String']['output']>;
  posDistributorPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  posDistributorPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorPrivateTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorRegionId?: Maybe<Scalars['Float']['output']>;
  posDistributorRegionName?: Maybe<Scalars['String']['output']>;
  posDistributorSalesTeamId?: Maybe<Scalars['Float']['output']>;
  posDistributorSalesTeamName?: Maybe<Scalars['String']['output']>;
  posDistributorShortName?: Maybe<Scalars['String']['output']>;
  posDistributorState?: Maybe<Scalars['String']['output']>;
  posDistributorZip?: Maybe<Scalars['String']['output']>;
  posSplitAmount?: Maybe<Scalars['Float']['output']>;
  previousYearMonthDaysRange?: Maybe<Scalars['String']['output']>;
  previousYearMonthsRange?: Maybe<Scalars['String']['output']>;
  previousYearRange?: Maybe<Scalars['String']['output']>;
  primaryCustomerCategoryId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerCategoryName?: Maybe<Scalars['String']['output']>;
  primaryCustomerCity?: Maybe<Scalars['String']['output']>;
  primaryCustomerClassId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerClassName?: Maybe<Scalars['String']['output']>;
  primaryCustomerCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  primaryCustomerId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerIndustries?: Maybe<Array<IIndustry>>;
  primaryCustomerName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCategoryId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentCategoryName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCity?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentClassId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentClassName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentIndustries?: Maybe<Array<IIndustry>>;
  primaryCustomerParentName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentRegionId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentRegionName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentShortName?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentState?: Maybe<Scalars['String']['output']>;
  primaryCustomerParentZip?: Maybe<Scalars['String']['output']>;
  primaryCustomerPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  primaryCustomerPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerRegionId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerRegionName?: Maybe<Scalars['String']['output']>;
  primaryCustomerSalesTeamId?: Maybe<Scalars['Float']['output']>;
  primaryCustomerSalesTeamName?: Maybe<Scalars['String']['output']>;
  primaryCustomerShortName?: Maybe<Scalars['String']['output']>;
  primaryCustomerState?: Maybe<Scalars['String']['output']>;
  primaryCustomerZip?: Maybe<Scalars['String']['output']>;
  privateTeamId?: Maybe<Scalars['Float']['output']>;
  privateTeamName?: Maybe<Scalars['String']['output']>;
  productFamilyId?: Maybe<Scalars['Float']['output']>;
  productFamilyName?: Maybe<Scalars['String']['output']>;
  productLine?: Maybe<Scalars['String']['output']>;
  purchaseOrderHeaderId?: Maybe<Scalars['Float']['output']>;
  purchaseOrderLineItemId?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  quarter?: Maybe<Scalars['String']['output']>;
  realCost?: Maybe<Scalars['Float']['output']>;
  recId?: Maybe<Scalars['Float']['output']>;
  salesAmount?: Maybe<Scalars['Float']['output']>;
  salesOrderNumber?: Maybe<Scalars['String']['output']>;
  salesTeamId?: Maybe<Scalars['Float']['output']>;
  salesTeamName?: Maybe<Scalars['String']['output']>;
  secondCustomerSales?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCategoryId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCategoryName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerCity?: Maybe<Scalars['String']['output']>;
  secondaryCustomerClassId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerClassName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerCompanyTypeName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerIndustries?: Maybe<Array<IIndustry>>;
  secondaryCustomerName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCategoryId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentCategoryName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCity?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentClassId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentClassName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentCompanyTypeId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentCompanyTypeName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentIndustries?: Maybe<Array<IIndustry>>;
  secondaryCustomerParentName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentPrivateTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentRegionId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentRegionName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentSalesTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerParentSalesTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentShortName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentState?: Maybe<Scalars['String']['output']>;
  secondaryCustomerParentZip?: Maybe<Scalars['String']['output']>;
  secondaryCustomerPrivateSalesTeam?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerPrivateTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerPrivateTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerRegionId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerRegionName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerSalesTeamId?: Maybe<Scalars['Float']['output']>;
  secondaryCustomerSalesTeamName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerShortName?: Maybe<Scalars['String']['output']>;
  secondaryCustomerState?: Maybe<Scalars['String']['output']>;
  secondaryCustomerZip?: Maybe<Scalars['String']['output']>;
  spreadsheetLoadId?: Maybe<Scalars['String']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
  yearMonth?: Maybe<Scalars['String']['output']>;
};

export type GridItem = {
  __typename?: 'gridItem';
  area: Scalars['String']['output'];
  caption: Scalars['String']['output'];
  dataField: Scalars['String']['output'];
  dataType: Scalars['String']['output'];
  format: Scalars['String']['output'];
  summaryType: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type GridItemInput = {
  area: Scalars['String']['input'];
  caption: Scalars['String']['input'];
  dataField: Scalars['String']['input'];
  dataType: Scalars['String']['input'];
  format: Scalars['String']['input'];
  summaryType: Scalars['String']['input'];
  width: Scalars['Int']['input'];
};

export type PhoneModel = {
  __typename?: 'phoneModel';
  ID?: Maybe<Scalars['Int']['output']>;
  PHONE?: Maybe<Scalars['String']['output']>;
  TYPE?: Maybe<Scalars['Int']['output']>;
};

export type PhoneModelEsInput = {
  ID?: InputMaybe<Scalars['Int']['input']>;
  PHONE?: InputMaybe<Scalars['String']['input']>;
  TYPE?: InputMaybe<Scalars['Int']['input']>;
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  AdditionalFieldDataTypeEnum: AdditionalFieldDataTypeEnum;
  AdditionalFieldFilterInput: AdditionalFieldFilterInput;
  AdditionalFieldModel: ResolverTypeWrapper<AdditionalFieldModel>;
  AdditionalFieldModuleEnum: AdditionalFieldModuleEnum;
  AdditionalFieldOrderByInput: AdditionalFieldOrderByInput;
  AdditionalFieldsCriteriaInput: AdditionalFieldsCriteriaInput;
  AdditionalFieldsResponse: ResolverTypeWrapper<AdditionalFieldsResponse>;
  AddressDBModel: ResolverTypeWrapper<AddressDbModel>;
  AddressFast: ResolverTypeWrapper<AddressFast>;
  AddressModel: ResolverTypeWrapper<AddressModel>;
  AddressModelESInput: AddressModelEsInput;
  AddressTypeCriteriaInput: AddressTypeCriteriaInput;
  AddressTypeElasticResponse: ResolverTypeWrapper<AddressTypeElasticResponse>;
  AddressTypeFilterInput: AddressTypeFilterInput;
  AddressTypeModel: ResolverTypeWrapper<AddressTypeModel>;
  AddressTypeOrderByInput: AddressTypeOrderByInput;
  AggregationsCriteriaInput: AggregationsCriteriaInput;
  AggregationsCriteriaOrderByInput: AggregationsCriteriaOrderByInput;
  AllFilters: AllFilters;
  AllFilters2: AllFilters2;
  AnalyticsWideSale: ResolverTypeWrapper<AnalyticsWideSale>;
  AssignRoleInput: AssignRoleInput;
  AttachmentTypeModel: ResolverTypeWrapper<AttachmentTypeModel>;
  AuroraSalesReport: ResolverTypeWrapper<AuroraSalesReport>;
  AuroraSalesReportResult: ResolverTypeWrapper<AuroraSalesReportResult>;
  BatchErrorModel: ResolverTypeWrapper<BatchErrorModel>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  BuyGroupModel: ResolverTypeWrapper<BuyGroupModel>;
  BuyingGroupCriteriaInput: BuyingGroupCriteriaInput;
  BuyingGroupFilterInput: BuyingGroupFilterInput;
  BuyingGroupOrderByInput: BuyingGroupOrderByInput;
  BuyingGroupResponse: ResolverTypeWrapper<BuyingGroupResponse>;
  CallPatternCriteriaInput: CallPatternCriteriaInput;
  CallPatternFilterInput: CallPatternFilterInput;
  CallPatternModel: ResolverTypeWrapper<CallPatternModel>;
  CallPatternOrderByInput: CallPatternOrderByInput;
  CallPatternResponse: ResolverTypeWrapper<CallPatternResponse>;
  CategoryCriteriaInput: CategoryCriteriaInput;
  CategoryFilterInput: CategoryFilterInput;
  CategoryOrderByInput: CategoryOrderByInput;
  CategoryResponse: ResolverTypeWrapper<CategoryResponse>;
  ChangeSalesTeamEnum: ChangeSalesTeamEnum;
  ChangeSalesTeamInput: ChangeSalesTeamInput;
  CloseReasonCriteriaInput: CloseReasonCriteriaInput;
  CloseReasonElasticResponse: ResolverTypeWrapper<CloseReasonElasticResponse>;
  CloseReasonFilterInput: CloseReasonFilterInput;
  CloseReasonOrderByInput: CloseReasonOrderByInput;
  CloseReasonOutputDto: ResolverTypeWrapper<CloseReasonOutputDto>;
  CloseStatusModel: ResolverTypeWrapper<CloseStatusModel>;
  ClosedReason: ResolverTypeWrapper<ClosedReason>;
  ClosedStatus: ResolverTypeWrapper<ClosedStatus>;
  CognitoConfirmResponseModel: ResolverTypeWrapper<CognitoConfirmResponseModel>;
  ColumnModel: ResolverTypeWrapper<ColumnModel>;
  CompanyAdditionalFieldModel: ResolverTypeWrapper<CompanyAdditionalFieldModel>;
  CompanyAddressModel: ResolverTypeWrapper<CompanyAddressModel>;
  CompanyAttachmentModel: ResolverTypeWrapper<CompanyAttachmentModel>;
  CompanyBTypeEnum: CompanyBTypeEnum;
  CompanyBaseType: ResolverTypeWrapper<CompanyBaseType>;
  CompanyBatchResultModel: ResolverTypeWrapper<CompanyBatchResultModel>;
  CompanyByTypeCriteriaInput: CompanyByTypeCriteriaInput;
  CompanyByTypeInput: CompanyByTypeInput;
  CompanyByTypeOrderInput: CompanyByTypeOrderInput;
  CompanyCategoryDBModel: ResolverTypeWrapper<CompanyCategoryDbModel>;
  CompanyCategoryESInput: CompanyCategoryEsInput;
  CompanyCategoryModel: ResolverTypeWrapper<CompanyCategoryModel>;
  CompanyClassDBModel: ResolverTypeWrapper<CompanyClassDbModel>;
  CompanyClassESInput: CompanyClassEsInput;
  CompanyClassModel: ResolverTypeWrapper<CompanyClassModel>;
  CompanyCriteriaInput: CompanyCriteriaInput;
  CompanyCriteriaInput2: CompanyCriteriaInput2;
  CompanyDivisionModel: ResolverTypeWrapper<CompanyDivisionModel>;
  CompanyDocNotesModel: ResolverTypeWrapper<CompanyDocNotesModel>;
  CompanyElasticModel: ResolverTypeWrapper<CompanyElasticModel>;
  CompanyFastModel: ResolverTypeWrapper<CompanyFastModel>;
  CompanyFields: CompanyFields;
  CompanyFilterInput: CompanyFilterInput;
  CompanyFilterInput2: CompanyFilterInput2;
  CompanyFindElasticInput: CompanyFindElasticInput;
  CompanyFindElasticInput2: CompanyFindElasticInput2;
  CompanyIndustryDBModel: ResolverTypeWrapper<CompanyIndustryDbModel>;
  CompanyIndustryResponse: ResolverTypeWrapper<CompanyIndustryResponse>;
  CompanyModel: ResolverTypeWrapper<CompanyModel>;
  CompanyModelElasticResponse: ResolverTypeWrapper<CompanyModelElasticResponse>;
  CompanyModelElasticResponse2: ResolverTypeWrapper<CompanyModelElasticResponse2>;
  CompanyOrderByInput: CompanyOrderByInput;
  CompanyOrderByInput2: CompanyOrderByInput2;
  CompanyPhoneModel: ResolverTypeWrapper<CompanyPhoneModel>;
  CompanyPitchesModel: ResolverTypeWrapper<CompanyPitchesModel>;
  CompanyProductPotentialModel: ResolverTypeWrapper<CompanyProductPotentialModel>;
  CompanyRankCriteriaInput: CompanyRankCriteriaInput;
  CompanyRankDBModel: ResolverTypeWrapper<CompanyRankDbModel>;
  CompanyRankFilterInput: CompanyRankFilterInput;
  CompanyRankResponse: ResolverTypeWrapper<CompanyRankResponse>;
  CompanySalesESTeamModel: ResolverTypeWrapper<CompanySalesEsTeamModel>;
  CompanySalesTeamESInput: CompanySalesTeamEsInput;
  CompanySalesTeamModel: ResolverTypeWrapper<CompanySalesTeamModel>;
  CompanySplitTypesCriteriaInput: CompanySplitTypesCriteriaInput;
  CompanySplitTypesModel: ResolverTypeWrapper<CompanySplitTypesModel>;
  CompanyTagsModel: ResolverTypeWrapper<CompanyTagsModel>;
  CompanyTierCriteriaInput: CompanyTierCriteriaInput;
  CompanyTierFilterInput: CompanyTierFilterInput;
  CompanyTierModel: ResolverTypeWrapper<CompanyTierModel>;
  CompanyTierOrderByInput: CompanyTierOrderByInput;
  CompanyTierResponse: ResolverTypeWrapper<CompanyTierResponse>;
  CompanyTypeAliasModel: ResolverTypeWrapper<CompanyTypeAliasModel>;
  CompanyTypeCriteriaInput: CompanyTypeCriteriaInput;
  CompanyTypeDBModel: ResolverTypeWrapper<CompanyTypeDbModel>;
  CompanyTypeESInput: CompanyTypeEsInput;
  CompanyTypeFilterInput: CompanyTypeFilterInput;
  CompanyTypeModel: ResolverTypeWrapper<CompanyTypeModel>;
  CompanyTypeModelElasticResponse: ResolverTypeWrapper<CompanyTypeModelElasticResponse>;
  CompanyTypeOrderByInput: CompanyTypeOrderByInput;
  ConfirmUserInput: ConfirmUserInput;
  ConstactsGridFieldInputEnum: ConstactsGridFieldInputEnum;
  ContactAdditionalFieldsContactModel: ResolverTypeWrapper<ContactAdditionalFieldsContactModel>;
  ContactBatchResponseModel: ResolverTypeWrapper<ContactBatchResponseModel>;
  ContactBatchResultModel: ResolverTypeWrapper<ContactBatchResultModel>;
  ContactBusinessInfoFastModel: ResolverTypeWrapper<ContactBusinessInfoFastModel>;
  ContactBusinessInfoModel: ResolverTypeWrapper<ContactBusinessInfoModel>;
  ContactCriteriaInput: ContactCriteriaInput;
  ContactCriteriaNestInput: ContactCriteriaNestInput;
  ContactFastGridResponseOutputDto: ResolverTypeWrapper<ContactFastGridResponseOutputDto>;
  ContactFastOutputDto: ResolverTypeWrapper<ContactFastOutputDto>;
  ContactFilterInput: ContactFilterInput;
  ContactGridExportFormatEnum: ContactGridExportFormatEnum;
  ContactGridExportNameEnum: ContactGridExportNameEnum;
  ContactGridResponseOutputDto: ResolverTypeWrapper<ContactGridResponseOutputDto>;
  ContactGroupContactModel: ResolverTypeWrapper<ContactGroupContactModel>;
  ContactGroupCriteriaInput: ContactGroupCriteriaInput;
  ContactGroupElasticResponse: ResolverTypeWrapper<ContactGroupElasticResponse>;
  ContactGroupFilterInput: ContactGroupFilterInput;
  ContactGroupModel: ResolverTypeWrapper<ContactGroupModel>;
  ContactGroupOrderByInput: ContactGroupOrderByInput;
  ContactNestFilterInput: ContactNestFilterInput;
  ContactOrderByInput: ContactOrderByInput;
  ContactOrderByNestInput: ContactOrderByNestInput;
  ContactOutputDto: ResolverTypeWrapper<ContactOutputDto>;
  ContactPersonalInfoModel: ResolverTypeWrapper<ContactPersonalInfoModel>;
  ContinentDBModel: ResolverTypeWrapper<ContinentDbModel>;
  CountryCriteriaInput: CountryCriteriaInput;
  CountryDBModel: ResolverTypeWrapper<CountryDbModel>;
  CountryElasticResponse: ResolverTypeWrapper<CountryElasticResponse>;
  CountryFilterInput: CountryFilterInput;
  CountryOrderByInput: CountryOrderByInput;
  CreateAdditionalFieldInput: CreateAdditionalFieldInput;
  CreateAddressInput: CreateAddressInput;
  CreateAddressTypeInput: CreateAddressTypeInput;
  CreateAnalyticsWideSaleInput: CreateAnalyticsWideSaleInput;
  CreateAppTokenInput: CreateAppTokenInput;
  CreateAttachmentTypeInput: CreateAttachmentTypeInput;
  CreateBuyGroupInput: CreateBuyGroupInput;
  CreateCallPatternInput: CreateCallPatternInput;
  CreateCloseReasonInput: CreateCloseReasonInput;
  CreateColumnInput: CreateColumnInput;
  CreateCompanyAdditionalFieldInput: CreateCompanyAdditionalFieldInput;
  CreateCompanyAttachmentInput: CreateCompanyAttachmentInput;
  CreateCompanyCategoryInput: CreateCompanyCategoryInput;
  CreateCompanyClassInput: CreateCompanyClassInput;
  CreateCompanyDivisionInput: CreateCompanyDivisionInput;
  CreateCompanyDocNotesInput: CreateCompanyDocNotesInput;
  CreateCompanyIndustryInput: CreateCompanyIndustryInput;
  CreateCompanyInput: CreateCompanyInput;
  CreateCompanyPitchInput: CreateCompanyPitchInput;
  CreateCompanyRankInput: CreateCompanyRankInput;
  CreateCompanySalesTeamInput: CreateCompanySalesTeamInput;
  CreateCompanySplitTypesInput: CreateCompanySplitTypesInput;
  CreateCompanyTagInput: CreateCompanyTagInput;
  CreateCompanyTierInput: CreateCompanyTierInput;
  CreateCompanyTypeAliasInput: CreateCompanyTypeAliasInput;
  CreateContactAdditionalFieldsContactInput: CreateContactAdditionalFieldsContactInput;
  CreateContactBusinessInfoInput: CreateContactBusinessInfoInput;
  CreateContactGroupContactInput: CreateContactGroupContactInput;
  CreateContactGroupInput: CreateContactGroupInput;
  CreateContactInput: CreateContactInput;
  CreateContactPersonalInfoInput: CreateContactPersonalInfoInput;
  CreateContinentsInput: CreateContinentsInput;
  CreateCountryInput: CreateCountryInput;
  CreateCustomerPriceCodesInput: CreateCustomerPriceCodesInput;
  CreateCustomerPriceGroupsInput: CreateCustomerPriceGroupsInput;
  CreateDepartmentInput: CreateDepartmentInput;
  CreateDivisionInput: CreateDivisionInput;
  CreateEmailInput: CreateEmailInput;
  CreateEmailServerInput: CreateEmailServerInput;
  CreateEmailTypeInput: CreateEmailTypeInput;
  CreateFailedReasonInput: CreateFailedReasonInput;
  CreateGridPreferenceInput: CreateGridPreferenceInput;
  CreateLeadSourceInput: CreateLeadSourceInput;
  CreateLineItemStatusInput: CreateLineItemStatusInput;
  CreateOpportunitiesInput: CreateOpportunitiesInput;
  CreatePhoneInput: CreatePhoneInput;
  CreatePhoneTypeInput: CreatePhoneTypeInput;
  CreatePrivateTeamInput: CreatePrivateTeamInput;
  CreatePrivateTeamTypeInput: CreatePrivateTeamTypeInput;
  CreateProductInterestInput: CreateProductInterestInput;
  CreateProductPotentialInput: CreateProductPotentialInput;
  CreateRegionInput: CreateRegionInput;
  CreateRelatedCategoryInput: CreateRelatedCategoryInput;
  CreateRelatedCompanyInput: CreateRelatedCompanyInput;
  CreateReportListInput: CreateReportListInput;
  CreateRoleInput: CreateRoleInput;
  CreateSalesTeamInput: CreateSalesTeamInput;
  CreateSalesTeamTypesInput: CreateSalesTeamTypesInput;
  CreateStageGroupInput: CreateStageGroupInput;
  CreateStageInput: CreateStageInput;
  CreateStateInput: CreateStateInput;
  CreateStatusInput: CreateStatusInput;
  CreateTenantInstanceInput: CreateTenantInstanceInput;
  CreateTerritoryInput: CreateTerritoryInput;
  CreateTypeInput: CreateTypeInput;
  CreateUserAdditionalFieldInput: CreateUserAdditionalFieldInput;
  CreateUserAdditionalFieldsUserInput: CreateUserAdditionalFieldsUserInput;
  CreateUserLandingWidget: CreateUserLandingWidget;
  CreateUserMenuInput: CreateUserMenuInput;
  CreateUserReportPreferenceInput: CreateUserReportPreferenceInput;
  CreateUserReportWidgetInput: CreateUserReportWidgetInput;
  CreateUserRoleInput: CreateUserRoleInput;
  CreateUserTypeInput: CreateUserTypeInput;
  CustomFieldDataStructureType: CustomFieldDataStructureType;
  CustomerPriceCodesModel: ResolverTypeWrapper<CustomerPriceCodesModel>;
  CustomerPriceGroupsModel: ResolverTypeWrapper<CustomerPriceGroupsModel>;
  DateRangeInput: DateRangeInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DeletedOkModel: ResolverTypeWrapper<DeletedOkModel>;
  DepartmentModel: ResolverTypeWrapper<DepartmentModel>;
  DivisionDBModel: ResolverTypeWrapper<DivisionDbModel>;
  DivisionsCompanyModel: ResolverTypeWrapper<DivisionsCompanyModel>;
  EmailModel: ResolverTypeWrapper<EmailModel>;
  EmailServerModel: ResolverTypeWrapper<EmailServerModel>;
  EmailTypeCriteriaInput: EmailTypeCriteriaInput;
  EmailTypeElasticResponse: ResolverTypeWrapper<EmailTypeElasticResponse>;
  EmailTypeFilterInput: EmailTypeFilterInput;
  EmailTypeModel: ResolverTypeWrapper<EmailTypeModel>;
  EmailTypeOrderByInput: EmailTypeOrderByInput;
  EmailUserModel: ResolverTypeWrapper<EmailUserModel>;
  ExportRequestResponseDTO: ResolverTypeWrapper<ExportRequestResponseDto>;
  ExportSearchInput: ExportSearchInput;
  FactoryTouchesReport: ResolverTypeWrapper<FactoryTouchesReport>;
  FactoryTouchesResults: ResolverTypeWrapper<FactoryTouchesResults>;
  FailedReasonCriteriaInput: FailedReasonCriteriaInput;
  FailedReasonElasticResponse: ResolverTypeWrapper<FailedReasonElasticResponse>;
  FailedReasonFilterInput: FailedReasonFilterInput;
  FailedReasonOrderByInput: FailedReasonOrderByInput;
  FailedReasonOutputDto: ResolverTypeWrapper<FailedReasonOutputDto>;
  FilterAdditionalFieldsEnum: FilterAdditionalFieldsEnum;
  FilterAddressTypeEnum: FilterAddressTypeEnum;
  FilterBuyGroupEnum: FilterBuyGroupEnum;
  FilterCallPatternEnum: FilterCallPatternEnum;
  FilterCategoryEnum: FilterCategoryEnum;
  FilterCloseReasonEnum: FilterCloseReasonEnum;
  FilterCompanyEnum: FilterCompanyEnum;
  FilterCompanyRankEnum: FilterCompanyRankEnum;
  FilterCompanySplitTypesEnum: FilterCompanySplitTypesEnum;
  FilterCompanyTierEnum: FilterCompanyTierEnum;
  FilterCompanyTypeEnum: FilterCompanyTypeEnum;
  FilterContactEnum: FilterContactEnum;
  FilterContactGroupEnum: FilterContactGroupEnum;
  FilterContactNestEnum: FilterContactNestEnum;
  FilterCountryEnum: FilterCountryEnum;
  FilterEmailTypeEnum: FilterEmailTypeEnum;
  FilterFailedReasonEnum: FilterFailedReasonEnum;
  FilterIndustryEnum: FilterIndustryEnum;
  FilterLeadSourceEnum: FilterLeadSourceEnum;
  FilterLineItemStatusEnum: FilterLineItemStatusEnum;
  FilterOpportunitiesEnum: FilterOpportunitiesEnum;
  FilterPhoneTypeEnum: FilterPhoneTypeEnum;
  FilterPrivateTeamEnum: FilterPrivateTeamEnum;
  FilterProductInterestEnum: FilterProductInterestEnum;
  FilterProductPotentialEnum: FilterProductPotentialEnum;
  FilterRegionEnum: FilterRegionEnum;
  FilterRelatedCategoryEnum: FilterRelatedCategoryEnum;
  FilterRelatedCompanyEnum: FilterRelatedCompanyEnum;
  FilterSalesTeamEnum: FilterSalesTeamEnum;
  FilterSalesTeamTypesEnum: FilterSalesTeamTypesEnum;
  FilterStageEnum: FilterStageEnum;
  FilterStageGroupEnum: FilterStageGroupEnum;
  FilterStatusEnum: FilterStatusEnum;
  FilterTerritoryEnum: FilterTerritoryEnum;
  FilterTypeEnum: FilterTypeEnum;
  FindAdditionalFieldsInput: FindAdditionalFieldsInput;
  FindAddressTypeInput: FindAddressTypeInput;
  FindBuyingGroupInput: FindBuyingGroupInput;
  FindCallPatternInput: FindCallPatternInput;
  FindCategoryInput: FindCategoryInput;
  FindCloseReasonInput: FindCloseReasonInput;
  FindCompanyRankInput: FindCompanyRankInput;
  FindCompanySplitTypesInput: FindCompanySplitTypesInput;
  FindCompanyTierInput: FindCompanyTierInput;
  FindCompanyTypeInput: FindCompanyTypeInput;
  FindContactGroupInput: FindContactGroupInput;
  FindContactInput: FindContactInput;
  FindContactNestInput: FindContactNestInput;
  FindCountryInput: FindCountryInput;
  FindEmailTypeInput: FindEmailTypeInput;
  FindFailedReasonInput: FindFailedReasonInput;
  FindIndustryInput: FindIndustryInput;
  FindLeadSourceInput: FindLeadSourceInput;
  FindLineItemStatusInput: FindLineItemStatusInput;
  FindOpportunitiesInput: FindOpportunitiesInput;
  FindPhoneTypeInput: FindPhoneTypeInput;
  FindPrivateTeamInput: FindPrivateTeamInput;
  FindProductInterestInput: FindProductInterestInput;
  FindProductPotentialInput: FindProductPotentialInput;
  FindRegionInput: FindRegionInput;
  FindRelatedCategoryInput: FindRelatedCategoryInput;
  FindRelatedCompanyInput: FindRelatedCompanyInput;
  FindReportListInput: FindReportListInput;
  FindRolesInput: FindRolesInput;
  FindSalesTeamInput: FindSalesTeamInput;
  FindSalesTeamTypesInput: FindSalesTeamTypesInput;
  FindStageGroupInput: FindStageGroupInput;
  FindStageInput: FindStageInput;
  FindStatusInput: FindStatusInput;
  FindTenantInstanceInput: FindTenantInstanceInput;
  FindTerritoryInput: FindTerritoryInput;
  FindTypeInput: FindTypeInput;
  FindUserLandingWidget: FindUserLandingWidget;
  FindUserReportPreferenceInput: FindUserReportPreferenceInput;
  FindUserReportWidgetInput: FindUserReportWidgetInput;
  FindUsersInput: FindUsersInput;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  GetAllSalesTeamMembersResponseModel: ResolverTypeWrapper<GetAllSalesTeamMembersResponseModel>;
  GetAllSalesTeamModel: ResolverTypeWrapper<GetAllSalesTeamModel>;
  GridPreferenceModel: ResolverTypeWrapper<GridPreferenceModel>;
  IIndustry: ResolverTypeWrapper<IIndustry>;
  IndustryCriteriaInput: IndustryCriteriaInput;
  IndustryFilterInput: IndustryFilterInput;
  IndustryOrderByInput: IndustryOrderByInput;
  InstanceSettings: ResolverTypeWrapper<InstanceSettings>;
  InstanceSettingsInput: InstanceSettingsInput;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Issuer: Issuer;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  JSONObject: ResolverTypeWrapper<Scalars['JSONObject']['output']>;
  LandingWidget: ResolverTypeWrapper<LandingWidget>;
  LandingWidgetInput: LandingWidgetInput;
  LeadSource: ResolverTypeWrapper<LeadSource>;
  LeadSourceCriteriaInput: LeadSourceCriteriaInput;
  LeadSourceElasticResponse: ResolverTypeWrapper<LeadSourceElasticResponse>;
  LeadSourceFilterInput: LeadSourceFilterInput;
  LeadSourceOrderByInput: LeadSourceOrderByInput;
  LeadSourceOutputDto: ResolverTypeWrapper<LeadSourceOutputDto>;
  LineItemStatusCriteriaInput: LineItemStatusCriteriaInput;
  LineItemStatusElasticResponse: ResolverTypeWrapper<LineItemStatusElasticResponse>;
  LineItemStatusFilterInput: LineItemStatusFilterInput;
  LineItemStatusOrderByInput: LineItemStatusOrderByInput;
  LineItemStatusOutputDto: ResolverTypeWrapper<LineItemStatusOutputDto>;
  LocationModel: ResolverTypeWrapper<LocationModel>;
  LocationPoint: LocationPoint;
  MemberModel: ResolverTypeWrapper<MemberModel>;
  MenuReport: ResolverTypeWrapper<MenuReport>;
  MergeCompanyInput: MergeCompanyInput;
  MonthlySalesReport: ResolverTypeWrapper<MonthlySalesReport>;
  MonthlySalesReportResult: ResolverTypeWrapper<MonthlySalesReportResult>;
  Mutation: ResolverTypeWrapper<{}>;
  OppOwner: ResolverTypeWrapper<OppOwner>;
  OppStage: ResolverTypeWrapper<OppStage>;
  OppStatus: ResolverTypeWrapper<OppStatus>;
  OpportunitiesCompanyOutputDto: ResolverTypeWrapper<OpportunitiesCompanyOutputDto>;
  OpportunitiesContactOutputDto: ResolverTypeWrapper<OpportunitiesContactOutputDto>;
  OpportunitiesCriteriaInput: OpportunitiesCriteriaInput;
  OpportunitiesDto: ResolverTypeWrapper<OpportunitiesDto>;
  OpportunitiesElasticResponse: ResolverTypeWrapper<OpportunitiesElasticResponse>;
  OpportunitiesFilterInput: OpportunitiesFilterInput;
  OpportunitiesOrderByInput: OpportunitiesOrderByInput;
  OpportunitiesOutputDto: ResolverTypeWrapper<OpportunitiesOutputDto>;
  OptionStructureType: OptionStructureType;
  Options: ResolverTypeWrapper<Options>;
  OrderAdditionalFieldEnum: OrderAdditionalFieldEnum;
  OrderAddressTypeEnum: OrderAddressTypeEnum;
  OrderBuyGroupEnum: OrderBuyGroupEnum;
  OrderCallPatternEnum: OrderCallPatternEnum;
  OrderCategoryEnum: OrderCategoryEnum;
  OrderCloseReasonEnum: OrderCloseReasonEnum;
  OrderCompanyEnum2: OrderCompanyEnum2;
  OrderCompanyRankEnum: OrderCompanyRankEnum;
  OrderCompanySplitTypesEnum: OrderCompanySplitTypesEnum;
  OrderCompanyTierEnum: OrderCompanyTierEnum;
  OrderCompanyTypeEnum: OrderCompanyTypeEnum;
  OrderContactGroupEnum: OrderContactGroupEnum;
  OrderContactNestEnum: OrderContactNestEnum;
  OrderCountryEnum: OrderCountryEnum;
  OrderEmailTypeEnum: OrderEmailTypeEnum;
  OrderFailedReasonEnum: OrderFailedReasonEnum;
  OrderIndustryEnum: OrderIndustryEnum;
  OrderLeadSourceEnum: OrderLeadSourceEnum;
  OrderLineItemStatusEnum: OrderLineItemStatusEnum;
  OrderOpportunitiesEnum: OrderOpportunitiesEnum;
  OrderPhoneTypeEnum: OrderPhoneTypeEnum;
  OrderPrivateTeamEnum: OrderPrivateTeamEnum;
  OrderProductInterestEnum: OrderProductInterestEnum;
  OrderProductPotentialEnum: OrderProductPotentialEnum;
  OrderRegionEnum: OrderRegionEnum;
  OrderRelatedCompanyEnum: OrderRelatedCompanyEnum;
  OrderSalesTeamEnum: OrderSalesTeamEnum;
  OrderSalesTeamTypesEnum: OrderSalesTeamTypesEnum;
  OrderStageEnum: OrderStageEnum;
  OrderStageGroupEnum: OrderStageGroupEnum;
  OrderStatusEnum: OrderStatusEnum;
  OrderTerritoryEnum: OrderTerritoryEnum;
  OrderTypeEnum: OrderTypeEnum;
  OrderTypes: OrderTypes;
  PaginationInput: PaginationInput;
  PaginationInput2: PaginationInput2;
  PhoneDBModel: ResolverTypeWrapper<PhoneDbModel>;
  PhoneFast: ResolverTypeWrapper<PhoneFast>;
  PhoneTypeCriteriaInput: PhoneTypeCriteriaInput;
  PhoneTypeElasticResponse: ResolverTypeWrapper<PhoneTypeElasticResponse>;
  PhoneTypeFilterInput: PhoneTypeFilterInput;
  PhoneTypeModel: ResolverTypeWrapper<PhoneTypeModel>;
  PhoneTypeOrderByInput: PhoneTypeOrderByInput;
  PrimaryCustomerWideSales: ResolverTypeWrapper<PrimaryCustomerWideSales>;
  PrivateTeamCriteriaInput: PrivateTeamCriteriaInput;
  PrivateTeamFilterInput: PrivateTeamFilterInput;
  PrivateTeamModel: ResolverTypeWrapper<PrivateTeamModel>;
  PrivateTeamOrderByInput: PrivateTeamOrderByInput;
  PrivateTeamResponse: ResolverTypeWrapper<PrivateTeamResponse>;
  PrivateTeamTypeModel: ResolverTypeWrapper<PrivateTeamTypeModel>;
  ProductInterestCriteriaInput: ProductInterestCriteriaInput;
  ProductInterestElasticResponse: ResolverTypeWrapper<ProductInterestElasticResponse>;
  ProductInterestFilterInput: ProductInterestFilterInput;
  ProductInterestModel: ResolverTypeWrapper<ProductInterestModel>;
  ProductInterestOrderByInput: ProductInterestOrderByInput;
  ProductLine: ResolverTypeWrapper<ProductLine>;
  ProductPotentialCriteriaInput: ProductPotentialCriteriaInput;
  ProductPotentialFilterInput: ProductPotentialFilterInput;
  ProductPotentialOrderByInput: ProductPotentialOrderByInput;
  ProductPotentialResponse: ResolverTypeWrapper<ProductPotentialResponse>;
  Query: ResolverTypeWrapper<{}>;
  RankOrderByInput: RankOrderByInput;
  RegionCriteriaInput: RegionCriteriaInput;
  RegionElasticResponse: ResolverTypeWrapper<RegionElasticResponse>;
  RegionFilterInput: RegionFilterInput;
  RegionModel: ResolverTypeWrapper<RegionModel>;
  RegionOrderByInput: RegionOrderByInput;
  RegionWideSales: ResolverTypeWrapper<RegionWideSales>;
  RegisterSalesTeamUserInput: RegisterSalesTeamUserInput;
  RegisterUserInput: RegisterUserInput;
  RegisterUserSalesTeamModel: ResolverTypeWrapper<RegisterUserSalesTeamModel>;
  RelatedCategoryCriteriaInput: RelatedCategoryCriteriaInput;
  RelatedCategoryElasticResponse: ResolverTypeWrapper<RelatedCategoryElasticResponse>;
  RelatedCategoryEnum: RelatedCategoryEnum;
  RelatedCategoryFilterInput: RelatedCategoryFilterInput;
  RelatedCategoryModel: ResolverTypeWrapper<RelatedCategoryModel>;
  RelatedCategoryOrderByInput: RelatedCategoryOrderByInput;
  RelatedCompanyCriteriaInput: RelatedCompanyCriteriaInput;
  RelatedCompanyDBModel: ResolverTypeWrapper<RelatedCompanyDbModel>;
  RelatedCompanyElasticResponse: ResolverTypeWrapper<RelatedCompanyElasticResponse>;
  RelatedCompanyFilterInput: RelatedCompanyFilterInput;
  RelatedCompanyOrderByInput: RelatedCompanyOrderByInput;
  ReportListModel: ResolverTypeWrapper<ReportListModel>;
  ReportResponseDTO: ResolverTypeWrapper<ReportResponseDto>;
  Role: ResolverTypeWrapper<Role>;
  RoleBasicInformation: ResolverTypeWrapper<RoleBasicInformation>;
  RoleModel: ResolverTypeWrapper<RoleModel>;
  SalesByMonth: ResolverTypeWrapper<SalesByMonth>;
  SalesTeam: ResolverTypeWrapper<SalesTeam>;
  SalesTeamCriteriaInput: SalesTeamCriteriaInput;
  SalesTeamFilterInput: SalesTeamFilterInput;
  SalesTeamMemberModel: ResolverTypeWrapper<SalesTeamMemberModel>;
  SalesTeamModel: ResolverTypeWrapper<SalesTeamModel>;
  SalesTeamOrderByInput: SalesTeamOrderByInput;
  SalesTeamResponse: ResolverTypeWrapper<SalesTeamResponse>;
  SalesTeamTypeResponse: ResolverTypeWrapper<SalesTeamTypeResponse>;
  SalesTeamTypesCriteriaInput: SalesTeamTypesCriteriaInput;
  SalesTeamTypesFilterInput: SalesTeamTypesFilterInput;
  SalesTeamTypesModel: ResolverTypeWrapper<SalesTeamTypesModel>;
  SalesTeamTypesOrderByInput: SalesTeamTypesOrderByInput;
  Section: ResolverTypeWrapper<Section>;
  SecurityTokenModel: ResolverTypeWrapper<SecurityTokenModel>;
  SomeColumnModel: ResolverTypeWrapper<SomeColumnModel>;
  SplitTypeResponse: ResolverTypeWrapper<SplitTypeResponse>;
  SplitTypesFilterInput: SplitTypesFilterInput;
  SplitTypesOrderByInput: SplitTypesOrderByInput;
  StageCriteriaInput: StageCriteriaInput;
  StageElasticResponse: ResolverTypeWrapper<StageElasticResponse>;
  StageFilterInput: StageFilterInput;
  StageGroupCriteriaInput: StageGroupCriteriaInput;
  StageGroupElasticResponse: ResolverTypeWrapper<StageGroupElasticResponse>;
  StageGroupFilterInput: StageGroupFilterInput;
  StageGroupModel: ResolverTypeWrapper<StageGroupModel>;
  StageGroupOrderByInput: StageGroupOrderByInput;
  StageGroupOutputDto: ResolverTypeWrapper<StageGroupOutputDto>;
  StageOrderByInput: StageOrderByInput;
  StageOutputDto: ResolverTypeWrapper<StageOutputDto>;
  State: ResolverTypeWrapper<State>;
  StateDBModel: ResolverTypeWrapper<StateDbModel>;
  StatusCriteriaInput: StatusCriteriaInput;
  StatusElasticResponse: ResolverTypeWrapper<StatusElasticResponse>;
  StatusFilterInput: StatusFilterInput;
  StatusModel: ResolverTypeWrapper<StatusModel>;
  StatusOrderByInput: StatusOrderByInput;
  StatusOutputDto: ResolverTypeWrapper<StatusOutputDto>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  StringFilters2: StringFilters2;
  SubTableByCompanyElasticResponse: ResolverTypeWrapper<SubTableByCompanyElasticResponse>;
  SubTableStageByCompanyElasticResponse: ResolverTypeWrapper<SubTableStageByCompanyElasticResponse>;
  Subscription: ResolverTypeWrapper<{}>;
  TeamWideSales: ResolverTypeWrapper<TeamWideSales>;
  TenantInstance: ResolverTypeWrapper<TenantInstance>;
  TerritoryCriteriaInput: TerritoryCriteriaInput;
  TerritoryDBModel: ResolverTypeWrapper<TerritoryDbModel>;
  TerritoryElasticResponse: ResolverTypeWrapper<TerritoryElasticResponse>;
  TerritoryFilterInput: TerritoryFilterInput;
  TerritoryOrderByInput: TerritoryOrderByInput;
  Type: ResolverTypeWrapper<Type>;
  TypeCriteriaInput: TypeCriteriaInput;
  TypeElasticResponse: ResolverTypeWrapper<TypeElasticResponse>;
  TypeFilterInput: TypeFilterInput;
  TypeOrderByInput: TypeOrderByInput;
  TypeOutputDto: ResolverTypeWrapper<TypeOutputDto>;
  UpdateAdditionalFieldInput: UpdateAdditionalFieldInput;
  UpdateAddressInlineInput: UpdateAddressInlineInput;
  UpdateAddressInput: UpdateAddressInput;
  UpdateAddressTypeInput: UpdateAddressTypeInput;
  UpdateAnUserInput: UpdateAnUserInput;
  UpdateAnalyticsWideSaleInput: UpdateAnalyticsWideSaleInput;
  UpdateAttachmentTypeInput: UpdateAttachmentTypeInput;
  UpdateBuyGroupInput: UpdateBuyGroupInput;
  UpdateCallPatternInput: UpdateCallPatternInput;
  UpdateCloseReasonInput: UpdateCloseReasonInput;
  UpdateColumnInput: UpdateColumnInput;
  UpdateCompanyAdditionalFieldInput: UpdateCompanyAdditionalFieldInput;
  UpdateCompanyAttachmentInput: UpdateCompanyAttachmentInput;
  UpdateCompanyBatchInput: UpdateCompanyBatchInput;
  UpdateCompanyBatchModel: ResolverTypeWrapper<UpdateCompanyBatchModel>;
  UpdateCompanyBatchResponseModel: ResolverTypeWrapper<UpdateCompanyBatchResponseModel>;
  UpdateCompanyCategoryInput: UpdateCompanyCategoryInput;
  UpdateCompanyClassInput: UpdateCompanyClassInput;
  UpdateCompanyDivisionInput: UpdateCompanyDivisionInput;
  UpdateCompanyDocNotesInput: UpdateCompanyDocNotesInput;
  UpdateCompanyElasticInput: UpdateCompanyElasticInput;
  UpdateCompanyElasticInputList: UpdateCompanyElasticInputList;
  UpdateCompanyElasticInputList2: UpdateCompanyElasticInputList2;
  UpdateCompanyIndustryInput: UpdateCompanyIndustryInput;
  UpdateCompanyInlineInput: UpdateCompanyInlineInput;
  UpdateCompanyInput: UpdateCompanyInput;
  UpdateCompanyPitchInput: UpdateCompanyPitchInput;
  UpdateCompanyRankInput: UpdateCompanyRankInput;
  UpdateCompanySalesTeamInput: UpdateCompanySalesTeamInput;
  UpdateCompanySplitTypesInput: UpdateCompanySplitTypesInput;
  UpdateCompanyTagInput: UpdateCompanyTagInput;
  UpdateCompanyTierInput: UpdateCompanyTierInput;
  UpdateCompanyTypeAliasInput: UpdateCompanyTypeAliasInput;
  UpdateContactAdditionalFieldsContactInput: UpdateContactAdditionalFieldsContactInput;
  UpdateContactBatchInput: UpdateContactBatchInput;
  UpdateContactBatchModel: ResolverTypeWrapper<UpdateContactBatchModel>;
  UpdateContactBusinessInfoInput: UpdateContactBusinessInfoInput;
  UpdateContactGroupContactInput: UpdateContactGroupContactInput;
  UpdateContactGroupInput: UpdateContactGroupInput;
  UpdateContactInput: UpdateContactInput;
  UpdateContactPersonalInfoInput: UpdateContactPersonalInfoInput;
  UpdateContinentsInput: UpdateContinentsInput;
  UpdateCountryInput: UpdateCountryInput;
  UpdateCustomerPriceCodesInput: UpdateCustomerPriceCodesInput;
  UpdateCustomerPriceGroupsInput: UpdateCustomerPriceGroupsInput;
  UpdateDepartmentInput: UpdateDepartmentInput;
  UpdateDivisionInput: UpdateDivisionInput;
  UpdateEmailInput: UpdateEmailInput;
  UpdateEmailServerInput: UpdateEmailServerInput;
  UpdateEmailTypeInput: UpdateEmailTypeInput;
  UpdateFailedReasonInput: UpdateFailedReasonInput;
  UpdateGridPreferenceInput: UpdateGridPreferenceInput;
  UpdateLeadSourceInput: UpdateLeadSourceInput;
  UpdateLineItemStatusInput: UpdateLineItemStatusInput;
  UpdateOpportunitiesInput: UpdateOpportunitiesInput;
  UpdatePhoneInlineInput: UpdatePhoneInlineInput;
  UpdatePhoneInput: UpdatePhoneInput;
  UpdatePhoneTypeInput: UpdatePhoneTypeInput;
  UpdatePrivateTeamInput: UpdatePrivateTeamInput;
  UpdatePrivateTeamTypeInput: UpdatePrivateTeamTypeInput;
  UpdateProductInterestInput: UpdateProductInterestInput;
  UpdateProductPotentialInput: UpdateProductPotentialInput;
  UpdateRegionInput: UpdateRegionInput;
  UpdateRelatedCategoryInput: UpdateRelatedCategoryInput;
  UpdateRelatedCompanyInput: UpdateRelatedCompanyInput;
  UpdateReportListInput: UpdateReportListInput;
  UpdateRoleInput: UpdateRoleInput;
  UpdateSalesTeamInput: UpdateSalesTeamInput;
  UpdateSalesTeamTypesInput: UpdateSalesTeamTypesInput;
  UpdateStageGroupInput: UpdateStageGroupInput;
  UpdateStageInput: UpdateStageInput;
  UpdateStateInput: UpdateStateInput;
  UpdateStatusInput: UpdateStatusInput;
  UpdateTenantInstanceInput: UpdateTenantInstanceInput;
  UpdateTerritoryInput: UpdateTerritoryInput;
  UpdateTypeInput: UpdateTypeInput;
  UpdateUserAdditionalFieldInput: UpdateUserAdditionalFieldInput;
  UpdateUserAdditionalFieldsUserInput: UpdateUserAdditionalFieldsUserInput;
  UpdateUserLandingWidget: UpdateUserLandingWidget;
  UpdateUserMenuInput: UpdateUserMenuInput;
  UpdateUserReportPreferenceInput: UpdateUserReportPreferenceInput;
  UpdateUserReportWidgetInput: UpdateUserReportWidgetInput;
  UpdateUserRoleInput: UpdateUserRoleInput;
  UpdateUserTypeInput: UpdateUserTypeInput;
  Upload: ResolverTypeWrapper<Scalars['Upload']['output']>;
  User: ResolverTypeWrapper<User>;
  UserAdditionalFieldModel: ResolverTypeWrapper<UserAdditionalFieldModel>;
  UserAdditionalFieldsUserModel: ResolverTypeWrapper<UserAdditionalFieldsUserModel>;
  UserDBModel: ResolverTypeWrapper<UserDbModel>;
  UserESInput: UserEsInput;
  UserEmailModel: ResolverTypeWrapper<UserEmailModel>;
  UserLandingWidget: ResolverTypeWrapper<UserLandingWidget>;
  UserMenuModel: ResolverTypeWrapper<UserMenuModel>;
  UserModel: ResolverTypeWrapper<UserModel>;
  UserRegisterInput: UserRegisterInput;
  UserReportPreference: ResolverTypeWrapper<UserReportPreference>;
  UserReportWidgetList: ResolverTypeWrapper<UserReportWidgetList>;
  UserRoleModel: ResolverTypeWrapper<UserRoleModel>;
  UserTypeModel: ResolverTypeWrapper<UserTypeModel>;
  ValueType: ValueType;
  WideAJReport: ResolverTypeWrapper<WideAjReport>;
  WideAJReportResult: ResolverTypeWrapper<WideAjReportResult>;
  WideCommissionReport: ResolverTypeWrapper<WideCommissionReport>;
  WideCommissionSalesReport: ResolverTypeWrapper<WideCommissionSalesReport>;
  WideCommissionsReportResult: ResolverTypeWrapper<WideCommissionsReportResult>;
  WideCommissionsSalesReportResult: ResolverTypeWrapper<WideCommissionsSalesReportResult>;
  WidePOReport: ResolverTypeWrapper<WidePoReport>;
  WidePOReportResult: ResolverTypeWrapper<WidePoReportResult>;
  WideQuotesReport: ResolverTypeWrapper<WideQuotesReport>;
  WideQuotesReportResult: ResolverTypeWrapper<WideQuotesReportResult>;
  WideSalesReport: ResolverTypeWrapper<WideSalesReport>;
  WideSalesReportResult: ResolverTypeWrapper<WideSalesReportResult>;
  gridItem: ResolverTypeWrapper<GridItem>;
  gridItemInput: GridItemInput;
  phoneModel: ResolverTypeWrapper<PhoneModel>;
  phoneModelESInput: PhoneModelEsInput;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  AdditionalFieldFilterInput: AdditionalFieldFilterInput;
  AdditionalFieldModel: AdditionalFieldModel;
  AdditionalFieldOrderByInput: AdditionalFieldOrderByInput;
  AdditionalFieldsCriteriaInput: AdditionalFieldsCriteriaInput;
  AdditionalFieldsResponse: AdditionalFieldsResponse;
  AddressDBModel: AddressDbModel;
  AddressFast: AddressFast;
  AddressModel: AddressModel;
  AddressModelESInput: AddressModelEsInput;
  AddressTypeCriteriaInput: AddressTypeCriteriaInput;
  AddressTypeElasticResponse: AddressTypeElasticResponse;
  AddressTypeFilterInput: AddressTypeFilterInput;
  AddressTypeModel: AddressTypeModel;
  AddressTypeOrderByInput: AddressTypeOrderByInput;
  AggregationsCriteriaInput: AggregationsCriteriaInput;
  AggregationsCriteriaOrderByInput: AggregationsCriteriaOrderByInput;
  AnalyticsWideSale: AnalyticsWideSale;
  AssignRoleInput: AssignRoleInput;
  AttachmentTypeModel: AttachmentTypeModel;
  AuroraSalesReport: AuroraSalesReport;
  AuroraSalesReportResult: AuroraSalesReportResult;
  BatchErrorModel: BatchErrorModel;
  Boolean: Scalars['Boolean']['output'];
  BuyGroupModel: BuyGroupModel;
  BuyingGroupCriteriaInput: BuyingGroupCriteriaInput;
  BuyingGroupFilterInput: BuyingGroupFilterInput;
  BuyingGroupOrderByInput: BuyingGroupOrderByInput;
  BuyingGroupResponse: BuyingGroupResponse;
  CallPatternCriteriaInput: CallPatternCriteriaInput;
  CallPatternFilterInput: CallPatternFilterInput;
  CallPatternModel: CallPatternModel;
  CallPatternOrderByInput: CallPatternOrderByInput;
  CallPatternResponse: CallPatternResponse;
  CategoryCriteriaInput: CategoryCriteriaInput;
  CategoryFilterInput: CategoryFilterInput;
  CategoryOrderByInput: CategoryOrderByInput;
  CategoryResponse: CategoryResponse;
  ChangeSalesTeamInput: ChangeSalesTeamInput;
  CloseReasonCriteriaInput: CloseReasonCriteriaInput;
  CloseReasonElasticResponse: CloseReasonElasticResponse;
  CloseReasonFilterInput: CloseReasonFilterInput;
  CloseReasonOrderByInput: CloseReasonOrderByInput;
  CloseReasonOutputDto: CloseReasonOutputDto;
  CloseStatusModel: CloseStatusModel;
  ClosedReason: ClosedReason;
  ClosedStatus: ClosedStatus;
  CognitoConfirmResponseModel: CognitoConfirmResponseModel;
  ColumnModel: ColumnModel;
  CompanyAdditionalFieldModel: CompanyAdditionalFieldModel;
  CompanyAddressModel: CompanyAddressModel;
  CompanyAttachmentModel: CompanyAttachmentModel;
  CompanyBaseType: CompanyBaseType;
  CompanyBatchResultModel: CompanyBatchResultModel;
  CompanyByTypeCriteriaInput: CompanyByTypeCriteriaInput;
  CompanyByTypeInput: CompanyByTypeInput;
  CompanyByTypeOrderInput: CompanyByTypeOrderInput;
  CompanyCategoryDBModel: CompanyCategoryDbModel;
  CompanyCategoryESInput: CompanyCategoryEsInput;
  CompanyCategoryModel: CompanyCategoryModel;
  CompanyClassDBModel: CompanyClassDbModel;
  CompanyClassESInput: CompanyClassEsInput;
  CompanyClassModel: CompanyClassModel;
  CompanyCriteriaInput: CompanyCriteriaInput;
  CompanyCriteriaInput2: CompanyCriteriaInput2;
  CompanyDivisionModel: CompanyDivisionModel;
  CompanyDocNotesModel: CompanyDocNotesModel;
  CompanyElasticModel: CompanyElasticModel;
  CompanyFastModel: CompanyFastModel;
  CompanyFilterInput: CompanyFilterInput;
  CompanyFilterInput2: CompanyFilterInput2;
  CompanyFindElasticInput: CompanyFindElasticInput;
  CompanyFindElasticInput2: CompanyFindElasticInput2;
  CompanyIndustryDBModel: CompanyIndustryDbModel;
  CompanyIndustryResponse: CompanyIndustryResponse;
  CompanyModel: CompanyModel;
  CompanyModelElasticResponse: CompanyModelElasticResponse;
  CompanyModelElasticResponse2: CompanyModelElasticResponse2;
  CompanyOrderByInput: CompanyOrderByInput;
  CompanyOrderByInput2: CompanyOrderByInput2;
  CompanyPhoneModel: CompanyPhoneModel;
  CompanyPitchesModel: CompanyPitchesModel;
  CompanyProductPotentialModel: CompanyProductPotentialModel;
  CompanyRankCriteriaInput: CompanyRankCriteriaInput;
  CompanyRankDBModel: CompanyRankDbModel;
  CompanyRankFilterInput: CompanyRankFilterInput;
  CompanyRankResponse: CompanyRankResponse;
  CompanySalesESTeamModel: CompanySalesEsTeamModel;
  CompanySalesTeamESInput: CompanySalesTeamEsInput;
  CompanySalesTeamModel: CompanySalesTeamModel;
  CompanySplitTypesCriteriaInput: CompanySplitTypesCriteriaInput;
  CompanySplitTypesModel: CompanySplitTypesModel;
  CompanyTagsModel: CompanyTagsModel;
  CompanyTierCriteriaInput: CompanyTierCriteriaInput;
  CompanyTierFilterInput: CompanyTierFilterInput;
  CompanyTierModel: CompanyTierModel;
  CompanyTierOrderByInput: CompanyTierOrderByInput;
  CompanyTierResponse: CompanyTierResponse;
  CompanyTypeAliasModel: CompanyTypeAliasModel;
  CompanyTypeCriteriaInput: CompanyTypeCriteriaInput;
  CompanyTypeDBModel: CompanyTypeDbModel;
  CompanyTypeESInput: CompanyTypeEsInput;
  CompanyTypeFilterInput: CompanyTypeFilterInput;
  CompanyTypeModel: CompanyTypeModel;
  CompanyTypeModelElasticResponse: CompanyTypeModelElasticResponse;
  CompanyTypeOrderByInput: CompanyTypeOrderByInput;
  ConfirmUserInput: ConfirmUserInput;
  ContactAdditionalFieldsContactModel: ContactAdditionalFieldsContactModel;
  ContactBatchResponseModel: ContactBatchResponseModel;
  ContactBatchResultModel: ContactBatchResultModel;
  ContactBusinessInfoFastModel: ContactBusinessInfoFastModel;
  ContactBusinessInfoModel: ContactBusinessInfoModel;
  ContactCriteriaInput: ContactCriteriaInput;
  ContactCriteriaNestInput: ContactCriteriaNestInput;
  ContactFastGridResponseOutputDto: ContactFastGridResponseOutputDto;
  ContactFastOutputDto: ContactFastOutputDto;
  ContactFilterInput: ContactFilterInput;
  ContactGridResponseOutputDto: ContactGridResponseOutputDto;
  ContactGroupContactModel: ContactGroupContactModel;
  ContactGroupCriteriaInput: ContactGroupCriteriaInput;
  ContactGroupElasticResponse: ContactGroupElasticResponse;
  ContactGroupFilterInput: ContactGroupFilterInput;
  ContactGroupModel: ContactGroupModel;
  ContactGroupOrderByInput: ContactGroupOrderByInput;
  ContactNestFilterInput: ContactNestFilterInput;
  ContactOrderByInput: ContactOrderByInput;
  ContactOrderByNestInput: ContactOrderByNestInput;
  ContactOutputDto: ContactOutputDto;
  ContactPersonalInfoModel: ContactPersonalInfoModel;
  ContinentDBModel: ContinentDbModel;
  CountryCriteriaInput: CountryCriteriaInput;
  CountryDBModel: CountryDbModel;
  CountryElasticResponse: CountryElasticResponse;
  CountryFilterInput: CountryFilterInput;
  CountryOrderByInput: CountryOrderByInput;
  CreateAdditionalFieldInput: CreateAdditionalFieldInput;
  CreateAddressInput: CreateAddressInput;
  CreateAddressTypeInput: CreateAddressTypeInput;
  CreateAnalyticsWideSaleInput: CreateAnalyticsWideSaleInput;
  CreateAppTokenInput: CreateAppTokenInput;
  CreateAttachmentTypeInput: CreateAttachmentTypeInput;
  CreateBuyGroupInput: CreateBuyGroupInput;
  CreateCallPatternInput: CreateCallPatternInput;
  CreateCloseReasonInput: CreateCloseReasonInput;
  CreateColumnInput: CreateColumnInput;
  CreateCompanyAdditionalFieldInput: CreateCompanyAdditionalFieldInput;
  CreateCompanyAttachmentInput: CreateCompanyAttachmentInput;
  CreateCompanyCategoryInput: CreateCompanyCategoryInput;
  CreateCompanyClassInput: CreateCompanyClassInput;
  CreateCompanyDivisionInput: CreateCompanyDivisionInput;
  CreateCompanyDocNotesInput: CreateCompanyDocNotesInput;
  CreateCompanyIndustryInput: CreateCompanyIndustryInput;
  CreateCompanyInput: CreateCompanyInput;
  CreateCompanyPitchInput: CreateCompanyPitchInput;
  CreateCompanyRankInput: CreateCompanyRankInput;
  CreateCompanySalesTeamInput: CreateCompanySalesTeamInput;
  CreateCompanySplitTypesInput: CreateCompanySplitTypesInput;
  CreateCompanyTagInput: CreateCompanyTagInput;
  CreateCompanyTierInput: CreateCompanyTierInput;
  CreateCompanyTypeAliasInput: CreateCompanyTypeAliasInput;
  CreateContactAdditionalFieldsContactInput: CreateContactAdditionalFieldsContactInput;
  CreateContactBusinessInfoInput: CreateContactBusinessInfoInput;
  CreateContactGroupContactInput: CreateContactGroupContactInput;
  CreateContactGroupInput: CreateContactGroupInput;
  CreateContactInput: CreateContactInput;
  CreateContactPersonalInfoInput: CreateContactPersonalInfoInput;
  CreateContinentsInput: CreateContinentsInput;
  CreateCountryInput: CreateCountryInput;
  CreateCustomerPriceCodesInput: CreateCustomerPriceCodesInput;
  CreateCustomerPriceGroupsInput: CreateCustomerPriceGroupsInput;
  CreateDepartmentInput: CreateDepartmentInput;
  CreateDivisionInput: CreateDivisionInput;
  CreateEmailInput: CreateEmailInput;
  CreateEmailServerInput: CreateEmailServerInput;
  CreateEmailTypeInput: CreateEmailTypeInput;
  CreateFailedReasonInput: CreateFailedReasonInput;
  CreateGridPreferenceInput: CreateGridPreferenceInput;
  CreateLeadSourceInput: CreateLeadSourceInput;
  CreateLineItemStatusInput: CreateLineItemStatusInput;
  CreateOpportunitiesInput: CreateOpportunitiesInput;
  CreatePhoneInput: CreatePhoneInput;
  CreatePhoneTypeInput: CreatePhoneTypeInput;
  CreatePrivateTeamInput: CreatePrivateTeamInput;
  CreatePrivateTeamTypeInput: CreatePrivateTeamTypeInput;
  CreateProductInterestInput: CreateProductInterestInput;
  CreateProductPotentialInput: CreateProductPotentialInput;
  CreateRegionInput: CreateRegionInput;
  CreateRelatedCategoryInput: CreateRelatedCategoryInput;
  CreateRelatedCompanyInput: CreateRelatedCompanyInput;
  CreateReportListInput: CreateReportListInput;
  CreateRoleInput: CreateRoleInput;
  CreateSalesTeamInput: CreateSalesTeamInput;
  CreateSalesTeamTypesInput: CreateSalesTeamTypesInput;
  CreateStageGroupInput: CreateStageGroupInput;
  CreateStageInput: CreateStageInput;
  CreateStateInput: CreateStateInput;
  CreateStatusInput: CreateStatusInput;
  CreateTenantInstanceInput: CreateTenantInstanceInput;
  CreateTerritoryInput: CreateTerritoryInput;
  CreateTypeInput: CreateTypeInput;
  CreateUserAdditionalFieldInput: CreateUserAdditionalFieldInput;
  CreateUserAdditionalFieldsUserInput: CreateUserAdditionalFieldsUserInput;
  CreateUserLandingWidget: CreateUserLandingWidget;
  CreateUserMenuInput: CreateUserMenuInput;
  CreateUserReportPreferenceInput: CreateUserReportPreferenceInput;
  CreateUserReportWidgetInput: CreateUserReportWidgetInput;
  CreateUserRoleInput: CreateUserRoleInput;
  CreateUserTypeInput: CreateUserTypeInput;
  CustomFieldDataStructureType: CustomFieldDataStructureType;
  CustomerPriceCodesModel: CustomerPriceCodesModel;
  CustomerPriceGroupsModel: CustomerPriceGroupsModel;
  DateRangeInput: DateRangeInput;
  DateTime: Scalars['DateTime']['output'];
  DeletedOkModel: DeletedOkModel;
  DepartmentModel: DepartmentModel;
  DivisionDBModel: DivisionDbModel;
  DivisionsCompanyModel: DivisionsCompanyModel;
  EmailModel: EmailModel;
  EmailServerModel: EmailServerModel;
  EmailTypeCriteriaInput: EmailTypeCriteriaInput;
  EmailTypeElasticResponse: EmailTypeElasticResponse;
  EmailTypeFilterInput: EmailTypeFilterInput;
  EmailTypeModel: EmailTypeModel;
  EmailTypeOrderByInput: EmailTypeOrderByInput;
  EmailUserModel: EmailUserModel;
  ExportRequestResponseDTO: ExportRequestResponseDto;
  ExportSearchInput: ExportSearchInput;
  FactoryTouchesReport: FactoryTouchesReport;
  FactoryTouchesResults: FactoryTouchesResults;
  FailedReasonCriteriaInput: FailedReasonCriteriaInput;
  FailedReasonElasticResponse: FailedReasonElasticResponse;
  FailedReasonFilterInput: FailedReasonFilterInput;
  FailedReasonOrderByInput: FailedReasonOrderByInput;
  FailedReasonOutputDto: FailedReasonOutputDto;
  FindAdditionalFieldsInput: FindAdditionalFieldsInput;
  FindAddressTypeInput: FindAddressTypeInput;
  FindBuyingGroupInput: FindBuyingGroupInput;
  FindCallPatternInput: FindCallPatternInput;
  FindCategoryInput: FindCategoryInput;
  FindCloseReasonInput: FindCloseReasonInput;
  FindCompanyRankInput: FindCompanyRankInput;
  FindCompanySplitTypesInput: FindCompanySplitTypesInput;
  FindCompanyTierInput: FindCompanyTierInput;
  FindCompanyTypeInput: FindCompanyTypeInput;
  FindContactGroupInput: FindContactGroupInput;
  FindContactInput: FindContactInput;
  FindContactNestInput: FindContactNestInput;
  FindCountryInput: FindCountryInput;
  FindEmailTypeInput: FindEmailTypeInput;
  FindFailedReasonInput: FindFailedReasonInput;
  FindIndustryInput: FindIndustryInput;
  FindLeadSourceInput: FindLeadSourceInput;
  FindLineItemStatusInput: FindLineItemStatusInput;
  FindOpportunitiesInput: FindOpportunitiesInput;
  FindPhoneTypeInput: FindPhoneTypeInput;
  FindPrivateTeamInput: FindPrivateTeamInput;
  FindProductInterestInput: FindProductInterestInput;
  FindProductPotentialInput: FindProductPotentialInput;
  FindRegionInput: FindRegionInput;
  FindRelatedCategoryInput: FindRelatedCategoryInput;
  FindRelatedCompanyInput: FindRelatedCompanyInput;
  FindReportListInput: FindReportListInput;
  FindRolesInput: FindRolesInput;
  FindSalesTeamInput: FindSalesTeamInput;
  FindSalesTeamTypesInput: FindSalesTeamTypesInput;
  FindStageGroupInput: FindStageGroupInput;
  FindStageInput: FindStageInput;
  FindStatusInput: FindStatusInput;
  FindTenantInstanceInput: FindTenantInstanceInput;
  FindTerritoryInput: FindTerritoryInput;
  FindTypeInput: FindTypeInput;
  FindUserLandingWidget: FindUserLandingWidget;
  FindUserReportPreferenceInput: FindUserReportPreferenceInput;
  FindUserReportWidgetInput: FindUserReportWidgetInput;
  FindUsersInput: FindUsersInput;
  Float: Scalars['Float']['output'];
  GetAllSalesTeamMembersResponseModel: GetAllSalesTeamMembersResponseModel;
  GetAllSalesTeamModel: GetAllSalesTeamModel;
  GridPreferenceModel: GridPreferenceModel;
  IIndustry: IIndustry;
  IndustryCriteriaInput: IndustryCriteriaInput;
  IndustryFilterInput: IndustryFilterInput;
  IndustryOrderByInput: IndustryOrderByInput;
  InstanceSettings: InstanceSettings;
  InstanceSettingsInput: InstanceSettingsInput;
  Int: Scalars['Int']['output'];
  JSON: Scalars['JSON']['output'];
  JSONObject: Scalars['JSONObject']['output'];
  LandingWidget: LandingWidget;
  LandingWidgetInput: LandingWidgetInput;
  LeadSource: LeadSource;
  LeadSourceCriteriaInput: LeadSourceCriteriaInput;
  LeadSourceElasticResponse: LeadSourceElasticResponse;
  LeadSourceFilterInput: LeadSourceFilterInput;
  LeadSourceOrderByInput: LeadSourceOrderByInput;
  LeadSourceOutputDto: LeadSourceOutputDto;
  LineItemStatusCriteriaInput: LineItemStatusCriteriaInput;
  LineItemStatusElasticResponse: LineItemStatusElasticResponse;
  LineItemStatusFilterInput: LineItemStatusFilterInput;
  LineItemStatusOrderByInput: LineItemStatusOrderByInput;
  LineItemStatusOutputDto: LineItemStatusOutputDto;
  LocationModel: LocationModel;
  LocationPoint: LocationPoint;
  MemberModel: MemberModel;
  MenuReport: MenuReport;
  MergeCompanyInput: MergeCompanyInput;
  MonthlySalesReport: MonthlySalesReport;
  MonthlySalesReportResult: MonthlySalesReportResult;
  Mutation: {};
  OppOwner: OppOwner;
  OppStage: OppStage;
  OppStatus: OppStatus;
  OpportunitiesCompanyOutputDto: OpportunitiesCompanyOutputDto;
  OpportunitiesContactOutputDto: OpportunitiesContactOutputDto;
  OpportunitiesCriteriaInput: OpportunitiesCriteriaInput;
  OpportunitiesDto: OpportunitiesDto;
  OpportunitiesElasticResponse: OpportunitiesElasticResponse;
  OpportunitiesFilterInput: OpportunitiesFilterInput;
  OpportunitiesOrderByInput: OpportunitiesOrderByInput;
  OpportunitiesOutputDto: OpportunitiesOutputDto;
  OptionStructureType: OptionStructureType;
  Options: Options;
  PaginationInput: PaginationInput;
  PaginationInput2: PaginationInput2;
  PhoneDBModel: PhoneDbModel;
  PhoneFast: PhoneFast;
  PhoneTypeCriteriaInput: PhoneTypeCriteriaInput;
  PhoneTypeElasticResponse: PhoneTypeElasticResponse;
  PhoneTypeFilterInput: PhoneTypeFilterInput;
  PhoneTypeModel: PhoneTypeModel;
  PhoneTypeOrderByInput: PhoneTypeOrderByInput;
  PrimaryCustomerWideSales: PrimaryCustomerWideSales;
  PrivateTeamCriteriaInput: PrivateTeamCriteriaInput;
  PrivateTeamFilterInput: PrivateTeamFilterInput;
  PrivateTeamModel: PrivateTeamModel;
  PrivateTeamOrderByInput: PrivateTeamOrderByInput;
  PrivateTeamResponse: PrivateTeamResponse;
  PrivateTeamTypeModel: PrivateTeamTypeModel;
  ProductInterestCriteriaInput: ProductInterestCriteriaInput;
  ProductInterestElasticResponse: ProductInterestElasticResponse;
  ProductInterestFilterInput: ProductInterestFilterInput;
  ProductInterestModel: ProductInterestModel;
  ProductInterestOrderByInput: ProductInterestOrderByInput;
  ProductLine: ProductLine;
  ProductPotentialCriteriaInput: ProductPotentialCriteriaInput;
  ProductPotentialFilterInput: ProductPotentialFilterInput;
  ProductPotentialOrderByInput: ProductPotentialOrderByInput;
  ProductPotentialResponse: ProductPotentialResponse;
  Query: {};
  RankOrderByInput: RankOrderByInput;
  RegionCriteriaInput: RegionCriteriaInput;
  RegionElasticResponse: RegionElasticResponse;
  RegionFilterInput: RegionFilterInput;
  RegionModel: RegionModel;
  RegionOrderByInput: RegionOrderByInput;
  RegionWideSales: RegionWideSales;
  RegisterSalesTeamUserInput: RegisterSalesTeamUserInput;
  RegisterUserInput: RegisterUserInput;
  RegisterUserSalesTeamModel: RegisterUserSalesTeamModel;
  RelatedCategoryCriteriaInput: RelatedCategoryCriteriaInput;
  RelatedCategoryElasticResponse: RelatedCategoryElasticResponse;
  RelatedCategoryFilterInput: RelatedCategoryFilterInput;
  RelatedCategoryModel: RelatedCategoryModel;
  RelatedCategoryOrderByInput: RelatedCategoryOrderByInput;
  RelatedCompanyCriteriaInput: RelatedCompanyCriteriaInput;
  RelatedCompanyDBModel: RelatedCompanyDbModel;
  RelatedCompanyElasticResponse: RelatedCompanyElasticResponse;
  RelatedCompanyFilterInput: RelatedCompanyFilterInput;
  RelatedCompanyOrderByInput: RelatedCompanyOrderByInput;
  ReportListModel: ReportListModel;
  ReportResponseDTO: ReportResponseDto;
  Role: Role;
  RoleBasicInformation: RoleBasicInformation;
  RoleModel: RoleModel;
  SalesByMonth: SalesByMonth;
  SalesTeam: SalesTeam;
  SalesTeamCriteriaInput: SalesTeamCriteriaInput;
  SalesTeamFilterInput: SalesTeamFilterInput;
  SalesTeamMemberModel: SalesTeamMemberModel;
  SalesTeamModel: SalesTeamModel;
  SalesTeamOrderByInput: SalesTeamOrderByInput;
  SalesTeamResponse: SalesTeamResponse;
  SalesTeamTypeResponse: SalesTeamTypeResponse;
  SalesTeamTypesCriteriaInput: SalesTeamTypesCriteriaInput;
  SalesTeamTypesFilterInput: SalesTeamTypesFilterInput;
  SalesTeamTypesModel: SalesTeamTypesModel;
  SalesTeamTypesOrderByInput: SalesTeamTypesOrderByInput;
  Section: Section;
  SecurityTokenModel: SecurityTokenModel;
  SomeColumnModel: SomeColumnModel;
  SplitTypeResponse: SplitTypeResponse;
  SplitTypesFilterInput: SplitTypesFilterInput;
  SplitTypesOrderByInput: SplitTypesOrderByInput;
  StageCriteriaInput: StageCriteriaInput;
  StageElasticResponse: StageElasticResponse;
  StageFilterInput: StageFilterInput;
  StageGroupCriteriaInput: StageGroupCriteriaInput;
  StageGroupElasticResponse: StageGroupElasticResponse;
  StageGroupFilterInput: StageGroupFilterInput;
  StageGroupModel: StageGroupModel;
  StageGroupOrderByInput: StageGroupOrderByInput;
  StageGroupOutputDto: StageGroupOutputDto;
  StageOrderByInput: StageOrderByInput;
  StageOutputDto: StageOutputDto;
  State: State;
  StateDBModel: StateDbModel;
  StatusCriteriaInput: StatusCriteriaInput;
  StatusElasticResponse: StatusElasticResponse;
  StatusFilterInput: StatusFilterInput;
  StatusModel: StatusModel;
  StatusOrderByInput: StatusOrderByInput;
  StatusOutputDto: StatusOutputDto;
  String: Scalars['String']['output'];
  SubTableByCompanyElasticResponse: SubTableByCompanyElasticResponse;
  SubTableStageByCompanyElasticResponse: SubTableStageByCompanyElasticResponse;
  Subscription: {};
  TeamWideSales: TeamWideSales;
  TenantInstance: TenantInstance;
  TerritoryCriteriaInput: TerritoryCriteriaInput;
  TerritoryDBModel: TerritoryDbModel;
  TerritoryElasticResponse: TerritoryElasticResponse;
  TerritoryFilterInput: TerritoryFilterInput;
  TerritoryOrderByInput: TerritoryOrderByInput;
  Type: Type;
  TypeCriteriaInput: TypeCriteriaInput;
  TypeElasticResponse: TypeElasticResponse;
  TypeFilterInput: TypeFilterInput;
  TypeOrderByInput: TypeOrderByInput;
  TypeOutputDto: TypeOutputDto;
  UpdateAdditionalFieldInput: UpdateAdditionalFieldInput;
  UpdateAddressInlineInput: UpdateAddressInlineInput;
  UpdateAddressInput: UpdateAddressInput;
  UpdateAddressTypeInput: UpdateAddressTypeInput;
  UpdateAnUserInput: UpdateAnUserInput;
  UpdateAnalyticsWideSaleInput: UpdateAnalyticsWideSaleInput;
  UpdateAttachmentTypeInput: UpdateAttachmentTypeInput;
  UpdateBuyGroupInput: UpdateBuyGroupInput;
  UpdateCallPatternInput: UpdateCallPatternInput;
  UpdateCloseReasonInput: UpdateCloseReasonInput;
  UpdateColumnInput: UpdateColumnInput;
  UpdateCompanyAdditionalFieldInput: UpdateCompanyAdditionalFieldInput;
  UpdateCompanyAttachmentInput: UpdateCompanyAttachmentInput;
  UpdateCompanyBatchInput: UpdateCompanyBatchInput;
  UpdateCompanyBatchModel: UpdateCompanyBatchModel;
  UpdateCompanyBatchResponseModel: UpdateCompanyBatchResponseModel;
  UpdateCompanyCategoryInput: UpdateCompanyCategoryInput;
  UpdateCompanyClassInput: UpdateCompanyClassInput;
  UpdateCompanyDivisionInput: UpdateCompanyDivisionInput;
  UpdateCompanyDocNotesInput: UpdateCompanyDocNotesInput;
  UpdateCompanyElasticInput: UpdateCompanyElasticInput;
  UpdateCompanyElasticInputList: UpdateCompanyElasticInputList;
  UpdateCompanyElasticInputList2: UpdateCompanyElasticInputList2;
  UpdateCompanyIndustryInput: UpdateCompanyIndustryInput;
  UpdateCompanyInlineInput: UpdateCompanyInlineInput;
  UpdateCompanyInput: UpdateCompanyInput;
  UpdateCompanyPitchInput: UpdateCompanyPitchInput;
  UpdateCompanyRankInput: UpdateCompanyRankInput;
  UpdateCompanySalesTeamInput: UpdateCompanySalesTeamInput;
  UpdateCompanySplitTypesInput: UpdateCompanySplitTypesInput;
  UpdateCompanyTagInput: UpdateCompanyTagInput;
  UpdateCompanyTierInput: UpdateCompanyTierInput;
  UpdateCompanyTypeAliasInput: UpdateCompanyTypeAliasInput;
  UpdateContactAdditionalFieldsContactInput: UpdateContactAdditionalFieldsContactInput;
  UpdateContactBatchInput: UpdateContactBatchInput;
  UpdateContactBatchModel: UpdateContactBatchModel;
  UpdateContactBusinessInfoInput: UpdateContactBusinessInfoInput;
  UpdateContactGroupContactInput: UpdateContactGroupContactInput;
  UpdateContactGroupInput: UpdateContactGroupInput;
  UpdateContactInput: UpdateContactInput;
  UpdateContactPersonalInfoInput: UpdateContactPersonalInfoInput;
  UpdateContinentsInput: UpdateContinentsInput;
  UpdateCountryInput: UpdateCountryInput;
  UpdateCustomerPriceCodesInput: UpdateCustomerPriceCodesInput;
  UpdateCustomerPriceGroupsInput: UpdateCustomerPriceGroupsInput;
  UpdateDepartmentInput: UpdateDepartmentInput;
  UpdateDivisionInput: UpdateDivisionInput;
  UpdateEmailInput: UpdateEmailInput;
  UpdateEmailServerInput: UpdateEmailServerInput;
  UpdateEmailTypeInput: UpdateEmailTypeInput;
  UpdateFailedReasonInput: UpdateFailedReasonInput;
  UpdateGridPreferenceInput: UpdateGridPreferenceInput;
  UpdateLeadSourceInput: UpdateLeadSourceInput;
  UpdateLineItemStatusInput: UpdateLineItemStatusInput;
  UpdateOpportunitiesInput: UpdateOpportunitiesInput;
  UpdatePhoneInlineInput: UpdatePhoneInlineInput;
  UpdatePhoneInput: UpdatePhoneInput;
  UpdatePhoneTypeInput: UpdatePhoneTypeInput;
  UpdatePrivateTeamInput: UpdatePrivateTeamInput;
  UpdatePrivateTeamTypeInput: UpdatePrivateTeamTypeInput;
  UpdateProductInterestInput: UpdateProductInterestInput;
  UpdateProductPotentialInput: UpdateProductPotentialInput;
  UpdateRegionInput: UpdateRegionInput;
  UpdateRelatedCategoryInput: UpdateRelatedCategoryInput;
  UpdateRelatedCompanyInput: UpdateRelatedCompanyInput;
  UpdateReportListInput: UpdateReportListInput;
  UpdateRoleInput: UpdateRoleInput;
  UpdateSalesTeamInput: UpdateSalesTeamInput;
  UpdateSalesTeamTypesInput: UpdateSalesTeamTypesInput;
  UpdateStageGroupInput: UpdateStageGroupInput;
  UpdateStageInput: UpdateStageInput;
  UpdateStateInput: UpdateStateInput;
  UpdateStatusInput: UpdateStatusInput;
  UpdateTenantInstanceInput: UpdateTenantInstanceInput;
  UpdateTerritoryInput: UpdateTerritoryInput;
  UpdateTypeInput: UpdateTypeInput;
  UpdateUserAdditionalFieldInput: UpdateUserAdditionalFieldInput;
  UpdateUserAdditionalFieldsUserInput: UpdateUserAdditionalFieldsUserInput;
  UpdateUserLandingWidget: UpdateUserLandingWidget;
  UpdateUserMenuInput: UpdateUserMenuInput;
  UpdateUserReportPreferenceInput: UpdateUserReportPreferenceInput;
  UpdateUserReportWidgetInput: UpdateUserReportWidgetInput;
  UpdateUserRoleInput: UpdateUserRoleInput;
  UpdateUserTypeInput: UpdateUserTypeInput;
  Upload: Scalars['Upload']['output'];
  User: User;
  UserAdditionalFieldModel: UserAdditionalFieldModel;
  UserAdditionalFieldsUserModel: UserAdditionalFieldsUserModel;
  UserDBModel: UserDbModel;
  UserESInput: UserEsInput;
  UserEmailModel: UserEmailModel;
  UserLandingWidget: UserLandingWidget;
  UserMenuModel: UserMenuModel;
  UserModel: UserModel;
  UserRegisterInput: UserRegisterInput;
  UserReportPreference: UserReportPreference;
  UserReportWidgetList: UserReportWidgetList;
  UserRoleModel: UserRoleModel;
  UserTypeModel: UserTypeModel;
  WideAJReport: WideAjReport;
  WideAJReportResult: WideAjReportResult;
  WideCommissionReport: WideCommissionReport;
  WideCommissionSalesReport: WideCommissionSalesReport;
  WideCommissionsReportResult: WideCommissionsReportResult;
  WideCommissionsSalesReportResult: WideCommissionsSalesReportResult;
  WidePOReport: WidePoReport;
  WidePOReportResult: WidePoReportResult;
  WideQuotesReport: WideQuotesReport;
  WideQuotesReportResult: WideQuotesReportResult;
  WideSalesReport: WideSalesReport;
  WideSalesReportResult: WideSalesReportResult;
  gridItem: GridItem;
  gridItemInput: GridItemInput;
  phoneModel: PhoneModel;
  phoneModelESInput: PhoneModelEsInput;
}>;

export type AdditionalFieldModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdditionalFieldModel'] = ResolversParentTypes['AdditionalFieldModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dataStructure?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  dataType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  module?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AdditionalFieldsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdditionalFieldsResponse'] = ResolversParentTypes['AdditionalFieldsResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['AdditionalFieldModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddressDbModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddressDBModel'] = ResolversParentTypes['AddressDBModel'],
> = ResolversObject<{
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  addressType?: Resolver<Maybe<ResolversTypes['AddressTypeModel']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  continent?: Resolver<Maybe<ResolversTypes['ContinentDBModel']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CountryDBModel']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  formattedAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geopositionLatitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  geopositionLongitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isAddressInvalid?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  pobox?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['RegionModel']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['StateDBModel']>, ParentType, ContextType>;
  territory?: Resolver<Maybe<ResolversTypes['TerritoryDBModel']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddressFastResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddressFast'] = ResolversParentTypes['AddressFast'],
> = ResolversObject<{
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['RegionModel']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['StateDBModel']>, ParentType, ContextType>;
  zipCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddressModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddressModel'] = ResolversParentTypes['AddressModel'],
> = ResolversObject<{
  ADDRESS?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CITY?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ID?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  PO_BOX?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  REGION?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  REGION_ID?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  STATE?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddressTypeElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddressTypeElasticResponse'] = ResolversParentTypes['AddressTypeElasticResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['AddressTypeModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AddressTypeModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddressTypeModel'] = ResolversParentTypes['AddressTypeModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isCompany?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isContact?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AnalyticsWideSaleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnalyticsWideSale'] = ResolversParentTypes['AnalyticsWideSale'],
> = ResolversObject<{
  exampleField?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AttachmentTypeModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttachmentTypeModel'] = ResolversParentTypes['AttachmentTypeModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AuroraSalesReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuroraSalesReport'] = ResolversParentTypes['AuroraSalesReport'],
> = ResolversObject<{
  results?: Resolver<
    Maybe<Array<ResolversTypes['AuroraSalesReportResult']>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AuroraSalesReportResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuroraSalesReportResult'] = ResolversParentTypes['AuroraSalesReportResult'],
> = ResolversObject<{
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateRangeSales?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  finalDateRangeMonthSales?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastYearDateRangeSales?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastYearFinalDateRangeMonthSales?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  lastYearSales?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentageDiff?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  saleTeam?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BatchErrorModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BatchErrorModel'] = ResolversParentTypes['BatchErrorModel'],
> = ResolversObject<{
  context?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  errorCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  service?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BuyGroupModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BuyGroupModel'] = ResolversParentTypes['BuyGroupModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BuyingGroupResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BuyingGroupResponse'] = ResolversParentTypes['BuyingGroupResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['BuyGroupModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CallPatternModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CallPatternModel'] = ResolversParentTypes['CallPatternModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CallPatternResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CallPatternResponse'] = ResolversParentTypes['CallPatternResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['CallPatternModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CategoryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CategoryResponse'] = ResolversParentTypes['CategoryResponse'],
> = ResolversObject<{
  results?: Resolver<
    Maybe<Array<ResolversTypes['CompanyCategoryDBModel']>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CloseReasonElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CloseReasonElasticResponse'] = ResolversParentTypes['CloseReasonElasticResponse'],
> = ResolversObject<{
  results?: Resolver<
    Maybe<Array<ResolversTypes['SubTableByCompanyElasticResponse']>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CloseReasonOutputDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CloseReasonOutputDto'] = ResolversParentTypes['CloseReasonOutputDto'],
> = ResolversObject<{
  boolean?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CloseStatusModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CloseStatusModel'] = ResolversParentTypes['CloseStatusModel'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ClosedReasonResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClosedReason'] = ResolversParentTypes['ClosedReason'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ClosedStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClosedStatus'] = ResolversParentTypes['ClosedStatus'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CognitoConfirmResponseModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CognitoConfirmResponseModel'] = ResolversParentTypes['CognitoConfirmResponseModel'],
> = ResolversObject<{
  cognito_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cognito_token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emails?: Resolver<Maybe<Array<ResolversTypes['EmailUserModel']>>, ParentType, ContextType>;
  first_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  last_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenant_id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ColumnModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ColumnModel'] = ResolversParentTypes['ColumnModel'],
> = ResolversObject<{
  aggregable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dataType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  elastic_search_field?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  filtrable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reportId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyAdditionalFieldModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyAdditionalFieldModel'] = ResolversParentTypes['CompanyAdditionalFieldModel'],
> = ResolversObject<{
  additionalField?: Resolver<
    Maybe<ResolversTypes['AdditionalFieldModel']>,
    ParentType,
    ContextType
  >;
  company?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyAddressModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyAddressModel'] = ResolversParentTypes['CompanyAddressModel'],
> = ResolversObject<{
  address?: Resolver<Maybe<ResolversTypes['AddressDBModel']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyAttachmentModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyAttachmentModel'] = ResolversParentTypes['CompanyAttachmentModel'],
> = ResolversObject<{
  companyID?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  typeID?: Resolver<Maybe<ResolversTypes['AttachmentTypeModel']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyBaseTypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyBaseType'] = ResolversParentTypes['CompanyBaseType'],
> = ResolversObject<{
  additionalFields?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  additionalRequiredFields?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  options?: Resolver<Array<ResolversTypes['Options']>, ParentType, ContextType>;
  parent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyBatchResultModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyBatchResultModel'] = ResolversParentTypes['CompanyBatchResultModel'],
> = ResolversObject<{
  company?: Resolver<
    Maybe<ResolversTypes['UpdateCompanyBatchResponseModel']>,
    ParentType,
    ContextType
  >;
  error?: Resolver<Maybe<ResolversTypes['BatchErrorModel']>, ParentType, ContextType>;
  new_data?: Resolver<Maybe<ResolversTypes['UpdateCompanyBatchModel']>, ParentType, ContextType>;
  prev_data?: Resolver<Maybe<ResolversTypes['UpdateCompanyBatchModel']>, ParentType, ContextType>;
  processed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyCategoryDbModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyCategoryDBModel'] = ResolversParentTypes['CompanyCategoryDBModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyCategoryModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyCategoryModel'] = ResolversParentTypes['CompanyCategoryModel'],
> = ResolversObject<{
  LABEL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VALUE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyClassDbModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyClassDBModel'] = ResolversParentTypes['CompanyClassDBModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyClassModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyClassModel'] = ResolversParentTypes['CompanyClassModel'],
> = ResolversObject<{
  LABEL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VALUE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyDivisionModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyDivisionModel'] = ResolversParentTypes['CompanyDivisionModel'],
> = ResolversObject<{
  companyID?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  divisionID?: Resolver<Maybe<ResolversTypes['DivisionDBModel']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyDocNotesModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyDocNotesModel'] = ResolversParentTypes['CompanyDocNotesModel'],
> = ResolversObject<{
  companyID?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  purchaseOrderTerms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quoteTerms?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyElasticModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyElasticModel'] = ResolversParentTypes['CompanyElasticModel'],
> = ResolversObject<{
  ACTIVE?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ADDRESSES?: Resolver<Maybe<ResolversTypes['AddressModel']>, ParentType, ContextType>;
  CALL_PATTERN_ID?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  CATEGORY?: Resolver<Maybe<ResolversTypes['CompanyCategoryModel']>, ParentType, ContextType>;
  CLASS?: Resolver<Maybe<ResolversTypes['CompanyClassModel']>, ParentType, ContextType>;
  COMMENTS?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CREATED_BY?: Resolver<Maybe<ResolversTypes['UserModel']>, ParentType, ContextType>;
  CREATED_DATE?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  DATA_SOURCE?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ES_ID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  HAS_HOLYDAY?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ID?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  IMP_BATCH?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  NAME?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PARENT_ID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  PARENT_NAME?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  PHONES?: Resolver<Maybe<ResolversTypes['phoneModel']>, ParentType, ContextType>;
  PRINT_NAME?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  REF_NO?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  SALES_TEAM?: Resolver<Maybe<ResolversTypes['CompanySalesESTeamModel']>, ParentType, ContextType>;
  SHORT_NAME?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TYPE?: Resolver<Maybe<ResolversTypes['CompanyTypeModel']>, ParentType, ContextType>;
  UPDATED_BY?: Resolver<Maybe<ResolversTypes['UserModel']>, ParentType, ContextType>;
  UPDATED_DATE?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  VISIT_FREQUENCY?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  WEB_SITE?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyFastModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyFastModel'] = ResolversParentTypes['CompanyFastModel'],
> = ResolversObject<{
  companyType?: Resolver<Maybe<ResolversTypes['CompanyTypeAliasModel']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesTeam?: Resolver<Maybe<ResolversTypes['SalesTeamModel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyIndustryDbModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyIndustryDBModel'] = ResolversParentTypes['CompanyIndustryDBModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyIndustryResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyIndustryResponse'] = ResolversParentTypes['CompanyIndustryResponse'],
> = ResolversObject<{
  results?: Resolver<
    Maybe<Array<ResolversTypes['CompanyIndustryDBModel']>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyModel'] = ResolversParentTypes['CompanyModel'],
> = ResolversObject<{
  addresses?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AddressDBModel']>>>,
    ParentType,
    ContextType
  >;
  callPattern?: Resolver<Maybe<ResolversTypes['CallPatternModel']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['CompanyCategoryDBModel']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyClass?: Resolver<Maybe<ResolversTypes['CompanyClassDBModel']>, ParentType, ContextType>;
  companyType?: Resolver<Maybe<ResolversTypes['CompanyTypeAliasModel']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  datasource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  divisions?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['DivisionDBModel']>>>,
    ParentType,
    ContextType
  >;
  emails?: Resolver<Maybe<Array<Maybe<ResolversTypes['EmailModel']>>>, ParentType, ContextType>;
  hasDwin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasForecast?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasHolydayCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPartNumberSplit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPosAccount?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasQuot?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasRegi?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasSamp?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasSecondaryAlias?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  impBatch?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  industries?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CompanyIndustryDBModel']>>>,
    ParentType,
    ContextType
  >;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['LocationModel']>, ParentType, ContextType>;
  mainAddress?: Resolver<Maybe<ResolversTypes['AddressDBModel']>, ParentType, ContextType>;
  mainContact?: Resolver<Maybe<ResolversTypes['ContactOutputDto']>, ParentType, ContextType>;
  mainEmail?: Resolver<Maybe<ResolversTypes['EmailModel']>, ParentType, ContextType>;
  mainPhone?: Resolver<Maybe<ResolversTypes['PhoneDBModel']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  phones?: Resolver<Maybe<Array<Maybe<ResolversTypes['PhoneDBModel']>>>, ParentType, ContextType>;
  poFooter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  printName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  privateTeam?: Resolver<Maybe<ResolversTypes['PrivateTeamModel']>, ParentType, ContextType>;
  productPotentials?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CompanyProductPotentialModel']>>>,
    ParentType,
    ContextType
  >;
  quoteFooter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rank?: Resolver<Maybe<ResolversTypes['CompanyRankDBModel']>, ParentType, ContextType>;
  refNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesTeam?: Resolver<Maybe<ResolversTypes['SalesTeamModel']>, ParentType, ContextType>;
  shipDetail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['CompanyTagsModel']>>>, ParentType, ContextType>;
  tenantDivision?: Resolver<Maybe<ResolversTypes['DivisionDBModel']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  visitFrequency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyModelElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyModelElasticResponse'] = ResolversParentTypes['CompanyModelElasticResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['CompanyElasticModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyModelElasticResponse2Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyModelElasticResponse2'] = ResolversParentTypes['CompanyModelElasticResponse2'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['CompanyModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyPhoneModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyPhoneModel'] = ResolversParentTypes['CompanyPhoneModel'],
> = ResolversObject<{
  company?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['PhoneDBModel']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyPitchesModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyPitchesModel'] = ResolversParentTypes['CompanyPitchesModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyProductPotentialModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyProductPotentialModel'] = ResolversParentTypes['CompanyProductPotentialModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyRankDbModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyRankDBModel'] = ResolversParentTypes['CompanyRankDBModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyRankResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyRankResponse'] = ResolversParentTypes['CompanyRankResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['CompanyRankDBModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanySalesEsTeamModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanySalesESTeamModel'] = ResolversParentTypes['CompanySalesESTeamModel'],
> = ResolversObject<{
  LABEL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VALUE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanySalesTeamModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanySalesTeamModel'] = ResolversParentTypes['CompanySalesTeamModel'],
> = ResolversObject<{
  companyCustomer?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  salesTeam?: Resolver<Maybe<ResolversTypes['SalesTeamModel']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanySplitTypesModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanySplitTypesModel'] = ResolversParentTypes['CompanySplitTypesModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyTagsModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyTagsModel'] = ResolversParentTypes['CompanyTagsModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyTierModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyTierModel'] = ResolversParentTypes['CompanyTierModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyTierResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyTierResponse'] = ResolversParentTypes['CompanyTierResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['CompanyTierModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyTypeAliasModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyTypeAliasModel'] = ResolversParentTypes['CompanyTypeAliasModel'],
> = ResolversObject<{
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['CompanyTypeDBModel']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyTypeDbModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyTypeDBModel'] = ResolversParentTypes['CompanyTypeDBModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyTypeModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyTypeModel'] = ResolversParentTypes['CompanyTypeModel'],
> = ResolversObject<{
  LABEL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  VALUE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyTypeModelElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyTypeModelElasticResponse'] = ResolversParentTypes['CompanyTypeModelElasticResponse'],
> = ResolversObject<{
  results?: Resolver<
    Maybe<Array<ResolversTypes['CompanyTypeAliasModel']>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactAdditionalFieldsContactModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactAdditionalFieldsContactModel'] = ResolversParentTypes['ContactAdditionalFieldsContactModel'],
> = ResolversObject<{
  additionalField?: Resolver<
    Maybe<ResolversTypes['AdditionalFieldModel']>,
    ParentType,
    ContextType
  >;
  contact?: Resolver<Maybe<ResolversTypes['ContactOutputDto']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactBatchResponseModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactBatchResponseModel'] = ResolversParentTypes['ContactBatchResponseModel'],
> = ResolversObject<{
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactBatchResultModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactBatchResultModel'] = ResolversParentTypes['ContactBatchResultModel'],
> = ResolversObject<{
  contact?: Resolver<Maybe<ResolversTypes['ContactBatchResponseModel']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['BatchErrorModel']>, ParentType, ContextType>;
  new_data?: Resolver<Maybe<ResolversTypes['UpdateContactBatchModel']>, ParentType, ContextType>;
  prev_data?: Resolver<Maybe<ResolversTypes['UpdateContactBatchModel']>, ParentType, ContextType>;
  processed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactBusinessInfoFastModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactBusinessInfoFastModel'] = ResolversParentTypes['ContactBusinessInfoFastModel'],
> = ResolversObject<{
  company?: Resolver<Maybe<ResolversTypes['CompanyFastModel']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainAddress?: Resolver<Maybe<ResolversTypes['AddressFast']>, ParentType, ContextType>;
  mainEmail?: Resolver<Maybe<ResolversTypes['EmailModel']>, ParentType, ContextType>;
  mainPhone?: Resolver<Maybe<ResolversTypes['PhoneFast']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactBusinessInfoModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactBusinessInfoModel'] = ResolversParentTypes['ContactBusinessInfoModel'],
> = ResolversObject<{
  addresses?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AddressDBModel']>>>,
    ParentType,
    ContextType
  >;
  assistant?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  context?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  doNotEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  emails?: Resolver<Maybe<Array<Maybe<ResolversTypes['EmailModel']>>>, ParentType, ContextType>;
  enabledForcedSync?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  globallyVisible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isPrimary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainAddress?: Resolver<Maybe<ResolversTypes['AddressDBModel']>, ParentType, ContextType>;
  mainEmail?: Resolver<Maybe<ResolversTypes['EmailModel']>, ParentType, ContextType>;
  mainPhone?: Resolver<Maybe<ResolversTypes['PhoneDBModel']>, ParentType, ContextType>;
  manager?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phones?: Resolver<Maybe<Array<Maybe<ResolversTypes['PhoneDBModel']>>>, ParentType, ContextType>;
  referencedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactFastGridResponseOutputDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactFastGridResponseOutputDto'] = ResolversParentTypes['ContactFastGridResponseOutputDto'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['ContactFastOutputDto']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactFastOutputDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactFastOutputDto'] = ResolversParentTypes['ContactFastOutputDto'],
> = ResolversObject<{
  businessInfo?: Resolver<
    Maybe<ResolversTypes['ContactBusinessInfoFastModel']>,
    ParentType,
    ContextType
  >;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactGridResponseOutputDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactGridResponseOutputDto'] = ResolversParentTypes['ContactGridResponseOutputDto'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['ContactOutputDto']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactGroupContactModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactGroupContactModel'] = ResolversParentTypes['ContactGroupContactModel'],
> = ResolversObject<{
  contact?: Resolver<Maybe<ResolversTypes['ContactOutputDto']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  groupContact?: Resolver<Maybe<ResolversTypes['ContactGroupModel']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactGroupElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactGroupElasticResponse'] = ResolversParentTypes['ContactGroupElasticResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['ContactGroupModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactGroupModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactGroupModel'] = ResolversParentTypes['ContactGroupModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactOutputDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactOutputDto'] = ResolversParentTypes['ContactOutputDto'],
> = ResolversObject<{
  businessInfo?: Resolver<
    Maybe<ResolversTypes['ContactBusinessInfoModel']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groups?: Resolver<Maybe<Array<ResolversTypes['ContactGroupModel']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personalInfo?: Resolver<
    Maybe<ResolversTypes['ContactPersonalInfoModel']>,
    ParentType,
    ContextType
  >;
  products?: Resolver<
    Maybe<Array<ResolversTypes['ProductInterestModel']>>,
    ParentType,
    ContextType
  >;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactPersonalInfoModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContactPersonalInfoModel'] = ResolversParentTypes['ContactPersonalInfoModel'],
> = ResolversObject<{
  addresses?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AddressDBModel']>>>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  emails?: Resolver<Maybe<Array<Maybe<ResolversTypes['EmailModel']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mainAddress?: Resolver<Maybe<ResolversTypes['AddressDBModel']>, ParentType, ContextType>;
  mainEmail?: Resolver<Maybe<ResolversTypes['EmailModel']>, ParentType, ContextType>;
  mainPhone?: Resolver<Maybe<ResolversTypes['PhoneDBModel']>, ParentType, ContextType>;
  phones?: Resolver<Maybe<Array<Maybe<ResolversTypes['PhoneDBModel']>>>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContinentDbModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContinentDBModel'] = ResolversParentTypes['ContinentDBModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CountryDbModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountryDBModel'] = ResolversParentTypes['CountryDBModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CountryElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CountryElasticResponse'] = ResolversParentTypes['CountryElasticResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['CountryDBModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomerPriceCodesModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerPriceCodesModel'] = ResolversParentTypes['CustomerPriceCodesModel'],
> = ResolversObject<{
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyCustomer?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  companyManufacturer?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  multiplier?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomerPriceGroupsModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CustomerPriceGroupsModel'] = ResolversParentTypes['CustomerPriceGroupsModel'],
> = ResolversObject<{
  buyGroup?: Resolver<Maybe<ResolversTypes['BuyGroupModel']>, ParentType, ContextType>;
  companyCustomer?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  companyManufacturer?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  companyTier?: Resolver<ResolversTypes['CompanyTierModel'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeletedOkModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeletedOkModel'] = ResolversParentTypes['DeletedOkModel'],
> = ResolversObject<{
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DepartmentModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DepartmentModel'] = ResolversParentTypes['DepartmentModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['DivisionDBModel']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DivisionDbModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DivisionDBModel'] = ResolversParentTypes['DivisionDBModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DivisionsCompanyModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DivisionsCompanyModel'] = ResolversParentTypes['DivisionsCompanyModel'],
> = ResolversObject<{
  designatedMember?: Resolver<Maybe<ResolversTypes['MemberModel']>, ParentType, ContextType>;
  division?: Resolver<Maybe<ResolversTypes['DivisionDBModel']>, ParentType, ContextType>;
  members?: Resolver<Maybe<Array<ResolversTypes['MemberModel']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EmailModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailModel'] = ResolversParentTypes['EmailModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailType?: Resolver<Maybe<ResolversTypes['EmailTypeModel']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EmailServerModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailServerModel'] = ResolversParentTypes['EmailServerModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  incomingPort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  incomingProtocol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  incomingSSL?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  incomingServer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  outgoingPort?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  outgoingProtocol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  outgoingSSL?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  outgoingServer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  providerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EmailTypeElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailTypeElasticResponse'] = ResolversParentTypes['EmailTypeElasticResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['EmailTypeModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EmailTypeModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailTypeModel'] = ResolversParentTypes['EmailTypeModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EmailUserModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmailUserModel'] = ResolversParentTypes['EmailUserModel'],
> = ResolversObject<{
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExportRequestResponseDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExportRequestResponseDTO'] = ResolversParentTypes['ExportRequestResponseDTO'],
> = ResolversObject<{
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestedFields?: Resolver<Maybe<Array<ResolversTypes['CompanyModel']>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FactoryTouchesReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FactoryTouchesReport'] = ResolversParentTypes['FactoryTouchesReport'],
> = ResolversObject<{
  results?: Resolver<
    Maybe<Array<ResolversTypes['FactoryTouchesResults']>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FactoryTouchesResultsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FactoryTouchesResults'] = ResolversParentTypes['FactoryTouchesResults'],
> = ResolversObject<{
  company?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  touches?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FailedReasonElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FailedReasonElasticResponse'] = ResolversParentTypes['FailedReasonElasticResponse'],
> = ResolversObject<{
  results?: Resolver<
    Maybe<Array<ResolversTypes['SubTableByCompanyElasticResponse']>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FailedReasonOutputDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FailedReasonOutputDto'] = ResolversParentTypes['FailedReasonOutputDto'],
> = ResolversObject<{
  boolean?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetAllSalesTeamMembersResponseModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetAllSalesTeamMembersResponseModel'] = ResolversParentTypes['GetAllSalesTeamMembersResponseModel'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['GetAllSalesTeamModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GetAllSalesTeamModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetAllSalesTeamModel'] = ResolversParentTypes['GetAllSalesTeamModel'],
> = ResolversObject<{
  companySplit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  companySplitType?: Resolver<
    Maybe<ResolversTypes['CompanySplitTypesModel']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  leaderSalesTeamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  specifierSplit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  teamMembers?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['SalesTeamMemberModel']>>>,
    ParentType,
    ContextType
  >;
  type?: Resolver<Maybe<ResolversTypes['SalesTeamTypesModel']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GridPreferenceModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GridPreferenceModel'] = ResolversParentTypes['GridPreferenceModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  gridName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isDefault?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IIndustryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IIndustry'] = ResolversParentTypes['IIndustry'],
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type InstanceSettingsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InstanceSettings'] = ResolversParentTypes['InstanceSettings'],
> = ResolversObject<{
  allowActivityJournals?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowCommissions?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowOrders?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowQuotes?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowSales?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowSalesAndCommissions?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export interface JsonObjectScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export type LandingWidgetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LandingWidget'] = ResolversParentTypes['LandingWidget'],
> = ResolversObject<{
  height?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  maxH?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  maxW?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  minH?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  minW?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  widgetId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  width?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  x?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  y?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LeadSourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeadSource'] = ResolversParentTypes['LeadSource'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LeadSourceElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeadSourceElasticResponse'] = ResolversParentTypes['LeadSourceElasticResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['LeadSourceOutputDto']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LeadSourceOutputDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeadSourceOutputDto'] = ResolversParentTypes['LeadSourceOutputDto'],
> = ResolversObject<{
  boolean?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LineItemStatusElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LineItemStatusElasticResponse'] = ResolversParentTypes['LineItemStatusElasticResponse'],
> = ResolversObject<{
  results?: Resolver<
    Maybe<Array<ResolversTypes['SubTableByCompanyElasticResponse']>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LineItemStatusOutputDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LineItemStatusOutputDto'] = ResolversParentTypes['LineItemStatusOutputDto'],
> = ResolversObject<{
  boolean?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocationModel'] = ResolversParentTypes['LocationModel'],
> = ResolversObject<{
  lat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  long?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MemberModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MemberModel'] = ResolversParentTypes['MemberModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MenuReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MenuReport'] = ResolversParentTypes['MenuReport'],
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  extraParams?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reportRoute?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reportTag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  widgets?: Resolver<Array<ResolversTypes['UserReportWidgetList']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MonthlySalesReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MonthlySalesReport'] = ResolversParentTypes['MonthlySalesReport'],
> = ResolversObject<{
  results?: Resolver<
    Maybe<Array<ResolversTypes['MonthlySalesReportResult']>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MonthlySalesReportResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MonthlySalesReportResult'] = ResolversParentTypes['MonthlySalesReportResult'],
> = ResolversObject<{
  month?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sales?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesAvg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = ResolversObject<{
  AssignRole?: Resolver<
    ResolversTypes['Role'],
    ParentType,
    ContextType,
    RequireFields<MutationAssignRoleArgs, 'assignRole'>
  >;
  ChangeSalesTeam?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationChangeSalesTeamArgs, 'changeSalesTeamInput'>
  >;
  CompanyBatchRemove?: Resolver<
    Array<ResolversTypes['CompanyBatchResultModel']>,
    ParentType,
    ContextType,
    RequireFields<MutationCompanyBatchRemoveArgs, 'ids'>
  >;
  CompanyBatchUpdate?: Resolver<
    ResolversTypes['CompanyBatchResultModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCompanyBatchUpdateArgs, 'updateCompanyBatchInput'>
  >;
  ConfirmUser?: Resolver<
    Maybe<ResolversTypes['CognitoConfirmResponseModel']>,
    ParentType,
    ContextType,
    RequireFields<MutationConfirmUserArgs, 'confirmUserInput'>
  >;
  ContactsBatchRemove?: Resolver<
    Array<ResolversTypes['ContactBatchResultModel']>,
    ParentType,
    ContextType,
    RequireFields<MutationContactsBatchRemoveArgs, 'ids'>
  >;
  ContactsBatchUpdate?: Resolver<
    Array<ResolversTypes['ContactBatchResultModel']>,
    ParentType,
    ContextType,
    RequireFields<MutationContactsBatchUpdateArgs, 'updateContactBatchInput'>
  >;
  CreateAdditionalField?: Resolver<
    ResolversTypes['AdditionalFieldModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateAdditionalFieldArgs, 'createAdditionalFieldInput'>
  >;
  CreateAddressType?: Resolver<
    ResolversTypes['AddressTypeModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateAddressTypeArgs, 'createAddressTypeInput'>
  >;
  CreateAttachmentType?: Resolver<
    ResolversTypes['AttachmentTypeModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateAttachmentTypeArgs, 'createAttachmentTypeInput'>
  >;
  CreateBuyGroup?: Resolver<
    ResolversTypes['BuyGroupModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateBuyGroupArgs, 'createBuyGroupInput'>
  >;
  CreateCallPattern?: Resolver<
    ResolversTypes['CallPatternModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCallPatternArgs, 'createCompanyCallPatternInput'>
  >;
  CreateColumn?: Resolver<
    ResolversTypes['ColumnModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateColumnArgs, 'createColumnInput'>
  >;
  CreateCompany?: Resolver<
    ResolversTypes['CompanyModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanyArgs, 'createCompanyInput'>
  >;
  CreateCompanyAdditionalField?: Resolver<
    ResolversTypes['CompanyAdditionalFieldModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanyAdditionalFieldArgs, 'createCompanyAdditionalFieldInput'>
  >;
  CreateCompanyAttachment?: Resolver<
    ResolversTypes['CompanyAttachmentModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanyAttachmentArgs, 'createCompanyAttachmentInput'>
  >;
  CreateCompanyCategory?: Resolver<
    ResolversTypes['CompanyCategoryDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanyCategoryArgs, 'createCompanyCategoryInput'>
  >;
  CreateCompanyClass?: Resolver<
    ResolversTypes['CompanyClassDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanyClassArgs, 'createCompanyClassInput'>
  >;
  CreateCompanyDivision?: Resolver<
    ResolversTypes['CompanyDivisionModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanyDivisionArgs, 'createCompanyDivisionInput'>
  >;
  CreateCompanyDocNotes?: Resolver<
    ResolversTypes['CompanyDocNotesModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanyDocNotesArgs, 'createCompanyDocNotesInput'>
  >;
  CreateCompanyPitch?: Resolver<
    ResolversTypes['CompanyPitchesModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanyPitchArgs, 'createCompanyPitchInput'>
  >;
  CreateCompanyRank?: Resolver<
    ResolversTypes['CompanyRankDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanyRankArgs, 'createCompanyRankInput'>
  >;
  CreateCompanySalesTeam?: Resolver<
    ResolversTypes['CompanySalesTeamModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanySalesTeamArgs, 'createCompanySalesTeamInput'>
  >;
  CreateCompanySplitType?: Resolver<
    ResolversTypes['CompanySplitTypesModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanySplitTypeArgs, 'createCompanySplitTypesInput'>
  >;
  CreateCompanyTag?: Resolver<
    ResolversTypes['CompanyTagsModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanyTagArgs, 'createCompanyTagInput'>
  >;
  CreateCompanyTier?: Resolver<
    ResolversTypes['CompanyTierModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanyTierArgs, 'createCompanyTierInput'>
  >;
  CreateCompanyTypeAlias?: Resolver<
    ResolversTypes['CompanyTypeAliasModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCompanyTypeAliasArgs, 'createCompanyTypeAliasInput'>
  >;
  CreateContact?: Resolver<
    ResolversTypes['ContactOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateContactArgs, 'createContactInput'>
  >;
  CreateContactAdditionalFieldsContact?: Resolver<
    ResolversTypes['ContactAdditionalFieldsContactModel'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateContactAdditionalFieldsContactArgs,
      'createContactAdditionalFieldsContactInput'
    >
  >;
  CreateContactGroup?: Resolver<
    ResolversTypes['ContactGroupModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateContactGroupArgs, 'createContactGroupInput'>
  >;
  CreateContactGroupContact?: Resolver<
    ResolversTypes['ContactGroupContactModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateContactGroupContactArgs, 'createContactGroupContactInput'>
  >;
  CreateContinent?: Resolver<
    ResolversTypes['ContinentDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateContinentArgs, 'createContinentsInput'>
  >;
  CreateCountry?: Resolver<
    ResolversTypes['CountryDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCountryArgs, 'createCountryInput'>
  >;
  CreateCustomerPriceCode?: Resolver<
    ResolversTypes['CustomerPriceCodesModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCustomerPriceCodeArgs, 'createCustomerPriceCodesInput'>
  >;
  CreateCustomerPriceGroup?: Resolver<
    ResolversTypes['CustomerPriceGroupsModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateCustomerPriceGroupArgs, 'createCustomerPriceGroupsInput'>
  >;
  CreateDepartment?: Resolver<
    ResolversTypes['DepartmentModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateDepartmentArgs, 'createDepartmentInput'>
  >;
  CreateDivision?: Resolver<
    ResolversTypes['DivisionDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateDivisionArgs, 'createDivisionInput'>
  >;
  CreateEmailServer?: Resolver<
    ResolversTypes['EmailServerModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateEmailServerArgs, 'createEmailServerInput'>
  >;
  CreateEmailType?: Resolver<
    ResolversTypes['EmailTypeModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateEmailTypeArgs, 'createEmailTypeInput'>
  >;
  CreateGridPreference?: Resolver<
    ResolversTypes['GridPreferenceModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateGridPreferenceArgs, 'createGridPreferenceInput'>
  >;
  CreateIndustry?: Resolver<
    ResolversTypes['CompanyIndustryDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateIndustryArgs, 'createCompanyIndustryInput'>
  >;
  CreateLeadSource?: Resolver<
    ResolversTypes['LeadSourceOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateLeadSourceArgs, 'createLeadSourceInput'>
  >;
  CreateOpportunities?: Resolver<
    ResolversTypes['OpportunitiesDto'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateOpportunitiesArgs, 'createOpportunitiesInput'>
  >;
  CreateOpportunityCloseReason?: Resolver<
    ResolversTypes['CloseReasonOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateOpportunityCloseReasonArgs, 'createCloseReasonInput'>
  >;
  CreateOpportunityFailedReason?: Resolver<
    ResolversTypes['FailedReasonOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateOpportunityFailedReasonArgs, 'createFailedReasonInput'>
  >;
  CreateOpportunityLineItemStatus?: Resolver<
    ResolversTypes['LineItemStatusOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateOpportunityLineItemStatusArgs, 'createLineItemStatusInput'>
  >;
  CreateOpportunityStage?: Resolver<
    ResolversTypes['StageOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateOpportunityStageArgs, 'createStageInput'>
  >;
  CreateOpportunityStatus?: Resolver<
    ResolversTypes['StatusOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateOpportunityStatusArgs, 'createOpportunityStatusInput'>
  >;
  CreateOpportunityType?: Resolver<
    ResolversTypes['TypeOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateOpportunityTypeArgs, 'createOpportunityTypeInput'>
  >;
  CreatePhoneType?: Resolver<
    ResolversTypes['PhoneTypeModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreatePhoneTypeArgs, 'createPhoneTypeInput'>
  >;
  CreatePrivateTeam?: Resolver<
    ResolversTypes['PrivateTeamModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreatePrivateTeamArgs, 'createPrivateTeamInput'>
  >;
  CreatePrivateTeamType?: Resolver<
    ResolversTypes['PrivateTeamTypeModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreatePrivateTeamTypeArgs, 'createPrivateTeamTypeInput'>
  >;
  CreateProductInterest?: Resolver<
    ResolversTypes['ProductInterestModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateProductInterestArgs, 'createProductInterestInput'>
  >;
  CreateProductPotential?: Resolver<
    ResolversTypes['CompanyProductPotentialModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateProductPotentialArgs, 'createProductPotentialInput'>
  >;
  CreateRegion?: Resolver<
    ResolversTypes['RegionModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateRegionArgs, 'createRegionInput'>
  >;
  CreateRelatedCategory?: Resolver<
    ResolversTypes['RelatedCategoryModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateRelatedCategoryArgs, 'relatedCategoryTerritoryInput'>
  >;
  CreateRelatedCompany?: Resolver<
    ResolversTypes['RelatedCompanyDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateRelatedCompanyArgs, 'createRelatedCompanyInput'>
  >;
  CreateRole?: Resolver<
    ResolversTypes['Role'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateRoleArgs, 'createRoleInput'>
  >;
  CreateSalesTeam?: Resolver<
    ResolversTypes['SalesTeamModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateSalesTeamArgs, 'createSalesTeamInput'>
  >;
  CreateSalesTeamType?: Resolver<
    ResolversTypes['SalesTeamTypesModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateSalesTeamTypeArgs, 'createSalesTeamTypesInput'>
  >;
  CreateStageGroup?: Resolver<
    ResolversTypes['StageGroupOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateStageGroupArgs, 'createStageGroupInput'>
  >;
  CreateState?: Resolver<
    ResolversTypes['StateDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateStateArgs, 'createStateInput'>
  >;
  CreateTerritory?: Resolver<
    ResolversTypes['TerritoryDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateTerritoryArgs, 'createTerritoryInput'>
  >;
  CreateUser?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserArgs, 'createUserInput'>
  >;
  CreateUserAdditionalField?: Resolver<
    ResolversTypes['UserAdditionalFieldModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserAdditionalFieldArgs, 'createUserAdditionalFieldInput'>
  >;
  CreateUserAdditionalFieldsUser?: Resolver<
    ResolversTypes['UserAdditionalFieldsUserModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserAdditionalFieldsUserArgs, 'createUserAdditionalFieldsUserInput'>
  >;
  CreateUserLandingWidget?: Resolver<
    ResolversTypes['UserLandingWidget'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserLandingWidgetArgs, 'CreateUserLandingWidget'>
  >;
  CreateUserMenu?: Resolver<
    ResolversTypes['UserMenuModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserMenuArgs, 'createUserMenuInput'>
  >;
  CreateUserReportList?: Resolver<
    ResolversTypes['ReportListModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserReportListArgs, 'createReportListInput'>
  >;
  CreateUserReportPreference?: Resolver<
    ResolversTypes['UserReportPreference'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserReportPreferenceArgs, 'createUserReportPreferenceInput'>
  >;
  CreateUserReportWidgets?: Resolver<
    ResolversTypes['UserReportWidgetList'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserReportWidgetsArgs, 'createUserReportWidgetInput'>
  >;
  CreateUserRole?: Resolver<
    ResolversTypes['UserRoleModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserRoleArgs, 'createUserRoleInput'>
  >;
  CreateUserType?: Resolver<
    ResolversTypes['UserTypeModel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserTypeArgs, 'createUserTypeInput'>
  >;
  DeleteColumn?: Resolver<
    ResolversTypes['DeletedOkModel'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteColumnArgs, 'id'>
  >;
  DeleteOneUserLandingWidget?: Resolver<
    ResolversTypes['UserLandingWidget'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteOneUserLandingWidgetArgs, 'id'>
  >;
  DeleteOneUserReportWidgetList?: Resolver<
    ResolversTypes['UserReportWidgetList'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteOneUserReportWidgetListArgs, 'id'>
  >;
  DisableRole?: Resolver<
    ResolversTypes['Role'],
    ParentType,
    ContextType,
    RequireFields<MutationDisableRoleArgs, 'id'>
  >;
  DisableUser?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationDisableUserArgs, 'id'>
  >;
  EnableRole?: Resolver<
    ResolversTypes['Role'],
    ParentType,
    ContextType,
    RequireFields<MutationEnableRoleArgs, 'id'>
  >;
  EnableUser?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationEnableUserArgs, 'id'>
  >;
  FindRoles?: Resolver<
    Array<ResolversTypes['Role']>,
    ParentType,
    ContextType,
    RequireFields<MutationFindRolesArgs, 'findRolesInput'>
  >;
  FindUsers?: Resolver<
    Array<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<MutationFindUsersArgs, 'findUsersInput'>
  >;
  MergeCompanyTo?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationMergeCompanyToArgs, 'mergeCompanyInput'>
  >;
  RegisterUser?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRegisterUserArgs, 'registerUserInput'>
  >;
  RemoveAdditionalField?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveAdditionalFieldArgs, 'id'>
  >;
  RemoveAddressType?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveAddressTypeArgs, 'id'>
  >;
  RemoveAttachmentType?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveAttachmentTypeArgs, 'id'>
  >;
  RemoveBuyGroup?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveBuyGroupArgs, 'id'>
  >;
  RemoveCallPattern?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCallPatternArgs, 'id'>
  >;
  RemoveCompany?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanyArgs, 'id'>
  >;
  RemoveCompanyAdditionalField?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanyAdditionalFieldArgs, 'id'>
  >;
  RemoveCompanyAddress?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanyAddressArgs, 'id'>
  >;
  RemoveCompanyAttachment?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanyAttachmentArgs, 'id'>
  >;
  RemoveCompanyCategory?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanyCategoryArgs, 'id'>
  >;
  RemoveCompanyClass?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanyClassArgs, 'id'>
  >;
  RemoveCompanyDivision?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanyDivisionArgs, 'id'>
  >;
  RemoveCompanyDocNotes?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanyDocNotesArgs, 'id'>
  >;
  RemoveCompanyFromElastic?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanyFromElasticArgs, 'id'>
  >;
  RemoveCompanyPhone?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanyPhoneArgs, 'id'>
  >;
  RemoveCompanyPitch?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanyPitchArgs, 'id'>
  >;
  RemoveCompanyRank?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanyRankArgs, 'id'>
  >;
  RemoveCompanySalesTeam?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanySalesTeamArgs, 'id'>
  >;
  RemoveCompanySplitType?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanySplitTypeArgs, 'id'>
  >;
  RemoveCompanyTag?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanyTagArgs, 'id'>
  >;
  RemoveCompanyTier?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanyTierArgs, 'id'>
  >;
  RemoveCompanyTypeAlias?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCompanyTypeAliasArgs, 'id'>
  >;
  RemoveContact?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveContactArgs, 'id'>
  >;
  RemoveContactAdditionalFieldsContact?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveContactAdditionalFieldsContactArgs, 'id'>
  >;
  RemoveContactGroup?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveContactGroupArgs, 'id'>
  >;
  RemoveContactGroupContact?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveContactGroupContactArgs, 'id'>
  >;
  RemoveContinent?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveContinentArgs, 'id'>
  >;
  RemoveCountry?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCountryArgs, 'id'>
  >;
  RemoveCustomerPriceCode?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCustomerPriceCodeArgs, 'id'>
  >;
  RemoveCustomerPriceGroup?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveCustomerPriceGroupArgs, 'id'>
  >;
  RemoveDepartment?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveDepartmentArgs, 'id'>
  >;
  RemoveDivision?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveDivisionArgs, 'id'>
  >;
  RemoveEmailServer?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveEmailServerArgs, 'id'>
  >;
  RemoveEmailType?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveEmailTypeArgs, 'id'>
  >;
  RemoveGridPreference?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveGridPreferenceArgs, 'id'>
  >;
  RemoveIndustry?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveIndustryArgs, 'id'>
  >;
  RemoveLeadSource?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveLeadSourceArgs, 'id'>
  >;
  RemoveOpportunities?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveOpportunitiesArgs, 'id'>
  >;
  RemoveOpportunityCloseReason?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveOpportunityCloseReasonArgs, 'id'>
  >;
  RemoveOpportunityFailedReason?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveOpportunityFailedReasonArgs, 'id'>
  >;
  RemoveOpportunityLineItemStatus?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveOpportunityLineItemStatusArgs, 'id'>
  >;
  RemoveOpportunityStage?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveOpportunityStageArgs, 'id'>
  >;
  RemoveOpportunityStatus?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveOpportunityStatusArgs, 'id'>
  >;
  RemoveOpportunityType?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveOpportunityTypeArgs, 'id'>
  >;
  RemovePhoneType?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemovePhoneTypeArgs, 'id'>
  >;
  RemovePrivateTeam?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemovePrivateTeamArgs, 'id'>
  >;
  RemovePrivateTeamType?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemovePrivateTeamTypeArgs, 'id'>
  >;
  RemoveProductInterest?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveProductInterestArgs, 'id'>
  >;
  RemoveProductPotential?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveProductPotentialArgs, 'id'>
  >;
  RemoveRegion?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveRegionArgs, 'id'>
  >;
  RemoveRelatedCategory?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveRelatedCategoryArgs, 'id'>
  >;
  RemoveRelatedCompany?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveRelatedCompanyArgs, 'id'>
  >;
  RemoveSalesTeam?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveSalesTeamArgs, 'id'>
  >;
  RemoveSalesTeamMember?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveSalesTeamMemberArgs, 'salesTeamId' | 'tenantId' | 'users'>
  >;
  RemoveSalesTeamType?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveSalesTeamTypeArgs, 'id'>
  >;
  RemoveStageGroup?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveStageGroupArgs, 'id'>
  >;
  RemoveState?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveStateArgs, 'id'>
  >;
  RemoveTenantInstance?: Resolver<
    ResolversTypes['TenantInstance'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveTenantInstanceArgs, 'id'>
  >;
  RemoveTerritory?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveTerritoryArgs, 'id'>
  >;
  RemoveUser?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveUserArgs, 'id'>
  >;
  RemoveUserAdditionalField?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveUserAdditionalFieldArgs, 'id'>
  >;
  RemoveUserAdditionalFieldsUser?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveUserAdditionalFieldsUserArgs, 'id'>
  >;
  RemoveUserMenu?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveUserMenuArgs, 'id'>
  >;
  RemoveUserReportListModel?: Resolver<
    ResolversTypes['ReportListModel'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveUserReportListModelArgs, 'id'>
  >;
  RemoveUserReportPreference?: Resolver<
    ResolversTypes['UserReportPreference'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveUserReportPreferenceArgs, 'id'>
  >;
  RemoveUserRole?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveUserRoleArgs, 'id'>
  >;
  RemoveUserType?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveUserTypeArgs, 'id'>
  >;
  SetDefaultGridPreference?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<MutationSetDefaultGridPreferenceArgs, 'id'>
  >;
  UpdateAdditionalField?: Resolver<
    ResolversTypes['AdditionalFieldModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAdditionalFieldArgs, 'updateAdditionalFieldInput'>
  >;
  UpdateAddressType?: Resolver<
    ResolversTypes['AddressTypeModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAddressTypeArgs, 'updateAddressTypeInput'>
  >;
  UpdateAttachmentType?: Resolver<
    ResolversTypes['AttachmentTypeModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAttachmentTypeArgs, 'updateAttachmentTypeInput'>
  >;
  UpdateBuyGroup?: Resolver<
    ResolversTypes['BuyGroupModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateBuyGroupArgs, 'updateBuyGroupInput'>
  >;
  UpdateCallPattern?: Resolver<
    ResolversTypes['CallPatternModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCallPatternArgs, 'updateCallPatternInput'>
  >;
  UpdateColumn?: Resolver<
    ResolversTypes['ColumnModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateColumnArgs, 'updateColumnInput'>
  >;
  UpdateCompanies?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompaniesArgs, 'Data'>
  >;
  UpdateCompaniesInline?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompaniesInlineArgs, 'data'>
  >;
  UpdateCompany?: Resolver<
    ResolversTypes['CompanyModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanyArgs, 'updateCompanyInput'>
  >;
  UpdateCompanyAdditionalField?: Resolver<
    ResolversTypes['CompanyAdditionalFieldModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanyAdditionalFieldArgs, 'updateCompanyAdditionalFieldInput'>
  >;
  UpdateCompanyAttachment?: Resolver<
    ResolversTypes['CompanyAttachmentModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanyAttachmentArgs, 'updateCompanyAttachmentInput'>
  >;
  UpdateCompanyCategory?: Resolver<
    ResolversTypes['CompanyCategoryDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanyCategoryArgs, 'updateCompanyCategoryInput'>
  >;
  UpdateCompanyClass?: Resolver<
    ResolversTypes['CompanyClassDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanyClassArgs, 'updateCompanyClassInput'>
  >;
  UpdateCompanyDivision?: Resolver<
    ResolversTypes['CompanyDivisionModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanyDivisionArgs, 'updateCompanyDivisionInput'>
  >;
  UpdateCompanyDocNotes?: Resolver<
    ResolversTypes['CompanyDocNotesModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanyDocNotesArgs, 'updateCompanyDocNotesInput'>
  >;
  UpdateCompanyPitch?: Resolver<
    ResolversTypes['CompanyPitchesModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanyPitchArgs, 'updateCompanyPitchInput'>
  >;
  UpdateCompanyRank?: Resolver<
    ResolversTypes['CompanyRankDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanyRankArgs, 'updateCompanyRankInput'>
  >;
  UpdateCompanySalesTeam?: Resolver<
    ResolversTypes['CompanySalesTeamModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanySalesTeamArgs, 'updateCompanySalesTeamInput'>
  >;
  UpdateCompanySplitType?: Resolver<
    ResolversTypes['CompanySplitTypesModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanySplitTypeArgs, 'updateCompanySplitTypesInput'>
  >;
  UpdateCompanyTag?: Resolver<
    ResolversTypes['CompanyTagsModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanyTagArgs, 'updateCompanyTagInput'>
  >;
  UpdateCompanyTier?: Resolver<
    ResolversTypes['CompanyTierModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanyTierArgs, 'updateCompanyTierInput'>
  >;
  UpdateCompanyTypeAlias?: Resolver<
    ResolversTypes['CompanyTypeAliasModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanyTypeAliasArgs, 'updateCompanyTypeAliasInput'>
  >;
  UpdateContact?: Resolver<
    ResolversTypes['ContactOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateContactArgs, 'updateContactInput'>
  >;
  UpdateContactAdditionalField?: Resolver<
    ResolversTypes['ContactAdditionalFieldsContactModel'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateContactAdditionalFieldArgs,
      'updateContactAdditionalFieldsContactInput'
    >
  >;
  UpdateContactGroup?: Resolver<
    ResolversTypes['ContactGroupModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateContactGroupArgs, 'updateContactGroupInput'>
  >;
  UpdateContactGroupContact?: Resolver<
    ResolversTypes['ContactGroupContactModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateContactGroupContactArgs, 'updateContactGroupContactInput'>
  >;
  UpdateContinent?: Resolver<
    ResolversTypes['ContinentDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateContinentArgs, 'updateContinentsInput'>
  >;
  UpdateCountry?: Resolver<
    ResolversTypes['CountryDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCountryArgs, 'updateCountryInput'>
  >;
  UpdateCustomerPriceCode?: Resolver<
    ResolversTypes['CustomerPriceCodesModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCustomerPriceCodeArgs, 'updateCustomerPriceCodesInput'>
  >;
  UpdateCustomerPriceGroup?: Resolver<
    ResolversTypes['CustomerPriceGroupsModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCustomerPriceGroupArgs, 'updateCustomerPriceGroupsInput'>
  >;
  UpdateDepartment?: Resolver<
    ResolversTypes['DepartmentModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDepartmentArgs, 'updateDepartmentInput'>
  >;
  UpdateDivision?: Resolver<
    ResolversTypes['DivisionDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDivisionArgs, 'updateDivisionInput'>
  >;
  UpdateEmailServer?: Resolver<
    ResolversTypes['EmailServerModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateEmailServerArgs, 'updateEmailServerInput'>
  >;
  UpdateEmailType?: Resolver<
    ResolversTypes['EmailTypeModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateEmailTypeArgs, 'updateEmailTypeInput'>
  >;
  UpdateGridPreference?: Resolver<
    ResolversTypes['GridPreferenceModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateGridPreferenceArgs, 'updateGridPreferenceInput'>
  >;
  UpdateIndustry?: Resolver<
    ResolversTypes['CompanyIndustryDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateIndustryArgs, 'updateCompanyIndustryInput'>
  >;
  UpdateLeadSource?: Resolver<
    ResolversTypes['LeadSourceOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateLeadSourceArgs, 'updateLeadSourceInput'>
  >;
  UpdateOneTenantInstance?: Resolver<
    ResolversTypes['TenantInstance'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateOneTenantInstanceArgs, 'updateTenantInstanceInput'>
  >;
  UpdateOpportunities?: Resolver<
    ResolversTypes['OpportunitiesDto'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateOpportunitiesArgs, 'updateStageInput'>
  >;
  UpdateOpportunityCloseReason?: Resolver<
    ResolversTypes['CloseReasonOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateOpportunityCloseReasonArgs, 'updateCloseReasonInput'>
  >;
  UpdateOpportunityFailedReason?: Resolver<
    ResolversTypes['FailedReasonOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateOpportunityFailedReasonArgs, 'updateFailedReasonInput'>
  >;
  UpdateOpportunityLineItemStatus?: Resolver<
    ResolversTypes['LineItemStatusOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateOpportunityLineItemStatusArgs, 'updateLineItemStatusInput'>
  >;
  UpdateOpportunityStage?: Resolver<
    ResolversTypes['StageOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateOpportunityStageArgs, 'updateStageInput'>
  >;
  UpdateOpportunityStatus?: Resolver<
    ResolversTypes['StatusOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateOpportunityStatusArgs, 'updateStatusInput'>
  >;
  UpdateOpportunityType?: Resolver<
    ResolversTypes['TypeOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateOpportunityTypeArgs, 'updateOpportunityTypeInput'>
  >;
  UpdatePhoneType?: Resolver<
    ResolversTypes['PhoneTypeModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePhoneTypeArgs, 'updatePhoneTypeInput'>
  >;
  UpdatePrivateTeam?: Resolver<
    ResolversTypes['PrivateTeamModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePrivateTeamArgs, 'updatePrivateTeamInput'>
  >;
  UpdatePrivateTeamType?: Resolver<
    ResolversTypes['PrivateTeamTypeModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePrivateTeamTypeArgs, 'updatePrivateTeamTypeInput'>
  >;
  UpdateProductInterest?: Resolver<
    ResolversTypes['ProductInterestModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateProductInterestArgs, 'updateProductInterestInput'>
  >;
  UpdateProductPotential?: Resolver<
    ResolversTypes['CompanyProductPotentialModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateProductPotentialArgs, 'updateProductPotentialInput'>
  >;
  UpdateRegion?: Resolver<
    ResolversTypes['RegionModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateRegionArgs, 'updateRegionInput'>
  >;
  UpdateRelatedCategory?: Resolver<
    ResolversTypes['RelatedCategoryModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateRelatedCategoryArgs, 'updateRelatedCategoryInput'>
  >;
  UpdateRelatedCompany?: Resolver<
    ResolversTypes['RelatedCompanyDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateRelatedCompanyArgs, 'updateRelatedCompanyInput'>
  >;
  UpdateRole?: Resolver<
    ResolversTypes['Role'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateRoleArgs, 'updateRoleInput'>
  >;
  UpdateSalesTeam?: Resolver<
    ResolversTypes['SalesTeamModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSalesTeamArgs, 'updateSalesTeamInput'>
  >;
  UpdateSalesTeamType?: Resolver<
    ResolversTypes['SalesTeamTypesModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateSalesTeamTypeArgs, 'updateSalesTeamTypesInput'>
  >;
  UpdateStageGroup?: Resolver<
    ResolversTypes['StageGroupOutputDto'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateStageGroupArgs, 'updateStageGroupInput'>
  >;
  UpdateState?: Resolver<
    ResolversTypes['StateDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateStateArgs, 'updateStateInput'>
  >;
  UpdateTerritory?: Resolver<
    ResolversTypes['TerritoryDBModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTerritoryArgs, 'updateTerritoryInput'>
  >;
  UpdateUser?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserArgs, 'updateUserInput'>
  >;
  UpdateUserAdditionalField?: Resolver<
    ResolversTypes['UserAdditionalFieldModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserAdditionalFieldArgs, 'updateUserAdditionalFieldInput'>
  >;
  UpdateUserAdditionalFieldsUser?: Resolver<
    ResolversTypes['UserAdditionalFieldsUserModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserAdditionalFieldsUserArgs, 'updateUserAdditionalFieldsUserInput'>
  >;
  UpdateUserLandingWidget?: Resolver<
    ResolversTypes['UserLandingWidget'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserLandingWidgetArgs, 'UpdateLandingWidgetInput'>
  >;
  UpdateUserMenu?: Resolver<
    ResolversTypes['UserMenuModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserMenuArgs, 'updateUserMenuInput'>
  >;
  UpdateUserReportListModel?: Resolver<
    ResolversTypes['ReportListModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserReportListModelArgs, 'updateReportListInput'>
  >;
  UpdateUserReportPreference?: Resolver<
    ResolversTypes['UserReportPreference'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserReportPreferenceArgs, 'updateUserReportPreferenceInput'>
  >;
  UpdateUserReportWidgetList?: Resolver<
    ResolversTypes['UserReportWidgetList'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserReportWidgetListArgs, 'updateUserReportWidgetInput'>
  >;
  UpdateUserRole?: Resolver<
    ResolversTypes['UserRoleModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserRoleArgs, 'updateUserRoleInput'>
  >;
  UpdateUserType?: Resolver<
    ResolversTypes['UserTypeModel'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserTypeArgs, 'updateUserTypeInput'>
  >;
  UploadLogo?: Resolver<
    ResolversTypes['TenantInstance'],
    ParentType,
    ContextType,
    RequireFields<MutationUploadLogoArgs, 'file'>
  >;
  createAnalyticsWideSale?: Resolver<
    ResolversTypes['AnalyticsWideSale'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateAnalyticsWideSaleArgs, 'createAnalyticsWideSaleInput'>
  >;
  createTenantInstance?: Resolver<
    ResolversTypes['TenantInstance'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateTenantInstanceArgs, 'createTenantInstanceInput'>
  >;
  registerSalesTeamUser?: Resolver<
    Array<ResolversTypes['RegisterUserSalesTeamModel']>,
    ParentType,
    ContextType,
    RequireFields<MutationRegisterSalesTeamUserArgs, 'createSalesTeamInput'>
  >;
  removeAnalyticsWideSale?: Resolver<
    ResolversTypes['AnalyticsWideSale'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveAnalyticsWideSaleArgs, 'id'>
  >;
  updateAnalyticsWideSale?: Resolver<
    ResolversTypes['AnalyticsWideSale'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAnalyticsWideSaleArgs, 'updateAnalyticsWideSaleInput'>
  >;
}>;

export type OppOwnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OppOwner'] = ResolversParentTypes['OppOwner'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OppStageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OppStage'] = ResolversParentTypes['OppStage'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OppStatusResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OppStatus'] = ResolversParentTypes['OppStatus'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OpportunitiesCompanyOutputDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OpportunitiesCompanyOutputDto'] = ResolversParentTypes['OpportunitiesCompanyOutputDto'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OpportunitiesContactOutputDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OpportunitiesContactOutputDto'] = ResolversParentTypes['OpportunitiesContactOutputDto'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OpportunitiesDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OpportunitiesDto'] = ResolversParentTypes['OpportunitiesDto'],
> = ResolversObject<{
  bidDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cityId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  closeReasonId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  closeStatusId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customers?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  customersContacts?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  distributors?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  distributorsContacts?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  estimatedCloseDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followUp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  leadSourceId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  manufacturerContacts?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  manufacturers?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  nextStep?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opportunityStageId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  opportunityStatusId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  productLineId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  salesTeamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  stateId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  topic?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OpportunitiesElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OpportunitiesElasticResponse'] = ResolversParentTypes['OpportunitiesElasticResponse'],
> = ResolversObject<{
  results?: Resolver<
    Maybe<Array<ResolversTypes['OpportunitiesOutputDto']>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OpportunitiesOutputDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OpportunitiesOutputDto'] = ResolversParentTypes['OpportunitiesOutputDto'],
> = ResolversObject<{
  bid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  closedReason?: Resolver<Maybe<ResolversTypes['ClosedReason']>, ParentType, ContextType>;
  closedStatus?: Resolver<Maybe<ResolversTypes['ClosedStatus']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<
    Maybe<Array<ResolversTypes['OpportunitiesCompanyOutputDto']>>,
    ParentType,
    ContextType
  >;
  customerContact?: Resolver<
    Maybe<Array<ResolversTypes['OpportunitiesContactOutputDto']>>,
    ParentType,
    ContextType
  >;
  distributor?: Resolver<
    Maybe<Array<ResolversTypes['OpportunitiesCompanyOutputDto']>>,
    ParentType,
    ContextType
  >;
  distributorContact?: Resolver<
    Maybe<Array<ResolversTypes['OpportunitiesContactOutputDto']>>,
    ParentType,
    ContextType
  >;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  estimatedCloseDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followUp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  leadSource?: Resolver<Maybe<ResolversTypes['LeadSource']>, ParentType, ContextType>;
  manufacturer?: Resolver<
    Maybe<Array<ResolversTypes['OpportunitiesCompanyOutputDto']>>,
    ParentType,
    ContextType
  >;
  manufacturerContact?: Resolver<
    Maybe<Array<ResolversTypes['OpportunitiesContactOutputDto']>>,
    ParentType,
    ContextType
  >;
  nextStep?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oppOwner?: Resolver<Maybe<ResolversTypes['OppOwner']>, ParentType, ContextType>;
  oppStage?: Resolver<Maybe<ResolversTypes['OppStage']>, ParentType, ContextType>;
  oppStatus?: Resolver<Maybe<ResolversTypes['OppStatus']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  productLine?: Resolver<Maybe<ResolversTypes['ProductLine']>, ParentType, ContextType>;
  salesTeam?: Resolver<Maybe<ResolversTypes['SalesTeam']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['State']>, ParentType, ContextType>;
  topic?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Type']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OptionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Options'] = ResolversParentTypes['Options'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PhoneDbModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PhoneDBModel'] = ResolversParentTypes['PhoneDBModel'],
> = ResolversObject<{
  areaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  extension?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneType?: Resolver<Maybe<ResolversTypes['PhoneTypeModel']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PhoneFastResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PhoneFast'] = ResolversParentTypes['PhoneFast'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PhoneTypeElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PhoneTypeElasticResponse'] = ResolversParentTypes['PhoneTypeElasticResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['PhoneTypeModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PhoneTypeModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PhoneTypeModel'] = ResolversParentTypes['PhoneTypeModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isCompany?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isContact?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PrimaryCustomerWideSalesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PrimaryCustomerWideSales'] = ResolversParentTypes['PrimaryCustomerWideSales'],
> = ResolversObject<{
  monthlySales?: Resolver<Maybe<Array<ResolversTypes['SalesByMonth']>>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PrivateTeamModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PrivateTeamModel'] = ResolversParentTypes['PrivateTeamModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['PrivateTeamTypeModel']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PrivateTeamResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PrivateTeamResponse'] = ResolversParentTypes['PrivateTeamResponse'],
> = ResolversObject<{
  results?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['PrivateTeamModel']>>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PrivateTeamTypeModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PrivateTeamTypeModel'] = ResolversParentTypes['PrivateTeamTypeModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductInterestElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductInterestElasticResponse'] = ResolversParentTypes['ProductInterestElasticResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['ProductInterestModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductInterestModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductInterestModel'] = ResolversParentTypes['ProductInterestModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductLineResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductLine'] = ResolversParentTypes['ProductLine'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProductPotentialResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductPotentialResponse'] = ResolversParentTypes['ProductPotentialResponse'],
> = ResolversObject<{
  results?: Resolver<
    Maybe<Array<ResolversTypes['CompanyProductPotentialModel']>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = ResolversObject<{
  CreateCompanyExport?: Resolver<
    ResolversTypes['ExportRequestResponseDTO'],
    ParentType,
    ContextType,
    RequireFields<QueryCreateCompanyExportArgs, 'Criteria' | 'FileFormat'>
  >;
  ExportContacts2Nest?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QueryExportContacts2NestArgs, 'Criteria'>
  >;
  FindOneCompanyFromElastic?: Resolver<
    ResolversTypes['CompanyElasticModel'],
    ParentType,
    ContextType,
    RequireFields<QueryFindOneCompanyFromElasticArgs, 'id'>
  >;
  FindOneCompanyFromElastic2?: Resolver<
    Maybe<ResolversTypes['CompanyModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindOneCompanyFromElastic2Args, 'id'>
  >;
  FindOneUserLandingWidget?: Resolver<
    ResolversTypes['UserLandingWidget'],
    ParentType,
    ContextType,
    RequireFields<QueryFindOneUserLandingWidgetArgs, 'id'>
  >;
  FindOneUserReportListModel?: Resolver<
    ResolversTypes['ReportListModel'],
    ParentType,
    ContextType,
    RequireFields<QueryFindOneUserReportListModelArgs, 'id'>
  >;
  FindOneUserReportPreference?: Resolver<
    ResolversTypes['UserReportPreference'],
    ParentType,
    ContextType,
    RequireFields<QueryFindOneUserReportPreferenceArgs, 'id'>
  >;
  FindOneUserReportWidgetList?: Resolver<
    ResolversTypes['UserReportWidgetList'],
    ParentType,
    ContextType,
    RequireFields<QueryFindOneUserReportWidgetListArgs, 'id'>
  >;
  FindTenantInstances?: Resolver<
    Maybe<Array<ResolversTypes['TenantInstance']>>,
    ParentType,
    ContextType,
    RequireFields<QueryFindTenantInstancesArgs, 'findTenantInstanceInput'>
  >;
  FindUserLandingWidgetsByArgs?: Resolver<
    Array<ResolversTypes['UserLandingWidget']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindUserLandingWidgetsByArgsArgs, 'FindUserLandingWidget'>
  >;
  FindUserReportListModels?: Resolver<
    Maybe<Array<ResolversTypes['ReportListModel']>>,
    ParentType,
    ContextType,
    RequireFields<QueryFindUserReportListModelsArgs, 'FindReportListDto'>
  >;
  FindUserReportPreferenceByArgs?: Resolver<
    Array<ResolversTypes['UserReportPreference']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindUserReportPreferenceByArgsArgs, 'FindUserReportPreferenceByArgs'>
  >;
  FindUserReportWidgetListByArgs?: Resolver<
    Array<ResolversTypes['UserReportWidgetList']>,
    ParentType,
    ContextType,
    RequireFields<QueryFindUserReportWidgetListByArgsArgs, 'FindUserReportWidgetListByArgs'>
  >;
  ForgetSecurityToken?: Resolver<
    Maybe<ResolversTypes['SecurityTokenModel']>,
    ParentType,
    ContextType
  >;
  GetAdditionalField?: Resolver<
    Maybe<ResolversTypes['AdditionalFieldModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAdditionalFieldArgs, 'id'>
  >;
  GetAddressType?: Resolver<
    Maybe<ResolversTypes['AddressTypeModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAddressTypeArgs, 'id'>
  >;
  GetAddressTypeGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetAddressTypeUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAddressTypeUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetAllAdditionalFields?: Resolver<
    Maybe<ResolversTypes['AdditionalFieldsResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllAdditionalFieldsArgs, 'Criteria'>
  >;
  GetAllAddressType?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['AddressTypeModel']>>>,
    ParentType,
    ContextType
  >;
  GetAllAddressType2?: Resolver<
    ResolversTypes['AddressTypeElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllAddressType2Args, 'Criteria'>
  >;
  GetAllAddressTypeCompany?: Resolver<
    Maybe<Array<ResolversTypes['AddressTypeModel']>>,
    ParentType,
    ContextType
  >;
  GetAllAddressTypeContact?: Resolver<
    Maybe<Array<ResolversTypes['AddressTypeModel']>>,
    ParentType,
    ContextType
  >;
  GetAllAttachmentType?: Resolver<
    Maybe<Array<ResolversTypes['AttachmentTypeModel']>>,
    ParentType,
    ContextType
  >;
  GetAllBuyGroup?: Resolver<Maybe<Array<ResolversTypes['BuyGroupModel']>>, ParentType, ContextType>;
  GetAllBuyGroup2?: Resolver<
    Maybe<ResolversTypes['BuyingGroupResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllBuyGroup2Args, 'Criteria'>
  >;
  GetAllCallPattern?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CallPatternModel']>>>,
    ParentType,
    ContextType
  >;
  GetAllCallPattern2?: Resolver<
    Maybe<ResolversTypes['CallPatternResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllCallPattern2Args, 'Criteria'>
  >;
  GetAllColumns?: Resolver<
    Array<ResolversTypes['ColumnModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllColumnsArgs, 'reportId'>
  >;
  GetAllCompanies?: Resolver<Maybe<Array<ResolversTypes['CompanyModel']>>, ParentType, ContextType>;
  GetAllCompanyAdditionalField?: Resolver<
    Maybe<Array<ResolversTypes['CompanyAdditionalFieldModel']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllCompanyAdditionalFieldArgs, 'companyId'>
  >;
  GetAllCompanyAddresses?: Resolver<
    Maybe<Array<ResolversTypes['CompanyAddressModel']>>,
    ParentType,
    ContextType
  >;
  GetAllCompanyAddressesByCompany?: Resolver<
    Maybe<Array<ResolversTypes['CompanyAddressModel']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllCompanyAddressesByCompanyArgs, 'id'>
  >;
  GetAllCompanyAttachment?: Resolver<
    Maybe<Array<ResolversTypes['CompanyAttachmentModel']>>,
    ParentType,
    ContextType
  >;
  GetAllCompanyCategory?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CompanyCategoryDBModel']>>>,
    ParentType,
    ContextType
  >;
  GetAllCompanyCategory2?: Resolver<
    Maybe<ResolversTypes['CategoryResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllCompanyCategory2Args, 'Criteria'>
  >;
  GetAllCompanyClass?: Resolver<
    Array<ResolversTypes['CompanyClassDBModel']>,
    ParentType,
    ContextType
  >;
  GetAllCompanyDivision?: Resolver<
    Maybe<Array<ResolversTypes['CompanyDivisionModel']>>,
    ParentType,
    ContextType
  >;
  GetAllCompanyDocNotes?: Resolver<
    Maybe<Array<ResolversTypes['CompanyDocNotesModel']>>,
    ParentType,
    ContextType
  >;
  GetAllCompanyPhones?: Resolver<
    Maybe<Array<ResolversTypes['CompanyPhoneModel']>>,
    ParentType,
    ContextType
  >;
  GetAllCompanyPhonesByCompany?: Resolver<
    Maybe<Array<ResolversTypes['CompanyPhoneModel']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllCompanyPhonesByCompanyArgs, 'id'>
  >;
  GetAllCompanyPitches?: Resolver<
    Maybe<Array<ResolversTypes['CompanyPitchesModel']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllCompanyPitchesArgs, 'companyId'>
  >;
  GetAllCompanyRanks?: Resolver<
    Maybe<Array<ResolversTypes['CompanyRankDBModel']>>,
    ParentType,
    ContextType
  >;
  GetAllCompanyRanks2?: Resolver<
    Maybe<ResolversTypes['CompanyRankResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllCompanyRanks2Args, 'Criteria'>
  >;
  GetAllCompanySalesTeam?: Resolver<
    Maybe<Array<ResolversTypes['CompanySalesTeamModel']>>,
    ParentType,
    ContextType
  >;
  GetAllCompanySplitTypes?: Resolver<
    ResolversTypes['SplitTypeResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllCompanySplitTypesArgs, 'Criteria'>
  >;
  GetAllCompanyTags?: Resolver<
    Maybe<Array<ResolversTypes['CompanyTagsModel']>>,
    ParentType,
    ContextType
  >;
  GetAllCompanyTier?: Resolver<
    Maybe<Array<ResolversTypes['CompanyTierModel']>>,
    ParentType,
    ContextType
  >;
  GetAllCompanyTier2?: Resolver<
    Maybe<ResolversTypes['CompanyTierResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllCompanyTier2Args, 'Criteria'>
  >;
  GetAllCompanyTypeAlias?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CompanyTypeAliasModel']>>>,
    ParentType,
    ContextType
  >;
  GetAllCompanyTypeAlias2?: Resolver<
    Maybe<ResolversTypes['CompanyTypeModelElasticResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllCompanyTypeAlias2Args, 'Criteria'>
  >;
  GetAllContact?: Resolver<
    Maybe<Array<ResolversTypes['ContactOutputDto']>>,
    ParentType,
    ContextType
  >;
  GetAllContactAdditionalField?: Resolver<
    Maybe<Array<ResolversTypes['ContactAdditionalFieldsContactModel']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllContactAdditionalFieldArgs, 'contactId'>
  >;
  GetAllContactByCompanyId?: Resolver<
    Maybe<ResolversTypes['ContactGridResponseOutputDto']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllContactByCompanyIdArgs, 'Criteria' | 'companyId'>
  >;
  GetAllContactGroupContact?: Resolver<
    Maybe<Array<ResolversTypes['ContactGroupContactModel']>>,
    ParentType,
    ContextType
  >;
  GetAllContactGroupContactRelations?: Resolver<
    Maybe<Array<ResolversTypes['ContactGroupContactModel']>>,
    ParentType,
    ContextType
  >;
  GetAllContactGroups?: Resolver<
    Array<ResolversTypes['ContactGroupModel']>,
    ParentType,
    ContextType
  >;
  GetAllContactGroups2?: Resolver<
    ResolversTypes['ContactGroupElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllContactGroups2Args, 'Criteria'>
  >;
  GetAllContacts2Nest?: Resolver<
    Maybe<ResolversTypes['ContactGridResponseOutputDto']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllContacts2NestArgs, 'Criteria'>
  >;
  GetAllContactsForGrid?: Resolver<
    Maybe<ResolversTypes['ContactFastGridResponseOutputDto']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllContactsForGridArgs, 'Criteria'>
  >;
  GetAllContinents?: Resolver<
    Maybe<Array<ResolversTypes['ContinentDBModel']>>,
    ParentType,
    ContextType
  >;
  GetAllCountries?: Resolver<
    Maybe<Array<ResolversTypes['CountryDBModel']>>,
    ParentType,
    ContextType
  >;
  GetAllCountries2?: Resolver<
    ResolversTypes['CountryElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllCountries2Args, 'Criteria'>
  >;
  GetAllCustomerPriceCodes?: Resolver<
    Maybe<Array<ResolversTypes['CustomerPriceCodesModel']>>,
    ParentType,
    ContextType
  >;
  GetAllCustomerPriceGroups?: Resolver<
    Maybe<Array<ResolversTypes['CustomerPriceGroupsModel']>>,
    ParentType,
    ContextType
  >;
  GetAllDepartments?: Resolver<
    Maybe<Array<ResolversTypes['DepartmentModel']>>,
    ParentType,
    ContextType
  >;
  GetAllDivisions?: Resolver<
    Maybe<Array<ResolversTypes['DivisionDBModel']>>,
    ParentType,
    ContextType
  >;
  GetAllEmailServers?: Resolver<
    Maybe<Array<ResolversTypes['EmailServerModel']>>,
    ParentType,
    ContextType
  >;
  GetAllEmailTypes?: Resolver<
    Maybe<Array<ResolversTypes['EmailTypeModel']>>,
    ParentType,
    ContextType
  >;
  GetAllEmailTypes2?: Resolver<
    ResolversTypes['EmailTypeElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllEmailTypes2Args, 'Criteria'>
  >;
  GetAllGridPreferences?: Resolver<
    Maybe<Array<ResolversTypes['GridPreferenceModel']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllGridPreferencesArgs, 'gridName'>
  >;
  GetAllIndustries?: Resolver<
    Array<ResolversTypes['CompanyIndustryDBModel']>,
    ParentType,
    ContextType
  >;
  GetAllIndustries2?: Resolver<
    ResolversTypes['CompanyIndustryResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllIndustries2Args, 'Criteria'>
  >;
  GetAllLeadSources?: Resolver<
    ResolversTypes['LeadSourceElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllLeadSourcesArgs, 'Criteria'>
  >;
  GetAllOpportunities?: Resolver<
    ResolversTypes['OpportunitiesElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllOpportunitiesArgs, 'Criteria'>
  >;
  GetAllOpportunityCloseReasons?: Resolver<
    ResolversTypes['CloseReasonElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllOpportunityCloseReasonsArgs, 'Criteria'>
  >;
  GetAllOpportunityFailedReasons?: Resolver<
    ResolversTypes['FailedReasonElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllOpportunityFailedReasonsArgs, 'Criteria'>
  >;
  GetAllOpportunityLineItemStatus?: Resolver<
    ResolversTypes['LineItemStatusElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllOpportunityLineItemStatusArgs, 'Criteria'>
  >;
  GetAllOpportunityStages?: Resolver<
    ResolversTypes['StageElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllOpportunityStagesArgs, 'Criteria'>
  >;
  GetAllOpportunityStatus?: Resolver<
    ResolversTypes['StatusElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllOpportunityStatusArgs, 'Criteria'>
  >;
  GetAllOpportunityTypes?: Resolver<
    ResolversTypes['TypeElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllOpportunityTypesArgs, 'Criteria'>
  >;
  GetAllPhoneTypeCompany?: Resolver<
    Maybe<Array<ResolversTypes['PhoneTypeModel']>>,
    ParentType,
    ContextType
  >;
  GetAllPhoneTypeContact?: Resolver<
    Maybe<Array<ResolversTypes['PhoneTypeModel']>>,
    ParentType,
    ContextType
  >;
  GetAllPhoneTypes?: Resolver<
    Maybe<Array<ResolversTypes['PhoneTypeModel']>>,
    ParentType,
    ContextType
  >;
  GetAllPhoneTypes2?: Resolver<
    ResolversTypes['PhoneTypeElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllPhoneTypes2Args, 'Criteria'>
  >;
  GetAllPrivateTeamTypes?: Resolver<
    Maybe<Array<ResolversTypes['PrivateTeamTypeModel']>>,
    ParentType,
    ContextType
  >;
  GetAllPrivateTeams?: Resolver<
    Maybe<Array<ResolversTypes['PrivateTeamModel']>>,
    ParentType,
    ContextType
  >;
  GetAllPrivateTeams2?: Resolver<
    Maybe<ResolversTypes['PrivateTeamResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllPrivateTeams2Args, 'Criteria'>
  >;
  GetAllProductInterest?: Resolver<
    Array<ResolversTypes['ProductInterestModel']>,
    ParentType,
    ContextType
  >;
  GetAllProductInterest2?: Resolver<
    ResolversTypes['ProductInterestElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllProductInterest2Args, 'Criteria'>
  >;
  GetAllProductPotentials?: Resolver<
    Maybe<ResolversTypes['ProductPotentialResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllProductPotentialsArgs, 'Criteria'>
  >;
  GetAllRegions?: Resolver<Maybe<Array<ResolversTypes['RegionModel']>>, ParentType, ContextType>;
  GetAllRegions2?: Resolver<
    Maybe<ResolversTypes['RegionElasticResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllRegions2Args, 'Criteria'>
  >;
  GetAllRelatedCategories?: Resolver<
    Maybe<ResolversTypes['RelatedCategoryElasticResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllRelatedCategoriesArgs, 'Criteria'>
  >;
  GetAllRelatedCompanies?: Resolver<
    Maybe<ResolversTypes['RelatedCompanyElasticResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAllRelatedCompaniesArgs, 'Criteria' | 'company'>
  >;
  GetAllRoles?: Resolver<Array<ResolversTypes['RoleModel']>, ParentType, ContextType>;
  GetAllRolesCrm?: Resolver<Array<ResolversTypes['Role']>, ParentType, ContextType>;
  GetAllSalesTeam?: Resolver<
    ResolversTypes['SalesTeamResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllSalesTeamArgs, 'Criteria'>
  >;
  GetAllSalesTeamTypes?: Resolver<
    ResolversTypes['SalesTeamTypeResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllSalesTeamTypesArgs, 'Criteria'>
  >;
  GetAllStageGroup?: Resolver<
    ResolversTypes['StageGroupElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllStageGroupArgs, 'Criteria'>
  >;
  GetAllStates?: Resolver<Maybe<Array<ResolversTypes['StateDBModel']>>, ParentType, ContextType>;
  GetAllTenantInstances?: Resolver<
    Maybe<Array<ResolversTypes['TenantInstance']>>,
    ParentType,
    ContextType
  >;
  GetAllTerritories?: Resolver<
    Maybe<Array<ResolversTypes['TerritoryDBModel']>>,
    ParentType,
    ContextType
  >;
  GetAllTerritories2?: Resolver<
    ResolversTypes['TerritoryElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAllTerritories2Args, 'Criteria'>
  >;
  GetAllUser?: Resolver<Array<ResolversTypes['UserDBModel']>, ParentType, ContextType>;
  GetAllUserAdditionalFields?: Resolver<
    Maybe<Array<ResolversTypes['UserAdditionalFieldModel']>>,
    ParentType,
    ContextType
  >;
  GetAllUserAdditionalFieldsUser?: Resolver<
    Maybe<Array<ResolversTypes['UserAdditionalFieldsUserModel']>>,
    ParentType,
    ContextType
  >;
  GetAllUserLandingWidgets?: Resolver<
    Array<ResolversTypes['UserLandingWidget']>,
    ParentType,
    ContextType
  >;
  GetAllUserMenu?: Resolver<Array<ResolversTypes['UserMenuModel']>, ParentType, ContextType>;
  GetAllUserReportListModels?: Resolver<
    Array<ResolversTypes['ReportListModel']>,
    ParentType,
    ContextType
  >;
  GetAllUserReportPreference?: Resolver<
    Array<ResolversTypes['UserReportPreference']>,
    ParentType,
    ContextType
  >;
  GetAllUserReportWidgets?: Resolver<
    Array<ResolversTypes['UserReportWidgetList']>,
    ParentType,
    ContextType
  >;
  GetAllUserRole?: Resolver<Array<ResolversTypes['UserRoleModel']>, ParentType, ContextType>;
  GetAllUserType?: Resolver<Array<ResolversTypes['UserTypeModel']>, ParentType, ContextType>;
  GetAllUsers?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  GetAttachmentType?: Resolver<
    Maybe<ResolversTypes['AttachmentTypeModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetAttachmentTypeArgs, 'id'>
  >;
  GetBusinessInfo?: Resolver<
    Maybe<ResolversTypes['ContactBusinessInfoModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetBusinessInfoArgs, 'id'>
  >;
  GetBuyGroup?: Resolver<
    Maybe<ResolversTypes['BuyGroupModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetBuyGroupArgs, 'id'>
  >;
  GetBuyGroupUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetBuyGroupUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetCallPattern?: Resolver<
    Maybe<ResolversTypes['CallPatternModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCallPatternArgs, 'id'>
  >;
  GetCallPatternUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCallPatternUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetCategoryUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCategoryUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetColumnById?: Resolver<
    ResolversTypes['ColumnModel'],
    ParentType,
    ContextType,
    RequireFields<QueryGetColumnByIdArgs, 'id'>
  >;
  GetColumnByName?: Resolver<
    ResolversTypes['ColumnModel'],
    ParentType,
    ContextType,
    RequireFields<QueryGetColumnByNameArgs, 'name' | 'reportId'>
  >;
  GetCompaniesBaseTypes?: Resolver<
    Array<ResolversTypes['CompanyTypeAliasModel']>,
    ParentType,
    ContextType
  >;
  GetCompaniesBaseTypesOptions?: Resolver<
    Array<ResolversTypes['CompanyBaseType']>,
    ParentType,
    ContextType
  >;
  GetCompaniesByLocationDistance?: Resolver<
    ResolversTypes['CompanyModelElasticResponse2'],
    ParentType,
    ContextType,
    RequireFields<QueryGetCompaniesByLocationDistanceArgs, 'distance' | 'location'>
  >;
  GetCompaniesByType?: Resolver<
    ResolversTypes['CompanyTypeModelElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetCompaniesByTypeArgs, 'Criteria'>
  >;
  GetCompaniesDepthGridHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetCompaniesDynamicGridHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetCompaniesFromElastic?: Resolver<
    ResolversTypes['CompanyModelElasticResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetCompaniesFromElasticArgs, 'Criteria'>
  >;
  GetCompaniesFromElastic2?: Resolver<
    ResolversTypes['CompanyModelElasticResponse2'],
    ParentType,
    ContextType,
    RequireFields<QueryGetCompaniesFromElastic2Args, 'Criteria'>
  >;
  GetCompaniesFromElasticProtected?: Resolver<
    ResolversTypes['CompanyModelElasticResponse2'],
    ParentType,
    ContextType,
    RequireFields<QueryGetCompaniesFromElasticProtectedArgs, 'Criteria'>
  >;
  GetCompaniesGridHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetCompany?: Resolver<
    Maybe<ResolversTypes['CompanyModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyArgs, 'id'>
  >;
  GetCompanyAdditionalField?: Resolver<
    Maybe<ResolversTypes['CompanyAdditionalFieldModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyAdditionalFieldArgs, 'id'>
  >;
  GetCompanyAddress?: Resolver<
    Maybe<ResolversTypes['CompanyAddressModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyAddressArgs, 'id'>
  >;
  GetCompanyAttachment?: Resolver<
    Maybe<ResolversTypes['CompanyAttachmentModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyAttachmentArgs, 'id'>
  >;
  GetCompanyBuyingGroupGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetCompanyCallPatternGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetCompanyCategory?: Resolver<
    Maybe<ResolversTypes['CompanyCategoryDBModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyCategoryArgs, 'id'>
  >;
  GetCompanyCategoryGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetCompanyClass?: Resolver<
    ResolversTypes['CompanyClassDBModel'],
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyClassArgs, 'id'>
  >;
  GetCompanyCustomTypesGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetCompanyDivision?: Resolver<
    Maybe<ResolversTypes['CompanyDivisionModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyDivisionArgs, 'id'>
  >;
  GetCompanyDocNotes?: Resolver<
    Maybe<ResolversTypes['CompanyDocNotesModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyDocNotesArgs, 'id'>
  >;
  GetCompanyPhone?: Resolver<
    Maybe<ResolversTypes['CompanyPhoneModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyPhoneArgs, 'id'>
  >;
  GetCompanyPitch?: Resolver<
    Maybe<ResolversTypes['CompanyPitchesModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyPitchArgs, 'id'>
  >;
  GetCompanyPitchGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetCompanyPredefinedTypesGridHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetCompanyRank?: Resolver<
    Maybe<ResolversTypes['CompanyRankDBModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyRankArgs, 'id'>
  >;
  GetCompanyRankGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetCompanyRankUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyRankUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetCompanySalesTeam?: Resolver<
    Maybe<ResolversTypes['CompanySalesTeamModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanySalesTeamArgs, 'id'>
  >;
  GetCompanySplitType?: Resolver<
    Maybe<ResolversTypes['CompanySplitTypesModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanySplitTypeArgs, 'id'>
  >;
  GetCompanySplitTypeUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanySplitTypeUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetCompanySplitTypesGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetCompanyTag?: Resolver<
    Maybe<ResolversTypes['CompanyTagsModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyTagArgs, 'id'>
  >;
  GetCompanyTier?: Resolver<
    Maybe<ResolversTypes['CompanyTierModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyTierArgs, 'id'>
  >;
  GetCompanyTierGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetCompanyTierUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyTierUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetCompanyTypeAlias?: Resolver<
    ResolversTypes['CompanyTypeAliasModel'],
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyTypeAliasArgs, 'id'>
  >;
  GetCompanyTypeUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyTypeUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetCompanyUniqueValuesFromColumn?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QueryGetCompanyUniqueValuesFromColumnArgs, 'column' | 'enabled' | 'filter'>
  >;
  GetContact?: Resolver<
    Maybe<ResolversTypes['ContactOutputDto']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetContactArgs, 'id'>
  >;
  GetContactAdditionalField?: Resolver<
    Maybe<ResolversTypes['ContactAdditionalFieldsContactModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetContactAdditionalFieldArgs, 'additionalFieldId' | 'id'>
  >;
  GetContactGroup?: Resolver<
    ResolversTypes['ContactGroupModel'],
    ParentType,
    ContextType,
    RequireFields<QueryGetContactGroupArgs, 'id'>
  >;
  GetContactGroupContact?: Resolver<
    Maybe<ResolversTypes['ContactGroupContactModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetContactGroupContactArgs, 'id'>
  >;
  GetContactGroupGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetContactGroupNest?: Resolver<
    ResolversTypes['ContactGroupModel'],
    ParentType,
    ContextType,
    RequireFields<QueryGetContactGroupNestArgs, 'id'>
  >;
  GetContactGroupUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetContactGroupUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetContactsAmountByFirstLetter?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetContactsCompanyGridHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetContactsDynamicGridHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetContactsGridHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetContactsUniqueValuesFromColumn?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QueryGetContactsUniqueValuesFromColumnArgs, 'column' | 'enabled' | 'filter'>
  >;
  GetContactsUniqueValuesFromColumnWithId?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QueryGetContactsUniqueValuesFromColumnWithIdArgs, 'column' | 'enabled' | 'filter'>
  >;
  GetContinent?: Resolver<
    Maybe<ResolversTypes['ContinentDBModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetContinentArgs, 'id'>
  >;
  GetCountry?: Resolver<
    Maybe<ResolversTypes['CountryDBModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCountryArgs, 'id'>
  >;
  GetCountryGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetCountryUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCountryUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetCustomerPriceCode?: Resolver<
    Maybe<ResolversTypes['CustomerPriceCodesModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCustomerPriceCodeArgs, 'id'>
  >;
  GetCustomerPriceGroup?: Resolver<
    Maybe<ResolversTypes['CustomerPriceGroupsModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetCustomerPriceGroupArgs, 'id'>
  >;
  GetDepartment?: Resolver<
    Maybe<ResolversTypes['DepartmentModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetDepartmentArgs, 'id'>
  >;
  GetDivision?: Resolver<
    Maybe<ResolversTypes['DivisionDBModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetDivisionArgs, 'id'>
  >;
  GetDivisionsByCompany?: Resolver<
    Maybe<Array<ResolversTypes['DivisionsCompanyModel']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetDivisionsByCompanyArgs, 'companyId'>
  >;
  GetEmailServer?: Resolver<
    Maybe<ResolversTypes['EmailServerModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetEmailServerArgs, 'id'>
  >;
  GetEmailType?: Resolver<
    Maybe<ResolversTypes['EmailTypeModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetEmailTypeArgs, 'id'>
  >;
  GetEmailTypeGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetEmailTypeUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetEmailTypeUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetExportFileLink?: Resolver<
    ResolversTypes['ExportRequestResponseDTO'],
    ParentType,
    ContextType,
    RequireFields<QueryGetExportFileLinkArgs, 'reportId' | 'token'>
  >;
  GetGridPreference?: Resolver<
    Maybe<ResolversTypes['GridPreferenceModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetGridPreferenceArgs, 'id'>
  >;
  GetIndustry?: Resolver<
    Maybe<ResolversTypes['CompanyIndustryDBModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetIndustryArgs, 'id'>
  >;
  GetIndustryGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetLeadSource?: Resolver<
    ResolversTypes['LeadSourceOutputDto'],
    ParentType,
    ContextType,
    RequireFields<QueryGetLeadSourceArgs, 'id'>
  >;
  GetLeadSourceGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetLeadSourceUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetLeadSourceUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetLogo?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  GetOneTenantInstance?: Resolver<
    ResolversTypes['TenantInstance'],
    ParentType,
    ContextType,
    RequireFields<QueryGetOneTenantInstanceArgs, 'id'>
  >;
  GetOpportunities?: Resolver<
    ResolversTypes['OpportunitiesDto'],
    ParentType,
    ContextType,
    RequireFields<QueryGetOpportunitiesArgs, 'id'>
  >;
  GetOpportunitiesGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetOpportunitiesUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetOpportunitiesUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetOpportunityCloseReason?: Resolver<
    ResolversTypes['CloseReasonOutputDto'],
    ParentType,
    ContextType,
    RequireFields<QueryGetOpportunityCloseReasonArgs, 'id'>
  >;
  GetOpportunityCloseReasonGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetOpportunityCloseReasonUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetOpportunityCloseReasonUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetOpportunityFailedReason?: Resolver<
    ResolversTypes['FailedReasonOutputDto'],
    ParentType,
    ContextType,
    RequireFields<QueryGetOpportunityFailedReasonArgs, 'id'>
  >;
  GetOpportunityFailedReasonGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetOpportunityFailedReasonUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetOpportunityFailedReasonUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetOpportunityLineItemStatus?: Resolver<
    ResolversTypes['LineItemStatusOutputDto'],
    ParentType,
    ContextType,
    RequireFields<QueryGetOpportunityLineItemStatusArgs, 'id'>
  >;
  GetOpportunityLineItemStatusGridHeaders?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType
  >;
  GetOpportunityLineItemStatusUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetOpportunityLineItemStatusUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetOpportunityStage?: Resolver<
    ResolversTypes['StageOutputDto'],
    ParentType,
    ContextType,
    RequireFields<QueryGetOpportunityStageArgs, 'id'>
  >;
  GetOpportunityStageGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetOpportunityStageUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetOpportunityStageUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetOpportunityStatus?: Resolver<
    ResolversTypes['StatusOutputDto'],
    ParentType,
    ContextType,
    RequireFields<QueryGetOpportunityStatusArgs, 'id'>
  >;
  GetOpportunityStatusGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetOpportunityStatusUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetOpportunityStatusUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetOpportunityType?: Resolver<
    ResolversTypes['TypeOutputDto'],
    ParentType,
    ContextType,
    RequireFields<QueryGetOpportunityTypeArgs, 'id'>
  >;
  GetOpportunityTypeGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetOpportunityTypeUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetOpportunityTypeUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetPersonalInfo?: Resolver<
    Maybe<ResolversTypes['ContactPersonalInfoModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetPersonalInfoArgs, 'id'>
  >;
  GetPhoneType?: Resolver<
    Maybe<ResolversTypes['PhoneTypeModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetPhoneTypeArgs, 'id'>
  >;
  GetPhoneTypeGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetPhoneTypeUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetPhoneTypeUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetPrivateTeam?: Resolver<
    Maybe<ResolversTypes['PrivateTeamModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetPrivateTeamArgs, 'id'>
  >;
  GetPrivateTeamGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetPrivateTeamType?: Resolver<
    Maybe<ResolversTypes['PrivateTeamTypeModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetPrivateTeamTypeArgs, 'id'>
  >;
  GetPrivateTeamsUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetPrivateTeamsUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetProductInterest?: Resolver<
    ResolversTypes['ProductInterestModel'],
    ParentType,
    ContextType,
    RequireFields<QueryGetProductInterestArgs, 'id'>
  >;
  GetProductInterestGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetProductInterestNest?: Resolver<
    ResolversTypes['ProductInterestModel'],
    ParentType,
    ContextType,
    RequireFields<QueryGetProductInterestNestArgs, 'id'>
  >;
  GetProductInterestUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetProductInterestUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetProductPotential?: Resolver<
    Maybe<ResolversTypes['CompanyProductPotentialModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetProductPotentialArgs, 'id'>
  >;
  GetProductPotentialGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetProductPotentialUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetProductPotentialUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetRegion?: Resolver<
    Maybe<ResolversTypes['RegionModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetRegionArgs, 'id'>
  >;
  GetRegionGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetRegionUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetRegionUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetRelatedCategory?: Resolver<
    Maybe<ResolversTypes['RelatedCategoryModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetRelatedCategoryArgs, 'id'>
  >;
  GetRelatedCategoryGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetRelatedCompany?: Resolver<
    Maybe<ResolversTypes['RelatedCompanyDBModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetRelatedCompanyArgs, 'id'>
  >;
  GetReportsOfRole?: Resolver<Array<ResolversTypes['ReportListModel']>, ParentType, ContextType>;
  GetRole?: Resolver<
    ResolversTypes['Role'],
    ParentType,
    ContextType,
    RequireFields<QueryGetRoleArgs, 'id'>
  >;
  GetSalesTeam?: Resolver<
    Maybe<ResolversTypes['SalesTeamModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetSalesTeamArgs, 'id'>
  >;
  GetSalesTeamGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetSalesTeamType?: Resolver<
    Maybe<ResolversTypes['SalesTeamTypesModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetSalesTeamTypeArgs, 'id'>
  >;
  GetSalesTeamTypesGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetSalesTeamTypesUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetSalesTeamTypesUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetSalesTeamUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetSalesTeamUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetSecurityToken?: Resolver<
    Maybe<ResolversTypes['SecurityTokenModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetSecurityTokenArgs, 'createAppTokenInput'>
  >;
  GetSomeColumns?: Resolver<
    ResolversTypes['SomeColumnModel'],
    ParentType,
    ContextType,
    RequireFields<QueryGetSomeColumnsArgs, 'ids'>
  >;
  GetStageGroup?: Resolver<
    ResolversTypes['StageGroupOutputDto'],
    ParentType,
    ContextType,
    RequireFields<QueryGetStageGroupArgs, 'id'>
  >;
  GetStageGroupGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetStageGroupUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetStageGroupUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetState?: Resolver<
    Maybe<ResolversTypes['StateDBModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetStateArgs, 'id'>
  >;
  GetTerritory?: Resolver<
    Maybe<ResolversTypes['TerritoryDBModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetTerritoryArgs, 'id'>
  >;
  GetTerritoryGridHeaders?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  GetTerritoryUniqueValuesFromColumn?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetTerritoryUniqueValuesFromColumnArgs, 'column' | 'filter'>
  >;
  GetUser?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<QueryGetUserArgs, 'id'>
  >;
  GetUserAdditionalField?: Resolver<
    Maybe<ResolversTypes['UserAdditionalFieldModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetUserAdditionalFieldArgs, 'id'>
  >;
  GetUserAdditionalFieldsUser?: Resolver<
    Maybe<ResolversTypes['UserAdditionalFieldsUserModel']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetUserAdditionalFieldsUserArgs, 'id'>
  >;
  GetUserMenu?: Resolver<
    ResolversTypes['UserMenuModel'],
    ParentType,
    ContextType,
    RequireFields<QueryGetUserMenuArgs, 'id'>
  >;
  GetUserRole?: Resolver<
    ResolversTypes['UserRoleModel'],
    ParentType,
    ContextType,
    RequireFields<QueryGetUserRoleArgs, 'id'>
  >;
  GetUserType?: Resolver<
    ResolversTypes['UserTypeModel'],
    ParentType,
    ContextType,
    RequireFields<QueryGetUserTypeArgs, 'id'>
  >;
  InitializeUser?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  TestGuardFunction?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  UpdateDesignatedByDivision?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<QueryUpdateDesignatedByDivisionArgs, 'divisionId'>
  >;
  analyticsWideSale?: Resolver<
    ResolversTypes['AnalyticsWideSale'],
    ParentType,
    ContextType,
    RequireFields<QueryAnalyticsWideSaleArgs, 'id'>
  >;
  analyticsWideSales?: Resolver<
    Array<ResolversTypes['AnalyticsWideSale']>,
    ParentType,
    ContextType
  >;
  auroraSalesReport?: Resolver<
    ResolversTypes['AuroraSalesReport'],
    ParentType,
    ContextType,
    RequireFields<QueryAuroraSalesReportArgs, 'aggsCriteria'>
  >;
  auroraSalesReportHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  contactsGlobalSearchQuery?: Resolver<
    Maybe<ResolversTypes['ContactGridResponseOutputDto']>,
    ParentType,
    ContextType,
    RequireFields<QueryContactsGlobalSearchQueryArgs, 'Criteria'>
  >;
  downloadReportFile?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<QueryDownloadReportFileArgs, 'tmp_index'>
  >;
  factoryTouches?: Resolver<
    ResolversTypes['FactoryTouchesReport'],
    ParentType,
    ContextType,
    RequireFields<QueryFactoryTouchesArgs, 'aggsCriteria'>
  >;
  factoryTouchesHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  getAJIndexStatus?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAjIndexStatusArgs, 'tmp_index'>
  >;
  getAllSalesTeams?: Resolver<
    ResolversTypes['GetAllSalesTeamMembersResponseModel'],
    ParentType,
    ContextType
  >;
  getCommissionsAndSalesIndexStatus?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QueryGetCommissionsAndSalesIndexStatusArgs, 'tmp_index'>
  >;
  getCommissionsIndexStatus?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QueryGetCommissionsIndexStatusArgs, 'tmp_index'>
  >;
  getDynamicPropertiesFromCompanyModel?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  getOrdersIndexStatus?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QueryGetOrdersIndexStatusArgs, 'tmp_index'>
  >;
  getQuotesIndexStatus?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QueryGetQuotesIndexStatusArgs, 'tmp_index'>
  >;
  getResultIndices?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  getSalesIndexStatus?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QueryGetSalesIndexStatusArgs, 'tmp_index'>
  >;
  monthlySalesReport?: Resolver<
    ResolversTypes['MonthlySalesReport'],
    ParentType,
    ContextType,
    RequireFields<QueryMonthlySalesReportArgs, 'aggsCriteria'>
  >;
  monthlySalesReportHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  paginateWideAJReportResults?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QueryPaginateWideAjReportResultsArgs, 'from' | 'size' | 'tmp_index'>
  >;
  paginateWideCommissionsAndSalesReportResults?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<
      QueryPaginateWideCommissionsAndSalesReportResultsArgs,
      'from' | 'size' | 'tmp_index'
    >
  >;
  paginateWideCommissionsReportResults?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QueryPaginateWideCommissionsReportResultsArgs, 'from' | 'size' | 'tmp_index'>
  >;
  paginateWideOrdersReportResults?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QueryPaginateWideOrdersReportResultsArgs, 'from' | 'size' | 'tmp_index'>
  >;
  paginateWideQuotesReportResults?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QueryPaginateWideQuotesReportResultsArgs, 'from' | 'size' | 'tmp_index'>
  >;
  paginateWideSalesReportResults?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType,
    RequireFields<QueryPaginateWideSalesReportResultsArgs, 'from' | 'size' | 'tmp_index'>
  >;
  sideBarMenu?: Resolver<Array<ResolversTypes['Section']>, ParentType, ContextType>;
  wideAJReport?: Resolver<
    ResolversTypes['WideAJReport'],
    ParentType,
    ContextType,
    RequireFields<QueryWideAjReportArgs, 'aggsCriteria'>
  >;
  wideAJReportHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  wideAJReportLastInvoiceDate?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  wideAJReportV2?: Resolver<
    ResolversTypes['WideAJReport'],
    ParentType,
    ContextType,
    RequireFields<QueryWideAjReportV2Args, 'aggsCriteria'>
  >;
  wideCommissionReport?: Resolver<
    ResolversTypes['WideCommissionReport'],
    ParentType,
    ContextType,
    RequireFields<QueryWideCommissionReportArgs, 'aggsCriteria'>
  >;
  wideCommissionReportHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  wideCommissionReportLastInvoiceDate?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  wideCommissionReportV2?: Resolver<
    ResolversTypes['WideCommissionReport'],
    ParentType,
    ContextType,
    RequireFields<QueryWideCommissionReportV2Args, 'aggsCriteria'>
  >;
  wideCommissionSaleReport?: Resolver<
    ResolversTypes['WideCommissionSalesReport'],
    ParentType,
    ContextType,
    RequireFields<QueryWideCommissionSaleReportArgs, 'aggsCriteria'>
  >;
  wideCommissionSaleReportHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  wideCommissionSaleReportLastInvoiceDate?: Resolver<
    ResolversTypes['JSON'],
    ParentType,
    ContextType
  >;
  wideCommissionSaleReportV2?: Resolver<
    ResolversTypes['WideCommissionSalesReport'],
    ParentType,
    ContextType,
    RequireFields<QueryWideCommissionSaleReportV2Args, 'aggsCriteria'>
  >;
  widePOReport?: Resolver<
    ResolversTypes['WidePOReport'],
    ParentType,
    ContextType,
    RequireFields<QueryWidePoReportArgs, 'aggsCriteria'>
  >;
  widePOReportHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  widePOReportLastInvoiceDate?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  widePOReportV2?: Resolver<
    ResolversTypes['WidePOReport'],
    ParentType,
    ContextType,
    RequireFields<QueryWidePoReportV2Args, 'aggsCriteria'>
  >;
  wideQuotesReport?: Resolver<
    ResolversTypes['WideQuotesReport'],
    ParentType,
    ContextType,
    RequireFields<QueryWideQuotesReportArgs, 'aggsCriteria'>
  >;
  wideQuotesReportHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  wideQuotesReportLastQuoteDate?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  wideQuotesReportV2?: Resolver<
    ResolversTypes['WideQuotesReport'],
    ParentType,
    ContextType,
    RequireFields<QueryWideQuotesReportV2Args, 'aggsCriteria'>
  >;
  wideSalesReport?: Resolver<
    ResolversTypes['WideSalesReport'],
    ParentType,
    ContextType,
    RequireFields<QueryWideSalesReportArgs, 'aggsCriteria'>
  >;
  wideSalesReportHeader?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  wideSalesReportLastInvoiceDate?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  wideSalesReportV2?: Resolver<
    ResolversTypes['WideSalesReport'],
    ParentType,
    ContextType,
    RequireFields<QueryWideSalesReportV2Args, 'aggsCriteria'>
  >;
}>;

export type RegionElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegionElasticResponse'] = ResolversParentTypes['RegionElasticResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['RegionModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RegionModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegionModel'] = ResolversParentTypes['RegionModel'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RegionWideSalesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegionWideSales'] = ResolversParentTypes['RegionWideSales'],
> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  primaryCustomers?: Resolver<
    Maybe<Array<ResolversTypes['PrimaryCustomerWideSales']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RegisterUserSalesTeamModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegisterUserSalesTeamModel'] = ResolversParentTypes['RegisterUserSalesTeamModel'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  salesTeam?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RelatedCategoryElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RelatedCategoryElasticResponse'] = ResolversParentTypes['RelatedCategoryElasticResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['RelatedCategoryModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RelatedCategoryModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RelatedCategoryModel'] = ResolversParentTypes['RelatedCategoryModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RelatedCompanyDbModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RelatedCompanyDBModel'] = ResolversParentTypes['RelatedCompanyDBModel'],
> = ResolversObject<{
  company?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  companyManufacturer?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  customerSplit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  relatedCategory?: Resolver<
    Maybe<ResolversTypes['RelatedCategoryModel']>,
    ParentType,
    ContextType
  >;
  relatedCompany?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  valueRole?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RelatedCompanyElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RelatedCompanyElasticResponse'] = ResolversParentTypes['RelatedCompanyElasticResponse'],
> = ResolversObject<{
  results?: Resolver<
    Maybe<Array<ResolversTypes['RelatedCompanyDBModel']>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReportListModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportListModel'] = ResolversParentTypes['ReportListModel'],
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  extraParams?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reportRoute?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reportTag?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReportResponseDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReportResponseDTO'] = ResolversParentTypes['ReportResponseDTO'],
> = ResolversObject<{
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Role'] = ResolversParentTypes['Role'],
> = ResolversObject<{
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disabledBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  disabledDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  updatedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoleBasicInformationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RoleBasicInformation'] = ResolversParentTypes['RoleBasicInformation'],
> = ResolversObject<{
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoleModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RoleModel'] = ResolversParentTypes['RoleModel'],
> = ResolversObject<{
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SalesByMonthResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesByMonth'] = ResolversParentTypes['SalesByMonth'],
> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sales?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SalesTeamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesTeam'] = ResolversParentTypes['SalesTeam'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SalesTeamMemberModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesTeamMemberModel'] = ResolversParentTypes['SalesTeamMemberModel'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SalesTeamModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesTeamModel'] = ResolversParentTypes['SalesTeamModel'],
> = ResolversObject<{
  companySplit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  companySplitType?: Resolver<
    Maybe<ResolversTypes['CompanySplitTypesModel']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  leaderSalesTeamId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  specifierSplit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['SalesTeamTypesModel']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SalesTeamResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesTeamResponse'] = ResolversParentTypes['SalesTeamResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['SalesTeamModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SalesTeamTypeResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesTeamTypeResponse'] = ResolversParentTypes['SalesTeamTypeResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['SalesTeamTypesModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SalesTeamTypesModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SalesTeamTypesModel'] = ResolversParentTypes['SalesTeamTypesModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Section'] = ResolversParentTypes['Section'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reports?: Resolver<Array<ResolversTypes['MenuReport']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SecurityTokenModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SecurityTokenModel'] = ResolversParentTypes['SecurityTokenModel'],
> = ResolversObject<{
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  tokenApp?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SomeColumnModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SomeColumnModel'] = ResolversParentTypes['SomeColumnModel'],
> = ResolversObject<{
  data?: Resolver<Array<ResolversTypes['ColumnModel']>, ParentType, ContextType>;
  total_found?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total_requested?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SplitTypeResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SplitTypeResponse'] = ResolversParentTypes['SplitTypeResponse'],
> = ResolversObject<{
  results?: Resolver<
    Maybe<Array<ResolversTypes['CompanySplitTypesModel']>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StageElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StageElasticResponse'] = ResolversParentTypes['StageElasticResponse'],
> = ResolversObject<{
  results?: Resolver<
    Maybe<Array<ResolversTypes['SubTableStageByCompanyElasticResponse']>>,
    ParentType,
    ContextType
  >;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StageGroupElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StageGroupElasticResponse'] = ResolversParentTypes['StageGroupElasticResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['StageGroupOutputDto']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StageGroupModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StageGroupModel'] = ResolversParentTypes['StageGroupModel'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StageGroupOutputDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StageGroupOutputDto'] = ResolversParentTypes['StageGroupOutputDto'],
> = ResolversObject<{
  boolean?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StageOutputDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StageOutputDto'] = ResolversParentTypes['StageOutputDto'],
> = ResolversObject<{
  boolean?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  closeStatus?: Resolver<Maybe<ResolversTypes['CloseStatusModel']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['CompanyModel']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  potential?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  stageGroup?: Resolver<Maybe<ResolversTypes['StageGroupModel']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['StatusModel']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['State'] = ResolversParentTypes['State'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StateDbModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StateDBModel'] = ResolversParentTypes['StateDBModel'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StatusElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusElasticResponse'] = ResolversParentTypes['StatusElasticResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['StatusOutputDto']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StatusModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusModel'] = ResolversParentTypes['StatusModel'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StatusOutputDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatusOutputDto'] = ResolversParentTypes['StatusOutputDto'],
> = ResolversObject<{
  boolean?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isPredefined?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubTableByCompanyElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubTableByCompanyElasticResponse'] = ResolversParentTypes['SubTableByCompanyElasticResponse'],
> = ResolversObject<{
  company?: Resolver<ResolversTypes['CompanyModel'], ParentType, ContextType>;
  data?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['CloseReasonOutputDto']>>>,
    ParentType,
    ContextType
  >;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubTableStageByCompanyElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SubTableStageByCompanyElasticResponse'] = ResolversParentTypes['SubTableStageByCompanyElasticResponse'],
> = ResolversObject<{
  company?: Resolver<ResolversTypes['CompanyModel'], ParentType, ContextType>;
  data?: Resolver<Maybe<Array<Maybe<ResolversTypes['StageOutputDto']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription'],
> = ResolversObject<{
  reports?: SubscriptionResolver<
    ResolversTypes['ReportResponseDTO'],
    'reports',
    ParentType,
    ContextType
  >;
}>;

export type TeamWideSalesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TeamWideSales'] = ResolversParentTypes['TeamWideSales'],
> = ResolversObject<{
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  primaryCustomers?: Resolver<
    Maybe<Array<ResolversTypes['PrimaryCustomerWideSales']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TenantInstanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TenantInstance'] = ResolversParentTypes['TenantInstance'],
> = ResolversObject<{
  clientId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cognitoClientId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cognitoPoolId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  elasticSearchKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  instanceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  instanceSettings?: Resolver<Maybe<ResolversTypes['InstanceSettings']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  legacyElasticSearchKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenantId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tenantName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TerritoryDbModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TerritoryDBModel'] = ResolversParentTypes['TerritoryDBModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TerritoryElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TerritoryElasticResponse'] = ResolversParentTypes['TerritoryElasticResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['TerritoryDBModel']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TypeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Type'] = ResolversParentTypes['Type'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TypeElasticResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TypeElasticResponse'] = ResolversParentTypes['TypeElasticResponse'],
> = ResolversObject<{
  results?: Resolver<Maybe<Array<ResolversTypes['TypeOutputDto']>>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TypeOutputDtoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TypeOutputDto'] = ResolversParentTypes['TypeOutputDto'],
> = ResolversObject<{
  boolean?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateCompanyBatchModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateCompanyBatchModel'] = ResolversParentTypes['UpdateCompanyBatchModel'],
> = ResolversObject<{
  addresses?: Resolver<Maybe<Array<ResolversTypes['AddressDBModel']>>, ParentType, ContextType>;
  hasDwin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasForecast?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasQuot?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasRegi?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasSamp?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasSecondaryAlias?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phones?: Resolver<Maybe<Array<ResolversTypes['PhoneDBModel']>>, ParentType, ContextType>;
  printName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateCompanyBatchResponseModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateCompanyBatchResponseModel'] = ResolversParentTypes['UpdateCompanyBatchResponseModel'],
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateContactBatchModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpdateContactBatchModel'] = ResolversParentTypes['UpdateContactBatchModel'],
> = ResolversObject<{
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User'],
> = ResolversObject<{
  cognitoId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cognitoPoolId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disabledBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  disabledDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mainPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['RoleBasicInformation']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  typeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  updatedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAdditionalFieldModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserAdditionalFieldModel'] = ResolversParentTypes['UserAdditionalFieldModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dataType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserAdditionalFieldsUserModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserAdditionalFieldsUserModel'] = ResolversParentTypes['UserAdditionalFieldsUserModel'],
> = ResolversObject<{
  additionalFieldId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  additionalFieldName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fieldValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserDbModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserDBModel'] = ResolversParentTypes['UserDBModel'],
> = ResolversObject<{
  cognitoId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  emails?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserEmailModel']>>>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserEmailModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserEmailModel'] = ResolversParentTypes['UserEmailModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserLandingWidgetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserLandingWidget'] = ResolversParentTypes['UserLandingWidget'],
> = ResolversObject<{
  columnMinHeight?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  columnNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  savedName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenantId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  widgetList?: Resolver<Array<ResolversTypes['LandingWidget']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserMenuModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserMenuModel'] = ResolversParentTypes['UserMenuModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  hasLink?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  level?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  linkAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserModel'] = ResolversParentTypes['UserModel'],
> = ResolversObject<{
  ID?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  NAME?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReportPreferenceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserReportPreference'] = ResolversParentTypes['UserReportPreference'],
> = ResolversObject<{
  configParams?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  gridItems?: Resolver<Array<ResolversTypes['gridItem']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isDefault?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  range_from?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  range_to?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  reportId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  savedName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tenantId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserReportWidgetListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserReportWidgetList'] = ResolversParentTypes['UserReportWidgetList'],
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  extraParams?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reportId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tenantId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserRoleModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserRoleModel'] = ResolversParentTypes['UserRoleModel'],
> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTypeModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserTypeModel'] = ResolversParentTypes['UserTypeModel'],
> = ResolversObject<{
  canLogin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  emailAccess?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  walletAccess?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WideAjReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WideAJReport'] = ResolversParentTypes['WideAJReport'],
> = ResolversObject<{
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  results?: Resolver<Maybe<Array<ResolversTypes['WideAJReportResult']>>, ParentType, ContextType>;
  tmp_index?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalAmounts?: Resolver<
    Maybe<Array<ResolversTypes['WideAJReportResult']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WideAjReportResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WideAJReportResult'] = ResolversParentTypes['WideAJReportResult'],
> = ResolversObject<{
  activeFlag?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  activityJournalDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ajTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commentsWordCount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contactId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  contactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followUpDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  hasOpportunity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  headerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCompanyTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerCompanyTypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  manufacturerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  manufacturerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerPrivateSalesTeam?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerPrivateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerPrivateTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  monthDaysRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  monthsRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opportunityId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  percentageDiff?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCompanyTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorCompanyTypeName?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  posDistributorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentCategoryId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  posDistributorParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentSalesTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentShortName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorPrivateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousYearMonthDaysRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousYearMonthsRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousYearRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCompanyTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  primaryCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentCategoryId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentSalesTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentShortName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerPrivateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quarter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  secondaryCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentCategoryId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentClassId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentRegionId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentSalesTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentShortName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  typeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  userName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  yearMonth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WideCommissionReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WideCommissionReport'] = ResolversParentTypes['WideCommissionReport'],
> = ResolversObject<{
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  results?: Resolver<
    Maybe<Array<ResolversTypes['WideCommissionsReportResult']>>,
    ParentType,
    ContextType
  >;
  tmp_index?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalAmounts?: Resolver<
    Maybe<Array<ResolversTypes['WideCommissionsReportResult']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WideCommissionSalesReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WideCommissionSalesReport'] = ResolversParentTypes['WideCommissionSalesReport'],
> = ResolversObject<{
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  results?: Resolver<
    Maybe<Array<ResolversTypes['WideCommissionsSalesReportResult']>>,
    ParentType,
    ContextType
  >;
  tmp_index?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalAmounts?: Resolver<
    Maybe<Array<ResolversTypes['WideCommissionsSalesReportResult']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WideCommissionsReportResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WideCommissionsReportResult'] = ResolversParentTypes['WideCommissionsReportResult'],
> = ResolversObject<{
  activeFlag?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  checkDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  checkNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commissionAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  commissionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  companyAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  conversionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  creditedSales?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customerReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  divisionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalAccountName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  importBatchNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  importProcessDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  importProductRecordId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  importSource?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  invoiceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  invoiceNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCompanyTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerCompanyTypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  manufacturerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  manufacturerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerPrivateSalesTeam?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerPrivateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerPrivateTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marketId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  marketName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  monthDaysRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  monthsRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  partNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentageDiff?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  poNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCompanyTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorCompanyTypeName?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  posDistributorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentCategoryId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  posDistributorParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentSalesTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentShortName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorPrivateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posFlag?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  previousYearMonthDaysRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousYearMonthsRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousYearRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCompanyTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  primaryCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentCategoryId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentSalesTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentShortName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerPartNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerPrivateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productFamilyId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  productFamilyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productLine?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quarter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  relatedCommissionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesOrderNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesTeamsSplitAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesTeamsSplitPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerCity1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  secondaryCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentCategoryId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentClassId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentRegionId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentSalesTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentShortName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerState1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerZip1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  somethingRowId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  unitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  yearMonth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WideCommissionsSalesReportResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WideCommissionsSalesReportResult'] = ResolversParentTypes['WideCommissionsSalesReportResult'],
> = ResolversObject<{
  activeFlag?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bookingCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  buyGroupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  checkDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  checkNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commissionAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  commissionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  companyAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  conversionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  creditedSales?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  customerReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  divisionID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalAccountName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  importBatchNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  importProcessDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  importProductRecordID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  importSource?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  invoiceDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  invoiceNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCategoryID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerClassID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCompanyTypeID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerCompanyTypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  manufacturerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentCategoryID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentClassID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentCompanyTypeID?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  manufacturerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentPrivateTeamID?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentRegionID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerPrivateTeamID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerPrivateTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerRegionID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marketID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  marketName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  monthDaysRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  monthsRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalSalesTeamID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paid?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  partNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentageDiff?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  poNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCategoryID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorClassID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCompanyTypeID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  posDistributorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentCategoryID?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentClassID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCompanyTypeID?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  posDistributorParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentPrivateTeamID?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentRegionID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorPrivateTeamID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorRegionID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posFlag?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posSplitAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  previousYearMonthDaysRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousYearMonthsRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousYearRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCategoryID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCity1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerClassID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCompanyTypeID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  primaryCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentCategoryID?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentClassID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCompanyTypeID?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentPrivateTeamID?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentRegionID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerPartNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerPrivateTeamID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerRegionID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerState1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerZip1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productFamilyID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productFamilyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productLine?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quarter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  realCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  relatedCommissionID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesOrderNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesTeamID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesTeamsSplitAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesTeamsSplitPercent?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondCustomerSales?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerCategoryID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerClassID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerCompanyTypeID?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  secondaryCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentCategoryID?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentClassID?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCompanyTypeID?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentPrivateTeamID?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentRegionID?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerPrivateTeamID?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerRegionID?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  somethingRowID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  unitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  yearMonth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WidePoReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WidePOReport'] = ResolversParentTypes['WidePOReport'],
> = ResolversObject<{
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  results?: Resolver<Maybe<Array<ResolversTypes['WidePOReportResult']>>, ParentType, ContextType>;
  tmp_index?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalAmounts?: Resolver<
    Maybe<Array<ResolversTypes['WidePOReportResult']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WidePoReportResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WidePOReportResult'] = ResolversParentTypes['WidePOReportResult'],
> = ResolversObject<{
  accountingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  activeFlag?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  billToAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billToCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billToCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billToState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billToType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billToZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  brand?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  closeStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commissionProjectedDetail?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  commissionProjectedHeader?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  commissionRateDetail?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  commissionRateHeader?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  customerPartNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  detailInsertUser?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  detailMatchingSalesId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  detailUpdateDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  detailUpdateUser?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  freightDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  headerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  headerInsertDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  headerInsertUser?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  headerMatchingSalesId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  headerUpdateDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  headerUpdateUser?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  invoiceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  invoiceNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCompanyTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerCompanyTypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  manufacturerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  manufacturerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerPrivateSalesTeam?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerPrivateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerPrivateTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  monthDaysRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  monthsRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  multiplier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opportunityId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  orderDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  orderDateYear?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  orderQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  overageAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  overagePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  partDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentageDiff?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  planDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  planQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  poBilltoType?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  poNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCompanyTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorCompanyTypeName?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  posDistributorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentCategoryId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  posDistributorParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentSalesTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentShortName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorPrivateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousYearMonthDaysRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousYearMonthsRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousYearRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCompanyTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  primaryCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentCategoryId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentSalesTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentShortName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerPrivateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productFamilyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  program?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quarter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quoteDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  quoteId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quoteNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recordStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesOrderDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  salesOrderNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  secondaryCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentCategoryId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentClassId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentRegionId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentSalesTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentShortName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sequenceNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shipAgent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipToAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipToCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipToCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipToState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipToType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipToZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shippingDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  shippingQuantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shippingStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  standardRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalPriceDetail?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalPriceHeader?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transactionDetailId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transportId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  unitOfMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitOfMeasureUnits?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  unitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  yearMonth?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WideQuotesReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WideQuotesReport'] = ResolversParentTypes['WideQuotesReport'],
> = ResolversObject<{
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  results?: Resolver<
    Maybe<Array<ResolversTypes['WideQuotesReportResult']>>,
    ParentType,
    ContextType
  >;
  tmp_index?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalAmounts?: Resolver<
    Maybe<Array<ResolversTypes['WideQuotesReportResult']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WideQuotesReportResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WideQuotesReportResult'] = ResolversParentTypes['WideQuotesReportResult'],
> = ResolversObject<{
  application?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bidDueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  bidOrBuy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buyGroupId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  buyGroupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  closeDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  competitorManufacturer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  competitorPartNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  customerPartNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerPriceFlag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerProgram?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerReferenceNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dataSource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deliveryStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  distributorContactId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distributorContactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailFlag?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  estimatedAnnualUnits?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  expiryDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  extendedPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  followUpDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  headerComments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  headerNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  headerSequenceNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  insertDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  insertUserId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  itemAction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  itemCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  itemCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  leadTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineItemNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCompanyTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerCompanyTypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerContactId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerContactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerIdHeader?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  manufacturerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  manufacturerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerPartNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerPrivateSalesTeam?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerPrivateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerPrivateTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  monthDaysRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  monthsRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  multiplier?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  openStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opportunityId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  opportunityItemId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  parentQuoteId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  partDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentageDiff?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCompanyTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorCompanyTypeName?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  posDistributorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentCategoryId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  posDistributorParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentSalesTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentShortName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorPrivateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousYearMonthDaysRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousYearMonthsRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousYearRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCompanyTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerContactId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerContactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  primaryCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentCategoryId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentSalesTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentShortName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerPrivateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  probability?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  productLine?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quarter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quoteDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  quoteHeaderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quoteNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quoteOwnerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quoteRecipient?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quoteValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  requestForQuoteNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resellPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerContactId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerContactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  secondaryCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentCategoryId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentClassId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentRegionId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentSalesTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentShortName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sequenceNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  standardRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  targetCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  territory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tsLinkIdDetail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tsLinkIdHeader?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitOfMeasure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitOfMeasureUnits?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updateDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updateUserId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  yearMonth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WideSalesReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WideSalesReport'] = ResolversParentTypes['WideSalesReport'],
> = ResolversObject<{
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  results?: Resolver<
    Maybe<Array<ResolversTypes['WideSalesReportResult']>>,
    ParentType,
    ContextType
  >;
  tmp_index?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalAmounts?: Resolver<
    Maybe<Array<ResolversTypes['WideSalesReportResult']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WideSalesReportResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WideSalesReportResult'] = ResolversParentTypes['WideSalesReportResult'],
> = ResolversObject<{
  bookingCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  buyGroupId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  buyGroupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  checkNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commissionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  commissionableTableId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  conversionRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  customerPartNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  divisionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  divisionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalAccountName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  importBatchNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  importBatchRowId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  importProcessDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  invoiceDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  invoiceNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerCompanyTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerCompanyTypeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  manufacturerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  manufacturerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  manufacturerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  manufacturerParentShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerPrivateSalesTeam?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerPrivateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerPrivateTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  manufacturerSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufacturerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  marketId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  marketName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  monthDaysRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  monthsRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  originalSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  partNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentageDiff?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  poNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorCompanyTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorCompanyTypeName?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  posDistributorName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentCategoryId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  posDistributorParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentSalesTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentShortName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  posDistributorPrivateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  posDistributorRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  posDistributorSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posDistributorZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  posSplitAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  previousYearMonthDaysRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousYearMonthsRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  previousYearRange?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerCategoryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerCompanyTypeId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  primaryCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentCategoryId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentSalesTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentShortName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  primaryCustomerPrivateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  primaryCustomerRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  primaryCustomerSalesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryCustomerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  privateTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  privateTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productFamilyId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  productFamilyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productLine?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purchaseOrderHeaderId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  purchaseOrderLineItemId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quarter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  realCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  recId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesOrderNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  salesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salesTeamName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondCustomerSales?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerCategoryId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerClassId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerClassName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  secondaryCustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentCategoryId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCategoryName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentClassId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentClassName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCompanyTypeId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentCompanyTypeName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerParentIndustries?: Resolver<
    Maybe<Array<ResolversTypes['IIndustry']>>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentRegionId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentRegionName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentSalesTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentShortName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerParentState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerParentZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerPrivateSalesTeam?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerPrivateTeamId?: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerPrivateTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerRegionId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerRegionName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerSalesTeamId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  secondaryCustomerSalesTeamName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  secondaryCustomerShortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryCustomerZip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  spreadsheetLoadId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unitPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  yearMonth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GridItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['gridItem'] = ResolversParentTypes['gridItem'],
> = ResolversObject<{
  area?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  caption?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dataField?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dataType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  format?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  summaryType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  width?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PhoneModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['phoneModel'] = ResolversParentTypes['phoneModel'],
> = ResolversObject<{
  ID?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  PHONE?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  TYPE?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  AdditionalFieldModel?: AdditionalFieldModelResolvers<ContextType>;
  AdditionalFieldsResponse?: AdditionalFieldsResponseResolvers<ContextType>;
  AddressDBModel?: AddressDbModelResolvers<ContextType>;
  AddressFast?: AddressFastResolvers<ContextType>;
  AddressModel?: AddressModelResolvers<ContextType>;
  AddressTypeElasticResponse?: AddressTypeElasticResponseResolvers<ContextType>;
  AddressTypeModel?: AddressTypeModelResolvers<ContextType>;
  AnalyticsWideSale?: AnalyticsWideSaleResolvers<ContextType>;
  AttachmentTypeModel?: AttachmentTypeModelResolvers<ContextType>;
  AuroraSalesReport?: AuroraSalesReportResolvers<ContextType>;
  AuroraSalesReportResult?: AuroraSalesReportResultResolvers<ContextType>;
  BatchErrorModel?: BatchErrorModelResolvers<ContextType>;
  BuyGroupModel?: BuyGroupModelResolvers<ContextType>;
  BuyingGroupResponse?: BuyingGroupResponseResolvers<ContextType>;
  CallPatternModel?: CallPatternModelResolvers<ContextType>;
  CallPatternResponse?: CallPatternResponseResolvers<ContextType>;
  CategoryResponse?: CategoryResponseResolvers<ContextType>;
  CloseReasonElasticResponse?: CloseReasonElasticResponseResolvers<ContextType>;
  CloseReasonOutputDto?: CloseReasonOutputDtoResolvers<ContextType>;
  CloseStatusModel?: CloseStatusModelResolvers<ContextType>;
  ClosedReason?: ClosedReasonResolvers<ContextType>;
  ClosedStatus?: ClosedStatusResolvers<ContextType>;
  CognitoConfirmResponseModel?: CognitoConfirmResponseModelResolvers<ContextType>;
  ColumnModel?: ColumnModelResolvers<ContextType>;
  CompanyAdditionalFieldModel?: CompanyAdditionalFieldModelResolvers<ContextType>;
  CompanyAddressModel?: CompanyAddressModelResolvers<ContextType>;
  CompanyAttachmentModel?: CompanyAttachmentModelResolvers<ContextType>;
  CompanyBaseType?: CompanyBaseTypeResolvers<ContextType>;
  CompanyBatchResultModel?: CompanyBatchResultModelResolvers<ContextType>;
  CompanyCategoryDBModel?: CompanyCategoryDbModelResolvers<ContextType>;
  CompanyCategoryModel?: CompanyCategoryModelResolvers<ContextType>;
  CompanyClassDBModel?: CompanyClassDbModelResolvers<ContextType>;
  CompanyClassModel?: CompanyClassModelResolvers<ContextType>;
  CompanyDivisionModel?: CompanyDivisionModelResolvers<ContextType>;
  CompanyDocNotesModel?: CompanyDocNotesModelResolvers<ContextType>;
  CompanyElasticModel?: CompanyElasticModelResolvers<ContextType>;
  CompanyFastModel?: CompanyFastModelResolvers<ContextType>;
  CompanyIndustryDBModel?: CompanyIndustryDbModelResolvers<ContextType>;
  CompanyIndustryResponse?: CompanyIndustryResponseResolvers<ContextType>;
  CompanyModel?: CompanyModelResolvers<ContextType>;
  CompanyModelElasticResponse?: CompanyModelElasticResponseResolvers<ContextType>;
  CompanyModelElasticResponse2?: CompanyModelElasticResponse2Resolvers<ContextType>;
  CompanyPhoneModel?: CompanyPhoneModelResolvers<ContextType>;
  CompanyPitchesModel?: CompanyPitchesModelResolvers<ContextType>;
  CompanyProductPotentialModel?: CompanyProductPotentialModelResolvers<ContextType>;
  CompanyRankDBModel?: CompanyRankDbModelResolvers<ContextType>;
  CompanyRankResponse?: CompanyRankResponseResolvers<ContextType>;
  CompanySalesESTeamModel?: CompanySalesEsTeamModelResolvers<ContextType>;
  CompanySalesTeamModel?: CompanySalesTeamModelResolvers<ContextType>;
  CompanySplitTypesModel?: CompanySplitTypesModelResolvers<ContextType>;
  CompanyTagsModel?: CompanyTagsModelResolvers<ContextType>;
  CompanyTierModel?: CompanyTierModelResolvers<ContextType>;
  CompanyTierResponse?: CompanyTierResponseResolvers<ContextType>;
  CompanyTypeAliasModel?: CompanyTypeAliasModelResolvers<ContextType>;
  CompanyTypeDBModel?: CompanyTypeDbModelResolvers<ContextType>;
  CompanyTypeModel?: CompanyTypeModelResolvers<ContextType>;
  CompanyTypeModelElasticResponse?: CompanyTypeModelElasticResponseResolvers<ContextType>;
  ContactAdditionalFieldsContactModel?: ContactAdditionalFieldsContactModelResolvers<ContextType>;
  ContactBatchResponseModel?: ContactBatchResponseModelResolvers<ContextType>;
  ContactBatchResultModel?: ContactBatchResultModelResolvers<ContextType>;
  ContactBusinessInfoFastModel?: ContactBusinessInfoFastModelResolvers<ContextType>;
  ContactBusinessInfoModel?: ContactBusinessInfoModelResolvers<ContextType>;
  ContactFastGridResponseOutputDto?: ContactFastGridResponseOutputDtoResolvers<ContextType>;
  ContactFastOutputDto?: ContactFastOutputDtoResolvers<ContextType>;
  ContactGridResponseOutputDto?: ContactGridResponseOutputDtoResolvers<ContextType>;
  ContactGroupContactModel?: ContactGroupContactModelResolvers<ContextType>;
  ContactGroupElasticResponse?: ContactGroupElasticResponseResolvers<ContextType>;
  ContactGroupModel?: ContactGroupModelResolvers<ContextType>;
  ContactOutputDto?: ContactOutputDtoResolvers<ContextType>;
  ContactPersonalInfoModel?: ContactPersonalInfoModelResolvers<ContextType>;
  ContinentDBModel?: ContinentDbModelResolvers<ContextType>;
  CountryDBModel?: CountryDbModelResolvers<ContextType>;
  CountryElasticResponse?: CountryElasticResponseResolvers<ContextType>;
  CustomerPriceCodesModel?: CustomerPriceCodesModelResolvers<ContextType>;
  CustomerPriceGroupsModel?: CustomerPriceGroupsModelResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DeletedOkModel?: DeletedOkModelResolvers<ContextType>;
  DepartmentModel?: DepartmentModelResolvers<ContextType>;
  DivisionDBModel?: DivisionDbModelResolvers<ContextType>;
  DivisionsCompanyModel?: DivisionsCompanyModelResolvers<ContextType>;
  EmailModel?: EmailModelResolvers<ContextType>;
  EmailServerModel?: EmailServerModelResolvers<ContextType>;
  EmailTypeElasticResponse?: EmailTypeElasticResponseResolvers<ContextType>;
  EmailTypeModel?: EmailTypeModelResolvers<ContextType>;
  EmailUserModel?: EmailUserModelResolvers<ContextType>;
  ExportRequestResponseDTO?: ExportRequestResponseDtoResolvers<ContextType>;
  FactoryTouchesReport?: FactoryTouchesReportResolvers<ContextType>;
  FactoryTouchesResults?: FactoryTouchesResultsResolvers<ContextType>;
  FailedReasonElasticResponse?: FailedReasonElasticResponseResolvers<ContextType>;
  FailedReasonOutputDto?: FailedReasonOutputDtoResolvers<ContextType>;
  GetAllSalesTeamMembersResponseModel?: GetAllSalesTeamMembersResponseModelResolvers<ContextType>;
  GetAllSalesTeamModel?: GetAllSalesTeamModelResolvers<ContextType>;
  GridPreferenceModel?: GridPreferenceModelResolvers<ContextType>;
  IIndustry?: IIndustryResolvers<ContextType>;
  InstanceSettings?: InstanceSettingsResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  JSONObject?: GraphQLScalarType;
  LandingWidget?: LandingWidgetResolvers<ContextType>;
  LeadSource?: LeadSourceResolvers<ContextType>;
  LeadSourceElasticResponse?: LeadSourceElasticResponseResolvers<ContextType>;
  LeadSourceOutputDto?: LeadSourceOutputDtoResolvers<ContextType>;
  LineItemStatusElasticResponse?: LineItemStatusElasticResponseResolvers<ContextType>;
  LineItemStatusOutputDto?: LineItemStatusOutputDtoResolvers<ContextType>;
  LocationModel?: LocationModelResolvers<ContextType>;
  MemberModel?: MemberModelResolvers<ContextType>;
  MenuReport?: MenuReportResolvers<ContextType>;
  MonthlySalesReport?: MonthlySalesReportResolvers<ContextType>;
  MonthlySalesReportResult?: MonthlySalesReportResultResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  OppOwner?: OppOwnerResolvers<ContextType>;
  OppStage?: OppStageResolvers<ContextType>;
  OppStatus?: OppStatusResolvers<ContextType>;
  OpportunitiesCompanyOutputDto?: OpportunitiesCompanyOutputDtoResolvers<ContextType>;
  OpportunitiesContactOutputDto?: OpportunitiesContactOutputDtoResolvers<ContextType>;
  OpportunitiesDto?: OpportunitiesDtoResolvers<ContextType>;
  OpportunitiesElasticResponse?: OpportunitiesElasticResponseResolvers<ContextType>;
  OpportunitiesOutputDto?: OpportunitiesOutputDtoResolvers<ContextType>;
  Options?: OptionsResolvers<ContextType>;
  PhoneDBModel?: PhoneDbModelResolvers<ContextType>;
  PhoneFast?: PhoneFastResolvers<ContextType>;
  PhoneTypeElasticResponse?: PhoneTypeElasticResponseResolvers<ContextType>;
  PhoneTypeModel?: PhoneTypeModelResolvers<ContextType>;
  PrimaryCustomerWideSales?: PrimaryCustomerWideSalesResolvers<ContextType>;
  PrivateTeamModel?: PrivateTeamModelResolvers<ContextType>;
  PrivateTeamResponse?: PrivateTeamResponseResolvers<ContextType>;
  PrivateTeamTypeModel?: PrivateTeamTypeModelResolvers<ContextType>;
  ProductInterestElasticResponse?: ProductInterestElasticResponseResolvers<ContextType>;
  ProductInterestModel?: ProductInterestModelResolvers<ContextType>;
  ProductLine?: ProductLineResolvers<ContextType>;
  ProductPotentialResponse?: ProductPotentialResponseResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RegionElasticResponse?: RegionElasticResponseResolvers<ContextType>;
  RegionModel?: RegionModelResolvers<ContextType>;
  RegionWideSales?: RegionWideSalesResolvers<ContextType>;
  RegisterUserSalesTeamModel?: RegisterUserSalesTeamModelResolvers<ContextType>;
  RelatedCategoryElasticResponse?: RelatedCategoryElasticResponseResolvers<ContextType>;
  RelatedCategoryModel?: RelatedCategoryModelResolvers<ContextType>;
  RelatedCompanyDBModel?: RelatedCompanyDbModelResolvers<ContextType>;
  RelatedCompanyElasticResponse?: RelatedCompanyElasticResponseResolvers<ContextType>;
  ReportListModel?: ReportListModelResolvers<ContextType>;
  ReportResponseDTO?: ReportResponseDtoResolvers<ContextType>;
  Role?: RoleResolvers<ContextType>;
  RoleBasicInformation?: RoleBasicInformationResolvers<ContextType>;
  RoleModel?: RoleModelResolvers<ContextType>;
  SalesByMonth?: SalesByMonthResolvers<ContextType>;
  SalesTeam?: SalesTeamResolvers<ContextType>;
  SalesTeamMemberModel?: SalesTeamMemberModelResolvers<ContextType>;
  SalesTeamModel?: SalesTeamModelResolvers<ContextType>;
  SalesTeamResponse?: SalesTeamResponseResolvers<ContextType>;
  SalesTeamTypeResponse?: SalesTeamTypeResponseResolvers<ContextType>;
  SalesTeamTypesModel?: SalesTeamTypesModelResolvers<ContextType>;
  Section?: SectionResolvers<ContextType>;
  SecurityTokenModel?: SecurityTokenModelResolvers<ContextType>;
  SomeColumnModel?: SomeColumnModelResolvers<ContextType>;
  SplitTypeResponse?: SplitTypeResponseResolvers<ContextType>;
  StageElasticResponse?: StageElasticResponseResolvers<ContextType>;
  StageGroupElasticResponse?: StageGroupElasticResponseResolvers<ContextType>;
  StageGroupModel?: StageGroupModelResolvers<ContextType>;
  StageGroupOutputDto?: StageGroupOutputDtoResolvers<ContextType>;
  StageOutputDto?: StageOutputDtoResolvers<ContextType>;
  State?: StateResolvers<ContextType>;
  StateDBModel?: StateDbModelResolvers<ContextType>;
  StatusElasticResponse?: StatusElasticResponseResolvers<ContextType>;
  StatusModel?: StatusModelResolvers<ContextType>;
  StatusOutputDto?: StatusOutputDtoResolvers<ContextType>;
  SubTableByCompanyElasticResponse?: SubTableByCompanyElasticResponseResolvers<ContextType>;
  SubTableStageByCompanyElasticResponse?: SubTableStageByCompanyElasticResponseResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  TeamWideSales?: TeamWideSalesResolvers<ContextType>;
  TenantInstance?: TenantInstanceResolvers<ContextType>;
  TerritoryDBModel?: TerritoryDbModelResolvers<ContextType>;
  TerritoryElasticResponse?: TerritoryElasticResponseResolvers<ContextType>;
  Type?: TypeResolvers<ContextType>;
  TypeElasticResponse?: TypeElasticResponseResolvers<ContextType>;
  TypeOutputDto?: TypeOutputDtoResolvers<ContextType>;
  UpdateCompanyBatchModel?: UpdateCompanyBatchModelResolvers<ContextType>;
  UpdateCompanyBatchResponseModel?: UpdateCompanyBatchResponseModelResolvers<ContextType>;
  UpdateContactBatchModel?: UpdateContactBatchModelResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  UserAdditionalFieldModel?: UserAdditionalFieldModelResolvers<ContextType>;
  UserAdditionalFieldsUserModel?: UserAdditionalFieldsUserModelResolvers<ContextType>;
  UserDBModel?: UserDbModelResolvers<ContextType>;
  UserEmailModel?: UserEmailModelResolvers<ContextType>;
  UserLandingWidget?: UserLandingWidgetResolvers<ContextType>;
  UserMenuModel?: UserMenuModelResolvers<ContextType>;
  UserModel?: UserModelResolvers<ContextType>;
  UserReportPreference?: UserReportPreferenceResolvers<ContextType>;
  UserReportWidgetList?: UserReportWidgetListResolvers<ContextType>;
  UserRoleModel?: UserRoleModelResolvers<ContextType>;
  UserTypeModel?: UserTypeModelResolvers<ContextType>;
  WideAJReport?: WideAjReportResolvers<ContextType>;
  WideAJReportResult?: WideAjReportResultResolvers<ContextType>;
  WideCommissionReport?: WideCommissionReportResolvers<ContextType>;
  WideCommissionSalesReport?: WideCommissionSalesReportResolvers<ContextType>;
  WideCommissionsReportResult?: WideCommissionsReportResultResolvers<ContextType>;
  WideCommissionsSalesReportResult?: WideCommissionsSalesReportResultResolvers<ContextType>;
  WidePOReport?: WidePoReportResolvers<ContextType>;
  WidePOReportResult?: WidePoReportResultResolvers<ContextType>;
  WideQuotesReport?: WideQuotesReportResolvers<ContextType>;
  WideQuotesReportResult?: WideQuotesReportResultResolvers<ContextType>;
  WideSalesReport?: WideSalesReportResolvers<ContextType>;
  WideSalesReportResult?: WideSalesReportResultResolvers<ContextType>;
  gridItem?: GridItemResolvers<ContextType>;
  phoneModel?: PhoneModelResolvers<ContextType>;
}>;
