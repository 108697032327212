import { SubTableColumn } from 'src/components/commons/subTable/types';

// eslint-disable-next-line import/prefer-default-export
export const filterIDAndAddingTitle = (dataSource: SubTableColumn[]): SubTableColumn[] =>
  dataSource
    .filter((el) => el.dataIndex !== 'id')
    .map((el) => ({
      ...el,
      title: el.headerName?.toLocaleUpperCase(),
      unique: el.key === 'name',
    }));
