import { gql } from '@apollo/client';

export const GET_ALL_PHONE_TYPES = gql`
  query GetAllPhoneTypes2($criteria: PhoneTypeCriteriaInput!, $searchFields: FindPhoneTypeInput) {
    GetAllPhoneTypes2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
        description
      }
      total
    }
  }
`;

export const GET_PHONE_TYPES_HEADERS = gql`
  query Query {
    GetPhoneTypeGridHeaders
  }
`;
