import { gql } from '@apollo/client';

export const GET_ALL_LEAD_SOURCES = gql`
  query GetAllLeadSources($criteria: LeadSourceCriteriaInput!) {
    GetAllLeadSources(Criteria: $criteria) {
      results {
        id
        name
        boolean
      }
      total
    }
  }
`;
