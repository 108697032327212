import { contactGroupAdapter } from 'src/adapters';
import { useEffect } from 'react';
import {
  CREATE_CONTACT_GROUP,
  DELETE_CONTACT_GROUP,
  UPDATE_CONTACT_GROUP,
} from 'src/graphql/mutations/contactGroup';
import { GET_ALL_CONTACT_GROUP, GET_CONTACT_GROUP } from 'src/graphql/queries/contactGroup';
import useEntityAdapter, { OperationSpecs } from '../useEntityAdapter';

const operations: OperationSpecs = {
  createOne: {
    op: CREATE_CONTACT_GROUP,
  },
  updateOne: {
    op: UPDATE_CONTACT_GROUP,
  },
  deleteOne: {
    op: DELETE_CONTACT_GROUP,
  },
  fetchAll: {
    op: GET_ALL_CONTACT_GROUP,
    dataPath: 'GetAllContactGroups2',
  },
  fetchOne: {
    op: GET_CONTACT_GROUP,
    dataPath: 'GetContactGroup',
  },
};

const useContactGroupStore = () => {
  const api = useEntityAdapter({
    adapters: { entityAdapter: contactGroupAdapter },
    operations,
    isPaginated: true,
    entityName: 'ContactGroup',
  });

  useEffect(() => {
    api.loadPage();
  }, []);

  return api;
};

export default useContactGroupStore;
