import { gql } from '@apollo/client';

export const CREATE_GRID_PREFERENCE = gql`
  mutation CreateGridPreference($createGridPreferenceInput: CreateGridPreferenceInput!) {
    CreateGridPreference(createGridPreferenceInput: $createGridPreferenceInput) {
      id
      userId
      gridName
      options
      name
      isDefault
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_GRID_PREFERENCE = gql`
  mutation UpdateGridPreference($updateGridPreferenceInput: UpdateGridPreferenceInput!) {
    UpdateGridPreference(updateGridPreferenceInput: $updateGridPreferenceInput) {
      id
      userId
      gridName
      options
      name
      isDefault
      createdAt
      updatedAt
    }
  }
`;

export const REMOVE_GRID_PREFERENCE = gql`
  mutation RemoveGridPreference($removeGridPreferenceId: Int!) {
    RemoveGridPreference(id: $removeGridPreferenceId)
  }
`;

export const SET_DEFAULT_GRID_PREFERENCE = gql`
  mutation SetDefaultGridPreference($setDefaultGridPreferenceId: Int!) {
    SetDefaultGridPreference(id: $setDefaultGridPreferenceId)
  }
`;

export default CREATE_GRID_PREFERENCE;
