import { useEffect } from 'react';
import { notification } from 'antd';
import { useSubscription, useLazyQuery } from '@apollo/client';
import { GET_REPORTS, GET_EXPORT_FILE_LINK } from 'src/services/graphqlSubscription/queries';

type StoredReportProps = {
  requestId: string;
  token: string;
};

const useApolloSubscriptionController = () => {
  const localStorageItemName = 'pendingStoredReports';
  const statusReady = 'ready';
  const pendingStoredReports = localStorage.getItem(localStorageItemName) || '';
  const parsedPendingStoredReports = pendingStoredReports ? JSON.parse(pendingStoredReports) : [];
  const subscription = useSubscription(GET_REPORTS, {
    onError: (error) => {
      notification.error({
        message: error.message,
        key: 'subscription-report-error',
      });
    },
  });
  const [getReportLink, reportLink] = useLazyQuery(GET_EXPORT_FILE_LINK, {
    onError: (error) => {
      notification.error({
        message: error.message,
        key: 'get-report-link-error',
      });
    },
  });

  const checkIfRequiredReport = (report: any) => {
    const requiredRecord = parsedPendingStoredReports?.find(
      (storedReport: StoredReportProps) => storedReport.requestId === report.requestId,
    );

    if (requiredRecord) {
      getReportLink({
        variables: {
          reportId: requiredRecord.requestId,
          token: requiredRecord.token,
        },
      });
    }
  };

  const removeStoredPendingReport = (requestId: string) => {
    const newPendingStoredRepors = parsedPendingStoredReports.filter(
      (storedReport: StoredReportProps) => storedReport.requestId !== requestId,
    );
    localStorage.setItem(localStorageItemName, JSON.stringify(newPendingStoredRepors));
    notification.destroy();
  };

  useEffect(() => {
    if (reportLink && reportLink.data && reportLink.data.GetExportFileLink) {
      const { link, requestId } = reportLink.data.GetExportFileLink;
      notification.success({
        placement: 'bottom',
        duration: 20,
        message: (
          <p>
            Your report was created successfully, to download it please click{' '}
            <a href={link} download onClick={() => removeStoredPendingReport(requestId)}>
              Here!
            </a>
          </p>
        ),
        key: 'download-file-notification',
      });
    }
  }, [reportLink]);

  useEffect(() => {
    if (subscription.data && subscription.data.reports) {
      const report = subscription?.data.reports;
      if (report.status === statusReady) {
        checkIfRequiredReport(report);
      }
    }
  }, [subscription.data]);
};

export default useApolloSubscriptionController;
