import { Route, Routes } from 'react-router';
import NotFoundPage from 'src/components/commons/notFound';
import UsersAndMembers from 'src/components/settingsTables/userAndMembers';
import { PageSubTableActivityJournals } from '../../pages';
import TabCannedNotes from './tabs/cannedNotes';
import TabCallType from './tabs/callType';
import TabProductLines from './tabs/productLines';

const SubTableActivityJournalsRoutes = () => (
  <Routes>
    <Route element={<PageSubTableActivityJournals />}>
      <Route index path='canned-notes' element={<TabCannedNotes />} />
      <Route path='call-type' element={<TabCallType />} />
      <Route path='product-lines' element={<TabProductLines />}>
        <Route index path='' element={<UsersAndMembers />} />
      </Route>
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  </Routes>
);

export default SubTableActivityJournalsRoutes;
