import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { LeadSourceModel } from '../models/leadSource.model';

export const leadSourceAdapter: AdapterFunc<GQL.LeadSourceOutputDto, LeadSourceModel> = (
  input,
) => ({
  id: input.id,
  name: input.name!,
  isDeletable: input.boolean!,
});
