import GenericForm from 'src/components/commons/genericForm';
import SectionCard from 'src/components/sectionCard/SectionCard';

const ExportCompaniesFilters = () => (
  <SectionCard title='Filters'>
    <GenericForm
      fields={[
        {
          name: 'modified',
          label: 'Last Modified',
          type: 'dateRange',
          span: 24,
          disabled: true,
        },
        {
          name: 'created',
          label: 'Creation Date',
          type: 'dateRange',
          span: 24,
          disabled: true,
        },
        {
          name: 'fileType',
          label: 'File Type',
          type: 'radio',
          initialValue: 'xlsx',
          options: [
            { label: 'Excel', value: 'xlsx' },
            { label: 'CSV', value: 'csv' },
          ],
        },
      ]}
    />
  </SectionCard>
);

export default ExportCompaniesFilters;
