import useEntityAdapter, { OperationSpecs } from 'src/hooks/useEntityAdapter';
import { GET_ALL_STAGE_GROUP } from '../graphql/queries';
import { CREATE_STAGE_GROUP, UPDATE_STAGE_GROUP, REMOVE_STAGE_GROUP } from '../graphql/mutations';
import { StageGroupModel } from '../models/stageGroup.model';
import { stageGroupAdapter } from '../adapters/stageGroup.adapter';

const operations: OperationSpecs = {
  createOne: {
    op: CREATE_STAGE_GROUP,
  },
  updateOne: {
    op: UPDATE_STAGE_GROUP,
  },
  deleteOne: {
    op: REMOVE_STAGE_GROUP,
  },
  fetchAll: {
    op: GET_ALL_STAGE_GROUP,
    dataPath: 'GetAllStageGroup',
  },
};

const useStageGroupStore = () => {
  const api = useEntityAdapter<StageGroupModel>({
    adapters: { entityAdapter: stageGroupAdapter },
    operations,
    isPaginated: true,
    entityName: 'StageGroup',
  });

  return api;
};
export default useStageGroupStore;
