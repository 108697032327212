import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import {
  CREATE_PRODUCT_POTENTIAL,
  REMOVE_PRODUCT_POTENTIAL,
  UPDATE_PRODUCT_POTENTIAL,
} from 'src/graphql/mutations/product-potentials';
import { GET_ALL_PRODUCT_POTENTIALS } from 'src/graphql/queries/product-potentials';
import {
  CompanyProductPotentialModel,
  Mutation,
  MutationRemoveProductPotentialArgs,
  MutationUpdateProductPotentialArgs,
  Query,
  QueryGetAllProductPotentialsArgs,
  UpdateProductPotentialInput,
} from 'src/graphql/schema-types';

type GetProductPotentialsResult = Pick<Query, 'GetAllProductPotentials'>;
type CreateProductPotentialResult = Pick<Mutation, 'CreateProductPotential'>;
type UpdateProductPotentialResult = Pick<Mutation, 'UpdateProductPotential'>;
type RemoveProductPotentialResult = Pick<Mutation, 'RemoveProductPotential'>;

const useProductPotentialsStore = () => {
  const [entities, setEntities] = useState<CompanyProductPotentialModel[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const [queryGetAll] = useLazyQuery<GetProductPotentialsResult, QueryGetAllProductPotentialsArgs>(
    GET_ALL_PRODUCT_POTENTIALS,
  );
  const [mutationDeleteOne] = useMutation<
    RemoveProductPotentialResult,
    MutationRemoveProductPotentialArgs
  >(REMOVE_PRODUCT_POTENTIAL);
  const [mutationUpdateOne] = useMutation<
    UpdateProductPotentialResult,
    MutationUpdateProductPotentialArgs
  >(UPDATE_PRODUCT_POTENTIAL);
  const [mutationCreateOne] = useMutation<
    CreateProductPotentialResult,
    MutationUpdateProductPotentialArgs
  >(CREATE_PRODUCT_POTENTIAL);

  const getAll = (variables: QueryGetAllProductPotentialsArgs) => {
    setLoading(true);
    return queryGetAll({
      variables,
    })
      .then((result) => {
        if (result.data?.GetAllProductPotentials?.results) {
          setEntities(result.data.GetAllProductPotentials.results);
          setTotal(result.data.GetAllProductPotentials.total!);
        }
        return result;
      })
      .catch((err) => {
        setEntities([]);
        setTotal(0);
        setError(err);
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeOne = (id: number) => {
    setDeleting(true);
    return mutationDeleteOne({
      variables: {
        id,
      },
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const updateOne = (updateProductPotentialInput: UpdateProductPotentialInput) => {
    setUpdating(true);
    mutationUpdateOne({
      variables: {
        updateProductPotentialInput,
      },
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const createOne = (updateProductPotentialInput: UpdateProductPotentialInput) => {
    setCreating(true);
    return mutationCreateOne({
      variables: {
        updateProductPotentialInput,
      },
    })
      .catch((err) => {
        setError(err);
        throw err;
      })
      .finally(() => {
        setCreating(false);
      });
  };

  return {
    entities,
    total,
    loading,
    updating,
    deleting,
    creating,
    error,
    getAll,
    removeOne,
    updateOne,
    createOne,
  };
};

export default useProductPotentialsStore;
