/* eslint react/jsx-no-useless-fragment: 0 */
/* eslint no-nested-ternary: 0 */

import React, { useEffect, useState } from 'react';
import { Checkbox, Select, Tag, Empty } from 'antd';
import _ from 'lodash';
import { SearchOutlined, TableOutlined } from '@ant-design/icons';
import { GridPreferenceNamesTypes } from 'src/types/companiesTypes';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

interface CustomSelectProps {
  options?: OptionType[];
  defaultValue?: string[] | string;
  style?: React.CSSProperties;
  handleMultipleSelect?: (value: string[], name?: string) => void;
  selectedColumns?: string[];
  columnsDefault?: { id: string; name: string }[];
  listDefault?: (string | OptionType)[];
  handleChange?: (e: React.FormEvent<HTMLInputElement>, name?: string) => void;
  addressOnChange?: (arg0: any) => void;
  dataTestId?: string;
  multiSel?: boolean;
  externalValue?: string | number | GridPreferenceNamesTypes;
  customClassName?: string;
  id?: string;
  disabled?: boolean;
  externalName?: string;
  columnSelection?: boolean;
  externalWidth?: string;
  internalWidth?: string;
  externalHeight?: string;
  internalHeight?: string;
  placeHolder?: string;
  allowClear?: boolean;
  isAddress?: boolean;
  onSearch?: (value: string) => void;
  ref?: React.Ref<any> | undefined;
  mode?: 'multiple' | 'tags' | undefined;
  tagRender?:
    | ((
        props: CustomTagProps,
      ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>)
    | undefined;
  showArrow?: boolean;
  loading?: boolean;
  maxTagCount?: number;
  addNewButton?: React.ReactNode;
  addNewButtonFunction?: () => void;
  twoLines?: boolean;
}

export interface OptionType {
  [x: string]: any;
  label: string;
  value: string | number;
}

interface FilterOption {
  children?: React.ReactNode;
  label?: string;
  value?: string | number;
}

const { Option } = Select;

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  handleChange,
  defaultValue,
  handleMultipleSelect,
  selectedColumns,
  columnsDefault,
  listDefault,
  style,
  multiSel,
  dataTestId = 'custom-select-id',
  externalValue,
  customClassName,
  addressOnChange,
  isAddress,
  id,
  disabled,
  externalName,
  columnSelection,
  externalWidth,
  externalHeight,
  placeHolder,
  allowClear,
  tagRender,
  onSearch,
  ref,
  mode,
  loading: loadingProps,
  showArrow = true,
  maxTagCount,
  internalHeight,
  internalWidth,
  addNewButton,
  addNewButtonFunction,
  twoLines,
}) => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredColumnOptions, setFilteredColumnOptions] = useState<
    { id: string; name: string }[]
  >([]);
  const [filteredListOptions, setFilteredListOptions] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [currentPlaceHolder, setCurrentPlaceHolder] = useState(placeHolder);
  const [optionDescriptions, setOptionDescriptions] = useState<any[]>();
  const regexp = /^{{%(.+)%}}/;

  useEffect(() => {
    setLoading(loadingProps!);
  }, [loadingProps]);

  useEffect(() => {
    if (selectedColumns) {
      const listOptions = selectedColumns.map((option: any) => `{{%${option.id}%}}${option.name}`);
      setSelectedOptions(listOptions);
    }
  }, [selectedColumns]);

  useEffect(() => {
    if (Array.isArray(defaultValue) && defaultValue.length > 0) {
      setSelectedOptions(defaultValue);
    }
  }, [defaultValue]);

  const inetrnalHandleMultipleSelect = (value: any) => {
    if (handleMultipleSelect) {
      if (externalName) {
        handleMultipleSelect(value, externalName);
      } else {
        handleMultipleSelect(value);
      }
    }
  };

  const internalChangeHandler = (value: React.FormEvent<HTMLInputElement>) => {
    if (handleChange) {
      if (externalName) {
        handleChange(value, externalName);
      } else {
        handleChange(value);
      }
    }
  };

  const customDropdownRender = () => (
    <>
      {addNewButton}
      <div style={{ marginTop: '7px', marginBottom: '5px' }}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='No data'
          style={{ marginBlock: 0 }}
        />
      </div>
    </>
  );

  const renderOptionWithCheckboxColumn = (option: { id: string; name: string }) => {
    const isChecked = selectedColumns?.includes(option.id);
    return (
      <div style={{ display: 'flex', alignItems: 'center', padding: '0px !important' }}>
        <Checkbox checked={isChecked} style={{ marginRight: 5 }} />
        {option.name}
      </div>
    );
  };

  const renderOptionWithCheckboxMulti = (
    option: string | { id: string; name: string; desc?: string },
  ) => {
    const isChecked = selectedOptions?.some((column: any) => {
      const name = column.replace(regexp, '');
      return name === option;
    });

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: twoLines ? '4px 10px' : '0px !important',
        }}
      >
        <Checkbox checked={isChecked} style={{ marginRight: twoLines ? 11 : 5 }} />
        {!twoLines && <p>{typeof option === 'string' ? option : option.name}</p>}
        {twoLines && (
          <div>
            <p style={{ fontSize: '14px', fontWeight: 400, lineHeight: '22px', color: '#353535' }}>
              {typeof option === 'string' ? option : option.name}
            </p>
            <p style={{ fontSize: '12px', fontWeight: 400, lineHeight: '16px', color: '#9A9A9A' }}>
              {typeof option === 'string' &&
                optionDescriptions?.find((e) => e?.label === option)?.desc}
            </p>
          </div>
        )}
      </div>
    );
  };

  const filterOptions = (input: string, option?: FilterOption) => {
    if (!option) return false;

    if (typeof option.children === 'string') {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
    if (option.label) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
    return false;
  };

  useEffect(() => {
    const listDefaultOptionsDesc = listDefault?.map((e) => ({
      label: typeof e !== 'string' && e.label,
      desc: typeof e !== 'string' && e.desc,
    }));
    setOptionDescriptions(listDefaultOptionsDesc);
    if (columnsDefault && columnSelection) {
      if (searchValue) {
        const filtered = columnsDefault.filter((option) =>
          option.name.toLowerCase().includes(searchValue.toLowerCase()),
        );
        setFilteredColumnOptions(filtered);
      } else {
        setFilteredColumnOptions(columnsDefault);
      }
    }
    if (listDefault) {
      const listOptions = listDefault.map((option) => {
        if (typeof option !== 'string' && option.desc) {
          return `{{%${option.value}%}}${option.label}`;
        }
        const value = typeof option === 'string' ? option : `{{%${option.value}%}}${option.label}`;
        return value;
      });

      if (searchValue) {
        const filtered = listOptions.filter((option) =>
          option.toLowerCase().includes(searchValue.toLowerCase()),
        );
        setFilteredListOptions(filtered);
      } else {
        setFilteredListOptions(listOptions);
      }
    }
  }, [searchValue, columnsDefault, listDefault]);

  const customTagRender = () => <></>;

  const [isFocused, setIsFocused] = useState(false);

  if (columnSelection) {
    return (
      <Select
        tagRender={customTagRender}
        showArrow={showArrow}
        allowClear={allowClear}
        suffixIcon={
          !searchValue && isFocused ? (
            <div style={{ fontSize: '14px', color: '#C6C4C4', width: '134px', cursor: 'default' }}>
              Please Select
              <SearchOutlined style={{ paddingLeft: '40px' }} />
            </div>
          ) : !searchValue && !isFocused ? (
            <div style={{ fontSize: '14px', width: '175px', cursor: 'default', color: '#4B4B4B' }}>
              <TableOutlined style={{ marginRight: '8px', color: '#0078D4' }} />
              Columns
            </div>
          ) : null
        }
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        searchValue={searchValue}
        onSearch={(e) => setSearchValue(e)}
        data-testid={dataTestId || 'columns-select-id'}
        mode='multiple'
        style={{ width: externalWidth || '150px', height: externalHeight || '32px' }}
        dropdownRender={customDropdownRender}
        value={selectedColumns}
        onChange={inetrnalHandleMultipleSelect}
        defaultValue={['Columns']}
        loading={loading}
        {...{ id }}
        {...{ disabled }}
      >
        {filteredColumnOptions.map((option) => (
          <Option
            key={option.id}
            value={option.id}
            style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white' }} // This ensures the checkbox aligns correctly with the label
          >
            {renderOptionWithCheckboxColumn(option)}
          </Option>
        ))}
      </Select>
    );
  }

  let multiselDefaultValue;
  if (typeof defaultValue === 'object') {
    multiselDefaultValue = defaultValue;
  } else if (typeof defaultValue === 'string') {
    multiselDefaultValue = [defaultValue];
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    multiselDefaultValue = undefined;
  }

  const maxTagPlaceholder = () => (
    <Tag style={{ background: '#DCDFFB', border: '1px solid #0078D4' }}>
      {selectedOptions.length || defaultValue?.length} Total
    </Tag>
  );

  const tagRenderFixed = (props: { value: string }) => {
    const onTagClose = () => {
      const newSelectedOptions = selectedOptions.filter((option) => option !== props.value);
      setSelectedOptions(newSelectedOptions);
      inetrnalHandleMultipleSelect(newSelectedOptions);
    };

    return (
      <Tag
        closable
        onClose={() => {
          onTagClose();
        }}
      >
        {props.value.replace(regexp, '')}
      </Tag>
    );
  };

  // const handlevalues = (defaultValue: string[], selectedOptions: string[]) => {
  //   let newSelectedOptions = [];
  //   if (selectedOptions?.length > 0 && defaultValue?.length > 0) {
  //     // create a set of values to prevent duplicates
  //     const set = new Set([selectedOptions, defaultValue].flat());
  //     newSelectedOptions = Array.from(set);
  //     return newSelectedOptions;
  //   }
  //   return selectedOptions || defaultValue;
  // };
  return multiSel ? (
    <Select
      showArrow={showArrow}
      maxTagCount={maxTagCount || 'responsive'}
      maxTagPlaceholder={maxTagPlaceholder}
      tagRender={(props) => tagRenderFixed(props)}
      ref={ref}
      loading={loading}
      placeholder={currentPlaceHolder || 'Please select'}
      // defaultValue={multiselDefaultValue}
      allowClear={allowClear}
      onFocus={() => setCurrentPlaceHolder('Please select')}
      onBlur={() => setCurrentPlaceHolder(placeHolder)}
      showSearch
      onSearch={onSearch}
      data-testid={dataTestId || 'columns-select-id'}
      mode='multiple'
      style={{ width: externalWidth || '130px', height: externalHeight || '32px' }}
      className={customClassName}
      dropdownRender={filteredListOptions?.length === 0 ? customDropdownRender : undefined}
      onChange={(selectedValues) => {
        const filteredArray = _.filter(selectedValues, (el) => !_.isNull(el));
        const isNull = _.some(selectedValues, (element) => element === null);
        if (isNull && addNewButtonFunction) {
          addNewButtonFunction();
        }
        setSelectedOptions(filteredArray);
        inetrnalHandleMultipleSelect(filteredArray);
      }}
      // value={handlevalues(defaultValue as string[], selectedOptions)}
      value={selectedOptions}
      {...{ id }}
      {...{ disabled }}
    >
      {addNewButton}
      {filteredListOptions?.map((column, index) => {
        let optionToShow;
        if (column.includes('.')) {
          optionToShow =
            column.split('.')[1] === 'LABEL' || column.split('.')[1] === 'NAME'
              ? column.split('.')[0].replace(/_/g, ' ')
              : column.split('.')[1].replace(/_/g, ' ');
        } else {
          optionToShow = column.replace(/_/g, ' ');
        }

        return (
          <Option
            style={{
              width: internalWidth || externalWidth || '130px',
              height: internalHeight || externalHeight || '32px',
              display: 'flex',
              alignItems: 'center',
              padding: twoLines ? '5px !important' : '0px !important',
            }}
            key={column}
            data-testid={`columns-options-id-${index}`}
          >
            {renderOptionWithCheckboxMulti(optionToShow.replace(/^{{%.*%}}/, ''))}
          </Option>
        );
      })}
    </Select>
  ) : (
    <Select
      showArrow={showArrow}
      mode={mode}
      loading={loading}
      ref={ref}
      defaultValue={defaultValue as unknown as React.FormEvent<HTMLInputElement>}
      style={{ ...style, minWidth: '130px' }}
      placeholder={placeHolder || 'Please select'}
      className={customClassName}
      data-testid={dataTestId}
      onChange={isAddress ? addressOnChange : internalChangeHandler}
      options={options}
      value={(externalValue as unknown as React.FormEvent<HTMLInputElement>) || defaultValue}
      showSearch
      filterOption={filterOptions}
      {...{ id }}
      {...{ disabled }}
      tagRender={tagRender}
      onSearch={onSearch}
      // eslint-disable-next-line react/no-unstable-nested-components
      optionRender={(option) => {
        if (twoLines && options) {
          return (
            <>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  color: '#353535',
                }}
              >
                {option.data.label}
              </p>
              <p
                style={{
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '16px',
                  color: '#9A9A9A',
                }}
              >
                {option.data.desc}
              </p>
            </>
          );
        }
        return option.data.label;
      }}
      // eslint-disable-next-line react/no-unstable-nested-components
      dropdownRender={(menu) => {
        if (twoLines && options) {
          return (
            <div className='custom-dropdown-render-container'>
              {addNewButton}
              {menu}
            </div>
          );
        }
        return <>{menu}</>;
      }}
    />
  );
};

export default CustomSelect;
