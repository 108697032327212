import styled from 'styled-components';

const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 64px);
`;

export default LandingContainer;
