import { gql } from '@apollo/client';

export const GET_ALL_EMAIL_TYPES = gql`
  query GetAllEmailTypes2($criteria: EmailTypeCriteriaInput!, $searchFields: FindEmailTypeInput) {
    GetAllEmailTypes2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
      }
      total
    }
  }
`;

export const GET_EMAIL_TYPES_HEADERS = gql`
  query Query {
    GetEmailTypeGridHeaders
  }
`;
