import { SubTableColumn } from 'src/components/commons/subTable/types';

export const COLUMN: SubTableColumn[] = [
  {
    title: 'Name',
    dataIndex: 'company',
    key: 'name',
    editable: true,
    width: 160,
    unique: true,
    required: true,
    dataType: 'string',
    hidden: false,
    dataStructure: 'string',
    headerName: 'Name',
  },
];
