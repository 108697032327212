/* eslint-disable no-param-reassign */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { VList } from 'virtuallist-antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { isEqual, reduce, some } from 'lodash';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  StarFilled,
  SearchOutlined,
  ProfileOutlined,
  EllipsisOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { PredefinedTypesElasticTypes } from 'src/modules/settings/modules/subTables/modules/company/tabs/type/types';
import { Table, notification, Row, Badge } from 'antd';
import { NewEditableCell } from 'src/components/commons/newEditableCell';
import { ResizeHandlerBuilder, SubTableColumn, SubTableGridProps } from '../types';
import convertPropToNumber, { showConfirmationMessage } from '../helpers';
import getEditableRowComponent from '../components/editableRow';
import FilterableColumnTitle from '../components/filterableTitle';
import CustomButton from '../../customButton';
import getActionsCellData from '../components/actionsCell';
import ResizableTitle from '../components/resizableTitle';
import { useSubTableContext } from '../contexts/subtable.context';
import { RecordType } from '../../createCompany/types';
import useNavigationPerformance from '../../../../hooks/useNavigationPerformance';

const makeClickable = (column: SubTableColumn) => ({
  ...column,
  onRow: (record: RecordType) => ({
    ...column,
    record,
  }),
});

const makeEditable = (column: SubTableColumn) =>
  ({
    ...column,
    onCell: (record: RecordType) => ({
      record,
      editable: column.editable,
      dataIndex: column.dataIndex,
      hasLink: column?.hasLink,
      dataKey: column.dataKey,
      title: column.title,
      render: column.render,
      // implement here the handleSave callback
    }),
  } as SubTableColumn);

const makeFilterable = (column: SubTableColumn) =>
  ({
    ...column,
    filterable: column.filterable,
    title: <FilterableColumnTitle column={column} />,
  } as SubTableColumn);

const saveScrollPosition = (id: string) => {
  const scrollViewport = document.querySelector(`#${id} .ant-table-body`);
  const scrollPosition = sessionStorage.getItem(`subtable-${id}-position`);
  const scrollTop =
    scrollViewport?.scrollTop && scrollViewport.scrollTop > 0
      ? scrollViewport.scrollTop
      : JSON.parse(scrollPosition ?? '0');
  sessionStorage.setItem(`subtable-${id}-position`, JSON.stringify(scrollTop));
};

const getLastScrollPosition = (id: string) => {
  const scrollPosition = sessionStorage.getItem(`subtable-${id}-position`);
  return scrollPosition ? JSON.parse(scrollPosition) : 0;
};

const makeResizable = (handler: ResizeHandlerBuilder) => (column: SubTableColumn, index: number) =>
  ({
    ...column,
    onHeaderCell: (col: SubTableColumn) => ({
      ...column,
      width: col.width || column.width,
      onResize: handler(index),
    }),
  } as SubTableColumn);

const useTable = (props: SubTableGridProps) => {
  const vidRef = useRef(Math.random().toString(36));
  const wasEditedRef = useRef(false);
  const initializedUnsaved = useRef(false);
  const initializedData = useRef(false);
  const {
    scroll,
    components,
    columns,
    nonSelectable,
    expandable,
    nonDeletable,
    showAutoIncrement = false,
    pagination,
    total,
    page,
    pageSize,
    actionCell,
    deleteIcon = (color: string) => <DeleteOutlined style={{ color, fontSize: 24 }} />,
    favIcon = <StarFilled />,
    editIcon = <EditOutlined />,
    detailsIcon = <EyeOutlined />,
    ellipsisIcon = <EllipsisOutlined />,
    onFavClick,
    onEditClick,
    onDeleteClick,
    onDetailsClick,
    onPaginationChange,
    allowGlobalEditActive,
    handleUpdateRows,
    postData,
    data,
    newRecord,
    resetNewRecord,
    // saveNewRecord,
    dataToCompare,
    setColumns,
    contentOnDelete,
    titleOnDelete,
    keyExpandableRow,
    addWithModal,
    notEditWithModal,
    clickable,
    clearFilter,
    editRecord,
    showDetailsIcon,
    filterCount,
    showFilterBadge,
    handleClearFilter,
    handleAllRowActions,
  } = props;

  const {
    setSelectedRows,
    items,
    dataTable,
    setDataTable,
    addNewRecord,
    setAddNewRecord,
    isEditing,
    setIsEditing,
    isFiltering,
    setIsFiltering,
    setFilterFields,
    storageKey,
    storageType,
    // rowsEdited,
    setRowsEdited,
    onRowClick,
    setOpenModal,
    setRecord,
    setOptionSelectedFromSelect,
  } = useSubTableContext();

  const [tableColumns, setTableColumns] = useState<SubTableColumn[]>(
    columns.filter((col) => col.gridVisible !== false),
  );
  const [tableDataSource, setTableDataSource] = useState(dataTable);
  const lastListInfoRef = useRef({ start: 0, renderLen: 0 });
  const amountEnter = useRef(0);
  const [clickableRow, setClickableRow] = useState(-1);
  const newData = useRef<any>();
  const [isHovering, setIsHovering] = useState(false);
  const { isReload, navigationType } = useNavigationPerformance();

  const handleFilterClick = (isFilteringArg: boolean) => {
    if (isFiltering) {
      clearFilter?.();
    }
    setIsFiltering(!isFilteringArg);
    setFilterFields?.({});
  };

  const handleResize: ResizeHandlerBuilder =
    (index) =>
    (e, { size }) => {
      setTableColumns((prevColumns) => {
        const nextColumns = [...prevColumns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const deleteNewRecord = () => {
    setAddNewRecord(false);
  };

  useEffect(() => {
    // this is needed because the div used to display the badge is not being removed when the filtercount becomes 0, please do not remove
    setIsHovering(true);
    setTimeout(() => setIsHovering(false), 10);
  }, [filterCount]);

  useEffect(() => {
    const cols = columns
      .map(makeClickable)
      .map(makeEditable)
      .map(makeFilterable)
      .map(makeResizable(handleResize));

    if (actionCell) {
      const { width, render } = getActionsCellData({
        actionCell,
        editIcon,
        deleteIcon,
        detailsIcon,
        favIcon,
        onFavClick,
        onEditClick,
        onDeleteClick,
        onDetailsClick,
        contentOnDelete,
        titleOnDelete,
        deleteNewRecord,
        ellipsisIcon,
        items,
        addNewRecord,
        handleAllRowActions,
      });
      const activateFilter = (
        <Row align='middle' justify='center'>
          {showFilterBadge && (
            <div onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
              {isHovering ? (
                <Badge
                  count={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        border: '2px solid #0078D4',
                        padding: '3px',
                        zIndex: 99999,
                      }}
                      onClick={handleClearFilter}
                    >
                      <CloseOutlined
                        style={{
                          fontSize: '10px',
                        }}
                      />
                    </div>
                  }
                  className={
                    isFiltering ? 'badge-clear-position-active' : 'badge-clear-position-disable'
                  }
                />
              ) : (
                <Badge
                  count={filterCount}
                  style={{
                    zIndex: 9999999,
                    display: filterCount ? 'block' : 'none',
                  }}
                  className={isFiltering ? 'badge-position-active' : 'badge-position-disabled'}
                  color='#0078D4'
                />
              )}
            </div>
          )}

          <CustomButton
            dataTestId='subtable-filter-button'
            key='filter'
            shape='circle'
            type='text'
            tooltipText={isFiltering ? 'Disable Column Search' : 'Enable Column Search'}
            ClassName={
              isFiltering ? 'searchOutlined-button-active' : 'searchOutlined-button-inactive'
            }
            placement='bottom'
            onlyIcon
            tooltipColor='#fff'
            onClick={() => handleFilterClick(isFiltering)}
            icon={
              <SearchOutlined
                className={
                  isFiltering ? 'searchOutlined-icon-ative' : 'searchOutlined-icon-inactive'
                }
              />
            }
            overlayInnerStyle={{ color: 'black' }}
          />
        </Row>
      );
      cols.push({
        title: activateFilter,
        dataIndex: '',
        key: 'x',
        fixed: 'right',
        width,
        render,
      });
    }

    setTableColumns(cols as []);
  }, [columns, nonDeletable, isFiltering, addNewRecord, isHovering]);

  useEffect(() => {
    if (addNewRecord) {
      resetNewRecord?.();
      setOptionSelectedFromSelect('');
      const recordObj: any = { ...newRecord, newRecord: true };

      if (dataTable !== undefined) {
        setTableDataSource([recordObj, ...dataTable]);
        if (document?.getElementsByClassName('ant-table-body').length === 2) {
          document
            ?.getElementsByClassName('ant-table-body')
            ?.item(1)
            ?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
          document
            ?.getElementsByClassName('ant-table-body')
            ?.item(0)
            ?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
      }
    } else {
      setTableDataSource(dataTable);
    }
    if (clickableRow === undefined || clickableRow === -1) {
      if (!dataTable?.[0]?.placeholder) {
        setClickableRow(dataTable?.[0]?.id || dataTable?.[0]?.ID || dataTable?.[0]?.key);
      }
    }
  }, [addNewRecord, dataTable]);

  // useEffect(() => {
  //   if (!addNewRecord) saveNewRecord?.();
  // }, [addNewRecord]);

  useEffect(() => {
    if (newRecord && newRecord[`${keyExpandableRow}`]) {
      let recordObj: any = {};
      recordObj = { ...newRecord, newRecord: true };
      if (dataTable !== undefined) {
        setTableDataSource([recordObj, ...dataTable]);
      }
    } else if (newRecord && newRecord[`${keyExpandableRow}`] === null) {
      let recordObj: any = {};
      recordObj = { ...newRecord, newRecord: true };
      if (dataTable !== undefined) {
        setTableDataSource([recordObj, ...dataTable]);
      }
    }
  }, [newRecord, setTableDataSource]);

  useEffect(() => {
    if (newRecord?.autoSave) {
      amountEnter.current = 0;
      setAddNewRecord(false);
      setIsEditing(true);
    }
  }, [newRecord, setAddNewRecord]);

  const height = useMemo(() => {
    if (scroll?.y) {
      if (typeof scroll.y === 'number') {
        return scroll.y;
      }
      if (typeof scroll.y === 'string') {
        return convertPropToNumber(scroll.y);
      }
    }
    return 0;
  }, [scroll]);

  const handleListRender = (listInfo: { start: number; renderLen: number }) => {
    const { onListRender } = props;

    if (
      lastListInfoRef.current.start === listInfo.start &&
      lastListInfoRef.current.renderLen === listInfo.renderLen
    ) {
      return;
    }

    if (onListRender) {
      if (initializedData.current) {
        saveScrollPosition(props.id || 'subtable');
      }
      onListRender(listInfo);
    }
    lastListInfoRef.current = listInfo;
  };

  const handleAddNewRecord = () => {
    if (newData.current) {
      let allowToSave = true;
      let isNotUnique = false;
      columns.forEach((element) => {
        if (element.required) {
          const tempRequiredValueInPayload =
            newData.current[element.dataIndex as keyof typeof newData.current];
          const tempDataIndex: any = element.dataIndex;
          if (tempRequiredValueInPayload && tempDataIndex) {
            const tempUniqueValueInPayload = {
              [tempDataIndex]: tempRequiredValueInPayload,
            };
            isNotUnique = some(dataTable, tempUniqueValueInPayload);
          }
          if (!tempRequiredValueInPayload) {
            allowToSave = false;
          }
        }
      });
      if (allowToSave && !isNotUnique) {
        if (postData) {
          postData(newData.current);
        }
        setAddNewRecord(!addNewRecord);
        newData.current = undefined;
      } else {
        showConfirmationMessage({
          title: 'Unable to save new record',
          content: (
            <div
              style={{
                background: '#FFFCEB',
                border: '1px solid #FAAD14',
                padding: '10px 10px',
                borderRadius: '5px',
              }}
            >
              All required fields must be completed in order to save new record.
            </div>
          ),
          okText: 'Continue Editing',
          cancelText: 'Discard',
          onCancel: () => {
            if (addNewRecord) {
              setAddNewRecord(!addNewRecord);
            }
          },
        });
      }
    } else {
      setAddNewRecord(!addNewRecord);
    }
  };

  const handleRowClick = (record: RecordType, index?: number) => {
    setClickableRow(record.id || record.ID || record.key);
    if (onRowClick) {
      onRowClick(record, index);
    }
  };

  const VComponents = useMemo(
    () =>
      !pagination
        ? VList({
            vid: vidRef.current,
            height,
            onListRender: handleListRender,
            resetTopWhenDataChange: false,
          })
        : {},
    [],
  );

  const handleDragEnd = (fromIndex: number, toIndex: number) => {
    if (!nonSelectable) {
      fromIndex -= 1;
      toIndex -= 1;
    }
    if (expandable) {
      fromIndex -= 1;
      toIndex -= 1;
    }
    if (showDetailsIcon) {
      fromIndex -= 1;
      toIndex -= 1;
    }

    // Adjusting for visible columns if filtering by selection is necessary
    const visibleColumns = columns.filter((col) => col.selected !== false);
    const globalFromIndex = columns.findIndex((col) => col === visibleColumns[fromIndex]);
    const globalToIndex = columns.findIndex((col) => col === visibleColumns[toIndex]);

    // Copy the columns to avoid direct state mutation
    const updatedColumns = [...columns];

    // Extract and move the column without modifying the array yet
    const item = updatedColumns[globalFromIndex];

    // Before actually moving the column, let's check the conditions for unpinning
    if (globalToIndex === 0 || globalToIndex === 1) {
      if (!item.pinned) {
        // If the column being moved is not pinned, ensure the target slot is unpinned if it was pinned
        // This is crucial if you're moving a non-pinned column to a position that was originally pinned
        updatedColumns[globalToIndex].pinned = false;
        updatedColumns[globalToIndex + 1].pinned = false;
      }
    } else {
      // For moving into non-pinned positions, check if it should be unpinned based on neighbor's status
      // This part remains unchanged, just repositioned
      const neighborBeforePinned = updatedColumns[globalToIndex - 1]?.pinned || false;
      const neighborAfterPinned = updatedColumns[globalToIndex]?.pinned || false; // Adjusted to check correctly after move

      if (!neighborBeforePinned && !neighborAfterPinned) {
        item.pinned = false;
      }
    }
    if (globalFromIndex === 0) {
      if (globalToIndex > 1) {
        item.pinned = false;
      }
      if (globalToIndex === 1 && !updatedColumns[globalToIndex]?.pinned) {
        item.pinned = false;
      }
    }

    // Now, move the column
    updatedColumns.splice(globalFromIndex, 1); // Remove the item
    updatedColumns.splice(globalToIndex, 0, item); // Insert it at the new position

    // Update the columns state
    setColumns?.(updatedColumns);
  };

  const updateGridData = (editedRow: PredefinedTypesElasticTypes) => {
    const innerNewData = [...(dataTable as any[])] as unknown as PredefinedTypesElasticTypes[];
    const index = innerNewData.findIndex((item) => editedRow.id === item.id);
    const item = innerNewData[index];
    innerNewData.splice(index, 1, {
      ...item,
      ...editedRow,
    });
    setDataTable(innerNewData);
    return item;
  };

  const handleSave = (row: PredefinedTypesElasticTypes) => {
    const innerData = updateGridData(row);
    const objetcsAreEqual = isEqual(innerData, row);
    if (!objetcsAreEqual) {
      setRowsEdited([row]);
      handleUpdateRows?.([row]);
      const [difference] = reduce(
        innerData,
        (result: any, value, key) =>
          isEqual(value, row[key as unknown as keyof typeof row]) ? result : [...result, key],
        [],
      );
      if (editRecord) {
        const changedValue = {
          id: row.id,
          [difference]: row[difference as unknown as keyof typeof row],
        };
        editRecord(changedValue);
      }
    }
  };

  const handleNewData = (recordData: any) => {
    newData.current = { ...newData.current, ...recordData };
  };

  const storage = useMemo(() => {
    if (!storageKey) {
      return null;
    }
    if (storageType === 'session') {
      return window.sessionStorage;
    }
    return window.localStorage;
  }, [storageType, storageKey]);

  useEffect(() => {
    setDataTable(data);
  }, [data]);

  useEffect(() => {
    if (isEditing) {
      wasEditedRef.current = true;
    } else if (wasEditedRef.current) {
      if (storageKey) {
        storage?.removeItem(storageKey);
      }
    }
  }, [isEditing]);

  // useEffect(() => {
  //   if (isEditing) setAddNewRecord(false);
  // }, [isEditing]);

  useEffect(() => {
    if (dataTable?.length && !initializedUnsaved.current) {
      initializedUnsaved.current = true;
      if (storage) {
        const unsavedSerialized = storage.getItem(storageKey!);
        if (unsavedSerialized) {
          try {
            const unsavedData = JSON.parse(unsavedSerialized);
            if (unsavedData) {
              let count = 0;
              Object.entries<Record<string, any>>(unsavedData).forEach(([key, value]) => {
                if (Object.keys(value).length) {
                  handleSave({ id: Number(key), ...value } as any);
                  count += 1;
                }
              });
              if (count > 0) {
                notification.warning({
                  message: 'Unsaved data',
                  description: 'You have unsaved data. Enter to edit mode to save them.',
                });
              }
            }
          } catch {
            storage.removeItem(storageKey!);
          }
        }
      }
    }
  }, [dataTable]);

  // Adding extra information to the onCell method
  const tableColumnsModified = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      fixed: col.pinned ? 'left' : undefined,
      onCell: (record: SubTableColumn) => ({
        record,
        editable: allowGlobalEditActive && col?.editable,
        dataIndex: col.dataIndex,
        title: col.headerName || col.title,
        dataKey: col.key,
        hasLink: col?.hasLink,
        dataType: col.dataType,
        options: col.options,
        unique: col?.unique,
        required: col?.required,
        addNewRecord,
        setAddNewRecord,
        handleAddNewRecord,
        render: col?.render,
        handleSave,
        handleNewData,
        newRecord,
        dataToCompare,
        data,
        tableColumns,
        onClick: () => {
          if (addWithModal && !notEditWithModal) {
            setOpenModal(true);
            if (!isEditing) setRecord(record);
          } else if (!isEditing) setRecord(record);
        },
      }),
    };
  });

  if (showAutoIncrement) {
    if (!nonSelectable) {
      tableColumnsModified.unshift(Table.SELECTION_COLUMN as any);
    }
    tableColumnsModified.unshift({
      width: 50,
      minWidth: 50,
      render(value, record, index) {
        if (addNewRecord && index === 0) {
          return (
            <span
              style={{
                fontSize: 12,
                fontWeight: 500,
                color: '#0078D4',
              }}
            >
              New
            </span>
          );
        }
        return (
          <span
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: '#0078D4',
            }}
          >
            {addNewRecord ? index : index + 1}
          </span>
        );
      },
      title: ' ',
      dataIndex: undefined!,
    });
  }

  if (showDetailsIcon) {
    tableColumnsModified.unshift({
      width: 50,
      minWidth: 50,
      fixed: 'left',
      render(value, record, index) {
        return (
          <CustomButton
            key={index}
            shape='circle'
            style={{ background: 'none', boxShadow: 'none' }}
            onlyIcon
            tooltipText='Details'
            tooltipColor='#fff'
            overlayInnerStyle={{ color: '#000' }}
            icon={<ProfileOutlined />}
            onClick={() => onDetailsClick && onDetailsClick(record?.id)}
          />
        );
      },
      title: ' ',
      dataIndex: undefined!,
    });
  }
  const scrollToPosition = (id: string, position: number) => {
    const element = document.querySelector(`#${id} .ant-table-body`);
    if (element) {
      element.scrollTop = position;
    }
  };

  useEffect(() => {
    if (dataTable?.length && dataTable.length! > 10 && !initializedData.current) {
      setTimeout(() => {
        if (navigationType === 'POP' || navigationType === 'PUSH' || isReload) {
          const lastScrollPosition = getLastScrollPosition(props.id || 'subtable');
          initializedData.current = true;
          scrollToPosition(props.id || 'subtable', lastScrollPosition);
        }
      }, 1000);
    }
  }, [dataTable]);

  const TrComponent = VComponents?.body?.row || 'tr';

  const EditableRow = components?.body?.row
    ? components.body.row(TrComponent)
    : getEditableRowComponent(TrComponent);

  const tableComponents = {
    ...VComponents,
    header: {
      ...VComponents.header,
      cell: ResizableTitle,
    },
    body: {
      ...VComponents.body,
      row: EditableRow,
      cell: NewEditableCell,
    },
  };

  const rowSelection: TableRowSelection<RecordType> | undefined =
    nonSelectable && !props.selectedRows
      ? undefined
      : {
          type: nonSelectable ? 'radio' : 'checkbox',
          onChange: (_, rows) => {
            setSelectedRows(rows);
          },
          onSelect: (record) => {
            if (onRowClick) {
              onRowClick(record);
            }
          },
          fixed: 'left',
          columnWidth: clickable ? 4 : 50,
          selectedRowKeys: clickable ? [clickableRow] : undefined,
        };

  const paginationSetup = pagination
    ? {
        total: typeof total === 'number' ? total : dataTable?.length || 0,
        pageSizeOptions: [10, 25, 50, 100],
        defaultPageSize: 100,
        showSizeChanger: true,
        hideOnSinglePage: false,
        position: ['bottomCenter'] as unknown as [],
        pageSize,
        current: page,
        onChange: (p: number, s: number) => {
          if (onPaginationChange) {
            onPaginationChange(p, s);
          }
        },
      }
    : (false as const);

  return {
    components: tableComponents,
    columns: tableColumnsModified,
    dataSource: tableDataSource,
    rowSelection,
    total: total || dataTable?.length || 0,
    pagination: paginationSetup,
    onRow: (record: RecordType, index?: number) => ({
      onClick: () => handleRowClick(record, index),
    }),
    handleDragEnd,
  };
};

export default useTable;
