import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

type ActivityJournalContextProps = {
  userId?: number;
  manufacturerId?: number;
  setUserId: Dispatch<SetStateAction<number | undefined>>;
  setManufacturerId: Dispatch<SetStateAction<number | undefined>>;
  openCreateNew: boolean;
  setOpenCreateNew: React.Dispatch<SetStateAction<boolean>>;
};

const ActivityJournalContext = createContext<ActivityJournalContextProps>(null!);

const ActivityJournalsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openCreateNew, setOpenCreateNew] = useState<boolean>(false);
  const [userId, setUserId] = useState<number | undefined>();
  const [manufacturerId, setManufacturerId] = useState<number | undefined>();

  const value = useMemo(
    () => ({
      userId,
      manufacturerId,
      setUserId,
      setManufacturerId,
      openCreateNew,
      setOpenCreateNew,
    }),
    [userId, manufacturerId, openCreateNew],
  );

  return (
    <ActivityJournalContext.Provider value={value}>{children}</ActivityJournalContext.Provider>
  );
};

export default ActivityJournalsProvider;

export const useActivityJournalContext = () => {
  const context = useContext(ActivityJournalContext);
  if (!context) {
    throw new Error('useActivityJournalContext must be used within a ActivityJournalProvider');
  }
  return context;
};
