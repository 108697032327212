import styled from 'styled-components';

const StyledCompanyHeader = styled.div<{ bigAvatar?: boolean }>`
  position: sticky;
  top: 0;
  z-index: 1050;
  .contact-info-container {
    color: #858585;
    h2,
    h3 {
      font-weight: normal !important;
      line-height: 1.5rem;
    }
    h2 
      color: #4B4B4B;
    }
  }
  .ant-card-bordered {
    border-bottom: 0.2rem solid #eee !important;
  }
  .ant-card-body {
    padding: 0 1.5rem;
  }

  .card-container {
    width: 100%;
    gap: 12;
    padding: 0 8px;
    .flex-col-container {
      flex-direction: row;
      justify-content: space-between;
      .avatar-company-container {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 8px 0;
        gap: 8px;
        .avatar-container {
          height: 32px;
          width: 32px;
          background-color: #0078d4;
          font-size: ${(props) => (props.bigAvatar ? '28px !important' : 'undefined')};
        }
        .industries-tags {
          display: flex;
          gap: 8px;
        }
      }
    }
    .inputs-buttons-container {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      width: 75%;
      .input-style {
        padding: 8px 12px;
      }
    }
  }
`;

export default StyledCompanyHeader;
