import { Route, Routes } from 'react-router';
import NotFoundPage from 'src/components/commons/notFound';
import ActivityJournalsPage from './pages/activityJournals';

const ActivityRoutes = () => (
  <Routes>
    <Route index element={<ActivityJournalsPage />} />
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

export default ActivityRoutes;
