import styled from 'styled-components';

const StyledSubTable = styled.div<{ $hideBorders?: boolean; $clickable?: boolean }>`
  height: 100%;

  .ant-btn.edit-button,
  .ant-btn.filter-button {
    color: #ff4d4f !important;
    background-color: transparent !important;

    &:hover,
    &:active {
      color: #ff4d4f !important;
      background-color: transparent !important;
    }

    &.active {
      color: #ff7875 !important;
      background-color: #fff2f0 !important;
    }
  }

  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    padding-top: 16px;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    &::before {
      display: none;
    }
  }

  .ant-table-expanded-row {
    height: max-content !important;
    min-height: auto !important;
  }

  .ant-table-row {
    cursor: pointer;

    .ant-table-cell {
      padding: 0 10px !important;
    }
  }

  .ant-table-body {
    height: 100vh;
  }

  .ant-select-selector {
    height: 30px !important;
  }

  .ant-table-row-selected > .ant-table-cell {
    background-color: ${(props) => (props.$clickable ? ' #0078d4 !important' : '')};
    color: ${(props) => (props.$clickable ? '#fff !important' : '')};

    div > button {
      color: ${(props) => (props.$clickable ? '#fff !important' : '')};
    }
  }

  //table header styles -----------------------------------------

  .ant-table-wrapper .ant-table-thead > tr > th:nth-last-child(2) {
    border-right: none !important;
  }

  .ant-table-wrapper .ant-table-thead > tr > th:first-child {
    border-right: 1px solid #939393;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    padding: 0 12px !important;
  }

  .ant-table-wrapper .ant-table-thead > tr > th:nth-last-child(3) {
    border-right: 1px solid #939393;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    border-bottom: 1px solid #939393;
  }

  //table row styles --------------------------------------------

  .ant-table-tbody > tr:nth-child(even) {
    background-color: #fff;
  }

  .ant-table-tbody > tr:nth-child(odd) {
    background-color: #fafafa;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td:not(:last-child) {
    border-right: 1px solid #e4e4e4;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td:first-child {
    border-right: 1px solid #939393;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td:nth-last-child(2) {
    border-right: 1px solid #939393;
    border-bottom: 1px solid #eee;
  }
  .ant-table-wrapper .ant-table-tbody > tr:nth-last-child(2) {
    border-right: 2px solid #eee;
  }

  th.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-scrollbar {
    box-shadow: none;
  }

  // table cell styles ----------------------------------------------
  .ant-table-tbody > tr > td:not(:first-child):not(:last-child):hover {
    border: 0.5px solid black;
  }
  .searchOutlined-button-active {
    margin-top: 22px;
    background-color: #cce4f6;
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0078d4 !important;
    &:hover {
      color: #5d5d5d !important;
    }
  }
  .searchOutlined-button-inactive {
    background-color: transparent;
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5d5d5d !important;
  }

  .searchOutlined-icon-ative {
    font-size: 32px !important;
  }
  .searchOutlined-icon-inactive {
    font-size: 32px !important;
  }
  .delete-button-subtable {
    font-size: 24px !important;
  }
  .badge-position-active {
    position: absolute;
    left: 30px;
    top: 18px;
    z-index: 9;
  }

  .badge-position-disabled {
    position: absolute;
    left: 30px;
    top: 0px;
    z-index: 9;
  }
  .badge-clear-position-active {
    position: absolute;
    left: 30px;
    top: 18px;
    z-index: 9;
    cursor: pointer;
  }
  .badge-clear-position-disable {
    position: absolute;
    left: 30px;
    top: 0px;
    z-index: 9;
  }
  .clear-badge-style {
    display: flex;
    align-items: center; /* Centers the icon vertically */
    justify-content: center; /* Centers the icon horizontally */
    background-color: white; /* Background color of the circle */
    border-radius: 50%; /* Makes the div circular */
    border: 2px solid blue; /* Blue border with 2px thickness */
    padding: 3px; /* Padding inside the circle */
  }

  /// selector--------------------------
  .ant-select-selector {
    border: none !important;
    background: transparent !important;
  }
`;

export default StyledSubTable;
