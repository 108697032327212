import { FC, useContext, useMemo } from 'react';
import NewContact from 'src/modules/contacts/components/newContact';
import { CompaniesContext, CompaniesContextProvider } from 'src/context/companies.context';
import ModalCreateEditActivity from 'src/modules/activity/components/modalCreateEditActivity';
import { Button } from 'antd';
import CreateCompany from '../createCompany';
import {
  CreateCommpanyFooterRenderer,
  CreateCompanyHeaderRenderer,
} from '../createCompany/renderer';
import { CustomModal } from '../customModal';
import StyledModalCreateEdit from '../subTable/styles/modalCreateEdit.styles';

type Props = {
  mode: 'company' | 'contact' | 'activityJournal' | null;
  onClose: () => void;
};

const NewCompanyModal: FC<Pick<Props, 'onClose'>> = ({ onClose }) => {
  const { progressCreationCompany } = useContext(CompaniesContext);
  return (
    <CustomModal
      modalStatus
      onCancelAction={() => {
        onClose();
      }}
      onOkAction={() => 0}
      centered
      customClass='custom-modal'
      headerHeight='72px'
      bodyHeight='589px'
      footerHeight='64px'
      bodyPadding='16px 32px 24px 32px'
      width={1200}
      closable
      modalTitle={<CreateCompanyHeaderRenderer percentage={progressCreationCompany} />}
      modalBody={<CreateCompany />}
      footer={<CreateCommpanyFooterRenderer />}
    />
  );
};

const NewCompany: FC<Pick<Props, 'onClose'>> = ({ onClose }) => (
  <CompaniesContextProvider>
    <NewCompanyModal onClose={onClose} />
  </CompaniesContextProvider>
);

// TODO: modify component to manage the data when AJ has query to data persistance.
const AJModal: FC<Pick<Props, 'onClose'>> = ({ onClose }) => (
  <StyledModalCreateEdit>
    <CustomModal
      modalStatus
      onCancelAction={onClose}
      onOkAction={() => 0}
      centered
      customClass='custom-modal-edit'
      closable
      width={1400}
      modalTitle={<h3 style={{ fontWeight: 'normal' }}>New Entry: Contractor</h3>}
      modalBody={<ModalCreateEditActivity />}
      footer={
        <>
          <Button type='default' onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={false} type='primary' htmlType='submit' onClick={() => {}}>
            Create
          </Button>
        </>
      }
      style={{ height: 'max-content' }}
    />
  </StyledModalCreateEdit>
);

const GlobalCreate: FC<Props> = ({ mode, onClose }) => {
  const modal = useMemo(() => {
    if (mode === 'company') return <NewCompany onClose={onClose} />;
    if (mode === 'contact') return <NewContact onClose={onClose} />;
    if (mode === 'activityJournal') return <AJModal onClose={onClose} />;
    return null;
  }, [mode, onClose]);

  return modal;
};

export default GlobalCreate;
