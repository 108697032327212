import { gql } from '@apollo/client';

export const CREATE_DIVISION = gql`
  mutation CreateDivision($createDivisionInput: CreateDivisionInput!) {
    CreateDivision(createDivisionInput: $createDivisionInput) {
      id
    }
  }
`;

export const UPDATE_DIVISION = gql`
  mutation UpdateCompanyDivision($updateCompanyDivisionInput: UpdateCompanyDivisionInput!) {
    UpdateCompanyDivision(updateCompanyDivisionInput: $updateCompanyDivisionInput) {
      id
    }
  }
`;

export const REMOVE_DIVISION = gql`
  mutation RemoveDivision($id: Int!) {
    RemoveDivision(id: $id)
  }
`;
