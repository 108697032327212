import { Space, AutoComplete, Input } from 'antd';
import React, { useRef, useContext, useState, useEffect } from 'react';
import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { TitleTableColumn } from 'src/styles/customTableStyles';
import { CompaniesContext } from 'src/context/companies.context';
import CustomButton from 'src/components/commons/customButton';
import {
  ColumnTypes,
  CompaniesHeaderType,
  ListTypeOptionType,
  CompaniesHeaderType2,
  CompaniesElasticTypes2,
  DataStructureType2,
  AddressType,
  EmailType,
  PhoneType,
} from 'src/types/companiesTypes';
import { legacyFilter } from 'src/services/global-variables';
import { useQuery } from '@apollo/client';
import { GET_COMPANIES_DEPTH_HEADERS } from 'src/components/company/queries';
import { Link } from 'react-router-dom';

interface DynamicTitleTypes {
  headerName: string;
  searchFieldData: string;
  orderField: string;
  criteriaFilterField: string;
}

const DynamicTitle: React.FC<DynamicTitleTypes> = ({
  headerName,
  searchFieldData,
  orderField,
  criteriaFilterField,
}) => {
  const initialized = useRef<boolean>(false);
  const [sortActive, setSortActive] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const textStyles: object = { userSelect: 'none' };
  const inputStyles = {
    border: '1px solid #0078D4',
    borderRadius: '8px',
    minWidth: '195px',
    height: '33px',
  };
  const sortState = useRef<string>('NONE');
  const columnActive = useRef<string>('NONE');
  const sortIcon = useRef(<SortAscendingOutlined />);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const savedSortState = localStorage.getItem('tableSortOrderPreferences');
    const savedSortStateParsed = savedSortState ? JSON.parse(savedSortState) : null;
    if (
      savedSortStateParsed &&
      savedSortStateParsed.activeColumn &&
      savedSortStateParsed.activeColumn === orderField
    ) {
      sortState.current = savedSortStateParsed.sortOrder;
      columnActive.current = savedSortStateParsed.activeColumn;
      if (savedSortStateParsed.sortOrder === 'ASC') {
        sortIcon.current = <SortAscendingOutlined />;
      } else if (savedSortStateParsed.sortOrder === 'DESC') {
        sortIcon.current = <SortDescendingOutlined />;
      }
      setSortActive(savedSortStateParsed.sortOrder !== 'NONE');
    }
  }, []);

  const {
    sortFunction,
    searchElasticFunction,
    criteria,
    typeaheadElasticFunction,
    typeaheadSearchOptions,
    getTypeaheadDataResponse,
  } = useContext(CompaniesContext);

  const sortAction = () => {
    switch (sortState.current) {
      case 'ASC':
        sortState.current = 'DESC';
        setSortActive(true);
        sortIcon.current = <SortDescendingOutlined />;
        sortFunction('DESC', columnActive.current);
        break;
      case 'DESC':
        sortState.current = 'NONE';
        setSortActive(false);
        sortIcon.current = <SortAscendingOutlined />;
        sortFunction('NONE', columnActive.current);
        break;
      default:
        sortState.current = 'ASC';
        setSortActive(true);
        sortIcon.current = <SortAscendingOutlined />;
        sortFunction('ASC', columnActive.current);
        break;
    }
  };

  const sortFunctionHandler = (column: string) => {
    if (column === columnActive.current) {
      sortAction();
    } else {
      columnActive.current = column;
      sortState.current = 'NONE';
      sortAction();
    }
  };

  const onSelectSearch = (searchField: string, data: string) => {
    setInputValue(data);
    searchElasticFunction(searchField, data);
  };

  const typeaheadSearch = (searchField: string, data: string) => {
    setInputValue(data);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const timer = setTimeout(() => {
      typeaheadElasticFunction(searchField, data);
    }, 350);
    setTimeoutId(timer);
  };

  // Validates if the current input contains content to filter
  const onFocusInput = async () => {
    const savedInputValue = await localStorage.getItem(`searchInputValue-${searchFieldData}`);
    if (savedInputValue === null) {
      typeaheadElasticFunction(searchFieldData, '');
    }
  };

  // Delete input content if a search option is not selected
  const onBlurInput = async () => {
    const savedInputValue = await localStorage.getItem(`searchInputValue-${searchFieldData}`);
    if (savedInputValue !== null) {
      setInputValue(savedInputValue);
    } else {
      setInputValue('');
    }
  };

  useEffect(() => {
    if (criteria.current.orderBy?.field !== undefined) {
      if (columnActive.current !== 'NONE') {
        if (criteria.current.orderBy?.field !== columnActive.current) {
          sortState.current = 'NONE';
          sortIcon.current = <SortAscendingOutlined />;
          setSortActive(false);
        }
      }
    }
  }, [criteria.current.orderBy?.field]);

  useEffect(() => {
    const savedInputValue = localStorage.getItem(`searchInputValue-${searchFieldData}`);
    if (savedInputValue !== null) {
      setInputValue(savedInputValue);
      if (!initialized.current) {
        initialized.current = true;
        searchElasticFunction(searchFieldData, savedInputValue);
      }
    } else {
      setInputValue('');
    }
  }, [headerName, searchFieldData, orderField, criteriaFilterField]);

  return (
    <>
      <TitleTableColumn className='dragHandler' style={textStyles}>
        {headerName}
      </TitleTableColumn>
      <Space align='center'>
        <AutoComplete
          onSearch={(text) => typeaheadSearch(criteriaFilterField, text)}
          onSelect={(text) => onSelectSearch(searchFieldData, text)}
          allowClear
          onClear={() => onSelectSearch(searchFieldData, 'empty')}
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          options={
            getTypeaheadDataResponse.loading ? [{ value: 'Loading...' }] : typeaheadSearchOptions
          }
          value={inputValue}
          placeholder='Auto | Complete'
          style={inputStyles}
        >
          <Input
            data-testid={`input-${searchFieldData}`}
            onPressEnter={(e) =>
              searchElasticFunction(searchFieldData, (e.target as HTMLInputElement).value, false)
            }
          />
        </AutoComplete>
        <CustomButton
          onlyIcon
          icon={sortIcon.current}
          onClick={() => sortFunctionHandler(orderField)}
          danger={sortActive}
          style={{ color: !sortActive ? '#0078d4' : undefined }}
        />
      </Space>
    </>
  );
};

const CompaniesColumns = () => {
  const { setCompaniesErrorHandler, showEmptyPageHandler } = useContext(CompaniesContext);
  const iteratedColumn = useRef<(ColumnTypes[number] & CompaniesHeaderType)[]>([]);
  const iteratedColumn2 = useRef<(ColumnTypes[number] & CompaniesHeaderType2)[]>([]);
  const divContainerStyles: object = { cursor: 'pointer' };
  const filterOptions = useRef<ListTypeOptionType[]>([]);
  const filterOptions2 = useRef<ListTypeOptionType[]>([]);
  const columnList = useRef<{ id: string; name: string }[]>([]);

  const companiesHeaderResponse2 = useQuery(GET_COMPANIES_DEPTH_HEADERS, {
    onCompleted: (data) => {
      iteratedColumn2.current = data.GetCompaniesDepthGridHeader.map(
        (element: CompaniesHeaderType2) => {
          const innerCompoundValue = element.key
            ? `${element.dataIndex}.${element.key}`
            : element.dataIndex;
          const innerOrderedColums = {
            id: innerCompoundValue,
            name: element.headerName,
          };
          if (element.gridVisible) {
            columnList.current.push(innerOrderedColums);
          }
          const internalElement = {
            relabelIndex: element.relabelIndex,
            headerName: element.headerName,
            key: element?.key,
            dataIndex: element.dataIndex,
            searchField: element.searchField,
            orderField: element.orderField,
            hasLink: element.hasLink,
            dataType: element.dataType,
            gridVisible: element.gridVisible,
            required: element.required,
            options: element.options,
            criteriaFilterField: element.criteriaFilterField,
            title: element.dataType !== 'color' && (
              <div style={divContainerStyles}>
                <DynamicTitle
                  headerName={element.headerName}
                  searchFieldData={element.searchField}
                  orderField={element.orderField}
                  criteriaFilterField={element.criteriaFilterField}
                />
              </div>
            ),
            width: element.dataType === 'color' ? 50 : 255,
            minWidth: element.dataType === 'color' ? 50 : 255,
            editable: element.editable,
            render: (
              renderData: DataStructureType2 | AddressType | EmailType | PhoneType,
              record: CompaniesElasticTypes2,
            ) => {
              if (element.hasLink) {
                return (
                  <Link to={`${record.id}`} state={record}>
                    {record.name}
                  </Link>
                );
              }

              if (typeof element.dataStructure === 'string') {
                return renderData;
              }

              if (typeof renderData === 'string') {
                return renderData;
              }

              if (renderData) {
                return renderData[element.key as keyof typeof renderData];
              }
              return '';
            },
          };
          const innerLegacyFilter = legacyFilter.find((item) => item.id === innerCompoundValue);
          if (innerLegacyFilter) {
            filterOptions2.current.push({
              label: element.headerName,
              value: innerCompoundValue,
            });
          }
          return internalElement;
        },
      );
      // TODO: DELETE THIS LINES WHEN MAIN EMAILS EXIST
      iteratedColumn2.current = [
        {
          relabelIndex: 'mainEmail',
          headerName: 'Main Email',
          key: 'email',
          dataIndex: 'mainEmail',
          searchField: 'mainEmail',
          orderField: 'mainEmail',
          dataType: 'string',
          gridVisible: true,
          required: true,
          options: [],
          title: (
            <div style={divContainerStyles}>
              <DynamicTitle
                headerName='Main Email'
                searchFieldData='mainEmail'
                orderField='mainEmail'
                criteriaFilterField='mainEmail'
              />
            </div>
          ),
          hasLink: false,
          width: 250,
          minWidth: 250,
          dataStructure: 'string',
          editable: true,
          hidden: false,
          criteriaFilterField: 'mainEmail',
        },
        ...iteratedColumn2.current,
      ];
      // ^
    },
    onError: () => {
      setCompaniesErrorHandler();
      showEmptyPageHandler(true);
    },
  });

  return {
    companiesHeaderResponse2,
    iteratedColumn,
    iteratedColumn2,
    filterOptions,
    filterOptions2,
    columnList,
  };
};

export default CompaniesColumns;
