import { gql } from '@apollo/client';

export const CREATE_CONTACT_GROUP = gql`
  mutation CreateContactGroup($createContactGroupInput: CreateContactGroupInput!) {
    CreateContactGroup(createContactGroupInput: $createContactGroupInput) {
      id
      name
    }
  }
`;

export const UPDATE_CONTACT_GROUP = gql`
  mutation UpdateContactGroup($updateContactGroupInput: UpdateContactGroupInput!) {
    UpdateContactGroup(updateContactGroupInput: $updateContactGroupInput) {
      id
      name
    }
  }
`;

export const DELETE_CONTACT_GROUP = gql`
  mutation RemoveContactGroup($removeContactGroupId: Int!) {
    RemoveContactGroup(id: $removeContactGroupId)
  }
`;
