import React, { useRef } from 'react';
import { Space, Typography, Table, Row, Tag } from 'antd';
import { CustomModal } from 'src/components/commons/customModal';
import CustomButton from 'src/components/commons/customButton';
import type { ColumnsType } from 'antd/es/table';
import { DeletedDataResponseType } from 'src/types/companiesTypes';
import StyleDiv from './deletingConfirmation.styles';
import { DeletingConfirmationType } from './utils';

const DeletingConfirmation: React.FC<DeletingConfirmationType> = ({
  modalStatus,
  data,
  closeAction,
}) => {
  const columns: ColumnsType<DeletedDataResponseType> = [
    {
      dataIndex: 'key',
      key: 'key',
      width: 30,
      fixed: 'left',
      render: (_, renderData) => <p style={{ paddingLeft: '10px' }}>{renderData.key}</p>,
    },
    {
      title: 'Company Name',
      dataIndex: 'company',
      key: 'name',
      width: 150,
      fixed: 'left',
      render: (_, renderData) => (
        <p style={{ paddingLeft: '10px' }}>{renderData.company.name ?? 'Unknown'}</p>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'processed',
      key: 'processed',
      width: 80,
      fixed: 'left',
      render: (_, renderData) => (
        <Tag style={{ marginLeft: '10px' }} color={renderData.processed ? 'green' : 'volcano'}>
          {renderData.processed ? 'successful' : 'error'}
        </Tag>
      ),
    },
    Table.EXPAND_COLUMN,
  ];

  const totalProcessed = useRef(0);
  const skipped = useRef(0);

  if (modalStatus && data) {
    data.forEach((element) => {
      if (element.processed) {
        totalProcessed.current += 1;
      } else {
        skipped.current += 1;
      }
    });
  } else {
    totalProcessed.current = 0;
    skipped.current = 0;
  }

  const errorRender = (record: DeletedDataResponseType) => (
    <p style={{ color: 'red' }}>{record.error?.message}</p>
  );

  return (
    <CustomModal
      modalStatus={modalStatus}
      onCancelAction={closeAction}
      onOkAction={() => 0}
      closable
      centered
      modalTitle='Deleted Data'
      width={750}
      modalBody={
        <Space>
          <StyleDiv>
            <table style={{ paddingTop: '70px' }}>
              <tbody>
                <tr>
                  <td>
                    <Typography.Text strong>Total Selected:</Typography.Text>
                  </td>
                  <td style={{ paddingLeft: '20px' }}>
                    <Typography.Text strong data-testid='total-selected-amount'>
                      {data?.length}
                    </Typography.Text>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: '10px' }}>
                    <Typography.Text strong>Processed:</Typography.Text>
                  </td>
                  <td style={{ paddingLeft: '20px', paddingTop: '10px' }}>
                    <Typography.Text strong data-testid='total-processed-amount'>
                      {totalProcessed.current}
                    </Typography.Text>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: '10px' }}>
                    <Typography.Text strong>Skipped:</Typography.Text>
                  </td>
                  <td style={{ paddingLeft: '20px', paddingTop: '10px' }}>
                    <Typography.Text strong data-testid='total-skipped-amount'>
                      {skipped.current}
                    </Typography.Text>
                  </td>
                </tr>
              </tbody>
            </table>
            <Space direction='vertical' style={{ marginTop: 'auto' }}>
              <Typography.Text>Not the changes you wanted?</Typography.Text>
              <Row justify='space-between'>
                <CustomButton
                  type='text'
                  title='Close'
                  noborder={false}
                  onClick={closeAction}
                  dataTestId='close-button'
                  ghost
                  style={{
                    fontSize: '17px',
                    borderRadius: 0,
                  }}
                />
                <CustomButton
                  type='primary'
                  title='Undo'
                  dataTestId='undo-button'
                  ghost
                  style={{
                    fontSize: '17px',
                    borderRadius: 0,
                  }}
                  disabled
                />
              </Row>
            </Space>
          </StyleDiv>
          <div>
            <Table
              style={{ maxWidth: '450px' }}
              columns={columns}
              expandable={{
                expandedRowRender: errorRender,
                rowExpandable: (record) => !record.processed,
              }}
              dataSource={data}
              bordered
              size='middle'
              scroll={{ y: 200 }}
            />
          </div>
        </Space>
      }
      footer={null}
    />
  );
};

export default DeletingConfirmation;
