import { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';
import { ActivityJournalModel } from 'src/models';
import useInfinityDataSource, { FetchFunc } from '../useInfinityDataSource';

const fakeLoadActivityJournals: (size: number) => any[] = (size: number) =>
  Array.from({ length: size }).map(() => ({
    id: Math.random() + Math.random(),
    date: '24/06/23',
    purpose: faker.lorem.sentence(),
    description: faker.lorem.paragraph(),
    company: Array.from({ length: faker.number.int({ min: 1, max: 10 }) }).map(() => ({
      id: faker.number.int(),
      name: faker.company.name(),
    })),
    timeSpent: faker.number.int({ min: 1, max: 100 }),
    genericNotes: faker.lorem.paragraph(),
    attendees: Array.from({ length: faker.number.int({ min: 1, max: 10 }) }).map(() => ({
      id: faker.number.int(),
      name: faker.person.fullName(),
    })),
    details: Array.from({ length: faker.number.int({ min: 1, max: 10 }) }).map(() => ({
      id: faker.number.int(),
      activityJournalHeaderId: faker.number.int(),
      manufacturerId: faker.number.int(),
      manufacturerName: faker.lorem.words({ min: 1, max: 3 }),
      comments: Array.from({ length: faker.number.int({ min: 1, max: 10 }) }).map(() =>
        faker.lorem.paragraph(),
      ),
      followUp: Array.from({ length: faker.number.int({ min: 1, max: 10 }) }).map(() => ({
        date: faker.date.future(),
        notes: faker.lorem.paragraph(),
      })),
    })),
    enabled: true,
    type: {
      id: faker.number.int(),
      name: faker.lorem.sentence({ min: 1, max: 3 }),
    },
    user: {
      id: faker.number.int(),
      name: faker.person.fullName(),
    },
  }));

const useActivityJournalsStore = () => {
  const fetchActivityJournals: FetchFunc = async ({ renderLen, start }) => {
    const size = start + renderLen > 500 ? 500 - start : renderLen;
    return {
      total: 500,
      results: fakeLoadActivityJournals(size),
    };
  };
  const { dataSource, setDataSource, onListRender, reset } =
    useInfinityDataSource(fetchActivityJournals);

  const [getTypes, setGetTypes] = useState<any[]>([]);
  const [getUsers, setGetUsers] = useState<any[]>([]);

  const deleteBulk = (ids: number[]) => {
    setDataSource((prev) => prev.filter((data) => !ids.includes(data.id)));
  };

  const updateOne = (data: ActivityJournalModel) => {
    setDataSource((prev) => prev.map((item) => (item.id === data.id ? data : item)));
  };

  useEffect(() => {
    const types = dataSource.map((el) => el.type);
    const users = dataSource.map((el) => el.user);
    setGetUsers(users);
    setGetTypes(types);
  }, [dataSource]);

  const api = {
    dataSource,
    setDataSource,
    loadPage: onListRender,
    getTypes,
    getUsers,
    deleteBulk,
    updateOne,
    reset,
  };

  return api;
};

export default useActivityJournalsStore;
