import { FC } from 'react';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import FormSection from 'src/components/formSection';
import { useContactContext } from 'src/context/contact.context';
import { useContactsContext } from 'src/modules/contacts/contexts/contacts.contexts';
import { GQL } from 'src/graphql';

type ContactFormBusinessKey = `personalInfo.${keyof GQL.CreateContactPersonalInfoInput}`;
type FormDataType = Record<ContactFormBusinessKey, unknown>;

type Props = { showTitle?: boolean; formName?: string; requiredFields?: string[] };

const ContactFormPersonalInfo: FC<Props> = ({ showTitle = true, formName, requiredFields }) => {
  const { activeExpandedEdition } = useContactContext();
  const { createContactModalActive } = useContactsContext();
  const fields: FieldType<FormDataType>[] = [
    {
      name: 'personalInfo.addresses',
      label: 'Main Address',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'addresses',
      span: 24,
      formName,
      rules: [
        {
          required: requiredFields?.includes('personalInfo.addresses'),
          message: 'Main Address is required',
        },
      ],
    },
    {
      name: 'personalInfo.phones',
      label: 'Main Phone',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'phones',
      span: 24,
      formName,
      rules: [
        {
          required: requiredFields?.includes('personalInfo.phones'),
          message: 'Main Phone is required',
        },
      ],
    },
    {
      name: 'personalInfo.emails',
      label: 'Main Email',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'emails',
      span: 24,
      formName,
      rules: [
        {
          required: requiredFields?.includes('personalInfo.emails'),
          message: 'Main Email is required',
        },
      ],
    },
  ];

  if (!showTitle) {
    return <GenericForm fields={fields} />;
  }

  return (
    <FormSection title='Personal'>
      <GenericForm fields={fields} />
    </FormSection>
  );
};

export default ContactFormPersonalInfo;
