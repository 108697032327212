import { Option, SubTableColumn } from 'src/components/commons/subTable/types';

export const COLUMNS_TABLE_PREDEFINED_TYPES: SubTableColumn[] = [
  {
    title: 'NAME',
    dataIndex: 'parent.name',
    filterKey: 'parent_name',
    sortKey: 'parent_name',
    dataType: 'string',
    editable: false,
  },
  {
    title: 'CUSTOM NAME',
    dataIndex: 'name',
    filterKey: 'name',
    sortKey: 'name',
    editable: true,
    dataType: 'string',
  },
  {
    title: 'COLOR CODE',
    dataIndex: 'color',
    dataKey: 'color',
    filterKey: 'color',
    sortKey: 'color',
    width: 200,
    editable: true,
    dataType: 'color',
  },
];

export const COLUMNS_TO_SELECT_OPTIONS: Option[] = [
  { label: 'NAME', value: 'name' },
  { label: 'JOB TITLE', value: 'job_title' },
  { label: 'COMPANY', value: 'company' },
  { label: 'PHONE', value: 'phone' },
  { label: 'EMAIL', value: 'email' },
];

export const COLUMNS_TABLE_SELECTED: string[] = ['name', 'job_title', 'company', 'phone', 'email'];

export const COLUMNS_TABLE_CUSTOM_TYPES: SubTableColumn[] = [
  {
    title: 'NAME',
    dataIndex: 'name',
    dataKey: 'name',
    filterKey: 'name',
    sortKey: 'name',
    key: '',
    width: 200,
    editable: true,
    dataType: 'string',
    unique: true,
    required: true,
  },
  {
    title: 'TAGGED AS',
    dataIndex: ['parent', 'name'],
    dataKey: 'parent_name',
    filterKey: 'parent_name',
    sortKey: 'parent_name',
    key: '',
    width: 200,
    editable: true,
    dataType: 'dropDown',
    options: [
      {
        LABEL: 'Manufacturer',
        VALUE: '1',
      },
      {
        LABEL: 'Customer',
        VALUE: '2',
      },
      {
        LABEL: 'External',
        VALUE: '3',
      },
      {
        LABEL: 'Distributor',
        VALUE: '4',
      },
    ],
  },
  {
    title: 'COLOR',
    dataIndex: 'color',
    dataKey: 'color',
    filterKey: 'color',
    sortKey: 'color',
    key: '',
    width: 200,
    editable: true,
    dataType: 'color',
  },
];
