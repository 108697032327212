import styled from 'styled-components';
import { Typography, Input, Space, Row, Image, Button } from 'antd';
import CustomSelect from 'src/components/commons/customSelect/index';

const { Title } = Typography;
const { Search } = Input;

const StyledTitle = styled(Title)`
  color: #4b4b4b !important;
  &&& {
    font-weight: normal;
    margin-top: 5px;
    margin-left: 25px;
  }
`;

const StyledSearch = styled(Search)`
  &&& {
    width: 350px;
  }
`;

const StyledCustomSelect = styled(CustomSelect)`
  .ant-select {
    border-color: blue;
    border: '1px solid #0078D4';
  }

  .ant-select-focused .ant-select-selector {
    border-color: blue;
  }
`;

const StyledSpace = styled(Space)`
  &&& {
    min-width: max-content;
    width: 100% !important;
    gap: 0px !important;
  }
`;

const StyledRow = styled(Row)`
  &&& {
    margin-bottom: 5px;
  }
`;

const StyledImage = styled(Image)`
  &&& {
    width: 45px;
    margin-right: 15px;
  }
`;

const StyledSubHeaderActionButton = styled(Button)<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#CCE4F6' : '#ffffff')} !important;
  background-color: #0078d4;
  box-shadow: none;
  padding: 0;
  font-size: 20px;
  width: 30px !important;
  height: 30px !important;
  min-width: 30px !important;
  min-height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${(props) => (props.active ? '#EEEEEE' : undefined)};
  }
  span {
    width: max-content;
    height: max-content;
    svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: ${(props) => (props.active ? '#0078d4' : '#5D5D5D')};
    }
  }
`;

export {
  StyledTitle,
  StyledSearch,
  StyledSpace,
  StyledRow,
  StyledImage,
  StyledCustomSelect,
  StyledSubHeaderActionButton,
};
