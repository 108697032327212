// import ContactFormAttendance from 'src/modules/contacts/components/newContact/components/attendance';
import ContactFormBasicInfo from 'src/modules/contacts/components/newContact/components/basicInfo';
import ContactFormBusinessInfo from 'src/modules/contacts/components/newContact/components/businessInfo';
import ContactFormContactGroup from 'src/modules/contacts/components/newContact/components/contactGroup';
import ContactFormNotes from 'src/modules/contacts/components/newContact/components/notes';
import ContactFormPersonalInfo from 'src/modules/contacts/components/newContact/components/personalInfo';
import ContactFormProductInterest from 'src/modules/contacts/components/newContact/components/productInterest';
import CustomFields from '../components/customFields';

export const DRAG_LIST_SECTIONS = [
  {
    title: 'Basic Information',
    content: <ContactFormBasicInfo showTitle={false} />,
  },
  {
    title: 'Business Information',
    content: <ContactFormBusinessInfo showTitle={false} formName='bussinessInfo' />,
  },
  {
    title: 'Custom Fields',
    content: <CustomFields />,
  },
  {
    title: 'Personal',
    content: <ContactFormPersonalInfo showTitle={false} formName='personalInfo' />,
  },
  /* {
    // eslint-disable-next-line prettier/prettier, quotes
    title: "Web URL's",
    content: <ContactFormWebInfo showTitle={false} />,
  }, */
  {
    title: 'Notes',
    content: <ContactFormNotes showTitle={false} />,
  } /* 
  {
    title: 'Attendance',
    content: <ContactFormAttendance showTitle={false} />,
  }, */,
  {
    title: 'Contact Group',
    content: <ContactFormContactGroup showTitle={false} />,
  },
  {
    title: 'Product Interest',
    content: <ContactFormProductInterest showTitle={false} />,
  },
];
