/* eslint-disable @typescript-eslint/no-explicit-any */
import { AdapterFunc } from 'src/hooks/useEntityAdapter';

export const optional = <TInput = any, TOutput = any>(
  adapter: AdapterFunc<TInput, TOutput>,
  input?: TInput | null,
) => {
  if (!input) {
    return undefined;
  }
  return adapter(input);
};

export const list = <TInput = any, TOutput = any>(
  adapter: AdapterFunc<TInput, TOutput>,
  input?: (TInput | null)[] | null,
) => {
  if (!input) {
    return undefined;
  }
  return input.filter((item) => !!item).map((m) => adapter(m!));
};
