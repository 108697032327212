import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { AddressTypeModel } from 'src/models';

const addressTypeAdapter: AdapterFunc<GQL.AddressTypeModel, AddressTypeModel> = (input) => ({
  id: input.id,
  name: input.name!,
  description: input.description!,
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
});

export default addressTypeAdapter;
