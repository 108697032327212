import { Route, Routes } from 'react-router';
import NotFoundPage from 'src/components/commons/notFound';
import { PageSubTableCompanies } from '../../pages';
import TabCompanyType from './tabs/type/TabCompanyType';
import TabCallPattern from './tabs/callPattern/TabCallPatternTable';
import TabTierTable from './tabs/tier/TabTierTable';
import TabBuyingGroupTable from './tabs/buyingGroup/TabBuyingGroupTable';
import TabCategoryCompanyTable from './tabs/category/TabCategoryCompanyTable';
import TabRankTable from './tabs/rank/TabRankTable';
import TabRegionCompanyTable from './tabs/region/TabRegionCompanyTable';
import TabTerritoryTable from './tabs/territory/TabTerritoryTable';
import TabIndustry from './tabs/industry/TabIndustryTable';
import TabPhoneTypesTable from './tabs/phoneType/TabPhoneTypesTable';
import TabCountry from './tabs/country/TabCountryTable';
import TabEmailTypesTable from './tabs/emailType/TabEmailTypesTable';
import TabAddressType from './tabs/addressType/TabAddressTypeTable';
import TabProductPotentialsTable from './tabs/productPotentials/TabProductPotentialsTable';

const SubTableCompaniesRoutes = () => (
  <Routes>
    <Route element={<PageSubTableCompanies />}>
      <Route index path='type' element={<TabCompanyType />} />
      <Route index path='call-pattern' element={<TabCallPattern />} />
      <Route index path='category' element={<TabCategoryCompanyTable />} />
      <Route index path='rank' element={<TabRankTable />} />
      <Route index path='region' element={<TabRegionCompanyTable />} />
      <Route index path='territory' element={<TabTerritoryTable />} />
      <Route index path='division' element={<h2>Divisions</h2>} />
      <Route index path='product-potential' element={<TabProductPotentialsTable />} />
      <Route index path='zip-code-masterlist' element={<h2>Zip Code Masterlist</h2>} />
      <Route index path='country' element={<TabCountry />} />
      <Route index path='address-type' element={<TabAddressType />} />
      <Route index path='industry' element={<TabIndustry />} />
      <Route index path='buying-group' element={<TabBuyingGroupTable />} />
      <Route index path='tier' element={<TabTierTable />} />
      <Route index path='phone-type' element={<TabPhoneTypesTable />} />
      <Route index path='email-type' element={<TabEmailTypesTable />} />
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  </Routes>
);

export default SubTableCompaniesRoutes;
