import { ContactModel } from 'src/models';

export const concatContactInfo = (contact: ContactModel) => {
  const addressParts = [
    contact?.businessInfo?.mainAddress?.address,
    contact?.businessInfo?.mainAddress?.pobox,
    contact?.businessInfo?.mainAddress?.zipCode,
    contact?.businessInfo?.mainAddress?.region?.name,
    contact?.businessInfo?.mainAddress?.territory?.name,
    contact?.businessInfo?.mainAddress?.city,
    contact?.businessInfo?.mainAddress?.state?.name,
    contact?.businessInfo?.mainAddress?.country?.name,
  ];

  const addressString = addressParts.filter((part) => part).join(', ');

  return `${contact?.firstName} ${contact?.lastName}\n${addressString}\n${
    contact?.businessInfo?.emails?.[0]?.email ?? ''
  }\n${contact?.businessInfo?.company?.name ?? ''}`;
};
