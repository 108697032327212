/* eslint-disable @typescript-eslint/no-explicit-any */
import { LazyQueryHookOptions } from '@apollo/client';
import { FetchFunc } from 'src/hooks/useInfinityDataSource';

// Find the best way to type fetchAll, entityAdapter, and errorHandler
// taking in account that it should support mocked data

type Args = {
  isPaginated?: boolean;
  fetchAll: any;
  entityAdapter: any;
  errorHandler: any;
  getOptions: (from: number, size: number) => LazyQueryHookOptions;
  getPayload?: (data: any) => any;
};

const buildInfinityHandler = ({
  fetchAll,
  entityAdapter,
  errorHandler,
  getOptions,
  getPayload = (d) => d,
}: Args) => {
  const handleFetchAll: FetchFunc = async ({ start, renderLen }) => {
    try {
      const options = getOptions(start, renderLen);

      const { data, error } = await fetchAll(options);

      const payload = getPayload(data);

      if (error) throw error;

      if (Array.isArray(payload)) {
        return {
          total: payload?.length,
          results: payload?.slice(start, start + renderLen)?.map(entityAdapter),
        };
      }

      return {
        total: payload?.total || 0,
        results: payload?.results?.map(entityAdapter) || [],
      };
    } catch (error) {
      errorHandler(error);
      return {
        total: 0,
        results: [],
      };
    }
  };

  return handleFetchAll;
};

export default buildInfinityHandler;
