import { gql } from '@apollo/client';

export const UPDATE_CATEGORY_COMPANY = gql`
  mutation UpdateCompanyCategory($updateCompanyCategoryInput: UpdateCompanyCategoryInput!) {
    UpdateCompanyCategory(updateCompanyCategoryInput: $updateCompanyCategoryInput) {
      id
    }
  }
`;

export const CREATE_CATEGORY_COMPANY = gql`
  mutation CreateCompanyCategory($createCompanyCategoryInput: CreateCompanyCategoryInput!) {
    CreateCompanyCategory(createCompanyCategoryInput: $createCompanyCategoryInput) {
      id
      name
    }
  }
`;

export const DELETE_CATEGORY_COMPANY = gql`
  mutation RemoveCompanyCategory($id: Int!) {
    RemoveCompanyCategory(id: $id)
  }
`;
