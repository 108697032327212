/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tooltip } from 'antd';
import { SubTableGridProps } from '../types';
import StyledRowActionButton from '../styles/rowActionButton.style';
import { showConfirmationMessage } from '../helpers';
import { useSubTableContext } from '../contexts/subtable.context';

type Props = Pick<
  SubTableGridProps,
  | 'actionCell'
  | 'editIcon'
  | 'deleteIcon'
  | 'detailsIcon'
  | 'onEditClick'
  | 'onDeleteClick'
  | 'onDetailsClick'
  | 'titleOnDelete'
  | 'contentOnDelete'
  | 'favIcon'
  | 'onFavClick'
  | 'deleteNewRecord'
>;

const getActionsCellData = (props: Props) => {
  const {
    actionCell,
    editIcon,
    deleteIcon,
    detailsIcon,
    favIcon,
    onFavClick,
    onEditClick,
    onDeleteClick,
    onDetailsClick,
    titleOnDelete,
    contentOnDelete,
    deleteNewRecord,
  } = props;

  let actions: any = actionCell;

  if (typeof actionCell === 'boolean') {
    actions = ['details', 'edit', 'delete'];
  }

  const render = (_: any, record: any) => {
    const { setOpenModal, setRecord, hideSubtableBorders } = useSubTableContext();

    if (record?.placeholder) {
      return null;
    }

    const handleEditClick = () => {
      if (onEditClick) {
        onEditClick([record]);
      } else {
        setOpenModal(true);
        setRecord(record);
      }
    };

    const handleDeleteClick = () => {
      showConfirmationMessage({
        title: titleOnDelete,
        content: contentOnDelete,
        onOk: () => {
          if (onDeleteClick) {
            if (record.newRecord) {
              deleteNewRecord?.(record);
              return;
            }
            onDeleteClick([record]);
          }
        },
      });
    };

    const handleDetailsClick = () => {
      if (onDetailsClick) {
        onDetailsClick(record);
      }
    };

    const buttons = {
      details: (
        <StyledRowActionButton
          data-testid='subtable-details-row-button'
          onClick={handleDetailsClick}
          key='details'
          type='text'
          shape='circle'
          icon={detailsIcon}
        />
      ),
      edit: (
        <Tooltip title='Edit' color='gray'>
          <StyledRowActionButton
            data-testid='subtable-edit-row-button'
            onClick={handleEditClick}
            key='edit'
            type='text'
            shape='circle'
            icon={editIcon}
          />
        </Tooltip>
      ),
      delete: (
        <StyledRowActionButton
          data-testid='subtable-delete-row-button'
          onClick={handleDeleteClick}
          key='delete'
          type='text'
          shape='circle'
          icon={deleteIcon}
        />
      ),
      fav: (
        <StyledRowActionButton
          data-testid='subtable-fav-row-button'
          onClick={() => onFavClick?.(record)}
          key='fav'
          type='text'
          shape='circle'
          icon={favIcon}
        />
      ),
    };
    const actionsButtons = (actions as any[]).map((action) => {
      if (action === 'details' || action === 'edit' || action === 'delete' || action === 'fav') {
        return buttons[action as keyof typeof buttons];
      }
      return action;
    });

    return (
      <div
        style={{
          display: 'flex',
          gap: 10,
          justifyContent: 'flex-end',
          borderLeft: hideSubtableBorders ? 'none' : '1px #ccc solid',
        }}
      >
        {actionsButtons}
      </div>
    );
  };

  const width = actions.length * 32 + (actions.length - 1) * 10 + 32;

  return { render, width };
};

export default getActionsCellData;
