import SettingsTable from 'src/components/settingsTables';
import SubheaderSettings from 'src/components/commons/subHeader/settings';
import useSalesTeamsSettingsTable from './hooks/useSalesTeamsSettingsTable';
import AddSalesTeam from './components/addSalesTeam';
import { TABS } from './constants';

const SalesTeams = () => {
  const {
    handleFilterChange,
    columns,
    userColumns,
    handleUpdateRows,
    newRecord,
    resetNewRecord,
    postData,
    createNewRecord,
    dataSource,
    onListRender,
    handleDeleteRows,
    columnsMembers,
    selectedRecord,
    setSelectedRecord,
    onSearch,
    allUsersData,
    setAllUsersData,
  } = useSalesTeamsSettingsTable();

  const headerTitle = 'Sales Team';

  return (
    <>
      <SubheaderSettings title={headerTitle} record={selectedRecord} />

      <SettingsTable
        dataSource={dataSource}
        columns={columns}
        columnsTeamMember={columnsMembers}
        createNewRecord={createNewRecord}
        handleDeleteRows={handleDeleteRows}
        handleFilterChange={handleFilterChange}
        newRecord={newRecord}
        onListRender={onListRender}
        postData={postData}
        handleUpdateRows={handleUpdateRows}
        resetNewRecord={resetNewRecord}
        tabs={TABS}
        title='Team for'
        titleModalNew='New Sales Team'
        titleOnDelete='Are you sure you want to remove this Sales Team?'
        contentOnDelete=''
        BodyModal={AddSalesTeam}
        setCurrentRecord={setSelectedRecord}
        selectedRecord={selectedRecord}
        hasPrimaryContact
        searchPlaceholder={`Search ${headerTitle}`}
        clickable
        onSearch={onSearch}
        allUsersData={allUsersData}
        setAllUsersData={setAllUsersData}
        userColumns={userColumns}
        setSelectedRecord={setSelectedRecord}
      />
    </>
  );
};

export default SalesTeams;
