import CustomFooter from 'src/components/commons/customFooter';
import CustomViewContainer from 'src/components/commons/customViewContainer';
import { ContactContent, ContactSetupHeader } from 'src/components/contactDetails';
import ContactContextProvider from 'src/context/contact.context';
import { GlobalContextProvider } from 'src/context/global.context';

const ContactSetupLayout = () => (
  <GlobalContextProvider>
    <CustomViewContainer>
      <ContactContextProvider>
        <ContactSetupHeader />
        <ContactContent />
        <CustomFooter />
      </ContactContextProvider>
    </CustomViewContainer>
  </GlobalContextProvider>
);

export default ContactSetupLayout;
