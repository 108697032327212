import { SubTableColumn } from 'src/components/commons/subTable/types';

const COLUMNS: SubTableColumn[] = [
  {
    title: 'NAME',
    dataIndex: 'title',
    editable: true,
    unique: true,
    required: true,
    key: 'name',
    minWidth: 270,
    dataType: 'string',
    hidden: false,
    dataStructure: 'name',
    sortable: false,
    headerName: 'NAME',
    orderField: 'name',
    searchField: 'name',
  },
];

export default COLUMNS;
