import React, { useState } from 'react';
import { Form } from 'antd';
import ReactDragListView from 'react-drag-listview';
import CompanyDetailStyles from 'src/components/companyDetails/components/companyDetail.styles';
import SectionCard from 'src/components/sectionCard/SectionCard';
import { useContactContext } from 'src/context/contact.context';
import { DRAG_LIST_SECTIONS } from '../constants';

const ContactBasicTab = () => {
  const [sections, setSections] = useState(DRAG_LIST_SECTIONS);
  const { form } = useContactContext();

  // TODO convert in a global hook
  const handleDragEnd = (fromIndex: number, toIndex: number) => {
    const data = [...sections];
    const item = data.splice(fromIndex, 1)[0];
    data.splice(toIndex, 0, item);
    setSections(data);
  };

  return (
    <>
      <CompanyDetailStyles />
      <Form name='formContactEdit' layout='vertical' form={form}>
        <ReactDragListView
          onDragEnd={handleDragEnd}
          nodeSelector='.section-card'
          handleSelector='.anticon-holder'
        >
          <div>
            {sections.map((section) => (
              <SectionCard title={section.title} key={section.title}>
                {section.content}
              </SectionCard>
            ))}
          </div>
        </ReactDragListView>
      </Form>
    </>
  );
};

export default ContactBasicTab;
