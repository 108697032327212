import React, { PropsWithChildren, useContext } from 'react';
import { Empty } from 'antd';
import { CompaniesContext } from 'src/context/companies.context';
import { useLocation } from 'react-router-dom';
import CustomFooter from 'src/components/commons/customFooter';
import CompanySubHeader from '../subHeader/company';
import ActivitySubHeader from '../subHeader/activity';

const CustomViewContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const { showEmptyPage, companiesError } = useContext(CompaniesContext);

  const subHeaderComponents = {
    companies: <CompanySubHeader />,
    tasks: <div>Tasks SubHeader</div>,
    activity: <ActivitySubHeader />,
    oportunities: <div>Opportunities SubHeader</div>,
    quotes: <div>Quotes SubHeader</div>,
    messages: <div>Messages SubHeader</div>,
  };

  if (!companiesError && showEmptyPage) {
    return (
      <>
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 115px)',
            display: 'flex',
            alignItems: 'center',
            margin: 'auto',
            justifyContent: 'center',
          }}
        >
          <Empty />
        </div>
        <CustomFooter />
      </>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      {subHeaderComponents[location.pathname.slice(1) as keyof typeof subHeaderComponents]}
      <div>{children}</div>
    </div>
  );
};

export default CustomViewContainer;
