import React from 'react';
import { Button, ConfigProvider } from 'antd';
import CustomSelect from 'src/components/commons/customSelect';
import { PlusOutlined } from '@ant-design/icons';
import { SelectSearchStyles } from './selectSearch.styles';

export interface OptionType {
  [x: string]: any;
  label: string;
  value: string | number;
}

interface SelectSearchProps {
  handleChange?: (list: any, name: any) => void;
  options?: (string | OptionType)[];
  addNewButton?: boolean;
  addNewButtonFunction?: () => void;
  multiselect?: boolean;
  twoLines?: boolean;
  name?: string;
  onSearch?: (value: string, name: string | undefined) => void;
  defaultValue?: string;
}

const SelectSearch: React.FC<SelectSearchProps> = ({
  handleChange,
  options,
  addNewButton,
  addNewButtonFunction,
  multiselect,
  twoLines,
  name,
  onSearch,
  defaultValue,
}) => (
  <>
    <SelectSearchStyles />
    <div className='multi-select-search-container'>
      <CustomSelect
        defaultValue={defaultValue}
        customClassName='customSelect'
        handleMultipleSelect={handleChange}
        handleChange={handleChange}
        onSearch={(value) => {
          if (onSearch) {
            onSearch(value, name);
          }
        }}
        allowClear
        twoLines={twoLines}
        multiSel={multiselect}
        externalWidth='100%'
        listDefault={multiselect ? options : undefined}
        options={
          multiselect
            ? undefined
            : options?.map((item: any) => ({
                label: item.label,
                value: item.value,
                desc: item.desc,
              })) || []
        }
        internalHeight='46px'
        addNewButton={
          addNewButton && multiselect ? (
            <Button className='custom-add-new-button'>
              Add New
              <PlusOutlined />
            </Button>
          ) : (
            <ConfigProvider wave={{ disabled: true }}>
              <Button onClick={addNewButtonFunction} className='custom-add-new-button'>
                Add New
                <PlusOutlined />
              </Button>
            </ConfigProvider>
          )
        }
        addNewButtonFunction={addNewButtonFunction}
      />
    </div>
  </>
);
export default SelectSearch;
