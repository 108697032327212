import { gql } from '@apollo/client';

export const GET_ALL_ADDRESS_TYPE = gql`
  query GetAllAddressType2(
    $criteria: AddressTypeCriteriaInput!
    $searchFields: FindAddressTypeInput
  ) {
    GetAllAddressType2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
        description
      }
      total
    }
  }
`;

export const GET_HEADERS_ADDRESS_TYPE = gql`
  query Query {
    GetAddressTypeGridHeaders
  }
`;
