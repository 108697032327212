import { FC } from 'react';
import SubTableV1 from 'src/components/commons/subTableV1';
import PageContainer from 'src/components/pageContainer/PageContainer';
import useLeadSource from './hooks/useLeadSource';

const LeadSource: FC = () => {
  const {
    data,
    columns,
    addNewRecord,
    editRecord,
    deleteRecord,
    autocompleteFieldSearch,
    sortRecord,
  } = useLeadSource();

  return (
    <PageContainer>
      <div style={{ paddingLeft: 50, paddingRight: 50 }}>
        <SubTableV1
          data={data ?? []}
          columns={columns ?? []}
          columnFilterShown
          total
          showAutoIncrement
          nonSelectable
          actionCell={['delete']}
          scroll={{ y: '55vh', x: 'auto' }}
          postData={addNewRecord}
          editRecord={editRecord}
          onDeleteClick={deleteRecord}
          autocompleteFieldProvider={autocompleteFieldSearch}
          onSortChange={sortRecord}
          allowGlobalEditActive
          id='lead-source'
        />
      </div>
    </PageContainer>
  );
};

export default LeadSource;
