import { Button, Space } from 'antd';
import React from 'react';

const ButtonReloadClearCache = () => (
  <Space>
    <Button
      type='primary'
      size='small'
      onClick={() => {
        const preservedItem: string = localStorage.getItem('tokenApp')!;
        localStorage.clear();
        localStorage.setItem('tokenApp', preservedItem);
        window.location.reload();
      }}
    >
      Reload page
    </Button>
  </Space>
);

export default ButtonReloadClearCache;
