import { StopOutlined, UserOutlined } from '@ant-design/icons';
import { SubTableColumn } from 'src/components/commons/subTable/types';
import EditableCellUserTable from 'src/components/settingsTables/editableCellUserTable';
import StyledCustomTag from './tag.style';

const COLUMNS: SubTableColumn[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    editable: true,
    width: 270,
    unique: true,
    required: true,
    // minWidth: 100,
    dataType: 'string',
    hidden: false,
    dataStructure: 'string',
    headerName: 'Name',
    // orderField: 'name',
    // searchField: 'name',
  },
  {
    title: 'Split',
    dataIndex: 'companySplit',
    editable: true,
    width: 170,
    unique: false,
    required: true,
    // minWidth: 80,
    dataType: 'percent',
    hidden: false,
    dataStructure: 'percent',
    headerName: 'Split',
    // orderField: 'name',
    // searchField: 'name',
  },
];

export const USERCOLUMNS: SubTableColumn[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    editable: true,
    width: 270,
    unique: true,
    required: true,
    // minWidth: 100,
    dataType: 'string',
    hidden: false,
    dataStructure: 'string',
    headerName: 'Name',
    // orderField: 'name',
    // searchField: 'name',
  },
];

export const COLUMNS_MEMBERS: SubTableColumn[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    editable: true,
    width: 200,
    unique: true,
    required: true,
    // minWidth: 270,
    dataType: 'string',
    hidden: false,
    dataStructure: 'string',
    headerName: 'Name',
    // orderField: 'name',
    // searchField: 'name',
  },
  {
    title: 'Split',
    dataIndex: 'companySplit',
    editable: true,
    width: 100,
    unique: false,
    required: true,
    // minWidth: 270,
    dataType: 'percent',
    hidden: false,
    dataStructure: 'percent',
    headerName: 'Name',
    render: (text) => <EditableCellUserTable value={19 ?? text} onValueChange={console.log} />,
    // orderField: 'name',
    // searchField: 'name',
  },
  {
    title: 'Sales Team',
    dataIndex: 'companySplit',
    editable: true,
    width: 100,
    // minWidth: 800,
    unique: false,
    required: true,
    dataType: 'percent',
    hidden: false,
    dataStructure: 'percent',
    headerName: 'Name',
    render: () => (
      <>
        <StyledCustomTag>0%</StyledCustomTag>
        <StopOutlined />
        <UserOutlined style={{ color: '#0078D4' }} />
      </>
    ),
    // orderField: 'name',
    // searchField: 'name',
  },
];

export const TABS: { to: string; label: string }[] = [
  {
    to: 'sales_teams',
    label: 'Member Info',
  },
  {
    to: 'sales_teams/customer_split',
    label: 'Customer Exception Split',
  },
  {
    to: 'sales_teams/internal_split',
    label: 'Internal Split',
  },
];

export default COLUMNS;
