import { Skeleton } from 'antd';

const TempSkeleton = () => {
  const data = [1, 2, 3, 4, 5, 6];

  return (
    <div style={{ padding: '2rem' }}>
      <table style={{ width: '100%' }}>
        {data.map((item: number) => (
          <tr key={item}>
            {data.map((head: number) => (
              <th key={head}>
                <Skeleton paragraph={{ rows: 0 }} style={{ width: '220%' }} />
              </th>
            ))}
          </tr>
        ))}
      </table>
    </div>
  );
};

export default TempSkeleton;
