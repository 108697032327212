import { FC, Fragment, useEffect, useState } from 'react';
import { Col, Divider, FormInstance } from 'antd';
import { StyledTitle } from 'src/components/commons/customHeader/CustomHeader.styles';
import { OptionType } from 'src/components/commons/customSelect';
import PrimaryInformation from './primaryInfo';
import Purpose from './purpose';
// import Expenses from './expenses';
import MfgProduct from './mfgProduct';
import { StyledModalCreateEditActivity } from './styles/index.styles';
import CompaniesAttendees from './companiesAttendees';

interface Props {
  attendees?: any[] | [];
  form?: FormInstance;
}

const ModalCreateEditActivity: FC<Props> = ({ form, ...rest }) => {
  const [attendeesAndCompanies, setAttendeesAndCompanies] = useState<any[] | []>([]);

  useEffect(() => {
    if (rest.attendees) {
      const attendeesStructure = rest.attendees.map((attendee) => ({
        company: {
          value: attendee?.companyName,
          label: attendee.companyName,
          toSend: attendee.companyId,
        },
        contact: [
          { value: attendee?.contactName, label: attendee.contactName, toSend: attendee.contactId },
        ],
      }));
      setAttendeesAndCompanies(attendeesStructure ?? []);
    }
  }, [rest.attendees]);

  const addAttendee = (idx: number, attendee: OptionType, contactIdx?: number) => {
    const copiedattendeesAndCompanies = attendeesAndCompanies.map((data, atdIdx) => {
      if (atdIdx === idx) {
        return {
          ...data,
          contact: contactIdx
            ? [...data.contact, attendee].filter((_, cIdx) => cIdx !== contactIdx)
            : [...data.contact, attendee],
        };
      }
      return data;
    });
    setAttendeesAndCompanies(copiedattendeesAndCompanies);
  };

  const deleteAttendee = (idx: number, attendeeIdx: number) => {
    const copiedattendeesAndCompanies = attendeesAndCompanies.map((data, atdIdx) => {
      if (atdIdx === idx) {
        return {
          ...data,
          contact: data.contact.filter((_: any, contactIdx: number) => contactIdx !== attendeeIdx),
        };
      }
      return data;
    });
    setAttendeesAndCompanies(copiedattendeesAndCompanies);
  };

  const addCompany = (idx: number, option: OptionType) => {
    const copiedattendeesAndCompanies = attendeesAndCompanies.map((data, atdIdx) => {
      if (atdIdx === idx) {
        return {
          ...data,
          company: option,
        };
      }
      return data;
    });
    setAttendeesAndCompanies(copiedattendeesAndCompanies);
  };

  const deleteCompany = (idx: number) => {
    const copiedattendeesAndCompanies = attendeesAndCompanies.filter(
      (data, atdIdx) => atdIdx !== idx,
    );
    setAttendeesAndCompanies(copiedattendeesAndCompanies);
  };

  return (
    <StyledModalCreateEditActivity>
      <Col span={6}>
        <StyledTitle style={{ color: 'black', margin: 0 }} level={5}>
          Primary Information
        </StyledTitle>
        <PrimaryInformation showTitle={false} setAttendeesAndCompanies={setAttendeesAndCompanies} />
        <div className='companies-attendees-container'>
          {(!attendeesAndCompanies || attendeesAndCompanies.length === 0) && (
            <p style={{ padding: '30% 0.5rem' }}>No companies or attendees have been selected</p>
          )}
          {attendeesAndCompanies?.map((attendee: any, idx) => (
            <Fragment key={attendee.company}>
              <CompaniesAttendees
                addAttendee={addAttendee}
                addCompany={addCompany}
                deleteAttendee={deleteAttendee}
                deleteCompany={deleteCompany}
                company={attendee?.company}
                contactName={attendee?.contact}
                currentIdx={idx}
              />
            </Fragment>
          ))}
        </div>
      </Col>
      <Col>
        <Divider
          orientationMargin={4}
          type='vertical'
          orientation='right'
          style={{ height: '100%' }}
        />
      </Col>
      <Col span={17}>
        <Purpose />
        {/* <StyledTitle style={{ color: 'black', margin: '0.5rem 0' }} level={5}>
          Expenses
        </StyledTitle> */}
        {/* <Expenses /> */}
        <Divider />
        <MfgProduct />
      </Col>
    </StyledModalCreateEditActivity>
  );
};

export default ModalCreateEditActivity;
