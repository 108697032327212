import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { SortModel, SubTableColumn } from 'src/components/commons/subTable/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { PhoneTypeModel } from 'src/graphql/schema-types';
import COLUMNS from '../constants';
import { GET_ALL_PHONE_TYPES, GET_PHONE_TYPES_HEADERS } from '../graphql/queries';
import { UPDATE_PHONE_TYPE, CREATE_PHONE_TYPE, REMOVE_PHONE_TYPE } from '../graphql/mutations';
import { PhoneTypeCompany, GetAllPhoneTypesCompany, GetPhoneTypeGridHeaders } from '../types';
import { filterIDAndAddingTitle } from '../../../../../utils/functions';
import useFilterColumn from '../../callPattern/hooks/useFilterColumn';

const usePhoneTypesTable = () => {
  const { createdFilters, setColumnFilters } = useFilterColumn();

  const [getAll, queryResponse] = useLazyQuery<GetAllPhoneTypesCompany>(GET_ALL_PHONE_TYPES, {
    fetchPolicy: 'network-only',
  });
  const [getAllSearch] = useLazyQuery<GetAllPhoneTypesCompany>(GET_ALL_PHONE_TYPES, {
    fetchPolicy: 'network-only',
  });
  const [getHeaders] = useLazyQuery<GetPhoneTypeGridHeaders>(GET_PHONE_TYPES_HEADERS, {
    fetchPolicy: 'network-only',
  });
  const [update] = useMutation(UPDATE_PHONE_TYPE);
  const [create] = useMutation(CREATE_PHONE_TYPE);
  const [remove] = useMutation(REMOVE_PHONE_TYPE);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 1000 });
  const [columns, setColumns] = useState<SubTableColumn[]>([]);
  const [newRecord, setNewRecord] = useState({
    name: '',
    description: '',
  });

  const fetchPhoneTypesPage: FetchFunc = ({ start, renderLen }) =>
    getAll({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllPhoneTypes2.results || [],
      total: res.data?.GetAllPhoneTypes2.total || 0,
    }));

  const { dataSource, onListRender, setDataSource, reset } =
    useInfinityDataSource(fetchPhoneTypesPage);

  useEffect(() => {
    getHeaders().then((res) => {
      const filteredResult = filterIDAndAddingTitle(
        res.data?.GetPhoneTypeGridHeaders as unknown as SubTableColumn[],
      ).map((header) => {
        if (header.dataIndex === 'name')
          return {
            ...header,
            searchField: 'name',
            title: 'PHONE TYPE',
          };
        if (header.dataIndex === 'description')
          return {
            ...header,
            searchField: 'description',
          };
        return header;
      });
      setColumns(filteredResult ?? COLUMNS);
      reset();
    });
  }, []);

  const autocompleteFieldSearch = (
    searchField: string,
    value: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(searchField, value, dataType);
    return getAllSearch({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 100,
          },
          filter: filters,
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => {
      if (res.data) {
        return {
          results: res?.data?.GetAllPhoneTypes2?.results,
          filter: res?.data?.GetAllPhoneTypes2?.results.map((result: PhoneTypeCompany) => ({
            value: result[searchField],
            label: result[searchField],
          })),
        };
      }

      return {
        results: [],
        filter: [],
      };
    });
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
      description: '',
    });
  };

  const postNewRecord = async () => {
    if (newRecord.name === '') {
      return;
    }

    await create({
      variables: {
        createPhoneTypeInput: {
          name: capitalizeFirstWord(newRecord.name),
          description: newRecord.description,
          isCompany: true,
          isContact: false,
        },
      },
    }).then(() => {
      setTimeout(() => {
        reset();
      }, 500);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: PhoneTypeModel[]) => {
    rows.forEach((row) => {
      const { id, name, description } = row;
      update({
        variables: {
          updatePhoneTypeInput: {
            id,
            name: capitalizeFirstWord(name!),
            description,
            isCompany: true,
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: PhoneTypeModel[]) => {
    const removeId = rows[0].id;
    try {
      remove({ variables: { id: removeId } }).then(() => {
        setTimeout(() => {
          reset();
        }, 500);
      });
    } catch (error) {
      console.error('Error deleting records:', error);
    }
  };

  const handleNewRecord = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = async (sortModel: SortModel | undefined) => {
    if (!sortModel) {
      // Load original data without any sorting
      try {
        const response = await getAll({
          variables: {
            criteria: {
              pagination: {
                from: (pagination.page - 1) * pagination.pageSize,
                size: pagination.pageSize,
              },
            },
          },
        });

        // Update data source with the original data
        if (response.data && response.data.GetAllPhoneTypes2) {
          setDataSource(response.data.GetAllPhoneTypes2.results);
        }
      } catch (error) {
        // Handle the error scenario
        console.error('Failed to fetch original data:', error);
      }
      return; // Early return to skip sorting if sortModel is undefined
    }

    // Check if sortBy is "parent" and change it to "parent_name"
    const sortByField = sortModel.sortBy === 'parent' ? 'parent_name' : sortModel.sortBy;

    try {
      // Update pagination with the new sort model
      setPagination((prev) => ({
        ...prev,
        sort: sortModel,
      }));

      // Fetch sorted data
      const response = await getAll({
        variables: {
          criteria: {
            pagination: {
              from: (pagination.page - 1) * pagination.pageSize,
              size: pagination.pageSize,
            },
            orderBy: [
              {
                field: sortByField,
                sort: sortModel.sort.toUpperCase(),
              },
            ],
          },
        },
      });

      // Update data source with new sorted data
      if (response.data && response.data.GetAllPhoneTypes2) {
        setDataSource(response.data.GetAllPhoneTypes2.results);
      }
    } catch (error) {
      // Handle the error scenario
      console.error('Failed to fetch sorted data:', error);
    }
  };

  const handleClearFilter = () => {
    reset();
    setColumnFilters([]);
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    dataSource,
    onListRender,
    handleUpdateRows,
    spinning: queryResponse.loading,
    newRecord,
    handleNewRecord,
    resetNewRecord,
    postNewRecord,
    handleClearFilter,
    handleDeleteRows,
  };
};

export default usePhoneTypesTable;
