import { gql } from '@apollo/client';

const GET_REPORTS = gql`
  subscription Subscription {
    reports {
      status
      requestId
    }
  }
`;

const GET_EXPORT_FILE_LINK = gql`
  query GetExportFileLink($reportId: String!, $token: String!) {
    GetExportFileLink(reportId: $reportId, token: $token) {
      status
      requestId
      link
    }
  }
`;

export { GET_REPORTS, GET_EXPORT_FILE_LINK };
