import { Button } from 'antd';
import styled from 'styled-components';

const StyledPinButton = styled(Button)<{ color: string }>`
  border: none !important;
  background-color: none;
  color: ${(props) => props.color};
`;

export default StyledPinButton;
