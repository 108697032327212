import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { AddressModel } from 'src/models';
import { optional } from './common';
import countryAdapter from './country.adapter';
import stateAdapter from './state.adapter';
import addressTypeAdapter from './addressType.adapter';
import continentAdapter from './continent.adapter';
import regionAdapter from './region.adapter';
import territoryAdapter from './territory.adapter';

const addressAdapter: AdapterFunc<GQL.AddressDbModel, AddressModel> = (input) => ({
  id: input.id,
  city: input.city!,
  address: input.address!,
  country: optional(countryAdapter, input.country),
  createdAt: input.createdAt,
  zipCode: input.zipCode!,
  state: optional(stateAdapter, input.state),
  addressType: optional(addressTypeAdapter, input.addressType),
  continent: optional(continentAdapter, input.continent),
  formattedAddress: input.formattedAddress!,
  geopositionLatitude: input.geopositionLatitude!,
  geopositionLongitude: input.geopositionLongitude!,
  isAddressInvalid: input.isAddressInvalid!,
  pobox: input.pobox!,
  region: optional(regionAdapter, input.region),
  territory: optional(territoryAdapter, input.territory),
  updatedAt: input.updatedAt!,
});

export default addressAdapter;
