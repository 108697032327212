import styled from 'styled-components';

export const SInputEmail = styled.div<{ mainRecord: boolean }>`
  margin-top: ${(props) => (props.mainRecord ? 8 : 0)};
  input {
    &:disabled {
      cursor: not-allowed;
    }
  }
`;
