import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import set from 'lodash/set';
import get from 'lodash/get';
import { jwtDecode } from 'jwt-decode';

const headers = {
  'rf-userId': '7',
  'x-tenantId': '3',
  'x-tenantName': 'demo',
};

const noContextHttpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVICE,
  headers,
});

const uploadLinkCreated = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVICE,
  headers,
});

const httpLinkWithContext = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVICE,
  headers,
});

const websocketUri = process.env.REACT_APP_GRAPHQL_SERVICE?.replace('https', 'wss') || '';
const websocketLink = new WebSocketLink(
  new SubscriptionClient(websocketUri, {
    reconnect: true,
    connectionParams: {
      headers,
    },
  }),
);

const jwtLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('tokenApp');

  if (token) {
    try {
      const decoded: any = jwtDecode(token);
      // const { tenantId, issuer, userId } = decoded;
      const userId = '7';
      const tenantId = '3';
      const { issuer } = decoded;
      operation.setContext({
        headers: {
          Authorization: `${token.replace(/^"(.*)"$/, '$1')}`,
          'rf-userId': userId,
          'x-issuer': issuer,
          'x-tenantId': tenantId,
          'x-tenantName': 'demo',
          token,
        },
      });
    } catch (error) {
      console.error('Error decodificando el JWT: ', error);
    }
  }
  return forward(operation);
});

const onlyEnabledLink = new ApolloLink((operation, forward) => {
  if (
    operation.query &&
    operation.operationName?.match(/^getall/i) &&
    operation.operationName !== 'GetAllOpportunities'
  ) {
    const enablePaths = ['variables.searchFields.enabled'];
    enablePaths.forEach((path) => {
      const enabled = get(operation, path);

      if (enabled === undefined || enabled === null) {
        set(operation, path, true);
        operation.setContext({
          variables: operation.variables,
        });
      }
    });
  }

  return forward(operation);
});

const uploadLink = uploadLinkCreated as unknown as ApolloLink;

const httpLink = ApolloLink.from([
  jwtLink,
  onlyEnabledLink,
  uploadLink,
  ApolloLink.split(
    (operation) => operation.getContext().clientName === 'elastic',
    httpLinkWithContext,
    noContextHttpLink,
  ),
]);

const splitLink = ApolloLink.split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  websocketLink,
  httpLink,
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink,
});

export default client;
