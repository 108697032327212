import { FC, useEffect, useMemo, useState } from 'react';
import {
  CopyOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  LinkedinFilled,
  MailOutlined,
  MobileOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import copyToClipboard from 'src/utils/functions/copyClipboard';
import { useContactContext } from 'src/context/contact.context';
import StyledMainContactCard from '../styles/contactsMainContactCard.styles';

const ContactsMainContactCard: FC = () => {
  const { contact: contactData } = useContactContext();

  const [contact, setContact] = useState<{ email?: string; phone?: string; mobile?: string }>();

  useEffect(() => {
    const contactEmail = contactData?.businessInfo?.mainEmail?.email;
    const contactPhone = contactData?.businessInfo?.mainPhone;
    const contactMobile = contactData?.businessInfo?.phones?.find(
      (phone) => phone.phoneType?.name?.toLocaleLowerCase() === 'mobile',
    );

    setContact({
      email: contactEmail,
      phone: contactPhone?.phone
        ? `${contactPhone?.countryCode}${contactPhone?.areaCode}${contactPhone?.phone}`.replace(
            /-/g,
            '',
          )
        : undefined,
      mobile: contactMobile?.phone
        ? `${contactMobile?.countryCode}${contactMobile?.areaCode}${contactMobile?.phone}`.replace(
            /-/g,
            '',
          )
        : undefined,
    });
  }, [contactData]);

  const address = useMemo(
    () =>
      [
        contactData?.businessInfo?.mainAddress?.address,
        contactData?.businessInfo?.mainAddress?.city,
        contactData?.businessInfo?.mainAddress?.state?.name,
        contactData?.businessInfo?.mainAddress?.zipCode,
      ]
        .filter(Boolean)
        .join(', '),
    [contactData],
  );

  const contactPhone = contact?.phone
    ? JSON.stringify(contact?.phone).split('-').join('')
    : undefined;

  return (
    <StyledMainContactCard>
      <div className='data-group-container'>
        <div className='data-container'>
          <p>
            <PhoneOutlined />
            Phone
          </p>
          {contactPhone ? (
            <a href={`tel:${contactPhone}`}>{contact?.phone}</a>
          ) : (
            <p className='text-data'>No data</p>
          )}
          <span>
            <Tooltip title='Copy' trigger='hover' color='gray'>
              <Button onClick={() => contactPhone && copyToClipboard(contactPhone)}>
                {contact?.phone && <CopyOutlined />}
              </Button>
            </Tooltip>
          </span>
        </div>
        <div className='data-container'>
          <p>
            <MobileOutlined />
            Mobile Phone
          </p>
          {contact?.mobile ? (
            <a href={`tel:${contact?.mobile}`}>{contact?.mobile}</a>
          ) : (
            <p className='text-data'>No data</p>
          )}
          <span>
            {contact?.mobile && (
              <Tooltip title='Copy' trigger='hover' color='gray'>
                <Button onClick={() => copyToClipboard('9384399374')}>
                  <CopyOutlined />
                </Button>
              </Tooltip>
            )}
          </span>
        </div>
      </div>
      <div className='data-group-container'>
        <div className='data-container'>
          <p>
            <MailOutlined />
            Mail
          </p>
          {contact?.email ? (
            <a href={`mailto:${contact.email}`}>{contact.email}</a>
          ) : (
            <p className='text-data'>No data</p>
          )}
          <span>
            <Tooltip title='Copy' trigger='hover' color='gray'>
              <Button onClick={() => copyToClipboard(contact?.email as string)}>
                {contact?.email && <CopyOutlined />}
              </Button>
            </Tooltip>
          </span>
        </div>
        <div className='data-container'>
          <p>
            <GlobalOutlined />
            Personal Web Site
          </p>
          <a href='https://bit.ly/3BztJYL' target='_blank' rel='noreferrer'>
            https://bit.ly/3BztJYL
          </a>
          <span>
            <Tooltip title='Copy' trigger='hover' color='gray'>
              <Button onClick={() => copyToClipboard('https://bit.ly/3BztJYL')}>
                <CopyOutlined />
              </Button>
            </Tooltip>
          </span>
        </div>
      </div>
      <div className='data-group-container'>
        <div className='data-container'>
          <p>
            <EnvironmentOutlined />
            Location
          </p>
          <p className='text-data'>
            {address ? (
              <a
                target='_blank'
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  address,
                )}`}
                rel='noreferrer'
              >
                {address}
              </a>
            ) : (
              'No data'
            )}
            {address && (
              <span>
                <Tooltip title='Copy' trigger='hover' color='gray'>
                  <Button onClick={() => copyToClipboard(address)}>
                    <CopyOutlined />
                  </Button>
                </Tooltip>
              </span>
            )}
          </p>
        </div>
        <div className='data-container'>
          <p>
            <LinkedinFilled />
            LinkedIn
          </p>
          <p className='text-data'>No data</p>
          {/* <a href='https://bit.ly/3BztJYL'>https://bit.ly/3BztJYL</a>
          <span>
            <Tooltip title='Copy' trigger='hover' color='gray'>
              <Button onClick={() => copyToClipboard('https://bit.ly/3BztJYL')}>
                <CopyOutlined />
              </Button>
            </Tooltip>
          </span> */}
        </div>
      </div>
    </StyledMainContactCard>
  );
};
export default ContactsMainContactCard;
