import React, { useState, useRef, useContext, useEffect } from 'react';
import get from 'lodash/get';
import type { FormInstance } from 'antd/es/form';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Form, Input, InputRef, ColorPicker, Space, Tag } from 'antd';
import { DataStructureType2, CompaniesElasticTypes2 } from 'src/types/companiesTypes';
import { CompaniesContext } from 'src/context/companies.context';
import CustomSelect from '../customSelect';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow2: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: string;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string | keyof CompaniesElasticTypes2;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  record: any;
  dataKey: string;
  handleSave: (record: CompaniesElasticTypes2) => void;
  searchField: string;
  dataType: string;
  options: DataStructureType2[] | undefined;
  hasLink: boolean;
}

const EditableCell2: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  dataKey,
  record,
  searchField,
  dataType,
  options,
  handleSave,
  hasLink,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [targetElement, setTargetElement] = useState(false);
  const [validatingStatus, setValidatingStatus] = useState<
    '' | 'success' | 'error' | 'warning' | 'validating' | undefined
  >('');
  const [wasEdited, setWasEdited] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;
  const {
    activeEditingColumn,
    searchRecurrentDataHandler,
    searchRecurrentDataResponse,
    saveEditDataPayload2,
  } = useContext(CompaniesContext);
  const prevValue = useRef<string | DataStructureType2>('');
  const newValue = useRef<string | DataStructureType2>('');
  const borderForm = useRef('');
  let timer: NodeJS.Timeout = 0 as unknown as NodeJS.Timeout;
  const requiredIds = ['name'];
  const dataToRender = useRef<string>('');
  const multiDataToRender = useRef<DataStructureType2[]>([]);
  const [generalParentObject, setGeneralParentObject] = useState(
    dataIndex?.split('.').length === 1 ? dataIndex : dataIndex?.split('.')[0],
  );
  const [detailRedirectLink, setDetailRedirectLink] = useState(false);

  const innerPath = [dataIndex, dataKey].filter(Boolean).join('.');
  if (dataType === 'multiSelect') {
    multiDataToRender.current = get(record, dataIndex);
  } else {
    dataToRender.current = get(record, innerPath);
  }
  if (dataToRender.current && !wasEdited) {
    prevValue.current = dataToRender.current;
  }

  const createObject = (path: string, value: DataStructureType2 | string) => {
    const allSplitedPath: string[] = path?.split('.');
    let actualObject: any = {};

    allSplitedPath?.forEach((splitedPath, index) => {
      if (index === allSplitedPath.length - 1) {
        actualObject[splitedPath] = value;
      } else {
        actualObject[splitedPath] = actualObject[splitedPath] || {};
        actualObject = actualObject[splitedPath];
      }
    });

    return actualObject;
  };

  const manageSearchRecurrentDataResponse = () => {
    const innerData = searchRecurrentDataResponse?.data?.GetCompanyUniqueValuesFromColumn;
    if (innerData.length > 0) {
      innerData?.forEach((element: string) => {
        if (newValue.current !== prevValue.current && typeof newValue.current === 'string') {
          if (element.toLowerCase() === newValue.current.toLowerCase()) {
            setValidatingStatus('warning');
          }
        }
      });
      if (validatingStatus === 'validating') {
        setValidatingStatus('success');
      }
    } else {
      setValidatingStatus('success');
    }
  };

  useEffect(() => {
    setGeneralParentObject(
      dataIndex?.split('.').length === 1 ? dataIndex : dataIndex?.split('.')[0],
    );
    if (dataIndex?.toLowerCase().includes('mainaddress') && !record[generalParentObject]) {
      setDetailRedirectLink(!dataToRender.current);
    }
    if (editing) {
      inputRef.current?.focus();
    }
    if (
      targetElement &&
      !searchRecurrentDataResponse?.loading &&
      searchRecurrentDataResponse?.data
    ) {
      manageSearchRecurrentDataResponse();
    }
    if (!activeEditingColumn) {
      setWasEdited(false);
      setValidatingStatus('');
      borderForm.current = '';
    }
  }, [editing, validatingStatus, searchRecurrentDataResponse?.loading, activeEditingColumn]);

  const targetElementHandler = () => {
    setTargetElement(true);
  };

  const leaveElementHandler = () => {
    setTargetElement(false);
  };

  const toggleEdit = () => {
    if (activeEditingColumn) {
      setEditing(!editing);
    }
  };

  const onInputChange = async () => {
    try {
      clearTimeout(timer);
      const value = inputRef.current?.input?.value ?? '';
      await form.validateFields();
      newValue.current = value;
      if (newValue.current !== prevValue.current) {
        if (searchField === 'names') {
          timer = setTimeout(() => {
            setValidatingStatus('validating');
            searchRecurrentDataHandler((newValue.current as unknown as string).trim(), 'name');
          }, 600);
        }
      } else {
        setValidatingStatus('');
      }
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.log('Save failed:', errInfo);
    }
  };

  const saveInputData = async () => {
    try {
      const value = inputRef.current?.input?.value ?? '';
      const parentObject = generalParentObject;
      await form.validateFields();
      newValue.current = value;
      const valueToSave = createObject(dataIndex, value);
      const { id } = record;
      const newPayloadData = valueToSave as unknown as CompaniesElasticTypes2;
      const warningStatus = validatingStatus === 'warning';
      toggleEdit();
      saveEditDataPayload2({ id, newPayloadData, warningStatus, parentObject });
      if (newValue.current !== prevValue.current) {
        setWasEdited(true);
      } else {
        setWasEdited(false);
      }
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.log('Save failed:', errInfo);
    }
  };

  const saveDropdownData = (e: React.FormEvent<HTMLInputElement>) => {
    if (options) {
      const innerValue = e as unknown as number;
      const parentObject = generalParentObject;
      const innerSelectedOption = options.findIndex((item) => innerValue === item.id);
      newValue.current = options[innerSelectedOption];
      const valueToSave = createObject(dataIndex, newValue.current);
      const { id } = record;
      const newPayloadData = valueToSave as unknown as CompaniesElasticTypes2;
      const warningStatus = false;
      saveEditDataPayload2({ id, newPayloadData, warningStatus, parentObject });
      if (newValue.current !== prevValue.current) {
        setWasEdited(true);
      } else {
        setWasEdited(false);
      }
    }
  };

  if (wasEdited) {
    borderForm.current = '1px solid #0078D4';
  }

  if (wasEdited && validatingStatus === 'warning') {
    borderForm.current = '1px solid #faad14';
  }

  let childNode = children;

  if (editable) {
    if (dataType === 'dropDown') {
      if (detailRedirectLink) {
        childNode = activeEditingColumn && (
          <a href={`/companies/${record.id}/basic`}>Please complete the address</a>
        );
      } else {
        childNode = activeEditingColumn ? (
          <CustomSelect
            handleChange={saveDropdownData}
            style={{ width: '100%' }}
            defaultValue={dataToRender.current}
            options={
              options?.map((element) => ({
                label: element?.name || 'unknown',
                value: element?.id || 'unknown',
              })) as []
            }
          />
        ) : (
          <div className='no-editable-cell'>{dataToRender.current}</div>
        );
      }
    } else if (dataType === 'multiSelect') {
      childNode = (
        <div className='no-editable-cell'>
          <Space wrap direction='horizontal'>
            {multiDataToRender.current?.map((element) => (
              <Tag>{element.name}</Tag>
            ))}
          </Space>
        </div>
      );
    } else if (detailRedirectLink) {
      childNode = activeEditingColumn && (
        <a href={`/companies/${record.id}/basic`}>Please complete the address</a>
      );
    } else {
      childNode =
        editing && activeEditingColumn ? (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex && dataIndex}
            rules={
              requiredIds.includes(inputRef?.current?.input?.id || '')
                ? [
                    {
                      required: true,
                      message: `A ${title} is required.`,
                    },
                  ]
                : []
            }
            hasFeedback={requiredIds.includes(inputRef?.current?.input?.id || '')}
            validateStatus={validatingStatus}
          >
            <Input
              ref={inputRef}
              onPressEnter={saveInputData}
              defaultValue={dataToRender.current}
              onBlur={saveInputData}
              onChange={onInputChange}
            />
          </Form.Item>
        ) : (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/mouse-events-have-key-events
          <div
            onMouseOver={targetElementHandler}
            onMouseLeave={leaveElementHandler}
            className={
              targetElement && activeEditingColumn ? 'editable-cell-value-wrap' : 'no-editable-cell'
            }
            style={{
              paddingLeft: 12,
              border: wasEdited && activeEditingColumn ? borderForm.current : undefined,
              paddingTop: 5,
              paddingBottom: 5,
            }}
            onClick={toggleEdit}
          >
            {hasLink && !activeEditingColumn ? children : dataToRender.current}
          </div>
        );
    }
  } else if (dataType === 'color' && typeof dataToRender.current === 'string') {
    childNode = <ColorPicker defaultValue={dataToRender.current} size='small' disabled />;
  } else if (dataType === 'multiSelect') {
    childNode = <div className='no-editable-cell'>{dataToRender.current}</div>;
  } else {
    childNode = (
      <div className={activeEditingColumn ? 'no-editable' : 'no-editable-cell'}>
        {dataIndex ? dataToRender.current : children}
      </div>
    );
  }

  const cellKey = `td-${[dataIndex, dataKey].filter(Boolean).join('.')}`;

  return (
    <td {...restProps} data-testid={cellKey}>
      {childNode}
    </td>
  );
};

export { EditableRow2, EditableCell2 };
