import { SortModel } from 'src/components/commons/subTable/types';
import useCannedNotesStore from 'src/hooks/stores/useCannedNotesStore';
import { useProductLinesHeaders } from 'src/hooks/useProductLinesHeaders';
import { CannedNoteModel } from 'src/models/cannedNote.model';

const useProductLinesTable = () => {
  const { dataSource, loadPage, deleteBulk, updateOne } = useCannedNotesStore();
  const { columns, setColumns } = useProductLinesHeaders();
  const viewportHeight = window.innerHeight - 260;

  const handleSortChange = (sort: SortModel | undefined) => {
    console.log(sort);
  };
  const handleFilterChange = (filter: any) => {
    console.log(filter);
  };

  const handleDeleteRows = (rows: CannedNoteModel[]) => {
    deleteBulk(rows.map((row) => row.id));
  };

  const handleEditRows = (rows: CannedNoteModel[]) => {
    rows.forEach((row) => updateOne(row));
  };

  const autocompleteFieldSearch = async (
    field: string,
    search: string,
    dataType: string | number | string[],
    dataIndex?: string,
  ) => {
    const lowercased = search.toLowerCase();
    const filtered = dataSource
      .filter((row: CannedNoteModel) => row.note?.toLowerCase()?.includes(lowercased))
      .splice(0, 200);
    return {
      results: filtered,
      filter: filtered.map((row) => ({
        value: row[dataIndex || field],
        label: row[dataIndex || field],
      })),
    };
  };

  return {
    viewportHeight,
    columns,
    dataSource,
    loadPage,
    setColumns,
    handleSortChange,
    handleFilterChange,
    handleDeleteRows,
    handleEditRows,
    autocompleteFieldSearch,
  };
};

export default useProductLinesTable;
