import { useState } from 'react';
import { CallPatternFilterInput } from 'src/graphql/schema-types';

interface DataItem {
  id: number;
  name: string;
  isDeletable: boolean;
}

interface SelectedRecord {
  id: number;
  company: {
    id: number;
    name: string;
  };
  data: Array<DataItem>;
  dropdownOptions: any[];
}

interface CompanyModel {
  id: number;
  name: string;
}

interface StageGroupModel {
  id: number;
  name: string;
}

interface StatusModel {
  id: number;
  name: string;
}

interface CloseStatusModel {
  id: number;
  name: string;
}

interface StageOutputDto {
  id: number;
  name: string;
  stageGroup: StageGroupModel;
  status: StatusModel;
  closeStatus: CloseStatusModel;
  potential: number;
  boolean: boolean;
}

interface SelectedRecordStage {
  id: number;
  company: CompanyModel;
  data: StageOutputDto[];
}

interface SortData {
  sort: 'ASC' | 'DESC' | undefined;
  sortBy: string;
}

const useLocalSearchAndSort = () => {
  const [isOpen, setOpen] = useState<boolean | undefined>(undefined);

  const localSearchProvider = async (filters: CallPatternFilterInput[], data: DataItem[]) => {
    const filteredData = data.filter((dataItem) =>
      filters.every((filter) => {
        if (filter.operator === 'CONTAINS') {
          // const fieldName = filter.fieldName === 'name' ? 'opportunity_stages' : 'name';

          const itemValue = dataItem[filter.fieldName as keyof DataItem];

          return (
            typeof itemValue === 'string' &&
            itemValue.toLowerCase().includes(filter.value.toLowerCase())
          );
        }
        return false;
      }),
    );

    return {
      results: filteredData,
      total: filteredData.length,
    };
  };

  const localSortProvider = (
    dataSort: SelectedRecord,
    sortData?: { sort: 'ASC' | 'DESC'; sortBy: keyof DataItem },
  ) => {
    if (dataSort && dataSort.data) {
      const sortDirection = sortData?.sort === 'DESC' ? -1 : 1;
      let sortBy: keyof DataItem = sortData?.sortBy || 'name';

      if (!sortData?.sortBy || !(sortBy in dataSort.data[0])) {
        sortBy = 'name'; // Default to 'id' or another guaranteed valid key
      }

      const sortedData = [...dataSort.data].sort((a, b) => {
        const aValue = a[sortBy];

        const bValue = b[sortBy];

        if (aValue < bValue) return -1 * sortDirection;
        if (aValue > bValue) return 1 * sortDirection;
        return 0;
      });

      return { ...dataSort, data: sortedData };
    }

    return dataSort;
  };

  const localSortStageProvider = (
    dataSort: SelectedRecordStage,
    sortData?: SortData,
  ): SelectedRecordStage => {
    if (dataSort.data.length > 0) {
      const sortDirection = sortData?.sort === 'DESC' ? -1 : 1;
      const sortBy = sortData?.sortBy || 'name'; // Default to 'name' if sortBy is undefined

      const sortedData = dataSort.data.sort((a, b) => {
        // Function to safely navigate through nested properties
        const getValue = (obj: any, path: string): any => {
          switch (path) {
            case 'status':
              return obj.status?.name;
            case 'stageGroup':
              return obj.stageGroup?.name;
            case 'closeStatus':
              return obj.closeStatus?.name;
            case 'potential':
              return obj.potential;
            default:
              return obj[path] || obj.name;
          }
        };
        const aValue = getValue(a, sortBy);
        const bValue = getValue(b, sortBy);

        if (aValue < bValue) return -1 * sortDirection;
        if (aValue > bValue) return 1 * sortDirection;
        return 0;
      });

      return { ...dataSort, data: sortedData };
    }

    return dataSort;
  };

  const localSearchStageProvider = async (filters: any[], data: StageOutputDto[]) => {
    const fieldMapping: { [key: string]: string } = {
      opportunity_stages: 'name',
      opportunity_status: 'status.name',
      opportunity_stage_groups: 'stageGroup.name',
      potential: 'potential',
      opportunity_close_status: 'closeStatus.name',
    };

    const filteredData = data.filter((dataItem) =>
      filters.every((filter) => {
        const mappedField = fieldMapping[filter.fieldName];

        const itemValue: any = mappedField
          .split('.')
          .reduce((acc: any, key: string) => acc?.[key], dataItem);

        if (typeof itemValue === 'string') {
          const filterValue = filter.value.toLowerCase();
          return itemValue.toLowerCase().includes(filterValue);
        }
        if (typeof itemValue === 'number' && filter.dataType === 'number') {
          return itemValue.toString().includes(filter.value);
        }

        return false;
      }),
    );

    return {
      results: filteredData,
      total: filteredData.length,
    };
  };

  return {
    isOpen,
    setOpen,
    localSearchProvider,
    localSortProvider,
    localSortStageProvider,
    localSearchStageProvider,
  };
};

export default useLocalSearchAndSort;
