import { gql } from '@apollo/client';

export const GET_ALL_OPP_FAILED_REASON = gql`
  query GetAllOpportunityFailedReasons(
    $criteria: FailedReasonCriteriaInput!
    $searchFields: FindFailedReasonInput
  ) {
    GetAllOpportunityFailedReasons(Criteria: $criteria, SearchFields: $searchFields) {
      total
      results {
        id
        company {
          id
          name
        }
        data {
          id
          name
          boolean
        }
      }
    }
  }
`;
