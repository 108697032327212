import { useEffect, useState } from 'react';
import { ColorPicker, DatePicker, Checkbox, Input } from 'antd';
// eslint-disable-next-line no-duplicate-imports
import type { SelectProps } from 'antd';
import dayjs from 'dayjs';
import localDate from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import InnerCustomSelect, { InterfaceStatus } from '../innerCustomSelect';

dayjs.extend(weekday);
dayjs.extend(localDate);

interface InnerEditableCellRendereProps {
  type: string;
  options?: SelectProps['options'];
  defaultValue?: string | string[];
  handleEditData?: (event: any, inputType?: string) => void;
  handleCreateData?: (event: any, inputType?: string) => void;
  status?: InterfaceStatus;
  addNewRecord?: boolean;
  handleStatus?: (data: InterfaceStatus) => void;
  handleDataStatus?: () => void;
}

const InnerEditableCellRenderer: React.FC<InnerEditableCellRendereProps> = ({
  options,
  defaultValue,
  handleEditData,
  handleCreateData,
  status,
  addNewRecord,
  type,
  handleStatus,
  handleDataStatus,
}) => {
  const [value, setValue] = useState<any>(defaultValue);

  const handleValue = (data: any) => {
    setValue(data);
  };

  useEffect(() => {
    if (handleStatus) {
      if (value === '' || !value || value.length === 0) {
        handleStatus('error');
      } else {
        handleStatus('');
      }
    }
  }, [value]);
  switch (type) {
    case 'dropdown':
      return (
        <InnerCustomSelect
          options={options}
          defaultValue={defaultValue}
          handleEditData={handleEditData}
          handleCreateData={handleCreateData}
          status={status ?? undefined}
          externalValue={value}
          handleExternalValue={handleValue}
          defaultOpen={!addNewRecord}
          addNewRecord={addNewRecord}
          handleDataStatus={handleDataStatus}
        />
      );
    case 'multiselect':
      return (
        <InnerCustomSelect
          multiSel
          options={options}
          defaultValue={defaultValue as string[]}
          handleEditData={handleEditData}
          handleCreateData={handleCreateData}
          status={status ?? undefined}
          externalValue={value}
          handleExternalValue={handleValue}
          defaultOpen={!addNewRecord}
          addNewRecord={addNewRecord}
          handleDataStatus={handleDataStatus}
        />
      );
    case 'number':
      return (
        <Input
          style={{ borderRadius: 0 }}
          defaultValue={defaultValue}
          autoFocus={!addNewRecord}
          status={addNewRecord ? status : undefined}
          type='number'
          onChange={(event) => {
            handleValue(event.target.value);
            if (handleCreateData) {
              handleCreateData(event, type);
            }
          }}
          onBlur={(event) => handleEditData && status !== 'error' && handleEditData(event, type)}
          onPressEnter={(event) => {
            if (addNewRecord && handleDataStatus && status !== 'error') {
              handleDataStatus();
            }
            if (handleEditData && status !== 'error') {
              handleEditData(event, type);
            }
          }}
        />
      );
    case 'date':
      return (
        <DatePicker
          style={{ width: '100%', border: status === 'error' ? '1px solid red' : undefined }}
          status={addNewRecord ? status : undefined}
          defaultOpen={!addNewRecord}
          defaultValue={defaultValue ? dayjs(defaultValue as string) : undefined}
          onChange={(event) => {
            handleValue(event);
            if (handleCreateData) {
              handleCreateData(event, type);
            }
          }}
          onBlur={(event) => handleEditData && status !== 'error' && handleEditData(event, type)}
          onKeyDown={(event) => {
            if (
              event.nativeEvent.key === 'Enter' &&
              addNewRecord &&
              handleDataStatus &&
              status !== 'error'
            ) {
              handleDataStatus();
            }
            if (event.nativeEvent.key === 'Enter' && handleEditData && status !== 'error') {
              handleEditData(event, type);
            }
          }}
        />
      );
    case 'checkbox':
      return (
        <Checkbox
          defaultChecked={defaultValue as unknown as boolean}
          onChange={(event) =>
            (handleCreateData && handleCreateData(event, type)) ??
            (handleEditData && handleEditData(event, type))
          }
        />
      );
    case 'color':
      return (
        <ColorPicker
          defaultValue={defaultValue as string}
          trigger='hover'
          onChange={(event) => {
            handleValue(event);
            if (handleCreateData) {
              handleCreateData(event, type);
            }
          }}
          onChangeComplete={(event) =>
            handleEditData && status !== 'error' && handleEditData(event, type)
          }
        />
      );
    default:
      return (
        <Input
          style={{ borderRadius: 0 }}
          // temp solution
          autoFocus
          defaultValue={defaultValue}
          status={status ?? undefined}
          onChange={(event) => {
            handleValue(event.target.value);
            if (handleCreateData) {
              handleCreateData(event, type);
            }
          }}
          onBlur={(event) => handleEditData && status !== 'error' && handleEditData(event, type)}
          onPressEnter={(event) => {
            if (addNewRecord && handleDataStatus && status !== 'error') {
              handleDataStatus();
            }
            if (handleEditData && status !== 'error') {
              handleEditData(event, type);
            }
          }}
        />
      );
  }
};

export default InnerEditableCellRenderer;
