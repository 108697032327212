import SubTable from 'src/components/commons/subTable';
import useCallTypeTable from './useCallTypeTable';

const TabCallType = () => {
  const {
    viewportHeight,
    dataSource,
    columns,
    setColumns,
    loadPage,
    handleFilterChange,
    handleSortChange,
    handleDeleteRows,
    handleEditRows,
    autocompleteFieldSearch,
    handleClearFilter,
  } = useCallTypeTable();

  return (
    <SubTable
      data={dataSource}
      columns={columns}
      setColumns={setColumns}
      onListRender={loadPage}
      columnFilterShown
      scroll={{ y: viewportHeight, x: 1100 }}
      clearFilter={handleClearFilter}
      actionCell={['delete']}
      headerActions={['filter', 'edit', 'delete']}
      id='activity-journals-table'
      showOptionFilterLeft={false}
      allowGlobalEditActive
      total
      onFilterChange={handleFilterChange}
      onSortChange={handleSortChange}
      onEditClick={handleEditRows}
      onDeleteClick={handleDeleteRows}
      autocompleteFieldProvider={autocompleteFieldSearch}
    />
  );
};

export default TabCallType;
