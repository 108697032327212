import { Form } from 'antd';
import { useEffect, useMemo } from 'react';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import { UpdateCompanyInput } from 'src/graphql/schema-types';
import useProductPotentialsStore from 'src/hooks/useProductPotentialsStore';
import { useCompanyContext } from 'src/context/company.context';

const CompanyProductPotentialsSection = () => {
  const form = Form.useFormInstance();
  const { activeExpandedEdition } = useCompanyContext();
  const { entities, getAll } = useProductPotentialsStore();
  const options = useMemo(
    () => entities.map((entity) => ({ label: entity.name!, value: entity.id })),
    [entities],
  );

  useEffect(() => {
    getAll({ Criteria: { pagination: { from: 0, size: 100 } } });
  }, []);

  const fields: FieldType<UpdateCompanyInput>[] = [
    {
      name: 'productPotentials',
      readOnly: !activeExpandedEdition,
      type: 'multiSelect',

      options,
      span: 24,
    },
  ];

  return <GenericForm fields={fields} form={form} name='formProductPotentials' />;
};

export default CompanyProductPotentialsSection;
