import { SubTableColumn } from 'src/components/commons/subTable/types';

const COLUMNS: SubTableColumn[] = [
  {
    key: '',
    title: 'NAME',
    dataIndex: 'name',
    editable: true,
    minWidth: 270,
    dataType: 'string',
    unique: true,
    required: true,
    hidden: false,
    dataStructure: 'string',
    headerName: 'NAME',
    options: [],
    orderField: 'name',
    searchField: 'name',
  },
  {
    key: '',
    title: 'DESCRIPTION',
    dataIndex: 'description',
    editable: true,
    minWidth: 270,
    dataType: 'string',
    unique: false,
    required: false,
    hidden: false,
    dataStructure: 'string',
    headerName: 'DESCRIPTION',
    options: [],
    orderField: 'description',
    searchField: 'description',
  },
];

export default COLUMNS;
