import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import _ from 'lodash';
import { useParams } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';

import GET_COMPANY from 'src/components/companyDetails/queries';

import {
  CompanyModel,
  Maybe,
  Query,
  UpdateCompanyInput,
  Mutation,
  MutationUpdateCompanyArgs,
} from 'src/graphql/schema-types';
import { Form, FormInstance, notification } from 'antd';

import { CustomFieldPayload } from 'src/types/companiesTypes';
import useUnSaveChangesWarning from 'src/hooks/useUnSaveChangesWarning';
import { useDeepLocalStorage } from 'src/hooks/useLocalStorage';
import { ViewNames } from 'src/types/viewTypes';
import { objectAreEquals } from 'src/utils/functions/object';
import useCustomFieldCard from 'src/components/companyDetails/components/customFields/hooks/useCustomFieldCard';
import { IntegratedCustomField } from 'src/components/companyDetails/components/customFields/types/customFieldsTypes';
import detectJSON from 'src/utils/functions/detectJSON';
import dayjs from 'dayjs';
import localDate from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import prepareDataForm from 'src/modules/companies/utils';
import useDebouncedCallback from 'src/hooks/useDebouncedCallback';
import {
  CREATE_ADDITIONAL_FIELDS,
  EDIT_ADDITIONAL_FIELDS,
} from 'src/components/companyDetails/components/customFields/graphql/mutations';
import { UPDATE_COMPANY } from 'src/components/company/mutations';
import { detectDiferences } from 'src/components/companyDetails/utils/detectChanges';
import {
  filterCompanyEmailPhoneAddressInfo,
  mainAndAditionalElement,
  prepareDataToDelete,
} from 'src/components/companyDetails/utils/filterCompanyEmailPhoneAddress';

dayjs.extend(weekday);
dayjs.extend(localDate);

interface CompanyContextProps {
  company: Maybe<CompanyModel>;
  submittable: boolean;
  setSubmittable: (submittable: boolean) => void;
  form: FormInstance;
  customFieldPayload: CustomFieldPayload[];
  handleCustomFieldPayload: (innerData: CustomFieldPayload[]) => void;
  loading: boolean;
  showSpinCustomFields: boolean;
  integratedCustomField: IntegratedCustomField[];
  customFieldsValues: Maybe<Record<string, string>>;
  handleActiveExpandedEdition: () => void;
  activeExpandedEdition: boolean;
  handleSave: () => void;
  processedSave: boolean;
  saveContactAddressToDelete: (addressId: [number]) => void;
  saveCompanyPhonesToDelete: (phoneId: [number]) => void;
  saveCompanyEmailsToDelete: (emailId: [number]) => void;
  parentName: string;
  setParentName: (v: string) => void;
}
const initialState: CompanyContextProps = null!;
const CompanyContext = createContext(initialState);

const CompanyContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const [company, setCompany] = useState<Maybe<CompanyModel>>(null);
  const [submittable, setSubmittable] = useState(false);
  const [customFieldPayload, setCustomFieldPayload] = useState<CustomFieldPayload[]>([]);
  const [showSpinCustomFields, setShowSpinCustomFields] = useState(true);
  const [integratedCustomField, setIntegratedCustomField] = useState<IntegratedCustomField[]>([]);
  const [customFieldsValues, setCustomFieldsValues] = useState<Maybe<Record<string, string>>>(null);
  const [activeExpandedEdition, setActiveExpandedEdition] = useState(false);
  const [processedSave, setProcessedSave] = useState(false);
  const [parentName, setParentName] = useState('');
  const initialDataForm = JSON.parse(localStorage.getItem(ViewNames.detailCompany) ?? '{}');
  const {
    getAllCustomFieldResponse,
    fetchCustomFields,
    fetchCompanyCustomFields,
    getAllCompanyCustomFieldResponse,
  } = useCustomFieldCard();
  const [form] = Form.useForm<UpdateCompanyInput>();
  const values = Form.useWatch([], form);
  const companyAddressesToDelete = useRef<number[]>([]);
  const companyPhonesToDelete = useRef<number[]>([]);
  const companyEmailsToDelete = useRef<number[]>([]);
  const initialValuesRef = useRef<any>();
  const [updateCompany] = useMutation<Mutation['UpdateCompany'], MutationUpdateCompanyArgs>(
    UPDATE_COMPANY,
    {
      onCompleted: () => {
        notification.success({
          message: 'Company Updated',
          key: 'update-company-success',
        });
      },
      onError: (error) => {
        notification.success({
          message: error.message,
          key: 'update-company-error',
        });
      },
    },
  );

  const [createCustomFieldRelation] = useMutation(CREATE_ADDITIONAL_FIELDS, {
    context: { clientName: 'elastic' },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      notification.success({
        message: 'Custom Field Created',
        key: 'create-custom-field-relation-success',
      });
    },
    onError: (error) => {
      notification.error({
        message: error.message,
        key: 'create-custom-field-relation-error',
      });
    },
  });

  const [updateCustomFieldRelation] = useMutation(EDIT_ADDITIONAL_FIELDS, {
    context: { clientName: 'elastic' },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      notification.success({
        message: 'Custom Field Updated',
        key: 'update-custom-field-relation-success',
      });
    },
    onError: (error) => {
      notification.error({
        message: error.message,
        key: 'update-custom-field-relation-error',
      });
    },
  });

  const handleCustomFieldPayload = (innerData: CustomFieldPayload[]) => {
    setCustomFieldPayload(innerData);
  };

  const { data: dataChangedByUser, deleteData: deleteDataChangedByUser } = useDeepLocalStorage(
    ViewNames.detailCompanyChanged,
    {},
    'localStorage',
  );

  const activeSubmittable = () => {
    form
      .validateFields()
      .then(() => {
        setSubmittable(true);
      })
      .catch(() => {
        setSubmittable(false);
      });
  };

  const active = useDebouncedCallback(activeSubmittable, 300);

  const { setShowExitPrompt } = useUnSaveChangesWarning();
  const activeShowPrompt = () => {
    const formValues = form.getFieldsValue();
    if (
      initialDataForm &&
      formValues.name &&
      !objectAreEquals({ ...initialDataForm }, { ...formValues }) &&
      activeExpandedEdition
    ) {
      localStorage.setItem(ViewNames.detailCompanyChanged, JSON.stringify(formValues) ?? '{}');
      localStorage.setItem('detail-company-id', id as string);
      active();
      setShowExitPrompt(true);
    } else {
      setShowExitPrompt(false);
    }
  };

  const handleActiveExpandedEdition = () => {
    setActiveExpandedEdition(!activeExpandedEdition);
  };

  const loadNewCompanyOrDeleteOlder = (
    companyLoaded: any,
    customFields: Record<string, string>,
  ) => {
    const savedCompanyId = localStorage.getItem('detail-company-id');
    if (!dataChangedByUser && !!customFields) {
      form.setFieldsValue(companyLoaded);
    } else if (!!savedCompanyId && Number(savedCompanyId) !== Number(id)) {
      localStorage.removeItem('detail-company-id');
      deleteDataChangedByUser();
      form.setFieldsValue(companyLoaded);
    } else if (dataChangedByUser) {
      form.setFieldsValue(dataChangedByUser);
    }
  };

  const { data, loading: loadingCompany } = useQuery<Pick<Query, 'GetCompany'>>(GET_COMPANY, {
    variables: {
      id: Number(id),
    },
  });

  useEffect(() => {
    fetchCustomFields(0, 100);
  }, []);

  useEffect(() => {
    if (data?.GetCompany) {
      setCompany(data.GetCompany);
      setParentName(data.GetCompany.parent?.name ?? '');
      fetchCompanyCustomFields(data?.GetCompany.id);
    }
  }, [data]);

  useEffect(() => {
    if (getAllCustomFieldResponse.data && getAllCompanyCustomFieldResponse.data) {
      const innerCustomFieldElements =
        getAllCustomFieldResponse.data?.GetAllAdditionalFields.results.filter(
          (element) => element.module?.toLowerCase() === 'company',
        );
      const dedicatedCustomFieldData =
        getAllCompanyCustomFieldResponse.data?.GetAllCompanyAdditionalField;
      const innerIntegratedCustomField: IntegratedCustomField[] = [];

      innerCustomFieldElements.forEach((element) => {
        let innerValue;
        let innerRelationId;
        const innerCustomFieldElementData = dedicatedCustomFieldData.find(
          (item) => item.additionalField.id === element.id,
        );
        if (innerCustomFieldElementData) {
          innerValue = innerCustomFieldElementData.value || undefined;
          innerRelationId = innerCustomFieldElementData.id;
        } else if (element.dataStructure?.selected) {
          innerValue = element.dataStructure?.selected;
        } else {
          innerValue = undefined;
        }
        const innerElement: IntegratedCustomField = {
          options: element.dataStructure?.options ?? undefined,
          id: JSON.stringify(element.id),
          label: element.name,
          dataType: element.dataType,
          isRequired: element.dataStructure?.required,
          value: innerValue,
          relationId: innerRelationId ?? undefined,
        };
        innerIntegratedCustomField.push(innerElement);
      });

      setIntegratedCustomField(innerIntegratedCustomField);

      setShowSpinCustomFields(false);
    }
  }, [getAllCustomFieldResponse.data, getAllCompanyCustomFieldResponse.data]);

  useEffect(() => {
    if (integratedCustomField.length) {
      let newFieldsValue = {};
      integratedCustomField.forEach((element) => {
        let value;
        if (Array.isArray(element.value)) {
          // eslint-disable-next-line prefer-destructuring
          value = element.value[0];
        } else {
          value = element.value;
        }
        if (value) {
          if (element.dataType === 'date') {
            newFieldsValue = {
              ...newFieldsValue,
              [element.label]: dayjs(value as string),
            };
          } else if (element.dataType === 'date_time') {
            newFieldsValue = {
              ...newFieldsValue,
              [element.label]: dayjs(value as string),
            };
          } else if (element.dataType === 'multi_select_dropdown') {
            newFieldsValue = {
              ...newFieldsValue,
              [element.label]: detectJSON(element.value as string),
            };
          } else if (element.dataType === 'integer' || element.dataType === 'decimal') {
            newFieldsValue = {
              ...newFieldsValue,
              [element.label]: detectJSON(element.value as string),
            };
          } else {
            newFieldsValue = {
              ...newFieldsValue,
              [element.label]: value,
            };
          }
        }
      });

      setCustomFieldsValues(newFieldsValue);
    }
  }, [integratedCustomField]);

  useEffect(() => {
    // Directly handling the scenario where customFieldsValues might be null
    if (company) {
      const effectiveCustomFieldsValues = customFieldsValues || {};

      if (effectiveCustomFieldsValues) {
        const formValues = prepareDataForm(company, effectiveCustomFieldsValues);
        initialValuesRef.current = {
          ...formValues,
          addresses: mainAndAditionalElement('addresses', company),
          emails: mainAndAditionalElement('emails', company),
          phones: mainAndAditionalElement('phones', company),
        };

        loadNewCompanyOrDeleteOlder(initialValuesRef.current, effectiveCustomFieldsValues);
        localStorage.setItem(ViewNames.detailCompany, JSON.stringify(initialValuesRef.current));
      }
    }
  }, [company, customFieldsValues]);

  // Helper function to get default custom fields values based on available data

  useEffect(() => {
    activeSubmittable();
    activeShowPrompt();
  }, [values]);

  const transformKeys = (obj: any) =>
    _.transform(obj, (result: any, value, key: string) => {
      const newKey = key.replace(/\./g, '.');
      _.set(result, newKey, value);
    });

  const filterFieldsToUpdate = (companyData: CompanyModel) => {
    const filteredValues = {
      website: _.get(companyData, 'website'),
      visitFrequency: _.get(companyData, 'visitFrequency'),
      tenantDivision: _.get(companyData, 'tenantDivision'),
      tags: _.get(companyData, 'tags'),
      shortName: _.get(companyData, 'shortName'),
      shipDetail: _.get(companyData, 'shipDetail'),
      salesTeam: _.get(companyData, 'salesTeam'),
      refNumber: _.get(companyData, 'refNumber'),
      rankId: _.get(companyData, 'rankId'),
      quoteFooter: _.get(companyData, 'quoteFooter'),
      productPotentials: _.get(companyData, 'productPotentials'),
      privateTeamId: _.get(companyData, 'privateTeamId'),
      printName: _.get(companyData, 'printName'),
      poFooter: _.get(companyData, 'poFooter'),
      phones: _.get(companyData, 'phones'),
      parent: _.get(companyData, 'parent'),
      name: _.get(companyData, 'name'),
      mainContact: _.get(companyData, 'mainContact'),
      isActive: _.get(companyData, 'isActive'),
      industriesId: _.get(companyData, 'industriesId'),
      impBatch: _.get(companyData, 'impBatch'),
      id: _.get(companyData, 'id'),
      hasSecondaryAlias: _.get(companyData, 'hasSecondaryAlias'),
      hasSamp: _.get(companyData, 'hasSamp'),
      hasRegi: _.get(companyData, 'hasRegi'),
      hasQuot: _.get(companyData, 'hasQuot'),
      hasPosAccount: _.get(companyData, 'hasPosAccount'),
      hasPartNumberSplit: _.get(companyData, 'hasPartNumberSplit'),
      hasHolydayCard: _.get(companyData, 'hasHolydayCard'),
      hasForecast: _.get(companyData, 'hasForecast'),
      hasDwin: _.get(companyData, 'hasDwin'),
      emails: _.get(companyData, 'emails'),
      divisionsId: _.get(companyData, 'divisionsId'),
      datasource: _.get(companyData, 'datasource'),
      companyTypeId: _.get(companyData, 'companyTypeId'),
      companyClassId: _.get(companyData, 'companyClassId'),
      comments: _.get(companyData, 'comments'),
      categoryId: _.get(companyData, 'categoryId'),
      callPatternId: _.get(companyData, 'callPatternId'),
      addresses: _.get(companyData, 'addresses'),
    };

    return filteredValues;
  };

  const saveContactAddressToDelete = (addressId: [number]) => {
    companyAddressesToDelete.current = prepareDataToDelete(
      addressId,
      companyAddressesToDelete.current,
    );
  };

  const saveCompanyPhonesToDelete = (phoneId: [number]) => {
    companyPhonesToDelete.current = prepareDataToDelete(phoneId, companyPhonesToDelete.current);
  };

  const saveCompanyEmailsToDelete = (emailId: [number]) => {
    companyEmailsToDelete.current = prepareDataToDelete(emailId, companyEmailsToDelete.current);
  };

  const handleEditCustomFields = () => {
    customFieldPayload.forEach((element) => {
      const { value, relationId } = element;
      if (element.relationId) {
        updateCustomFieldRelation({
          variables: {
            updateCompanyAdditionalFieldInput: {
              companyId: company?.id,
              additionalFieldsId: Number(element.id),
              value,
              id: Number(relationId),
            },
          },
        });
      } else {
        createCustomFieldRelation({
          variables: {
            createCompanyAdditionalFieldInput: {
              companyId: company?.id,
              additionalFieldsId: Number(element.id),
              value,
            },
          },
        });
      }
    });
  };

  const handleSave = () => {
    setProcessedSave(false);
    handleActiveExpandedEdition();
    const innerInitialValues = transformKeys(initialDataForm);
    const companyData = transformKeys(values);
    const filteredInitialValues = filterFieldsToUpdate(innerInitialValues);
    const filteredUpdatedValues = filterFieldsToUpdate(companyData);

    const newPayload = detectDiferences(filteredInitialValues, filteredUpdatedValues);

    const filteredPayload = _.pickBy(
      filterCompanyEmailPhoneAddressInfo(newPayload),
      (value) => !_.isEmpty(value) || _.isNumber(value),
    );

    if (filteredPayload) {
      if (companyAddressesToDelete.current.length) {
        filteredPayload.deleteAddresses = companyAddressesToDelete.current;
      }

      if (companyEmailsToDelete.current.length) {
        filteredPayload.deleteEmails = companyEmailsToDelete.current;
      }

      if (companyPhonesToDelete.current.length) {
        filteredPayload.deletePhones = companyPhonesToDelete.current;
      }
    }

    updateCompany({
      variables: {
        updateCompanyInput: {
          ...filteredPayload,
          id: Number(id),
        },
      },
    }).then((updatedData: any) => {
      setProcessedSave(true);
      if (updatedData?.data?.UpdateCompany) {
        setCompany(updatedData.data.UpdateCompany);
      }
    });
    handleEditCustomFields();
  };

  const loading = loadingCompany || showSpinCustomFields;

  const value = useMemo(
    () => ({
      company,
      submittable,
      form,
      customFieldPayload,
      handleCustomFieldPayload,
      loading,
      showSpinCustomFields,
      integratedCustomField,
      setSubmittable,
      customFieldsValues,
      handleActiveExpandedEdition,
      activeExpandedEdition,
      handleSave,
      processedSave,
      saveContactAddressToDelete,
      saveCompanyPhonesToDelete,
      saveCompanyEmailsToDelete,
      parentName,
      setParentName,
    }),
    [
      company,
      form,
      submittable,
      customFieldPayload,
      handleCustomFieldPayload,
      loading,
      showSpinCustomFields,
      integratedCustomField,
      setSubmittable,
      customFieldsValues,
      handleActiveExpandedEdition,
      activeExpandedEdition,
      handleSave,
      processedSave,
      saveContactAddressToDelete,
      saveCompanyPhonesToDelete,
      saveCompanyEmailsToDelete,
      parentName,
      setParentName,
    ],
  );

  return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
};

export const useCompanyContext = () => useContext(CompanyContext);

export default CompanyContextProvider;
