import { QueryHookOptions, useQuery } from '@apollo/client';

import { GET_OPP_STAGE_HEADERS } from '../graphql/queries';

const useStageHeaders = (options?: Partial<QueryHookOptions>) => {
  const { data } = useQuery(GET_OPP_STAGE_HEADERS, options);

  return data?.GetOpportunityStageGridHeaders || [];
};

export default useStageHeaders;
