import { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import FormSection from 'src/components/formSection';
import useProductInterestStore from 'src/hooks/stores/useProductInterest';
import { useContactContext } from 'src/context/contact.context';
import { useContactsContext } from 'src/modules/contacts/contexts/contacts.contexts';
import CustomSelect from 'src/components/commons/customSelect';

type Props = { showTitle?: boolean; requiredFields?: string[] };

interface AutocompleteComponentProps {
  options: { value: number; label: string }[];
  onChange?: (e: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
  selectedOptions: any[] | undefined;
}

const AutocompleteComponent: FC<AutocompleteComponentProps> = ({
  options,
  onChange,
  readOnly,
  disabled,
  selectedOptions,
}) => (
  <CustomSelect
    multiSel
    handleMultipleSelect={(_, opt) => onChange?.((opt as any) || (_ as string[]))}
    listDefault={options}
    disabled={disabled || readOnly}
    externalWidth='100%'
    customClassName={readOnly ? 'readOnly-ant-multisel' : ''}
    selectedColumns={selectedOptions}
  />
);

const ContactFormProductInterest: FC<Props> = ({ showTitle = true, requiredFields }) => {
  const createForm = Form.useFormInstance();
  const { dataSource } = useProductInterestStore();
  const [opt, setOpt] = useState<{ value: number; label: string }[] | []>([]);
  const { activeExpandedEdition, form, contact } = useContactContext();
  const { createContactModalActive } = useContactsContext();
  const formName = 'productInterest';

  const handleProductInterestValues = (options: any) => {
    const values = options.map((option: string) => {
      const selectedValue = option.substring(option.indexOf('{') + 3, option.lastIndexOf('%'));
      const isString = Number.isNaN(selectedValue);
      return isString ? selectedValue : Number(selectedValue);
    });

    if (form) {
      form.setFieldValue(formName, values);
    } else {
      createForm.setFieldValue(formName, values);
    }
  };

  useEffect(() => {
    const data = dataSource.map((dataopt) => ({
      value: dataopt.id,
      label: dataopt.name,
      toSend: dataopt.id,
    }));
    setOpt(data);
  }, [dataSource]);
  const fields: FieldType[] = [
    {
      name: formName,
      type: 'multiselect',
      options: opt,
      render: () => (
        <AutocompleteComponent
          options={opt}
          readOnly={!activeExpandedEdition && !createContactModalActive}
          onChange={(options) => handleProductInterestValues(options)}
          selectedOptions={contact?.products}
        />
      ),
      span: 24,
      rules: [
        {
          required: requiredFields?.includes('productInterest'),
          message: 'Product Interest is required',
        },
      ],
      readOnly: !activeExpandedEdition && !createContactModalActive,
    },
  ];

  if (!showTitle) {
    return <GenericForm fields={fields} />;
  }

  return (
    <FormSection title='Product Interest'>
      <GenericForm fields={fields} />
    </FormSection>
  );
};

export default ContactFormProductInterest;
