import { NavLink, useParams } from 'react-router-dom';
import SCompanySetupNav from '../../companyDetails/styles/companySetupNav.style';

const TABS = [{ label: 'Basic', to: 'basic' }];

const ContactSetupNav = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <SCompanySetupNav>
      {TABS.map((link) => (
        <NavLink key={link.to} to={`/contacts/${id}/${link.to}`}>
          {link.label}
        </NavLink>
      ))}
    </SCompanySetupNav>
  );
};
export default ContactSetupNav;
