import { FC, useEffect, useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { LocationDistance } from './types';

interface Props {
  distance: number;
  coordinates: {
    lat: number;
    long: number;
  };
  nearbyCompanies?: LocationDistance[];
}
declare let google: any;
const MapComponent: FC<Props> = ({ distance, coordinates, nearbyCompanies }) => {
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);

  const mapRef = useRef(null);

  useEffect(() => {
    if (typeof google !== 'undefined') return;
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    setLatitude(coordinates.lat);
    setLongitude(coordinates.long);
  }, [coordinates]);

  useEffect(() => {
    if (typeof google === 'undefined') return;
    const distanceInMeters = distance * 1609;
    let zoom = 0;
    if (distanceInMeters >= 40000) {
      zoom = 9;
    } else if (distanceInMeters >= 20000) {
      zoom = 10;
    } else if (distanceInMeters >= 10000) {
      zoom = 11;
    } else if (distanceInMeters >= 5000) {
      zoom = 12;
    } else {
      zoom = 13;
    }
    if (latitude === null || longitude === null) return;
    const mapOptions = {
      center: { lat: latitude, lng: longitude },
      zoom,
    };
    const map = new google.maps.Map(mapRef.current, mapOptions);
    // eslint-disable-next-line no-new
    new google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
    });
    // eslint-disable-next-line no-new
    new google.maps.Circle({
      strokeColor: '#0078d4',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#0078d4',
      fillOpacity: 0.1,
      map,
      center: { lat: latitude, lng: longitude },
      radius: distanceInMeters,
    });
    nearbyCompanies?.forEach((location) => {
      const marker = new google.maps.Marker({
        position: { lat: location.location.lat, lng: location.location.long },
        map,
        title: location.name,
      });
      const infowindow = new google.maps.InfoWindow({
        content: location.name,
      });
      marker.addListener('mouseover', () => {
        infowindow.open(map, marker);
      });

      marker.addListener('mouseout', () => {
        infowindow.close();
      });
    });
  }, [latitude, longitude, distance, nearbyCompanies]);

  return typeof google === 'undefined' ? (
    <>
      <LoadingOutlined style={{ fontSize: 32 }} />
      <div ref={mapRef} style={{ height: '100%', width: '100%' }} />
    </>
  ) : (
    <div ref={mapRef} style={{ height: '100%', width: '100%' }} data-testid='map' />
  );
};

export default MapComponent;
