import { gql } from '@apollo/client';

export const GET_ALL_OPP_CLOSE_REASON = gql`
  query GetAllOpportunityCloseReasons(
    $criteria: CloseReasonCriteriaInput!
    $searchFields: FindCloseReasonInput
  ) {
    GetAllOpportunityCloseReasons(Criteria: $criteria, SearchFields: $searchFields) {
      total
      results {
        id
        company {
          id
          name
        }
        data {
          id
          name
          boolean
        }
      }
    }
  }
`;
