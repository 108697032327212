import { gql } from '@apollo/client';

const GET_ALL_COMPANY_CATEGORY = gql`
  query GetAllContactGroups2(
    $criteria: ContactGroupCriteriaInput!
    $searchFields: FindContactGroupInput
  ) {
    GetAllContactGroups2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
      }
      total
    }
  }
`;

export default GET_ALL_COMPANY_CATEGORY;
