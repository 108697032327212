import { gql } from '@apollo/client';

export const GET_ALL_PRODUCT_POTENTIALS = gql`
  query GetAllProductPotentials(
    $Criteria: ProductPotentialCriteriaInput!
    $SearchFields: FindProductPotentialInput
  ) {
    GetAllProductPotentials(Criteria: $Criteria, SearchFields: $SearchFields) {
      total
      results {
        id
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PRODUCT_POTENTIAL = gql`
  query GetProductPotential($id: Int!) {
    GetProductPotential(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
