import { FC } from 'react';
import { Space, Typography } from 'antd';
import { HolderOutlined } from '@ant-design/icons';
import { SubTableColumn } from '../types';
import SortButton from './sortButton';
import PinButton from './pinButton';
import { useSubTableContext } from '../contexts/subtable.context';

const Title: FC<{ column: SubTableColumn }> = ({ column }) => {
  const { sortable = true } = column;
  const { pinnableColumns, withDragHandler, handleOnPin, columns } = useSubTableContext();
  const pinnedColumnsCount = handleOnPin ? columns.filter((col) => col.pinned).length : 0;
  const title = typeof column.title === 'string' ? column.title.toUpperCase() : column.title;

  return (
    <Space
      direction='horizontal'
      className='column-title'
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{ display: 'flex', gap: 5 }}>
        {withDragHandler && (
          <div className='drag-holder' title=''>
            <HolderOutlined style={{ cursor: 'pointer', color: '#5D5D5D' }} />
          </div>
        )}
        <Typography.Text className='title-text' style={{ width: 'max-content', overflow: 'clip' }}>
          {title}
        </Typography.Text>
      </div>
      <div>
        {sortable && <SortButton column={column} />}
        {pinnableColumns && (
          <PinButton
            onPinClick={handleOnPin as unknown as (column: SubTableColumn | undefined) => void}
            column={column}
            disabled={pinnedColumnsCount >= 2 && !column.pinned}
          />
        )}
      </div>
    </Space>
  );
};

export default Title;
