import { Button } from 'antd';
import styled from 'styled-components';

const StyledSubHeaderAddButton = styled(Button)<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#EEEEEE' : '#0078d4')} !important;
  background-color: #0078d4;
  padding: 0;
  font-size: 24px;
  width: 30px !important;
  height: 30px !important;
  min-width: 30px !important;
  min-height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${(props) => (props.active ? '#EEEEEE' : '#0078d4')};
  }
  span {
    width: max-content;
    height: max-content;
    svg {
      width: 1rem;
      height: 1rem;
      fill: ${(props) => (props.active ? '#C6C4C4' : '#ffffff')};
    }
  }
`;

export default StyledSubHeaderAddButton;
