import React from 'react';
import { CustomModal } from 'src/components/commons/customModal';
import { PARENT_COMPANY_COLUMNS } from 'src/components/commons/createCompany/consts';
import useParentCompanyModal from 'src/components/commons/createCompany/hook/useParentCompanyModal';
import SubTable from 'src/components/commons/subTable';
import { Space } from 'antd';
import CustomButton from 'src/components/commons/customButton';

type Props = {
  handleOpenedMergeTo: () => void;
  handleMerge: () => void;
  show: boolean;
  companyName: string;
  handleCompanySelection: (value: any) => void;
  selectedCompany: any;
};

const SelectMergeToCompanyInto: React.FC<Props> = ({
  show,
  handleOpenedMergeTo,
  handleMerge,
  companyName,
  handleCompanySelection,
  selectedCompany,
}) => {
  const {
    dataSource,
    autocompleteFieldSearch,
    onListRender,
    handleFilterChange,
    handleSortModelChange,
  } = useParentCompanyModal();

  return (
    <CustomModal
      modalTitle={companyName}
      modalStatus={show}
      width={1300}
      modalBody={
        <SubTable
          data={dataSource}
          onListRender={onListRender}
          columns={PARENT_COMPANY_COLUMNS}
          autocompleteFieldProvider={autocompleteFieldSearch}
          onFilterChange={handleFilterChange}
          onSortChange={handleSortModelChange}
          scroll={{ y: 400, x: 1200 }}
          nonAddable
          columnFilterShown
          nonSelectable
          onRowClick={(record) => handleCompanySelection(record)}
          total
          showOptionFilterLeft={false}
          selectedRows={[selectedCompany]}
        />
      }
      onCancelAction={handleOpenedMergeTo}
      onOkAction={() => {}}
      footer={
        <Space>
          <CustomButton
            type='text'
            title='Cancel'
            onClick={handleOpenedMergeTo}
            style={{
              fontSize: '14px',
              height: '32px',
              padding: '4px 15px',
            }}
          />
          <CustomButton
            type='primary'
            title='Merge'
            disabled={!selectedCompany}
            onClick={handleMerge}
            style={{
              fontSize: '14px',
              height: '32px',
              padding: '4px 15px',
            }}
          />
        </Space>
      }
    />
  );
};

export default SelectMergeToCompanyInto;
