/* eslint-disable react/jsx-no-undef */
import React, { useState } from 'react';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Input } from 'antd';

interface CustomInputProps {
  customStyle?: object;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  label?: string;
  required?: boolean;
  id?: string;
  dataTestId?: string;
  name?: string;
  value?: any;
  invalid?: boolean;
  valid?: boolean;
  type?: string;
  suffix?: React.ReactNode;
}

const CustomInput: React.FC<CustomInputProps> = ({
  handleChange,
  customStyle,
  placeholder,
  label,
  required,
  id = `custom-input-${Math.random()}`,
  dataTestId,
  name,
  value,
  invalid,
  valid,
  type,
  suffix,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [dirty, setDirty] = useState(false);

  return (
    <div style={{ marginBottom: type === 'password' ? 32 : 12, ...customStyle }}>
      {label && (
        <label htmlFor={id} aria-labelledby={label}>
          {label} {required && <span style={{ color: 'red' }}>*</span>}
        </label>
      )}
      <Input
        id={id}
        type={showPassword ? 'text' : type}
        data-testid={dataTestId}
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={handleChange}
        required={required}
        formNoValidate
        status={dirty && invalid ? 'error' : undefined}
        onBlur={() => setDirty(true)}
        suffix={suffix}
        style={
          valid
            ? {
                border: '1px solid #52C41A',
                boxShadow: '0px 0px 0px 2px rgba(0, 174, 17, 0.2)',
              }
            : {}
        }
      />
      {type === 'password' && (
        <span
          role='button'
          style={{ cursor: 'pointer', display: 'inline-block', float: 'right' }}
          tabIndex={0}
          onClick={() => setShowPassword(!showPassword)}
          onKeyDown={(ev) => ev.key === 'Enter' && setShowPassword(!showPassword)}
        >
          {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        </span>
      )}
    </div>
  );
};

export default CustomInput;
