import { gql } from '@apollo/client';

export const CREATE_COMPANY_PITCH = gql`
  mutation CreateCompanyPitch($createCompanyPitchInput: CreateCompanyPitchInput!) {
    CreateCompanyPitch(createCompanyPitchInput: $createCompanyPitchInput) {
      id
      name
      text
    }
  }
`;

export const UPDATE_COMPANY_PITCH = gql`
  mutation UpdateCompanyPitch($updateCompanyPitchInput: UpdateCompanyPitchInput!) {
    UpdateCompanyPitch(updateCompanyPitchInput: $updateCompanyPitchInput) {
      id
      name
      text
    }
  }
`;

export const DELETE_COMPANY_PITCH = gql`
  mutation RemoveCompanyPitch($removeCompanyPitchId: Int!) {
    RemoveCompanyPitch(id: $removeCompanyPitchId)
  }
`;
