import React, { useCallback, useRef } from 'react';
import { Typography, Form, Alert, Row, Col, InputRef } from 'antd';
import AddressHandler from 'src/components/commons/addressHandler';
import CustomPhoneHandler from 'src/components/commons/phoneForm';
import CustomEmailHandler from 'src/components/commons/emailForm';
import { AddressModel, FormElementsOptions } from 'src/types/companiesTypes';
import { validateEmptyInput } from 'src/utils/functions/validateEmptyInput';
import { CustomStyledInput } from '../createCompany.styles';
import { CreateCompanyBodyRendererTypes } from '../types';
import isRequired from '../utils/functions';

const BasicInfoRenderer: React.FC<CreateCompanyBodyRendererTypes> = ({
  validatingStatus,
  formElementsOptions,
  handleInputChange,
  separateComponentDataHandler,
  expanded,
  externalCountry,
  showTextHeader = true,
  company,
}) => {
  const initialized = useRef(false);

  const autoFocus = useCallback((ref: InputRef) => {
    if (ref && !initialized.current) {
      setTimeout(() => {
        ref.focus();
        initialized.current = true;
      }, 10);
    }
  }, []);

  return (
    <div style={{ marginBottom: '49px' }}>
      {showTextHeader && (
        <Typography.Title level={4} className='section-title'>
          Basic Information{' '}
        </Typography.Title>
      )}
      <Form.Item
        label='Company Name'
        name='name'
        hasFeedback
        validateStatus={validatingStatus}
        required={isRequired(formElementsOptions, 'name')}
        rules={[
          {
            validator: validateEmptyInput,
          },
        ]}
      >
        <>
          <CustomStyledInput
            onChange={handleInputChange}
            name='name'
            autoComplete='off'
            data-testid='company-name-input'
            value={company?.name ?? undefined}
            ref={autoFocus}
          />
          {validatingStatus === 'error' && (
            <Alert
              message='Error'
              description='Company with the same name already exist. Please provide unique name.'
              type='error'
              style={{
                width: 'max-content',
                position: 'absolute',
                zIndex: 1,
                right: 0,
              }}
            />
          )}
        </>
      </Form.Item>
      <Form.Item
        label='Website'
        name='website'
        required={isRequired(formElementsOptions, 'website')}
      >
        <CustomStyledInput
          name='website'
          data-testid='company-website-input'
          value={company?.website ?? undefined}
          onChange={handleInputChange}
        />
        {validatingStatus === 'error'}
      </Form.Item>
      <AddressHandler
        requiredFields={formElementsOptions?.filter((option: FormElementsOptions) =>
          option.name.includes('mainAddress'),
        )}
        {...{ separateComponentDataHandler }}
        addresses={[company?.addresses as AddressModel]}
      />
      <Row justify='space-between'>
        <Col span={!expanded ? 24 : 12} style={{ paddingRight: !expanded ? 0 : '5px' }}>
          <CustomPhoneHandler
            required={isRequired(formElementsOptions, 'phones')}
            {...{ separateComponentDataHandler }}
            {...{ expanded }}
            country={externalCountry}
            phones={company?.phones}
          />
        </Col>
        <Col span={!expanded ? 24 : 12} style={{ paddingLeft: !expanded ? 0 : '5px' }}>
          <CustomEmailHandler
            required={isRequired(formElementsOptions, 'emails')}
            {...{ separateComponentDataHandler }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BasicInfoRenderer;
