import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { StateModel } from 'src/models';

const stateAdapter: AdapterFunc<GQL.StateDbModel, StateModel> = (input) => ({
  id: input.id,
  name: input.name!,
});

export default stateAdapter;
