import {
  LeftOutlined,
  RightOutlined,
  DoubleLeftOutlined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  DoubleRightOutlined,
} from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { useContext, useEffect } from 'react';
import { CompaniesContext } from 'src/context/companies.context';
import { TableContext } from 'src/context/table.context';
import { PaginationWrapper } from 'src/styles/customTableStyles';
import CustomButton from 'src/components/commons/customButton';
import CustomToolTip from '../customPopover';

interface PaginationProps {
  totalData: number;
  currentPage: number;
}

const CustomPagination = ({ totalData, currentPage }: PaginationProps) => {
  const { rowsDisplay2 } = useContext(TableContext);
  const { setPageHandler } = useContext(CompaniesContext);

  const [form] = Form.useForm();

  const onFinish = ({ page }: { page: string }) => {
    const enteredPage = Number(page);
    if (currentPage === 1 && (enteredPage === 0 || !enteredPage)) {
      if (!enteredPage) {
        setPageHandler(1, rowsDisplay2);
      }
      form.setFieldsValue({ page: 1 });
      return;
    }
    if (Number.isNaN(enteredPage) || enteredPage < 1) {
      form.setFieldsValue({ page: 1 });
      setPageHandler(1, rowsDisplay2);
      return;
    }
    const totalPages = Math.ceil(totalData / rowsDisplay2);
    if (enteredPage <= totalPages) {
      setPageHandler(enteredPage, rowsDisplay2);
      form.setFieldsValue({ page: enteredPage });
    } else {
      setPageHandler(totalPages, rowsDisplay2);
      form.setFieldsValue({ page: totalPages });
    }
  };

  useEffect(() => {
    form.setFieldsValue({ page: currentPage });
  }, [currentPage]);

  useEffect(() => {
    if (setPageHandler) {
      setPageHandler(1, rowsDisplay2);
    }
    if (Math.ceil(totalData / rowsDisplay2) < currentPage) {
      setPageHandler(1, rowsDisplay2);
    }
  }, [rowsDisplay2, totalData]);

  return (
    <>
      {' '}
      <PaginationWrapper>
        <CustomToolTip title='First Page' color='gray'>
          <CustomButton
            type='text'
            dataTestId='first-page-arrow'
            onlyIcon
            icon={<DoubleLeftOutlined />}
            disabled={currentPage === 1}
            style={currentPage === 1 ? { color: '#c6c4c4' } : { color: '#0078D4' }}
            onClick={() => {
              setPageHandler(1, rowsDisplay2);
            }}
          />
        </CustomToolTip>
        <CustomToolTip title='Previous Page' color='gray'>
          <CustomButton
            type='text'
            dataTestId='back-page-arrow'
            onlyIcon
            icon={<LeftOutlined />}
            disabled={currentPage === 1}
            style={currentPage === 1 ? { color: '#c6c4c4' } : { color: '#0078D4' }}
            onClick={() => {
              setPageHandler(currentPage - 1, rowsDisplay2);
            }}
          />
        </CustomToolTip>
        <p style={{ marginTop: 0 }}>page</p>

        <Form
          form={form}
          name='basic'
          onFinish={onFinish}
          autoComplete='off'
          initialValues={{ page: currentPage }}
          style={{
            display: 'flex',
            gap: '0.8vw',
            alignItems: 'center',
          }}
        >
          <Form.Item name='page' style={{ margin: '0', width: '3.8vw' }}>
            <Input data-testid='input-enter-page' style={{ border: '1px solid #0078D4' }} />
          </Form.Item>
          <p style={{ marginTop: 0 }}>of {Math.ceil(totalData / rowsDisplay2)}</p>
          <Form.Item style={{ margin: '0' }}>
            <Button type='primary' htmlType='submit' data-testid='button-entered-page'>
              Go!
            </Button>
          </Form.Item>
        </Form>
        <CustomToolTip title='Next Page' color='gray'>
          <CustomButton
            type='text'
            dataTestId='next-page-arrow'
            onlyIcon
            icon={<RightOutlined />}
            disabled={currentPage === Math.ceil(totalData / rowsDisplay2)}
            style={
              currentPage === Math.ceil(totalData / rowsDisplay2)
                ? { color: '#c6c4c4' }
                : { color: '#0078D4' }
            }
            onClick={() => {
              setPageHandler(currentPage + 1, rowsDisplay2);
            }}
          />
        </CustomToolTip>
        <CustomToolTip title='Last Page' color='gray'>
          <CustomButton
            type='text'
            dataTestId='last-page-arrow'
            onlyIcon
            icon={<DoubleRightOutlined />}
            disabled={currentPage === Math.ceil(totalData / rowsDisplay2)}
            style={
              currentPage === Math.ceil(totalData / rowsDisplay2)
                ? { color: '#c6c4c4' }
                : { color: '#0078D4' }
            }
            onClick={() => setPageHandler(Math.ceil(totalData / rowsDisplay2), rowsDisplay2)}
          />
        </CustomToolTip>
      </PaginationWrapper>
    </>
  );
};

export default CustomPagination;
