import { Button } from 'antd';
import styled from 'styled-components';

const StyledSortButton = styled(Button)`
  border: none !important;
  color: #858585;

  &.active {
    color: #ff4d4f !important;
  }
`;

export default StyledSortButton;
