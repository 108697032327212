import { gql } from '@apollo/client';

const GET_ALL_COMPANY_CATEGORY = gql`
  query GetAllCompanyCategory2(
    $criteria: CategoryCriteriaInput!
    $searchFields: FindCategoryInput
  ) {
    GetAllCompanyCategory2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
        description
      }
      total
    }
  }
`;

export default GET_ALL_COMPANY_CATEGORY;
