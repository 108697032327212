import { gql } from '@apollo/client';

export const GET_ALL_CONTACT_GROUP = gql`
  query GetAllContactGroups2($criteria: ContactGroupCriteriaInput!) {
    GetAllContactGroups2(Criteria: $criteria) {
      results {
        id
        name
      }
    }
  }
`;

export const GET_CONTACT_GROUP = gql`
  query GetContactGroup($getContactGroupId: Int!) {
    GetContactGroup(id: $getContactGroupId) {
      id
      name
    }
  }
`;
