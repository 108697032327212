import { useMutation } from '@apollo/client';
import { CREATE_NEW_COMPANY } from 'src/components/company/mutations';
import { Button, Space, notification } from 'antd';
import { useNavigate } from 'react-router';
import handleError from 'src/utils/functions/handleError';
import { CompanyPayloadInternalDataType, CreateCompanyPayloadType } from '../types';

const mapField = (field: CompanyPayloadInternalDataType) => {
  if (field.parser) {
    return field.parser(field.data);
  }

  return field.data;
};

const handleMainFields = (originalPayload: any) => {
  const payload: any = JSON.parse(JSON.stringify(originalPayload));

  if (Array.isArray(payload.addresses)) {
    const addressesLength = payload.addresses.length;

    if (addressesLength >= 1) {
      const mainAddress = payload.addresses[0];
      payload.mainAddress = mainAddress;

      if (addressesLength > 1) {
        payload.addresses = payload.addresses.slice(1);
      } else {
        delete payload.addresses;
      }
    }
  }

  if (Array.isArray(payload.phones)) {
    const phonesLength = payload.phones.length;

    if (phonesLength >= 1) {
      const mainPhone = payload.phones[0];
      payload.mainPhone = mainPhone;

      if (phonesLength > 1) {
        payload.phones = payload.phones.slice(1);
      } else {
        delete payload.phones;
      }
    }
  }
  if (Array.isArray(payload.emails)) {
    const emailsLength = payload.emails.length;

    if (emailsLength >= 1) {
      const mainEmail = payload.emails[0];
      payload.mainEmail = mainEmail;

      if (emailsLength > 1) {
        payload.emails = payload.emails.slice(1);
      } else {
        delete payload.emails;
      }
    }
  }

  return payload;
};

const preparePayload = (payload: CreateCompanyPayloadType) => {
  if (payload) {
    const transformedPayload = Object.entries(payload)
      .filter(
        ([, field]) =>
          typeof field.data === 'boolean' || typeof field.data === 'number' || !!field.data,
      )
      .map(([key, field]) => [key, mapField(field)])
      .map((value) => {
        if (Array.isArray(value[1])) {
          return [
            value[0],
            value[1].filter(
              (data) =>
                typeof data !== 'object' ||
                (typeof data === 'object' && Object.keys(data).length > 0),
            ),
          ];
        }
        return value;
      })
      .reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value,
        }),
        {},
      );
    const finalPayLoad = handleMainFields(transformedPayload);

    return finalPayLoad;
  }

  return null;
};

const useCreateCompany = (refetchData?: () => void) => {
  const [createCompanyMutation, { loading }] = useMutation(CREATE_NEW_COMPANY);
  const navigate = useNavigate();

  const createCompany = async (payload: CreateCompanyPayloadType) => {
    const createCompanyInput = preparePayload(payload);

    if (createCompanyInput) {
      return createCompanyMutation({
        variables: {
          createCompanyInput,
        },
      })
        .then(({ errors, data }) => {
          if (errors) {
            handleError(errors[0]);
          } else if (data) {
            refetchData?.();
            const btn = (
              <Space>
                <Button
                  type='primary'
                  size='small'
                  data-cy='goDetailView'
                  onClick={() => {
                    const { id } = data.CreateCompany;
                    notification.destroy();
                    navigate(`/companies/${id}`);
                  }}
                >
                  Yes, go to details
                </Button>
              </Space>
            );

            notification.success({
              message: 'Success',
              // eslint-disable-next-line quotes
              description: "Company created successfully. Do you want go to the company's page?",
              key: 'createCompanySuccess',
              duration: 3,
              btn,
            });
          }
        })
        .catch((error) => {
          handleError(error);
          throw error;
        });
    }

    return Promise.reject<void>();
  };

  return {
    createCompany,
    createCompanyLoading: loading,
  };
};

export default useCreateCompany;
