import { InboxOutlined } from '@ant-design/icons';
import { Empty, Skeleton, Typography } from 'antd';

export const NO_DATA_OPTION = [{ value: null, label: <Empty /> }];
export const LOADING_DATA_OPTION = [
  { value: null, label: <Skeleton active paragraph={{ rows: 1 }} title={false} /> },
];
export const NO_DATA_OPTION_SMALL = [
  {
    value: null,
    label: (
      <Typography.Text style={{ pointerEvents: 'none' }}>
        <InboxOutlined />
      </Typography.Text>
    ),
  },
];
