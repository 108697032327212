import { Input, Radio } from 'antd';
import styled from 'styled-components';

const StyledSearchInput = styled(Input)`
  flex: 1;
  background: #eeeeee;
  font-size: 14px;

  input {
    background: inherit;
  }

  .ant-input-prefix {
    padding: 0 8px;
    font-size: 18px;
    color: #858585;
  }
`;

type StyledRadioButtonProps = React.ComponentProps<typeof Radio.Button> & {
  isSelected: boolean;
};

export const StyledRadioButton = styled(Radio.Button)<StyledRadioButtonProps>`
  &&& {
    border: none;
    height: 32px;
    border-inline-start: none;
    border-radius: 0;
    color: rgb(133, 133, 133);
    overflow: clip;
    background: ${({ isSelected }) => (isSelected ? 'rgb(204, 228, 246)' : 'transparent')};
    color: ${({ isSelected }) => (isSelected ? 'rgb(0, 120, 212)' : 'rgb(133, 133, 133)')};
  }
`;

export default StyledSearchInput;
