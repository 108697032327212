import React, { useState } from 'react';
import { Form } from 'antd';
import ReactDragListView from 'react-drag-listview';
import SectionCard from 'src/components/sectionCard/SectionCard';
import { DRAG_LIST_SECTIONS } from 'src/modules/opportunities/components/newOpportunity/constants';
import CloseOppModalComponent from 'src/modules/opportunities/components/closeOpp';
import { useOppContext } from 'src/context/opportunity.context';
import useNewOpp from 'src/modules/opportunities/components/newOpportunity/hook/useNewOpp';
import { AllLeadSourceModel } from 'src/modules/settings/modules/subTables/modules/opportunities/tabs/leadSource/models/leadSource.model';
import { AllUsersModel } from 'src/models/users.model';
import StyledOppFormContent from 'src/modules/opportunities/components/newOpportunity/styles/StyledOppFormContent';
import { CustomSelectContactOptions } from 'src/modules/opportunities/components/newOpportunity/context/newOpp.context';

interface CompanyOption {
  label: string;
  value: number;
  desc: string;
}
const OpportunityBasicTab = () => {
  const [sections, setSections] = useState(DRAG_LIST_SECTIONS);
  const {
    form,
    valueCompany,
    loading,
    contactsMfg,
    mfgContacts,
    distributorContactsValue,
    valueCompanyDistributor,
    customerCompaniesValues,
    customerContactsValues,
    customerContactsOptions,
    customerCompaniesOptions,
    opportunityOwner,
    oppStatus,
    oppStatusDefaultValue,
    handleOnSearch,
    mfgCompanies,
    customSelectOnChange,
    distributorCompanies,
    distributorContacts,
    closeOppModalState,
    opportunityClosedReasonOptions,
    opportunityFailedReasonOptions,
    handleCloseOppSaveAction,
    handleCloseOppModalState,
    closingLoading,
  } = useOppContext();
  const { opportunityHeaders, leadSourcesOptions, usersList } = useNewOpp();

  // TODO convert in a global hook
  const handleDragEnd = (fromIndex: number, toIndex: number) => {
    const data = [...sections];
    const item = data.splice(fromIndex, 1)[0];
    data.splice(toIndex, 0, item);
    setSections(data);
  };

  const addProps = (
    content: React.ReactElement<{
      // requiredFields?: string[];
      // handleOnChangeForm?: (e: any) => void;
      // handleOnSearch?: (value: string, fieldName: string) => void;
      mfgContacts?: CompanyOption[];

      // valueCompany?: string;
      loading?: boolean;
      hidden?: string[];
      headers: any;
      notInEditView?: string[];
      leadSourcesOptions?: AllLeadSourceModel;
      usersList?: AllUsersModel;
      valueCompany?: string;
      contactsMfg?: string[];
      mfgCompanies?: object[];
      distributorContactsValue?: string[];
      distributorCompanies?: CompanyOption[];
      distributorContacts?: CustomSelectContactOptions[];
      customerCompaniesOptions?: CompanyOption[];
      valueCompanyDistributor?: string[];
      customerCompaniesValues?: string[];
      customerContactsValues?: string[];
      customerContactsOptions?: CustomSelectContactOptions[];
      opportunityOwner?: string;
      status?: object[];
      oppStatusDefaultValue?: any;
      handleOnSearch?: (value: string, fieldName: string) => void;
      customSelectOnChange?: (value: any[], name: any) => void;
    }>,
  ) => {
    if (React.isValidElement(content)) {
      return React.cloneElement(content, {
        loading,
        hidden: ['stageId'],
        notInEditView: ['stageId'],
        headers: opportunityHeaders,
        leadSourcesOptions,
        usersList,
        valueCompany,
        contactsMfg,
        mfgCompanies,
        mfgContacts,
        distributorContactsValue,
        distributorCompanies,
        distributorContacts,
        valueCompanyDistributor,
        customerCompaniesValues,
        customerContactsValues,
        customerCompaniesOptions,
        customerContactsOptions,
        opportunityOwner,
        status: oppStatus,
        oppStatusDefaultValue,
        handleOnSearch,
        customSelectOnChange,
      });
    }
    return content;
  };

  const closeOppModal = (
    <CloseOppModalComponent
      show={!!closeOppModalState}
      onCancel={handleCloseOppModalState}
      opportunityClosedReasonOptions={opportunityClosedReasonOptions?.current}
      opportunityFailedReasonOptions={opportunityFailedReasonOptions?.current}
      handleOnSave={handleCloseOppSaveAction}
      closingLoading={closingLoading}
    />
  );

  return (
    <StyledOppFormContent>
      {closeOppModal}
      <Form name='formContactEdit' layout='vertical' form={form}>
        <ReactDragListView
          onDragEnd={handleDragEnd}
          nodeSelector='.section-card'
          handleSelector='.anticon-holder'
        >
          <div>
            {sections.map((section, index) => (
              <SectionCard
                title={section.title}
                key={section.title}
                notDraggable={index === 0 ? false : section.notDraggable}
                notClosable={section.notCloseable}
              >
                {addProps(section.content)}
              </SectionCard>
            ))}
          </div>
        </ReactDragListView>
      </Form>
    </StyledOppFormContent>
  );
};

export default OpportunityBasicTab;
