import { gql } from '@apollo/client';

export const GET_ALL_DIVISIONS = gql`
  query GetAllDivisions {
    GetAllDivisions {
      id
      name
    }
  }
`;

export const GET_DIVISION = gql`
  query GetDivision($getDivisionId: Int!) {
    GetDivision(id: $getDivisionId) {
      id
      name
      description
    }
  }
`;
