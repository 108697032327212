import { jwtDecode } from 'jwt-decode';

export const getUser = () => {
  const user = localStorage.getItem('tokenApp');
  const userDecoded: any = jwtDecode(user as string);
  return {
    fullName: userDecoded?.userFullName,
    id: userDecoded?.userId,
    email: userDecoded?.userEmail,
    roles: userDecoded?.roles,
    salesTeams: userDecoded?.salesTeams,
  };
};
