import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import useContactHeaders from 'src/hooks/useContactHeaders';
import useGridPreferences, { GridNames } from 'src/hooks/useGridPreferences';
import { SubTableColumn } from 'src/components/commons/subTable/types';
import { FilterContactNestEnum, ValueType, AllFilters2 } from 'src/graphql/schema-types';
import useContactsStore from 'src/hooks/stores/useContactsStore';

interface InitialStateProps {
  activeCreateContact: () => void;
  desactiveCreateContact: () => void;
  createContactModalActive: boolean;
  allowCreateContact: boolean;
  allowCreateContactHandler: (status: boolean) => void;
  progressCreationContact: number;
  progressCreationContactHandler: (progress: number) => void;
  exportContactsHandler: () => void;
  exportContactsModalActive: boolean;
  gridPreferences: ReturnType<typeof useGridPreferences>;
  columns: SubTableColumn[];
  setColumns: React.Dispatch<React.SetStateAction<SubTableColumn[]>>;
  headerContactsGlobalSearch: (searchValue: string) => void;
  newDataSource: any;
  filters: any;
}

export const ContactsContext = createContext({} as InitialStateProps);

const ContactsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { fetchAll, setFilters, filters } = useContactsStore();
  const [createContactModalActive, setCreateContactModalActive] = useState(false);
  const [exportContactsModalActive, setExportContactsModalActive] = useState(false);
  const [allowCreateContact, setAllowCreateContact] = useState(true);
  const [progressCreationContact, setProgressCreationContact] = useState(0);
  const { initialColumns } = useContactHeaders();
  const gridPreferences = useGridPreferences(GridNames.Contacts);
  const [columns, setColumns] = useState<SubTableColumn[]>([]);
  const [newDataSource, setNewDataSource] = useState<any>([]);

  useEffect(() => {
    if (gridPreferences.selected && initialColumns) {
      setColumns(() => gridPreferences.prepareColumns(initialColumns));
    }
  }, [gridPreferences.selected, initialColumns]);

  const activeCreateContact = () => {
    setCreateContactModalActive(!createContactModalActive);
  };
  const desactiveCreateContact = () => {
    setCreateContactModalActive(!createContactModalActive);
  };

  const allowCreateContactHandler = (status: boolean) => {
    setAllowCreateContact(status);
  };

  const progressCreationContactHandler = (pregress: number) => {
    setProgressCreationContact(pregress);
  };

  const exportContactsHandler = () => {
    setExportContactsModalActive(!exportContactsModalActive);
  };

  const prepareGlobalSearchFilters = (value: string) => {
    const fieldsToSearch = [
      {
        property: 'FirstName',
        dataType: ValueType.String,
      },
      {
        property: 'LastName',
        dataType: ValueType.String,
      },
      {
        property: 'BusinessInfoJobTitle',
        dataType: ValueType.String,
      },
      // {
      //   property: 'BusinessInfoMainPhonePhone',
      //   dataType: ValueType.Number,
      // },
      {
        property: 'BusinessInfoCompanyName',
        dataType: ValueType.String,
      },
      {
        property: 'BusinessInfoCompanyTypeName',
        dataType: ValueType.String,
      },
      {
        property: 'BusinessInfoCompanySalesTeamName',
        dataType: ValueType.String,
      },
      {
        property: 'BusinessInfoMainAddressCity',
        dataType: ValueType.String,
      },
      {
        property: 'BusinessInfoMainAddressAddress',
        dataType: ValueType.String,
      },
      {
        property: 'BusinessInfoMainAddressRegionName',
        dataType: ValueType.String,
      },
      {
        property: 'BusinessInfoMainAddressStateName',
        dataType: ValueType.String,
      },
      {
        property: 'BusinessInfoMainAddressZipCode',
        dataType: ValueType.String,
      },
      {
        property: 'GroupName',
        dataType: ValueType.String,
      },
      {
        property: 'FullName',
        dataType: ValueType.String,
      },
      {
        property: 'Title',
        dataType: ValueType.String,
      },
    ];

    const filtersList = fieldsToSearch.map((filter: { property: string; dataType: ValueType }) => ({
      fieldName: FilterContactNestEnum[filter.property as keyof typeof FilterContactNestEnum],
      dataType: filter.dataType,
      operator: AllFilters2.Contains,
      value,
    }));

    return filtersList;
  };

  const headerContactsGlobalSearch = (searchValue: string) => {
    if (searchValue) {
      const globalSearchFilters = prepareGlobalSearchFilters(searchValue);
      setFilters(globalSearchFilters);
      fetchAll({
        variables: {
          criteria: {
            pagination: {
              size: -1,
            },
            filter: globalSearchFilters,
          },
        },
      }).then((data: any) => {
        setNewDataSource(data.results);
      });
    } else {
      setNewDataSource([]);
      setFilters([]);
    }
  };

  const companiesValue = useMemo(
    () => ({
      createContactModalActive,
      activeCreateContact,
      desactiveCreateContact,
      allowCreateContact,
      allowCreateContactHandler,
      progressCreationContact,
      progressCreationContactHandler,
      exportContactsHandler,
      exportContactsModalActive,
      columns,
      setColumns,
      gridPreferences,
      newDataSource,
      headerContactsGlobalSearch,
      filters,
    }),
    [
      activeCreateContact,
      desactiveCreateContact,
      createContactModalActive,
      allowCreateContact,
      allowCreateContactHandler,
      progressCreationContact,
      progressCreationContactHandler,
      exportContactsHandler,
      exportContactsModalActive,
      columns,
      setColumns,
      gridPreferences,
      newDataSource,
      headerContactsGlobalSearch,
      filters,
    ],
  );

  return <ContactsContext.Provider value={companiesValue}>{children}</ContactsContext.Provider>;
};

export const useContactsContext = () => {
  const context = useContext(ContactsContext);

  if (context === undefined) {
    throw new Error('useContactsContext must be used within a ContactsContextProvider');
  }

  return context;
};

export default ContactsContextProvider;
