import { FC } from 'react';
import SettingsTable from 'src/components/settingsTables';
import useProductLinesTable from './useProductLinesTable';

const TabProductLines: FC = () => {
  const { dataSource, columns } = useProductLinesTable();
  return (
    <SettingsTable
      dataSource={dataSource}
      columns={columns}
      columnsTeamMember={columns}
      contentOnDelete=''
      isMembersTableOnly
      infoIconTextUser='In this list you can find all the Mgf./Product lines available in RepFabric. Select those you want to have on the list in Activity Journal.'
      infoIconTextMembers='In this list, you can find all the Mgf./Product lines that appear when creating or editing activities in RepFabric.'
      usersMembersTitle={{ users: 'Available', members: 'Selected' }}
    />
  );
};

export default TabProductLines;
