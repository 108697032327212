import { gql } from '@apollo/client';

export const CREATE_OPP_STAGE = gql`
  mutation CreateOpportunityStage($createStageInput: CreateStageInput!) {
    CreateOpportunityStage(createStageInput: $createStageInput) {
      id
      name
      potential
      boolean
      closeStatus {
        id
        name
      }
      stageGroup {
        id
        name
      }
      status {
        id
        name
      }
    }
  }
`;
export const UPDATE_OPP_CLOSE_REASON = gql`
  mutation UpdateOpportunityStage($updateStageInput: UpdateStageInput!) {
    UpdateOpportunityStage(updateStageInput: $updateStageInput) {
      id
      name
      potential
      boolean
      closeStatus {
        id
        name
      }
      stageGroup {
        id
        name
      }
      status {
        id
        name
      }
    }
  }
`;
export const REMOVE_OPP_STAGE = gql`
  mutation RemoveOpportunityStage($id: Int!) {
    RemoveOpportunityStage(id: $id)
  }
`;
