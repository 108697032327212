import React, { useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';

const QuillInput = ({ value, onChange }: { value?: string; onChange?: () => void }) => {
  const textRef = useRef<ReactQuill>(null);
  useEffect(() => {
    if (textRef.current?.editor && value) {
      textRef.current.editor.root.innerHTML = value;
    }
  }, []);
  return <ReactQuill ref={textRef} theme='snow' value={value} onChange={onChange} />;
};

export default QuillInput;
