import { gql } from '@apollo/client';

export const UPDATE_BUYING_GROUP = gql`
  mutation UpdateBuyGroup($updateBuyGroupInput: UpdateBuyGroupInput!) {
    UpdateBuyGroup(updateBuyGroupInput: $updateBuyGroupInput) {
      id
    }
  }
`;

export const CREATE_BUYING_GROUP = gql`
  mutation CreateBuyGroup($createBuyGroupInput: CreateBuyGroupInput!) {
    CreateBuyGroup(createBuyGroupInput: $createBuyGroupInput) {
      id
      name
      description
    }
  }
`;

export const REMOVE_BUYING_GROUP = gql`
  mutation RemoveBuyGroup($id: Int!) {
    RemoveBuyGroup(id: $id)
  }
`;
