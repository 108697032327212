import { FC } from 'react';
import SubTableV1 from 'src/components/commons/subTableV1';
import PageContainer from 'src/components/pageContainer/PageContainer';
import useStatus from './hooks/useStatus';

const Status: FC = () => {
  const {
    data,
    columns,
    addNewRecord,
    editRecord,
    deleteRecord,
    autocompleteFieldSearch,
    sortRecord,
    tempPredefinedStatus,
  } = useStatus();

  return (
    <PageContainer>
      <div style={{ paddingLeft: 50, paddingRight: 50 }}>
        <SubTableV1
          data={data ?? []}
          columns={columns ?? []}
          columnFilterShown
          total
          showAutoIncrement
          nonSelectable
          actionCell={['delete']}
          scroll={{ y: '55vh', x: 'auto' }}
          postData={addNewRecord}
          editRecord={editRecord}
          onDeleteClick={deleteRecord}
          autocompleteFieldProvider={autocompleteFieldSearch}
          onSortChange={sortRecord}
          headerMessage={tempPredefinedStatus}
          allowGlobalEditActive
          id='status'
        />
      </div>
    </PageContainer>
  );
};

export default Status;
