import useEntityAdapter, { OperationSpecs } from 'src/hooks/useEntityAdapter';
import { GET_ALL_OPPORTUNITY_TYPES } from '../graphql/queries';
import {
  CREATE_OPPORTUNITY_TYPE,
  UPDATE_OPPORTUNITY_TYPE,
  REMOVE_OPPORTUNITY_TYPE,
} from '../graphql/mutations';
import { TypeSubtableModel } from '../models/typeSubtable.model';
import { typeSubtableAdapter } from '../adapters/typeSubtable.adapter';

const operations: OperationSpecs = {
  createOne: {
    op: CREATE_OPPORTUNITY_TYPE,
  },
  updateOne: {
    op: UPDATE_OPPORTUNITY_TYPE,
  },
  deleteOne: {
    op: REMOVE_OPPORTUNITY_TYPE,
  },
  fetchAll: {
    op: GET_ALL_OPPORTUNITY_TYPES,
    dataPath: 'GetAllOpportunityTypes',
  },
};

const useTypeSubtableStore = () => {
  const api = useEntityAdapter<TypeSubtableModel>({
    adapters: { entityAdapter: typeSubtableAdapter },
    operations,
    isPaginated: true,
    entityName: 'TypeSubtable',
  });

  return api;
};
export default useTypeSubtableStore;
