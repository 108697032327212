import React, { useState } from 'react';
import { Form } from 'antd';
import ReactDragListView from 'react-drag-listview';
import CompanyDetailStyles from 'src/components/companyDetails/components/companyDetail.styles';
import SectionCard from 'src/components/sectionCard/SectionCard';
import { useCompanyContext } from 'src/context/company.context';
import { CustomLoadingModal } from 'src/components/commons/customModal';

import { DRAG_LIST_SECTIONS } from '../constants';

const CompanyBasic = () => {
  const [sections, setSections] = useState(DRAG_LIST_SECTIONS);
  const { form, loading } = useCompanyContext();

  const handleDragEnd = (fromIndex: number, toIndex: number) => {
    const data = [...sections];
    const item = data.splice(fromIndex, 1)[0];
    data.splice(toIndex, 0, item);
    setSections(data);
  };

  return (
    <>
      <CompanyDetailStyles />
      {loading && <CustomLoadingModal modalStatus />}
      <Form name='formCompanyEdit' form={form} layout='vertical'>
        <ReactDragListView
          onDragEnd={handleDragEnd}
          nodeSelector='.section-card'
          handleSelector='.anticon-holder'
        >
          <div>
            {sections.map((section) => (
              <SectionCard title={section.title} key={section.title}>
                {section.content}
              </SectionCard>
            ))}
          </div>
        </ReactDragListView>
      </Form>
    </>
  );
};

export default CompanyBasic;
