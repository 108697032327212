import { gql } from '@apollo/client';

export const UPDATE_PHONE_TYPE = gql`
  mutation UpdatePhoneType($updatePhoneTypeInput: UpdatePhoneTypeInput!) {
    UpdatePhoneType(updatePhoneTypeInput: $updatePhoneTypeInput) {
      name
      description
    }
  }
`;

export const CREATE_PHONE_TYPE = gql`
  mutation CreatePhoneType($createPhoneTypeInput: CreatePhoneTypeInput!) {
    CreatePhoneType(createPhoneTypeInput: $createPhoneTypeInput) {
      name
      description
    }
  }
`;

export const REMOVE_PHONE_TYPE = gql`
  mutation RemovePhoneType($id: Int!) {
    RemovePhoneType(id: $id)
  }
`;
