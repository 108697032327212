/* eslint-disable prettier/prettier */
import React, { useState, useRef, useEffect } from 'react';
import CustomAddressForm from 'src/components/commons/addressForm';
import { DeleteOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import {
  AddressModel,
  FormAddressElementsType,
  FormElementsOptions,
  SeparateComponentDataHandlerType,
  CompaniesHeaderType2,
  ListGroupType,
} from 'src/types/companiesTypes';
import CompaniesColumns from 'src/utils/tables/companiesTable';
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_REGION_REGION } from 'src/modules/settings/modules/subTables/modules/company/tabs/region/graphql/queries';
import {
  GetAllRegions,
  GetAllRegionsResponse,
} from 'src/modules/settings/modules/subTables/modules/company/tabs/region/types';

interface AddressHandlerType {
  requiredFields?: FormElementsOptions[];
  separateComponentDataHandler?: (
    data: SeparateComponentDataHandlerType[] | '',
    name?: string,
  ) => void;
  addresses?: AddressModel[] | undefined;
}

declare let google: any;
const AddressHandler: React.FC<AddressHandlerType> = ({
  requiredFields,
  separateComponentDataHandler,
  addresses,
}) => {
  const [regions, setRegions] = useState<GetAllRegionsResponse[]>([]);
  const [localRequiredFields, setLocalRequiredFields] = useState([]);
  const { companiesHeaderResponse2 } = CompaniesColumns();
  const [getRegions] = useLazyQuery<GetAllRegions>(GET_ALL_REGION_REGION);

  useEffect(() => {
    getRegions({
      variables: {
        criteria: {
          pagination: {
            size: -1,
          },
        },
      },
    }).then((res) => {
      const opt = res.data?.GetAllRegions2.results.map((el) => ({
        label: el.name,
        value: el.id,
      })) as [];
      const data = requiredFields?.map((element) => {
        if (element.name === 'mainAddress.region') {
          return {
            ...element,
            options: opt,
          };
        }
        return element;
      });
      setLocalRequiredFields(data as []);
      setRegions(opt ?? []);
    });
  }, []);

  const addressTypeOption: CompaniesHeaderType2 =
    companiesHeaderResponse2.data?.GetCompaniesDepthGridHeader.find(
      (item: CompaniesHeaderType2) => item.dataIndex === 'mainAddress.addressType',
    );

  const internalAddressTypes = useRef(
    addressTypeOption?.options?.map((element) => ({
      label: element.name,
      value: String(element.id),
    })) ?? [],
  );

  const dataList: CompaniesHeaderType2[] =
    companiesHeaderResponse2?.data?.GetCompaniesDepthGridHeader?.filter(
      (element: CompaniesHeaderType2) =>
        element.dataIndex === 'mainAddress.region' ||
        element.dataIndex === 'mainAddress.country' ||
        element.dataIndex === 'mainAddress.territory' ||
        element.dataIndex === 'mainAddress.state',
    );

  const localDataList: ListGroupType[] = dataList?.map((element) => {
    if (element.dataIndex === 'mainAddress.region') {
      return {
        label: element.dataIndex.toLowerCase(),
        options: regions as [],
      };
    }
    return {
      label: element.dataIndex.toLowerCase(),
      options:
        element.options?.map((item) => ({
          label: item.name,
          value: String(item.id),
        })) ?? [],
    };
  });

  const [formElementData, setFormElementData] = useState<FormAddressElementsType[]>([
    {
      formId: 'form-1',
      compactForm: false,
      addressData: {
        addressType: {
          name:
            internalAddressTypes?.current?.length > 0 ? internalAddressTypes.current[0].label : '',
          id:
            internalAddressTypes?.current?.length > 0
              ? String(internalAddressTypes.current[0].value)
              : '',
        },
        address: '',
        pobox: '',
        zipCode: '',
        region: '',
        territory: '',
        city: '',
        state: '',
        country: '',
        geopositionLatitude: '',
        geopositionLongitude: '',
      },
    },
  ]);

  const autocompleteRef = useRef(null);
  const [currentId, setCurrentId] = useState('form-1');

  function removeDuplicatesAndFormat(
    array: string[],
    selected: {
      selectedCity: string;
      selectedState: { name: string; id: number };
      selectedAddress: { name: string; id: number };
    },
  ): string {
    const filteredArray = array.filter(
      (item) =>
        item?.trim() !== selected.selectedCity &&
        item?.trim() !== selected.selectedState?.name &&
        item?.trim() !== selected.selectedAddress?.name,
    );

    return filteredArray.join(', ');
  }

  const googleAutocompleteFunction = (
    searchText: HTMLInputElement,
    addressId: React.Key,
    selectedAddress?: any,
  ) => {
    if (currentId !== addressId) {
      setCurrentId(String(addressId));
    }
    const service =
      autocompleteRef.current && currentId === String(addressId)
        ? autocompleteRef.current
        : (autocompleteRef.current = new google.maps.places.Autocomplete(searchText, {
            fields: ['address_components', 'geometry', 'formatted_address'],
            types: ['address'],
            componentRestrictions: {
              country: ['USA', 'CA'],
            },
          }));
    service.addListener('place_changed', () => {
      const place = service.getPlace();
      const index: number = formElementData.findIndex((item) => item.formId === addressId);
      const newFormElementData = formElementData;
      newFormElementData[index].addressData.address = removeDuplicatesAndFormat(
        place.formatted_address.split(','),
        selectedAddress,
      );
      newFormElementData[index].addressData.geopositionLatitude = place.geometry.location.lat();
      newFormElementData[index].addressData.geopositionLongitude = place.geometry.location.lng();
      place.address_components?.forEach((element: any) => {
        const placeType = element.types[0];
        switch (placeType) {
          case 'postal_code': {
            newFormElementData[index].addressData.zipCode = element.long_name;
            break;
          }
          case 'locality': {
            newFormElementData[index].addressData.city = element.short_name;
            break;
          }
          case 'sublocality_level_1': {
            newFormElementData[index].addressData.city = element.short_name;
            break;
          }
          case 'administrative_area_level_1': {
            const selectedDropDown = localDataList.find(
              (item) => item.label === 'mainaddress.state',
            );
            const selectedOption = selectedDropDown?.options.find(
              (item) => item.label === element.long_name,
            );
            newFormElementData[index].addressData.state = {
              name: selectedOption?.label || '',
              id: Number(selectedOption?.value) || '',
            };
            break;
          }
          case 'country': {
            const selectedDropDown = localDataList.find(
              (item) => item.label === 'mainaddress.country',
            );

            // TODO: update backend to return correct data and matches with the google api
            const definedElement =
              element.long_name === 'United States' ? 'USA' : element.long_name;

            const selectedOption = selectedDropDown?.options.find(
              (item) => item.label === definedElement,
            );

            newFormElementData[index].addressData.country = {
              name: selectedOption?.label || '',
              id: Number(selectedOption?.value) || '',
            };
            break;
          }
          default:
            break;
        }
      });
      setFormElementData(newFormElementData);
    });
  };

  const onAddressSearch = (
    searchText: HTMLInputElement,
    addressId: React.Key,
    selectedAddress?: any,
  ) => {
    googleAutocompleteFunction(searchText, addressId, selectedAddress);
  };

  const addNewFormElement = () => {
    const innerElement = [...formElementData];
    innerElement.push({
      formId: `form-${innerElement.length + 1}`,
      compactForm: false,
      addressData: {
        addressType: null,
        address: '',
        pobox: '',
        zipCode: '',
        region: '',
        territory: '',
        city: '',
        state: '',
        country: '',
        geopositionLatitude: '',
        geopositionLongitude: '',
      },
    });
    setFormElementData(innerElement);
  };

  const onInputChange = (addressId: React.Key, data: string, formName: string) => {
    const index = formElementData.findIndex((item) => item.formId === addressId);
    const newFormElementData = [...formElementData];
    newFormElementData[index] = {
      ...newFormElementData[index],
      addressData: {
        ...newFormElementData[index].addressData,
        [formName]: data,
      },
    };
    setFormElementData(newFormElementData);
  };

  const onSelectChange = (
    addressId: React.Key,
    event: React.FormEvent<HTMLInputElement>,
    formName?: string,
  ) => {
    const innerElement = formElementData;
    const index: number = innerElement.findIndex((item) => item.formId === addressId);
    const selectedDropDown = requiredFields?.find((element) => element.name === formName);
    const selectedOption = selectedDropDown?.options.find(
      (element) => element.value === (event as unknown as string),
    );
    const newInnerElement = innerElement[index];
    if (formName === 'mainAddress.region') {
      newInnerElement.addressData.region = {
        id: selectedOption?.value || '',
        name: selectedOption?.label || '',
      };
    }
    if (formName === 'mainAddress.territory') {
      newInnerElement.addressData.territory = {
        id: selectedOption?.value || '',
        name: selectedOption?.label || '',
      };
    }
    if (formName === 'mainAddress.state') {
      newInnerElement.addressData.state = {
        id: selectedOption?.value || '',
        name: selectedOption?.label || '',
      };
    }
    if (formName === 'mainAddress.country') {
      newInnerElement.addressData.country = {
        id: selectedOption?.value || '',
        name: selectedOption?.label || '',
      };
    }
  };
  const addressTypeAction = (
    elementId: React.Key,
    addressType: React.FormEvent<HTMLInputElement>,
  ) => {
    const innerElement = [...formElementData];
    const index = innerElement.findIndex((item) => item.formId === elementId);
    const newInnerElement = innerElement[index];
    const selectedAddressType = internalAddressTypes.current.find(
      (element) => element.value === (addressType as unknown as string),
    );
    newInnerElement.addressData.addressType = {
      name: selectedAddressType?.label || '',
      id: String(selectedAddressType?.value) || '',
    };
    innerElement.splice(index, 1, newInnerElement);
    setFormElementData(innerElement);
  };

  const compactFormHandler = (element: FormAddressElementsType) => {
    const innerElement = [...formElementData];
    const newInnerElement = element;
    const index = innerElement.findIndex((item) => newInnerElement.formId === item.formId);
    newInnerElement.compactForm = !newInnerElement.compactForm;
    innerElement.splice(index, 1, newInnerElement);
    setFormElementData(innerElement);
  };

  const deleteAddressAction = (elementId: React.Key) => {
    const innerElement = [...formElementData];
    const index = innerElement.findIndex((item) => elementId === item.formId);
    innerElement.splice(index, 1);
    setFormElementData(innerElement);
  };

  useEffect(() => {
    if (separateComponentDataHandler) {
      let allowToSave = false;
      const innerValue = formElementData.map((item) => {
        const { addressData } = item;
        if (addressData.address !== '') {
          allowToSave = true;
        }
        return { addressData };
      });
      if (allowToSave) {
        separateComponentDataHandler(innerValue, 'addresses');
      } else {
        separateComponentDataHandler('', 'addresses');
      }
    }
  }, [formElementData]);

  return (
    <>
      {React.Children.toArray(
        formElementData.map((element) => (
          <CustomAddressForm
            addressFormatList={internalAddressTypes.current}
            deleteIcon={<DeleteOutlined />}
            hideOrShowIcon={element.compactForm ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            {...{ compactFormHandler }}
            elementData={element}
            {...{ addNewFormElement }}
            {...{ addressTypeAction }}
            {...{ deleteAddressAction }}
            {...{ onAddressSearch }}
            {...{ onInputChange }}
            requiredFields={localRequiredFields}
            {...{ onSelectChange }}
            addresses={addresses}
          />
        )),
      )}
    </>
  );
};

export default AddressHandler;
