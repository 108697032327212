import styled from 'styled-components';

const StyledSettingsTables = styled.div<{ tabs?: boolean }>`
  display: flex;
  gap: 1.5rem;
  padding: 1rem 2rem;
  .table-container {
    .ant-table-cell {
      border: none;
      font-size: 14px;
      padding: 0 5px !important;
    }
    .ant-table-cell::before {
      width: 0 !important;
    }
  }
  .ant-table-thead {
    display: none;
  }
  .table-container,
  .tabs-title-container {
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 1rem;
  }
  .tabs-title-container {
    padding-bottom: ${(props) => (props.tabs ? 0 : undefined)};
    h2 {
      font-weight: normal;
    }
  }
  .tabs-outlet-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media (min-width: 768px) {
      padding-left: 26px;
    }
  }
`;

export default StyledSettingsTables;
