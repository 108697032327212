/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint react/jsx-no-useless-fragment: 0 */
/* eslint no-nested-ternary: 0 */
import {
  Checkbox,
  Input,
  Radio,
  Select,
  DatePicker,
  InputRef,
  Row,
  Col,
  Typography,
  Space,
  InputNumber,
  Tag,
} from 'antd';
import SelectSearch from 'src/components/commons/selectSearch';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import Addresses from 'src/components/addressesSubForm';
import Phones from 'src/components/phonesSubForm';
import Emails from 'src/components/emailsSubForm';
import { getDefaultValue, getValueFromSelectToInput } from 'src/utils/functions/getDefaultValues';
import dayjs from 'dayjs';
import { FieldType } from '../types';
import FileInput from './fileInput';
import Stepper, { Step } from '../../stepper';

type Props = {
  field: FieldType;
  value?: any;
  onChange?: (value: any, option?: any) => void;
  addonAfter?: ReactNode;
  addonBefore?: ReactNode;
  hadleAddNew?: () => void;
};

const GenericInput: FC<Props> = ({
  field,
  value,
  onChange,
  addonAfter,
  addonBefore,
  hadleAddNew,
}) => {
  const defaultPlaceholder = field.type === 'select' ? 'Please select' : 'Type here...';
  // const regexp = /^{{%(.+)%}}/;
  const { Option } = Select;
  const placeholder = field.placeholder ?? defaultPlaceholder;
  const initialized = useRef(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    if (field.type === 'multiSelect' && field.options) {
      const initialValues = field.options
        .filter((option) => value?.includes(option.value))
        .map((option) => option.value);
      setSelectedOptions(initialValues);
    }
  }, [value, field.type, field.options]);

  const customDropdownRender = (menu: React.ReactNode) => <>{menu}</>;

  const maxTagPlaceholder = () => (
    <Tag style={{ background: '#DCDFFB', border: '1px solid #0078D4' }}>
      {selectedOptions?.length} Total
    </Tag>
  );

  const renderOptionWithCheckboxMulti = (option: any) => {
    const isChecked = selectedOptions.some(
      (selectedOption: any) => selectedOption === option.value,
    );

    return (
      <div style={{ display: 'flex', alignItems: 'center', padding: '0px !important' }}>
        <Checkbox checked={isChecked} style={{ marginRight: 5 }} />
        {option.label}
      </div>
    );
  };

  const disabledDate = (current: any) => current && current < dayjs().startOf('day');

  const autoFocus = useCallback(
    (ref: InputRef) => {
      if (ref && field.autoFocus && !initialized.current) {
        initialized.current = true;
        setTimeout(() => {
          ref.focus();
        }, 10);
      }
    },
    [field.name],
  );

  const handleChange = (e: any) => {
    onChange?.(e);
    field.onChange?.(e);
  };

  const handleChangeSelect = (e: any, option: any) => {
    onChange?.(e, option);

    setTimeout(() => {
      field.onChange?.(e, option || field.name);
    }, 0);
  };
  const handleStepSelect = (e: number) => {
    onChange?.(e);
    field.onChange?.(e);
  };

  const handleChangeSelectMulti = (selectedValues: any[], Soption?: any) => {
    const newSelectedOptions = selectedValues.map((val) => {
      const foundOption = field?.options?.find((option) => option.value === val);
      return foundOption ? foundOption.value : val;
    });

    setSelectedOptions(newSelectedOptions);

    onChange?.(selectedValues, Soption);
    field.onChange?.(selectedValues, field.name);
  };

  if (field.render)
    return field.render({
      value,
      onChange:
        field.type === 'multiselect'
          ? handleChangeSelectMulti
          : field.type === 'select' || field.type === 'selectSearch'
          ? handleChangeSelect
          : handleChange,
    });

  switch (field.type) {
    case 'select':
      return field.readOnly ? (
        <Input
          readOnly
          value={getValueFromSelectToInput(value, field.options as [])}
          placeholder='Please select'
        />
      ) : (
        <Select
          value={getDefaultValue(value, field.options as [])}
          placeholder={placeholder}
          options={field.options}
          onChange={handleChangeSelect}
          data-cy={String(field.name)}
          id={field.label}
          disabled={field.disabled}
          defaultValue={field.defaultValue}
        />
      );

    case 'stepper':
      return field.readOnly ? null : field.hidden ? null : (
        <Stepper setStepValue={handleStepSelect} steps={field.options as unknown as Step[]} />
      );
    case 'selectSearch':
      return field.readOnly ? (
        <Input
          readOnly
          value={getValueFromSelectToInput(value, field.options as [])}
          placeholder='Please select'
        />
      ) : (
        <SelectSearch
          twoLines
          options={field.options ?? []}
          // addNewButton
          handleChange={handleChangeSelect}
          addNewButtonFunction={hadleAddNew}
          name={String(field.name)}
          onSearch={field.onSearch}
          defaultValue={field.defaultValue}
        />
      );
    case 'selectionRenderWithTag':
      return (
        <Select
          mode='multiple'
          maxTagCount='responsive'
          maxTagPlaceholder={maxTagPlaceholder}
          defaultValue={getDefaultValue(field.initialValue, field.options as [])}
          value={getDefaultValue(value, field.options as [])}
          onSearch={field.onSearch}
          placeholder={field.placeholder}
          style={{ width: '100%' }}
          onChange={handleChangeSelectMulti}
          options={field.options ?? []}
          // eslint-disable-next-line react/no-unstable-nested-components
          optionRender={(option) => (
            <Space align='center'>
              {option.data.label}
              <Tag>User</Tag>
            </Space>
          )}
        />
      );
    case 'multiSelectSearch':
      return field.readOnly ? (
        <Input
          readOnly
          value={getValueFromSelectToInput(value, field.options as [])}
          placeholder='Please select'
        />
      ) : (
        <SelectSearch
          twoLines
          multiselect
          options={field.options ?? []}
          addNewButton
          handleChange={handleChangeSelectMulti}
          addNewButtonFunction={hadleAddNew}
          name={String(field.name)}
          onSearch={field.onSearch}
          defaultValue={field.defaultValue}
        />
      );
    case 'multiSelect':
      return (
        <Select
          maxTagCount='responsive'
          disabled={field.disabled || field.readOnly}
          maxTagPlaceholder={maxTagPlaceholder}
          placeholder={placeholder}
          defaultValue={getDefaultValue(field.initialValue, field.options as [])}
          allowClear
          showSearch
          data-cy={String(field.name)}
          mode='multiple'
          className={field.readOnly ? 'readOnly-ant-multisel' : undefined}
          dropdownRender={customDropdownRender}
          value={getDefaultValue(value, field.options as [])} // Control the value of Select
          // tagRender={(props) => tagRenderFixed(props)}
          onChange={handleChangeSelectMulti}
        >
          {field.options?.map((option, index) => (
            <Option
              key={option.label}
              data-testid={`columns-options-id-${index}`}
              style={{ display: 'flex', alignItems: 'center', padding: '0px !important' }}
              value={option.value}
            >
              {renderOptionWithCheckboxMulti(option)}
            </Option>
          ))}
        </Select>
      );
    case 'radio':
      return (
        <Radio.Group
          defaultValue={field.initialValue}
          value={value}
          options={field.options}
          onChange={
            field.readOnly
              ? (e) => {
                  e.preventDefault();
                  return false;
                }
              : handleChange
          }
          data-cy={String(field.name)}
          disabled={field.disabled}
        />
      );
    case 'checkbox':
      return (
        <Checkbox
          name={String(field.name)}
          value={value}
          checked={value}
          style={{ pointerEvents: field.readOnly ? 'none' : undefined }}
          onChange={(e) => handleChange(e.target.checked)}
          data-cy={String(field.name)}
          disabled={field.disabled}
        >
          {field.label}
        </Checkbox>
      );
    case 'checkboxEnd':
      return (
        <Row justify='end'>
          <Checkbox
            name={String(field.name)}
            value={value}
            checked={value}
            style={{ pointerEvents: field.readOnly ? 'none' : undefined }}
            onChange={(e) => handleChange(e.target.checked)}
            data-cy={String(field.name)}
            disabled={field.disabled}
          >
            {field.label}
          </Checkbox>
        </Row>
      );
    case 'textarea':
      return (
        <Row justify='space-between' align='bottom'>
          {field.addDateTimeButtom && (
            <>
              <Col
                style={{
                  fontSize: 14,
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '22px',
                  color: '#353535',
                }}
              >
                Reporting Comments
              </Col>
              <Col
                style={{
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '16px',
                  color: '#5D5D5D',
                  cursor: 'pointer',
                }}
              >
                <div
                  onClick={() => {
                    field.addDateAndTime?.(field.name as string);
                  }}
                >
                  Add date/time
                </div>
              </Col>
            </>
          )}

          <Input.TextArea
            defaultValue={field.initialValue}
            value={value}
            placeholder={placeholder}
            autoSize
            onChange={handleChange}
            data-cy={String(field.name)}
            ref={autoFocus}
            disabled={field.disabled}
            readOnly={field.readOnly}
            showCount={field.showCount}
            maxLength={field.maxLength}
          />
        </Row>
      );
    case 'file':
      return <FileInput accept={field.accept} value={value} onChange={handleChange} />;
    case 'addresses':
      return (
        <Addresses
          value={value}
          onChange={handleChange}
          readOnly={field.readOnly}
          formName={field.formName}
        />
      );
    case 'phones':
      return (
        <Phones
          value={value}
          onChange={handleChange}
          readOnly={field.readOnly}
          formName={field.formName}
        />
      );
    case 'emails':
      return (
        <Emails
          value={value}
          onChange={handleChange}
          readOnly={field.readOnly}
          formName={field.formName}
        />
      );
    case 'dateRange':
      return (
        <DatePicker.RangePicker
          value={value}
          onChange={handleChange}
          disabled={field.disabled}
          data-cy={String(field.name)}
        />
      );
    case 'date':
      return (
        <DatePicker
          value={value}
          onChange={handleChange}
          disabled={field.disabled}
          data-cy={String(field.name)}
          style={field.style}
          format='MM-DD-YYYY'
          disabledDate={field.disabledDate ? disabledDate : undefined}
        />
      );
    case 'number':
      return (
        <InputNumber
          defaultValue={field.initialValue}
          style={{ width: '100%' }}
          value={value}
          type='number'
          maxLength={field.maxLength}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={field.disabled}
          readOnly={field.readOnly}
          min={field.min}
          max={field.max}
        />
      );
    case 'label':
      return (
        <Typography.Title level={5} style={field.style}>
          {field.label}
        </Typography.Title>
      );
    default:
      return (
        <Input
          defaultValue={field.initialValue}
          value={value}
          maxLength={field.maxLength}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={field.disabled}
          readOnly={field.readOnly}
          data-cy={String(field.name)}
          ref={autoFocus}
          id='caca'
          addonAfter={addonAfter}
          addonBefore={addonBefore}
        />
      );
  }
};

export default GenericInput;
