import styled from 'styled-components';
import { Layout, Typography, Avatar } from 'antd';

const { Header } = Layout;
const { Title } = Typography;

const StyledHeader = styled(Header)`
  &&& {
    background-color: rgba(250, 250, 250, 1);
    padding: 0 32px;
    min-width: max-content;
    height: 50px;
    line-height: 50px;
    .customDropdown {
      :hover {
        background: #e4e4e4 !important;
      }
    }
  }
`;

const StyledTitle = styled(Title)`
  &&& {
    margin: auto 20px;
    color: white;
    font-weight: normal;
  }
`;

const StyledAvatar = styled(Avatar)`
  &&& {
    background-color: #87d068;
    margin-bottom: 6px;
  }
`;

export { StyledHeader, StyledTitle, StyledAvatar };
