import { gql } from '@apollo/client';

const GET_ALL_TIER_COMPANIES = gql`
  query GetAllCompanyTier2(
    $criteria: CompanyTierCriteriaInput!
    $searchFields: FindCompanyTierInput
  ) {
    GetAllCompanyTier2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
        description
      }
      total
    }
  }
`;

export default GET_ALL_TIER_COMPANIES;
