import { useState } from 'react';
import AuditableInfo from './auditableInfo';
import BasicInformation from './basicInformation';
import SUserInfo from './styles/index.styles';
import ChangePasswordModal from './ChangePasswordModal';

const UserInfo = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => setIsOpen(true);
  return (
    <>
      {isOpen ? (
        <ChangePasswordModal modalStatus={isOpen} onClose={() => setIsOpen(false)} />
      ) : null}
      <SUserInfo>
        <h5>Basic information</h5>
        <BasicInformation buttonAction={handleModal} />
        <div className='divider' />
        <AuditableInfo />
      </SUserInfo>
    </>
  );
};
export default UserInfo;
