import { gql } from '@apollo/client';

const GET_ALL_CONTACTS_BY_COMPANY = gql`
  query GetAllContactByCompanyId($companyId: Int!, $criteria: ContactCriteriaInput!) {
    GetAllContactByCompanyId(companyId: $companyId, Criteria: $criteria) {
      total
      results {
        id
        firstName
        lastName
        businessInfo {
          company {
            id
            name
            companyType {
              name
            }
            salesTeam {
              name
            }
          }
          jobTitle
          emails {
            email
          }
          phones {
            phone
          }
        }
      }
    }
  }
`;

export default GET_ALL_CONTACTS_BY_COMPANY;
