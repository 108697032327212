import { FC, ReactElement, useMemo } from 'react';
import { Cascader, Popover } from 'antd';
import { CustomCascaderGlobalStyles } from './customCascader.styles';

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
  disableCheckbox?: boolean;
}
interface CustomCascaderProps {
  children: ReactElement;
  options: Option[];
  handleOnChange?: (value: any[]) => void;
  defaultValue?: any;
}
const CustomCascader: FC<CustomCascaderProps> = ({
  options,
  children,
  handleOnChange,
  defaultValue,
}) => {
  const onChange = (value: any) => {
    if (handleOnChange) {
      handleOnChange(value);
    }
  };

  const tagRender = () => <div />;

  const contentRender = useMemo(
    () => (
      <Cascader
        options={options}
        onChange={onChange}
        multiple
        placement='bottomRight'
        showSearch
        tagRender={tagRender}
        placeholder='Search'
        defaultValue={JSON.parse(defaultValue ?? '{}')}
        getPopupContainer={(triggerNode) =>
          document.getElementById('custom-cascader') || triggerNode
        }
        open
      />
    ),
    [options, defaultValue],
  );
  return (
    <>
      <CustomCascaderGlobalStyles />
      <Popover
        content={contentRender}
        destroyTooltipOnHide
        trigger='click'
        placement='bottomRight'
        arrow={false}
        overlayInnerStyle={{ padding: 0 }}
        id='custom-cascader'
      >
        {children}
      </Popover>
    </>
  );
};
export default CustomCascader;
