import { gql } from '@apollo/client';

export const CREATE_TIER_COMPANY = gql`
  mutation CreateCompanyTier($createCompanyTierInput: CreateCompanyTierInput!) {
    CreateCompanyTier(createCompanyTierInput: $createCompanyTierInput) {
      id
      name
      description
    }
  }
`;

export const UPDATE_TIER_COMPANY = gql`
  mutation UpdateCompanyTier($updateCompanyTierInput: UpdateCompanyTierInput!) {
    UpdateCompanyTier(updateCompanyTierInput: $updateCompanyTierInput) {
      name
      description
    }
  }
`;

export const DELETE_TIER_COMPANY = gql`
  mutation RemoveCompanyTier($id: Int!) {
    RemoveCompanyTier(id: $id)
  }
`;
