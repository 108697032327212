import React, { FC, useEffect, useRef, useState } from 'react';
import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { Form, Input, InputRef } from 'antd';
import StyledPitchForm from '../styles/pitchForm.styles';
import { PitchesForm } from '../types';
import QuillInput from './quillInput';

const PitchForm: FC<PitchesForm> = () => {
  const [files, setFiles] = useState<File[] | undefined>(undefined);
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (!selectedFile) return;
    setFiles((prev) => {
      if (prev) {
        return [...prev, selectedFile];
      }
      return [selectedFile];
    });
  };
  const filterFiles = (idx: number) => {
    if (!files) return;
    const copiedFiles = [...files].filter((_, ind) => ind !== idx);
    setFiles(copiedFiles);
  };

  return (
    <StyledPitchForm>
      <Form.Item
        style={{
          margin: 0,
        }}
        name='name'
        label='Subject'
        className='name'
        rules={[
          {
            required: true,
            message: 'this is a required field',
          },
        ]}
        hasFeedback
      >
        <Input
          ref={inputRef}
          autoComplete='off'
          className='input-new-record-js'
          placeholder='Type here...'
        />
      </Form.Item>
      <div className='wysiwyn-container'>
        <Form.Item
          style={{
            margin: 0,
          }}
          name='text'
        >
          <QuillInput />
        </Form.Item>
      </div>
      <div className='button-container'>
        <label htmlFor='file-upload' className='custom-file-upload'>
          <UploadOutlined style={{ marginRight: '0.3rem' }} /> Upload
          <input
            type='file'
            id='file-upload'
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </label>
        {files?.map((file, idx) => (
          <div className='file-container'>
            <p>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <PaperClipOutlined /> <a>{file.name}</a>
            </p>
            <DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => filterFiles(idx)} />
          </div>
        ))}
      </div>
    </StyledPitchForm>
  );
};

export default PitchForm;
