import React, { useRef, useState, useEffect } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
  FormPhoneElementsType,
  CompaniesHeaderType2,
  SeparateComponentDataHandlerType,
  PhoneModel,
} from 'src/types/companiesTypes';
import CompaniesColumns from 'src/utils/tables/companiesTable';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import CustomPhoneForm from './renderer';

interface CustomPhoneHandlerType {
  required?: boolean;
  separateComponentDataHandler?: (
    data: SeparateComponentDataHandlerType[] | '',
    name?: string,
  ) => void;
  expanded?: boolean;
  country?: string;
  phones?: PhoneModel[] | PhoneModel | undefined;
}

const CustomPhoneHandler: React.FC<CustomPhoneHandlerType> = ({
  required,
  separateComponentDataHandler,
  expanded,
  country,
  phones,
}) => {
  const { companiesHeaderResponse2 } = CompaniesColumns();

  const phonesType: CompaniesHeaderType2 =
    companiesHeaderResponse2.data?.GetCompaniesDepthGridHeader.find(
      (item: CompaniesHeaderType2) => item.dataIndex === 'mainPhone.phoneType',
    );

  const internalPhoneTypes = useRef(
    phonesType?.options?.map((element) => ({
      label: element.name,
      value: String(element.id),
    })) || [],
  );

  const primaryPhoneType = internalPhoneTypes.current?.find((item) =>
    item.label?.toLocaleLowerCase().includes('primary' || 'main'),
  );

  const defaultPhoneType =
    internalPhoneTypes?.current?.length > 0
      ? internalPhoneTypes?.current[0]
      : {
          label: '',
          value: '',
        };

  const formPhoneData = useRef<FormPhoneElementsType[]>([
    {
      formId: 'form-1',
      phoneType: {
        label: primaryPhoneType?.label ?? defaultPhoneType?.label,
        value: primaryPhoneType?.value ?? defaultPhoneType?.value,
      },
      countryCode: '',
      areaCode: '',
      phone: '',
      formatedNumber: '',
      phoneExt: 0,
      validPhone: true,
    },
  ]);
  const [formElements, setFormElements] = useState<FormPhoneElementsType[]>(formPhoneData.current);
  const [phoneRenderAction, setPhoneRenderAction] = useState(false);

  const onInputChange = (formId: React.Key, data: string, ext?: boolean) => {
    const phoneValidation = parsePhoneNumberFromString(data);
    const innerNationalNumber = phoneValidation?.nationalNumber ?? '';
    const index: number = formPhoneData.current.findIndex((item) => item.formId === formId);
    if (ext) {
      formPhoneData.current[index].phoneExt = Number(data);
      separateComponentDataHandler?.(formPhoneData.current, 'phones');
      return;
    }

    formPhoneData.current[index].formatedNumber = data;

    if (innerNationalNumber.length >= 9) {
      formPhoneData.current[index].validPhone = true;
    }
    setPhoneRenderAction(!phoneRenderAction);
  };

  const addNewFormElement = () => {
    const innerElement = formPhoneData.current;
    innerElement.push({
      formId: `form-${innerElement.length + 1}`,
      phoneType: '',
      formatedNumber: '',
      countryCode: '',
      areaCode: '',
      phone: '',
      phoneExt: 0,
      validPhone: true,
    });
    formPhoneData.current = innerElement;
    setPhoneRenderAction(!phoneRenderAction);
  };

  const deletePhoneAction = (elementId: React.Key) => {
    const innerElement = formPhoneData.current;
    const index = innerElement.findIndex((item) => elementId === item.formId);
    innerElement.splice(index, 1);
    formPhoneData.current = innerElement;
    setPhoneRenderAction(!phoneRenderAction);
  };

  const phoneTypeAction = (elementId: React.Key, phoneType: string) => {
    const innerElement = formPhoneData.current;
    const index = innerElement.findIndex((item) => elementId === item.formId);
    const phoneTypeIndex = internalPhoneTypes.current.findIndex((item) => item.value === phoneType);
    innerElement[index].phoneType = {
      label: internalPhoneTypes.current[phoneTypeIndex].label,
      value: internalPhoneTypes.current[phoneTypeIndex].value,
    };
    formPhoneData.current = innerElement;
    setPhoneRenderAction(!phoneRenderAction);
  };

  const dividePhoneData = (data: string) => {
    const innerData = data.split(' ');
    const countryCode = innerData[0];
    const areaCode = innerData[1]?.replace(/\(|\)/g, '');
    const phone = innerData.slice(2).join('').replace(/\(|\)/g, '').replace(/-/g, '');
    return { countryCode, areaCode, phone };
  };

  const onBlurPhoneData = (formId: React.Key, data: string) => {
    const phoneValidation = parsePhoneNumberFromString(data);
    const { countryCode, areaCode, phone } = dividePhoneData(data);
    const innerNationalNumber = phoneValidation?.nationalNumber ?? '';
    const index: number = formPhoneData.current.findIndex((item) => item.formId === formId);
    formPhoneData.current[index].validPhone = innerNationalNumber.length >= 9;
    if (formPhoneData.current[index].validPhone) {
      formPhoneData.current[index].countryCode = countryCode;
      formPhoneData.current[index].areaCode = areaCode;
      formPhoneData.current[index].phone = phone;
    }
    setPhoneRenderAction(!phoneRenderAction);
  };

  useEffect(() => {
    setFormElements(formPhoneData.current);
    if (separateComponentDataHandler) {
      let allowToSave = false;
      const innerValue = formPhoneData.current.map((item, index) => {
        const { phoneType, formatedNumber, validPhone, countryCode, areaCode, phone } = item;
        if (index === 0) {
          allowToSave = formatedNumber.length >= 14 ? validPhone : false;
        }
        return { phoneType, countryCode, areaCode, phone };
      });
      if (allowToSave) {
        separateComponentDataHandler(innerValue, 'phones');
      } else {
        separateComponentDataHandler('', 'phones');
      }
    }
  }, [phoneRenderAction]);

  return (
    <>
      {React.Children.toArray(
        formElements.map((element) => (
          <CustomPhoneForm
            deleteIcon={<DeleteOutlined />}
            elementData={element}
            {...{ addNewFormElement }}
            {...{ deletePhoneAction }}
            {...{ onInputChange }}
            phoneTypes={internalPhoneTypes.current}
            {...{ phoneTypeAction }}
            {...{ required }}
            {...{ expanded }}
            {...{ onBlurPhoneData }}
            {...{ country }}
            phones={phones as PhoneModel}
          />
        )),
      )}
    </>
  );
};

export default CustomPhoneHandler;
