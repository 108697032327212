import SettingsTable from 'src/components/settingsTables';
import SubheaderSettings from 'src/components/commons/subHeader/settings';
import AddDivision from 'src/modules/settings/modules/divisions/components/addDivision';
import useDivisionsSettingsTable from './hooks/useDivisionsSettingsTable';

const Divisions = () => {
  const {
    handleFilterChange,
    columns,
    handleUpdateRows,
    newRecord,
    resetNewRecord,
    postData,
    createNewRecord,
    dataSource,
    onListRender,
    handleDeleteRows,
  } = useDivisionsSettingsTable();

  const headerTitle = 'Divisions';

  return (
    <>
      <SubheaderSettings title={headerTitle} />
      <SettingsTable
        dataSource={dataSource}
        columns={columns}
        columnsTeamMember={columns}
        createNewRecord={createNewRecord}
        handleDeleteRows={handleDeleteRows}
        handleFilterChange={handleFilterChange}
        newRecord={newRecord}
        onListRender={onListRender}
        postData={postData}
        handleUpdateRows={handleUpdateRows}
        resetNewRecord={resetNewRecord}
        title='Division'
        titleModalNew='Add Division'
        BodyModal={AddDivision}
        hasPrimaryContact
        searchPlaceholder={`Search ${headerTitle}`}
        clickable
      />
    </>
  );
};

export default Divisions;
