import React, { FC, useCallback, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Select } from 'antd';
import { GET_FILTER_COMPANIES } from 'src/components/companyDetails/components/companyRelated/table/graphql/queries';
import { GetCompanyUniqueValuesFromColumn } from 'src/components/companyDetails/components/companyRelated/table/types';
import { AllFilters2 } from 'src/graphql/schema-types';

export type Props = {
  postData: (args: { [x: string]: unknown }) => void;
  dataIndex: any;
  newRecord: Record<string, unknown>;
};

const { Option } = Select;

const TypeAheadCompany: FC<Props> = ({ postData, dataIndex, newRecord }) => {
  const [getFilterCompany, { loading }] =
    useLazyQuery<GetCompanyUniqueValuesFromColumn>(GET_FILTER_COMPANIES);
  const [internalOptions, setInternalOptions] = useState<
    { label: string; value: string; id: number }[]
  >([]);

  const filterCompanies = (filter: string) =>
    getFilterCompany({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 100,
          },
          filter: [
            {
              fieldName: 'name',
              value: filter,
              dataType: 'string',
              operator: AllFilters2.Contains,
            },
          ],
        },
      },
    }).then((res) =>
      res.data?.GetCompaniesFromElastic2.results?.map((company) => ({
        label: company.name,
        value: company.id,
      })),
    );

  const handleSearchInSelect = useCallback(async (pattern: string) => {
    const companies = await filterCompanies(pattern);
    const optionsLoadedByPattern =
      companies?.map((option) => ({
        value: option.label,
        label: option.label,
        id: option.value,
      })) || [];

    setInternalOptions([...optionsLoadedByPattern] || []);
  }, []);

  const dataIndexes =
    typeof dataIndex === 'object'
      ? dataIndex.reduce(
          (acc: string, curr: string, idx: number) =>
            acc + (idx === 0 ? curr : curr.charAt(0).toUpperCase() + curr.slice(1).toLowerCase()),
          '',
        )
      : dataIndex;

  const renderOptions = useMemo(
    () =>
      internalOptions?.map((option) => (
        <Option key={option.label} value={option.value} label={option.label}>
          {option.label}
        </Option>
      )),
    [internalOptions],
  );

  return (
    <Select
      style={{ width: '100%' }}
      showSearch
      onSearch={handleSearchInSelect}
      defaultValue={newRecord[dataIndexes] as unknown as string}
      onChange={(companyName: string) => {
        const internalOption = internalOptions.filter((option) => option.label === companyName);

        postData({ value: internalOption, dataIndex: dataIndexes });
      }}
      optionLabelProp='label'
      loading={loading}
    >
      {renderOptions}
    </Select>
  );
};

export default TypeAheadCompany;
