import { PlusOutlined } from '@ant-design/icons';
import { AutoComplete, Button, DatePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import GlobalCreate from 'src/components/commons/customHeader/GlobalCreate';
import { OptionType } from 'src/components/commons/customSelect';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import FormSection from 'src/components/formSection';
import useActivityJournalsStore from 'src/hooks/stores/useActivityJournalsStore';
import useCompanyStore from 'src/hooks/stores/useCompanyStore';
import useContactsStore from 'src/hooks/stores/useContactsStore';
import useDebouncedCallback from 'src/hooks/useDebouncedCallback';
import { getUser } from 'src/utils/functions/getUser';

type Props = {
  showTitle?: boolean;
  setAttendeesAndCompanies?: React.Dispatch<React.SetStateAction<any[] | []>>;
};

interface InterfaceInputText {
  field?: FieldType;
  handleSearch?: (value: any) => void;
  handleChange?: (value: any, opt: any) => void;
  placeholder?: string;
  title?: string;
  options?: OptionType[];
  loading: boolean;
  value: string;
}

const optionsPayload = (filter: any[]) => ({
  variables: {
    criteria: {
      pagination: {
        from: 0,
        size: 25,
      },
      filter,
    },
  },
});

const DateInput: FC<{ onChange: (date: string) => void; value: any }> = ({ onChange, value }) => (
  <DatePicker
    format='MM/DD/YYYY'
    defaultValue={dayjs(value as string)}
    style={{ width: '100%', zIndex: 1070 }}
    onChange={(date, dateString) => onChange(dateString)}
  />
);

const InputText: FC<InterfaceInputText> = ({
  field,
  handleSearch,
  placeholder,
  title,
  options: opt,
  loading: load,
  handleChange,
  value,
}) => {
  const [createNew, setCreateNew] = useState<'company' | 'contact' | null>(null);
  const [options, setOption] = useState(opt);
  const [loading, setLoading] = useState(load);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setOption(opt);
  }, [opt]);

  useEffect(() => {
    setLoading(load);
  }, [load]);

  useEffect(() => {
    if (value === '') setSearch('');
  }, [value]);

  return (
    <div>
      <GlobalCreate mode={createNew} onClose={() => setCreateNew(null)} />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p>{title}</p>
        <Button
          onClick={() =>
            setCreateNew(title?.toLocaleLowerCase().includes('company') ? 'company' : 'contact')
          }
          type='link'
          style={{ padding: 0 }}
        >
          <PlusOutlined /> New
        </Button>
      </div>
      <AutoComplete
        showArrow={false}
        placeholder={placeholder ?? 'Type here...'}
        onSearch={(val) => {
          setSearch(val);
          handleSearch?.(val);
        }}
        options={loading ? ([{ value: 'Loading...' }] as OptionType[]) : options}
        showAction={['focus']}
        value={search}
        onChange={(_, option) => {
          handleChange?.(_, option);
          setSearch('');
        }}
        disabled={field?.disabled}
        showSearch
        notFoundContent='No results'
        virtual
      />
    </div>
  );
};

const PrimaryInformation: React.FC<Props> = ({ showTitle = true, setAttendeesAndCompanies }) => {
  const { reset } = useActivityJournalsStore();
  const { fetchAll: getContacts } = useContactsStore();
  const { fetchAll: getCompanies } = useCompanyStore();

  const [companies, setCompanies] = useState<OptionType[] | []>([]);
  const [contacts, setContacts] = useState<OptionType[] | []>([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<OptionType[] | []>([]);
  const [searchedCompany, setSearchedCompany] = useState('');
  const [searchedContact, setSearchedContact] = useState('');

  // TODO: In the future getUser funct should be in the getUsersStore
  // TODO: Delete this function when it happens
  const getAllUsers = () => {
    const user = getUser();
    const obj = {
      value: user.fullName,
      label: user.fullName,
      toSend: user.id,
    };
    setUsers([obj]);
  };

  useEffect(() => {
    setLoading(true);
    getContacts(optionsPayload([])).then((res) => {
      if (res.results) {
        const options: OptionType[] = res.results.map((data: any) => ({
          toSend: data.id,
          value: `${data.firstName} ${data.lastName}`,
          label: `${data.firstName} ${data.lastName}`,
          jobTitle: data?.businessInfo?.jobTitle,
          company: {
            toSend: data?.businessInfo?.company?.id,
            value: data?.businessInfo?.company?.name,
            label: data?.businessInfo?.company?.name,
          },
        }));
        setContacts(options);
      }
    });
    getCompanies(optionsPayload([])).then((res) => {
      if (res.results) {
        const options: OptionType[] = res.results.map((data: any) => ({
          toSend: data.id,
          value: data.name,
          label: data.name,
        }));
        setCompanies(options);
      }
    });
    reset();
    getAllUsers();
    setLoading(false);
  }, []);

  const filterContact = async (value: any) => {
    setLoading(true);
    setSearchedContact(value);
    getContacts(
      optionsPayload([
        {
          dataType: 'string',
          fieldName: 'fullName',
          operator: 'CONTAINS',
          value,
        },
      ]),
    )
      .then((res) => {
        if (res.results) {
          const options: OptionType[] = res.results.map((data: any) => ({
            toSend: data.id,
            value: `${data.firstName} ${data.lastName}`,
            label: `${data.firstName} ${data.lastName}`,
            jobTitle: data?.businessInfo?.jobTitle,
            company: {
              toSend: data?.businessInfo?.company?.id,
              value: data?.businessInfo?.company?.name,
              label: data?.businessInfo?.company?.name,
            },
          }));
          setContacts(options);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filterCompany = async (value: any) => {
    setLoading(true);
    setSearchedCompany(value);
    getCompanies(
      optionsPayload([
        {
          dataType: 'string',
          fieldName: 'name',
          operator: 'CONTAINS',
          value,
        },
      ]),
    )
      .then((res) => {
        if (res.results) {
          const options: OptionType[] = res.results.map((data: any) => ({
            toSend: data.id,
            value: data.name,
            label: data.name,
          }));
          setCompanies(options);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const selectedCompany = (val: any, opt?: any) => {
    if (!opt.value) return;
    setAttendeesAndCompanies?.((prev) => [
      ...prev,
      {
        company: opt,
        contact: [],
      },
    ]);
    filterCompany('');
  };

  const selectedContact = (_val: any, opt?: any) => {
    if (!opt.value) return;
    setAttendeesAndCompanies?.((prev) => [
      ...prev,
      {
        company: opt?.company ?? {},
        contact: [opt],
      },
    ]);
    filterContact('');
  };

  const filterCompanyDebounce = useDebouncedCallback(filterCompany, 300);
  const filterContactDebounce = useDebouncedCallback(filterContact, 300);

  const fields: FieldType<any>[] = [
    {
      name: 'date',
      label: 'Date',
      type: 'date',
      span: 24,
      render: ({ onChange, value }) => (
        <DateInput onChange={onChange!} value={value ?? new Date()} />
      ),
    },
    {
      name: 'user',
      label: 'User',
      type: 'select',
      initialValue: getUser().fullName,
      options: users,
      span: 24,
    },
    {
      name: 'company',
      type: '',
      span: 24,
      suffixButton: (
        <Tooltip title='Add to Company List' color='gray'>
          <PlusOutlined />
        </Tooltip>
      ),
      suffixType: 'square',
      onSuffixButtonClick() {
        const val = {
          toSend: searchedCompany,
          value: searchedCompany,
          label: searchedCompany,
        };
        selectedCompany('', val);
      },
      onChange: (val) => filterCompanyDebounce(val),
      render: ({ onChange }) => (
        <InputText
          options={companies}
          handleChange={selectedCompany}
          handleSearch={onChange}
          title='Company:'
          loading={loading}
          value={searchedCompany}
        />
      ),
    },
    {
      name: 'attendee',
      type: '',
      suffixButton: (
        <Tooltip title='Add to Attendee List' color='gray'>
          <PlusOutlined />
        </Tooltip>
      ),
      suffixType: 'square',
      span: 24,
      onSuffixButtonClick() {
        const val = {
          toSend: searchedContact,
          value: searchedContact,
          label: searchedContact,
        };
        selectedContact('', val);
      },
      onChange: (val) => filterContactDebounce(val),
      render: ({ onChange }) => (
        <InputText
          options={contacts}
          handleChange={selectedContact}
          handleSearch={onChange}
          title='Attendees:'
          loading={loading}
          value={searchedContact}
        />
      ),
    },
  ];

  if (!showTitle) {
    return <GenericForm fields={fields} />;
  }

  return (
    <FormSection title='Custom Fields'>
      <GenericForm fields={fields} />
    </FormSection>
  );
};

export default PrimaryInformation;
