import CustomFooter from 'src/components/commons/customFooter';
import CustomViewContainer from 'src/components/commons/customViewContainer';
import CompanyContent from 'src/components/companyDetails/components/companyContent';
import CompanyHeader from 'src/components/companyDetails/components/companyHeader';
import CompanyContextProvider from 'src/context/company.context';
import { GlobalContextProvider } from 'src/context/global.context';

const CompanySetupLayout = () => (
  <GlobalContextProvider>
    <CustomViewContainer>
      <CompanyContextProvider>
        <CompanyHeader />
        <CompanyContent />
        <CustomFooter />
      </CompanyContextProvider>
    </CustomViewContainer>
  </GlobalContextProvider>
);

export default CompanySetupLayout;
