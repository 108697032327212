import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

interface CustomPopoverProps {
  children: ReactNode;
  color?: string;
  title: string;
  placement?: TooltipPlacement;
  overlayClassName?: string;
  overlayInnerStyle?: object;
}

const CustomToolTip: React.FC<CustomPopoverProps> = ({
  children,
  color,
  title,
  placement,
  overlayClassName,
  overlayInnerStyle,
}) => (
  <Tooltip
    title={title}
    color={color}
    placement={placement || 'top'}
    overlayClassName={overlayClassName}
    overlayInnerStyle={overlayInnerStyle}
  >
    {children}
  </Tooltip>
);

export default CustomToolTip;
