import styled from 'styled-components';

const StyledParentCompanyInput = styled.div`
  position: relative;

  .reset-button {
    position: absolute;
    right: 8px;
    top: 5px;
    color: #a6a6a6;

    &:hover {
      cursor: pointer;
      color: #000;
    }
  }
`;

export default StyledParentCompanyInput;
