import { useState } from 'react';
import { SubTableColumn } from 'src/components/commons/subTable/types';

export const COLUMNS: SubTableColumn[] = [
  {
    title: 'NAME',
    dataIndex: 'name',
    editable: true,
    dataType: 'string',
    filterable: true,
    hidden: false,
    dataStructure: 'string',
    required: true,
    searchField: 'names',
    headerName: 'Name',
  },
];

export const useProductLinesHeaders = () => {
  const [columns, setColumns] = useState(COLUMNS);

  return {
    columns,
    setColumns,
  };
};
