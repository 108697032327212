import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { Option, SortModel } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { AllFilters2, CountryDbModel } from 'src/graphql/schema-types';
import COLUMNS from '../constants';
import { GetAllCountriesResponse, GetAllCountries } from '../types';
import GET_ALL from '../graphql/queries';
import { CREATE_COUNTRY, DELETE_COUNTRY, UPDATE_COUNTRY } from '../graphql/mutations';

const useCountryTable = () => {
  const [load, queryResponse] = useLazyQuery<GetAllCountries>(GET_ALL, {
    fetchPolicy: 'network-only',
  });

  const [loadFilter] = useLazyQuery<GetAllCountries>(GET_ALL, {
    fetchPolicy: 'network-only',
  });

  const [updateData] = useMutation(UPDATE_COUNTRY);
  const [createData] = useMutation(CREATE_COUNTRY);
  const [removeData] = useMutation(DELETE_COUNTRY);
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 1000 });
  const [columns, setColumns] = useState(COLUMNS);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newRecord, setNewRecord] = useState({
    name: '',
  });

  const uploadData: FetchFunc = ({ start, renderLen }) =>
    load({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllCountries2.results || [],
      total: res.data?.GetAllCountries2.total || 0,
    }));

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(uploadData);

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) =>
    loadFilter({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },

          filter: [
            {
              fieldName: searchField,
              value: search,
              dataType,
              operator: AllFilters2.Contains,
            },
          ],
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => {
      if (res.data) {
        return {
          results: res?.data?.GetAllCountries2.results as any[],
          filter: res?.data?.GetAllCountries2.results?.map((result: GetAllCountriesResponse) => ({
            value: result[searchField],
            label: result[searchField],
          })) as Option[],
        };
      }

      return {
        results: [],
        filter: [],
      };
    });

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
    });
  };

  const createNewRecord = async () => {
    const { name } = newRecord;
    if (name === '') return;
    await createData({
      variables: {
        createCountryInput: {
          name: capitalizeFirstWord(name),
        },
      },
    }).then(() => {
      setTimeout(() => {
        reset();
      }, 500);
    });
  };

  const handleUpdateRows = (rows: CountryDbModel[]) => {
    rows.forEach((row) => {
      const { id, name } = row;
      updateData({
        variables: {
          updateCountryInput: {
            id,
            name: capitalizeFirstWord(name!),
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: CountryDbModel[]) => {
    const removeId = rows[0].id;

    try {
      removeData({ variables: { id: removeId } }).then(() => {
        setTimeout(() => {
          reset();
        }, 500);
      });
    } catch (error) {
      console.error('Error deleting records:', error);
    }
  };

  const handleClearFilter = () => {
    reset();
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = async (sortModel: SortModel | undefined) => {
    if (!sortModel) {
      // Load original data without any sorting
      try {
        const response = await load({
          variables: {
            criteria: {
              pagination: {
                from: (pagination.page - 1) * pagination.pageSize,
                size: pagination.pageSize,
              },
            },
          },
        });

        // Update data source with the original data
        if (response.data && response.data.GetAllCountries2) {
          setDataSource(response.data.GetAllCountries2.results);
        }
      } catch (error) {
        // Handle the error scenario
        console.error('Failed to fetch original data:', error);
      }
      return; // Early return to skip sorting if sortModel is undefined
    }

    // Check if sortBy is "parent" and change it to "parent_name"
    const sortByField = sortModel.sortBy === 'parent' ? 'parent_name' : sortModel.sortBy;

    try {
      // Update pagination with the new sort model
      setPagination((prev) => ({
        ...prev,
        sort: sortModel,
      }));

      // Fetch sorted data
      const response = await load({
        variables: {
          criteria: {
            pagination: {
              from: (pagination.page - 1) * pagination.pageSize,
              size: pagination.pageSize,
            },
            orderBy: [
              {
                field: sortByField,
                sort: sortModel.sort.toUpperCase(),
              },
            ],
          },
        },
      });

      // Update data source with new sorted data
      if (response.data && response.data.GetAllCountries2) {
        setDataSource(response.data.GetAllCountries2.results);
      }
    } catch (error) {
      // Handle the error scenario
      console.error('Failed to fetch sorted data:', error);
    }
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    onListRender,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    spinning: queryResponse.loading,
    postData,
    newRecord,
    createNewRecord,
    handleUpdateRows,
    resetNewRecord,
    dataSource,
    handleClearFilter,
    handleDeleteRows,
  };
};

export default useCountryTable;
