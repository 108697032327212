import { gql } from '@apollo/client';

export const UPDATE_REGION_REGION = gql`
  mutation UpdateRegion($updateRegionInput: UpdateRegionInput!) {
    UpdateRegion(updateRegionInput: $updateRegionInput) {
      id
    }
  }
`;

export const CREATE_REGION_REGION = gql`
  mutation CreateRegion($createRegionInput: CreateRegionInput!) {
    CreateRegion(createRegionInput: $createRegionInput) {
      id
      name
    }
  }
`;

export const REMOVE_REGION = gql`
  mutation RemoveRegion($id: Int!) {
    RemoveRegion(id: $id)
  }
`;
