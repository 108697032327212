import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete } from 'antd';
import { useState } from 'react';
import StyledSearchInput from '../styles/searchInput.style';

export type Option = {
  pinned?: boolean;
  value: string;
  label: string;
};

const GlobalSearch = (globalSearchProps: any) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [currentValue, setCurrentValue] = useState('');
  const { placeholder, onSearch, autoCompleteGlobalProvider, onClear } = globalSearchProps;

  const performSearch = async (value: string) => {
    const innerResult = await autoCompleteGlobalProvider(value);
    const opts = innerResult;
    setOptions(
      opts.filter((e: { label: string }) => e.label.toLowerCase().includes(value.toLowerCase())),
    );
  };

  const handleSearch = async (value: string) => {
    setCurrentValue(value);
    if (!value) {
      setOptions([]);
      return;
    }
    performSearch(value);
  };

  const handleClear = async () => {
    setCurrentValue('');
    await onClear();
  };

  const handleSelect = async (value: string) => {
    await onSearch(value);
    setCurrentValue(value);
  };

  const handlePressEnter = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (!value) {
      onClear();
    } else {
      onSearch(value);
    }
    setCurrentValue(value);
  };

  return (
    <AutoComplete
      data-testid='subtable-global-search'
      style={{ width: '100%' }}
      options={options}
      onSearch={handleSearch}
      onSelect={handleSelect}
      onClear={handleClear}
      value={currentValue}
      allowClear
    >
      <StyledSearchInput
        placeholder={placeholder || 'Search'}
        prefix={<SearchOutlined />}
        onPressEnter={handlePressEnter} // Listening for the enter key press
      />
    </AutoComplete>
  );
};

export default GlobalSearch;
