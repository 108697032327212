import { gql } from '@apollo/client';

export const CREATE_RELATED_COMPANY = gql`
  mutation CreateAdditionalField($createAdditionalFieldInput: CreateAdditionalFieldInput!) {
    CreateAdditionalField(createAdditionalFieldInput: $createAdditionalFieldInput) {
      id
      name
      module
      description
      dataStructure
      dataType
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_RELATED_COMPANY = gql`
  mutation UpdateAdditionalField($updateAdditionalFieldInput: UpdateAdditionalFieldInput!) {
    UpdateAdditionalField(updateAdditionalFieldInput: $updateAdditionalFieldInput) {
      id
      name
      module
      dataStructure
      dataType
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_RELATED_COMPANY = gql`
  mutation RemoveAdditionalField($removeAdditionalFieldId: Int!) {
    RemoveAdditionalField(id: $removeAdditionalFieldId)
  }
`;
