import styled from 'styled-components';

export const StyledPrimaryButton = styled.div<{ isActive?: boolean }>`
  && button {
    background-color: ${(props) => (props.isActive ? '#0078d4' : 'rgba(0, 0, 0, 0.1)')};
    color: white;
    height: auto;
    padding: 2px 25px;
  }
`;

export const StyledSecundaryButton = styled.div`
  .ant-btn {
    color: black;
    height: auto;
    padding: 2px 25px;
    border: 1px solid rgba(0, 0, 0, 0.3) !important;
  }
`;
