import { Form, Tooltip } from 'antd';
import { ComponentType, FC, ReactNode, HTMLAttributes } from 'react';
import EditableProvider from '../contexts/editable.context';

interface EditableRowProps extends HTMLAttributes<HTMLTableRowElement> {
  index: number;
  children?: ReactNode;
}
const getEditableRowComponent = (
  TrComponent: ComponentType<unknown> | string,
  rowTooltip: boolean,
) => {
  const EditableRow: FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();

    const { children } = props;
    let name = '';
    if (children && Array.isArray(children) && children.length > 0) {
      name = children[0].props.record.name;
    }

    return (
      <Form form={form} component={false}>
        <EditableProvider form={form}>
          <Tooltip title={rowTooltip ? name : null} className='test'>
            <TrComponent {...props} />
          </Tooltip>
        </EditableProvider>
      </Form>
    );
  };

  return EditableRow;
};

export default getEditableRowComponent;
