// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { SubTableColumn } from 'src/components/commons/subTable/types';
import { GET_CONTACT_HEADERS } from 'src/graphql';
import { ContactModel } from 'src/models';

function getNestedProperty(obj: ContactModel, path: string | number | string[]) {
  return path
    ?.toString()
    ?.split('.')
    ?.reduce((acc: { [x: string]: any }, part: string | number) => acc && acc[part], obj);
}

const useContactHeaders = () => {
  const [initialColumns, setInitialColumns] = useState<SubTableColumn[]>([]);

  useQuery(GET_CONTACT_HEADERS, {
    onCompleted: (data) => {
      const cols = data.GetContactsGridHeader.filter(
        (c: SubTableColumn) => c.gridVisible !== false,
      ).map((column: SubTableColumn) => ({
        ...column,
        title: column.headerName,
        minWidth: 250,
        width: 250,
        ellipsis: false,
        render: (_value: string, record: ContactModel) => {
          const dataToRender = getNestedProperty(record, column.dataIndex);
          if (column.key) {
            return column.dataIndex === 'businessInfo.mainPhone' ? (
              <a href={`tel:+${dataToRender?.[column.key]}`}>{dataToRender?.[column.key]}</a>
            ) : (
              <p>{dataToRender?.[column.key]}</p>
            );
          }
          // this is a special case for the fullName column, which does not contain data and its data needs to be built from the firstName and lastName columns.
          if (column.relabelIndex === 'relabelIndexForContactGridMainAddressfullName') {
            return <p>{`${record?.firstName ?? ''} ${record?.lastName ?? ''}`}</p>;
          }
          return <p />;
        },
      }));
      setInitialColumns(cols);
    },
  });

  const visibleHeaders = initialColumns.filter((column) => !column.hidden);
  const selectOptions = initialColumns.map((column) => ({
    label: column.title,
    value: [column.dataIndex, column.key].filter(Boolean).join('.'),
  }));

  return { initialColumns, setInitialColumns, visibleHeaders, selectOptions };
};

export default useContactHeaders;
