import { useContext } from 'react';
import { CompaniesContext } from 'src/context/companies.context';
import { Button } from 'antd';
import CustomButton from '../customButton';
import { StyledPrimaryButton, StyledSecundaryButton } from './styles/styledFooterButton';

const FooterButtonsModal = () => {
  const { activeMapSearch, setActiveMapSearch, setOpenMap } = useContext(CompaniesContext);
  return (
    <>
      <StyledSecundaryButton>
        <Button type='default' ghost onClick={() => setOpenMap(false)}>
          Cancel
        </Button>
      </StyledSecundaryButton>
      <StyledPrimaryButton isActive={activeMapSearch}>
        <CustomButton
          title='Search'
          disabled={!activeMapSearch}
          type='primary'
          ghost
          onClick={() => setActiveMapSearch(false)}
          dataTestId='search-location-button'
        />
      </StyledPrimaryButton>
    </>
  );
};

export default FooterButtonsModal;
