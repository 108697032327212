import { UserOutlined } from '@ant-design/icons';
import { FC } from 'react';
import StyledAvatar from './index.styles';

interface IAvatar {
  img: string;
  customStyles?: { [x: string]: string };
  customImageStyles?: { [x: string]: string };
  name?: string;
}

const Avatar: FC<IAvatar> = ({ img, customStyles, customImageStyles, name }) => (
  <StyledAvatar>
    <div className='container-styles' style={{ ...customStyles }}>
      {img ? (
        <img
          src={img}
          alt={img}
          style={{ ...customImageStyles }}
          className='img-styles'
          data-testid='avatar'
        />
      ) : (
        <UserOutlined
          className='svg-styles'
          style={{ ...customImageStyles }}
          data-testid='avatar'
        />
      )}
    </div>
    <p>{name}</p>
  </StyledAvatar>
);
export default Avatar;
