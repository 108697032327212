import styled from 'styled-components';
import { Menu } from 'antd';

const StyledMenu = styled(Menu)`
  &&& {
    background: transparent;
    border-bottom: none;
    .ant-menu-item-active {
      color: black;
    }
    li {
      color: black;
      height: 35px;
      border-radius: 8px !important;
      &:hover {
        color: black !important;
        background-color: #e4e4e4 !important;
        div {
          color: #0078d4 !important;
        }
      }
      &:active {
        color: black !important;
        background-color: gray !important;
      }
      &:after {
        border-bottom: 0 !important;
      }
      div {
        color: white;
        width: 100% !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;
      }
    }
    span {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }
  }
`;

export default StyledMenu;
