import { gql } from '@apollo/client';

const GET_COMPANY = gql`
  query GetCompany($id: Int!) {
    GetCompany(id: $id) {
      id
      name
      website
      addresses {
        id
        addressType {
          id
          name
          description
          isCompany
          isContact
        }
        zipCode
        region {
          id
          name
        }
        territory {
          id
          name
        }
        country {
          id
          name
        }
        state {
          id
          name
        }
        city
        pobox
        address
        formattedAddress
        isAddressInvalid
      }
      mainContact {
        id
        lastName
        firstName
        title
        businessInfo {
          mainEmail {
            email
          }
          mainPhone {
            phone
          }
        }
      }
      mainAddress {
        id
        addressType {
          id
        }
        address
        zipCode
        pobox
        city
        country {
          id
          name
        }
        state {
          id
          name
        }
        region {
          id
          name
        }
        territory {
          id
          name
        }
        geopositionLatitude
        geopositionLongitude
      }
      mainEmail {
        email
        emailType {
          id
          name
          description
        }
        id
      }
      mainPhone {
        areaCode
        countryCode
        extension
        id
        phone
        phoneType {
          id
          isCompany
          isContact
          name
          description
        }
      }
      salesTeam {
        id
        name
      }
      phones {
        id
        phone
        areaCode
        countryCode
        extension
        phoneType {
          id
        }
      }
      emails {
        id
        email
        emailType {
          id
          name
          description
        }
      }
      companyType {
        id
        name
      }
      comments
      industries {
        id
        name
      }
      productPotentials {
        id
        name
      }
      tags {
        id
        name
      }
      privateTeam {
        id
        name
      }
      parent {
        name
        id
      }
      callPattern {
        id
        name
      }
      category {
        id
        name
      }
      shipDetail
      printName
      isActive
      hasPosAccount
      hasHolydayCard
      visitFrequency
      rank {
        id
        name
      }
      salesTeam {
        id
        name
        type {
          id
          name
        }
      }
    }
  }
`;

export default GET_COMPANY;
