import { Button } from 'antd';
import styled from 'styled-components';

const StyledAddButton = styled(Button)<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#EEEEEE' : '#0078d4')};
  background-color: #0078d4;
  box-shadow: ${(props) =>
    props.active
      ? 'none'
      : '12px 23px 10px rgba(0, 0, 0, 0.05), 7px 13px 9px rgba(0, 0, 0, 0.15), 1px 6px 6px rgba(0, 0, 0, 0.15), 1px 1px 4px rgba(0, 0, 0, 0.25)'};
  padding: 0;
  font-size: 24px;
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
  min-height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #dcdffb !important;
    color: #0078d4;
  }
  span {
    width: max-content;
    height: max-content;
    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: ${(props) => (props.active ? '#C6C4C4' : '#ffffff')};
      &:hover {
        fill: #0078d4;
      }
    }
  }
`;

export default StyledAddButton;
