import SettingsTable from 'src/components/settingsTables';
import SubheaderSettings from 'src/components/commons/subHeader/settings';
import usePrivateTeamsSettingsTable from './hooks/usePrivateTeamsSettingsTable';
import AddPrivateTeam from './components/addPrivateTeam';

const PrivateTeam = () => {
  const {
    handleFilterChange,
    columns,
    handleUpdateRows,
    newRecord,
    resetNewRecord,
    postData,
    createNewRecord,
    dataSource,
    onListRender,
    handleDeleteRows,
  } = usePrivateTeamsSettingsTable();

  const headerTitle = 'Private Teams';

  return (
    <>
      <SubheaderSettings title={headerTitle} />
      <SettingsTable
        dataSource={dataSource}
        columns={columns}
        columnsTeamMember={columns}
        createNewRecord={createNewRecord}
        handleDeleteRows={handleDeleteRows}
        handleFilterChange={handleFilterChange}
        newRecord={newRecord}
        onListRender={onListRender}
        postData={postData}
        handleUpdateRows={handleUpdateRows}
        resetNewRecord={resetNewRecord}
        title='Team for'
        titleModalNew='New Private Team'
        titleOnDelete='Are you sure you want to delete this Private Team?'
        contentOnDelete=''
        BodyModal={AddPrivateTeam}
        hasPrimaryContact
        searchPlaceholder={`Search ${headerTitle}`}
        clickable
      />
    </>
  );
};

export default PrivateTeam;
