import React from 'react';
import { Typography, Form } from 'antd';
import CustomSelect from 'src/components/commons/customSelect';
import { CreateCompanyBodyRendererTypes } from '../types';
import isRequired, { getOptions, parseEventFromHeader } from '../utils/functions';

const IndustryRenderer: React.FC<CreateCompanyBodyRendererTypes> = ({
  formElementsOptions,
  handleMultiSelection,
}) => (
  <div style={{ marginBottom: '49px' }}>
    <Typography.Title level={4} className='section-title'>
      Industries{' '}
    </Typography.Title>
    <Form.Item name='industriesId' required={isRequired(formElementsOptions, 'industries')}>
      <CustomSelect
        customClassName='customSelect'
        handleMultipleSelect={(value, name) => {
          const event = parseEventFromHeader(value, 'industries') as unknown as string[];
          if (handleMultiSelection) {
            handleMultiSelection(event, name);
          }
        }}
        allowClear
        multiSel
        externalWidth='100%'
        listDefault={getOptions(formElementsOptions, 'industries').map((item) => item.label)}
        externalName='industriesId'
        dataTestId='industry-select'
      />
    </Form.Item>
  </div>
);

export default IndustryRenderer;
