import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { GET_LOGO } from 'src/graphql/queries/clientInformation';
import base64Decode from 'src/utils/functions/base64Decode';

const useClientinfo = () => {
  const [logo, setLogo] = useState<string>('');

  useQuery(GET_LOGO, {
    onCompleted: (data) => {
      setLogo(base64Decode(data.GetLogo));
    },
  });

  return { setLogo, logo };
};

export default useClientinfo;
