import { gql } from '@apollo/client';

export const GET_ALL_TERRITORIES = gql`
  query GetAllTerritories2($criteria: TerritoryCriteriaInput!, $searchFields: FindTerritoryInput) {
    GetAllTerritories2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
      }
      total
    }
  }
`;

export const GET_TERRITORY_HEADERS = gql`
  query Query {
    GetTerritoryGridHeaders
  }
`;
