import { gql } from '@apollo/client';

export const CREATE_OPP_LINE_ITEM_STATUS = gql`
  mutation CreateOpportunityLineItemStatus($createLineItemStatusInput: CreateLineItemStatusInput!) {
    CreateOpportunityLineItemStatus(createLineItemStatusInput: $createLineItemStatusInput) {
      boolean
      id
      name
    }
  }
`;

export const UPDATE_OPP_LINE_ITEM_STATUS = gql`
  mutation UpdateOpportunityLineItemStatus($updateLineItemStatusInput: UpdateLineItemStatusInput!) {
    UpdateOpportunityLineItemStatus(updateLineItemStatusInput: $updateLineItemStatusInput) {
      name
      id
      boolean
    }
  }
`;

export const REMOVE_OPP_LINE_ITEM_STATUS = gql`
  mutation RemoveOpportunityLineItemStatus($id: Int!) {
    RemoveOpportunityLineItemStatus(id: $id)
  }
`;
