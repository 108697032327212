import { useEffect, useMemo, useState } from 'react';
import { useBlocker } from 'react-router-dom';
import { confirmModal } from 'src/components/commons/customModal';
import { showConfirmationMessage } from 'src/components/commons/subTableV1/helpers';
import { Modal } from 'antd';

/**
 * Custom hook to warn users about unsaved changes when navigating away from the current page.
 *
 * @param {boolean} useModal - Flag to determine whether to use a modal for the confirmation message.
 * @param {Function} handleSave - Optional callback function to handle saving changes.
 * @returns {object} - An object containing state and setter functions for managing the warning.
 */
const useUnSaveChangesWarning = (useModal?: boolean, handleSave?: () => void) => {
  const [showExitPrompt, setShowExitPrompt] = useState(false); // State to control the display of the exit prompt
  const [proccessSaving, setProccessSaving] = useState(false); // State to indicate if saving process is ongoing
  const [confirmSaving, setConfirmSaving] = useState(false); // State to confirm saving action

  // Blocker to prevent navigation away from the current page
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      showExitPrompt && currentLocation.pathname !== nextLocation.pathname,
  );

  // Function to destroy all modals
  const destroyAll = () => {
    Modal.destroyAll();
  };

  // Handler for the "OK" button in the confirmation modal
  const handleOkButton = () => {
    if (!proccessSaving) {
      if (blocker.proceed) {
        blocker.proceed();
      }
    } else {
      setConfirmSaving(true);
      setProccessSaving(false);
    }
    setShowExitPrompt(false);
  };

  // Handler for the "Cancel" button in the confirmation modal
  const handleCancelButton = () => {
    setShowExitPrompt(false);
    setProccessSaving(false);
  };

  // Handler for canceling the confirmation message
  const handleCancelConfirmationMessage = (source: any) => {
    if (source === 'cancel') {
      setShowExitPrompt(false);
      setProccessSaving(false);
      if (blocker.proceed) {
        destroyAll();
        blocker.proceed();
      }
    }
  };

  // Effect to handle the browser's "beforeunload" event to warn about unsaved changes
  useEffect(() => {
    console.log(showExitPrompt, 'showExitPrompt');
    const handleTabClose = (event: any) => {
      if (!showExitPrompt) return '';
      event.preventDefault();
      // eslint-disable-next-line no-return-assign
      return (event.returnValue = '');
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [showExitPrompt]);

  // Function to display the confirmation modal using custom modal
  const confirmationModal = () =>
    confirmModal(
      proccessSaving
        ? 'Do you want to save these changes?'
        : 'There are unsaved changes. Are you sure you want to leave the page?',
      ' ',
      () => {
        if (handleSave) {
          handleSave();
          setProccessSaving(false);
        } else {
          handleOkButton();
        }
      },
      () => handleCancelButton(),
      'Yes',
      'No',
    );

  // Content to display in the custom confirmation message
  // ---------> NOTE: The confirmation Message part was added to handle the confirmation message in Opp Detail since it has a specific logic
  const contentOnDelete = (
    <div
      style={{
        background: '#FFFCEB',
        border: '1px solid #FAAD14',
        padding: '10px 10px',
        borderRadius: '5px',
      }}
    >
      You have unsaved changes in this record. Would you like to save before leaving this page?
    </div>
  );

  // Function to display the confirmation message using showConfirmationMessage
  const confirmationMessage = () =>
    showConfirmationMessage({
      title: 'Are you sure?',
      content: contentOnDelete,
      onOk: () => {
        if (handleSave) {
          handleSave();
          setProccessSaving(false);
          if (blocker.proceed) {
            destroyAll();
            blocker.proceed();
          }
        } else {
          handleOkButton();
        }
      },
      onCancel: handleCancelConfirmationMessage,
      okText: 'Save',
      closable: true,
      cancelText: 'Discard',
      useOnClick: true,
    });

  // Effect to show the appropriate confirmation based on the blocker state and saving process
  useEffect(() => {
    if (blocker.state === 'blocked' || proccessSaving) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      useModal ? confirmationMessage() : confirmationModal();
    }
  }, [blocker, proccessSaving]);

  // Memoized variables to be returned from the hook
  const variables = useMemo(
    () => ({
      showExitPrompt,
      proccessSaving,
      confirmSaving,
      setShowExitPrompt,
      setProccessSaving,
      setConfirmSaving,
    }),
    [
      showExitPrompt,
      proccessSaving,
      confirmSaving,
      setShowExitPrompt,
      setProccessSaving,
      setConfirmSaving,
    ],
  );

  return variables;
};

export default useUnSaveChangesWarning;
