import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { GetAllSalesTeamModel } from 'src/models';
import { optional, list } from './common';
import companySplitTypeAdapter from './companySplitType.adapter';
import salesTeamTypeAdapter from './salesTeamType.adapter';
import salesTeamMemberAdapter from './salesTeamMemberModel.adapter';

const getAllSalesTeamAdapter: AdapterFunc<GQL.GetAllSalesTeamModel, GetAllSalesTeamModel> = (
  input,
) => ({
  id: input.id,
  companySplit: input.companySplit!,
  companySplitType: optional(companySplitTypeAdapter, input.companySplitType),
  name: input.name!,
  specifierSplit: input.specifierSplit!,
  type: optional(salesTeamTypeAdapter, input.type),
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
  leaderSalesTeamId: input.leaderSalesTeamId!,
  teamMembers: list(salesTeamMemberAdapter, input.teamMembers!),
});

export default getAllSalesTeamAdapter;
