import { Route, Routes } from 'react-router';
import NotFoundPage from 'src/components/commons/notFound';
import UserInfo from './components/userSetup/userInfo';

const UserSetupRoutes = () => (
  <Routes>
    <Route index element={<UserInfo />} />
    <Route index path='user-info' element={<UserInfo />} />
    <Route index path='email-configuration' element={<h2>email configuration</h2>} />
    <Route index path='user-menu' element={<h2>User menu</h2>} />
    <Route index path='activity-journal' element={<h2>Activity Journal</h2>} />
    <Route index path='sync-settings' element={<h2>Sync settings</h2>} />
    <Route index path='user-custom-features' element={<h2>User custom features</h2>} />
    <Route index path='user-enrollment' element={<h2>User Enrolment</h2>} />
    <Route index path='integations' element={<h2>Integration</h2>} />
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

export default UserSetupRoutes;
