import { Empty } from 'antd';
import LandingContainer from './LandingContainer';

const Landing = () => (
  <LandingContainer data-testid='landing-page'>
    <Empty description='Under construction' />
  </LandingContainer>
);

export default Landing;
