import React, { createContext, useMemo, useState, useRef, useEffect, useContext } from 'react';
import CompaniesColumns from 'src/utils/tables/companiesTable';
import { CompaniesContext } from 'src/context/companies.context';
import {
  CompaniesHeaderType,
  CompaniesHeaderType2,
  ColumnTypes,
  CompanyHeaderOptionsType2,
} from 'src/types/companiesTypes';
import { alphabet } from 'src/services/global-variables';
import {
  DataType,
  GridPreferenceDataToSaveType,
  TableContextProviderProps,
} from 'src/types/tableContextTypes';
import useGridPreferences, { GridNames } from 'src/hooks/useGridPreferences';

interface InitialStateProps {
  selectedColumns2: string[];
  handleColumnSelect: (value: string[]) => void;
  companiesCol2: CompaniesHeaderType2[];
  rowsDisplay2: number;
  rowsDisplayedFilter: (data: number) => void;
  moveToRightTable: boolean;
  handletableMovement: () => void;
  enterPage: (pge: number) => void;
  navPagesHandle: (fn: string) => void;
  totalPages: (pages: number) => void;
  currentPage: number;
  totalPage: number;
  handleSetCompaniesCol2: (data: (ColumnTypes[number] & CompaniesHeaderType2)[]) => void;
  gridPreferenceDataToSave: React.MutableRefObject<GridPreferenceDataToSaveType>;
  saveGridPreference: () => void;
  tablesError: boolean;
  orderedColumns: React.MutableRefObject<string[]>;
  orderedColumnsToList: React.MutableRefObject<{ id: string; name: string }[]>;
  orderedColumns2: React.MutableRefObject<string[]>;
  calculateRealPositionColumn: (
    fromElement: CompaniesHeaderType,
    toElement: CompaniesHeaderType,
  ) => void;
  calculateRealPositionColumn2: (
    fromElement: CompaniesHeaderType2,
    toElement: CompaniesHeaderType2,
  ) => void;
  clearAllFilters: () => void;
  sideTableFilterHandlerFunction: (event: React.FormEvent<HTMLInputElement>) => void;
  selectedSideTableFilter: string;
  selectedSideTableOptions: React.MutableRefObject<DataType[]>;
  tableErrorHandler: (tableData?: boolean) => void;
  companyUseGridPreferences: ReturnType<typeof useGridPreferences>;
  onCompletedGridPreferenceData2: () => void;
  setGridPreferenceDefault: (value: number) => void;
}

const TableContext = createContext({} as InitialStateProps);

const TableContextProvider: React.FC<TableContextProviderProps> = ({ children }) => {
  const {
    criteria,
    activeStatusFilter,
    activeOrInactiveFilter,
    searchColumnsData,
    clearAllFilterLoadder,
    selectedOptionHandler,
    selectedFilterField,
    setPaginationSizeHandler,
  } = useContext(CompaniesContext);

  const { iteratedColumn, companiesHeaderResponse2, iteratedColumn2 } = CompaniesColumns();
  const [rowsDisplay2, setRowsDisplay2] = useState(25);
  const [moveToRightTable, setMoveToRightTable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [companiesCol2, setCompaniesCol2] = useState(iteratedColumn2.current);
  const [selectedColumns2, setSelectedColumns2] = useState([]);
  const orderedColumns = useRef<string[]>([]);
  const orderedColumns2 = useRef<string[]>([]);
  const orderedColumnsToList = useRef<{ id: string; name: string }[]>([]);
  const columnBacklog = useRef<(ColumnTypes[number] & CompaniesHeaderType)[]>([]);
  const columnBacklog2 = useRef<(ColumnTypes[number] & CompaniesHeaderType2)[]>([]);
  const gridPreferenceDataToSave = useRef<GridPreferenceDataToSaveType>({});
  const [tablesError, setTablesError] = useState(false);
  const [selectedSideTableFilter, setSelectedSideTableFilter] = useState('all');
  const selectedSideTableOptions = useRef<DataType[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [saveInStorage, setSaveInStorage] = useState(false);
  const companyUseGridPreferences = useGridPreferences(GridNames.Companies);

  const tableErrorHandler = (tableData?: boolean) => {
    if (tableData || tableData === false) {
      setTablesError(tableData);
    } else {
      setTablesError(!tablesError);
    }
  };

  const sortingColumn2 = (
    data: (ColumnTypes[number] & CompaniesHeaderType2)[],
    order: string[],
  ) => {
    const orderedColumnsToSet: (ColumnTypes[number] & CompaniesHeaderType2)[] = [];
    order.forEach((element: string) => {
      const innerColumn = data.find((column: ColumnTypes[number] & CompaniesHeaderType2) =>
        column.key ? `${column.dataIndex}.${column.key}` === element : column.dataIndex === element,
      );
      if (innerColumn?.gridVisible) {
        orderedColumnsToSet.push(innerColumn);
      }
    });
    return orderedColumnsToSet;
  };

  const sideTableFilterHandlerFunction = (event: React.FormEvent<HTMLInputElement> | string) => {
    const innerEvent = event as unknown as string;
    setSelectedSideTableFilter(innerEvent);
    const dropdownAllOptions: CompaniesHeaderType2[] =
      companiesHeaderResponse2.data.GetCompaniesDepthGridHeader.filter(
        (element: CompaniesHeaderType2) =>
          element.dataType === 'dropDown' || element.dataType === 'multiSelect',
      );
    switch (innerEvent) {
      case 'all': {
        selectedOptionHandler(['all-companies']);
        break;
      }
      case 'alphabetical': {
        searchColumnsData.current = undefined;
        selectedFilterField.current = 'alphabetical';
        selectedSideTableOptions.current = alphabet.map((element: string) => ({
          key: element.toUpperCase(),
          mainData: element.toUpperCase(),
        }));
        selectedSideTableOptions.current.unshift({
          key: 'all',
          mainData: 'Show All',
        });
        localStorage.setItem('selectedFilterField', innerEvent);
        companyUseGridPreferences.localSave('selectedFilterField', innerEvent);
        localStorage.removeItem('selectedRowKeys');
        break;
      }
      default: {
        const innerSelectedFilterField = localStorage.getItem('selectedFilterField');
        const innerSelectedRowKeys = localStorage.getItem('selectedRowKeys') ?? undefined;
        if (innerSelectedRowKeys) {
          selectedOptionHandler(['all-companies']);
        }
        if (innerSelectedFilterField !== innerEvent) {
          localStorage.removeItem('selectedRowKeys');
        }
        const innerSelectedOptions = dropdownAllOptions.filter((element: CompaniesHeaderType2) =>
          element.key
            ? `${element.dataIndex}.${element.key}` === innerEvent
            : element.dataIndex === innerEvent,
        );
        selectedFilterField.current = innerSelectedOptions[0].criteriaFilterField;
        if (innerSelectedOptions[0].options) {
          selectedSideTableOptions.current = innerSelectedOptions[0].options.map(
            (element: CompanyHeaderOptionsType2) => ({
              key: element.id,
              mainData: element.name ?? element.productName,
            }),
          );
        }
        localStorage.setItem('selectedFilterField', innerEvent);
        companyUseGridPreferences.localSave('selectedFilterField', innerEvent);
        break;
      }
    }
  };

  const calculateRealPositionColumn = (
    fromElement: CompaniesHeaderType,
    toElement: CompaniesHeaderType,
  ) => {
    const innerData =
      columnBacklog.current.length > 0 ? columnBacklog.current : iteratedColumn.current;
    const realFromElement = innerData.indexOf(fromElement);
    const realToElement = innerData.indexOf(toElement);
    const nextColumn = [...innerData];
    const innerFromItem = nextColumn.splice(realFromElement, 1)[0];
    nextColumn.splice(realToElement, 0, innerFromItem);
    columnBacklog.current = nextColumn;
    orderedColumns.current = nextColumn.map((element) => {
      if (element.key === element.dataIndex) {
        return element.key;
      }
      return `${element.dataIndex}.${element.key}`;
    });
    localStorage.setItem('orderedColumns', JSON.stringify(orderedColumns.current));
  };

  const calculateRealPositionColumn2 = (
    fromElement: CompaniesHeaderType2,
    toElement: CompaniesHeaderType2,
  ) => {
    const innerData =
      columnBacklog2.current.length > 0 ? columnBacklog2.current : iteratedColumn2.current;
    orderedColumnsToList.current = [];
    const realFromElement = innerData.indexOf(fromElement);
    const realToElement = innerData.indexOf(toElement);
    const nextColumn = [...innerData];
    const innerFromItem = nextColumn.splice(realFromElement, 1)[0];
    nextColumn.splice(realToElement, 0, innerFromItem);
    columnBacklog2.current = nextColumn;
    orderedColumns2.current = nextColumn.map((element) => {
      if (element.gridVisible && element?.dataType !== 'color') {
        orderedColumnsToList.current.push({
          id: element.key ? `${element.dataIndex}.${element.key}` : element.dataIndex,
          name: element.headerName,
        });
      }
      if (element.key && element.gridVisible) {
        return `${element.dataIndex}.${element.key}`;
      }
      return element.key ? `${element.dataIndex}.${element.key}` : element.dataIndex;
    });
    const colorIndex = nextColumn.findIndex((element) => element.dataType === 'color');
    const colorElement = orderedColumns2.current[colorIndex];
    orderedColumns2.current.splice(colorIndex, 1);
    orderedColumns2.current.splice(0, 0, colorElement);
    localStorage.setItem('orderedColumnsToList2', JSON.stringify(orderedColumnsToList.current));
    localStorage.setItem('orderedColumns2', JSON.stringify(orderedColumns2.current));
    companyUseGridPreferences.localSave('order', orderedColumns2.current);
  };

  const setGridPreferenceDefault = (value: number) => {
    companyUseGridPreferences.setDefaultGridPreference(value);
  };

  const filterColumnsAction = () => {
    const columnPreferences = localStorage.getItem('selectedOrderedColumns2');
    let innerColumnPreferences2: string[] = [];
    if (columnPreferences) {
      innerColumnPreferences2 = JSON.parse(columnPreferences);
    } else {
      innerColumnPreferences2 = [];
    }
    const innerData2 =
      columnBacklog2.current.length > 0 ? columnBacklog2.current : iteratedColumn2.current;
    const filteredColumns2 = innerData2.filter(
      (column: ColumnTypes[number] & CompaniesHeaderType2) => {
        if (column?.gridVisible) {
          const didMatch = column?.key
            ? innerColumnPreferences2.includes(`${column?.dataIndex}.${column?.key}`)
            : innerColumnPreferences2.includes(column?.dataIndex);
          if (didMatch) {
            return column;
          }
        }
        return null;
      },
    );

    setCompaniesCol2(filteredColumns2);
  };

  const onCompletedGridPreferenceData2 = () => {
    const innerMoveToRightTable =
      companyUseGridPreferences.selected?.options.moveToRightTable === 'false'
        ? false
        : Boolean(companyUseGridPreferences.selected?.options.moveToRightTable);

    const innerOrder = companyUseGridPreferences.selected?.options.order;
    const innerPageSize = companyUseGridPreferences.selected?.options.pageSize;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const innerSearchImputValueArr =
      companyUseGridPreferences.selected?.options?.searchImputValueArr &&
      JSON.parse(companyUseGridPreferences.selected?.options?.searchImputValueArr);
    const innerSelected = companyUseGridPreferences.selected?.options.selected;
    if (innerMoveToRightTable) {
      setMoveToRightTable(innerMoveToRightTable);
    }
    if (innerPageSize) {
      if (setPaginationSizeHandler) {
        setPaginationSizeHandler(innerPageSize);
      }
      setRowsDisplay2(parseInt(innerPageSize, 10));
      localStorage.setItem('tablePageSizePreferences', JSON.stringify(innerPageSize));
      companyUseGridPreferences.localSave('pageSize', innerPageSize);
    }
    if (innerOrder) {
      orderedColumns2.current = innerOrder;
      orderedColumnsToList.current = [];
      localStorage.setItem('orderedColumns2', JSON.stringify(innerOrder));
      companyUseGridPreferences.localSave('order', innerOrder);
      if (iteratedColumn2.current.length > 0) {
        const innersortedColumnResult = sortingColumn2(iteratedColumn2.current, innerOrder);
        columnBacklog2.current = innersortedColumnResult;
        innersortedColumnResult.map(
          (element) =>
            element?.gridVisible &&
            element?.dataType !== 'color' &&
            orderedColumnsToList.current.push({
              id: element.key ? `${element.dataIndex}.${element.key}` : element.dataIndex,
              name: element.headerName,
            }),
        );
      }
    }
    if (innerSelected) {
      setSelectedColumns2(innerSelected);
      localStorage.setItem('selectedOrderedColumns2', JSON.stringify(innerSelected));
      companyUseGridPreferences.localSave('selected', innerSelected);
    }
    filterColumnsAction();
  };

  const saveGridPreference = async () => {
    const innerColumnsKey: string[] = [];
    companiesCol2.forEach((element: CompaniesHeaderType2) =>
      innerColumnsKey.push(element.key ? `${element.dataIndex}.${element.key}` : element.dataIndex),
    );
    const innerOptions = {
      pageSize: Number(localStorage.getItem('tablePageSizePreferences') ?? 25),
      order: orderedColumns2.current,
      selected: innerColumnsKey,
      tableActiveFilterPreferences:
        localStorage.getItem('tableActiveFilterPreferences') ?? undefined,
      tableSortOrderPreferences: localStorage.getItem('tableSortOrderPreferences') ?? undefined,
      moveToRightTable,
      selectedFilterField: localStorage.getItem('selectedFilterField') ?? undefined,
      selectedFilterValues: localStorage.getItem('selectedRowKeys') ?? undefined,
      searchImputValueArr: localStorage.getItem('searchInputValueArr') ?? undefined,
    };
    companyUseGridPreferences.createGridPreference(innerOptions);
  };

  const handleColumnSelect = (value: string[]) => {
    localStorage.setItem('selectedOrderedColumns2', JSON.stringify(value));
    companyUseGridPreferences.localSave('selected', value);
    setSelectedColumns2(value as unknown as []);
    filterColumnsAction();
  };

  const rowsDisplayedFilter = (data: number) => {
    setRowsDisplay2(data);
    gridPreferenceDataToSave.current = {
      ...gridPreferenceDataToSave.current,
      rowsAmount: data,
    };
  };
  const handletableMovement = () => {
    setMoveToRightTable(!moveToRightTable);
    localStorage.setItem('moveToRightTable', `${!moveToRightTable}`);
    companyUseGridPreferences.localSave('moveToRightTable', `${!moveToRightTable}`);
  };
  const handleSetCompaniesCol2 = (data: (ColumnTypes[number] & CompaniesHeaderType2)[]) => {
    const innerData = data;
    const colorIndex = innerData.findIndex((element) => element.dataType === 'color');
    const colorElement = innerData[colorIndex];
    innerData.splice(colorIndex, 1);
    innerData.splice(0, 0, colorElement);
    setCompaniesCol2(innerData);
    const innerSelectOrderedColumns = innerData.map((element) =>
      element.key ? `${element.dataIndex}.${element.key}` : element.dataIndex,
    );
    localStorage.setItem('selectedOrderedColumns2', JSON.stringify(innerSelectOrderedColumns));
    companyUseGridPreferences.localSave('selected', innerSelectOrderedColumns);
  };
  const navPagesHandle = (fn: string) => {
    switch (fn) {
      case 'nextPage':
        if (currentPage < totalPage) {
          setCurrentPage(currentPage + 1);
        }
        return currentPage;
      case 'backPage':
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
        return currentPage;
      case 'firstPage':
        setCurrentPage(1);
        return currentPage;
      case 'lastPage':
        setCurrentPage(Math.max(totalPage));
        return currentPage;

      default:
        return currentPage;
    }
  };
  const totalPages = (pages: number) => {
    setTotalPage(pages);
  };

  const enterPage = (pge: number) => {
    if (pge < totalPage) {
      setCurrentPage(pge);
    }
    return null;
  };

  const clearAllFilters = () => {
    const pageSize = localStorage.getItem('tablePageSizePreferences');
    localStorage.clear();
    if (pageSize) {
      localStorage.setItem('tablePageSizePreferences', pageSize);
      companyUseGridPreferences.localSave('pageSize', pageSize);
    }
    clearAllFilterLoadder.current = true;
    const innerEvent = 'all';
    criteria.current.orderBy = null;
    activeOrInactiveFilter.current = innerEvent;
    searchColumnsData.current = undefined;
    activeStatusFilter(innerEvent as unknown as React.FormEvent<HTMLInputElement>);
    companyUseGridPreferences.localSave('tableActiveFilterPreferences', innerEvent);
    clearAllFilterLoadder.current = false;
  };

  useEffect(() => {
    const reloadedSelectedOrderedColumns = localStorage.getItem('selectedOrderedColumns2');
    setSaveInStorage(!reloadedSelectedOrderedColumns);
  }, []);

  useEffect(() => {
    if (companyUseGridPreferences.preferences.length > 0) {
      onCompletedGridPreferenceData2();
    }
  }, [companyUseGridPreferences.selected]);

  useEffect(() => {
    if (
      companyUseGridPreferences.preferences.length > 0 &&
      companiesHeaderResponse2.data &&
      iteratedColumn2.current.length > 0
    ) {
      onCompletedGridPreferenceData2();
    }
    const reloadedSelectedSearchField = localStorage.getItem('selectedFilterField');
    if (reloadedSelectedSearchField && companiesHeaderResponse2.data) {
      sideTableFilterHandlerFunction(reloadedSelectedSearchField);
    }
  }, [
    companiesHeaderResponse2.data,
    companyUseGridPreferences.preferences,
    iteratedColumn2.current,
  ]);

  useEffect(() => {
    if (iteratedColumn2.current.length > 0) {
      filterColumnsAction();
      const reloadedSelectedOrderedColumns = localStorage.getItem('selectedOrderedColumns2');
      if (reloadedSelectedOrderedColumns) {
        const innersortingColumnResult = sortingColumn2(
          iteratedColumn2.current,
          JSON.parse(reloadedSelectedOrderedColumns),
        );
        setCompaniesCol2(innersortingColumnResult);
      }
    }
  }, [iteratedColumn2.current]);

  const tableValues = useMemo(
    () => ({
      rowsDisplayedFilter,
      moveToRightTable,
      handletableMovement,
      currentPage,
      enterPage,
      totalPage,
      totalPages,
      navPagesHandle,
      handleColumnSelect,
      gridPreferenceDataToSave,
      saveGridPreference,
      tablesError,
      orderedColumns,
      calculateRealPositionColumn,
      clearAllFilters,
      sideTableFilterHandlerFunction,
      selectedSideTableFilter,
      selectedSideTableOptions,
      tableErrorHandler,
      rowsDisplay2,
      selectedColumns2,
      orderedColumns2,
      calculateRealPositionColumn2,
      companiesCol2,
      handleSetCompaniesCol2,
      orderedColumnsToList,
      companyUseGridPreferences,
      onCompletedGridPreferenceData2,
      setGridPreferenceDefault,
    }),
    [
      {
        rowsDisplayedFilter,
        moveToRightTable,
        handletableMovement,
        currentPage,
        enterPage,
        totalPage,
        totalPages,
        navPagesHandle,
        handleColumnSelect,
        gridPreferenceDataToSave,
        saveGridPreference,
        tablesError,
        orderedColumns,
        calculateRealPositionColumn,
        clearAllFilters,
        sideTableFilterHandlerFunction,
        selectedSideTableFilter,
        selectedSideTableOptions,
        tableErrorHandler,
        rowsDisplay2,
        selectedColumns2,
        orderedColumns2,
        calculateRealPositionColumn2,
        companiesCol2,
        handleSetCompaniesCol2,
        orderedColumnsToList,
        companyUseGridPreferences,
        onCompletedGridPreferenceData2,
        setGridPreferenceDefault,
      },
    ],
  );

  return <TableContext.Provider value={tableValues}>{children}</TableContext.Provider>;
};

export { TableContext, TableContextProvider };
