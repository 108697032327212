import { useState } from 'react';
import { Option, SortModel } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import useCallPatternStore from 'src/hooks/useCallPatternStore';
import { CallPatternModel, OrderCallPatternEnum, OrderTypes } from 'src/graphql/schema-types';
import COLUMNS from '../constants';
import { CallPatternResponse } from '../types';
import useFilterColumn from './useFilterColumn';

const useCallPatternTable = () => {
  const { createdFilters, setColumnFilters } = useFilterColumn();
  const { removeOne, updateOne, createOne, getAll, loading, getAllFilter } = useCallPatternStore();
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState(COLUMNS);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newRecord, setNewRecord] = useState({
    name: '',
    description: '',
  });

  const uploadData: FetchFunc = ({ start, renderLen }) =>
    getAll({
      Criteria: {
        pagination: {
          from: start,
          size: renderLen,
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllCallPattern2.results || [],
      total: res.data?.GetAllCallPattern2.total || 0,
    }));

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(uploadData);

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(searchField, search, dataType);

    return getAllFilter({
      Criteria: {
        pagination: {
          from: 0,
          size: 25,
        },

        filter: filters,
      },
      SearchFields: {
        ids: null,
        names: null,
        enabled: null,
      },
    }).then((res) => ({
      results: res?.data?.GetAllCallPattern2.results as any[],
      filter: res?.data?.GetAllCallPattern2.results?.map((result: CallPatternResponse) => ({
        value: result[searchField],
        label: result[searchField],
      })) as Option[],
    }));
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
      description: '',
    });
  };

  const createNewRecord = async () => {
    const { name, description } = newRecord;
    if (name === '') return;
    await createOne({
      name: capitalizeFirstWord(name),
      description,
    }).then(() => {
      setTimeout(() => {
        reset();
      }, 500);
    });
  };

  const handleUpdateRows = (rows: CallPatternModel[]) => {
    rows.forEach((row) => {
      const { id, name, description } = row;
      updateOne({
        id,
        name: capitalizeFirstWord(name!),
        description,
      });
    });
  };

  const handleRemoveRecords = async (rows: CallPatternModel[]) => {
    const removeId = rows[0].id;
    try {
      removeOne(removeId).then(() => {
        setTimeout(() => {
          reset();
        }, 500);
      });
    } catch (error) {
      console.error('Error deleting records:', error);
    }
  };

  const handleClearFilter = () => {
    reset();
    setColumnFilters([]);
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = async (sortModel: SortModel | undefined) => {
    if (!sortModel) {
      try {
        const response = await getAll({
          Criteria: {
            pagination: {
              from: (pagination.page - 1) * pagination.pageSize,
              size: pagination.pageSize,
            },
          },
        });

        if (response.data && response.data.GetAllCallPattern2) {
          setDataSource(response.data.GetAllCallPattern2.results);
        }
        return;
      } catch (error) {
        console.error('Failed to fetch original data:', error);
      }
    }

    try {
      const fieldSortBy = sortModel?.sortBy as OrderCallPatternEnum.Name;
      const order = sortModel?.sort.toUpperCase() as OrderTypes;

      const response = await getAll({
        Criteria: {
          pagination: {
            from: (pagination.page - 1) * pagination.pageSize,
            size: pagination.pageSize,
          },
          orderBy: [
            {
              field: fieldSortBy,
              sort: order,
            },
          ],
        },
      });

      if (response.data && response.data.GetAllCallPattern2) {
        setDataSource(response.data.GetAllCallPattern2.results);
      }
    } catch (error) {
      console.error('Failed to fetch sorted data:', error);
    }
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    onListRender,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    spinning: loading,
    postData,
    newRecord,
    createNewRecord,
    handleUpdateRows,
    resetNewRecord,
    dataSource,
    handleClearFilter,
    handleRemoveRecords,
  };
};

export default useCallPatternTable;
