// eslint-disable-next-line consistent-return
const activeStatusFilterFunc = (data: any, operation: React.FormEvent<HTMLInputElement>) => {
  const requestedOperation = operation as unknown as string;
  let internalData;
  switch (requestedOperation) {
    case 'active':
      // eslint-disable-next-line no-case-declarations
      internalData = data.filter((element: any) => element.active === true);
      return internalData;
    case 'inactive':
      // eslint-disable-next-line no-case-declarations
      internalData = data.filter((element: any) => element.active === false);
      return internalData;
    case 'all':
      internalData = data;
      return internalData;
    case '':
      internalData = data;
      return internalData;
    default:
      return 'Unrecognized operation!';
  }
};

const emailVerificator = (email: string) => {
  const emailRegex =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  return emailRegex.test(email);
};

const phoneVerificator = (phone: string) => {
  const emailRegex = /^(\+1\s)?((\(\d{3}\)\s?)|\d{3}[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
  return emailRegex.test(phone);
};

const websiteVerificator = (website: string) => {
  const websiteRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[a-zA-Z]{2,}$/;
  return websiteRegex.test(website);
};

export const clearFilters = (filters: Record<string, any> | undefined) => {
  if (!filters) return {};
  const newFilters = { ...filters };
  Object.keys(newFilters).forEach((key) => {
    if (newFilters[key] === '') {
      delete newFilters[key];
    }
  });

  return newFilters;
};

export { activeStatusFilterFunc, emailVerificator, websiteVerificator, phoneVerificator };
