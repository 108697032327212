import get from 'lodash/get';
import set from 'lodash/set';
import { useCallback, useEffect, useState } from 'react';

type StorageType = 'localStorage' | 'sessionStorage';

const useLocalStorage = (key: string, initialValue: any, type: StorageType = 'localStorage') => {
  const [data, setData] = useState(initialValue);

  const storage = type === 'localStorage' ? window.localStorage : window.sessionStorage;

  useEffect(() => {
    const getData = storage.getItem(key);
    if (getData && getData !== 'undefined') {
      setData(JSON.parse(getData));
    }
  }, [key]);

  const storeData = useCallback((updateValue: any) => {
    setData(updateValue);
    storage.setItem(key, JSON.stringify(updateValue));
  }, []);

  const deleteData = useCallback(() => storage.removeItem(key), []);

  return {
    data,
    storeData,
    deleteData,
  };
};

export const useDeepLocalStorage = (
  key: string,
  initialValue: any,
  type: StorageType = 'localStorage',
) => {
  const steps = key.split('.');
  const mainKey = steps.shift()!;
  const { data, storeData, deleteData } = useLocalStorage(mainKey, initialValue, type);

  const deepData = get(data, steps.join('.'));

  const deepStoreData = useCallback(
    (updateValue: any) => {
      const newData = set(data || {}, steps.join('.'), updateValue);
      storeData(newData);
    },
    [data, key],
  );

  return {
    data: deepData,
    storeData: deepStoreData,
    deleteData,
  };
};

export default useLocalStorage;
