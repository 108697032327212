import React, { FC, useState } from 'react';
import { Tooltip } from 'antd';
import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import StyledPinButton from '../styles/pinButton.styles';
import { SubTableColumn } from '../types';

type PinButtonProps = {
  color?: string;
  column?: SubTableColumn | undefined;
  onPinClick?: (column: SubTableColumn | undefined) => void;
  disabled?: boolean;
};

const PinButton: FC<PinButtonProps> = ({ color, onPinClick, column, disabled }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false); // Start with false to manage visibility manually

  const icon = column?.pinned ? <PushpinFilled color={color} /> : <PushpinOutlined color={color} />;
  const className = column?.pinned ? 'active' : undefined;

  const handleClick = () => {
    setTooltipVisible(false); // Explicitly hide the tooltip on click
    if (onPinClick) {
      onPinClick(column);
    }
  };

  // We don't need to explicitly handle onMouseLeave to show the tooltip again
  // because the Tooltip component will manage its visibility based on hover by default

  return (
    <Tooltip
      title={column?.pinned ? 'Unpin' : 'Pin to left'}
      color='#fff'
      overlayInnerStyle={{ color: '#000' }}
      destroyTooltipOnHide
      visible={tooltipVisible}
      onVisibleChange={(visible) => setTooltipVisible(visible)} // Update the visibility based on Tooltip's internal state changes
    >
      <StyledPinButton
        onMouseEnter={() => setTimeout(() => setTooltipVisible(true), 100)} // Show the tooltip when the mouse enters the button
        onClick={handleClick}
        title={column?.pinned ? 'Unpin' : 'Pin to left'}
        color={color || '#858585'}
        style={{ backgroundColor: 'transparent' }}
        size='small'
        type='text'
        icon={icon}
        className={className}
        disabled={disabled}
      />
    </Tooltip>
  );
};

export default PinButton;
