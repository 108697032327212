import { MutableRefObject, useRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let google: any;

type AddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

type Option = { value: string | number; label: string };

type Args = {
  regions: Option[];
  countries: Option[];
  states: Option[];
  territories: Option[];
};

const matchOption = (
  options: { value: string | number; label: string }[],
  comp: AddressComponent,
) => {
  const option = options.find((opt) => {
    const label = opt.label.toLowerCase();
    const longName = comp.long_name.toLowerCase();
    const shortName = comp.short_name.toLowerCase();
    return label === longName || label === shortName;
  });
  return option ? option.value : undefined;
};

const useGoogleAddressAutocomplete = (options: Args) => {
  const autocompleteRefs: MutableRefObject<{ instanceGoogle: any; id: number }[]> = useRef([]);

  const autocompleteAddress = (input: HTMLInputElement, id: number) =>
    new Promise<{
      address: string;
      latitude: number;
      longitude: number;
      zipCode?: string;
      city?: string;
      state?: string;
      country?: string;
      region?: string;
    }>((resolve, reject) => {
      if (typeof google !== 'undefined') {
        const existInstance = autocompleteRefs.current.find((e: { id: number }) => e.id === id);
        const autocomplete =
          existInstance?.instanceGoogle ||
          new google.maps.places.Autocomplete(input, {
            fields: ['address_components', 'geometry', 'formatted_address'],
            types: ['address'],
            componentRestrictions: {
              country: ['USA', 'CA'],
            },
          });

        if (!existInstance) {
          const instance = { instanceGoogle: autocomplete, id };
          autocompleteRefs.current.push(instance);
        }

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();

          const components = place.address_components as AddressComponent[];

          const info = components.reduce((acc, comp) => {
            switch (comp.types[0]) {
              case 'postal_code':
                return { ...acc, zipCode: comp.long_name };
              case 'locality':
                return { ...acc, city: comp.long_name };
              case 'administrative_area_level_1':
                return { ...acc, state: matchOption(options.states, comp) };
              case 'country':
                return { ...acc, country: matchOption(options.countries, comp) };
              case 'sublocality_level_1':
                return { ...acc, region: matchOption(options.regions, comp) };
              default:
                return acc;
            }
          }, {});

          resolve({
            ...info,
            address: place.formatted_address,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          });
        });
      } else {
        reject(new Error('Google API not loaded'));
      }
    });

  return { autocompleteAddress };
};

export default useGoogleAddressAutocomplete;
