/* eslint-disable react/no-unstable-nested-components */
import { Table as AntdTable } from 'antd';
import ReactDragListView from 'react-drag-listview';
import React, { useMemo } from 'react';
import { SubTableGridProps } from '../types';
import useTable from '../hooks/useTable';
import { useSubTableContext } from '../contexts/subtable.context';

const SubTableGrid: React.FC<SubTableGridProps> = (props) => {
  const { components, columns, onRow, handleDragEnd, pagination, dataSource, rowSelection } =
    useTable(props);
  const { withDragHandler } = useSubTableContext();

  const columnsToShow = useMemo(
    () => columns?.filter((column) => column.selected !== false),
    [columns],
  );
  return (
    <ReactDragListView.DragColumn
      onDragEnd={handleDragEnd}
      nodeSelector='th'
      lineClassName='dragAndDropLine'
      handleSelector={withDragHandler ? '.drag-holder' : '.sub-table-column-header'}
    >
      <AntdTable
        {...props}
        components={components}
        dataSource={dataSource}
        columns={columnsToShow as []}
        rowSelection={rowSelection}
        rowKey={(record) => record?.id || record?.ID || record?.key}
        onRow={onRow}
        pagination={pagination}
        data-testid='subtable'
      />
    </ReactDragListView.DragColumn>
  );
};

export default SubTableGrid;
