import { Form } from 'antd';
import { buildOptions } from 'src/components/commons/createCompany/utils/functions';
import GenericForm from 'src/components/commons/genericForm';
import useCompanyHeaders from 'src/hooks/useCompanyHeaders';
import { useCompanyContext } from 'src/context/company.context';

const CompanyIndustriesSection = () => {
  const form = Form.useFormInstance();
  const { activeExpandedEdition } = useCompanyContext();
  const headers = useCompanyHeaders();
  const fields = [
    {
      name: 'industriesId',
      type: 'multiSelect',
      readOnly: !activeExpandedEdition,
      options: buildOptions(headers, 'industries'),
      span: 24,
    },
  ];

  return <GenericForm fields={fields} form={form} name='formIndustries' />;
};

export default CompanyIndustriesSection;
