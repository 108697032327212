import React, { createContext, ReactNode, useMemo, useState, useEffect, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { confirmModal } from 'src/components/commons/customModal';
import { GET_COMPANIES_ELASTIC_2, GET_TYPE_AHEAD_RESULTS } from 'src/components/company/queries';
import { UPDATE_COMPANIES, DELETE_ROWS_MUTATION } from 'src/components/company/mutations';
import { OperationVariables, QueryResult, useLazyQuery, useMutation } from '@apollo/client';
import { GET_ALL_CUSTOM_TYPES } from 'src/modules/settings/modules/subTables/modules/company/tabs/type/graphql/queries';
import CompaniesColumns from 'src/utils/tables/companiesTable';
import { showFilterOptions } from 'src/services/global-variables';
import {
  CompaniesElasticTypes,
  CompaniesElasticTypes2,
  EditingDataPayloadType,
  EditingDataPayloadType2,
  SearchFieldsType,
  DeletedDataResponseType,
  QueryCriteriaType,
  ActiveOrInactiveFilterType,
  EditWithSelectionReferenceType,
  CompaniesHeaderType2,
  DataStructureType2,
} from 'src/types/companiesTypes';
import { clearFilters } from 'src/utils/functions/companiesFunctions';
import { CreateCompanyPayloadType } from 'src/components/commons/createCompany/types';
import useGridPreferences, { GridNames } from 'src/hooks/useGridPreferences';

interface CompaniesContextProviderProps {
  children: ReactNode;
}

interface InitialStateProps {
  companiesData2: CompaniesElasticTypes2[];
  pagination: number;
  currentPage: number;
  handleAllDataSelection: (index: number[]) => void;
  noSelectedRowModal: boolean;
  handleNoSelectedRowModal: () => void;
  activeStatusFilter: (operation: React.FormEvent<HTMLInputElement>) => void;
  deleteRowButtonAction: () => void;
  activeFilterValue: string;
  companiesLoading: boolean;
  getCompaniesResponse2: OperationVariables;
  deletingCompaniesResponse: OperationVariables;
  handleOneDeleteSelection: () => void;
  totalDataSize2: number;
  activeOrInactiveFilter: React.MutableRefObject<string>;
  disableDelButton: boolean;
  setPaginationSizeHandler: (data: number) => void;
  companiesError: boolean;
  sortFunction: (sortOrder: 'ASC' | 'DESC' | 'NONE', activeColumn: string) => void;
  searchElasticFunction: (
    activeColumn: string,
    searchValue: string,
    isInputValue?: boolean,
  ) => void;
  saveCompanyData2: (newData: CompaniesElasticTypes2[]) => void;
  setCompaniesErrorHandler: (data?: boolean) => void;
  activeEditingColumnHandler: () => void;
  activeCreateCompany: () => void;
  activeEditingColumn: boolean;
  searchRecurrentDataHandler: (value: string, searchField: string) => void;
  searchRecurrentDataResponse: OperationVariables;
  saveEditDataPayload2: (data: EditingDataPayloadType2) => void;
  warningStatusErrorHandler: () => void;
  sendNewPayloadData: (isBulkEdit?: boolean) => void;
  warningStatusError: boolean;
  tableLoading: boolean;
  setTableLoadingHandler: (status: boolean) => void;
  editingCompaniesResponse: OperationVariables;
  oldDataPayload: React.MutableRefObject<CompaniesElasticTypes[]>;
  editDataPayload: React.MutableRefObject<EditingDataPayloadType[]>;
  openConfirmationModal: boolean;
  deletedDataResponse: React.MutableRefObject<DeletedDataResponseType[]>;
  closeDeleteConfirmationAction: () => void;
  createCompanyModalActive: boolean;
  openMap: boolean;
  setOpenMap: React.Dispatch<React.SetStateAction<boolean>>;
  criteria: React.MutableRefObject<QueryCriteriaType>;
  searchColumnsData: React.MutableRefObject<SearchFieldsType | undefined>;
  clearAllFilterLoadder: React.MutableRefObject<boolean>;
  setPageHandler: (value: number, rowsDisplay: number) => void;
  activeMetadataDropdown: boolean;
  setActiveMetadataDropdownHandler: () => void;
  gridDataSelected: React.MutableRefObject<number[]>;
  openEditConfirmationModal: boolean;
  openEditConfirmationModalHandler: () => void;
  editingReferencesData: React.MutableRefObject<EditWithSelectionReferenceType>;
  cancelActiveMetadataDropdownHandler: () => void;
  selectedOptionHandler: (value: React.Key[]) => void;
  selectedFilterField: React.MutableRefObject<string | null>;
  typeaheadElasticFunction: (activeColumn: string, searchValue: string) => void;
  typeaheadSearchOptions: { value: string }[];
  getTypeaheadDataResponse: OperationVariables;
  showEmptyPage: boolean;
  showEmptyPageHandler: (value: boolean) => void;
  allowCreateCompany: boolean;
  allowCreateCompanyHandler: (value: boolean) => void;
  progressCreationCompany: number;
  progressCreationCompanyHandler: (pregress: number) => void;
  createCompanyPayload: React.MutableRefObject<CreateCompanyPayloadType>;
  setCompaniesLoadingHandler: (status: boolean) => void;
  activeMapSearch: boolean;
  setActiveMapSearch: React.Dispatch<React.SetStateAction<boolean>>;
  refetchData: () => void;
}

const CompaniesContext = createContext({} as InitialStateProps);

const CompaniesContextProvider: React.FC<CompaniesContextProviderProps> = ({ children }) => {
  const [companiesData2, setCompaniesData2] = useState<CompaniesElasticTypes2[]>([]);
  const [pagination, setPagination] = useState(1);
  const [companiesError, setCompaniesError] = useState(false);
  const [warningStatusError, setWarningStatusError] = useState(false);
  const gridDataSelected = useRef<number[]>([]);
  const [totalDataSize2, setTotalDataSize2] = useState<number>(0);
  const [companiesLoading, setCompaniesLoading] = useState(false);
  const [createCompanyModalActive, setCreateCompanyModalActive] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [activeEditingColumn, setActiveEditingColumn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const activeOrInactiveFilter = useRef('all');
  const editDataPayload = useRef<EditingDataPayloadType[]>([]);
  const editDataPayload2 = useRef<EditingDataPayloadType2[]>([]);
  const oldDataPayload = useRef<CompaniesElasticTypes[]>([]);
  const companyDataBackup2 = useRef<CompaniesElasticTypes2[]>([]);
  const searchColumnsData = useRef<SearchFieldsType | undefined>(undefined);
  const deletedDataResponse = useRef<DeletedDataResponseType[]>([]);
  const clearAllFilterLoadder = useRef(false);
  const [activeMetadataDropdown, setActiveMetadataDropdown] = useState(false);
  const [openEditConfirmationModal, setOpenEditConfirmationModal] = useState(false);
  const [activeMapSearch, setActiveMapSearch] = useState(false);
  const editingReferencesData = useRef<EditWithSelectionReferenceType>({});
  const selectedFilterField = useRef<string | null>(null);
  const [typeaheadSearchOptions, setTypeaheadSearchOptions] = useState<{ value: string }[]>([]);
  const [allowCreateCompany, setAllowCreateCompany] = useState(true);
  const [progressCreationCompany, setProgressCreationCompany] = useState(0);
  const createCompanyPayload = useRef<CreateCompanyPayloadType>({});
  const isCompaniesPath = window?.location?.pathname === '/companies';
  const companiesCustomTypes = useRef<DataStructureType2[]>([]);

  let companiesHeaderResponse2: QueryResult<any, OperationVariables> | undefined;
  let localSave: (key: string, value: any) => void;
  let localRemove: (key: string) => void;

  if (isCompaniesPath) {
    ({ companiesHeaderResponse2 } = CompaniesColumns());
    ({ localSave, localRemove } = useGridPreferences(GridNames.Companies));
  }

  const [showEmptyPage, setShowEmptyPage] = useState(false);

  const criteria = useRef<QueryCriteriaType>({
    pagination: {
      from: 0,
      size: 25,
    },
  });

  const progressCreationCompanyHandler = (pregress: number) => {
    setProgressCreationCompany(pregress);
  };

  const allowCreateCompanyHandler = (status: boolean) => {
    setAllowCreateCompany(status);
  };

  const showEmptyPageHandler = (status: boolean) => {
    setShowEmptyPage(status);
  };

  const openEditConfirmationModalHandler = () => {
    setOpenEditConfirmationModal(!openEditConfirmationModal);
  };

  const setTableLoadingHandler = (status: boolean) => {
    setTableLoading(status);
  };

  const setCompaniesLoadingHandler = (status: boolean) => {
    setCompaniesLoading(status);
  };

  const setActiveMetadataDropdownHandler = () => {
    setActiveMetadataDropdown(!activeMetadataDropdown);
  };

  const activeEditingColumnHandler = () => {
    const innerData = cloneDeep(companyDataBackup2.current);
    if (gridDataSelected.current.length > 0 && !activeEditingColumn) {
      setActiveMetadataDropdownHandler();
    } else {
      setCompaniesData2(innerData);
      setActiveEditingColumn(!activeEditingColumn);
    }
  };

  const activeCreateCompany = () => {
    setCreateCompanyModalActive(!createCompanyModalActive);
  };

  const saveCompanyData2 = (newData: CompaniesElasticTypes2[]) => {
    setCompaniesData2(newData);
  };

  const setCompaniesErrorHandler = (data?: boolean) => {
    if (data || data === false) {
      setCompaniesError(data);
    } else {
      setCompaniesError(!companiesError);
    }
  };

  const [getCompanies2, getCompaniesResponse2] = isCompaniesPath
    ? useLazyQuery(GET_COMPANIES_ELASTIC_2, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
          setCompaniesData2(data.GetCompaniesFromElastic2.results);
          companyDataBackup2.current = cloneDeep(data.GetCompaniesFromElastic2.results);
          setTotalDataSize2(data.GetCompaniesFromElastic2.total);
          setCompaniesLoading(false);
          setTableLoadingHandler(false);
        },
        onError: () => {
          setCompaniesError(true);
          setCompaniesLoading(false);
          setTableLoadingHandler(false);
          showEmptyPageHandler(true);
        },
      })
    : [() => {}, {}];

  const [getCompaniesCustomTypes] = useLazyQuery(GET_ALL_CUSTOM_TYPES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      companiesCustomTypes.current = data?.GetAllCompanyTypeAlias2?.results;
    },
    onError: () => {
      setCompaniesError(true);
    },
  });

  const [getTypeaheadData, getTypeaheadDataResponse] = useLazyQuery(GET_TYPE_AHEAD_RESULTS, {
    context: { clientName: 'elastic' },
    fetchPolicy: 'network-only',
    onError: () => {
      setCompaniesError(true);
      setCompaniesLoading(false);
      setTableLoadingHandler(false);
    },
  });

  const [searchRecurrentData, searchRecurrentDataResponse] = useLazyQuery(GET_TYPE_AHEAD_RESULTS, {
    // context: { clientName: 'elastic' },
    fetchPolicy: 'network-only',
    onError: () => {
      setCompaniesError(true);
    },
  });

  const refetchData = () => {
    const searchFields = clearFilters(searchColumnsData.current);
    getCompanies2({
      variables: {
        criteria: { ...criteria.current },
        searchFields,
      },
    });
  };

  const selectedOptionHandler = (value: React.Key[]) => {
    setTableLoadingHandler(true);
    const dropdownSelectedOption: CompaniesHeaderType2 =
      companiesHeaderResponse2?.data?.GetCompaniesDepthGridHeader?.find(
        (element: CompaniesHeaderType2) => selectedFilterField.current === element.orderField,
      );
    const selectedValuesNames = dropdownSelectedOption?.options?.filter((element) => {
      const innerValue = value.find((item) => item === element.id);
      return innerValue && element.name;
    });
    let innerFilter: ActiveOrInactiveFilterType[] | undefined = [];
    if (value[0] === 'all-companies') {
      const searchFields = clearFilters(searchColumnsData.current);
      localStorage.removeItem('selectedRowKeys');
      localStorage.removeItem('selectedFilterField');
      localRemove('selectedRowKeys');
      localRemove('selectedFilterField');
      searchColumnsData.current = undefined;
      let innerIndex;
      if (criteria.current.filter) {
        innerIndex = criteria.current.filter?.findIndex((item) => item.fieldName === 'isActive');
        if (innerIndex >= 0) {
          const tempIsActiveFilter = criteria.current.filter[innerIndex];
          criteria.current.filter = [];
          criteria.current.filter.push(tempIsActiveFilter);
        } else {
          criteria.current.filter = undefined;
        }
      }
      getCompanies2({
        variables: {
          criteria: { ...criteria.current },
          searchFields,
        },
      });
    } else {
      if (selectedFilterField.current === 'alphabetical') {
        innerFilter =
          value[0] !== 'all'
            ? [
                {
                  fieldName: 'name',
                  dataType: 'string',
                  operator: 'STARTWITH',
                  value: value[0],
                },
              ]
            : undefined;
        if (criteria.current.filter && innerFilter) {
          const index = criteria.current.filter.findIndex(
            (item) => item.fieldName === 'name' && item.operator === 'STARTWITH',
          );
          if (index >= 0) {
            criteria.current.filter.splice(index, 1);
            criteria.current.filter = [...innerFilter];
          } else {
            criteria.current.filter = [...criteria.current.filter, ...innerFilter];
          }
        } else {
          criteria.current.filter = innerFilter;
        }
      } else if (value.length === 0) {
        let innerIndex;
        if (criteria.current.filter) {
          innerIndex = criteria.current.filter?.findIndex((item) => item.fieldName === 'isActive');
          if (innerIndex >= 0) {
            const tempIsActiveFilter = criteria.current.filter[innerIndex];
            criteria.current.filter = [];
            criteria.current.filter.push(tempIsActiveFilter);
          } else {
            criteria.current.filter = undefined;
          }
        }
      } else {
        selectedValuesNames?.forEach(
          (element) =>
            innerFilter &&
            innerFilter.push({
              fieldName: selectedFilterField.current ?? '',
              dataType: 'string',
              operator: 'EQUALS',
              value: element.name,
            }),
        );
        let innerIndex;
        if (criteria.current.filter) {
          innerIndex = criteria.current.filter?.findIndex((item) => item.fieldName === 'isActive');
          if (innerIndex >= 0) {
            const tempIsActiveFilter = criteria.current.filter[innerIndex];
            criteria.current.filter = innerFilter;
            criteria.current.filter.push(tempIsActiveFilter);
          } else {
            criteria.current.filter = innerFilter;
          }
        } else {
          criteria.current.filter = innerFilter;
        }
      }

      const searchFields = clearFilters(searchColumnsData.current);
      getCompanies2({
        variables: {
          criteria: { ...criteria.current },
          searchFields,
        },
      });
    }
  };

  const activeFilterQueryAction = () => {
    if (clearAllFilterLoadder.current) {
      setCompaniesLoading(true);
    } else {
      setTableLoadingHandler(true);
    }
    let innerIndex;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const searchFields = clearFilters(searchColumnsData.current);
    const fieldName = companiesHeaderResponse2?.data?.GetCompaniesDepthGridHeader?.find(
      (element: CompaniesHeaderType2) => element.dataIndex === 'isActive',
    );
    switch (activeOrInactiveFilter.current) {
      case 'active':
        if (criteria.current.filter) {
          innerIndex = criteria.current.filter?.findIndex((item) => item.fieldName === 'isActive');
          if (innerIndex >= 0) {
            criteria.current.filter?.splice(innerIndex, 1);
            criteria.current.filter?.push({
              fieldName: fieldName?.dataIndex ?? 'isActive',
              dataType: 'boolean',
              operator: 'EQUALS',
              value: 'true',
            });
          } else {
            criteria.current.filter?.push({
              fieldName: fieldName?.dataIndex ?? 'isActive',
              dataType: 'boolean',
              operator: 'EQUALS',
              value: 'true',
            });
          }
        } else {
          criteria.current.filter = [
            {
              fieldName: fieldName?.dataIndex ?? 'isActive',
              dataType: 'boolean',
              operator: 'EQUALS',
              value: 'true',
            },
          ];
        }
        break;
      case 'inactive':
        if (criteria.current.filter) {
          innerIndex = criteria.current.filter?.findIndex((item) => item.fieldName === 'isActive');
          if (innerIndex >= 0) {
            criteria.current.filter?.splice(innerIndex, 1);
            criteria.current.filter?.push({
              fieldName: fieldName?.dataIndex ?? 'isActive',
              dataType: 'boolean',
              operator: 'EQUALS',
              value: 'false',
            });
          } else {
            criteria.current.filter?.push({
              fieldName: fieldName?.dataIndex ?? 'isActive',
              dataType: 'boolean',
              operator: 'EQUALS',
              value: 'false',
            });
          }
        } else {
          criteria.current.filter = [
            {
              fieldName: fieldName?.dataIndex ?? 'isActive',
              dataType: 'boolean',
              operator: 'EQUALS',
              value: 'false',
            },
          ];
        }
        break;
      default:
        if (criteria.current.filter) {
          innerIndex = criteria.current.filter?.findIndex((item) => item.fieldName === 'isActive');
          if (innerIndex >= 0) {
            criteria.current.filter?.splice(innerIndex, 1);
          }
        }
        break;
    }
    getCompanies2({
      variables: {
        criteria: { ...criteria.current },
        searchFields,
      },
    });
  };

  const setPaginationSizeHandler = (data: number) => {
    localStorage.setItem('tablePageSizePreferences', JSON.stringify(data));
    criteria.current.pagination.size = data;
    setPagination(data);
  };

  const setPageHandler = (data: number, rowsDisplay: number) => {
    let dataMod = data;

    if (dataMod < 1) {
      dataMod = 1;
    }

    const maxPage = Math.ceil(totalDataSize2 / rowsDisplay);

    if (dataMod > maxPage && maxPage > 0) {
      dataMod = maxPage;
    }

    const from = Math.max(0, (dataMod - 1) * rowsDisplay);
    criteria.current.pagination.from = from;
    setCurrentPage(dataMod);
  };

  const getSortCompaniesWithPagination = () => {
    const searchFields = searchColumnsData.current;
    getCompanies2({
      variables: {
        criteria: { ...criteria.current },
        searchFields,
      },
    });
  };

  const cancelActiveMetadataDropdownHandler = () => {
    setCompaniesLoading(true);
    getSortCompaniesWithPagination();
    editingReferencesData.current = {};
    editDataPayload.current = [];
    setActiveMetadataDropdown(false);
    setOpenEditConfirmationModal(false);
  };

  const closeDeleteConfirmationAction = () => {
    setOpenConfirmationModal(false);
    setTableLoadingHandler(true);
    getSortCompaniesWithPagination();
    setCompaniesData2([]);
  };

  const deletedDataResponseHandler = (data: DeletedDataResponseType[]) => {
    deletedDataResponse.current = data.map((element: DeletedDataResponseType, index: number) => ({
      ...element,
      key: index,
    }));
    setOpenConfirmationModal(true);
    setTableLoadingHandler(false);
  };

  const [editingCompanies, editingCompaniesResponse] = useMutation(UPDATE_COMPANIES, {
    context: { clientName: 'elastic' },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      editDataPayload.current = [];
      gridDataSelected.current = [];
      if (editingReferencesData.current.optionChanged) {
        openEditConfirmationModalHandler();
      }
      companyDataBackup2.current = companiesData2;
      setCompaniesLoadingHandler(false);
    },
    onError: () => {
      setCompaniesError(true);
    },
  });

  const [deletingCompanies, deletingCompaniesResponse] = useMutation(DELETE_ROWS_MUTATION, {
    context: { clientName: 'elastic' },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      gridDataSelected.current = [];
      deletedDataResponseHandler(data.CompanyBatchRemove);
    },
    onError: () => {
      setCompaniesError(true);
    },
  });

  const sortFunction = (sortOrder: 'ASC' | 'DESC' | 'NONE', activeColumn: string) => {
    const searchFields = clearFilters(searchColumnsData.current);
    criteria.current.orderBy =
      sortOrder !== 'NONE'
        ? {
            field: activeColumn,
            sort: sortOrder,
          }
        : null;
    getCompanies2({
      variables: {
        criteria: { ...criteria.current },
        searchFields,
      },
    });
    localStorage.setItem('tableSortOrderPreferences', JSON.stringify({ sortOrder, activeColumn }));
    localSave('tableSortOrderPreferences', { sortOrder, activeColumn });
    setTableLoadingHandler(true);
  };

  const searchElasticFunction = (
    searchField: string,
    searchValue: string,
    isInputValue?: boolean,
  ) => {
    // Save and retrieve input values from localStorage
    if (searchValue !== 'empty' && !isInputValue) {
      let searchInputValueArr = [];
      if (localStorage.getItem('searchInputValueArr')) {
        const reloadedSearchInputValueArr = JSON.parse(
          localStorage.getItem('searchInputValueArr') ?? '',
        );
        const newElement = { searchField, searchValue };
        const index = reloadedSearchInputValueArr.findIndex(
          (element: { searchField: string; searchValue: string }) =>
            element.searchField === newElement.searchField,
        );
        if (index >= 0) {
          reloadedSearchInputValueArr.splice(index, 1);
          searchInputValueArr = [...reloadedSearchInputValueArr, newElement];
        } else {
          searchInputValueArr = [...reloadedSearchInputValueArr, newElement];
        }
        localStorage.setItem('searchInputValueArr', JSON.stringify(searchInputValueArr));
        localSave('searchInputValueArr', searchInputValueArr);
      } else {
        searchInputValueArr = [{ searchField, searchValue }];
        localStorage.setItem('searchInputValueArr', JSON.stringify(searchInputValueArr));
        localSave('searchInputValueArr', searchInputValueArr);
      }
      localStorage.setItem(`searchInputValue-${searchField}`, searchValue);
    } else {
      localStorage.removeItem(`searchInputValue-${searchField}`);
    }

    if (searchColumnsData.current === undefined) {
      searchColumnsData.current =
        searchValue !== 'empty'
          ? {
              [searchField]: searchValue,
            }
          : undefined;
    } else if (searchValue !== 'empty') {
      searchColumnsData.current[searchField] = searchValue;
    } else {
      delete searchColumnsData.current[searchField];
    }
    const searchFields = clearFilters(searchColumnsData.current);
    getCompanies2({
      variables: {
        criteria: { ...criteria.current },
        searchFields,
      },
    });
    setTableLoadingHandler(true);
  };

  const typeaheadElasticFunction = async (searchField: string, searchValue: string) => {
    if (searchValue === '') {
      setTypeaheadSearchOptions([]);
      const tableColumnSearchPreferences = localStorage.getItem(`searchInputValue-${searchField}`);
      if (tableColumnSearchPreferences) {
        searchElasticFunction(searchField, 'empty');
      }
    } else {
      const column = searchField;
      const filter = searchValue;
      getTypeaheadData({
        variables: {
          column,
          filter,
          enabled: true,
        },
      })
        .then((response) => {
          const innerData = response.data?.GetCompanyUniqueValuesFromColumn;
          const result: { value: string }[] = innerData.map((item: string) => ({ value: item }));
          setTypeaheadSearchOptions(result);
        })
        .catch((err) => {
          if (err.message) {
            setCompaniesError(true);
          }
        });
    }
  };

  const saveEditDataPayload2 = (data: EditingDataPayloadType2) => {
    const innerParentObject = data.parentObject;
    const innerCompanyTypeSelected: any =
      data.newPayloadData[innerParentObject as keyof typeof data.newPayloadData];
    const innerNewColor = companiesCustomTypes.current.find(
      (element) => element.id === innerCompanyTypeSelected?.id,
    );
    const innerCompaniesData = companiesData2;
    const innerIndex = editDataPayload2.current.findIndex((item) => item.id === data.id);
    if (innerIndex >= 0) {
      const innerNewPayloadData = editDataPayload2.current[innerIndex].newPayloadData;
      const sameData = Object.keys(innerNewPayloadData)[0] === Object.keys(data.newPayloadData)[0];
      if (sameData) {
        editDataPayload2.current.splice(innerIndex, 1);
      }
    }
    editDataPayload2.current.push(data);
    if (data.id) {
      const selectedCompany: any = innerCompaniesData.find((item) => item.id === data.id);
      if (selectedCompany && data.parentObject) {
        const newPayloadDataProp: string = Object.keys(data.newPayloadData)[0];
        const newInnerData: any = data.newPayloadData;
        if (newPayloadDataProp === 'companyType') {
          newInnerData[data.parentObject] = {
            ...newInnerData[newPayloadDataProp as keyof typeof newInnerData],
            color: innerNewColor?.color,
          };
        }
        if (newPayloadDataProp !== data.parentObject) {
          selectedCompany[data.parentObject] = {
            ...selectedCompany[data.parentObject],
            ...newInnerData,
          };
        } else {
          selectedCompany[data.parentObject] =
            newInnerData[newPayloadDataProp as keyof typeof newInnerData];
        }
      }
      const index = innerCompaniesData.findIndex((item) => data.id === item.id);
      innerCompaniesData[index] = selectedCompany;
      setCompaniesData2(innerCompaniesData);
    }
  };

  const warningStatusErrorHandler = () => {
    setWarningStatusError(!warningStatusError);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sendNewPayloadData = async (isBulkEdit?: boolean) => {
    let innerWarningStatus;
    editDataPayload2.current.forEach((element: EditingDataPayloadType2) => {
      if (element.warningStatus) {
        setWarningStatusError(true);
        innerWarningStatus = true;
      }
    });
    if (!innerWarningStatus && editDataPayload2.current.length > 0) {
      const companies = editDataPayload2.current.map((element) => {
        const parentObjectDataProp =
          (element.parentObject || Object.keys(element.newPayloadData)[0].split('.')[0]) ?? '';
        const childObjectDataProp =
          Object.keys(element.newPayloadData)[0].split('.')[1] ||
          Object.keys(element.newPayloadData)[0];
        if (parentObjectDataProp !== childObjectDataProp) {
          if (Object.keys(element.newPayloadData)[0].split('.')[1]) {
            return {
              id: element.id,
              [parentObjectDataProp]: {
                [childObjectDataProp]: Object.values(element.newPayloadData)[0],
              },
            };
          }
          return {
            id: element.id,
            [parentObjectDataProp]: element.newPayloadData,
          };
        }
        return {
          id: element.id,
          ...element.newPayloadData,
        };
      });
      setCompaniesLoadingHandler(true);
      await editingCompanies({
        variables: {
          data: {
            companies,
          },
        },
      });
      setActiveEditingColumn(false);
      editDataPayload2.current = [];
    }
  };

  const searchRecurrentDataHandler = (filter: string, column: string) => {
    searchRecurrentData({
      variables: { column, filter, enabled: true },
    });
  };

  const [disableDelButton, setDisableDelButon] = useState(false);
  const [noSelectedRowModal, setNoSelectedRowModal] = useState(false);
  const [activeFilterValue, setActiveFilterValue] = useState(showFilterOptions[0].label);

  const activeStatusFilter = (operation: React.FormEvent<HTMLInputElement>) => {
    localStorage.setItem('tableActiveFilterPreferences', JSON.stringify(operation));
    const internalActiveFilterLabel = showFilterOptions.find(
      (element) => element.value === (operation as unknown as string),
    );
    setActiveFilterValue(
      internalActiveFilterLabel?.label as unknown as React.SetStateAction<string>,
    );
    activeOrInactiveFilter.current = operation as unknown as string;
    activeFilterQueryAction();
  };

  const handleAllDataSelection = (index: number[]) => {
    if (index.length === 0) {
      gridDataSelected.current = [];
    }
    if (index.length > 1) {
      setDisableDelButon(true);
    } else {
      setDisableDelButon(false);
    }
    gridDataSelected.current = index;
  };

  const handleOneDeleteSelection = () => {
    const ids = gridDataSelected.current;
    deletingCompanies({
      variables: {
        ids,
      },
    });
    setTableLoadingHandler(true);
    setDisableDelButon(false);
  };

  const handleConfirmationModal = () => {
    const modalProps = {
      modalTitle: 'Are you sure you want to delete this record',
      modalBody: <p>Any information regarding this company will be lost.</p>,
      okAction: handleOneDeleteSelection,
      cancelAction: () => 0,
      okText: 'Yes',
      cancelText: 'No',
    };
    confirmModal(
      modalProps.modalTitle,
      modalProps.modalBody,
      modalProps.okAction,
      modalProps.cancelAction,
      modalProps.okText,
      modalProps.cancelText,
    );
  };

  const handleNoSelectedRowModal = () => {
    setNoSelectedRowModal(!noSelectedRowModal);
  };

  const deleteRowButtonAction = () => {
    if (gridDataSelected.current.length > 0) {
      handleConfirmationModal();
    } else {
      handleNoSelectedRowModal();
    }
  };

  const companiesValue = useMemo(
    () => ({
      handleAllDataSelection,
      noSelectedRowModal,
      handleNoSelectedRowModal,
      activeStatusFilter,
      deleteRowButtonAction,
      activeFilterValue,
      companiesLoading,
      handleOneDeleteSelection,
      gridDataSelected,
      pagination,
      currentPage,
      activeOrInactiveFilter,
      disableDelButton,
      setPaginationSizeHandler,
      setPageHandler,
      companiesError,
      sortFunction,
      searchElasticFunction,
      setCompaniesErrorHandler,
      activeEditingColumnHandler,
      activeEditingColumn,
      searchRecurrentDataHandler,
      searchRecurrentDataResponse,
      warningStatusErrorHandler,
      sendNewPayloadData,
      warningStatusError,
      tableLoading,
      setTableLoadingHandler,
      editingCompaniesResponse,
      oldDataPayload,
      editDataPayload,
      openConfirmationModal,
      deletedDataResponse,
      closeDeleteConfirmationAction,
      activeCreateCompany,
      createCompanyModalActive,
      criteria,
      searchColumnsData,
      clearAllFilterLoadder,
      activeMetadataDropdown,
      setActiveMetadataDropdownHandler,
      openEditConfirmationModal,
      openEditConfirmationModalHandler,
      editingReferencesData,
      cancelActiveMetadataDropdownHandler,
      selectedOptionHandler,
      selectedFilterField,
      typeaheadElasticFunction,
      typeaheadSearchOptions,
      getTypeaheadDataResponse,
      showEmptyPage,
      showEmptyPageHandler,
      allowCreateCompany,
      allowCreateCompanyHandler,
      progressCreationCompany,
      progressCreationCompanyHandler,
      companiesData2,
      getCompaniesResponse2,
      totalDataSize2,
      saveCompanyData2,
      saveEditDataPayload2,
      createCompanyPayload,
      deletingCompaniesResponse,
      setCompaniesLoadingHandler,
      openMap,
      setOpenMap,
      activeMapSearch,
      setActiveMapSearch,
      refetchData,
    }),
    [
      {
        handleAllDataSelection,
        gridDataSelected,
        noSelectedRowModal,
        handleNoSelectedRowModal,
        activeStatusFilter,
        deleteRowButtonAction,
        activeFilterValue,
        companiesLoading,
        handleOneDeleteSelection,
        pagination,
        activeOrInactiveFilter,
        disableDelButton,
        setPaginationSizeHandler,
        setPageHandler,
        currentPage,
        companiesError,
        sortFunction,
        searchElasticFunction,
        setCompaniesErrorHandler,
        activeEditingColumnHandler,
        activeEditingColumn,
        searchRecurrentDataHandler,
        searchRecurrentDataResponse,
        warningStatusErrorHandler,
        sendNewPayloadData,
        warningStatusError,
        tableLoading,
        setTableLoadingHandler,
        editingCompaniesResponse,
        oldDataPayload,
        editDataPayload,
        openConfirmationModal,
        deletedDataResponse,
        closeDeleteConfirmationAction,
        activeCreateCompany,
        createCompanyModalActive,
        criteria,
        searchColumnsData,
        clearAllFilterLoadder,
        activeMetadataDropdown,
        setActiveMetadataDropdownHandler,
        openEditConfirmationModal,
        openEditConfirmationModalHandler,
        editingReferencesData,
        cancelActiveMetadataDropdownHandler,
        selectedOptionHandler,
        selectedFilterField,
        typeaheadElasticFunction,
        typeaheadSearchOptions,
        getTypeaheadDataResponse,
        showEmptyPage,
        showEmptyPageHandler,
        allowCreateCompany,
        allowCreateCompanyHandler,
        progressCreationCompany,
        progressCreationCompanyHandler,
        companiesData2,
        getCompaniesResponse2,
        totalDataSize2,
        saveCompanyData2,
        saveEditDataPayload2,
        deletingCompaniesResponse,
        setCompaniesLoadingHandler,
        openMap,
        setOpenMap,
        activeMapSearch,
        setActiveMapSearch,
        refetchData,
      },
    ],
  );

  useEffect(() => {
    if (companiesData2.length === 0) {
      setCompaniesLoading(true);
    } else {
      setTableLoadingHandler(true);
    }
    if (pagination !== 1) {
      getSortCompaniesWithPagination();
    }
    if (isCompaniesPath) {
      getCompaniesCustomTypes({
        variables: {
          criteria: {
            pagination: {
              from: 0,
              size: 100,
            },
          },
        },
      });
    }
  }, [pagination, currentPage]);

  useEffect(() => {
    const tableActiveFilterPreferences = localStorage.getItem('tableActiveFilterPreferences');
    const tableSortPreferences = localStorage.getItem('tableSortOrderPreferences');
    if (tableActiveFilterPreferences) {
      activeStatusFilter(JSON.parse(tableActiveFilterPreferences));
    }
    if (tableSortPreferences) {
      sortFunction(
        JSON.parse(tableSortPreferences).sortOrder,
        JSON.parse(tableSortPreferences).activeColumn,
      );
    }
  }, [companiesHeaderResponse2?.data]);

  return <CompaniesContext.Provider value={companiesValue}>{children}</CompaniesContext.Provider>;
};

export { CompaniesContext, CompaniesContextProvider };
