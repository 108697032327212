import { gql } from '@apollo/client';

export const CREATE_COMPANY_RANK = gql`
  mutation CreateCompanyRank($createCompanyRankInput: CreateCompanyRankInput!) {
    CreateCompanyRank(createCompanyRankInput: $createCompanyRankInput) {
      id
      name
    }
  }
`;

export const UPDATE_COMPANY_RANK = gql`
  mutation UpdateCompanyRank($updateCompanyRankInput: UpdateCompanyRankInput!) {
    UpdateCompanyRank(updateCompanyRankInput: $updateCompanyRankInput) {
      name
    }
  }
`;

export const DELETE_COMPANY_RANK = gql`
  mutation RemoveCompanyRank($id: Int!) {
    RemoveCompanyRank(id: $id)
  }
`;
