import { useState } from 'react';
import { Checkbox, Select, Tag } from 'antd';
// eslint-disable-next-line no-duplicate-imports
import type { SelectProps } from 'antd';

interface InnerMultiSelectProps {
  options?: SelectProps['options'];
  defaultValue?: string | string[];
  multiSel?: boolean;
  handleEditData?: (event: any, inputType?: string) => void;
  handleCreateData?: (event: any, inputType?: string) => void;
  status: InterfaceStatus;
  externalValue?: any;
  handleExternalValue?: (data: any) => void;
  defaultOpen?: boolean;
  addNewRecord?: boolean;
  handleDataStatus?: () => void;
}

export type InterfaceStatus = '' | 'warning' | 'error' | undefined;

const InnerCustomSelect: React.FC<InnerMultiSelectProps> = ({
  options,
  defaultValue,
  multiSel,
  handleEditData,
  handleCreateData,
  status,
  handleExternalValue,
  defaultOpen,
  addNewRecord,
  handleDataStatus,
}) => {
  const innerDefaultOption =
    defaultValue && !multiSel
      ? options?.find((element) => element.label === defaultValue)?.value
      : defaultValue;
  const [selectedOptions, setSelectedOptions] = useState<string | string[]>(
    !multiSel ? String(innerDefaultOption) : (innerDefaultOption as string[]),
  );

  const renderOptionWithCheckboxMulti = (option: { value: string; label: string }) => {
    const isChecked = (selectedOptions as string[])?.some((element) => option.value === element);

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '0px !important',
        }}
      >
        <Checkbox checked={isChecked} style={{ marginRight: 5 }} />
        <p>{option.label}</p>
      </div>
    );
  };

  if (!multiSel) {
    return (
      <Select
        style={{ width: '100%', border: status === 'error' ? '1px solid red' : undefined }}
        className='customSelect'
        defaultValue={innerDefaultOption as string}
        defaultOpen={defaultOpen}
        autoFocus={defaultOpen}
        value={selectedOptions}
        options={options}
        onChange={(selectedValues) => {
          if (handleExternalValue) {
            handleExternalValue(selectedValues);
          }
          setSelectedOptions(selectedValues);
          if (handleCreateData) {
            handleCreateData(selectedValues, 'dropdown');
          }
        }}
        onBlur={() =>
          handleEditData && status !== 'error' && handleEditData(selectedOptions, 'dropdown')
        }
        onKeyDown={(event) => {
          if (event.nativeEvent.key === 'Enter' && handleEditData && status !== 'error') {
            handleEditData(selectedOptions, 'dropdown');
          }
          if (
            event.nativeEvent.key === 'Enter' &&
            addNewRecord &&
            handleDataStatus &&
            status !== 'error'
          ) {
            handleDataStatus();
          }
        }}
      />
    );
  }

  return (
    <Select
      className='customSelect'
      style={{ width: '100%', border: status === 'error' ? '1px solid red' : undefined }}
      defaultValue={defaultValue as string[]}
      value={selectedOptions}
      maxTagCount='responsive'
      defaultOpen={defaultOpen}
      autoFocus={defaultOpen}
      mode='multiple'
      allowClear
      // eslint-disable-next-line react/no-unstable-nested-components
      maxTagPlaceholder={(omittedValues) => (
        <Tag style={{ background: '#DCDFFB', border: '1px solid #0078D4' }}>
          {omittedValues.length + 1} Total
        </Tag>
      )}
      options={options}
      // eslint-disable-next-line react/no-unstable-nested-components
      optionRender={(option) =>
        renderOptionWithCheckboxMulti(option.data as { value: string; label: string })
      }
      onChange={(selectedValues) => {
        if (handleExternalValue) {
          handleExternalValue(selectedValues);
        }
        setSelectedOptions(selectedValues);
        if (handleCreateData) {
          handleCreateData(selectedValues, 'multiselect');
        }
      }}
      onBlur={() =>
        handleEditData && status !== 'error' && handleEditData(selectedOptions, 'multiselect')
      }
      onKeyDown={(event) => {
        if (event.nativeEvent.key === 'Enter' && handleEditData && status !== 'error') {
          handleEditData(selectedOptions, 'multiselect');
        }
        if (
          event.nativeEvent.key === 'Enter' &&
          addNewRecord &&
          handleDataStatus &&
          status !== 'error'
        ) {
          handleDataStatus();
        }
      }}
    />
  );
};

export default InnerCustomSelect;
