import { CopyOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';
import copyToClipboard from 'src/utils/functions/copyClipboard';
import { English } from 'src/languages/english';
import { SH4CardCompanyContact, SH5CardCompanyContact } from './styles/index.styles';

interface Props {
  sellerName: string;
  title?: string;
  phone?: string | English.noData;
  email?: string;
}

const ContactInfo: FC<Props> = ({ sellerName, title, phone, email }) => (
  <div>
    <SH4CardCompanyContact data-testid='seller-name'>{sellerName}</SH4CardCompanyContact>
    {title && (
      <SH4CardCompanyContact style={{ fontSize: 16 }} data-testid='company-name'>
        {title}
      </SH4CardCompanyContact>
    )}
    {phone && (
      <SH5CardCompanyContact data-testid='company-phone'>
        <PhoneOutlined />
        {phone !== English.noData ? <a href={`tel:${phone}`}>{phone}</a> : <span>{phone}</span>}
        {phone !== English.noData && (
          <Button onClick={() => phone && copyToClipboard(JSON.stringify(phone))}>
            <CopyOutlined />
          </Button>
        )}
      </SH5CardCompanyContact>
    )}
    {email && (
      <SH5CardCompanyContact data-testid='company-email'>
        <MailOutlined />
        <span>{email}</span>
        {email !== English.noData && (
          <Button onClick={() => copyToClipboard(email)}>
            <CopyOutlined />
          </Button>
        )}
      </SH5CardCompanyContact>
    )}
  </div>
);
export default ContactInfo;
