import { Form } from 'antd';
import styled from 'styled-components';

const StyledModalCreateEdit = styled(Form)`
  .custom-modal-edit {
    display: flex;
    align-items: space-between !important;
    justify-content: space-between !important;
  }
`;

export default StyledModalCreateEdit;
