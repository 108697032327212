import ClientInformationCard from '../components';

const DRAG_LIST_CONFIGURATIONS_SECTIONS = [
  {
    title: 'Client Information',
    content: <ClientInformationCard />,
  },
];

export default DRAG_LIST_CONFIGURATIONS_SECTIONS;
