import useEntityAdapter, { OperationSpecs } from 'src/hooks/useEntityAdapter';
import { GET_ALL_OPP_LINE_ITEM_STATUS } from '../graphql/queries';
import {
  UPDATE_OPP_LINE_ITEM_STATUS,
  CREATE_OPP_LINE_ITEM_STATUS,
  REMOVE_OPP_LINE_ITEM_STATUS,
} from '../graphql/mutations';
import { LineItemStatusModel } from '../models/lineItemStatus.model';
import { lineItemStatusAdapter } from '../adapters/lineItemStatus.adapter';

const operations: OperationSpecs = {
  createOne: {
    op: CREATE_OPP_LINE_ITEM_STATUS,
  },
  updateOne: {
    op: UPDATE_OPP_LINE_ITEM_STATUS,
  },
  deleteOne: {
    op: REMOVE_OPP_LINE_ITEM_STATUS,
  },
  fetchAll: {
    op: GET_ALL_OPP_LINE_ITEM_STATUS,
    dataPath: 'GetAllOpportunityLineItemStatus',
  },
};

const useOpportunityLineItemStatusStore = () => {
  const api = useEntityAdapter<LineItemStatusModel>({
    adapters: { entityAdapter: lineItemStatusAdapter },
    operations,
    isPaginated: true,
    entityName: 'OpportunityLineItemStatus',
  });

  return api;
};

export default useOpportunityLineItemStatusStore;
