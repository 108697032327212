import TableCustomTypes from '../components/customTypes/TableCustomTypes';
import TablePredefinedTypes from '../components/predefinedTypes/TablePredefinedTypes';

const DRAG_LIST_TYPE_SECTIONS = [
  {
    title: 'Predefined Types',
    content: <TablePredefinedTypes />,
  },
  {
    title: 'Custom Types',
    content: <TableCustomTypes />,
  },
];

export default DRAG_LIST_TYPE_SECTIONS;
