import { Navigate, Route, Routes } from 'react-router';
import NotFoundPage from 'src/components/commons/notFound';
import OppSetupLayout from 'src/layouts/oppSetup.layout';
import { OppBasicTab } from 'src/components/opportunityDetail';
import PageOpportunity from './pages';

const OpportunitiesRoutes = () => (
  <Routes>
    <Route path='/'>
      <Route path='/' element={<PageOpportunity />} />
      <Route path='/:id' element={<OppSetupLayout />}>
        <Route index path='basic' element={<OppBasicTab />} />
        <Route index path='*' element={<Navigate to='basic' replace />} />
      </Route>
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  </Routes>
);

export default OpportunitiesRoutes;
