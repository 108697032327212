import { Form, message } from 'antd';
import { CustomModal } from 'src/components/commons/customModal';
import CustomInput from 'src/components/commons/customInput';
import { useMemo, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import CenteredPopover from './centeredPopover';

interface ChangePasswordModalProps {
  modalStatus: boolean;
  onClose: () => void;
}

// eslint-disable-next-line react/prop-types
const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ modalStatus, onClose }) => {
  const [data, setData] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  const isValidPassword = useMemo(() => {
    const { newPassword } = data;
    const hasMinLength = newPassword.length >= 8;
    const hasUpperCase = /[A-Z]/.test(newPassword);
    const hasLowerCase = /[a-z]/.test(newPassword);
    const hasSpecialChars = /[^a-zA-Z0-9\s]/.test(newPassword);
    const hasNumericDigits = /\d/.test(newPassword);

    return hasMinLength && hasUpperCase && hasLowerCase && hasSpecialChars && hasNumericDigits;
  }, [data.newPassword]);

  const passwordsMatch = useMemo(() => {
    const { newPassword, confirmPassword } = data;
    return newPassword === confirmPassword;
  }, [data.newPassword, data.confirmPassword]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleOkClick = () => {
    onClose();
    message.success({
      className: 'custom-message',
      content: (
        <div>
          <span className='custom-message-title'>Password Changed!</span>
          <span className='custom-message-body'>
            Remember to use your new password next time you try to log in.
          </span>
        </div>
      ),
    });
  };

  return (
    <CustomModal
      modalStatus={modalStatus}
      modalTitle='Change Password'
      modalBody={
        <div style={{ margin: '8px 0' }}>
          <div style={{ color: '#858585' }}>
            <p>Password must contain</p>
            <ul>
              <li>At least 8 characters</li>
              <li>Upper case letters</li>
              <li>Lower case letters</li>
              <li>Special characters {'(*+-/?.,<>@#$%^&-and”’;:{}][\\|)'} </li>
              <li>Numeric digits</li>
            </ul>
          </div>

          <Form onSubmitCapture={(ev) => ev.preventDefault()}>
            <CustomInput
              required
              label='New Password'
              placeholder='Type here...'
              name='newPassword'
              type='password'
              dataTestId='newPassword'
              value={data.newPassword}
              handleChange={handleChange}
              invalid={!isValidPassword}
              valid={isValidPassword}
              suffix={
                !isValidPassword ? (
                  <CenteredPopover
                    message='Not all requirements have been completed, please check your password.'
                    title='Incorrect Password'
                  >
                    <InfoCircleOutlined style={{ color: 'red' }} />
                  </CenteredPopover>
                ) : (
                  <span />
                )
              }
            />
            <CustomInput
              required
              label='Re-enter Password'
              placeholder='Type here...'
              name='confirmPassword'
              type='password'
              dataTestId='confirmPassword'
              value={data.confirmPassword}
              handleChange={handleChange}
              invalid={!passwordsMatch}
              valid={!!data.confirmPassword && passwordsMatch}
              suffix={
                !passwordsMatch ? (
                  <CenteredPopover
                    message='Passwords don’t match, please check.'
                    title='Incorrect Password'
                  >
                    <InfoCircleOutlined style={{ color: 'red' }} />
                  </CenteredPopover>
                ) : (
                  <span />
                )
              }
            />
          </Form>
        </div>
      }
      invalid={!isValidPassword || !passwordsMatch}
      okText='Save'
      onOkAction={handleOkClick}
      onCancelAction={onClose}
    />
  );
};

export default ChangePasswordModal;
