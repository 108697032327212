import { ChangeEvent, FC } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import StyledFileInputContainer from '../styles/fileInputContainer.style';
import StyledFileInput from '../styles/fileInput.styles';
import StyledFileInputWrapper from '../styles/fileInputWrapper.style';
import FileItem from './fileItem';

type Props = {
  accept?: string;
  value?: FileList | File[] | string[] | null;
  onChange?: (files: FileList | File[] | string[] | null) => void;
};

const getMessageAndAccept = (accept?: string) => {
  if (!accept) {
    return {};
  }

  if (accept.startsWith('image')) {
    return { message: 'Support only .jpg .png or other images files', accept: 'image/*' };
  }

  const acceptList = accept.split(',');
  const message = `Support only ${acceptList.join(', ')} files.`;

  return { message, accept };
};

const getFineNames = (files: FileList | File[] | string[] | null | undefined) => {
  if (!files) {
    return [];
  }

  if (Array.isArray(files)) {
    return files.map((file) => (typeof file === 'string' ? file : file.name));
  }

  const filesList = Array.from(files);
  return filesList.map((file) => file.name);
};

const FileInput: FC<Props> = ({ onChange, value, accept }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const { files } = target;
    if (onChange) {
      onChange(files);
    }
  };

  const handleDeleteFile = (index: number) => {
    if (onChange && value) {
      const files = Array.isArray(value) ? value : Array.from(value);
      files.splice(index, 1);
      onChange(files);
    }
  };

  const { message, accept: acceptPattern } = getMessageAndAccept(accept);
  const names = getFineNames(value);

  return (
    <StyledFileInputWrapper>
      <StyledFileInputContainer>
        <InboxOutlined style={{ fontSize: 24 }} />
        <span>Click or drag file to this area to upload</span>
        {message && <span style={{ color: '#858585' }}>{message}</span>}
        <StyledFileInput type='file' accept={acceptPattern} onChange={handleChange} />
      </StyledFileInputContainer>
      {!!names.length && (
        <div>
          {names.map((name, index) => (
            <FileItem key={name} name={name} onDelete={() => handleDeleteFile(index)} />
          ))}
        </div>
      )}
    </StyledFileInputWrapper>
  );
};

export default FileInput;
