import { gql } from '@apollo/client';

export const EDIT_ADDITIONAL_FIELDS = gql`
  mutation UpdateCompanyAdditionalField(
    $updateCompanyAdditionalFieldInput: UpdateCompanyAdditionalFieldInput!
  ) {
    UpdateCompanyAdditionalField(
      updateCompanyAdditionalFieldInput: $updateCompanyAdditionalFieldInput
    ) {
      company {
        id
      }
      additionalField {
        id
      }
      id
      value
    }
  }
`;

export const CREATE_ADDITIONAL_FIELDS = gql`
  mutation CreateCompanyAdditionalField(
    $createCompanyAdditionalFieldInput: CreateCompanyAdditionalFieldInput!
  ) {
    CreateCompanyAdditionalField(
      createCompanyAdditionalFieldInput: $createCompanyAdditionalFieldInput
    ) {
      additionalField {
        id
      }
      id
      value
      company {
        id
      }
    }
  }
`;
