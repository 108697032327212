const base64Decode = (base64String: string): string => {
  let prefix: string;

  if (base64String?.startsWith('/9j/')) {
    prefix = 'data:image/jpeg;base64,';
  } else if (base64String?.startsWith('iVBORw0KGgo')) {
    prefix = 'data:image/png;base64,';
  } else {
    throw new Error('Unsupported image format');
  }

  return `${prefix}${base64String}`;
};

export default base64Decode;
