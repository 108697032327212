import React from 'react';
import { Typography, Form, Row } from 'antd';
import CustomButton from 'src/components/commons/customButton';
import { ClockCircleOutlined } from '@ant-design/icons';
import { FormElementsOptions } from 'src/types/companiesTypes';
import { CustomStyledInput } from '../createCompany.styles';
import { CreateCompanyBodyRendererTypes } from '../types';

const CommentsRenderer: React.FC<CreateCompanyBodyRendererTypes> = ({
  addDateAndTimeIntoComments,
  formElementsOptions,
  handleTextAreaChange,
}) => (
  <>
    <Row align='bottom' justify='space-between'>
      <Typography.Title level={4} className='section-title'>
        Comments{' '}
      </Typography.Title>
      <CustomButton
        type='text'
        icon={<ClockCircleOutlined />}
        style={{ borderRadius: 0, color: 'rgb(0, 10, 104)' }}
        onlyIcon
        dataTestId='add-date-time-button'
        placement='topRight'
        tooltipColor='#CCCCCC'
        tooltipText='Add Current Date/Time'
        tooltipClassName='custom-tooltip'
        onClick={addDateAndTimeIntoComments}
      />
    </Row>
    <Form.Item
      name='comments'
      required={
        formElementsOptions?.find((option: FormElementsOptions) => option.name === 'comments')
          ?.required ?? false
      }
    >
      <CustomStyledInput.TextArea
        onChange={handleTextAreaChange}
        rows={4}
        style={{ borderRadius: 0 }}
        name='comments'
        data-testid='comments-textarea'
      />
    </Form.Item>
  </>
);

export default CommentsRenderer;
