import SubTable from 'src/components/commons/subTable';
import { useExportCompaniesContext } from '../../contexts/export-companies.context';

const ExportCompaniesPreview = () => {
  const { dataSource, onListRender, headers } = useExportCompaniesContext();
  return (
    <SubTable
      data={dataSource}
      onListRender={onListRender}
      columns={headers}
      scroll={{ y: window.innerHeight - 350, x: 1200 }}
      selectedRows={undefined}
      nonSelectable
      nonAddable
      nonGlobalFilterable
      showOptionFilterLeft={false}
      nonFilterable
      id='export-companies-table'
    />
  );
};

export default ExportCompaniesPreview;
