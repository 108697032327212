import { FC } from 'react';
import { useLocation } from 'react-router';
import { SubTableGridProps } from './types';
import SubTableProvider from './contexts/subtable.context';
// eslint-disable-next-line import/no-cycle
import SubTableHeader from './components/header';
import StyledSubTable from './styles/subTable.style';
import AdvancedFilterWithSubTableGrid from './components/AdvancedFilterWithSubTableGrid';

type Props = Pick<
  SubTableGridProps,
  | 'columns'
  | 'setColumns'
  | 'columnsToSelectOption'
  | 'columnsHeaderSelected'
  | 'showOptionFilterLeft'
  | 'leftFilters'
  | 'leftFiltersOptions'
  | 'selectedLeftFilter'
  | 'setSelectedLeftFilter'
  | 'showHeaderSelect'
  | 'data'
  | 'scroll'
  | 'nonFilterable'
  | 'nonAddable'
  | 'nonDeletable'
  | 'nonEditable'
  | 'pinnableColumns'
  | 'withDragHandler'
  | 'nonSelectable'
  | 'showAutoIncrement'
  | 'allowGlobalEditActive'
  | 'activeOnSelectRowsDisableEdit'
  | 'editBySelected'
  | 'onRowClick'
  | 'onListRender'
  | 'onFilterChange'
  | 'onGlobalFilterChange'
  | 'onSortChange'
  | 'handleUpdateRows'
  | 'autocompleteFieldProvider'
  | 'autocompleteGlobalProvider'
  | 'columnFilterShown'
  | 'nonGlobalFilterable'
  | 'pagination'
  | 'total'
  | 'onPaginationChange'
  | 'page'
  | 'pageSize'
  | 'loading'
  | 'actionCell'
  | 'deleteIcon'
  | 'favIcon'
  | 'editIcon'
  | 'detailsIcon'
  | 'ellipsisIcon'
  | 'onEditClick'
  | 'onDeleteClick'
  | 'onDetailsClick'
  | 'headerActions'
  | 'id'
  | 'postData'
  | 'newRecord'
  | 'resetNewRecord'
  | 'saveNewRecord'
  | 'dataToCompare'
  | 'clearFilter'
  | 'storageKey'
  | 'storageType'
  | 'selectedRows'
  | 'contentOnDelete'
  | 'titleOnDelete'
  | 'detailsTable'
  | 'onFavClick'
  | 'keyExpandableRow'
  | 'addNewButtonDetailsTable'
  | 'isSubTableExpandable'
  | 'dataForListInHeader'
  | 'filterByHeaderListServer'
  | 'filterByHeaderListClient'
  | 'BodyModal'
  | 'addWithModal'
  | 'onAddClick'
  | 'titleModalNew'
  | 'titleModalEdit'
  | 'expandable'
  | 'notEditWithModal'
  | 'clickedRecord'
  | 'headerSelectDefaultValue'
  | 'isModalCreateEditOpen'
  | 'setIsModalCreateEditOpen'
  | 'onSelectRecord'
  | 'searchPlaceholder'
  | 'clickable'
  | 'handleCollapseToggle'
  | 'editRecord'
  | 'showDetailsIcon'
  | 'headerMessage'
  | 'handleOnPin'
  | 'items'
  | 'handleOnReload'
  | 'useUniqueValues'
  | 'setSearchFields'
  | 'searchFields'
  | 'handleClearFilter'
  | 'filterCount'
  | 'showFilterBadge'
  | 'handleAllRowActions'
>;

const SubTableV1: FC<Props> = (props) => {
  const {
    nonFilterable,
    nonAddable,
    nonDeletable,
    nonEditable,
    nonSelectable,
    nonGlobalFilterable,
    columnFilterShown,
    headerActions,
    pagination,
    autocompleteFieldProvider,
    autocompleteGlobalProvider,
    onFilterChange,
    onGlobalFilterChange,
    onSortChange,
    addWithModal,
    onAddClick,
    clickedRecord,
    isModalCreateEditOpen,
    setIsModalCreateEditOpen,
    onSelectRecord,
    clickable,
    clearFilter,
    headerMessage,
    handleOnPin,
    handleOnReload,
    useUniqueValues,
    setSearchFields,
    searchFields,
  } = props;

  const location = useLocation();
  const hideSubtableBorders =
    location.pathname.includes('subtables') || location.pathname.includes('settings');

  return (
    <SubTableProvider
      nonFilterable={nonFilterable}
      nonAddable={nonAddable}
      nonDeletable={nonDeletable}
      nonEditable={nonEditable}
      nonSelectable={nonSelectable}
      columnFilterShown={columnFilterShown}
      nonGlobalFilterable={nonGlobalFilterable}
      autocompleteFieldProvider={autocompleteFieldProvider}
      autocompleteGlobalProvider={autocompleteGlobalProvider}
      onFilterChange={onFilterChange}
      onGlobalFilterChange={onGlobalFilterChange}
      onSortChange={onSortChange}
      headerActions={headerActions}
      pagination={pagination}
      clickedRecord={clickedRecord}
      isModalCreateEditOpen={isModalCreateEditOpen}
      setIsModalCreateEditOpen={setIsModalCreateEditOpen}
      onSelectRecord={onSelectRecord}
      clearFilter={clearFilter}
      handleOnPin={handleOnPin}
      handleOnReload={handleOnReload}
      useUniqueValues={useUniqueValues}
      setSearchFieldsOnStore={setSearchFields}
      searchFieldsOnStore={searchFields}
      {...props}
    >
      <StyledSubTable $hideBorders={hideSubtableBorders} $clickable={clickable}>
        <SubTableHeader
          addWithModal={addWithModal}
          onAddClick={onAddClick}
          headerMessage={headerMessage}
        />
        <AdvancedFilterWithSubTableGrid {...props} />
      </StyledSubTable>
    </SubTableProvider>
  );
};

export default SubTableV1;
