import React, { PropsWithChildren } from 'react';
import FormSectionStyled from './formSection.styled';

const FormSection: React.FC<PropsWithChildren<{ title: string; suffix?: React.ReactNode }>> = ({
  title,
  children,
  suffix,
}) => (
  <FormSectionStyled style={{ marginBottom: '49px' }}>
    <div className='form-section-title'>
      <div className='form-section-title-text'>{title}</div>
      {suffix && <div className='form-section-title-suffix'>{suffix}</div>}
    </div>
    <div className='form-section-content'>{children}</div>
  </FormSectionStyled>
);

export default FormSection;
