import { Modal } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

const { success } = Modal;

export const successModal = (props?: {
  title?: string;
  content?: string;
  onClose?: () => void;
}) => {
  success({
    title: props?.title || 'Success',
    content: props?.content || 'Record created',
    icon: <CheckCircleOutlined color='#82C785' />,
    afterClose: props?.onClose,
    maskClosable: true,
    className: 'successModal',
    okButtonProps: {
      style: { display: 'none' },
    },
  });
};
