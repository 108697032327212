import { useState } from 'react';
import {
  AllFilters2,
  CallPatternFilterInput,
  ValueType,
  FilterCallPatternEnum,
} from 'src/graphql/schema-types';

const useFilterColumn = () => {
  const [columnFilters, setColumnFilters] = useState<CallPatternFilterInput[]>([]);

  const createdFilters = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const newFilter = {
      fieldName: searchField as FilterCallPatternEnum,
      value: search,
      dataType: dataType as ValueType,
      operator: AllFilters2.Contains,
    };

    let newFilters: CallPatternFilterInput[] = [];

    if (columnFilters.length === 0) {
      newFilters = [newFilter];
      setColumnFilters(newFilters);
    } else {
      const foundFilter = columnFilters.find((filter) => filter.fieldName === searchField);

      if (!foundFilter) {
        newFilters = [...columnFilters, newFilter];
        setColumnFilters(newFilters);
      } else {
        newFilters = columnFilters.map((filter) => {
          if (filter.fieldName === searchField) {
            return {
              ...filter,
              value: search,
            };
          }

          return filter;
        });

        setColumnFilters(newFilters);
      }
    }

    return newFilters;
  };

  return {
    createdFilters,
    columnFilters,
    setColumnFilters,
  };
};

export default useFilterColumn;
