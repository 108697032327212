import styled from 'styled-components';

const StyledFileInputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  flex: 1;
  line-height: 1;
`;

export default StyledFileInputContainer;
