import { useEffect, useState } from 'react';
import { Option, SortModel, SubTableColumn } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { AllFilters2 } from 'src/graphql/schema-types';
import useDivisionsStore from 'src/hooks/stores/useDivisionsStore';
import { DivisionModel } from 'src/models';
import COLUMNS from '../constants';

const useDivisionsSettingsTable = () => {
  const [deleting, setDeleting] = useState(false);
  const {
    loading,
    reset,
    dataSource,
    loadPage: onListRender,
    fetchAll,
    deleteOne,
    updateOne,
    createOne,
  } = useDivisionsStore();

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState<SubTableColumn[]>([]);
  const [newRecord, setNewRecord] = useState({
    name: '',
  });

  useEffect(() => {
    setColumns(COLUMNS);
  }, []);

  const autocompleteFieldSearch = (
    searchField: string,
    value: string,
    dataType: string | number | string[],
  ) =>
    fetchAll({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },
          filter: [
            {
              fieldName: searchField,
              value,
              dataType,
              operator: AllFilters2.Contains,
            },
          ],
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => {
      if (res.data) {
        return {
          results: res?.data?.GetAllDivisions as any[],
          filter: res?.data?.GetAllDivisions?.map((result: any) => ({
            value: result[searchField],
            label: result[searchField],
          })) as Option[],
        };
      }

      return {
        results: [],
        filter: [],
      };
    });

  const handleUpdateRows = (rows: DivisionModel[]) => {
    rows.forEach((row) => {
      const { id, name } = row;
      updateOne({
        variables: {
          updateCompanyDivisionInput: {
            id,
            name: capitalizeFirstWord(name!),
          },
        },
      });
    });
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
    });
  };

  const createNewRecord = async (record: DivisionModel) => {
    const { name } = record;
    if (name === '') return;
    await createOne({
      variables: {
        createDivisionInput: {
          name: capitalizeFirstWord(name as string),
        },
      },
    });
    resetNewRecord();
    reset();
  };

  const handleDeleteRows = async (rows: DivisionModel[]) => {
    setDeleting(true);
    await Promise.all(rows.map((row) => deleteOne(row.id)));
    setDeleting(false);
  };

  const handleClearFilter = () => {
    reset();
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    handleUpdateRows,
    newRecord,
    postData,
    createNewRecord,
    resetNewRecord,
    spinning: loading || deleting,
    dataSource,
    onListRender,
    handleClearFilter,
    handleDeleteRows,
  };
};

export default useDivisionsSettingsTable;
