import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { SalesTeamModel } from 'src/models';
import { optional } from './common';
import companySplitTypeAdapter from './companySplitType.adapter';
import salesTeamTypeAdapter from './salesTeamType.adapter';

const salesTeamAdapter: AdapterFunc<GQL.SalesTeamModel, SalesTeamModel> = (input) => ({
  id: input.id,
  companySplit: input.companySplit!,
  companySplitType: optional(companySplitTypeAdapter, input.companySplitType),
  name: input.name!,
  specifierSplit: input.specifierSplit!,
  type: optional(salesTeamTypeAdapter, input.type),
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
});

export default salesTeamAdapter;
