import { useContext } from 'react';
import { CompaniesContext } from 'src/context/companies.context';
import { TableContext } from 'src/context/table.context';
import { notification } from 'antd';
import CustomTable from '../commons/customTable/customTable';
import ButtonReloadClearCache from '../buttonReloadClearCache/ButtonReloadClearCache';
import TempSkeleton from '../commons/tempSkeleton';

const Companies = () => {
  const {
    rowsDisplay2,
    moveToRightTable,
    enterPage,
    totalPages,
    handleSetCompaniesCol2,
    companiesCol2,
    gridPreferenceDataToSave,
    saveGridPreference,
    calculateRealPositionColumn,
    calculateRealPositionColumn2,
    selectedSideTableFilter,
    tablesError,
    tableErrorHandler,
  } = useContext(TableContext);

  const {
    handleAllDataSelection,
    noSelectedRowModal,
    handleNoSelectedRowModal,
    pagination,
    saveCompanyData2,
    warningStatusError,
    warningStatusErrorHandler,
    tableLoading,
    openConfirmationModal,
    deletedDataResponse,
    closeDeleteConfirmationAction,
    activeMetadataDropdown,
    openEditConfirmationModal,
    cancelActiveMetadataDropdownHandler,
    createCompanyModalActive,
    activeCreateCompany,
    progressCreationCompany,
    companiesLoading,
    companiesData2,
    companiesError,
    openMap,
    setOpenMap,
    setCompaniesErrorHandler,
  } = useContext(CompaniesContext);

  if (companiesLoading) {
    return <TempSkeleton />;
  }

  if (companiesError || tablesError) {
    notification.error({
      message: 'Error fetching companies',
      description: 'Some companies could not be loaded. Try to reload the page.',
      key: 'error-fetching-companies',
      btn: <ButtonReloadClearCache />,
    });
    tableErrorHandler(false);
    setCompaniesErrorHandler(false);
  }

  return (
    <CustomTable
      data2={companiesData2}
      entityColumns2={companiesCol2}
      tableLoading={tableLoading}
      gridPreferenceDataToSave={gridPreferenceDataToSave}
      rowsDisplay2={rowsDisplay2}
      noSelectedRowModal={noSelectedRowModal}
      moveToRightTable={moveToRightTable}
      enterPage={enterPage}
      totalPages={totalPages}
      handleSetCompaniesCol2={handleSetCompaniesCol2}
      calculateRealPositionColumn={calculateRealPositionColumn}
      calculateRealPositionColumn2={calculateRealPositionColumn2}
      selectedSideTableFilter={selectedSideTableFilter}
      handleAllDataSelection={handleAllDataSelection}
      handleNoSelectedRowModal={handleNoSelectedRowModal}
      pagination={pagination}
      saveCompanyData2={saveCompanyData2}
      warningStatusError={warningStatusError}
      warningStatusErrorHandler={warningStatusErrorHandler}
      openConfirmationModal={openConfirmationModal}
      deletedDataResponse={deletedDataResponse}
      closeDeleteConfirmationAction={closeDeleteConfirmationAction}
      activeMetadataDropdown={activeMetadataDropdown}
      openEditConfirmationModal={openEditConfirmationModal}
      cancelActiveMetadataDropdownHandler={cancelActiveMetadataDropdownHandler}
      createCompanyModalActive={createCompanyModalActive}
      activeCreateCompany={activeCreateCompany}
      progressCreationCompany={progressCreationCompany}
      saveGridPreference={saveGridPreference}
      openMap={openMap}
      setOpenMap={setOpenMap}
    />
  );
};

export default Companies;
