import AdditionalInfoForm from '../internalComponents/AdditionalsFields';
import DesignRegistration from '../internalComponents/DesignRegistration';

import OppInformation from '../internalComponents/OppInformation';

export const DRAG_LIST_SECTIONS = [
  {
    title: 'Opportunity Information',
    content: <OppInformation requiredFields={['topic']} />,
    notDraggable: true,
    notCloseable: true,
  },
  {
    title: 'Additional Information',
    content: <AdditionalInfoForm />,
  },
  {
    title: 'Design Registration',
    content: <DesignRegistration />,
  },
];
