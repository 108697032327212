import styled from 'styled-components';

const StyledUserAndMembers = styled.div`
  .first-table,
  .second-table {
    max-width: calc(50% - 13px);
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 1rem;
    height: calc(100vh - 16rem);

    @media (max-width: 768px) {
      margin-bottom: 1.5rem;
      min-width: 100%;
    }
  }
`;

export default StyledUserAndMembers;
