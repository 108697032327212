import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import type { ActionType } from '@rc-component/trigger/lib/interface';
import { Checkbox, Row, Col, Input, Empty, Popover } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { TooltipPlacement } from 'antd/es/tooltip';
import { Option } from 'src/components/commons/subTable/types/index';
import PinButton from '../commons/subTableV1/components/pinButton';
import { SubTableColumn } from '../commons/subTableV1/types';

type Props = {
  children?: ReactNode | undefined;
  options: Option[];
  optionsSelected?: string[];
  onCheckboxChange: (values: CheckboxValueType[]) => void;
  onOpenChange?: (visible: boolean) => void;
  trigger?: ActionType | ActionType[];
  placement?: TooltipPlacement;
  noSearch?: boolean;
  hasPin?: boolean;
  onPinClick?: (arg: any) => void;
};

const { Search } = Input;

const CheckboxMenu: React.FC<Props> = ({
  children,
  options,
  optionsSelected,
  onCheckboxChange,
  onOpenChange,
  trigger = 'click',
  placement = 'bottomLeft',
  noSearch,
  hasPin,
  onPinClick,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedItems, setSelectedItems] = useState<CheckboxValueType[]>([]);
  const pinnedColumnsCount = hasPin ? options.filter((col) => col.pinned).length : 0;
  useEffect(() => {
    if (optionsSelected && optionsSelected.length) {
      setSelectedItems([...optionsSelected]);
    }
  }, [optionsSelected]);

  const handleOnChangeCheckboxGroup = (selection: CheckboxValueType[]) => {
    const lastElement = selection.pop(); // get last element of array
    selection.unshift(lastElement ?? ''); // add last element to the beginning of array

    setSelectedItems([...selection]);
    onCheckboxChange([...selection]);
  };

  const handleOnChangeSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.currentTarget.value) {
      setSearchValue(event.currentTarget.value);
    } else {
      setSearchValue('');
    }
  };

  const handleOnOpenChange = (visible: boolean) => {
    onOpenChange?.(visible);
  };

  const searchRender = useMemo(
    () => (
      <Row key='search' style={{ marginBottom: 10 }}>
        <Search
          placeholder='Search'
          defaultValue={searchValue}
          onKeyUp={handleOnChangeSearch}
          style={{ width: 120 }}
        />
      </Row>
    ),
    [searchValue, handleOnChangeSearch],
  );

  const filterOptions = useMemo(
    () =>
      options.filter((option) => option.label?.toLowerCase().includes(searchValue?.toLowerCase())),
    [options, searchValue],
  );

  const checkboxRender = useMemo(
    () => (
      <Checkbox.Group
        onChange={handleOnChangeCheckboxGroup}
        value={selectedItems}
        style={{ maxHeight: '500px', overflowY: 'scroll' }}
      >
        <Col>
          {!noSearch && searchRender}
          {filterOptions.map((option) => (
            <Row
              key={`checkbox-group-${option.value}`}
              justify='space-between'
              style={{
                width: '100%',
                backgroundColor: selectedItems.includes(option.value) ? '#CCE4F6' : 'white',
                padding: '3px 6px',
              }}
            >
              <Checkbox
                key={option.value}
                value={option.value}
                style={{ color: selectedItems.includes(option.value) ? '#0078D4' : '' }}
              >
                {option.label}
              </Checkbox>
              {hasPin && (
                <PinButton
                  disabled={
                    (!option.pinned && pinnedColumnsCount >= 2) ||
                    !selectedItems.includes(option.value)
                  }
                  column={
                    { headerName: option.label, pinned: option.pinned } as unknown as SubTableColumn
                  }
                  onPinClick={onPinClick}
                  color={selectedItems.includes(option.value) ? '#0078D4' : ''}
                />
              )}
            </Row>
          ))}

          {filterOptions.length === 0 && (
            <Row key='empty-result' style={{ justifyContent: 'center' }}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Row>
          )}
        </Col>
      </Checkbox.Group>
    ),
    [filterOptions, searchRender, selectedItems, handleOnChangeCheckboxGroup],
  );

  return (
    <Popover
      content={checkboxRender}
      onOpenChange={handleOnOpenChange}
      trigger={trigger}
      placement={placement}
      arrow={false}
      overlayInnerStyle={{ padding: 0 }}
    >
      {children}
    </Popover>
  );
};

export default CheckboxMenu;
