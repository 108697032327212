import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { CountryModel } from 'src/models';

const countryAdapter: AdapterFunc<GQL.CountryDbModel, CountryModel> = (input) => ({
  id: input.id,
  name: input.name!,
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
});

export default countryAdapter;
