import { gql } from '@apollo/client';

export const GET_ALL_SALES_TEAMS = gql`
  query GetAllSalesTeams {
    getAllSalesTeams {
      total
      results {
        companySplit
        companySplitType {
          id
          name
        }
        id
        leaderSalesTeamId
        name
        specifierSplit
        teamMembers {
          id
          name
        }
        type {
          id
          name
        }
      }
    }
  }
`;

export const GET_ALL_SALES_TEAMS_SHORT = gql`
  query GetAllSalesTeam($criteria: SalesTeamCriteriaInput!) {
    GetAllSalesTeam(Criteria: $criteria) {
      results {
        id
        name
        companySplit
      }
    }
  }
`;

export default GET_ALL_SALES_TEAMS;
