import { gql } from '@apollo/client';

const GET_ALL_RELATED_COMPANIES = gql`
  query GetAllRelatedCompanies($criteria: RelatedCompanyCriteriaInput!, $company: Float!) {
    GetAllRelatedCompanies(Criteria: $criteria, company: $company) {
      results {
        id
        relatedCompany {
          id
          name
        }
        relatedCategory {
          id
          name
        }
        valueRole
        companyManufacturer {
          id
          name
        }
        customerSplit
      }
    }
  }
`;

export const GET_ALL_RELATED_CATEGORIES = gql`
  query GetAllRelatedCategories {
    GetAllRelatedCategories(Criteria: { pagination: { size: 2000 } }) {
      total
      results {
        id
        name
        description
      }
    }
  }
`;

export const GET_FILTER_COMPANIES = gql`
  query GetCompaniesFromElastic2(
    $criteria: CompanyCriteriaInput2!
    $searchFields: CompanyFindElasticInput2
  ) {
    GetCompaniesFromElastic2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
      }
    }
  }
`;

export default GET_ALL_RELATED_COMPANIES;
