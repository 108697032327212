import { Avatar, Card, Select } from 'antd';
import { FC, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import StyledCompanyHeader from 'src/components/companyDetails/styles/companyHeader.styles';
import logo from 'src/images/logo.png';
import FlexCol from 'src/components/companyDetails/styles/flexCol.style';
import { StyledTitle } from 'src/components/commons/customHeader/CustomHeader.styles';
import { MODULE_OPTIONS_CONFIGURATOR } from 'src/modules/settings/modules/subTables/constants';
import removeLastDirectoryPartOf from 'src/utils/functions/url';

interface SettingsSubheaderInterface {
  title?: string;
  record?: any;
  withSelect?: boolean;
}

const SubheaderSettings: FC<SettingsSubheaderInterface> = ({
  title = 'Configuration',
  record,
  withSelect,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [configuratorValue, setConfiguratorValue] = useState({
    label: '',
    value: '',
  });
  const [companyComisionSplit, setCompanyComisionSplit] = useState<number | string>(0);
  const [salesTeamComisionSplit, setSalesTeamComisionSplit] = useState<number | string>(0);

  useEffect(() => {
    // Remove the las url to update the select value with the correct module
    // selected by the user
    const filterOption = MODULE_OPTIONS_CONFIGURATOR.filter(
      (option) =>
        removeLastDirectoryPartOf(option.value) === removeLastDirectoryPartOf(location.pathname),
    );

    setConfiguratorValue({
      label: filterOption[0]?.label,
      value: filterOption[0]?.value,
    });
  }, [location.pathname]);

  const handleSelectOptions = (value: { value: string; label: React.ReactNode }) => {
    navigate(value.value);
  };

  const titleComplement = (
    <span style={{ fontSize: '1.8rem', color: '#4B4B4B' }}>{record?.name}</span>
  );

  useEffect(() => {
    if (record?.companySplit) {
      const innerCalculatedCompanyComisionSplit = Number(record?.companySplit) * 100;
      const innerSalesTeamComisionSplit = 100 - Number(record?.companySplit) * 100;
      setCompanyComisionSplit(
        Number.isInteger(innerCalculatedCompanyComisionSplit)
          ? innerCalculatedCompanyComisionSplit
          : innerCalculatedCompanyComisionSplit.toFixed(2),
      );
      setSalesTeamComisionSplit(
        Number.isInteger(innerSalesTeamComisionSplit)
          ? innerSalesTeamComisionSplit
          : innerSalesTeamComisionSplit.toFixed(2),
      );
    } else {
      setSalesTeamComisionSplit(0);
      setCompanyComisionSplit(0);
    }
  }, [record]);

  const salesTeamComplement = (
    <>
      <FlexCol className='avatar-company-container'>
        <StyledTitle
          style={{
            color: '#C6C4C4',
            fontFamily: 'Libre Franklin, sans-serif',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '22px',
            textAlign: 'right',
          }}
          level={5}
        >
          Company Commission Split:{' '}
          <span
            style={{
              fontSize: '20px',
              color: '#4B4B4B',
              fontFamily: 'libre franklin, sans-serif',
              fontWeight: '500',
              lineHeight: '28px',
              textAlign: 'right',
            }}
          >
            {companyComisionSplit}%
          </span>
        </StyledTitle>
      </FlexCol>
      <FlexCol className='avatar-company-container'>
        <StyledTitle
          style={{
            color: '#C6C4C4',
            fontFamily: 'libre franklin, sans-serif',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '22px',
            textAlign: 'right',
          }}
          level={5}
        >
          Sales Team Commission Split:{' '}
          <span
            style={{
              fontSize: '20px',
              fontFamily: 'libre franklin, sans-serif',
              color: '#4B4B4B',
              fontWeight: '500',
              lineHeight: '28px',
              textAlign: 'right',
            }}
          >
            {salesTeamComisionSplit}%
          </span>
        </StyledTitle>
      </FlexCol>
    </>
  );

  const selector = (
    <Select
      labelInValue
      data-testid='subtables-select-id'
      onChange={handleSelectOptions}
      options={MODULE_OPTIONS_CONFIGURATOR}
      showSearch
      style={{
        width: '80%',
      }}
      value={configuratorValue}
    />
  );

  return (
    <StyledCompanyHeader>
      <Card
        className='card-container'
        style={{
          borderRadius: 0,
          paddingBlock: '0.5rem',
        }}
      >
        <FlexCol span={24} className='flex-col-container'>
          <FlexCol className='avatar-company-container'>
            <StyledTitle
              style={{
                color: record ? '#C6C4C4' : '#4B4B4B',
                margin: 0,
                fontFamily: 'libre franklin, sans-serif',
                fontWeight: '500',
                fontSize: record ? '24px' : '30px',
                lineHeight: record ? '32px' : '40px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              level={3}
            >
              {title}
              {record && ': '} {record && titleComplement}
            </StyledTitle>
          </FlexCol>
          {withSelect && selector}
          {record && salesTeamComplement}
          <FlexCol>
            <Avatar src={logo} style={{ width: 40, height: 40 }} />
          </FlexCol>
        </FlexCol>
      </Card>
    </StyledCompanyHeader>
  );
};

export default SubheaderSettings;
