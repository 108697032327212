import React, { useEffect, useState } from 'react';
import type { MenuProps } from 'antd';
import { Link, useLocation } from 'react-router-dom';
// eslint-disable-next-line no-duplicate-imports
import StyledMenu from './CustomNavigation.styles';

const items: MenuProps['items'] = [
  {
    label: <Link to='/companies'>Companies</Link>,
    key: '/companies',
  },
  {
    label: <Link to='/companies/migration'>Companies2</Link>,
    key: '/companies2',
  },
  {
    label: <Link to='/contacts'>Contacts</Link>,
    key: '/contacts',
  },
  {
    label: <Link to='/contacts/migration'>Contacts2</Link>,
    key: '/contacts2',
  },
  {
    label: <Link to='/activity'>Activity Journals</Link>,
    key: '/activity',
  },
  // {
  //   label: <Link to='/tasks'>Tasks</Link>,
  //   key: '/tasks',
  // },
  {
    label: <Link to='/opportunities'>Opportunities</Link>,
    key: '/opportunities',
  },
  // {
  //   label: <Link to='/quotes'>Quotes</Link>,
  //   key: '/quotes',
  // },
  // {
  //   label: <Link to='/messages'>Messages</Link>,
  //   key: '/messages',
  // },
  // {
  //   label: 'More',
  //   key: 'alipay7',
  //   children: [
  //     {
  //       type: 'group',
  //       label: 'Item 1',
  //       children: [
  //         {
  //           label: 'Option 1',
  //           key: '/setting:1',
  //         },
  //         {
  //           label: 'Option 2',
  //           key: '/setting:2',
  //         },
  //       ],
  //     },
  //     {
  //       type: 'group',
  //       label: 'Item 2',
  //       children: [
  //         {
  //           label: 'Option 3',
  //           key: '/setting:3',
  //         },
  //         {
  //           label: 'Option 4',
  //           key: '/setting:4',
  //         },
  //       ],
  //     },
  //   ],
  // },
];

const CustomNavigation = () => {
  const location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname === '/' || location.pathname === '' ? '/companies' : location.pathname,
  );

  const handleClick = (e: any) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);

  return (
    <StyledMenu
      mode='horizontal'
      onClick={handleClick}
      items={items}
      activeKey={current}
      selectedKeys={[current]}
      style={{ minWidth: '680px' }}
    />
  );
};

export default CustomNavigation;
