import React from 'react';
import { Table } from 'antd';
import SideTableFilterStyles from './CustomSideTableFilter.styles';
import { DataType, SideTableFilterRendererDataType } from './types';

const SideTableFilterRenderer: React.FC<SideTableFilterRendererDataType> = ({
  column,
  data,
  selectedSideTableFilter,
  selectedOptionHandler,
  selectedRowKeys,
  setSelectedRowKeysHandler,
}) => {
  const handleRowClick = (record: DataType) => {
    if (selectedSideTableFilter === 'alphabetical') {
      const newSelectedRowKeys = [record.key];
      setSelectedRowKeysHandler(newSelectedRowKeys);
      selectedOptionHandler(newSelectedRowKeys);
    }
  };

  const rowClassName = selectedSideTableFilter === 'alphabetical' ? 'hide-checkbox' : '';

  return (
    <>
      <SideTableFilterStyles />
      <div className='side-table-filter-container' data-testid='side-table-filter-container'>
        <Table
          className={rowClassName}
          style={{ maxWidth: '260px', minHeight: 'calc(100vh - 190px)' }}
          pagination={{
            defaultPageSize: 250,
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedKeys) => {
              setSelectedRowKeysHandler(selectedKeys);
              selectedOptionHandler(selectedKeys);
            },
            type: 'checkbox',
          }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
          columns={column}
          dataSource={data}
          scroll={{ y: 'calc(100vh - 275px)' }}
        />
      </div>
    </>
  );
};

export default SideTableFilterRenderer;
