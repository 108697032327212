import { Space } from 'antd';
import styled from 'styled-components';

const StyledTitle = styled(Space)`
  width: 100%;

  .column-title {
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
  }

  .ant-select-auto-complete {
    width: 100%;
    border-color: #0078d4;
  }

  .title-text {
    font-weight: 400;
    font-size: 14px;
    color: rgb(0, 0, 0);
  }
`;

export default StyledTitle;
