import { useLazyQuery } from '@apollo/client';
import { Form } from 'antd';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import { GET_TYPE_AHEAD_RESULTS } from 'src/components/company/queries';
import { useCompanyContext } from 'src/context/company.context';
import {
  FilterCompanyEnum,
  Query,
  QueryGetCompanyUniqueValuesFromColumnArgs,
} from 'src/graphql/schema-types';

const CompanyBasicSection = () => {
  const context = useCompanyContext();
  const form = Form.useFormInstance();
  const [verifyName] = useLazyQuery<
    Pick<Query, 'GetCompanyUniqueValuesFromColumn'>,
    QueryGetCompanyUniqueValuesFromColumnArgs
  >(GET_TYPE_AHEAD_RESULTS);

  const fields: FieldType[] = [
    {
      name: 'name',
      type: 'text',
      label: 'Company Name',
      readOnly: !context?.activeExpandedEdition,
      rules: [
        { required: true, message: 'Company name is required' },
        () => ({
          validator(_, value) {
            if (!value) return Promise.resolve();
            return verifyName({
              variables: {
                column: FilterCompanyEnum.Name,
                filter: value,
                enabled: true,
              },
            }).then(({ data }) => {
              const result = data?.GetCompanyUniqueValuesFromColumn?.map((n: string) =>
                n.toLowerCase(),
              );
              if (result) {
                const lowerCasedName = value.toLowerCase();
                if (context?.company?.name?.toLowerCase() === lowerCasedName) {
                  return Promise.resolve();
                }
                if (result.includes(lowerCasedName)) {
                  return Promise.reject(new Error('Company name already exists'));
                }
              }
              return Promise.resolve();
            });
          },
        }),
      ],
      span: 24,
      autoFocus: true,
    },
    {
      name: 'website',
      readOnly: !context?.activeExpandedEdition,
      type: 'text',
      label: 'Website',
      span: 24,
    },
    {
      name: 'addresses',
      readOnly: !context?.activeExpandedEdition,
      type: 'addresses',
      label: 'Main Address',
      span: 24,
    },
    {
      name: 'phones',
      readOnly: !context?.activeExpandedEdition,
      type: 'phones',
      label: 'Main Phone',
      span: 24,
    },
    {
      name: 'emails',
      readOnly: !context?.activeExpandedEdition,
      type: 'emails',
      label: 'Main Email',
      span: 24,
    },
  ];

  return <GenericForm name='Basic Information' fields={fields} form={form} />;
};

export default CompanyBasicSection;
