import { gql } from '@apollo/client';

export const CREATE_ADDRESS_TYPE = gql`
  mutation CreateAddressType($createAddressTypeInput: CreateAddressTypeInput!) {
    CreateAddressType(createAddressTypeInput: $createAddressTypeInput) {
      id
      name
      description
      isCompany
      isContact
    }
  }
`;

export const UPDATE_ADDRESS_TYPE = gql`
  mutation UpdateAddressType($updateAddressTypeInput: UpdateAddressTypeInput!) {
    UpdateAddressType(updateAddressTypeInput: $updateAddressTypeInput) {
      id
      name
      description
      isCompany
      isContact
    }
  }
`;

export const DELETE_ADDRESS_TYPE = gql`
  mutation RemoveAddressType($id: Int!) {
    RemoveAddressType(id: $id)
  }
`;
