import { gql } from '@apollo/client';

export const GET_ALL_PRODUCT_INTEREST = gql`
  query GetAllProductInterest2(
    $criteria: ProductInterestCriteriaInput!
    $searchFields: FindProductInterestInput
  ) {
    GetAllProductInterest2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
      }
      total
    }
  }
`;

export const GET_PRODUCT_INTEREST = gql`
  query GetProductInterest($getProductInterestId: Int!) {
    GetProductInterest(id: $getProductInterestId) {
      id
      name
    }
  }
`;

export const GET_PRODUCT_INTEREST_HEADERS = gql`
  query Query {
    GetProductInterestGridHeaders
  }
`;
