import { gql } from '@apollo/client';

const GET_ALL_CUSTOM_FIELDS = gql`
  query GetAllAdditionalFields(
    $criteria: AdditionalFieldsCriteriaInput!
    $searchFields: FindAdditionalFieldsInput
  ) {
    GetAllAdditionalFields(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
        description
        dataStructure
        dataType
        module
        createdAt
        updatedAt
      }
    }
  }
`;

export default GET_ALL_CUSTOM_FIELDS;
