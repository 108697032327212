import { Button } from 'antd';
import styled from 'styled-components';

const StyledAddButton = styled(Button)<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#EEEEEE' : '#0078d4')} !important;
  background-color: #0078d4;

  padding: 0;
  font-size: 18px;
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
  min-height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${(props) => (props.active ? '#EEEEEE' : '#0078d4')};
  }
  span {
    width: max-content;
    height: max-content;
    svg {
      width: 24.35px;
      height: 24.25px;
      fill: ${(props) => (props.active ? '#C6C4C4' : '#ffffff')};
    }
  }
`;

export default StyledAddButton;
