// List of items that do not count towards the percentage because there is no input related
export const dontCount = [
  'quoteFooter',
  'poFooter',
  'hasPartNumberSplit',
  'hasSecondaryAlias',
  'hasForecast',
  'hasHolidayCard',
  'divisionsId',
];
