import { NavLink, useParams } from 'react-router-dom';
import SOppSetupNav from '../styles/oppSetupNav.style';

const TABS = [{ label: 'Basic', to: 'basic' }];

const OppSetupNav = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <SOppSetupNav>
      {TABS.map((link) => (
        <NavLink key={link.to} to={`/opportunities/${id}/${link.to}`}>
          {link.label}
        </NavLink>
      ))}
    </SOppSetupNav>
  );
};
export default OppSetupNav;
