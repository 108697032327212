import { gql } from '@apollo/client';

const GET_ALL = gql`
  query GetAllCountries2($criteria: CountryCriteriaInput!, $searchFields: FindCountryInput) {
    GetAllCountries2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
      }
      total
    }
  }
`;

export default GET_ALL;
