import { useEffect, useRef, useState } from 'react';
import { useNavigationType } from 'react-router';

let { PerformanceObserver } = window;

if (!PerformanceObserver) {
  class A {
    // eslint-disable-next-line class-methods-use-this
    observe() {}

    // eslint-disable-next-line class-methods-use-this
    disconnect() {}
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PerformanceObserver = A as any;
}

const useNavigationPerformance = () => {
  const [isReload, setIsReload] = useState(false);
  const navigationType = useNavigationType();
  const observerRef = useRef<PerformanceObserver>(
    new PerformanceObserver((list) => {
      list.getEntries().forEach((entry) => {
        const data = entry as unknown as PerformanceNavigationTiming;
        setIsReload(data.type === 'reload');
      });
    }),
  );

  useEffect(() => {
    observerRef.current.observe({ type: 'navigation' });
    return () => {
      observerRef.current.disconnect();
    };
  });

  return {
    isReload,
    navigationType,
  };
};

export default useNavigationPerformance;
