import { Col } from 'antd';
import styled from 'styled-components';

interface FlexColProps extends React.HTMLAttributes<HTMLDivElement> {
  justifyContent?: string;
  xs?: number;
  sm?: { span: number };
  md?: { span: number };
  span?: number;
}

const FlexCol: React.FC<FlexColProps> = styled(Col)<FlexColProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }

  .ant-select-selector {
    height: 32px !important;
  }

  .ant-select-selection-item {
    line-height: 32px !important;
  }
`;

export default FlexCol;
