/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */
import React, {
  ComponentType,
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import dayjs from 'dayjs';
import localDate from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import {
  Form,
  Input,
  InputRef,
  ColorPicker,
  Tooltip,
  Select,
  Checkbox,
  DatePicker,
  DatePickerProps,
  Typography,
  Tag,
} from 'antd';
import { Color } from 'antd/es/color-picker';
import { HeaderDropdownOptions } from 'src/types/companiesTypes';
import updateValueJavascriptObject from 'src/utils/functions/object';
import { useDeepLocalStorage } from 'src/hooks/useLocalStorage';
import get from 'lodash/get';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { formFieldTypes } from 'src/modules/settings/modules/applicationSettings/tabs/customFields/constants';
import { TextAreaRef } from 'antd/es/input/TextArea';
import { useLocation } from 'react-router';
import { useEditableContext } from '../contexts/editable.context';
import StyledCellSkeleton from '../styles/CellSkeleton.style';
import { RecordType } from '../../createCompany/types';
import { useSubTableContext } from '../contexts/subtable.context';
import { EditableCellEnum, InterfaceStatus, SubTableColumn } from '../types';
import capitalizeFirstWord from '../../../../utils/functions/capitalizeFirstWord';
import TypeAheadCompany from '../businnesComponents/TypeAheadCompany';
import TypeAheadManufacturerCompany from '../businnesComponents/TypeAheadManufacturerCompany';
import InputNumber from './InputNumber';
import { EditableCell2 } from '../../editableCell2';
import { customRound } from '../helpers';

const { TextArea } = Input;
const { Link } = Typography;

dayjs.extend(weekday);
dayjs.extend(localDate);

type EditableCellProps = PropsWithChildren<{
  title: React.ReactNode;
  editable: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataIndex: any;
  record: RecordType;
  handleSave: (record: RecordType) => void;
  dataType: string;
  dataKey: string;
  hasLink?: boolean;
  options: HeaderDropdownOptions[] | undefined;
  postData: (args: { [x: string]: unknown }) => void;
  data: any[];
  setNewRecord: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
  newRecord: Record<string, unknown>;
  unique: boolean;
  dataToCompare: any[];
  tableColumns: SubTableColumn[];
  filterInSelect: (filter: string) => Promise<any[] | undefined>;
  render?: (value: any, record: RecordType, index?: number) => React.ReactNode;
}>;

const getEditableCellComponent = (
  CellComponent: ComponentType<PropsWithChildren> | string,
  allowGlobalEditActive: boolean,
) => {
  const EditableDataCell: FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    dataType,
    options,
    dataKey,
    data,
    postData,
    setNewRecord,
    newRecord,
    unique,
    dataToCompare,
    tableColumns,
    hasLink,
    render,
    ...restProps
  }) => {
    const [inputValue, setInputValue] = useState<any>();
    const [internalOptions, setInternalOptions] = useState(options);
    const [valueColor] = useState(dataType === 'color' ? record[dataIndex as string] : '');
    const [color, setColor] = useState<Color>();
    const [editing, setEditing] = useState(false);

    const inputRef = useRef<InputRef>(null);
    const textAreaRef = useRef<TextAreaRef>(null);
    const form = useEditableContext();
    const {
      isEditing,
      storageKey,
      storageType,
      keyToEditNewRow,
      setKeyToEditNewRow,
      optionSelectedFromSelect,
      setOptionSelectedFromSelect,
      setOptionsFromValues,
      optionsFromValues,
    } = useSubTableContext();
    const [status, setStatus] = useState<InterfaceStatus>(undefined);
    const [helpInput, setHelpInput] = useState<ReactNode>();
    const dataPath = useMemo(
      () => [dataIndex, dataKey].flat().filter(Boolean).join('.'),
      [dataKey, dataIndex],
    );

    const { pathname } = useLocation();

    const storagePath = useMemo(() => {
      if (!storageKey || !dataIndex) return '';
      return [storageKey, record?.id, dataPath]
        .flat()
        .filter((item) => item !== undefined && item !== null)
        .join('.');
    }, [dataPath, record]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data: storedData, storeData } = useDeepLocalStorage(
      storagePath,
      undefined,
      storageType === 'local' ? 'localStorage' : 'sessionStorage',
    );

    const saveLocalData = (value: any) => {
      if (storageKey && status !== 'error') {
        storeData(value);
      }
    };

    /**
     * To access to the data in the record you can use lodash
     * import _ from 'lodash'
     * const data = _.get(record, dataPath)
     */

    const handleOnClickEditableCell = async () => {
      if (isEditing) {
        setEditing(!editing);
        // TODO check when dataIndex is array
        if (dataIndex === 'companySplit') {
          form.setFieldsValue({
            [dataIndex as string]: record[dataIndex as string] * 100,
          });
        } else {
          form.setFieldsValue({
            [dataIndex as string]: record[dataIndex as string],
          });
        }
      }
    };

    const handleOnOpenChangeEditableColor = (open: boolean) => {
      if (!open && color?.toHex() !== undefined) {
        handleSave({ ...record, ...{ color: color?.toHex() } });
      }
    };

    const handleOnSearchSelectNewRecord = (search: string) => {
      if (!search) {
        setInternalOptions(options);
        return;
      }

      const filteredOptions =
        internalOptions?.filter((element) =>
          element.LABEL.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
        ) || [];

      setInternalOptions([...filteredOptions]);
    };

    const handleOnChangeEditableCheckbox = (e: CheckboxChangeEvent) => {
      postData({ dataIndex, value: e.target.checked, dataKey });
    };

    const uniqueValues = () => {
      const input = newRecord ? (newRecord[dataPath] as string) : '';

      const inputLowerCase = input ? input.toLowerCase() : undefined;
      const valueLowerCase = inputRef.current?.input?.value;

      const exist = data?.some((recordData) => {
        const recordDataLowerCase = (recordData[dataIndex] as string)?.toLowerCase();
        return (
          recordDataLowerCase === (inputLowerCase ?? valueLowerCase?.toLocaleLowerCase()) &&
          record.id !== recordData.id
        );
      });

      if (exist) {
        setHelpInput(`This ${title} already exist. Please provide unique ${title}.`);
        setStatus('error');
        storeData(undefined);
      } else {
        setHelpInput('');
        setStatus('success');
      }
    };

    const validateRequiredAndUnique = () => {
      const currentHeader = tableColumns.find((element) => element.dataIndex === dataIndex);
      if (currentHeader?.required && inputRef.current?.input?.value === '') {
        setHelpInput(`${title} is required`);
        setStatus('error');
        storeData(undefined);
        return;
      }

      if (currentHeader?.required && inputRef.current?.input?.value !== '') {
        uniqueValues();
        return;
      }

      setHelpInput('');
      setStatus('');
    };

    const handleOnBlurEditableInput = () => {
      if (status === 'error') return;
      if (allowGlobalEditActive) {
        const value =
          dataIndex === 'companySplit'
            ? Number(inputRef.current?.input?.value) / 100
            : inputRef.current?.input?.value;
        handleSave({ ...record, [dataIndex]: value });
      }
    };

    const validateNumber = async (value: string) => {
      if (Number(value) <= 0 || Number(value) > 100) {
        setStatus('error');
        setHelpInput('The value must be greater than 0 and not exceed 100.');
        return;
      }
      if (!/^\d+(\.\d{0,2})?$/.test(value)) {
        setStatus('error');
        setHelpInput('The value must have at most 2 decimal places.');
        return;
      }
      setStatus('');
      setHelpInput('');
    };

    const handleIfCompanySplit = () => {
      if (dataIndex === 'companySplit') {
        const value = inputRef.current?.input?.value;
        validateNumber(value as string);
        return;
      }
      handleOnBlurEditableInput();
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleOnChangeDatePickerNewRecord: DatePickerProps['onChange'] = (_, value) => {
      postData({ dataIndex, value, dataKey });
    };

    const handleOnChangeInputNewRecord = () => {
      const value = inputRef.current?.input?.value;
      if (inputRef.current?.input?.type === 'number' && inputRef.current?.input?.max) {
        if (Number(value) > Number(inputRef.current?.input?.max)) {
          setHelpInput(`${title} must be between 0 and 100`);
          setStatus('error');
          storeData(undefined);
          postData({ dataIndex, value: '100' });
          return;
        }

        if (!value) {
          setHelpInput('');
          setStatus(undefined);
          return;
        }

        setHelpInput('');
        setStatus('success');
        return;
      }

      validateRequiredAndUnique();
    };

    const handleOnBlurInputNewRecord = () => {
      const value = inputRef.current?.input?.value;
      const currHeader = tableColumns.find((element) => element.dataIndex === dataIndex);
      if (currHeader?.required && value === '') {
        setHelpInput(`${title} is required`);
        setStatus('error');
        postData({ dataIndex, value, dataKey });
        return;
      }
      postData({ dataIndex, value, dataKey });

      if (dataPath === 'dataStructure.options') {
        // Only for Custom fields
        setOptionsFromValues(value ?? ''); // saving options to show in the select of the columns DEFAULT
      }
    };

    const handleOnBlurTextAreaNewRecord = () => {
      if (status === 'error') {
        return;
      }

      const value = textAreaRef?.current?.resizableTextArea?.textArea.value;
      const currHeader = tableColumns.find((element) => element.dataIndex === dataIndex);
      if (currHeader?.required && value === '') {
        setHelpInput(`${title} is required`);
        setStatus('error');
        postData({ dataIndex, value, dataKey });
        return;
      }

      postData({ dataIndex, value, dataKey });
    };

    const handleOnChangeTextAreaNewRecord = () => {
      const value = inputRef.current?.input?.value;
      const currHeader = tableColumns.find((element) => element.dataIndex === dataIndex);
      if (currHeader?.required && value === '') {
        setHelpInput(`${title} is required`);
        setStatus('error');
        postData({ dataIndex, value, dataKey });
      }
    };

    const handleOnChangeNewRecordCheckbox = (e: CheckboxChangeEvent) => {
      postData({ dataIndex, value: e.target.checked, dataKey });
    };

    // TODO Example: validate in the server
    const handleOnChangeEditableInput = () => {
      validateRequiredAndUnique();
      handleOnChangeInputNewRecord();

      // saveLocalData(inputRef.current?.input?.value);
      setInputValue(inputRef.current?.input?.value);
    };

    const handleOnChangeCompleteEditableColor = (c: Color) => {
      setColor(c);
      saveLocalData(c.toHex());
    };

    const handleOnChangeEditableSelect = (e: React.FormEvent<HTMLInputElement> | string) => {
      // TODO include the implementation for save the data in local storage using the saveLocalData function

      // TODO review file src\components\commons\editableCell\index.tsx in order to refactor this
      if (options) {
        const innerValue = e as unknown as string;
        const innerSelectedOption = options.findIndex((item) => innerValue === item.VALUE);
        const optionSelected = options[innerSelectedOption];
        // TODO function Number(optionSelected.VALUE) when the option come from the backend
        if (Array.isArray(dataIndex)) {
          const response = updateValueJavascriptObject(record, dataIndex, optionSelected.LABEL);
          if (data?.some((element) => element.color)) {
            const findColor = dataToCompare?.find((el) => el.id === Number(optionSelected.VALUE));
            handleSave({
              ...response,
              ...{
                selectedId: optionSelected.VALUE,
                color: findColor?.color,
              },
            });
          } else {
            handleSave({ ...response, ...{ selectedId: Number(optionSelected.VALUE) } });
          }
        } else if (data.some((element) => element.color)) {
          const findColor = dataToCompare?.find((el) => el.id === optionSelected.VALUE);
          handleSave({
            ...record,
            ...{
              selectedId: optionSelected.VALUE,
              ...{
                [dataIndex]: {
                  [dataKey]: optionSelected.LABEL,
                },
              },
              color: findColor?.color,
            },
          });
        } else {
          handleSave({
            ...record,
            ...{
              [dataIndex]: {
                [dataKey]: optionSelected.LABEL,
              },
            },
          });
          saveLocalData(Number(optionSelected.VALUE));
        }
      }
    };

    const handleOnPressEnterEditableInput = async () => {
      if (status === 'error') return;

      // Global edit active dont allow handleOnPressEnterEditableInput in the event onblur and onclick
      if (allowGlobalEditActive) {
        const values = await form.validateFields();
        handleSave({ ...record, ...values });

        return;
      }

      try {
        const values = await form.validateFields();
        handleOnClickEditableCell();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        // HERE Implement error handling
      }
    };

    // TODO Right now is trigger en error and i need to analyze more deeply
    useEffect(() => {
      if (editing && inputRef) {
        inputRef?.current?.focus();
      }
    }, [editing]);

    useEffect(() => {
      // TODO Remove 'name' string by property that come from outside
      if (record?.newRecord && dataIndex === 'name' && status === 'error') {
        inputRef?.current?.focus();
      }
    }, [record?.newRecord, status]);

    useEffect(() => {
      if (allowGlobalEditActive && isEditing && record) {
        if (dataIndex === 'companySplit') {
          form.setFieldsValue({
            [dataIndex]: record[dataIndex] * 100,
          });
        } else {
          form.setFieldsValue({
            [dataIndex]: record[dataIndex],
          });
        }
      }
    }, [isEditing]);

    const postDataWithAutoSaveOrNot = async () => {
      const currHeader = tableColumns.find((element) => element.dataIndex === dataIndex);

      if (currHeader?.required && inputRef.current?.input?.value === '') {
        setHelpInput(`${title} is required`);
        setStatus('error');
        return;
      }

      const emptyValues = tableColumns?.some(
        (col) =>
          col?.required &&
          dataIndex !== col?.dataIndex &&
          newRecord[col?.dataIndex as string] === '',
      );

      let value = '';
      if (textAreaRef?.current?.resizableTextArea?.textArea.value) {
        value = textAreaRef?.current?.resizableTextArea?.textArea.value;
      } else {
        value = inputRef.current?.input?.value ?? '';
      }

      if (!emptyValues) {
        postData({ dataIndex, value, dataKey, autoSave: true });
      } else {
        postData({ dataIndex, value, dataKey });
      }
    };

    const handleOnPressEnterNewRecord = async () => {
      postDataWithAutoSaveOrNot();
    };

    const handleOnPressEnterTextAreaNewRecord = () => {
      postDataWithAutoSaveOrNot();
    };

    const handleOnOpenChangeNewRecord = (open: boolean) => {
      if (!open && color?.toHex() !== undefined) {
        postData({ dataIndex, value: color?.toHex() });
      }
    };

    let childNode = children;

    let childNodeValue = '';
    if (Array.isArray(childNode)) {
      childNodeValue = childNode.find((child) => typeof child === 'string');
    }

    let cellValue = get(record, dataPath);

    if (!cellValue && dataIndex && !dataKey) {
      cellValue = get(record, dataIndex);
    }

    const defaultValue = get(newRecord, dataPath);

    const formInputNewRecord = (
      <Tooltip
        overlayInnerStyle={{
          color: 'red',
        }}
        color='white'
        trigger='hover'
        open={!!helpInput}
        title={helpInput}
        placement='bottomLeft'
      >
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataPath}
          validateStatus={status}
          hasFeedback
        >
          <Input
            ref={inputRef}
            onPressEnter={handleOnPressEnterNewRecord}
            onBlur={handleOnBlurInputNewRecord}
            onChange={handleOnChangeInputNewRecord}
            defaultValue={defaultValue as unknown as string}
            autoComplete='off'
            className='input-new-record-js'
          />
        </Form.Item>
      </Tooltip>
    );

    // This condition is use in the grid custom fields in order to show the column values and defaults
    if (typeof cellValue === 'object') {
      if (
        record?.dataType?.toLowerCase() === formFieldTypes.dropdown.toLowerCase() ||
        record?.dataType?.toLowerCase() === formFieldTypes.multi_select_dropdown.toLowerCase() ||
        record?.dataType?.toLowerCase() === formFieldTypes.integer.toLowerCase()
      ) {
        cellValue = record?.dataStructure[dataKey]?.reduce((acc: string, curr: any) => {
          if (typeof curr === 'object') {
            return acc ? `${acc}, ${curr.name}` : curr.name;
          }

          return acc ? `${acc}, ${curr}` : curr;
        }, '');
      } else {
        cellValue = record?.dataStructure?.[dataKey]?.reduce(
          (acc: string, curr: any) => (acc ? `${acc}, ${curr}` : curr),
          '',
        );
      }
    }

    useEffect(() => {
      if (isEditing && editing && storedData && storageKey) {
        form.setFieldsValue({
          [dataIndex]: storedData,
        });
        if (inputRef.current?.input) {
          inputRef.current.input.value = storedData;
          handleOnChangeEditableInput();
        }
      }
    }, [isEditing, editing]);

    useEffect(() => {
      if (editing && storageKey) {
        if (status === 'error') {
          storeData(undefined);
        } else {
          storeData(inputValue);
        }
      }
    }, [inputValue, status, editing]);

    if (record?.newRecord && editable) {
      if (
        dataPath === 'dataStructure.options' &&
        keyToEditNewRow === EditableCellEnum.editingNewRow
      ) {
        // Only for custom fields
        if (
          optionSelectedFromSelect === formFieldTypes.integer ||
          optionSelectedFromSelect === formFieldTypes.dropdown ||
          optionSelectedFromSelect === formFieldTypes.multi_select_dropdown
        ) {
          childNode = formInputNewRecord;
        } else {
          childNode = (
            <div
              className='no-editable-cell'
              style={{
                paddingTop: 5,
                paddingBottom: 5,
              }}
            >
              {capitalizeFirstWord(storedData ?? cellValue ?? childNodeValue)}
            </div>
          );
        }
      } else if (dataPath === 'dataStructure.options') {
        // Only for custom fields
        childNode = (
          <div
            className='no-editable-cell'
            style={{
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            {capitalizeFirstWord(storedData ?? cellValue ?? childNodeValue)}
          </div>
        );
      } else if (
        dataPath === 'dataStructure.selected' &&
        keyToEditNewRow === EditableCellEnum.editingNewRow
      ) {
        if (optionSelectedFromSelect === formFieldTypes.checkbox) {
          childNode = (
            <Checkbox
              onChange={handleOnChangeNewRecordCheckbox}
              defaultChecked={defaultValue as unknown as boolean}
            />
          );
        } else if (optionSelectedFromSelect === formFieldTypes.date) {
          childNode = (
            <DatePicker
              onChange={handleOnChangeDatePickerNewRecord}
              format='MM/DD/YYYY'
              defaultValue={defaultValue ? dayjs(defaultValue as string) : undefined}
              style={{ width: '100%' }}
            />
          );
        } else if (optionSelectedFromSelect === formFieldTypes.date_time) {
          childNode = (
            <DatePicker
              onChange={handleOnChangeDatePickerNewRecord}
              format='MM/DD/YYYY  HH:mm:00'
              defaultValue={defaultValue ? dayjs(defaultValue as string) : undefined}
              style={{ width: '100%' }}
              showTime
            />
          );
        } else if (optionSelectedFromSelect === formFieldTypes.textarea) {
          childNode = (
            <Tooltip
              overlayInnerStyle={{
                color: 'red',
              }}
              color='white'
              trigger='hover'
              title={helpInput}
              placement='bottomLeft'
            >
              <Form.Item
                style={{
                  margin: 0,
                }}
                name={dataIndex}
                validateStatus={status}
                hasFeedback
              >
                <TextArea
                  showCount
                  maxLength={500}
                  ref={textAreaRef}
                  defaultValue={defaultValue as unknown as string}
                  onPressEnter={handleOnPressEnterTextAreaNewRecord}
                  onBlur={handleOnBlurTextAreaNewRecord}
                  onChange={handleOnChangeTextAreaNewRecord}
                />
              </Form.Item>
            </Tooltip>
          );
        } else if (
          optionSelectedFromSelect === formFieldTypes.decimal ||
          optionSelectedFromSelect === formFieldTypes.integer
        ) {
          childNode = (
            <Tooltip
              overlayInnerStyle={{
                color: 'red',
              }}
              color='white'
              trigger='hover'
              title={helpInput}
              placement='bottomLeft'
            >
              <Form.Item
                style={{
                  margin: 0,
                }}
                name={dataIndex}
                validateStatus={status}
                hasFeedback
              >
                <Input
                  ref={inputRef}
                  type='number'
                  defaultValue={defaultValue as unknown as number}
                  onBlur={handleOnBlurInputNewRecord}
                  onChange={handleOnChangeInputNewRecord}
                  onKeyDown={(event) => {
                    if (optionSelectedFromSelect === formFieldTypes.decimal) {
                      if (!/[0-9.]/.test(event.key) && event.key !== 'Backspace') {
                        event.preventDefault();
                      }
                      return;
                    }

                    if (!/[0-9]/.test(event.key) && event.key !== 'Backspace') {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Tooltip>
          );
        } else if (
          optionSelectedFromSelect === formFieldTypes.dropdown ||
          optionSelectedFromSelect === formFieldTypes.multi_select_dropdown
        ) {
          const optionsToSelect = optionsFromValues?.split(',').map((option) => ({
            label: option.trim(),
            value: option.trim(),
          })) as [];

          childNode = (
            <Select
              showSearch
              onChange={(value: any) => {
                postData({ value, dataIndex, dataType, dataKey });
              }}
              style={{ width: '100%' }}
              defaultValue={
                (defaultValue || undefined) as unknown as React.FormEvent<HTMLInputElement>
              }
              options={optionsToSelect}
              allowClear
              onSearch={handleOnSearchSelectNewRecord}
              mode={
                optionSelectedFromSelect === formFieldTypes.multi_select_dropdown
                  ? 'multiple'
                  : undefined
              }
            />
          );
        } else {
          childNode = formInputNewRecord;
        }
      } else if (dataPath === 'dataStructure.selected') {
        // Only for custom fields
        childNode = formInputNewRecord;
      } else if (dataType === 'color') {
        childNode = (
          <ColorPicker
            defaultValue={defaultValue as string}
            value={color?.toHex()}
            onChangeComplete={(value: Color) => setColor(value)}
            onOpenChange={handleOnOpenChangeNewRecord}
            trigger='hover'
          />
        );
      } else if (!!dataType && dataType.toLocaleLowerCase() === 'dropdown') {
        // TODO Refactor because we can use dataPath
        const dataIndexes =
          typeof dataIndex === 'object'
            ? dataIndex.reduce(
                (acc: string, curr: string, idx: number) =>
                  acc +
                  (idx === 0 ? curr : curr.charAt(0).toUpperCase() + curr.slice(1).toLowerCase()),
                '',
              )
            : dataIndex;
        childNode = (
          <Select
            showSearch
            onChange={(value: any) => {
              postData({ value, dataIndex: dataIndexes, dataType, dataKey });
              setKeyToEditNewRow(EditableCellEnum.editingNewRow);
              setOptionSelectedFromSelect(value as string);
            }}
            style={{ width: '100%' }}
            defaultValue={newRecord[dataIndexes] as unknown as React.FormEvent<HTMLInputElement>}
            options={
              internalOptions?.map((element) => ({
                label: element.LABEL,
                value: element.VALUE,
              })) as []
            }
            allowClear
            onSearch={handleOnSearchSelectNewRecord}
          />
        );
      } else if (!!dataType && dataType === 'typeAheadCompany') {
        childNode = (
          <TypeAheadCompany dataIndex={dataIndex} newRecord={newRecord} postData={postData} />
        );
      } else if (!!dataType && dataType === 'typeAheadManufacturerCompany') {
        childNode = (
          <TypeAheadManufacturerCompany
            dataIndex={dataIndex}
            newRecord={newRecord}
            postData={postData}
          />
        );
      } else if (dataType === 'checkbox') {
        childNode = (
          <Checkbox
            onChange={handleOnChangeNewRecordCheckbox}
            defaultChecked={defaultValue as unknown as boolean}
          />
        );
      } else if (dataType === 'number') {
        childNode = (
          <InputNumber
            helpInput={helpInput}
            inputRef={inputRef}
            dataIndex={dataIndex}
            validateStatus={status}
            onBlur={handleOnBlurInputNewRecord}
            onChange={handleOnChangeInputNewRecord}
            defaultValue={defaultValue as unknown as number}
          />
        );
      } else if (dataType === 'percent') {
        childNode = (
          <Tooltip
            overlayInnerStyle={{
              color: 'red',
            }}
            color='white'
            trigger='hover'
            title={helpInput}
            placement='bottomLeft'
          >
            <Form.Item
              style={{
                margin: 0,
              }}
              name={dataIndex}
              validateStatus={status}
              hasFeedback
            >
              <Input
                ref={inputRef}
                type='number'
                min={0}
                max={100}
                defaultValue={defaultValue as unknown as number}
                onBlur={handleOnBlurInputNewRecord}
                onChange={handleOnChangeInputNewRecord}
                onKeyDown={(event) => {
                  if (!/[0-9.]/.test(event.key) && event.key !== 'Backspace') {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Tooltip>
        );
      } else {
        useEffect(() => {
          validateRequiredAndUnique();
        }, []);
        childNode = formInputNewRecord;
      }

      return <CellComponent {...restProps}>{childNode}</CellComponent>;
    }

    if (editable) {
      if (render && !record?.placeholder) {
        childNode = render(cellValue, record);
      } else if (!!dataType && dataType.toLocaleLowerCase() === 'dropdown') {
        childNode = isEditing ? (
          <Select
            onChange={handleOnChangeEditableSelect}
            style={{ width: '100%' }}
            defaultValue={record?.selectedId ?? cellValue}
            options={
              options?.map((element) => ({
                label: element.LABEL,
                value: element.VALUE,
              })) as []
            }
          />
        ) : (
          <div className='no-editable-cell'>{cellValue}</div>
        );
      } else if (dataType === 'color') {
        childNode = (
          <ColorPicker
            disabled={!isEditing}
            defaultValue={valueColor}
            onOpenChange={handleOnOpenChangeEditableColor}
            onChangeComplete={handleOnChangeCompleteEditableColor}
            trigger='hover'
          />
        );
      } else if (dataType === 'checkbox') {
        childNode = (
          <Checkbox
            onChange={handleOnChangeEditableCheckbox}
            defaultChecked={cellValue}
            disabled={!isEditing}
          />
        );
      } else if (dataType === 'number') {
        childNode = (
          <InputNumber
            helpInput={helpInput}
            inputRef={inputRef}
            dataIndex={dataIndex}
            validateStatus={status}
            onBlur={handleOnBlurEditableInput}
            onChange={handleOnChangeEditableInput}
            value={storedData ?? cellValue ?? childNodeValue}
          />
        );
      } else if (dataType === 'percent') {
        childNode =
          editing && isEditing ? (
            <Tooltip
              overlayInnerStyle={{
                color: 'red',
              }}
              color='white'
              trigger='hover'
              title={helpInput}
              placement='bottomLeft'
            >
              <Form.Item
                style={{
                  margin: 0,
                }}
                name={dataIndex}
                validateStatus={status}
                hasFeedback
              >
                <Input
                  ref={inputRef}
                  type='number'
                  min={0}
                  max={100}
                  defaultValue={storedData ?? cellValue ?? childNodeValue}
                  onBlur={handleOnBlurEditableInput}
                  onChange={handleIfCompanySplit}
                  onKeyDown={(event) => {
                    if (!/[0-9.]/.test(event.key) && event.key !== 'Backspace') {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Tooltip>
          ) : (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/mouse-events-have-key-events
            <div
              className={children && isEditing ? 'editable-cell-active' : 'no-editable-cell'}
              style={{
                paddingTop: 5,
                paddingBottom: 5,
              }}
              onClick={handleOnClickEditableCell}
            >
              {dataIndex === 'companySplit' ? (
                <Tag
                  color='default'
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '150px',
                    gap: '5px',
                  }}
                >
                  Company Split:
                  <strong>{customRound((storedData ?? cellValue ?? childNodeValue) * 100)}%</strong>
                </Tag>
              ) : (
                storedData ?? cellValue ?? childNodeValue
              )}
            </div>
          );
      } else if (
        record.dataType === formFieldTypes.hyperlink &&
        dataPath === 'dataStructure.selected'
      ) {
        childNode = (
          <Link href={storedData ?? cellValue ?? childNodeValue} target='_blank'>
            {storedData ?? cellValue ?? childNodeValue}
          </Link>
        );
      } else {
        const value =
          dataIndex === 'name'
            ? capitalizeFirstWord(storedData ?? cellValue ?? childNodeValue)
            : storedData ?? cellValue ?? childNodeValue;

        childNode =
          editing && isEditing ? (
            <Tooltip
              overlayInnerStyle={{
                color: 'red',
              }}
              color='white'
              trigger='hover'
              title={helpInput}
              placement='bottomLeft'
            >
              <Form.Item
                style={{
                  margin: 0,
                }}
                name={dataIndex}
                validateStatus={status}
                hasFeedback
              >
                <Input
                  value={storedData ?? cellValue ?? childNodeValue}
                  ref={inputRef}
                  onPressEnter={handleOnPressEnterEditableInput}
                  onBlur={handleOnBlurEditableInput}
                  onChange={handleOnChangeEditableInput}
                  autoComplete='off'
                />
              </Form.Item>
            </Tooltip>
          ) : (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/mouse-events-have-key-events
            <div
              className={children && isEditing ? 'editable-cell-active' : 'no-editable-cell'}
              style={{
                paddingTop: 5,
                paddingBottom: 5,
              }}
              onClick={handleOnClickEditableCell}
            >
              {dataKey === 'phone' ? (
                <a
                  href={`tel:${storedData ?? cellValue ?? childNodeValue}`}
                  rel='noopener noreferrer'
                >
                  {storedData ?? cellValue ?? childNodeValue}
                </a>
              ) : (
                value
              )}
            </div>
          );
      }
    } else if (hasLink) {
      childNode = (
        <Link href={`/contacts/${record.id}/basic`}>
          {capitalizeFirstWord(storedData ?? cellValue ?? childNodeValue ?? children)}
        </Link>
      );
    } else if (!editable) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      childNode =
        // eslint-disable-next-line no-nested-ternary
        !storedData && !cellValue && !childNodeValue ? (
          // TAKE A LOOK IN THIS VALIDATION BECAUSE THERE IS ALOT OF LOGIC HERE
          pathname === '/companies' ? (
            EditableCell2({
              editable,
              children,
              dataIndex,
              dataKey,
              record,
              dataType,
              handleSave,
              title: '',
              searchField: '',
              options: undefined,
              hasLink: false,
            })
          ) : (
            children
          )
        ) : (
          <div
            className='no-editable-cell'
            style={{
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            {capitalizeFirstWord(storedData ?? cellValue ?? childNodeValue ?? children)}
          </div>
        );
    }
    if (Array.isArray(children) && /<[^>]+>/.test(children[1] as string)) {
      return (
        <CellComponent {...restProps}>
          <div {...restProps} dangerouslySetInnerHTML={{ __html: children[1] as string }} />
        </CellComponent>
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (
      Array.isArray(children) &&
      children?.[1]?._owner?.key === 'phone' &&
      children?.[1]?.props?.children
    ) {
      return (
        <CellComponent {...restProps}>
          <a href={`tel:${children?.[1]?.props?.children}`}>{children?.[1]?.props?.children}</a>
        </CellComponent>
      );
    }
    return <CellComponent {...restProps}>{childNode}</CellComponent>;
  };
  const EditableCell: FC<EditableCellProps> = (props) => {
    const { record } = props;

    if (record?.placeholder) {
      return (
        <CellComponent {...props}>
          <StyledCellSkeleton
            active
            paragraph={{ rows: 1 }}
            title={false}
            className='virtualist-skeleton'
          />
        </CellComponent>
      );
    }
    return <EditableDataCell {...props} />;
  };

  return EditableCell;
};

export default getEditableCellComponent;
