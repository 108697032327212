import { SubTableColumn } from 'src/components/commons/subTable/types';

const COLUMNS: SubTableColumn[] = [
  {
    title: 'NAME',
    dataIndex: 'name',
    editable: true,
    width: 600,
    unique: true,
    required: true,
    key: 'name',
    minWidth: 270,
    dataType: 'string',
    hidden: false,
    dataStructure: 'string',
    headerName: 'name',
    options: [],
    orderField: 'name',
    searchField: 'name',
  },
];

export default COLUMNS;
