import { SubTableColumn } from 'src/components/commons/subTable/types';
import CustomField from 'src/components/companyDetails/components/customFields';
import CompanyBasicSection from '../components/companyBasicSection';
import CompanyCommentsSection from '../components/companyCommentsSection';
import CompanyDetailsSection from '../components/companyDetailsSection';
import CompanyIndustriesSection from '../components/companyIndustries';
import CompanyProductPotentialsSection from '../components/companyProductPotencialsSection';

export const DRAG_LIST_SECTIONS = [
  {
    title: 'Basic Information',
    content: <CompanyBasicSection />,
  },
  {
    title: 'Details',
    content: <CompanyDetailsSection />,
  },
  {
    title: 'Custom Fields',
    content: <CustomField />,
  },

  // Hide section to have a cleaner testing (F20-5372)
  // {
  //   title: 'Related Companies',
  //   content: <CompanyRelatedSection />,
  // },
  // Hide divisions due to is not part of the MVP
  // {
  //   title: 'Divisions',
  //   content: <CompanyDivision />,
  // },
  {
    title: 'Comments',
    content: <CompanyCommentsSection />,
  },
  {
    title: 'Industries',
    content: <CompanyIndustriesSection />,
  },
  {
    title: 'Product Potentials',
    content: <CompanyProductPotentialsSection />,
  },
];

export const COMPANY_DIVISIONS_COLUMNS: SubTableColumn[] = [
  {
    dataIndex: 'name',
    dataStructure: 'name',
    title: 'Company Name',
    orderField: 'name',
    searchField: 'names',
    hasLink: false,
  },
  {
    dataIndex: 'companyType',
    title: 'Company Type',
    key: 'name',
    orderField: 'companyTypeName',
    searchField: 'type_names',
  },
];
