import React, { FC } from 'react';

import GenericForm, { FieldType } from 'src/components/commons/genericForm';

type Props = { disableRegistration?: boolean };

const DesignRegistration: FC<Props> = ({ disableRegistration }) => {
  //   const form = Form.useFormInstance();

  const fields: FieldType[] = [
    {
      name: 'registrationNumber',
      type: 'text',
      label: 'Registration No.',
      readOnly: false,
      span: 12,
      maxLength: 40,
    },
    {
      name: 'statusRegistration',
      readOnly: false,
      type: 'select',
      label: 'Status',
      span: 12,
      style: { width: '100%' },
      disabled: disableRegistration,
      defaultValue: 'requested',
      initialValue: 'requested',
      options: [
        { value: 'requested', label: 'Requested' },
        { value: 'approved', label: 'Approved' },
        { value: 'pending', label: 'Pending' },
        { value: 'denied', label: 'Denied' },
      ],
    },
    {
      name: 'approvedDate',
      readOnly: false,
      type: 'date',
      label: 'Approved Date',
      style: { width: '100%' },
      disabled: disableRegistration,
      span: 12,
    },
    {
      name: 'approvedBy',
      readOnly: false,
      type: 'text',
      label: 'Approved By',
      span: 12,
      disabled: disableRegistration,
    },
    {
      name: 'expirationDate',
      readOnly: false,
      type: 'date',
      label: 'Expiration Date',
      span: 12,
      style: { width: '100%' },
      disabled: disableRegistration,
    },
    {
      name: 'registrationLevel',
      readOnly: false,
      type: 'text',
      label: 'Registration Level',
      span: 12,
      disabled: disableRegistration,
    },
    {
      name: 'notes',
      readOnly: false,
      type: 'textarea',
      label: 'Notes',
      span: 24,
      disabled: disableRegistration,
    },
  ];
  return <GenericForm fields={fields} />;
};

export default DesignRegistration;
