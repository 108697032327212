import { CompaniesElasticTypes2 } from 'src/types/companiesTypes';
import { FC } from 'react';
import { Checkbox } from 'antd';
import { CustomModal } from '../../customModal';
import SubTable from '../../subTable';
import { PARENT_COMPANY_COLUMNS } from '../consts';
import useParentCompanyModal from '../hook/useParentCompanyModal';

type Props = {
  onClose: (record: CompaniesElasticTypes2 | null, checked?: boolean) => void;
  headerCheckbox?: string;
  title?: string;
  show: boolean;
};

const ParentCompanyModal: FC<Props> = ({
  show,
  onClose,
  headerCheckbox,
  title = 'Company Lookup',
}) => {
  const {
    dataSource,
    checked,
    setChecked,
    autocompleteFieldSearch,
    onListRender,
    handleFilterChange,
    handleSortModelChange,
  } = useParentCompanyModal();

  const titleNode = headerCheckbox ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: '1' }}>{title}</div>
      <div style={{ display: 'flex' }}>
        <Checkbox
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
        >
          {headerCheckbox}
        </Checkbox>
      </div>
    </div>
  ) : (
    title
  );

  return (
    <CustomModal
      modalStatus={show}
      modalTitle={titleNode}
      width={1300}
      headerHeight='72px'
      footerHeight='64px'
      bodyPadding='24px 32px'
      centered
      testId='select-parent-company-modal'
      customClass='custom-modal'
      modalBody={
        <SubTable
          data={dataSource}
          onListRender={onListRender}
          columns={PARENT_COMPANY_COLUMNS}
          autocompleteFieldProvider={autocompleteFieldSearch}
          onFilterChange={handleFilterChange}
          onSortChange={handleSortModelChange}
          onRowClick={(record) => onClose(record, checked)}
          scroll={{ y: 400, x: 1200 }}
          nonAddable
          nonSelectable
          nonGlobalFilterable
          columnFilterShown
        />
      }
      onOkAction={() => {}}
      onCancelAction={() => {
        onClose(null, checked);
      }}
      footer={false}
    />
  );
};

export default ParentCompanyModal;
