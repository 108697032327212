import React, { useContext, useRef, useState } from 'react';
import { CompaniesContext } from 'src/context/companies.context';
import CompaniesColumns from 'src/utils/tables/companiesTable';
import { CompaniesHeaderType2, ListTypeOptionType } from 'src/types/companiesTypes';
import get from 'lodash/get';
import EditingCompanyAttrRenderer from './renderer';

const EditingCompanyAttr = () => {
  const {
    setActiveMetadataDropdownHandler,
    gridDataSelected,
    sendNewPayloadData,
    editingReferencesData,
    cancelActiveMetadataDropdownHandler,
    editDataPayload,
    companiesData2,
    saveEditDataPayload2,
  } = useContext(CompaniesContext);
  const { companiesHeaderResponse2 } = CompaniesColumns();
  const dropdownColumn = useRef<CompaniesHeaderType2[]>(
    companiesHeaderResponse2.data?.GetCompaniesDepthGridHeader.filter(
      (element: CompaniesHeaderType2) => element.dataType === 'dropDown',
    ),
  );
  const elementDataSelected = gridDataSelected.current.map((element) => {
    const innerSelectedElement = companiesData2.find((item) => item.id === element);
    return innerSelectedElement;
  });
  const [firstSelection, setFirstSelection] = useState('');
  const [allowUpdate, setAllowUpdate] = useState(false);
  const secondSelectOptions = useRef<ListTypeOptionType[] | undefined>([]);

  const firstSelectOptionsChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFirstSelection('');
    const innerSelection = e as unknown as string;
    setFirstSelection(innerSelection);
    const innerDropdownColumn = dropdownColumn.current.filter(
      (element) => element.dataIndex === innerSelection,
    );
    editingReferencesData.current.optionChanged = innerDropdownColumn[0].headerName;
    editDataPayload.current = [];
    editingReferencesData.current.options = elementDataSelected.map((element) => {
      const innerFrom = get(element, innerDropdownColumn[0].dataIndex);
      return {
        id: element?.id,
        name: element?.name,
        from: innerFrom?.name,
      };
    });
    secondSelectOptions.current = innerDropdownColumn[0]?.options?.map((element) => ({
      label: element.name,
      value: JSON.stringify(element.id),
    }));
  };

  const secondSelectOptionsChange = (e: React.FormEvent<HTMLInputElement>) => {
    const innerValue = e as unknown as string;
    const innerOptionSelected = secondSelectOptions.current?.find(
      (element) => element.value === innerValue,
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const warningStatus = false;
    elementDataSelected.forEach((element, index: number) => {
      if (editingReferencesData.current.options) {
        editingReferencesData.current.options[index].to = innerOptionSelected?.label;
      }
      const id = element?.id as unknown as number;
      const newPayloadData = {
        [firstSelection]: {
          id: Number(innerOptionSelected?.value),
          name: innerOptionSelected?.label,
        },
      };
      const innerIndex = editDataPayload.current.findIndex((item) => id === item.id);
      if (innerIndex >= 0) {
        const newPayloadDataProp = Object.keys(newPayloadData)[0];
        let repeated = false;
        editDataPayload.current.forEach((item) => {
          const itemProp = Object.keys(item.newPayloadData)[0];
          if (itemProp === newPayloadDataProp) {
            repeated = true;
          }
        });
        if (repeated) {
          editDataPayload.current.splice(innerIndex, 1);
          saveEditDataPayload2({ id, newPayloadData, warningStatus });
        } else {
          saveEditDataPayload2({ id, newPayloadData, warningStatus });
        }
      } else {
        saveEditDataPayload2({ id, newPayloadData, warningStatus });
      }
    });
    setAllowUpdate(true);
  };

  return (
    <EditingCompanyAttrRenderer
      cancelAction={() => {
        cancelActiveMetadataDropdownHandler();
        setAllowUpdate(false);
      }}
      firstSelectOptions={dropdownColumn.current?.map((element) => ({
        value: element.dataIndex,
        label: element.headerName,
      }))}
      {...{ firstSelectOptionsChange }}
      {...{ firstSelection }}
      secondSelectOptions={secondSelectOptions.current}
      {...{ secondSelectOptionsChange }}
      sendNewPayloadData={() => {
        setActiveMetadataDropdownHandler();
        sendNewPayloadData(true);
        setAllowUpdate(false);
      }}
      {...{ allowUpdate }}
    />
  );
};

export default EditingCompanyAttr;
