import React from 'react';
import { Row, Col, Form, Input, Space, Typography } from 'antd';
import CustomSelect from 'src/components/commons/customSelect';
import CustomButton from 'src/components/commons/customButton';
import { PlusOutlined } from '@ant-design/icons';
import { FormEmailElementsType } from 'src/types/companiesTypes';
import { SInputEmail } from '../genericForm/styles/emailForm.styles';
// import useFormInstance from 'antd/es/form/hooks/useFormInstance';

interface CustomEmailFormType {
  deleteIcon: React.ReactNode;
  elementData: FormEmailElementsType;
  addNewFormElement?: () => void;
  deleteEmailAction?: (elementId: React.Key) => void;
  onInputChange?: (addressId: React.Key, data: string) => void;
  required?: boolean;
  emailTypes?: { value: string; label: string }[];
  emailTypeAction?: (elementId: React.Key, emailType: string) => void;
}

const CustomEmailForm: React.FC<CustomEmailFormType> = ({
  deleteIcon,
  elementData,
  addNewFormElement,
  deleteEmailAction,
  onInputChange,
  required,
  emailTypes,
  emailTypeAction,
}) => {
  // const form = useFormInstance();
  const addressSearchHandler = (e: React.FormEvent<HTMLInputElement>) => {
    if (onInputChange) {
      const innerData = e.currentTarget.value;
      onInputChange(elementData.formId, innerData);
    }
  };

  const emailTypeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    const phoneType = e as unknown as string;
    if (emailTypeAction) {
      emailTypeAction(elementData.formId, phoneType);
    }
  };

  return (
    <Col span={24}>
      <Row align='middle' justify='space-between'>
        {elementData.formId === 'form-1' && (
          <Typography.Text style={{ marginRight: 10, paddingBottom: 8 }}>
            Main Email
          </Typography.Text>
        )}
        {elementData.formId !== 'form-1' && (
          <CustomSelect
            style={{ width: 165, marginRight: 'auto' }}
            defaultValue='Select Email Type'
            options={emailTypes}
            customClassName='no-border-radius-select'
            dataTestId='phone-type-select'
            handleChange={emailTypeHandler}
          />
        )}
        {elementData.formId !== 'form-1' && (
          <CustomButton
            shape='circle'
            icon={deleteIcon}
            onlyIcon
            style={{ textAlign: 'end', paddingRight: 7 }}
            onClick={() => {
              if (deleteEmailAction) {
                deleteEmailAction(elementData.formId);
              }
            }}
            dataTestId='delete-email-button'
          />
        )}
      </Row>
      <SInputEmail mainRecord={elementData.emailType === 'main'}>
        <Form.Item
          style={{ marginBottom: 15 }}
          hasFeedback
          validateStatus={elementData.verificationFeedBack}
          required={required}
        >
          <Space.Compact style={{ width: '100%' }}>
            <Input
              style={{ borderRadius: 0 }}
              data-testid='email-input'
              onChange={addressSearchHandler}
              name={`${elementData.emailType}_email-form`}
              id={`${elementData.formId}_email-form`}
              required
              value={elementData.emailData}
              disabled={!elementData.emailType}
            />
            <CustomButton
              type='primary'
              icon={<PlusOutlined />}
              style={{ borderRadius: 0 }}
              onClick={addNewFormElement}
              onlyIcon
              dataTestId='add-new-email-button'
              placement='topRight'
              tooltipColor='#CCCCCC'
              tooltipText='Add new email'
              tooltipClassName='custom-tooltip'
              disabled={!elementData.emailType}
            />
          </Space.Compact>
        </Form.Item>
      </SInputEmail>
    </Col>
  );
};

export default CustomEmailForm;
