import React, { useState, FC } from 'react';
import { Input, Tag } from 'antd';

interface EditableCellProps {
  value: any;
  onValueChange: (newValue: string) => void;
}

const EditableCellUserTable: FC<EditableCellProps> = ({ value, onValueChange }) => {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const toggleEditing = () => {
    if (editing) {
      onValueChange(inputValue);
    }
    setEditing(!editing);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (
      /^\d*\.?\d{0,2}$/.test(newValue) &&
      parseFloat(newValue) <= 100 &&
      parseFloat(newValue) >= 0
    ) {
      setInputValue(newValue);
    }
  };

  const handleInputConfirm = () => {
    onValueChange(inputValue);
    setEditing(false);
  };

  return editing ? (
    <Input
      value={inputValue}
      onChange={handleInputChange}
      onPressEnter={handleInputConfirm}
      onBlur={handleInputConfirm}
      autoFocus
    />
  ) : (
    <Tag color='default' onClick={toggleEditing}>
      {inputValue}%
    </Tag>
  );
};

export default EditableCellUserTable;
