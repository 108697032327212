import { useQuery } from '@apollo/client';
import { AllUsersModel } from 'src/models/users.model';
import { GET_ALL_USERS } from 'src/graphql/queries/users';
import { GET_OPP_HEADERS } from 'src/graphql/queries/opportunities';
import { GET_ALL_LEAD_SOURCES } from 'src/modules/settings/modules/subTables/modules/opportunities/tabs/leadSource/graphql/queries';
import { AllLeadSourceModel } from 'src/modules/settings/modules/subTables/modules/opportunities/tabs/leadSource/models/leadSource.model';

const useNewOpp = () => {
  const { data: opportunityHeaders } = useQuery(GET_OPP_HEADERS);
  const { data: usersList } = useQuery<AllUsersModel>(GET_ALL_USERS);
  const { data: leadSourcesOptions } = useQuery<AllLeadSourceModel>(GET_ALL_LEAD_SOURCES, {
    variables: { criteria: { pagination: { from: 0, size: 1000 } } },
  });

  const requiredFields = opportunityHeaders
    ? opportunityHeaders.GetOpportunitiesGridHeaders.filter(
        (e: { required: boolean; hidden: boolean }) => e.required && !e.hidden,
      ).map((e: { dataIndex: any }) => e.dataIndex)
    : [];

  return { requiredFields, usersList, opportunityHeaders, leadSourcesOptions };
};
export default useNewOpp;
