import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { SortModel, SubTableColumn } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import handleError from 'src/utils/functions/handleError';
import { CompanyTypeAliasModel } from 'src/graphql/schema-types';
import get from 'lodash/get';
import {
  COLUMNS_TABLE_CUSTOM_TYPES,
  COLUMNS_TABLE_SELECTED,
  COLUMNS_TO_SELECT_OPTIONS,
} from '../constants/table';
import { GetCustomTypesFromElasticSearchType, GetCustomTypesHeaders } from '../types';
import { GET_ALL_CUSTOM_TYPES, GET_HEADERS_CUSTOM_TYPES } from '../graphql/queries';
import { CREATE_CUSTOM_TYPES, REMOVE_CUSTOM_TYPE, UPDATE_CUSTOM_TYPES } from '../graphql/mutations';
import usePredefinedTypesTable from './usePredefinedTypesTable';
import { filterIDAndAddingTitle } from '../../../../../utils/functions';
import { useSubTableTypeContext } from '../contexts/subtabletype.context';
import useFilterColumn from '../../callPattern/hooks/useFilterColumn';

const useCustomTypesTable = () => {
  const { createdFilters, setColumnFilters } = useFilterColumn();
  const { predefinedTypeChanged, setPredefinedTypeChanges } = useSubTableTypeContext();
  const [loadCustomTypes, queryResponse] = useLazyQuery<GetCustomTypesFromElasticSearchType>(
    GET_ALL_CUSTOM_TYPES,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [loadHeadersCustom] = useLazyQuery<GetCustomTypesHeaders>(GET_HEADERS_CUSTOM_TYPES, {
    fetchPolicy: 'network-only',
  });
  const { getAllPredefinedTypesResponse } = usePredefinedTypesTable();
  const [update] = useMutation(UPDATE_CUSTOM_TYPES);
  const [create] = useMutation(CREATE_CUSTOM_TYPES);
  const [remove] = useMutation(REMOVE_CUSTOM_TYPE);
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 1000 });
  const [columns, setColumns] = useState<SubTableColumn[]>([]);
  const [columnsToSelectOption] = useState(COLUMNS_TO_SELECT_OPTIONS);
  const [columnsHeaderSelected] = useState(COLUMNS_TABLE_SELECTED);
  const [newRecord, setNewRecord] = useState({
    name: '',
    parent: 'Customer',
    color: '000000',
  });

  const loadData: FetchFunc = ({ start, renderLen }) =>
    loadCustomTypes({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllCompanyTypeAlias2.results || [],
      total: res.data?.GetAllCompanyTypeAlias2.total || 0,
    }));

  const loadHeader = () => {
    loadHeadersCustom().then((res) => {
      const filteredResult = filterIDAndAddingTitle(
        res.data?.GetCompanyCustomTypesGridHeaders ?? [],
      )
        .filter((el) => el.gridVisible)
        .map((el) => {
          if (el.options) {
            el.options = el.options.map((option: any) => ({
              VALUE: option?.id,
              LABEL: option?.name,
            }));
          }
          return el;
        });
      setColumns(filteredResult ?? COLUMNS_TABLE_CUSTOM_TYPES);
    });
  };

  useEffect(() => {
    loadHeader();
  }, []);

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(loadData);

  useEffect(() => {
    reset();
    setColumnFilters([]);
  }, []);

  useEffect(() => {
    if (predefinedTypeChanged) {
      loadHeader();
      reset();
      setPredefinedTypeChanges?.(false);
    }
  }, [predefinedTypeChanged]);

  const setDefaultColor = () => {
    const findColor = getAllPredefinedTypesResponse?.data?.GetCompaniesBaseTypes?.find(
      (el) => el?.parent?.id === 2,
    );
    setNewRecord((prev) => ({
      ...prev,
      color: findColor?.color as string,
      parent: findColor?.name as string,
    }));
  };

  useEffect(() => {
    if (getAllPredefinedTypesResponse?.data?.GetCompaniesBaseTypes) {
      setDefaultColor();
    }
  }, [getAllPredefinedTypesResponse?.data?.GetCompaniesBaseTypes]);

  const getFilterFieldName = (searchField: string) =>
    searchField === 'company_type_alias_names' ? 'parent_name' : searchField.slice(0, -1);

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filterFieldName = getFilterFieldName(searchField);
    const filters = createdFilters(
      filterFieldName,
      search,
      (dataType as string).toLocaleLowerCase() === 'dropdown' ? 'string' : dataType,
    );
    return loadCustomTypes({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },
          filter: filters,
        },
      },
    }).then((res) => {
      if (res.data?.GetAllCompanyTypeAlias2) {
        const modifiedSearch =
          searchField === 'company_type_alias_names' ? 'parent.name' : searchField.slice(0, -1);

        return {
          results: res.data.GetAllCompanyTypeAlias2.results,
          filter: res.data.GetAllCompanyTypeAlias2.results.map((result: any) => ({
            value: get(result, modifiedSearch),
            label: get(result, modifiedSearch),
          })),
        };
      }

      return {
        results: [],
        filter: [],
      };
    });
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
      parent: 'Customer',
      color: '000000',
    });
    setDefaultColor();
  };

  const postNewRecord = async () => {
    if (newRecord.name === '') return;
    const findParent = getAllPredefinedTypesResponse?.data?.GetCompaniesBaseTypes?.find(
      (el) => el.name === newRecord.parent,
    );
    await create({
      variables: {
        createCompanyTypeAliasInput: {
          name: capitalizeFirstWord(newRecord.name),
          parent: findParent?.id,
          color: newRecord.color,
        },
      },
    }).then(() => {
      setTimeout(() => {
        reset();
      }, 500);
    });
  };

  const handleUpdateRows = (rows: any[]) => {
    rows.forEach((row) => {
      const { id, name, color, selectedId } = row;
      update({
        variables: {
          updateCompanyTypeAliasInput: {
            id,
            name: capitalizeFirstWord(name),
            color,
            parent: selectedId,
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: CompanyTypeAliasModel[]) => {
    const removeId = rows[0].id;
    const messages = {
      400: {
        title: 'This record cannot be deleted',
        message: 'This value is linked to the company. You can not delete it.',
      },
    };

    try {
      await remove({ variables: { id: removeId } }).then(() => {
        setTimeout(() => {
          reset();
        }, 500);
      });
    } catch (error) {
      handleError(error, messages);
      console.log(error);
    }
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = async (sortModel: SortModel | undefined) => {
    if (!sortModel) {
      // Load original data without any sorting
      try {
        const response = await loadCustomTypes({
          variables: {
            criteria: {
              pagination: {
                from: (pagination.page - 1) * pagination.pageSize,
                size: pagination.pageSize,
              },
            },
          },
        });

        // Update data source with the original data
        if (response.data && response.data.GetAllCompanyTypeAlias2) {
          setDataSource(response.data.GetAllCompanyTypeAlias2.results);
        }
      } catch (error) {
        // Handle the error scenario
        console.error('Failed to fetch original data:', error);
      }
      return; // Early return to skip sorting if sortModel is undefined
    }

    // Check if sortBy is "parent" and change it to "parent_name"
    const sortByField = sortModel.sortBy === 'parent' ? 'parent_name' : sortModel.sortBy;

    try {
      // Update pagination with the new sort model
      setPagination((prev) => ({
        ...prev,
        sort: sortModel,
      }));

      // Fetch sorted data
      const response = await loadCustomTypes({
        variables: {
          criteria: {
            pagination: {
              from: (pagination.page - 1) * pagination.pageSize,
              size: pagination.pageSize,
            },
            orderBy: [
              {
                field: sortByField,
                sort: sortModel.sort.toUpperCase(),
              },
            ],
          },
        },
      });

      // Update data source with new sorted data
      if (response.data && response.data.GetAllCompanyTypeAlias2) {
        setDataSource(response.data.GetAllCompanyTypeAlias2.results);
      }
    } catch (error) {
      // Handle the error scenario
      console.error('Failed to fetch sorted data:', error);
    }
  };

  const handleNewRecord = (args: Record<string, unknown>) => {
    if (args?.dataType === 'dropDown') {
      if (getAllPredefinedTypesResponse.data?.GetCompaniesBaseTypes) {
        const findColor = getAllPredefinedTypesResponse?.data?.GetCompaniesBaseTypes?.find(
          (el) => el.id === args?.value,
        );

        setNewRecord((prev) => ({
          ...prev,
          color: findColor?.color as string,
          parent: findColor?.name as string,
        }));
      }
    } else {
      setNewRecord((prev) => ({
        ...prev,
        [args?.dataIndex as string]: args?.value as string,
        autoSave: !!args?.autoSave,
      }));
    }
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    handleUpdateRows,
    handleDeleteRows,
    handleNewRecord,
    pagination,
    columns,
    setColumns,
    columnsToSelectOption,
    columnsHeaderSelected,
    queryResponse,
    newRecord,
    resetNewRecord,
    dataSource,
    onListRender,
    postNewRecord,
    reset,
    predefinedData: getAllPredefinedTypesResponse?.data?.GetCompaniesBaseTypes as [],
  };
};

export default useCustomTypesTable;
