import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { PhoneTypeModel } from 'src/models';

const phoneTypeAdapter: AdapterFunc<GQL.PhoneTypeModel, PhoneTypeModel> = (input) => ({
  id: input.id,
  name: input.name!,
  description: input.description!,
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
});

export default phoneTypeAdapter;
