import styled from 'styled-components';
import { Button, ButtonProps } from 'antd';

interface StyledButtonlProps extends ButtonProps {
  noborder: boolean;
  active?: boolean;
  defaultMode?: boolean;
}

const StyledCustomButton = styled(Button).attrs((props: StyledButtonlProps) => ({
  ghost: props.ghost,
  danger: props.danger,
  noborder: props.noborder,
  active: props.active,
  defaultMode: props.defaultMode,
}))`
  &&& {
    border: ${(props) => props.noborder && 'none !important'};
    background-color: ${(props) => props.active && '#dcdffb !important'};
    &:hover {
      color: ${(props) => props.ghost && !props.danger && '#0078D4 !important'};
      background-color: ${(props) => props.ghost && '#dcdffb !important'};
    }
    width: ${(props) => props.defaultMode && '88px !important'};
    padding: ${(props) => props.defaultMode && '2px 7px !important'};
    border-radius: ${(props) => props.defaultMode && '6px !important'};
  }
`;

export default StyledCustomButton;
