import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { ContactBusinessInfoModel } from 'src/models';
import addressAdapter from './address.adapter';
import { list, optional } from './common';
import emailAdapter from './email.adapter';
import phoneAdapter from './phone.adapter';
// eslint-disable-next-line import/no-cycle
import companyAdapter from './company.adapter';

const contactBusinessInfoAdapter: AdapterFunc<
  GQL.ContactBusinessInfoModel,
  ContactBusinessInfoModel
> = (input) => ({
  id: input.id,
  addresses: list(addressAdapter, input.addresses),
  assistant: input.assistant!,
  company: optional(companyAdapter, input.company),
  context: input.context!,
  createdAt: input.createdAt,
  department: input.department as string,
  emails: list(emailAdapter, input.emails),
  enabledForcedSync: input.enabledForcedSync!,
  doNotEmail: input.doNotEmail!,
  globallyVisible: input.globallyVisible!,
  isPrimary: input.isPrimary!,
  jobTitle: input.jobTitle!,
  manager: input.manager!,
  phones: list(phoneAdapter, input.phones),
  referencedBy: input.referencedBy!,
  updatedAt: input.updatedAt,
  mainPhone: optional(phoneAdapter, input.mainPhone),
  mainEmail: optional(emailAdapter, input.mainEmail),
  mainAddress: optional(addressAdapter, input.mainAddress),
});

export default contactBusinessInfoAdapter;
