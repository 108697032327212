import styled from 'styled-components';

const StyledOppFormContent = styled.div`
  .section-card.ant-card {
    padding: 16px 24px 16px 48px;
    background: #ffffff;
    box-shadow: none !important;

    border-radius: 12px;
    border: 1px solid #9a9a9a !important;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .ant-card-head {
      border-bottom: none;
      min-height: 0;
      padding: 0;

      .ant-card-head-title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #4b4b4b;
      }
    }

    .ant-card-body {
      padding: 0;
    }

    .anticon-holder {
      position: absolute;
      top: 19px;
      left: 12px;
      font-size: 24px;
      color: #4b4b4b;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    .ant-collapse {
      border: none;
    }

    .ant-collapse-item {
      border-bottom: none;
    }

    .ant-collapse-header {
      display: none;
    }

    .ant-collapse-content {
      border-radius: 0;
      border-top: none;
    }

    .ant-collapse-content-box {
      padding: 24px 0 0 0;
    }

    .ant-form-item {
      margin-bottom: 12px;
    }

    .ant-form-item-required {
      flex-direction: row-reverse;
    }
    .ant-form-item-label label {
      color: #353535 !important;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 500 !important;
      line-height: 22px !important;
    }

    .ant-form-item .ant-form-item-label > label {
      color: #353535;
    }
  }
`;

export default StyledOppFormContent;
