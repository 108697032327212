import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';

const emailToInputAdapter: AdapterFunc<GQL.EmailModel, GQL.CreateEmailInput> = (input) => ({
  email: input.email!,
  id: input?.id,
  emailType: input?.emailType?.id ?? 0,
});

export default emailToInputAdapter;
