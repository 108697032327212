import styled from 'styled-components';

const SAuditableInfo = styled.div`
  .flex-gap {
    display: grid;
    min-width: 20rem;
    max-width: 20rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    p {
      color: #4b4b4b;
      font-size: 12px;
    }
  }
`;

export default SAuditableInfo;
