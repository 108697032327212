/* eslint-disable no-duplicate-imports */
import React from 'react';
import { CustomModal } from 'src/components/commons/customModal';
import CustomButton from 'src/components/commons/customButton';
import type { RadioChangeEvent } from 'antd';
import { Radio, Space } from 'antd';

type Props = {
  handleOpenedMergedCompanyConfirmation: () => void;
  show: boolean;
  selectedCompany: any;
  selectedRadio?: number;
  handleRadioSelection: (value: RadioChangeEvent) => void;
  handleMergeTo: () => void;
};

const MergedCompanyConfirmation: React.FC<Props> = ({
  show,
  handleOpenedMergedCompanyConfirmation,
  selectedCompany,
  selectedRadio,
  handleRadioSelection,
  handleMergeTo,
}) => (
  <CustomModal
    modalStatus={show}
    onCancelAction={handleOpenedMergedCompanyConfirmation}
    onOkAction={() => {}}
    modalTitle='Merge companies'
    modalBody={
      <div>
        <p>
          Merging companies will move all Contacts, Product Potentials, Opportunities, Activity
          Journals, Quotes, Purchase Orders, Sales and Commission History into{' '}
          <strong>{selectedCompany?.name}</strong>. Are you sure to proceed?
        </p>
        <Space style={{ marginTop: '15px' }}>
          <p>What to do with merged company?</p>
          <Radio.Group onChange={handleRadioSelection} value={selectedRadio}>
            <Radio value={1}>Mark as inactive</Radio>
            <Radio value={2}>Delete</Radio>
          </Radio.Group>
        </Space>
      </div>
    }
    footer={
      <Space>
        <CustomButton
          type='text'
          title='No'
          onClick={handleOpenedMergedCompanyConfirmation}
          style={{
            fontSize: '14px',
            height: '32px',
            padding: '4px 15px',
          }}
        />
        <CustomButton
          type='primary'
          title='Yes'
          disabled={!selectedRadio}
          onClick={handleMergeTo}
          style={{
            fontSize: '14px',
            height: '32px',
            padding: '4px 15px',
          }}
        />
      </Space>
    }
  />
);

export default MergedCompanyConfirmation;
