import styled from 'styled-components';

const FormSectionStyled = styled.div`
  .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-form-item-required {
    flex-direction: row-reverse;
  }

  .form-section-title {
    font-style: normal;
    color: var(--crm-primary-primary, #0078d4) !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 28px !important;
    display: flex;

    .form-section-title-text {
      flex: 1;
    }

    .form-section-title-suffix {
      button {
        border: none;
      }
    }
  }

  .form-section-content {
    margin-bottom: 1rem;
  }
`;

export default FormSectionStyled;
