import { Route, Routes } from 'react-router';
import NotFoundPage from 'src/components/commons/notFound';
import { PageSubTableContacts } from '../../pages';
import TabContactGroupTable from './tabs/contactGroup/TabContactGroupTable';
import TabProductInterest from './tabs/productInterest/TabProductInterest';

const SubTableContactsRoutes = () => (
  <Routes>
    <Route element={<PageSubTableContacts />}>
      <Route index path='product-interest' element={<TabProductInterest />} />
      <Route index path='contact-group' element={<TabContactGroupTable />} />
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  </Routes>
);

export default SubTableContactsRoutes;
