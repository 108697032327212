import { gql } from '@apollo/client';

export const UPDATE_PRODUCT_INTEREST = gql`
  mutation UpdateProductInterest($updateProductInterestInput: UpdateProductInterestInput!) {
    UpdateProductInterest(updateProductInterestInput: $updateProductInterestInput) {
      id
      name
    }
  }
`;

export const CREATE_PRODUCT_INTEREST = gql`
  mutation CreateProductInterest($createProductInterestInput: CreateProductInterestInput!) {
    CreateProductInterest(createProductInterestInput: $createProductInterestInput) {
      id
      name
    }
  }
`;

export const REMOVE_PRODUCT_INTEREST = gql`
  mutation RemoveProductInterest($removeProductInterestId: Int!) {
    RemoveProductInterest(id: $removeProductInterestId)
  }
`;
