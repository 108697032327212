export const onDeleteDiv = (
  <div
    style={{
      background: '#FFFCEB',
      border: '1px solid #FAAD14',
      padding: '10px 10px',
      borderRadius: '5px',
    }}
  >
    Deleting this record is an irreversible action and will permanently remove all associated data.
  </div>
);
