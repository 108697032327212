import { createGlobalStyle } from 'styled-components';

const SideTableFilterStyles = createGlobalStyle`
.ant-table-selection-column {
  vertical-align: bottom;
}

.ant-table-thead .ant-checkbox-wrapper {
  margin-bottom: 10px;
}

.ant-table-body .ant-table-row .ant-table-selection-column {
  vertical-align: middle;
}

.ant-table-row {
  height: 32px;
}

.hide-checkbox .ant-checkbox-wrapper {
  display: none;
}
.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child{
  padding: 12px 8px;
  height: 65px;
}
.side-table-filter-input {
  border: 1px solid #0078D4;
  border-radius: 8px;
  height: 26px;
  width: 90%;
}
.side-table-filter-container {
  position: absolute;
  z-index: 5;
  border-right: 1px solid;
}

.side-table-filter-container::-webkit-scrollbar {
  display: none
}
`;

export default SideTableFilterStyles;
