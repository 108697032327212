import SubTableV1 from 'src/components/commons/subTableV1';
import { onDeleteDiv } from 'src/components/commons/subTableV1/components/deleteNode';
import TempSkeleton from 'src/components/commons/tempSkeleton';
import { useContactsContext } from '../../context/contacts.context';
import { ContactsGlobalStyles } from '../../styles/global';

const ContactsTable = () => {
  const {
    columns,
    viewportHeight,
    setColumns,
    handleOnPin,
    handleDeleteRows,
    items,
    handleOnReload,
    loading,
    handleClearFilter,
    filterCount,
    handleOnDetailsCick,
    autocompleteFieldSearch,
    setSearchFields,
    handleSort,
    dataSource,
    searchFields,
    loadPage,
    pinnedColumnsNumber,
  } = useContactsContext();

  if (columns.length < 3 || loading) {
    return <TempSkeleton />;
  }

  return (
    <>
      <ContactsGlobalStyles pinnedColumns={pinnedColumnsNumber || 0} />
      <SubTableV1
        data={dataSource ?? []}
        setColumns={setColumns}
        columns={columns ?? []}
        onListRender={loadPage}
        actionCell={['ellipsis']}
        headerActions={['delete', 'filter', 'clear-left-filter', 'columns']}
        scroll={{ y: viewportHeight, x: 'max-content' }}
        id='opportunities-table'
        columnFilterShown
        nonAddable
        total
        pinnableColumns
        handleOnPin={handleOnPin}
        handleOnReload={handleOnReload}
        onDeleteClick={handleDeleteRows}
        withDragHandler
        onDetailsClick={handleOnDetailsCick}
        autocompleteFieldProvider={autocompleteFieldSearch}
        showDetailsIcon
        items={items}
        titleOnDelete='Are you sure?'
        onSortChange={handleSort}
        contentOnDelete={onDeleteDiv as unknown as string}
        useUniqueValues
        setSearchFields={setSearchFields}
        searchFields={searchFields}
        handleClearFilter={handleClearFilter}
        filterCount={filterCount}
        showFilterBadge={!!Object.keys(searchFields).length}
        handleAllRowActions={() => {}}
      />
    </>
  );
};

export default ContactsTable;
