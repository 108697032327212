import { Outlet } from 'react-router';
import OffCanvas from 'src/components/commons/offcanvas';
import UnderConstruction from 'src/components/UnderConstruction';
import CompanySetupNav from './companySetupNav';
import CardCompanyContact from './cardCompanyContact';

const CompanyContent = () => {
  const header = (isCollapsed: boolean) => <CardCompanyContact isCollapsed={isCollapsed} />;
  const content = () => (
    <>
      <CompanySetupNav />
      <Outlet />
    </>
  );
  const offCanvas = () => (
    <UnderConstruction
      style={{
        messageFontSize: '30px',
        messageTextAlign: 'center',
        svgWidth: '50px',
        svgHeight: '50px',
        svgMargin: '0 auto',
      }}
    />
  );

  return <OffCanvas header={header} content={content} offCanvas={offCanvas} />;
};

export default CompanyContent;
