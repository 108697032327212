import { Button, Col, Input, Row, Select, Space, Tooltip, Form } from 'antd';
import React from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { getDefaultValue, getValueFromSelectToInput } from 'src/utils/functions/getDefaultValues';
import InputGroup from '../commons/genericForm/components/inputGroup';
import StyledPhoneInput from './styles/PhoneInput.style';
import usePhonesSubForm, { Props } from './usePhonesSubForm';

const Phones: React.FC<Props> = (props) => {
  const {
    phones,
    options,
    handleAddClick,
    handlePhoneChange,
    handleExtensionChange,
    handleTypeChange,
    readOnly,
    formName,
    handleRemovePhones,
  } = usePhonesSubForm(props);

  const validateNumber = async (phone: any) => {
    if (!phone) {
      return Promise.reject(new Error('A phone number is required'));
    }
    return Promise.resolve();
  };

  return (
    <Row gutter={16}>
      {phones?.map((phone, index) => {
        const phoneValue = `${phone.countryCode || '+1'}${phone.areaCode || ''}${
          phone.phone || ''
        }`.replace(/-/g, '');
        const phoneExt = phone.extension ? Number(phone.extension) : undefined;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Col span={24} key={`${index}-`}>
            <Row gutter={16}>
              {index > 0 && (
                <>
                  <Col span={6}>
                    <Form.Item label='Phone Type' style={{ marginTop: '10px' }}>
                      {readOnly ? (
                        <Input
                          readOnly
                          value={getValueFromSelectToInput(phone.phoneType, options as [])}
                          placeholder='Please select'
                        />
                      ) : (
                        <Select
                          data-phone-type={phone.phoneType}
                          placeholder='Select phone type'
                          // defaultValue={getDefaultValue(phone.phoneType, options)}
                          value={getDefaultValue(phone.phoneType, options)}
                          options={options}
                          onChange={(val) => handleTypeChange(index, String(val))}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col
                    span={18}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-end',
                      paddingBottom: '12px',
                    }}
                  >
                    <Space wrap>
                      <Tooltip title='Remove Phone'>
                        <Button
                          shape='circle'
                          icon={<DeleteOutlined />}
                          onClick={
                            readOnly
                              ? (e) => {
                                  e.preventDefault();
                                  return false;
                                }
                              : () => handleRemovePhones(phone.id!, index, formName)
                          }
                        />
                      </Tooltip>{' '}
                    </Space>
                  </Col>
                </>
              )}
              <Col span={24}>
                <Space.Compact style={{ width: '100%' }}>
                  <Form.Item
                    style={{ marginBottom: 0, width: '100%' }}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your phone number!',
                      },
                      {
                        validator: () => {
                          if (index > 0 && phone.phoneType) validateNumber(phone.phone);
                        },
                      },
                    ]}
                  >
                    <StyledPhoneInput
                      country='us'
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: false,
                        paddingLeft: 0,
                        'data-index': index,
                        readOnly,
                      }}
                      disabled={index > 0 && !phone.phoneType}
                      value={phoneValue}
                      onChange={(value, data, e, formattedValue) =>
                        handlePhoneChange(value, data, e, formattedValue, index)
                      }
                    />
                  </Form.Item>
                  <InputGroup style={{ width: 200 }}>
                    <Input
                      name='extension'
                      value={phoneExt}
                      placeholder='Extension'
                      onChange={(ev) => handleExtensionChange(index, ev.target.value)}
                      type='number'
                      style={{ height: 'fit-content' }}
                      readOnly={readOnly}
                      disabled={index > 0 && !phone.phoneType}
                      onKeyDown={(event) => {
                        if (!/[0-9]/.test(event.key) && event.key !== 'Backspace') {
                          event.preventDefault();
                        }
                      }}
                    />
                    <Tooltip title='Add new Phone' color='#CCCCCC'>
                      <Button
                        type='primary'
                        shape='circle'
                        disabled={index > 0 && !phone.phoneType}
                        style={{ borderRadius: 2 }}
                        onClick={
                          readOnly
                            ? (e) => {
                                e.preventDefault();
                                return false;
                              }
                            : () => handleAddClick(index)
                        }
                      >
                        <PlusOutlined />
                      </Button>
                    </Tooltip>
                  </InputGroup>
                </Space.Compact>
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default Phones;
