import { useLazyQuery, gql } from '@apollo/client';
import { Form, FormInstance, notification } from 'antd';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  FC,
} from 'react';
import { SubTableColumn } from 'src/components/commons/subTable/types';
import { GET_COMPANIES_ELASTIC_2 } from 'src/components/company/queries';
import { CompanyModel, Query } from 'src/graphql/schema-types';
import useCompanyHeaders from 'src/hooks/useCompanyHeaders';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { CompaniesHeaderType, CompaniesHeaderType2 } from 'src/types/companiesTypes';

type ExportCompaniesContextProps = {
  searchFields: Record<string, string[]>;
  setSearchFields: (searchFields: Record<string, string[]>) => void;
  form: FormInstance;
  companyHeaders: CompaniesHeaderType2[];
  headers: SubTableColumn[];
  dataSource: CompanyModel[];
  areAllSelected: boolean;
  selectedHeaders: Record<string, boolean>;
  setSelectedHeaders: (selectedHeaders: Record<string, boolean>) => void;
  onListRender: (params: { renderLen: number; start: number }) => void;
  reset: () => void;
  exportTable: () => void;
};

const ExportCompaniesContext = createContext<ExportCompaniesContextProps>(null!);

const ExportCompaniesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [form] = Form.useForm();
  const [selectedHeaders, setSelectedHeaders] = useState<Record<string, boolean>>({});
  const [searchFields, setSearchFields] = useState<Record<string, string[]>>({});
  const companyHeaders = useCompanyHeaders();
  const [fetchCompanies] = useLazyQuery<Pick<Query, 'GetCompaniesFromElastic2'>>(
    GET_COMPANIES_ELASTIC_2,
    {
      fetchPolicy: 'cache-first',
    },
  );

  const { data: pendingStoredReports, storeData: storePendingStoredReports } = useLocalStorage(
    'pendingStoredReports',
    [],
    'localStorage',
  );

  const validateSearchFields = (fieldsValidate: Record<string, string[]>) => {
    const fields: any = {};
    Object.keys(fieldsValidate).forEach((field: string) => {
      if (fieldsValidate[field].length > 0) {
        fields[field] = fieldsValidate[field];
      }
    });
    const isValidated = Object.keys(fields).length > 0 || Object.keys(fieldsValidate).length > 0;
    if (isValidated) {
      return fields;
    }
    return {};
  };

  const validatedSearchFields = validateSearchFields(searchFields);

  const loadPage = useCallback<FetchFunc>(
    () =>
      fetchCompanies({
        variables: {
          criteria: {
            pagination: {
              size: -1,
            },
          },
          searchFields: validatedSearchFields,
        },
      }).then((res) => ({
        results: res.data?.GetCompaniesFromElastic2?.results || [],
        total: res.data?.GetCompaniesFromElastic2?.total || 0,
      })),
    [searchFields],
  );

  const doFirstFetch =
    Object.keys(validatedSearchFields).length > 0 &&
    Object.keys(validatedSearchFields).some((field: string) => field !== 'enabled');

  const { dataSource, onListRender, reset } = useInfinityDataSource(
    loadPage,
    undefined,
    doFirstFetch,
  );

  const headers: SubTableColumn[] = companyHeaders
    .filter(
      (header: CompaniesHeaderType) =>
        !header.hidden && !!header.headerName.trim() && selectedHeaders[header.searchField],
    )
    .map((header: CompaniesHeaderType) => ({
      title: header.headerName,
      dataIndex: header.dataIndex,
      dataKey: header.key,
      width: 250,
    }));

  const areAllSelected = Object.values(selectedHeaders).every((value) => value);

  useEffect(() => {
    setSelectedHeaders(
      companyHeaders
        .filter((header: CompaniesHeaderType2) => !header.hidden && !!header.headerName.trim())
        .reduce((acc: Record<string, boolean>, header: CompaniesHeaderType2) => {
          acc[header.searchField!] = true;
          return acc;
        }, {} as Record<string, boolean>),
    );
  }, [companyHeaders]);

  function createFinalObject(
    companyHeadersData: CompaniesHeaderType2[],
    selectedHeadersData: Record<string, boolean>,
  ): any {
    const filteredHeaders: CompaniesHeaderType2[] = companyHeadersData.filter(
      (header) => selectedHeadersData[header.searchField] && header.searchField.trim() !== '',
    );

    const finalObject: any = {};

    filteredHeaders.forEach((header) => {
      const { dataIndex, key }: { dataIndex: string; key?: string } = header;

      const dataIndexParts: string[] = dataIndex.split('.');

      let currentObj: any = finalObject;
      dataIndexParts.forEach((part, index) => {
        if (index === dataIndexParts.length - 1) {
          currentObj[part] = key ? { key } : {};
        } else {
          currentObj[part] = currentObj[part] || {};
          currentObj = currentObj[part];
        }
      });
    });

    return finalObject;
  }

  function transformToAttributeStrings(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map((attr) =>
        typeof attr === 'object' ? transformToAttributeStrings(attr) : attr,
      );
    }

    if (typeof obj === 'object' && obj !== null) {
      const result: any = {};

      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && Object.keys(obj[key]).length > 0) {
          if ('key' in obj[key]) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            result[key] = { name: obj[key]['key'] };
          } else {
            result[key] = transformToAttributeStrings(obj[key]);
          }
        } else {
          result[key] = obj[key];
        }
      });

      return result;
    }

    return obj;
  }

  function generateRequestedFieldsString(obj: any): string {
    return Object.keys(obj)
      .map((key) => {
        if (typeof obj[key] === 'object' && Object.keys(obj[key]).length > 0) {
          return `${key} { ${generateRequestedFieldsString(obj[key])} }`;
        }
        return key;
      })
      .join('\n');
  }
  const finalObject = createFinalObject(companyHeaders, selectedHeaders);

  const transformedObject = transformToAttributeStrings(finalObject);

  const requestedFieldsString = generateRequestedFieldsString(transformedObject);

  const CREATE_COMPANY_EXPORT = gql`
    query CreateCompanyExport(
      $criteria: CompanyCriteriaInput2!
      $searchFields: CompanyFindElasticInput2
    ) {
      CreateCompanyExport(Criteria: $criteria, SearchFields: $searchFields) {
        requestId
        status
        token
        requestedFields {
          ${requestedFieldsString === '' ? null : requestedFieldsString}
        }
      }
    }
  `;
  const [createExport] = useLazyQuery<'CreateCompanyExport'>(CREATE_COMPANY_EXPORT, {
    fetchPolicy: 'cache-first',
  });

  const exportTable = () =>
    createExport({
      variables: {
        criteria: {
          pagination: {
            size: -1,
          },
        },
        searchFields: validatedSearchFields,
      },
    })
      .then((res: any) => {
        const updatedReports = [...pendingStoredReports, res.data.CreateCompanyExport];
        storePendingStoredReports(updatedReports);
        notification.info({
          message: 'Export in progress. We will notify you when its done',
          duration: 3,
          key: 'export',
          placement: 'bottomLeft',
        });
      })
      .catch((error) => {
        console.error('Error exporting companies:', error);
      });

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = useMemo(
    () => ({
      form,
      searchFields,
      companyHeaders,
      dataSource,
      headers,
      areAllSelected,
      selectedHeaders,
      setSelectedHeaders,
      setSearchFields,
      onListRender,
      reset,
      exportTable,
    }),
    [form, searchFields, companyHeaders, dataSource, headers, areAllSelected, selectedHeaders],
  );

  return (
    <ExportCompaniesContext.Provider value={value}>{children}</ExportCompaniesContext.Provider>
  );
};

export const useExportCompaniesContext = () => useContext(ExportCompaniesContext);
export default ExportCompaniesProvider;
