import { gql } from '@apollo/client';

const GET_ALL_ADITIONAL_FIELDS = gql`
  query GetAllAdditionalFields(
    $criteria: AdditionalFieldsCriteriaInput!
    $searchFields: FindAdditionalFieldsInput
  ) {
    GetAllAdditionalFields(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        name
        id
        dataStructure
        dataType
        description
        module
      }
    }
  }
`;

const GET_ALL_CONTACT_ADITIONAL_FIELDS = gql`
  query GetAllContactAdditionalField($contactId: Int!) {
    GetAllContactAdditionalField(contactId: $contactId) {
      contact {
        id
      }
      id
      value
      additionalField {
        id
        module
        name
      }
    }
  }
`;

export { GET_ALL_ADITIONAL_FIELDS, GET_ALL_CONTACT_ADITIONAL_FIELDS };
