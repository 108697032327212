import { gql } from '@apollo/client';

export const GET_ALL_STAGE_GROUP = gql`
  query GetAllStageGroup($criteria: StageGroupCriteriaInput!) {
    GetAllStageGroup(Criteria: $criteria) {
      results {
        id
        name
        boolean
      }
      total
    }
  }
`;
