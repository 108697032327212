import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { LineItemStatusModel } from '../models/lineItemStatus.model';

export const lineItemStatusAdapter: AdapterFunc<
  GQL.SubTableByCompanyElasticResponse,
  LineItemStatusModel
> = (input) => ({
  id: input.id!,
  company: input.company!,
  data: input.data!,
});
