import React from 'react';
import SubTable from 'src/components/commons/subTable';
import PageContainer from 'src/components/pageContainer/PageContainer';
import { CustomLoadingModal } from 'src/components/commons/customModal';
import StyleInfinityScroll from '../../../../styles/StyleInfinityScroll.style';
import useTabContactGroupTable from './hooks/useTabContactGroupTable';

const TabContactGroupTable = () => {
  const {
    autocompleteFieldSearch,
    handleFilterChange,
    handleSortModelChange,
    handleUpdateRows,
    setColumns,
    onListRender,
    dataSource,
    columns,
    spinning,
    newRecord,
    postData,
    resetNewRecord,
    createNewRecord,
    handleClearFilter,
    handleDeleteRows,
  } = useTabContactGroupTable();

  return (
    <>
      {spinning && <CustomLoadingModal modalStatus />}
      <PageContainer margin='2rem'>
        <StyleInfinityScroll>
          <SubTable
            data={dataSource}
            columns={columns}
            setColumns={setColumns}
            autocompleteFieldProvider={autocompleteFieldSearch}
            onFilterChange={handleFilterChange}
            onSortChange={handleSortModelChange}
            onListRender={onListRender}
            scroll={{ y: '60vh', x: '90vw' }}
            actionCell={['delete']}
            headerActions={['filter', 'edit', 'delete']}
            id='contact-group-table'
            showOptionFilterLeft={false}
            allowGlobalEditActive
            activeOnSelectRowsDisableEdit
            total
            nonGlobalFilterable
            showAutoIncrement
            handleUpdateRows={handleUpdateRows}
            onDeleteClick={handleDeleteRows}
            newRecord={newRecord}
            resetNewRecord={resetNewRecord}
            postData={postData}
            saveNewRecord={createNewRecord}
            clearFilter={handleClearFilter}
            columnFilterShown
          />
        </StyleInfinityScroll>
      </PageContainer>
    </>
  );
};
export default TabContactGroupTable;
