import styled from 'styled-components';
import { Col } from 'antd';
import { ColProps } from 'antd/es/grid';

interface StyledColProps extends ColProps {
  moveToRight: boolean;
}

const StyledCol = styled(Col)<StyledColProps>`
  &&& {
    padding-inline-start: ${(props) => (props.moveToRight ? '261px' : '0%')};
    transition: padding-inline-start 0.3s;
  }
`;
export default StyledCol;
