import { CompanyModel } from 'src/models';
import _ from 'lodash';
import { optional } from 'src/adapters/common';
import {
  addressToInputAdapter,
  emailToInputAdapter,
  list,
  phoneToInputAdapter,
} from 'src/adapters';

export const prepareDataToDelete = (element: [number], passedList: number[]) => {
  const innerList = _.cloneDeep(passedList);
  return _.xor(innerList, element);
};
export const mainAndAditionalElement = (elementName: string, contact: any) => {
  switch (elementName) {
    case 'addresses': {
      const mainAddress: any = optional(addressToInputAdapter, contact.mainAddress);
      const aditionalAddress = list(addressToInputAdapter, contact.addresses);
      let fullAddress = [{ isMain: true }].concat(aditionalAddress || ([] as any[]));
      if (mainAddress && aditionalAddress) {
        mainAddress.isMain = true;
        fullAddress = [mainAddress].concat(
          aditionalAddress.filter((item) => item.id !== mainAddress.id),
        );
      }
      return fullAddress;
    }
    case 'phones': {
      const mainPhone: any = optional(phoneToInputAdapter, contact.mainPhone);
      const aditionalPhones = list(phoneToInputAdapter, contact.phones);
      let fullPhones = [{ isMain: true }].concat(aditionalPhones || ([] as any[]));
      if (mainPhone && aditionalPhones) {
        mainPhone.isMain = true;
        fullPhones = [mainPhone].concat(aditionalPhones.filter((item) => item.id !== mainPhone.id));
      }
      return fullPhones;
    }
    case 'emails': {
      const mainEmail: any = optional(emailToInputAdapter, contact.mainEmail);
      const aditionalEmails = list(emailToInputAdapter, contact.emails);
      let fullEmail = [{ isMain: true }].concat(aditionalEmails || ([] as any[]));
      if (mainEmail && aditionalEmails) {
        mainEmail.isMain = true;
        fullEmail = [mainEmail].concat(aditionalEmails.filter((item) => item.id !== mainEmail.id));
      }
      return fullEmail;
    }
    default:
      return undefined;
  }
};

export const filterCompanyEmailPhoneAddressInfo = (company: CompanyModel) => {
  const innerCompanyData = JSON.parse(JSON.stringify(company));
  const ensureArray = (data: any): any[] => {
    if (data === null || data === undefined) {
      return [];
    }
    return Array.isArray(data) ? data : Object.values(data);
  };
  const innerInfoAddress = ensureArray(innerCompanyData?.addresses);
  const innerInfoEmails = ensureArray(innerCompanyData?.emails);
  const innerInfoPhones = ensureArray(innerCompanyData?.phones);

  if (innerInfoAddress) {
    const filteredInnerAddress = _.filter(
      innerInfoAddress,
      (obj: any) =>
        obj.address ||
        obj.region ||
        obj.pobox ||
        obj.addressType ||
        obj.city ||
        obj.country ||
        obj.state ||
        obj.territory ||
        obj.zipCode,
    );
    delete innerCompanyData.addresses;
    if (filteredInnerAddress.length > 0) {
      filteredInnerAddress.forEach((address: any) => {
        if (address.isMain) {
          innerCompanyData.mainAddress = address;
          delete innerCompanyData.mainAddress.isMain;
        } else if (address.id) {
          if (!innerCompanyData.updateAddresses) {
            innerCompanyData.updateAddresses = [address];
          } else {
            innerCompanyData.updateAddresses.push(address);
          }
        } else if (!innerCompanyData.addAddresses) {
          innerCompanyData.addAddresses = [address];
        } else {
          innerCompanyData.addAddresses.push(address);
        }
      });
    }
  }

  if (innerInfoEmails) {
    const filteredInnerEmails = _.filter(innerInfoEmails, (obj: any) => obj.email);
    delete innerCompanyData.emails;
    if (filteredInnerEmails.length > 0) {
      filteredInnerEmails.forEach((email: any) => {
        if (email.isMain) {
          innerCompanyData.mainEmail = email;
          delete innerCompanyData.mainEmail.isMain;
        } else if (email.id) {
          if (!innerCompanyData.updateEmails) {
            innerCompanyData.updateEmails = [email];
          } else {
            innerCompanyData.updateEmails.push(email);
          }
        } else if (!innerCompanyData.addEmails) {
          innerCompanyData.addEmails = [email];
        } else {
          innerCompanyData.addEmails.push(email);
        }
      });
    }
  }

  if (innerInfoPhones) {
    const filteredInnerPhones = _.filter(innerInfoPhones, (obj: any) => obj.phone || obj.extension);
    delete innerCompanyData.phones;
    if (filteredInnerPhones.length > 0) {
      filteredInnerPhones.forEach((phone: any) => {
        if (phone.isMain) {
          innerCompanyData.mainPhone = phone;
          delete innerCompanyData.mainPhone.isMain;
        } else if (phone.id) {
          if (!innerCompanyData.edditedPhones) {
            innerCompanyData.updatePhones = [phone];
          } else {
            innerCompanyData.updatePhones.push(phone);
          }
        } else if (!innerCompanyData.addPhones) {
          innerCompanyData.addPhones = [phone];
        } else {
          innerCompanyData.addPhones.push(phone);
        }
      });
    }
  }

  return innerCompanyData;
};
