import SubTable from 'src/components/commons/subTable';
import useCannedNotesTable from './useCannedNotesTable';

const TabCannedNotes = () => {
  const {
    viewportHeight,
    dataSource,
    columns,
    newRecord,
    resetNewRecord,
    setColumns,
    handleFilterChange,
    handleSortChange,
    handleDeleteRows,
    handleEditRows,
    autocompleteFieldSearch,
    postData,
    createNewRecord,
  } = useCannedNotesTable();

  return (
    <SubTable
      data={dataSource}
      columns={columns}
      setColumns={setColumns}
      scroll={{ y: viewportHeight, x: 1100 }}
      actionCell={['delete']}
      headerActions={['filter', 'edit', 'delete']}
      id='canned-notes-table'
      showOptionFilterLeft={false}
      allowGlobalEditActive
      total
      onFilterChange={handleFilterChange}
      onSortChange={handleSortChange}
      onEditClick={handleEditRows}
      onDeleteClick={handleDeleteRows}
      autocompleteFieldProvider={autocompleteFieldSearch}
      postData={postData}
      newRecord={newRecord}
      resetNewRecord={resetNewRecord}
      saveNewRecord={createNewRecord}
      columnFilterShown
    />
  );
};

export default TabCannedNotes;
