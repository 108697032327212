import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, ModalFuncProps } from 'antd';

const { confirm } = Modal;

const convertPropToNumber = (prop: string) => {
  const div = document.createElement('div');
  div.style.position = 'absolute';
  div.style.height = prop;
  document.body.appendChild(div);
  const computedHeight = window.getComputedStyle(div).height;
  document.body.removeChild(div);
  return parseInt(computedHeight, 10);
};

let closeSource: any = null;

export const showConfirmationMessage = ({
  title = 'Are you sure you want to remove this record?',
  content = 'Any information linked to this record will be lost.',
  onOk,
  onCancel,
  cancelText,
  okText,
  okButtonProps,
  cancelButtonProps,
  closable,
  closeIcon,
  useOnClick,
}: Partial<ModalFuncProps> & { useOnClick?: boolean }) => {
  const cancelBtnProps = {
    id: 'cancel-button',
    'data-testid': 'confirmation-cancel-button',
    style: { width: '100px' },
    ...cancelButtonProps,
  };

  if (useOnClick) {
    cancelBtnProps.onClick = () => {
      closeSource = 'cancel';

      if (onCancel) {
        onCancel(closeSource);
      }
    };
  }

  confirm({
    icon: <ExclamationCircleOutlined />,
    centered: true,
    title,
    className: typeof content !== 'string' ? 'confirmModal' : '',
    content,
    okText: okText || 'Yes',
    cancelText: cancelText || 'No',
    type: 'warn',
    onOk,
    onCancel: () => {
      if (onCancel) {
        onCancel(closeSource);
      }
      closeSource = null; // Reset the source
    },
    closable,
    closeIcon,
    okButtonProps: {
      'data-testid': 'confirmation-ok-button',
      style: { minWidth: '100px' },
      ...okButtonProps,
    },
    cancelButtonProps: cancelBtnProps,
    // eslint-disable-next-line no-return-assign
    afterClose: () => (closeSource = 'close'), // Set the source for the "X" button
  });
};

export default convertPropToNumber;
