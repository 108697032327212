import { SubTableColumn } from '../../subTable/types';
import { CreateCompanyPayloadType } from '../types';
import {
  parseAddress,
  parseEmails,
  parseOption,
  parseOptions,
  parsePhone,
} from '../utils/functions';

export const PARENT_COMPANY_COLUMNS: SubTableColumn[] = [
  {
    dataIndex: 'name',
    dataStructure: 'name',
    title: 'Company Name',
    orderField: 'name',
    searchField: 'names',
    hasLink: false,
  },
  {
    dataIndex: 'companyType',
    title: 'Company Type',
    dataKey: 'name',
    orderField: 'companyTypeName',
    searchField: 'type_names',
  },
  {
    title: 'Sales Team',
    dataIndex: 'salesTeam',
    dataKey: 'name',
    orderField: 'salesTeamName',
    searchField: 'sales_team_names',
  },
  {
    dataIndex: 'mainPhone.phone',
    title: 'Main Phone',
    orderField: 'mainPhonePhone',
    searchField: 'main_phone_phones',
  },
  {
    dataIndex: 'mainAddress.address',
    title: 'Main Address',
    orderField: 'mainAddressAddress',
    searchField: 'main_address_addresses',
  },
];

export const FAKE_ID = 1;

export const CREATE_COMPANY_SCHEMA: CreateCompanyPayloadType = {
  name: {
    required: false,
    inputType: 'textInput',
    error: false,
    data: '',
  },
  website: {
    required: false,
    inputType: 'textInput',
    error: false,
    data: '',
  },
  addresses: {
    required: false,
    inputType: 'integratedFields',
    error: false,
    data: '',
    parser: parseAddress,
  },
  phones: {
    required: false,
    inputType: 'integratedFields',
    error: false,
    data: '',
    parser: parsePhone,
  },
  emails: {
    required: false,
    inputType: 'integratedFields',
    error: false,
    data: '',
    parser: parseEmails,
  },
  companyTypeId: {
    required: true,
    inputType: 'select',
    error: false,
    data: '',
    parser: parseOption,
  },
  salesTeam: {
    required: false,
    inputType: 'select',
    error: false,
    data: '',
    parser: parseOption,
  },
  privateTeamId: {
    required: false,
    inputType: 'select',
    error: false,
    data: '',
    parser: parseOption,
  },
  parent: {
    required: false,
    inputType: 'external',
    error: false,
    data: '',
  },
  callPatternId: {
    required: false,
    inputType: 'select',
    error: false,
    data: '',
    parser: parseOption,
  },
  categoryId: {
    required: false,
    inputType: 'select',
    error: false,
    data: '',
    parser: parseOption,
  },
  rankId: {
    required: false,
    inputType: 'select',
    error: false,
    data: '',
    parser: parseOption,
  },
  visitFrequency: {
    required: false,
    inputType: 'textInput',
    error: false,
    data: '',
    parser: Number,
  },
  printName: {
    required: false,
    inputType: 'textInput',
    error: false,
    data: '',
  },
  isActive: {
    required: false,
    inputType: 'radio',
    error: false,
    data: '',
    parser: Boolean,
  },
  hasHolydayCard: {
    required: false,
    inputType: 'radio',
    error: false,
    data: '',
    parser: Boolean,
  },
  hasPosAccount: {
    required: false,
    inputType: 'radio',
    error: false,
    data: '',
    parser: Boolean,
  },
  tags: {
    required: false,
    inputType: 'multiSelect',
    error: false,
    data: '',
    parser: parseOptions,
  },
  shipDetail: {
    required: false,
    inputType: 'textArea',
    error: false,
    data: '',
  },
  // tenantDivision: {
  //   required: false,
  //   inputType: 'select',
  //   error: false,
  //   data: '',
  //   parser: parseOption,
  // },
  divisionsId: {
    required: false,
    inputType: 'multiSelect',
    error: false,
    data: '',
    parser: parseOptions,
  },
  productPotentials: {
    required: false,
    inputType: 'multiSelect',
    error: false,
    data: '',
    parser: parseOptions,
  },
  industriesId: {
    required: false,
    inputType: 'multiSelect',
    error: false,
    data: '',
    parser: parseOptions,
  },
  comments: {
    required: false,
    inputType: 'textArea',
    error: false,
    data: '',
  },
  quoteFooter: {
    required: false,
    inputType: 'textArea',
    error: false,
    data: '',
  },
  poFooter: {
    required: false,
    inputType: 'textArea',
    error: false,
    data: '',
  },
  hasPartNumberSplit: {
    required: false,
    inputType: 'check',
    error: false,
    data: '',
  },
  hasSecondaryAlias: {
    required: false,
    inputType: 'check',
    error: false,
    data: '',
    parser: Boolean,
  },
  hasForecast: {
    required: false,
    inputType: 'check',
    error: false,
    data: '',
    parser: Boolean,
  },
  hasHolidayCard: {
    required: false,
    inputType: 'check',
    error: false,
    data: '',
    parser: Boolean,
  },
};

export const createNewPayload = () =>
  Object.entries(CREATE_COMPANY_SCHEMA).reduce((acc, [key, value]) => {
    acc[key] = { ...value, data: '' };
    return acc;
  }, {} as CreateCompanyPayloadType);
