import styled from 'styled-components';

export const SCardCompanyContact = styled.div`
  display: flex;
  gap: 0.63rem;
  padding: 1rem 1.5rem;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  .company-contact-main {
    display: flex;
    width: 33%;
  }
  .company-contact-visible {
    width: 33%;
    transition: ease-in all 1s;
  }
  .company-contact-map {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const SH4CardCompanyContact = styled.h4`
  font-size: 1.25rem;
  color: #858585;
  margin: 0;
  font-weight: 500;
`;

export const SH5CardCompanyContact = styled.h5`
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 0.38rem;
  font-size: 1rem;
  margin: 0;
  white-space: nowrap;
  span {
    color: #0078d4;
  }
  .anticon svg {
    fill: #858585;
  }
  button {
    padding: 0;
    border: none;
    height: max-content;
    .anticon svg {
      height: 1rem;
      fill: #e44f3b;
    }
  }
`;
