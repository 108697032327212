import { FC, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { isTokenValid } from './isTokenValid';

const RouteValidator: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem('tokenApp');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const validToken = token && isTokenValid(token);

    if (!validToken) {
      navigate('/login');
      localStorage.removeItem('tokenApp');
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [location, token, navigate]);

  return isValid ? <Outlet /> : null;
};

export default RouteValidator;
