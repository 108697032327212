import { FC } from 'react';
import { DeleteOutlined, FileOutlined } from '@ant-design/icons';
import StyledFileItem from '../styles/fileItem.style';

type Props = {
  name: string;
  onDelete: () => void;
};

const FileItem: FC<Props> = ({ name, onDelete }) => (
  <StyledFileItem>
    <FileOutlined />
    <span>{name}</span>
    <DeleteOutlined onClick={onDelete} />
  </StyledFileItem>
);

export default FileItem;
