import { gql } from '@apollo/client';

export const CREATE_INDUSTRY = gql`
  mutation CreateIndustry($createCompanyIndustryInput: CreateCompanyIndustryInput!) {
    CreateIndustry(createCompanyIndustryInput: $createCompanyIndustryInput) {
      id
      name
      description
    }
  }
`;

export const UPDATE_INDUSTRY = gql`
  mutation UpdateIndustry($updateCompanyIndustryInput: UpdateCompanyIndustryInput!) {
    UpdateIndustry(updateCompanyIndustryInput: $updateCompanyIndustryInput) {
      id
      name
      description
    }
  }
`;

export const DELETE_INDUSTRY = gql`
  mutation RemoveIndustry($id: Int!) {
    RemoveIndustry(id: $id)
  }
`;
