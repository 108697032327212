import { gql } from '@apollo/client';

const GET_ALL_COMPANY_PITCHES = gql`
  query GetAllCompanyPitches($companyId: Int!) {
    GetAllCompanyPitches(companyId: $companyId) {
      id
      name
      text
    }
  }
`;

export const GET_HEADERS = gql`
  query Query {
    GetCompanyPitchGridHeaders
  }
`;

export default GET_ALL_COMPANY_PITCHES;
