import { ComponentType, FC } from 'react';

type EditableRowProps = {
  index: number;
};

const getEditableRowComponent = (TrComponent: ComponentType<unknown> | string) => {
  const EditableRow: FC<EditableRowProps> = ({ index, ...props }) => <TrComponent {...props} />;

  return EditableRow;
};

export default getEditableRowComponent;
