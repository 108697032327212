import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Option, SortModel, SubTableColumn } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { AllFilters2, CompanyRankDbModel } from 'src/graphql/schema-types';
import COLUMNS from '../constants';
import { GET_ALL_COMPANY_RANK, GET_COMPANY_RANK_HEADERS } from '../graphql/queries';
import { CompanyRanks, GetAllCompanyRanks, GetCompanyRankHeaders } from '../types';
import {
  CREATE_COMPANY_RANK,
  DELETE_COMPANY_RANK,
  UPDATE_COMPANY_RANK,
} from '../graphql/mutations';
import { filterIDAndAddingTitle } from '../../../../../utils/functions';

const useRankTable = () => {
  const [loadData, { loading }] = useLazyQuery<GetAllCompanyRanks>(GET_ALL_COMPANY_RANK, {
    fetchPolicy: 'network-only',
  });
  const [loadDataFilter] = useLazyQuery<GetAllCompanyRanks>(GET_ALL_COMPANY_RANK, {
    fetchPolicy: 'cache-and-network',
  });
  const [deleteMutation] = useMutation(DELETE_COMPANY_RANK);
  const [loadHeaders] = useLazyQuery<GetCompanyRankHeaders>(GET_COMPANY_RANK_HEADERS);
  const [update] = useMutation(UPDATE_COMPANY_RANK);
  const [create] = useMutation(CREATE_COMPANY_RANK);
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 1000 });
  const [columns, setColumns] = useState<SubTableColumn[]>([]);
  const [newRecord, setNewRecord] = useState({
    name: '',
  });

  useEffect(() => {
    loadHeaders().then((res) => {
      const filteredResult = filterIDAndAddingTitle(
        res.data?.GetCompanyRankGridHeaders as unknown as SubTableColumn[],
      );
      setColumns(filteredResult ?? COLUMNS);
    });
  }, []);

  const uploadData: FetchFunc = ({ start, renderLen }) =>
    loadData({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllCompanyRanks2.results as [],
      total: res.data?.GetAllCompanyRanks2.total as number,
    }));

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(uploadData);

  const autocompleteFieldSearch = (
    searchField: string,
    value: string,
    dataType: string | number | string[],
  ) =>
    loadDataFilter({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },
          filter: [
            {
              fieldName: searchField,
              value,
              dataType,
              operator: AllFilters2.Contains,
            },
          ],
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => {
      if (res.data) {
        return {
          results: res?.data?.GetAllCompanyRanks2?.results as any[],
          filter: res?.data?.GetAllCompanyRanks2?.results?.map((result: CompanyRanks) => ({
            value: result[searchField],
            label: result[searchField],
          })) as Option[],
        };
      }

      return {
        results: [],
        filter: [],
      };
    });

  const handleUpdateRows = (rows: CompanyRankDbModel[]) => {
    rows.forEach((row) => {
      const { id, name } = row;
      update({
        variables: {
          updateCompanyRankInput: {
            id,
            name: capitalizeFirstWord(name!),
          },
        },
      });
    });
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
    });
  };

  const createNewRecord = async () => {
    const { name } = newRecord;
    if (name === '') return;
    await create({
      variables: {
        createCompanyRankInput: {
          name: capitalizeFirstWord(name),
        },
      },
    }).then(() => {
      setTimeout(() => {
        reset();
      }, 500);
    });
  };

  const handleDeleteRows = async (rows: CompanyRankDbModel[]) => {
    const removeId = rows[0].id;

    try {
      await deleteMutation({ variables: { id: removeId } }).then(() => {
        setTimeout(() => {
          reset();
        }, 500);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearFilter = () => {
    reset();
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = async (sortModel: SortModel | undefined) => {
    if (!sortModel) {
      // Load original data without any sorting
      try {
        const response = await loadData({
          variables: {
            criteria: {
              pagination: {
                from: (pagination.page - 1) * pagination.pageSize,
                size: pagination.pageSize,
              },
            },
          },
        });

        // Update data source with the original data
        if (response.data && response.data.GetAllCompanyRanks2) {
          setDataSource(response.data.GetAllCompanyRanks2.results);
        }
      } catch (error) {
        // Handle the error scenario
        console.error('Failed to fetch original data:', error);
      }
      return; // Early return to skip sorting if sortModel is undefined
    }

    // Check if sortBy is "parent" and change it to "parent_name"
    const sortByField = sortModel.sortBy === 'parent' ? 'parent_name' : sortModel.sortBy;

    try {
      // Update pagination with the new sort model
      setPagination((prev) => ({
        ...prev,
        sort: sortModel,
      }));

      // Fetch sorted data
      const response = await loadData({
        variables: {
          criteria: {
            pagination: {
              from: (pagination.page - 1) * pagination.pageSize,
              size: pagination.pageSize,
            },
            orderBy: [
              {
                field: sortByField,
                sort: sortModel.sort.toUpperCase(),
              },
            ],
          },
        },
      });

      // Update data source with new sorted data
      if (response.data && response.data.GetAllCompanyRanks2) {
        setDataSource(response.data.GetAllCompanyRanks2.results);
      }
    } catch (error) {
      // Handle the error scenario
      console.error('Failed to fetch sorted data:', error);
    }
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    handleUpdateRows,
    newRecord,
    postData,
    createNewRecord,
    resetNewRecord,
    spinning: loading,
    dataSource,
    onListRender,
    handleClearFilter,
    handleDeleteRows,
  };
};

export default useRankTable;
