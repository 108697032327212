import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { SortModel } from 'src/components/commons/subTable/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { ContactGroupModel, RelatedCompanyDbModel, StringFilters2 } from 'src/graphql/schema-types';
import { useParams } from 'react-router';
import COLUMNS from '../constants';
import GET_ALL_CONTACTS_BY_COMPANY from '../graphql/queries';
import {
  UPDATE_CONTACT_GROUP,
  CREATE_CONTACT_GROUP,
  DELETE_CONTACT_GROUP,
} from '../graphql/mutations';
import { GetAllContactByCompanyId } from '../types';

const useContactsCompaniesExpanded = () => {
  const [deleting, setDeleting] = useState(false);
  const [getAll, { loading }] = useLazyQuery<GetAllContactByCompanyId>(
    GET_ALL_CONTACTS_BY_COMPANY,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [getAllFilter] = useLazyQuery<GetAllContactByCompanyId>(GET_ALL_CONTACTS_BY_COMPANY, {
    fetchPolicy: 'network-only',
  });

  const [create] = useMutation(CREATE_CONTACT_GROUP);
  const [update] = useMutation(UPDATE_CONTACT_GROUP);
  const [deleteMutation] = useMutation(DELETE_CONTACT_GROUP);

  const [showContactForm, setShowContactForm] = useState(false);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState(COLUMNS);
  const [newRecord, setNewRecord] = useState({
    name: '',
  });

  const { id: companyID } = useParams();

  const fetchPage: FetchFunc = ({ start, renderLen }) =>
    getAll({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        companyId: Number(companyID),
      },
    }).then((res) => ({
      results: res.data?.GetAllContactByCompanyId.results || [],
      total: res.data?.GetAllContactByCompanyId.total || 0,
    }));

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(fetchPage);

  useEffect(() => {
    const data = dataSource.map((el) => ({
      ...el,
      firstName: `${el.firstName} ${el.lastName}`,
    }));
    setDataSource(data);
  }, []);

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) =>
    getAllFilter({
      variables: {
        criteria: {
          companyId: Number(companyID),
          pagination: {
            from: 0,
            size: 25,
          },
          filter: [
            {
              fieldName: searchField,
              value: search,
              dataType,
              operator: StringFilters2.Contains,
            },
          ],
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => {
      if (res.data) {
        return {
          results: res?.data?.GetAllContactByCompanyId.results as any[],
          filter: res?.data?.GetAllContactByCompanyId?.results.map((result: any) => ({
            value: result[searchField],
            label: result[searchField],
          })),
        };
      }

      return {
        results: [],
        filter: [],
      };
    });

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
    });
  };

  const createNewRecord = async () => {
    if (newRecord.name === '') {
      return;
    }

    await create({
      variables: {
        createContactGroupInput: {
          name: capitalizeFirstWord(newRecord.name),
        },
      },
    }).then((contactGroup) => {
      setDataSource([
        {
          id: contactGroup.data.CreateContactGroup.id,
          name: contactGroup.data.CreateContactGroup.name,
        },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: RelatedCompanyDbModel[]) => {
    rows.forEach((row) => {
      const { id, company } = row;
      update({
        variables: {
          updateContactGroupInput: {
            id,
            name: capitalizeFirstWord(company?.name as string),
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: ContactGroupModel[]) => {
    setDeleting(true);
    await Promise.all(
      rows.map((row) => deleteMutation({ variables: { removeContactGroupId: row.id } })),
    );
    setDeleting(false);
    reset();
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const handleClearFilter = () => {
    reset();
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  const handleFavContact = (record: any[]) => {
    console.log(record);
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    dataSource,
    onListRender,
    handleUpdateRows,
    spinning: loading || deleting,
    newRecord,
    postData,
    resetNewRecord,
    createNewRecord,
    handleClearFilter,
    handleDeleteRows,
    handleFavContact,
    showContactForm,
    setShowContactForm,
  };
};

export default useContactsCompaniesExpanded;
